.punctuality-performance-modal {
    &.modal {
        @apply font-body;
        .modal-content {
            @apply bg-white p-0;
            border-radius: 20px;
            min-height: 150px;
            max-width: 95%;

            .modal-header {
                @apply relative flex items-center justify-center bg-be-cyan-4 text-white font-bold italic;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                font-size: 28px;
                height: 70px;

                .modal-header-closing {
                    @apply absolute bg-black text-white p-0 font-extrabold border-none cursor-pointer;
                    top: -14px;
                    right: -14px;
                    width: 34px;
                    height: 34px;
                    font-size: 25px;
                    border-radius: 50%;
                    letter-spacing: normal;

                    &:hover {
                        background: #163a70;
                    }
                }
            }

            .modal-body {
                padding: 35px 30px 35px 60px;
            }
        }
    }
}

.ppm-opener-link {
    @apply underline text-be-cyan-2 cursor-pointer font-bold;
    font-size: 12px;
    margin-left: 15px;
}

.flight-number {
    @apply flex items-center;
    margin-bottom: 30px;

    span {
        width: 90%;
        font-size: 28px;
        font-weight: 600;
    }
}

.ppm-list-elem {
    @apply flex flex-row mb-4;
}

.ppm-icon-container {
    @apply flex justify-center items-center;
    font-size: 18pt;
}

.punctuality-info-container {
    @apply flex flex-col ml-4;
    font-size: 10pt;

    p {
        @apply m-0 font-extrabold leading-5;
    }

    span {
        @apply leading-5;
        font-size: 12pt;
    }
}

.ppm-data-row {
    @apply text-be-cyan-4 font-bold;
}

/* MEDIA QUERIES */

@media #{$medium} {
    .punctuality-performance-modal {
        &.modal {
            .modal-content {
                width: 380px;

                .modal-header {
                    font-size: 26px;
                }
            }
        }
    }

    .flight-number {
        span {
            font-size: 26px;
        }
    }
}

@media #{$xsmall} {
    .punctuality-performance-modal {
        &.modal {
            .modal-content {
                width: 300px;

                .modal-header {
                    font-size: 21px;
                }

                .modal-body {
                    padding: 25px;
                }
            }
        }
    }

    .flight-number {
        span {
            font-size: 21px;
        }
    }

    .ppm-icon-container {
        font-size: 18pt;
    }

    .punctuality-info-container {
        p {
            font-size: 0.85rem;
        }

        span {
            font-size: 0.9rem;
        }
    }
}
