.ac-datepicker {
    @apply relative;

    .dc-datepicker {
        @apply absolute z-10 bg-white shadow-modal;
        width: 620px;
        display: none;

        &.align-right {
            @apply right-0;
        }

        &.up {
            bottom: 100%;
        }
    }

    &.open {
        .dc-datepicker {
            @apply block;
        }

        input {
            @apply border-be-cyan;
        }

        i {
            @apply text-be-cyan;
        }
    }

    i {
        @apply absolute pointer-events-none vertical-center;
        font-size: 20px;
        right: 13px;
    }

    .options-opener {
        ~ i {
            @apply left-auto;
            right: 20px;
        }

        &.disabled {
            ~ i {
                @apply opacity-50;
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    .ac-datepicker {
        @apply w-1/2;

        &.wide {
            @apply w-full;
        }

        .dc-datepicker {
            width: 310px;

            &.sm-align-left {
                @apply left-0 right-auto;
            }

            &.sm-align-right {
                @apply left-auto right-0;
            }
        }
    }
}

@media #{$xsmall} {
    .ac-datepicker {
        @apply w-1/2;

        &.wide {
            @apply w-full;
        }

        i {
            @apply right-auto;
            left: 5px;
            font-size: 15px;
        }

        .dc-datepicker {
            width: 310px;

            &.xs-align-left {
                @apply left-0 right-auto;
            }

            &.xs-align-right {
                @apply left-auto right-0;
            }
        }
    }
}
