@mixin arrow($rotation, $borderColor, $translateY) {
    @apply absolute border-b-2 border-l-0 border-r-2 border-t-0 border-solid;
    content: "";
    top: 50%;
    right: 20px;
    width: 12px;
    height: 12px;
    border-color: #{$borderColor};
    transform: translateY(-50%) translateY($translateY) rotate($rotation);
    transition: all 0.25s ease-in-out;
}

@mixin menuItem($paddingRight, $paddingLeft) {
    @apply relative flex h-full cursor-pointer items-center whitespace-normal border-r border-solid border-white font-body font-semibold text-white;
    padding: 0 #{$paddingRight} 0 #{$paddingLeft};
    font-size: 18px;
    line-height: 21px;
    transition: all 0.25s ease-in-out;
}

@mixin divider($color, $translateY) {
    @apply absolute;
    background-color: $color;
    content: "";
    top: 100%;
    left: 8px;
    right: 8px;
    height: 1px;
    z-index: 10;
    transform: translateY($translateY);
}

.cug2b-header {
    @apply fixed left-0 right-0 top-0 z-50;
    transition: top 0.25s ease-in-out;

    &.collapsed {
        @apply overflow-hidden;
        top: -135px;
    }
}

.cug2b-header-site-menu {
    @apply flex items-center bg-white;
    height: 60px;
    z-index: 100;

    a {
        @apply flex h-full items-center px-6 font-body font-semibold text-be-blue;
        font-size: 16px;
        transition: all 0.25s ease-in-out;

        &:hover {
            @apply text-white;
            background: #204071;
        }
    }

    i {
        @apply text-be-blue;
        transition: all 0.25s ease-in-out;
    }

    ac-culture-selector {
        @apply h-full;

        .culture-selector {
            @apply h-full;

            a {
                @apply mx-0 h-full;
            }
        }
    }
}

.cug2b-user-dropdown {
    @apply relative flex h-full cursor-pointer items-center bg-cug-gray-6;
    padding: 0 40px 0 10px;
    z-index: 100;

    &:after {
        @include arrow(45deg, #1c355e, -5px);
    }

    &:hover {
        background: #204071;

        .cug2b-name,
        .cug2b-org-name,
        i {
            @apply text-white;
        }

        &:after {
            @include arrow(45deg, #fff, -5px);
        }
    }

    &.open {
        background: #204071;

        .cug2b-name,
        .cug2b-org-name,
        i {
            @apply text-white;
        }

        &:after {
            @include arrow(-135deg, #fff, 2px);
        }
    }
}

.cug2b-user-dropdown-content {
    @apply absolute left-0 right-0 overflow-hidden bg-cug-gray-6;
    top: 100%;
    max-height: 0;
    transition: all 0.25s ease-in-out;

    &.open {
        max-height: 200px;
    }

    a {
        padding-left: 60px;
        height: 60px;
    }
}

.cug2b-header-info-menu-container {
    @apply relative flex items-center;
    background: #565d60;
    height: 75px;

    img {
        height: 48px;
    }

    &:after {
        @apply absolute bottom-0 right-0 top-0 bg-cug-dark-gray;
        content: "";
        width: 25%;
        z-index: 0;
    }
}

.cug2b-header-info-menu {
    @apply absolute bottom-0 right-0 top-0 flex items-center bg-cug-dark-gray;
    border-bottom-left-radius: 37px;
    padding: 15px 0 15px 30px;
    z-index: 1;

    > a {
        @include menuItem(25px, 25px);
        max-width: 270px;

        &:last-child {
            @apply border-r-0 pr-0;
        }

        &:visited {
            @apply text-white;
        }

        &:hover {
            @apply text-cug-gray-2;

            &:after {
                @apply border-cug-gray-2;
            }
        }
    }
}

.cug2b-info-dropdown {
    @include menuItem(75px, 25px);
    min-width: 260px;

    &:after {
        @include arrow(45deg, #fff, -5px);
    }

    &:hover {
        @apply text-cug-gray-2;

        &:after {
            @apply border-cug-gray-2;
        }
    }

    &.open {
        @apply text-white;

        &:after {
            @include arrow(-135deg, #fff, 2px);
        }
    }
}

.cug2b-info-dropdown-content {
    @apply absolute left-0 right-0 overflow-hidden bg-cug-dark-gray;
    top: 100%;
    max-height: 0;
    transition: all 0.25s ease-in-out;
    transform: translateY(15px);

    &.open {
        max-height: 250px;
    }

    a {
        @apply relative flex h-full w-full items-center border-0 font-light text-white;
        height: 48px;
        padding: 0 25px 0 25px;

        &:after {
            @include arrow(-45deg, #fff, 0);
        }

        &:hover {
            @apply font-bold text-white;

            &:after {
                @apply border-white;
            }
        }

        &:not(:last-child) {
            &:before {
                @include divider(#fff, 0);
            }
        }
    }
}

.cug2b-name {
    @apply font-bold text-be-blue;
    font-size: 16px;
    line-height: 20px;
    transition: all 0.25s ease-in-out;
}

.cug2b-org-name {
    @apply font-semibold text-be-blue;
    font-size: 14px;
    line-height: 17px;
    transition: all 0.25s ease-in-out;
}

// MEDIA QUERIES
@media #{$small-down} {
    .cug2b-mobile-header {
        @apply fixed left-0 right-0 top-0 z-50 flex items-center justify-between border-solid border-cug-lightest-gray bg-white;
        border-bottom-width: 3px;
        height: 60px;
        transition: top 0.25s ease-in-out;

        &.collapsed {
            @apply overflow-hidden;
            top: -60px;
        }

        &.chile-compra-mobile-header {
            @apply justify-end border-brand-primary;
        }

        &:after {
            content: "|||";
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 20px;
            top: 15px;
            font-size: 30px;
            font-weight: 900;
            transform: rotate(-90deg) scaleY(1.3);
            @include transition(all $main-navigation-transition-time);
            color: $header-main-color;
            background: white;
        }

        .mobile-navigation {
            top: 35px;
            right: 10px;

            display: block;
            position: absolute;
            width: 40px;
            text-transform: uppercase;
            color: #204071;
            font-size: 10px;
            text-align: center;

            &[type="checkbox"] {
                @apply inset-y-0 z-50 block h-auto border-0 bg-transparent;
                width: 45px;
            }
        }
    }

    .cug2b-mobile-menu-content {
        @apply fixed w-full bg-cug-dark-gray;
        top: 60px;
        height: calc(100vh - 60px);
        z-index: 10000;
    }

    .cug2b-header-site-menu {
        @apply block;
        height: unset;

        a {
            @apply relative flex w-full items-center bg-white text-be-blue;
            height: 60px;
            padding-left: 30px;

            &:not(:last-child) {
                &:before {
                    @include divider(#9d9d9c, -1px);
                }
            }
        }
    }

    .cug2b-user-dropdown {
        height: 60px;

        &:after {
            @include arrow(-45deg, #1c355e, 0);
        }

        &:hover {
            &:after {
                @include arrow(-45deg, #fff, 0);
            }
        }

        &.open {
            &:after {
                @include arrow(45deg, #fff, -5px);
            }
        }
    }

    .cug2b-user-dropdown-content {
        @apply relative top-0 block overflow-hidden;
        max-height: 0;
        transition: all 0.25s ease-in-out;
        transform: none;

        &.open {
            max-height: 500px;
        }

        a {
            @apply flex items-center text-be-blue;
            height: 60px;
            padding-left: 30px;

            &:after {
                @include arrow(-45deg, #1c355e, 0);
            }
        }
    }

    .cug2b-header-info-menu {
        @apply relative block rounded-none p-0;
        height: unset;

        a {
            @apply w-full border-0;
            padding: 0 40px 0 30px;
            max-width: unset;
            height: 60px;

            &:before {
                @include divider(#9d9d9c, 0);
            }

            &:after {
                @include arrow(-45deg, #fff, 0);
            }
        }
    }

    .cug2b-info-dropdown {
        @apply border-0;
        padding-left: 30px;
        height: 60px;

        &:before {
            @include divider(#9d9d9c, 0);
        }

        &.open {
            @apply border-l border-solid border-white;
            background-color: #565d60;
            z-index: 100;

            &:after {
                transform: translateY(-50%) translateY(-5px) rotate(45deg);
            }
        }

        &:after {
            transform: translateY(-50%) translateY(0) rotate(-45deg);
        }
    }

    .cug2b-info-dropdown-content {
        @apply relative top-0 block overflow-hidden;
        max-height: 0;
        transition: all 0.25s ease-in-out;
        transform: none;

        &.open {
            @apply border-l border-solid border-white;
            background-color: #565d60;
            max-height: 500px;
        }

        a {
            padding-left: 48px;
        }
    }
}

@media #{$small} {
    .cug2b-mobile-header {
        padding: 0 80px 0 36px;

        &:after {
            right: 36px;
        }

        img {
            height: 52px;
        }

        .mobile-navigation {
            right: 25px;
        }
    }
}

@media #{$xsmall} {
    .cug2b-mobile-header {
        padding: 0 50px 0 15px;

        .culture-selector {
            > a {
                width: 60px;
                height: 55px;
                padding-right: 15px;

                > span {
                    font-size: 0;
                }
            }

            .cultures {
                left: -80px;
                width: 140px;
            }
        }

        img {
            height: 40px;
        }
    }
}
