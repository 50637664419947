.booking-modal-content {
    > button {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        border: none;
        background: black;
        color: white;
        font-size: 36px;
        cursor: pointer;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 0;
        font-weight: normal;
        z-index: 1;
        letter-spacing: unset;
        line-height: 1;

        &:hover {
            border: none;
            color: $brand-secondary;
            text-decoration: none;
        }
    }

    .modal-header-closing {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        border: none;
        background: transparent;
        color: white;
        font-size: 36px;
        cursor: pointer;

        &:hover {
            border: none;
            color: $brand-secondary;
            text-decoration: none;
        }
    }

    .modal-button-container {
        a {
            &.modal-btn {
                display: block;
                box-sizing: border-box;
                margin: 5px;
                padding: $padding-small $padding-xlarge;
                border: 2px solid $modal-border-color;
                border-radius: $border-radius-sm;
                background: $modal-button-bg;
                color: #fff;
                font-weight: $semibold-font-weight;
                @include font-size(1.1);

                &:hover {
                    background: $brand-secondary;
                    border-color: $brand-secondary;
                    color: #fff;
                }
            }
        }

        &.button-center {
            justify-content: center;
        }
    }
}

.flight-modal-content {
    .modal-button-container {
        &.buttons-right {
            button {
                margin-left: 10px;
            }
        }

        &.buttons-center {
            button {
                width: 170px;

                &:first-child {
                    margin-right: 10px;
                }
            }
        }

        &.upgrade-buttons {
            button {
                width: 200px;
            }
        }
    }

    .secondary-btn {
        padding: 14px;
        color: $brand-secondary;
        border-color: $brand-secondary;

        &:hover {
            background: $brand-secondary;
            color: #fff;
            border-color: $brand-secondary;
        }
    }
}

.cug-modal-content {
    .secondary-btn {
        background: $cug-secondary-btn-color;
        color: #fff;
    }
}

.agency-modal {
    .secondary-btn {
        margin: 0 10px 40px;

        &.push-down {
            margin: 40px 0 0;
        }
    }

    .text-right {
        .secondary-btn {
            margin-right: 0;
        }
    }
}

.checkin-modal-buttons {
    a {
        &.rounded-primary-btn {
            padding: 12px 45px;
            margin-bottom: 10px;
        }

        &:not(.rounded-primary-btn) {
            @apply cursor-pointer underline;
            color: $brand-secondary;

            &:hover {
                @apply no-underline;
                color: $brand-primary;
            }
        }
    }
}

@media #{$medium-up} {
    .cug-modal-content {
        .secondary-btn.centered {
            margin: 40px auto 0;
            display: block;
        }
    }
}

@media #{$small} {
    .cug-modal-content {
        .secondary-btn.centered {
            margin: 40px auto 0;
            display: block;
        }
    }
}

@media #{$small-down} {
    .flight-modal-content {
        .modal-button-container {
            &.buttons-right {
                button {
                    padding: 15px 30px;
                    margin: 0;
                }
            }
        }

        .primary-btn,
        .secondary-btn {
            display: inline-block;
            margin-right: unset;
        }
    }
}

@media #{$xsmall} {
    .flight-modal-content {
        &.extension-modal,
        &.upgrade-modal {
            .modal-button-container {
                .primary-btn {
                    margin-left: unset;
                }

                button {
                    display: block;
                    width: 100%;

                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .forgot-password {
        .modal-button-container {
            .secondary-btn {
                float: none;
                display: block;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 20px;
            }
        }
    }

    .payment-modal-content {
        .primary-btn,
        .secondary-btn {
            min-width: 150px;
        }
    }

    .booking-modal-content {
        > button {
            width: 30px;
            height: 30px;
            font-size: 25px;
        }
    }

    .agency-modal {
        .cug-modal-content {
            .secondary-btn {
                display: block;
            }
        }
    }

    .cug-modal-content {
        .primary-btn,
        .secondary-btn {
            min-width: 150px;
        }

        .secondary-btn,
        .secondary-btn.centered {
            float: none;
            display: block;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 20px;
        }
    }
}
