.profile-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    background: #fff;

    .profile-sidebar {
        width: 300px;
        padding: 20px;
        border: 1px solid $brand-secondary;
        border-top-left-radius: $border-radius-lg;
        border-bottom-left-radius: $border-radius-lg;
    }

    .profile-content {
        padding: 20px;
        margin: 20px 20px 20px 40px;
        width: calc(100% - 340px);
        border: 1px solid $brand-secondary;
        border-radius: $border-radius-lg;
    }
}

.dc-user-icon {
    width: 175px;
    height: 175px;
    margin: 20px auto;

    path {
        fill: $profile-dc-user-icon-path-color;
    }

    &.member {
        path {
            fill: $membership-accent-color;
        }
    }
}

.profile-name {
    font-family: ClanOT-Bold;
    color: $brand-secondary;
    font-size: 17px;
    margin: 20px 0 10px;
    line-height: 1;
}

.profile-subtitle {
    color: $brand-secondary;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: $font-size-medium;
    margin: 5px 0;
    line-height: 1;

    &.push-down {
        margin-top: 20px;
    }

    i {
        font-size: $font-size-medium;
        margin-left: 10px;
        color: $brand-secondary;
        cursor: pointer;

        &:hover {
            color: $brand-primary;
        }
    }
}

.profile-user-data {
    margin: 20px 0;
    font-family: "Lato", sans-serif;
    color: $brand-secondary;
    font-size: $font-size-medium;
}

.dc-chart {
    margin-top: 40px;

    header {
        margin-bottom: 0;
        padding: 10px 20px;
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
        background: $brand-secondary;
        color: #fff;
        line-height: 1;
        font-size: $font-size-medium;
        font-weight: 900;
        font-family: "Lato", sans-serif;

        &.active {
            background: $membership-accent-color;
        }
    }

    .dc-chart-content {
        padding: 10px 20px;
        border: 1px solid $gray-lighter;
        border-bottom-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        line-height: 2;
        font-family: "Lato", sans-serif;
        color: $brand-secondary;

        .emphasis {
            color: $brand-secondary;
            font-weight: 600;
        }
    }
}

.trapeze-profile-text {
    position: relative;
    padding: 10px;
    color: #fff;
    background: $membership-accent-color;
    text-align: center;
    font-size: $font-size-small;
    font-weight: 600;
    font-family: "Lato", sans-serif;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        top: -30%;
        bottom: -30%;
        left: -3%;
        right: -3%;
        z-index: -1;
        border-radius: $border-radius-sm;
        background: $membership-accent-color;
        -webkit-transform: perspective(50em) rotateX(-50deg);
        transform: perspective(50em) rotateX(-50deg);
    }

    span {
        font-weight: 600;
        color: #fff;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.fa {
    &.large-icon {
        &.js-icon {
            font-size: 20px;
            font-weight: 300;
        }
    }
}

.table-routes {
    display: inline-block;
    width: 138px;
    height: 15px;
    position: relative;
    top: -2px;

    .table-route-departure {
        width: 71px;
        height: 22px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .table-route-arrival {
        width: 67px;
        height: 22px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .table-route-departure-station,
    .table-route-arrival-station {
        position: absolute;
        top: 3px;
        color: $brand-secondary;
        font-weight: 900;
        font-family: "Lato", sans-serif;
        font-size: $font-size-xsmall;

        &.route-flown {
            color: #fff;
        }
    }

    .table-route-departure-station {
        left: 19px;
    }

    .table-route-arrival-station {
        right: 20px;
    }
}

.route-unflown {
    fill: $profile-route-unflown-color;
    stroke: $profile-route-unflown-stroke-color;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

.route-flown {
    fill: $profile-route-flown-color;
}

.my-trips-title {
    font-family: "Lato", sans-serif;
    font-size: $font-size-medium;
    font-weight: 900;
    color: $brand-secondary;
    margin: 20px 0;
}

.dc-circle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .emphasis {
        color: $brand-secondary;
        font-weight: 600;
    }

    .dc-circle-title {
        font-family: "Lato", sans-serif;
    }

    .dc-circle {
        width: 120px;
        height: 120px;
        font-family: "Lato", sans-serif;
        position: relative;

        path {
            fill: $profile-dc-circle-path-color;

            &.selected-dc-slice {
                fill: $membership-accent-color;
            }
        }

        .dc-circle-percent {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -26px);
            font-size: 33px;
            color: $membership-accent-color;
            font-weight: 900;
            line-height: 1;
        }

        .dc-circle-slogan {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 5px);
            font-size: 15px;
            color: $membership-accent-color;
            font-weight: 900;
            text-transform: uppercase;
            line-height: 1;
        }
    }

    .dc-circle-legend {
        font-family: "Lato", sans-serif;
        display: flex;
        align-items: center;

        i {
            font-size: 28px;
            margin-right: 10px;
            color: $membership-accent-color;
        }
    }
}

.used-giftcards {
    tr {
        td {
            opacity: 0.5;
        }
    }
}

.no-trips {
    margin: 20px;
    color: $brand-secondary;
    font-size: 20px;
    font-weight: 600;

    a {
        color: $brand-primary;
        font-size: 20px;
        font-weight: 600;

        &:hover {
            color: $brand-secondary;
        }
    }
}

.edit-profile {
    .mdl-textfield {
        padding: 5px 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 9px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }

        &:disabled {
            color: $brand-secondary;
            background: #fff;
            opacity: 0.5;
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }

    .birthdate-container {
        padding-top: 26px;

        .input-inner-title {
            top: 8px;
        }
    }
}

.profile-buy-flights-btn-container {
    text-align: right;

    .primary-btn {
        width: 150px;
        height: 45px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0 0;
        padding: 0;
        background: #af272f;
        color: #fff;
        line-height: 1;
        border: none;
        border-radius: 5px;
        letter-spacing: normal;
        appearance: none;
        -webkit-appearance: none;
        font-family: "Lato", sans-serif;
        font-size: 16px;
        text-transform: none;

        &:hover {
            border: none;
            font-size: 16px;
            background: $brand-secondary;
            color: #fff;
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .dc-circle-container {
        .dc-circle {
            .dc-circle-percent {
                font-size: 25px;
                transform: translate(-50%, -32px);
            }
            .dc-circle-slogan {
                font-size: 13px;
                transform: translate(-50%, -8px);
            }
        }
    }
}

@media #{$small} {
    .profile-container {
        .profile-sidebar {
            width: 245px;
        }

        .profile-content {
            margin: 0;
            width: calc(100% - 246px);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        }
    }

    .dc-circle-container {
        display: block;

        .dc-circle-title {
            display: inline-block;
            width: 50%;
        }

        .dc-circle {
            display: inline-block;
        }
    }

    .table-routes {
        width: 98px;
        top: 0;

        .table-route-departure {
            width: 51px;
        }

        .table-route-arrival {
            width: 47px;
        }

        .table-route-departure-station,
        .table-route-arrival-station {
            top: 1px;
            font-size: 11px;
        }

        .table-route-departure-station {
            left: 16px;
        }

        .table-route-arrival-station {
            right: 12px;
        }
    }
}

@media #{$xsmall} {
    profile {
        .booking-wrapper {
            padding: 10px;
        }

        .inner-deep-box {
            &.padded {
                padding: 10px;
            }
        }
    }

    .profile-container {
        display: block;
        background: transparent;

        .profile-sidebar {
            width: 100%;
            border-radius: $border-radius-lg;
            padding: 10px;
            background: #fff;
        }

        .profile-content {
            width: 100%;
            margin: 0;
            border: none;
            padding: 0;
        }
    }

    .profile-dc-icon {
        display: inline-block;
        width: 39%;
        vertical-align: top;
    }

    .dc-user-icon {
        width: 107px;
        height: 107px;
        max-width: 100%;
        margin: 10px auto;
    }

    .profile-dc-info {
        display: inline-block;
        width: 56%;
        margin-left: 3%;
        vertical-align: top;
    }

    .trapeze-profile-text {
        font-size: $font-size-xsmall;
        font-weight: 600;
        padding: 5px;
        line-height: 1.1;
    }

    .profile-name {
        margin-top: 10px;
    }

    .profile-extra-info {
        display: none;

        .profile-subtitle {
            display: inline-block;
            width: 42%;
            margin: 10px 0;
            vertical-align: top;
        }

        .profile-user-data {
            display: inline-block;
            width: 57%;
            margin: 10px 0;
            vertical-align: top;
        }
    }

    [type="checkbox"] {
        + .profile-extra-info-opener-label {
            display: block;
            width: 57%;
            margin-left: 43%;
            padding-left: 0;
            color: $brand-secondary;

            &:before {
                display: none;
            }

            i {
                position: relative;
                top: 3px;
                font-size: 20px;
                font-weight: 600;
                color: $brand-secondary;
            }
        }
    }

    .profile-extra-info-opener {
        &:checked {
            ~ .profile-extra-info {
                display: block;
                margin-top: 10px;
                border-top: 1px dashed $brand-secondary;
            }

            ~ label {
                i {
                    display: inline-block;
                    transform: rotate(-270deg);
                }
            }
        }
    }

    .dc-circle-container {
        display: block;

        .dc-circle-title {
            display: inline-block;
            width: calc(95% - 120px);
        }

        .dc-circle {
            display: inline-block;
        }

        .dc-circle-legend {
            margin-top: 10px;

            > span {
                font-size: $font-size-xsmall;
            }
        }
    }

    .trip-acc-table {
        width: 100%;
        border-bottom: 1px solid $gray-lighter;
        background: $gray-lightest;

        &.open {
            background: #fff;
        }

        th {
            background: $profile-trip-acc-table-th-bg;
            color: #fff;

            &:first-child {
                border-top-left-radius: $border-radius-lg;
            }

            &:last-child {
                border-top-right-radius: $border-radius-lg;
            }
        }

        td {
            color: $brand-secondary;
        }

        th,
        td {
            padding: 10px 5px;
            text-align: center;
            vertical-align: middle;

            &:nth-child(1) {
                width: 30px;
                border-left: 1px solid $gray-lighter;
            }

            &:nth-child(2) {
                width: 90px;
            }

            &:nth-child(4) {
                width: 50px;
                border-right: 1px solid $gray-lighter;
            }
        }

        .js-add {
            color: $brand-tertiary;
            font-size: $font-size-small;
            cursor: pointer;
        }

        .js-close {
            color: $membership-accent-color;
            font-size: $font-size-small;
            cursor: pointer;
        }
    }

    trigger-view-booking {
        cursor: pointer;
    }
}
