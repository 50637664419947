.b2-journey-pax-grid-container {
    @apply rounded-xl;
}

.b2-bag-grid {
    @apply grid relative w-full items-stretch gap-y-0 gap-x-0;
    justify-items: stretch;
    grid-template-columns: 6fr 10fr 10fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "view free paid"
        "selector selector selector";
}

.ac-per-journey-per-pax {
    grid-area: selector;
}

.b2-per-pax-grid {
    @apply grid relative w-full items-stretch gap-y-0 gap-x-0;
    justify-items: stretch;
    grid-template-columns: 6fr 10fr 10fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "view2 reset reset"
        "per-pax per-pax per-pax";
}

.b2-pax-grid {
    @apply grid relative w-full items-stretch gap-y-0 gap-x-0;
    justify-items: stretch;
    grid-template-columns: 6fr 10fr 10fr;
    grid-template-rows: auto;
}

.b2-oversized-grid {
    @apply grid relative w-full items-stretch gap-y-0 gap-x-0;
    justify-items: stretch;
    grid-template-columns: 6fr 10fr 10fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "opentop opentop opentop"
        "selector selector selector";
}
