.i2-transactions-table {
    @apply w-full;

    thead {
        tr {
            @apply bg-be-light-gray;

            th {
                @apply font-body text-be-gray-4;
                font-size: 22px;
                height: 67px;
                vertical-align: middle;
            }
        }
    }

    tbody {
        tr {
            td {
                @apply font-body font-semibold text-be-blue;
                font-size: 21px;
                height: 67px;
                text-align: center;
                vertical-align: middle;

                img {
                    max-width: 50px;
                }

                span {
                    @apply inline-block rounded-full border border-solid border-be-blue font-body font-semibold leading-none text-be-blue;
                    font-size: 21px;
                    padding: 10px 15px;
                }

                a:not(.btn) {
                    @apply font-body font-semibold text-be-blue underline;

                    &:hover {
                        @apply text-be-red;
                    }
                }
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-transactions-table {
        thead {
            tr {
                th {
                    font-size: 20px;
                    height: 60px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 17px;
                    height: 60px;

                    img {
                        max-width: 45px;
                    }

                    span {
                        font-size: 17px;
                        padding: 8px 12px;
                    }
                }
            }
        }
    }
}

@media #{$medium} {
    .i2-transactions-table {
        thead {
            tr {
                th {
                    font-size: 17px;
                    height: 52px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 14px;
                    height: 52px;

                    img {
                        max-width: 32px;
                    }

                    span {
                        font-size: 14px;
                        padding: 6px 10px;
                    }
                }
            }
        }
    }
}

@media #{$small} {
    .i2-transactions-table {
        thead {
            tr {
                th {
                    font-size: 13px;
                    height: 40px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 12px;
                    height: 40px;

                    img {
                        max-width: 24px;
                    }

                    span {
                        font-size: 12px;
                        padding: 6px 10px;
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .i2-transaction-container {
        @apply relative overflow-x-scroll whitespace-nowrap pb-3;
        -webkit-overflow-scrolling: touch;

        td,
        th {
            @apply px-1;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .ps__rail-x {
            @apply opacity-75;
        }
        .ps__rail-y {
            @apply opacity-75;
        }
    }

    .i2-transactions-table {
        min-width: 460px;

        thead {
            tr {
                th {
                    font-size: 13px;
                    height: 40px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 12px;
                    height: 40px;

                    img {
                        max-width: 24px;
                    }

                    span {
                        font-size: 12px;
                        padding: 6px 10px;
                    }
                }
            }
        }
    }
}
