.itinerary-flight-info-container {
    @apply relative flex w-full justify-center;

    &.fill-space {
        @apply justify-between;

        .itinerary-flight-time {
            &.with-one-more-day {
                .one-more-day {
                    margin-right: -19px;
                }
            }
        }

        .fligh-info-wrapper {
            @apply block;
        }
    }
}

.itinerary-station {
    @apply flex h-full flex-col text-brand-secondary;
    margin: 0 9px;
}

.itinerary-flight-time {
    @apply relative flex items-end justify-center font-semibold;
    height: 50%;
    text-align: center;
    font-size: 20px;

    &.with-one-more-day {
        margin-left: -13px;

        .one-more-day {
            @apply block font-semibold text-new-ui-red;
            line-height: 1.2;
            margin: 1px -24px 3px 2px;
            font-size: 15px;

            &.per-leg-view {
                margin: -2px -24px 0 2px;
                font-size: 14px;
            }

            &.in-checkin {
                margin: 1px -24px 2px 2px;
            }
        }
    }

    &.crossed-time {
        &:after {
            @apply absolute rounded-full bg-be-red-2;
            content: "";
            height: 2px;
            width: 50%;
            top: 85%;
            left: 25%;
        }
    }
}

.itinerary-station-info {
    @apply flex flex-col justify-start;
    height: 50%;
}

.itinerary-station-name {
    @apply block font-semibold text-brand-secondary;
    font-size: $font-size-small;
    line-height: 1.2;
    margin-top: 1px;
    text-align: center;

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }
}

.itinerary-airport-name {
    @apply flex flex-col justify-start font-semibold;
    color: #838383;
    font-size: 10px;
    line-height: 1.2;
    text-align: center;

    &.right {
        text-align: right;
    }

    &.left {
        text-align: left;
    }
}

.itinerary-separator {
    @apply relative flex h-full items-center justify-center pt-2;

    .help-icon {
        @apply cursor-pointer text-brand-tertiary;

        &.darker {
            @apply text-new-ui-turqoise;
        }
    }
}

.itinerary-info-divider {
    width: 35px;
    border-bottom: 1px solid #ccc;
    margin: 0 5px;
}

.itinerary-info-plane-icon {
    @apply text-brand-secondary;

    font-size: 35px;
}

.itinerary-new-time-container {
    @apply flex flex-col items-center justify-center;
}

.itinerary-new-time-text {
    @apply block font-body font-black italic text-new-ui-cyan-2;

    font-size: 15px;
    line-height: 28px;
}

.itinerary-new-time {
    @apply flex items-center justify-center;

    background-color: #caf8ff;
    border-radius: 3px;
    padding: 5px 10px;

    span {
        @apply block font-black text-new-ui-cyan-2;
        font-size: 19px;
    }
}

.flight-itinerary-clock-icon {
    @apply text-new-ui-cyan-2;

    font-size: 20px;
    margin-right: 5px;
}

// MEDIA QUERIES
@media #{$large} {
    .itinerary-station-name {
        font-size: 13px;
    }
}

@media #{$medium} {
    .itinerary-flight-info-container {
        &.fill-space {
            @apply w-full;

            .itinerary-station {
                margin: 0 9px;
            }
        }
    }

    .fligh-info-wrapper {
        @apply flex items-center justify-center;
    }

    .itinerary-flight-info-container {
        width: 295px;

        &.in-flight-move {
            width: 265px;
        }
    }

    .itinerary-flight-time {
        &.with-one-more-day {
            .one-more-day {
                margin: 1px -24px 4px 2px;
                font-size: 12px;

                &.per-leg-view {
                    margin: -2px -20px 0 2px;
                }
            }
        }

        &.crossed-time {
            &:after {
                width: 64%;
                top: 88%;
                left: 18%;
            }
        }
    }

    .itinerary-station {
        margin: 0 4px;
    }

    .itinerary-station-name {
        font-size: 12px;
    }

    .itinerary-separator {
        width: 70%;

        &.flight-move-separator {
            @apply w-1/2;
        }
    }

    .itinerary-info-divider {
        &.flight-move-divider {
            width: 20px;
        }
    }

    .itinerary-info-plane-icon {
        font-size: 25px;
    }

    .itinerary-new-time-text {
        font-size: 12px;
        line-height: 25px;
    }

    .itinerary-new-time {
        span {
            font-size: 15px;
        }
    }

    .flight-itinerary-clock-icon {
        font-size: 15px;
    }
}

@media #{$small} {
    .itinerary-flight-info-container {
        &.in-flight-move {
            width: 98%;
        }
    }

    .itinerary-station {
        width: 85px;
    }

    .itinerary-flight-time {
        &.crossed-time {
            &:after {
                width: 60%;
                top: 88%;
                left: 20%;
            }
        }
    }

    .itinerary-station-name {
        font-size: 13px;
    }

    .itinerary-separator {
        &.flight-move-separator {
            @apply w-1/2;
        }
    }

    .itinerary-info-divider {
        width: 30px;

        &.flight-move-divider {
            width: 20px;
        }
    }

    .itinerary-new-time-text {
        font-size: 14px;
        line-height: 25px;
    }
}

@media #{$xsmall} {
    .itinerary-flight-info-container {
        @apply relative items-center;
        margin: -20px 0 10px 0;

        &.fill-space {
            @apply m-3 w-[calc(100%-1.5rem)] justify-center;
        }

        &.with-more-space {
            margin: -20px 0 20px 0;
        }
    }

    .itinerary-station {
        width: 86px;

        &.checkin-itinerary-station {
            @apply m-0;

            width: 65px;
        }
    }

    .itinerary-flight-time {
        &.crossed-time {
            &:after {
                width: 78%;
                top: 88%;
                left: 10%;
            }
        }
    }

    .itinerary-info-divider {
        width: 25px;

        &.checkin-divider {
            width: 20px;
        }
    }

    .itinerary-info-plane-icon {
        font-size: 30px;
    }

    .itinerary-new-time-text {
        font-size: 12px;
        line-height: 25px;
    }

    .itinerary-new-time {
        span {
            font-size: 15px;
        }
    }

    .flight-itinerary-clock-icon {
        font-size: 15px;
    }
}
