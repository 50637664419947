.cug-summary-widget-container {
    @apply grid relative w-full justify-items-stretch items-stretch gap-x-5 gap-y-5;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "widget1 widget1 widget1 widget2 widget2 widget2"
        "widget3 widget3 widget3 widget3 widget3 widget3"
        "widget4 widget4 widget4 widget4 widget4 widget4";
    transition: all 0.25s ease-in-out;

    &.first-inactive {
        grid-template-areas:
            "widget2 widget2 widget2 widget2 widget2 widget2"
            "widget3 widget3 widget3 widget3 widget3 widget3"
            "widget4 widget4 widget4 widget4 widget4 widget4";
    }

    &.second-inactive {
        grid-template-areas:
            "widget1 widget1 widget1 widget1 widget1 widget1"
            "widget3 widget3 widget3 widget3 widget3 widget3"
            "widget4 widget4 widget4 widget4 widget4 widget4";
    }
}

ac-summary-page-widget {
    @apply contents;
}

.cug-summary-widget {
    @apply bg-cover bg-no-repeat bg-left relative cursor-pointer;
    border-radius: 10px;
    height: 186px;
}

// Widgets:

.cug-summary-widget-1 {
    grid-area: widget1;
}

.cug-summary-widget-2 {
    grid-area: widget2;
}

.cug-summary-widget-3 {
    grid-area: widget3;
}

.cug-summary-widget-4 {
    grid-area: widget4;
}

/* MEDIA QUERIES */

@media #{$small} {
    .cug-summary-widget {
        height: 130px;
    }
}

@media #{$xsmall} {
    .cug-summary-widget-container {
        grid-template-areas:
            "widget1"
            "widget2"
            "widget3"
            "widget4";
        row-gap: 15px;
        grid-template-columns: auto;
        grid-template-rows: auto;
    }

    .cug-summary-widget {
        height: 130px;
    }
}
