.modal {
    &.multisegment-modal {
        .modal-content {
            @apply p-0 overflow-hidden;
            width: 355px;
            height: 655px;
            max-height: 95%;
            border-radius: 20px;
        }

        .modal-header {
            @apply bg-new-ui-cyan-2 text-white font-bold flex items-center justify-center relative;
            height: 60px;
            font-size: 25px;

            .modal-header-closing {
                @apply absolute font-body rounded-full flex items-center justify-center border-none text-white vertical-center font-black;
                right: 15px;
                width: 30px;
                height: 30px;
                padding: 0 0 1px 1px;
                font-size: 24px;
                background: #484848;
                letter-spacing: normal;

                &:hover {
                    @apply bg-white;
                    color: #484848;
                }
            }
        }

        .modal-body {
            padding: 24px;
        }
    }
}

.msm-segment {
    @apply border-bg-gray-1 border border-solid rounded;
    padding: 16px;
}

.msm-endpoint-header {
    @apply flex items-center text-new-ui-blue font-bold;
    font-size: 18px;
    margin-bottom: 5px;

    i {
        font-size: 25px;
        margin-right: 5px;
    }
}

.msm-endpoint-station {
    @apply grid gap-x-1;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "stationCity stationCode"
        "stationAirport none";
}

.msm-endpoint-station-city {
    @apply font-bold;
    grid-area: stationCity;
}

.msm-endpoint-station-airport {
    @apply font-bold;
    grid-area: stationAirport;
}

.msm-endpoint-station-code {
    @apply font-semibold;
    grid-area: stationCode;
}

.msm-time {
    @apply text-new-ui-turqoise font-bold flex items-center relative;
    font-size: 16px;
    margin-bottom: 10px;

    &:last-child {
        @apply mb-0;
    }

    &.changed {
        .msm-time-value {
            @apply relative;

            span {
                @apply text-new-ui-gray relative;
                margin-right: 10px;

                &:after {
                    @apply absolute vertical-center left-0 right-0 bg-new-ui-red;
                    content: "";
                    height: 1px;
                }

                &.one-more-day {
                    @apply font-extrabold text-new-ui-red top-auto right-auto mr-0;
                    margin-left: 1px;
                    bottom: 3px;

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .one-more-day {
        @apply absolute text-new-ui-red font-extrabold leading-none;
        top: 2px;
        right: -13px;
        font-size: 11px;
        letter-spacing: -1px;
    }
}

.msm-flight-number {
    @apply text-new-ui-gray font-bold;
    font-size: 14px;
    margin-bottom: 3px;
}

.msm-flight-time {
    @apply text-new-ui-turqoise flex items-center;
    font-size: 12px;
    margin-bottom: 3px;

    i {
        font-size: 12px;
        margin-right: 5px;
    }
}

.msm-operator {
    @apply text-new-ui-gray;
    font-size: 12px;
    margin-bottom: 10px;
}

.msm-transfer {
    @apply border border-dashed border-new-ui-blue rounded;
    margin: 16px 0;
    padding: 16px;
}

.msm-transfer-change {
    @apply font-semibold text-new-ui-turqoise italic;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
}

.msm-transfer-divider {
    @apply flex w-full items-center justify-center relative;
    margin-bottom: 10px;

    &:before {
        @apply bg-be-blue absolute vertical-center left-0 right-0;
        content: "";
        height: 1px;
    }

    span {
        @apply font-semibold italic text-new-ui-blue bg-white z-10;
        padding: 0 10px;
        font-size: 14px;
    }
}

.msm-transfer-info {
    @apply flex items-center;

    i {
        @apply text-new-ui-turqoise;
        font-size: 24px;
        margin-right: 5px;
    }
}

.msm-transfer-details {
    @apply text-new-ui-turqoise;
    font-size: 11px;
}

/* MEDIA QUERIES */

@media #{$xsmall} {
    .modal {
        &.multisegment-modal {
            .modal-content {
                @apply overflow-y-auto;
                width: 355px;
                max-height: 90vh;
            }
        }
    }
}
