@import "./giftcard-colors";

.modal {
    &.giftcard-success-modal {
        .modal-content {
            width: 657px;
            max-width: 95%;
            padding: 30px 95px 70px;
        }

        .giftcard-header {
            margin: 0 0 30px 0;
            padding: 0;
            border: none;

            .giftcard-header-logo {
                top: 60px;
            }

            i {
                top: -10px;
            }
        }

        .modal-close {
            position: absolute;
            top: -17px;
            right: -17px;
            width: 34px;
            height: 34px;
            background: $gray-darkest;
            color: #fff;
            border-radius: 50%;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            z-index: 100;
            user-select: none;
            padding: 0;
            line-height: 1;
            cursor: pointer;

            &:not(disabled):hover {
                border: none;
                background: $brand-secondary;
                color: #fff;
            }
        }
    }
}

.giftcard-modal-title {
    font-family: "Lato", sans-serif;
    font-size: 21px;
    font-style: italic;
    font-weight: 600;
    color: $giftcard-blue;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.4;
}

.giftcard-modal-text {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    color: $giftcard-blue;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.4;

    &:last-child {
        margin-bottom: 0;
    }
}

.my-vouchers-title {
    font-family: "Lato", sans-serif;
    font-size: $font-size-medium;
    font-weight: 900;
    color: $brand-secondary;
    margin: 60px 0 15px;
}

.my-vouchers-table {
    width: 100%;
    border: 1px solid $giftcard-blue;

    tr {
        &:nth-child(4n + 1) {
            td {
                background: $giftcard-blue3;
                color: #fff;
            }
        }
    }

    th,
    td {
        height: 55px;
        width: 25%;
        vertical-align: middle;
        text-align: left;
        padding: 0 20px;
    }

    th {
        background: #718198;
        color: #fff;
    }

    td {
        border-top: 1px solid $giftcard-blue;
        border-bottom: 1px solid $giftcard-blue;
        color: $giftcard-blue;
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    .modal {
        &.giftcard-success-modal {
            .giftcard-header {
                margin-bottom: 25px;
                font-size: 27px;
                height: 38px;

                span {
                    font-size: 24px;
                }

                i {
                    top: -4px;
                    transform: translateX(58px);
                    font-size: 18px;
                }

                .giftcard-header-logo {
                    top: 24px;
                    transform: translateX(21px);
                    height: 10px;
                    width: 44px;

                    img {
                        height: 10px;
                    }
                }
            }

            .modal-content {
                width: 280px;
                padding: 20px;
            }
        }
    }

    .giftcard-modal-title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 25px;
    }

    .giftcard-modal-text {
        font-size: 14px;
        line-height: 15px;
    }

    .my-vouchers-title {
        margin: 35px 0 15px;
    }

    .my-vouchers-table th {
        font-size: 12px;
        line-height: 15px;
        padding: 0 10px;
    }

    .my-vouchers-table td {
        font-size: 12px;
        line-height: 12px;
        padding: 0 10px;
        height: 42px;
    }
}

@media #{$xsmall} {
    .modal {
        &.giftcard-success-modal {
            .giftcard-header {
                margin-bottom: 18px;
                font-size: 27px;
                height: 38px;

                span {
                    font-size: 24px;
                }

                i {
                    top: -4px;
                    transform: translateX(58px);
                    font-size: 18px;
                }

                .giftcard-header-logo {
                    top: 24px;
                    transform: translateX(21px);
                    height: 10px;
                    width: 44px;

                    img {
                        height: 10px;
                    }
                }
            }

            .modal-content {
                width: 280px;
                padding: 20px;
            }
        }
    }

    .giftcard-modal-title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 17px;
    }

    .giftcard-modal-text {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 15px;
    }

    .my-vouchers-title {
        margin-top: 15px;
    }

    .my-vouchers-table {
        th,
        td {
            font-size: 12px;
            line-height: 14px;
            padding: 0 10px;
        }

        th {
            height: 30px;
        }

        td {
            height: 26px;
            overflow: hidden;
            transition: height 0.25s ease-in-out;
            vertical-align: top;
            position: relative;

            .gift-cell-content {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 5px 0 5px 8px;

                .gift-cell-inside-header,
                .gift-cell-inside-content {
                    display: block;
                    transition: max-height 0.25s ease-in-out;
                    overflow: hidden;
                    max-height: 0;
                }

                .gift-cell-inside-header {
                    font-size: 10px;
                    line-height: 11px;
                    margin: 3px 0 2px 0;
                    font-weight: 600;
                }

                a {
                    position: absolute;
                    top: 5px;
                    right: 8px;
                    text-decoration: underline;
                    overflow: hidden;
                    transition: top 0.25s ease-in-out;

                    &:active,
                    &:visited,
                    &:hover {
                        color: inherit;
                        text-decoration: underline;
                    }

                    &.opened-link {
                        max-height: 0;
                    }
                }
            }
        }

        tr {
            &:nth-child(4n + 2) {
                td {
                    background: $giftcard-blue3;
                    color: #fff;

                    a {
                        color: #fff;
                    }
                }
            }

            &:nth-child(4n) {
                a {
                    color: $giftcard-blue3;
                }
            }

            &.open {
                td {
                    height: 52px;

                    .gift-cell-content {
                        a {
                            top: 31px;

                            &.closed-link {
                                max-height: 0;
                            }

                            &.opened-link {
                                max-height: 26px;
                            }
                        }

                        .gift-cell-inside-header,
                        .gift-cell-inside-content {
                            max-height: 13px;
                        }
                    }
                }
            }
        }
    }
}
