.collapsed {
    .be-ribbon {
        @apply h-0;
    }
}

.be-ribbon {
    @apply overflow-hidden w-full flex items-center common-transition;
    height: 45px;
    background: $banco-estado-color;
    padding-left: 320px;

    &.full-width {
        padding-left: 0;
    }

    .be-container {
        display: flex;
        max-width: 1155px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    span {
        color: #fff;

        &:nth-child(1) {
            font-family: "Lato", sans-serif;
            font-style: italic;
            font-size: 18px;
        }

        &:nth-child(2) {
            font-size: 18px;
            font-family: "Gotham-Book", sans-serif;
            position: relative;
            top: 1px;
        }
    }
}

.ac-tooltip-balloon {
    &.banco-estado-pw-tooltip {
        @apply text-white;
        background-color: $banco-estado-color;

        &:before {
            border: 10px solid $banco-estado-color;
            border-color: $banco-estado-color transparent transparent;
        }

        &.down {
            &:before {
                border-color: transparent transparent $banco-estado-color;
            }
        }

        &.left {
            &:before {
                border-color: transparent transparent transparent $banco-estado-color;
            }
        }
    }
}

#mainContentWrapper {
    &.register-page {
        &.banco-estado {
            .mdl-textfield--floating-label:not(.is-focused) {
                label {
                    color: #333;
                }
            }

            .js-input,
            select {
                border-color: $banco-estado-color;

                &.disabled {
                    opacity: 1;
                    background: $gray-light;
                    color: #333;
                }
            }

            .cb-title {
                a,
                a:visited,
                a:active {
                    color: $banco-estado-color;

                    &:hover {
                        color: $brand-secondary;
                    }
                }
            }

            .mdl-checkbox-wrapper {
                .mdl-checkbox {
                    .mdl-checkbox__box-outline {
                        border-color: $banco-estado-color;
                    }

                    &.is-upgraded,
                    &.is-checked {
                        .mdl-checkbox__box-outline {
                            border-color: $banco-estado-color;
                        }
                    }

                    &.is-checked {
                        .mdl-checkbox__tick-outline {
                            background: $banco-estado-color
                                url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCjwvc3R5bGU+DQo8cG9seWxpbmUgY2xhc3M9InN0MCIgcG9pbnRzPSIzMS42LDE1LjYgMTcuNywyOS41IDExLDIyLjggIi8+DQo8L3N2Zz4NCg==");
                        }
                    }
                }
            }
        }
    }

    &.login-page {
        .login-button-container {
            @apply flex justify-end;

            .rounded-secondary-btn {
                @apply mr-3;
            }
        }
    }
}

.radio-with-img {
    img {
        height: 11px;
    }
}

.banco-estado-login-benefits {
    font-family: "Lato", sans-serif;
    font-size: 22px;
    font-style: italic;
    text-align: center;
    color: #1e365d;
    max-width: 380px;
    margin: 40px auto 0;
    line-height: 1.2;
}

.banco-estado-login-benefits-banner {
    img {
        max-width: 100%;
    }
}

#mainContentWrapper {
    .booking-wrapper {
        &.banco-estado {
            .mdl-radio__outer-circle {
                border-color: $banco-estado-color;
            }

            .mdl-radio.is-checked {
                .mdl-radio__inner-circle {
                    background: $banco-estado-color;
                }
            }

            .js-input {
                border-color: $banco-estado-color;
                margin-bottom: 30px;

                &::placeholder {
                    color: $gray-light;
                }

                &.non-float {
                    padding-top: 10px;
                }
            }

            .be-label {
                display: block;
                font-family: "Lato", sans-serif;
                font-size: 18px;
                margin: 30px 0 10px;
            }
        }
    }
}

.booking-wrapper {
    header {
        &.login-image-container {
            justify-content: center;
            margin-bottom: 20px;

            img {
                height: 40px;

                &:nth-child(2) {
                    height: 33px;
                    margin-left: 30px;
                }
            }
        }
    }
}

.banco-estado-seats-taken,
.banco-estado-seats-taken-mobile {
    width: 100%;
    color: #1c355e;
    font-size: 15px;
    background: #f7c190;
    padding: 10px;
    border-radius: 5px;
    line-height: 1.2;
    margin-top: 30px;
}

.btn-boarding {
    &.selected {
        &.banco-estado {
            opacity: 1;
            background: #f7f7f7;
            color: $banco-estado-color;
            font-family: "Gotham-Book", sans-serif;
            font-size: 12px;
            border-color: $banco-estado-color;
        }
    }
}

.be-modal-border-box {
    border: 1px solid #293d6e;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
}

.be-modal-content-box {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 20px;
    color: $brand-secondary;
}

.be-modal-img {
    height: 55px;

    + span {
        margin-left: 20px;
    }
}

.be-modal-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.be-modal-link {
    margin: 10px 0 30px;
    line-height: 1.2;

    a {
        color: $brand-secondary;
        text-decoration: underline;
    }
}

.banco-estado-login {
    .js-input {
        border-color: $banco-estado-color;
    }

    .primary-btn {
        border-color: $banco-estado-color;
        background: $banco-estado-color;
        color: #fff;
        transition: none;

        &:not(.disabled):hover {
            border-color: $brand-secondary;
            background: $brand-secondary;
            color: #fff;
        }
    }

    .secondary-btn {
        border-color: $banco-estado-color;
        color: $banco-estado-color;

        &:not(.disabled):hover {
            border-color: $banco-estado-color;
            background: $banco-estado-color;
            color: #fff;
        }
    }
}

.banco-estado-login-footnote {
    font-family: "Lato", sans-serif;
    font-style: italic;
    font-size: 14px;
    text-align: center;
    color: #555759;
    margin-top: 15px;
}

.transaction-wrapper {
    &.banco-estado {
        header {
            &.transactions-header {
                background-color: $banco-estado-color;
            }
        }

        .js-circle-payment {
            &.js-icon {
                &.title-icon {
                    color: $banco-estado-color;
                }
            }
        }
    }
}

.priority-boarding-banco-estado-info {
    background: rgba($banco-estado-color, 0.5);
    color: $brand-secondary;
    padding: 15px;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    margin-top: 15px;
    border-radius: 10px;
}

.login-footer-image-container {
    margin-top: 30px;
    text-align: center;

    img {
        &:first-child {
            height: 18px;
            margin-right: 15px;
        }

        &:last-child {
            height: 15px;
        }
    }
}

.primary-btn {
    &.banco-estado-color {
        background: $banco-estado-color;
        border-color: $banco-estado-color;

        &:not(:disabled):hover {
            background: $brand-secondary;
            border-color: $brand-secondary;
        }
    }
}

.secondary-btn {
    &.banco-estado-color {
        color: $banco-estado-color;
        border-color: $banco-estado-color;
    }
}

.booking-wrapper {
    header {
        &.be-login-page-header {
            display: block;
            font-family: "Lato", sans-serif;
            font-size: 24px;
            font-style: italic;
            color: $brand-secondary;
            text-align: center;
            margin: 45px 0;
        }

        .main-title {
            &.banco-estado-color {
                color: #333;
            }
        }
    }
}

.register-page {
    h1 {
        &.banco-estado-color {
            color: #333;
        }
    }

    .birthdate-container {
        .input-inner-title {
            &.banco-estado-color {
                color: #333;
            }
        }
    }

    &.banco-estado {
        .js-select-arrow {
            &:after {
                color: #333;
            }
        }

        .js-input {
            color: #333;

            &.js-select {
                color: #333;
            }
        }
    }

    .banco-estado-register-breadcrumbs {
        width: 300px;
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &:after {
            content: "";
            height: 1px;
            border-bottom: 2px dashed #aaa;
            width: 300px;
            position: absolute;
            left: 0;
            top: 50%;
            z-index: -1;
        }

        span {
            width: 40px;
            height: 40px;
            background: #aaa;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 600;

            &:last-child {
                background: $banco-estado-color;
            }
        }
    }
}

.be-register-logo {
    margin: 60px 0 20px;

    img {
        max-width: 100%;
    }
}

.be-login-page-warning {
    background: rgba($banco-estado-color, 0.5);
    color: $brand-secondary;
    font-family: "Lato", sans-serif;
    font-size: 17px;
    line-height: 1.2;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 30px;
}

.be-login-page-pw-warning {
    background: rgba($banco-estado-color, 0.5);
    color: $brand-secondary;
    font-family: "Lato", sans-serif;
    font-size: 17px;
    line-height: 1.2;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 10px;

    span {
        font-weight: 600;
    }
}

.postreg-greeting {
    margin: 60px 0 45px;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: #333;
    line-height: 1;

    span {
        color: $banco-estado-color;
        font-weight: 700;
    }
}

.postreg-text {
    margin-bottom: 45px;
    font-family: "Lato", sans-serif;
    font-size: 36px;
    font-style: italic;
    color: #333;
    line-height: 1;
}

.postreg-img-container {
    margin-bottom: 70px;

    img {
        &:first-child {
            height: 55px;
            margin-right: 40px;
        }

        &:last-child {
            height: 45px;
        }
    }
}

.postreg-banner-plane {
    max-width: 100%;
    margin-bottom: 30px;
}

.postreg-button-container {
    @apply flex justify-center;
}

.banco-estado-breakdown-discount {
    margin: 10px -25px;
    padding: 0 25px 14px;
    background: $banco-estado-color;
}

.be-breakdown-label {
    font-family: "Gotham-Book";
    font-size: 12px;
}

.login-container {
    li {
        > a {
            span {
                &.be-header-username {
                    white-space: normal;
                    line-height: 0.9;
                }

                &.smaller {
                    margin-top: 0 !important;
                }
            }
        }

        &.be-pointer-5 {
            width: 300px;

            &:after {
                @apply absolute bg-be-orange;
                content: "";
                width: 18px;
                height: 18px;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -5px) rotate(45deg);
                transition: all 0.25s ease-in-out;
            }

            &.opened {
                &:after {
                    display: none;
                }
            }

            &.pointer-squash {
                &:after {
                    transform: translate(-50%, -7px) rotate(45deg) scale(0);
                }
            }
        }

        &.be-pointer-6 {
            width: 300px;

            &:after {
                @apply absolute bg-be-gray;
                content: "";
                width: 18px;
                height: 18px;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -5px) rotate(45deg);
                transition: all 0.25s ease-in-out;
            }

            &.opened {
                &:after {
                    display: none;
                }
            }

            &.pointer-squash {
                &:after {
                    transform: translate(-50%, -7px) rotate(45deg) scale(0);
                }
            }
        }
    }
}

.modal-content {
    &.upgrade-modal {
        &.flight-modal-content {
            &.upgrade-membership-banco-estado {
                min-height: 480px;

                > button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(50%, -50%);
                    border: none;
                    background: black;
                    color: white;
                    font-size: 36px;
                    cursor: pointer;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    font-weight: normal;
                    z-index: 1;
                    letter-spacing: unset;
                    line-height: 1;

                    &:hover {
                        border: none;
                        color: $brand-secondary;
                        text-decoration: none;
                    }
                }

                .flight-modal-sidebar {
                    background: #464547 url("../images/dc-logo-2-be.png") no-repeat center center;
                }

                .modal-header {
                    margin-bottom: 25px;
                }

                .membership-modal-banner {
                    background: #464547;
                    white-space: normal;
                    line-height: 1.4;
                    padding: 15px;
                    font-weight: 500;

                    span {
                        display: block;
                    }
                }

                .upgrade-info {
                    max-width: 635px;
                    text-align: left;

                    span:not(:first-child) {
                        font-style: italic;
                        font-weight: 600;
                    }
                }

                .upgrade-banco-estado-info {
                    font-family: "Lato", sans-serif;
                    font-size: 16px;
                    color: #183264;
                    margin: 15px auto;
                    max-width: 640px;
                    line-height: 1.4;

                    span {
                        font-weight: 600;
                        font-style: italic;
                    }
                }

                .modal-button-container {
                    &.upgrade-buttons {
                        .primary-btn {
                            padding-left: 30px;
                            letter-spacing: normal;

                            span {
                                font-size: 14px;
                                font-weight: 900;
                            }
                        }

                        .secondary-btn {
                            border: none;
                            border-bottom: 1px solid #183264;
                            border-radius: 0;
                            padding: 0;
                            height: unset;
                            margin-right: 45px;
                            font-weight: 500;
                            letter-spacing: normal;

                            i {
                                display: none;
                            }

                            &:hover {
                                background: transparent;
                                border-bottom-color: transparent;
                                color: $brand-primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-content {
    &.membership-modal {
        &.flight-modal-content {
            &.get-membership-banco-estado {
                min-height: 550px;

                &.taller {
                    min-height: 610px;
                }

                > button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(50%, -50%);
                    border: none;
                    background: black;
                    color: white;
                    font-size: 36px;
                    cursor: pointer;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    font-weight: normal;
                    z-index: 1;
                    letter-spacing: unset;
                    line-height: 1;

                    &:hover {
                        border: none;
                        color: $brand-secondary;
                        text-decoration: none;
                    }
                }

                .flight-modal-sidebar {
                    background: #464547 url("../images/dc-logo-2-be.png") no-repeat center center;
                }

                .modal-header {
                    margin-bottom: 25px;
                }

                .membership-modal-banner {
                    background: #464547;
                    white-space: normal;
                    line-height: 1.4;
                    padding: 15px 60px 15px 100px;
                    font-weight: 500;
                }

                .modal-button-container {
                    &.get-membership {
                        .primary-btn {
                            width: 191px;
                            height: 32px;
                            padding: 0 45px 0 20px;
                            letter-spacing: normal;

                            span {
                                font-size: 14px;
                                font-weight: 900;
                            }

                            &.disabled {
                                background: #b7b3b3;
                                opacity: 1;
                                border-color: #b7b3b3;
                            }
                        }

                        .secondary-btn {
                            border: none;
                            border-bottom: 1px solid #183264;
                            border-radius: 0;
                            padding: 0;
                            height: unset;
                            margin-right: 45px;
                            font-weight: 500;
                            letter-spacing: normal;

                            i {
                                display: none;
                            }

                            &:hover {
                                @apply bg-transparent;
                                border-bottom-color: transparent;
                                color: $brand-primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

.banco-estado-select-membership {
    @apply font-body italic;
    font-size: 20px;
    color: #2a3c66;
    text-align: center;
    margin-bottom: 25px;
}

.login-container {
    li {
        > a {
            span {
                &.banco-estado-phase-2-header-username {
                    @apply m-0 flex items-center font-body font-black text-be-gray-3;
                    font-size: 16px;

                    &.category-5 {
                        @apply text-be-orange;

                        i {
                            @apply text-be-orange;
                        }
                    }

                    img {
                        margin-right: 10px;
                        width: 40px;
                    }

                    i {
                        @apply font-black text-be-gray-3;
                        margin-left: 10px;
                        font-size: 15px;
                        transition: all 0.25s ease-in-out;

                        &.point-down {
                            transform: rotate(-90deg);
                        }

                        &.point-up {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        &:hover {
            &[class^="be-pointer"] {
                &:not(.opened) {
                    background: inherit;
                }
            }
        }
    }
}

.be2-baggage-info {
    @apply inline-flex items-center border border-solid border-be-cyan-2 font-body font-semibold text-be-cyan-2;
    border-radius: 10px;
    padding: 8px 15px;
    font-size: 13px;
    margin-bottom: 15px;

    i {
        @apply text-be-cyan-2;
        font-size: 20px;
        margin-right: 8px;
    }
}

/* MEDIA QUERIES */

@media #{$medium-down} {
    .banco-estado-seats-taken-mobile {
        @apply fixed mt-0 rounded-none;
        bottom: 42px;
        z-index: 10000;
        left: 390px;
        width: calc(100% - 456px);
    }
}

@media #{$small} {
    .btn-boarding {
        &.selected {
            &.banco-estado {
                font-size: 11px;
            }
        }
    }

    .modal-content {
        &.membership-modal {
            &.flight-modal-content {
                &.get-membership-banco-estado {
                    min-height: 440px;

                    &.taller {
                        min-height: 500px;
                    }

                    .flight-modal-sidebar {
                        background-size: 90%;
                    }

                    .modal-header {
                        margin-bottom: 5px;
                    }

                    .membership-modal-banner {
                        padding: 15px 30px 15px 40px;
                    }
                }
            }
        }
    }

    .modal-content {
        &.upgrade-modal {
            &.flight-modal-content {
                &.upgrade-membership-banco-estado {
                    min-height: 420px;

                    .flight-modal-sidebar {
                        background-size: 90%;
                    }

                    .upgrade-info {
                        max-width: 425px;
                    }

                    .modal-button-container {
                        &.upgrade-buttons {
                            .primary-btn {
                                width: 127px;
                                padding-left: 10px;
                                border-radius: 16px;

                                span {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .login-container {
        li {
            > a {
                span {
                    &.banco-estado-phase-2-header-username {
                        @apply justify-center;
                    }
                }
            }
        }
    }

    .be2-baggage-info {
        @apply bg-be-cyan-2 text-white;
        font-size: 10px;
        padding: 5px 10px;

        i {
            @apply text-white;
            font-size: 15px;
        }
    }
}

@media #{$small-down} {
    .banco-estado-seats-taken-mobile {
        @apply left-0 w-full;
    }

    .content-wrapper {
        #mainContentWrapper {
            &.content-push-down {
                margin-top: 0;
            }
        }
    }

    .be-ribbon {
        padding-left: 0;

        span {
            &:nth-child(1),
            &:nth-child(2) {
                font-size: 15px;
            }
        }
    }

    .main-header {
        .login-container {
            &:not(.unlogged) {
                li,
                li:hover {
                    > a,
                    > a:hover {
                        span {
                            &.be-header-username {
                                color: $banco-estado-color;
                            }
                        }
                    }
                }
            }
        }
    }

    .banco-estado-login-benefits-banner {
        text-align: center;
    }

    .booking-wrapper {
        header {
            &.be-login-page-header {
                margin: 0 0 20px;
            }
        }
    }
}

@media #{$xsmall} {
    #mainContentWrapper {
        &.login-page {
            .login-button-container {
                @apply flex-col;

                .rounded-primary-btn {
                    padding-right: 7px;
                }

                .rounded-secondary-btn {
                    @apply mb-3 mr-0;
                }
            }
        }
    }

    .be-ribbon {
        .be-container {
            @apply block;
        }

        span {
            &:nth-child(2) {
                top: 0;
                left: -5px;
            }
        }
    }

    .modal-content {
        &.upgrade-modal {
            &.flight-modal-content {
                &.upgrade-membership-banco-estado {
                    height: 570px;
                    max-width: 90%;

                    .flight-modal-sidebar {
                        height: 120px;
                        background-size: auto 90px;
                    }

                    .flight-scroller {
                        top: 120px;
                    }

                    .membership-modal-banner {
                        font-size: 14px;
                    }

                    .modal-header {
                        margin-bottom: 5px;
                    }

                    .modal-button-container {
                        &.padded {
                            @apply flex flex-col-reverse;

                            .primary-btn,
                            .secondary-btn {
                                width: 176px;
                            }

                            .primary-btn {
                                font-size: 14px;
                                padding-left: 14px;
                            }

                            .secondary-btn {
                                @apply flex items-center font-body font-black leading-none;
                                height: 32px;
                                padding: 0 40px 0 15px;
                                border: 1px solid #7287a0;
                                border-radius: 13px;
                                font-size: 12px;
                                margin: 5px 0;

                                span {
                                    @apply font-black;
                                }

                                i {
                                    @apply inline-block;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .modal-content {
        &.membership-modal {
            &.flight-modal-content {
                &.get-membership-banco-estado {
                    max-width: 90%;
                    min-height: 610px;

                    &.taller {
                        min-height: 650px;
                    }

                    .flight-modal-sidebar {
                        height: 110px;
                        background-size: auto 80px;
                    }

                    .flight-scroller {
                        top: 110px;
                    }

                    .membership-modal-banner {
                        display: none;
                    }

                    .modal-header {
                        margin-bottom: 0;
                    }

                    .banco-estado-select-membership {
                        display: none;
                    }

                    .membership-btn {
                        span {
                            &:first-child {
                                line-height: 1.5;
                            }

                            &:nth-child(2) {
                                line-height: 1.2;
                            }
                        }
                    }

                    .modal-button-container {
                        &.padded {
                            @apply flex flex-col-reverse;

                            .primary-btn,
                            .secondary-btn {
                                width: 176px;
                            }

                            .primary-btn {
                                font-size: 14px;
                                padding-left: 14px;
                            }

                            .secondary-btn {
                                @apply flex items-center font-body font-black leading-none;
                                height: 32px;
                                padding: 0 40px 0 15px;
                                border: 1px solid #7287a0;
                                border-radius: 13px;
                                font-size: 12px;
                                margin: 5px 0;

                                span {
                                    @apply font-black;
                                }

                                i {
                                    @apply inline-block;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .be2-baggage-info {
        @apply w-full bg-be-cyan-2 text-white;
        font-size: 11px;
        padding: 10px 20px;
        margin: 20px 0;
        line-height: 1.2;

        i {
            @apply text-white;
        }
    }

    .be-modal-img {
        height: 45px;
    }

    .be-modal-border-box {
        padding: 12px;
        margin: 15px;
    }

    .be-modal-content-box {
        padding: 12px;
    }

    .be-modal-link {
        margin: 10px 0 20px;
    }
}
