.i2-itinerary-section {
    &.i2-cabin-bags,
    &.i2-checked-bags {
        @apply relative;
        border-radius: 22px;
        height: 253px;
        padding: 46px 0 0 387px;

        img {
            @apply block absolute top-0 left-0 bottom-0;
            max-height: 100%;
        }

        header {
            @apply italic text-be-cyan-5;
            max-width: 510px;
            font-family: "ClanOT-Black", sans-serif;
            font-size: 28px;
            line-height: 34px;
        }

        article {
            @apply italic text-be-blue;
            font-family: "ClanOT-Black", sans-serif;
            font-size: 19px;
            line-height: 28px;
            margin-bottom: 25px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-itinerary-section {
        &.i2-cabin-bags,
        &.i2-checked-bags {
            height: 200px;
            padding: 20px 0 0 290px;

            header {
                font-size: 26px;
            }
        }
    }
}

@media #{$medium} {
    .i2-itinerary-section {
        &.i2-cabin-bags,
        &.i2-checked-bags {
            height: 150px;
            padding: 30px 0 0 230px;

            header {
                max-width: 315px;
                font-size: 17px;
                line-height: 20px;
            }

            article {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 10px;
            }
        }
    }
}

@media #{$small} {
    .i2-itinerary-section {
        &.i2-cabin-bags,
        &.i2-checked-bags {
            height: 150px;
            padding: 30px 0 0 230px;

            header {
                max-width: 315px;
                font-size: 17px;
                line-height: 20px;
            }

            article {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 10px;
            }
        }
    }
}

@media #{$xsmall} {
    .i2-itinerary-section {
        &.i2-cabin-bags {
            height: 145px;
            padding: 10px 20px 0 140px;
            border-radius: 5px;

            header {
                font-size: 11px;
                line-height: 13px;
                margin-bottom: 3px;
            }

            article {
                font-size: 10px;
                line-height: 12px;
                margin-bottom: 10px;
            }
        }

        &.i2-checked-bags {
            height: 126px;
            padding: 10px 20px 0 140px;
            border-radius: 5px;

            header {
                @apply mb-0;
                font-size: 11px;
                line-height: 13px;
            }

            article {
                font-size: 10px;
                line-height: 12px;
                margin-bottom: 10px;
            }
        }
    }
}
