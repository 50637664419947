.pcra-low-balance-modal-content {
    @apply p-0 overflow-hidden;
    width: 500px;
    height: 285px;
    max-height: 95%;
    max-width: 95%;
    border-radius: 20px;
}

.pcra-low-balance-modal-header {
    @apply bg-new-ui-cyan-2 text-white font-bold flex items-center justify-center relative;
    height: 60px;
    font-size: 25px;

    .modal-header-closing {
        @apply absolute font-body rounded-full flex items-center justify-center border-none text-white vertical-center font-black;
        right: 15px;
        width: 30px;
        height: 30px;
        padding: 0 0 1px 1px;
        font-size: 24px;
        background: #484848;
        letter-spacing: normal;

        &:hover {
            @apply bg-white;
            color: #484848;
        }
    }
}

.pcra-low-balance-modal-body {
    @apply flex flex-col items-center justify-between leading-6 text-brand-secondary font-bold;
    padding: 24px;
    height: 165px;
    text-align: center;
}

.pcra-low-balance-modal-footer {
    @apply flex justify-center;
    height: 60px;

    .rounded-primary-btn {
        height: 42px;
    }
}

/* MEDIA QUERIES */

@media #{$xsmall} {
    .pcra-low-balance-modal-footer {
        .rounded-primary-btn {
            height: 39px;
        }
    }
}
