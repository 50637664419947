.family-member-selector-modal {
    @apply relative;

    &.modal-content {
        @apply p-0;
        width: 880px;
        max-width: 95%;
        height: 620px;
        max-height: 95%;
    }

    .modal-header {
        @apply bg-be-blue text-white relative font-body font-black flex items-center justify-center;
        height: 136px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-size: 40px;

        &:after {
            @apply absolute bottom-0 left-0 right-0 bg-be-red;
            content: "";
            height: 5px;
        }

        .modal-header-closing {
            @apply absolute flex items-center justify-center rounded-full border-solid border-white text-white p-0 m-0 z-10 bg-transparent;
            width: 40px;
            height: 40px;
            border-width: 3px;
            font-size: 30px;
            top: 20px;
            right: 20px;

            &:hover {
                @apply bg-white text-be-blue;
            }
        }

        i {
            @apply text-be-cyan-2 font-normal absolute vertical-center;
            left: 2.5rem;
            font-size: 80px;
        }
    }

    .modal-body {
        padding: 35px 70px 0;
    }

    .family-member-filter {
        @apply relative;
        width: 392px;
        margin-bottom: 30px;

        input {
            height: 53px;
            border-radius: 5px;
        }

        i {
            @apply absolute vertical-center pointer-events-none;
            font-size: 28px;
            right: 20px;
            color: #072240;
        }
    }

    .family-member-selector-modal-buttons {
        @apply flex justify-between;
        margin-top: 60px;
    }

    dc-table {
        .dc-table {
            th {
                @apply bg-be-blue;
                font-size: 19px;
            }

            td {
                @apply text-be-blue px-8;
                font-size: 16px;

                // TODO What the .. is this? :-)
                &:not([class^="p-"]):not([class*=" p-"]) {
                    &:first-child,
                    &:last-child {
                        @apply px-8;

                        // TODO What the .. is this? :-)
                        > div {
                            @apply p-0;
                        }
                    }
                }
            }

            tr {
                // TODO What the .. is this? :-)
                &:first-child,
                &:last-child {
                    td:not([class^="p-"]):not([class*=" p-"]) {
                        &:first-child,
                        &:last-child {
                            @apply px-8;

                            // TODO What the .. is this? :-)
                            > div {
                                @apply p-0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .family-member-selector-modal {
        .modal-header {
            height: 136px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 40px;

            &:after {
                height: 5px;
            }

            .modal-header-closing {
                width: 40px;
                height: 40px;
                border-width: 3px;
                font-size: 30px;
                top: 20px;
                right: 20px;
            }

            i {
                font-size: 80px;
            }
        }

        .modal-body {
            padding: 35px 70px 0;
        }

        .family-member-filter {
            width: 392px;
            margin-bottom: 30px;

            input {
                height: 53px;
                border-radius: 5px;
            }

            i {
                font-size: 28px;
                right: 20px;
            }
        }

        .family-member-selector-modal-buttons {
            margin-top: 60px;
        }

        dc-table {
            .dc-table {
                th {
                    height: 50px;
                    font-size: 19px;
                }

                td {
                    @apply px-8;
                    height: 50px;
                    font-size: 16px;

                    // TODO What the .. is this? :-)
                    &:not([class^="p-"]):not([class*=" p-"]) {
                        &:first-child,
                        &:last-child {
                            @apply px-8;

                            // TODO What the .. is this? :-)
                            > div {
                                @apply p-0;
                            }
                        }
                    }
                }

                tr {
                    // TODO What the .. is this? :-)
                    &:first-child,
                    &:last-child {
                        td:not([class^="p-"]):not([class*=" p-"]) {
                            &:first-child,
                            &:last-child {
                                @apply px-8;

                                // TODO What the .. is this? :-)
                                > div {
                                    @apply p-0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media #{$small} {
    .family-member-selector-modal {
        &.modal-content {
            height: 615px;
        }

        .modal-header {
            height: 122px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 30px;

            &:after {
                height: 5px;
            }

            .modal-header-closing {
                width: 40px;
                height: 40px;
                border-width: 3px;
                font-size: 30px;
                top: 20px;
                right: 20px;
            }

            i {
                font-size: 60px;
            }
        }

        .modal-body {
            padding: 35px 25px 0;
        }

        .family-member-filter {
            width: 392px;
            margin-bottom: 30px;

            input {
                height: 53px;
                border-radius: 5px;
            }

            i {
                font-size: 28px;
                right: 25px;
            }
        }

        .family-member-selector-modal-buttons {
            margin-top: 60px;
        }

        dc-table {
            .dc-table {
                th {
                    height: 50px;
                    font-size: 19px;
                }

                td {
                    @apply px-8;
                    height: 50px;
                    font-size: 16px;

                    // TODO What the .. is this? :-)
                    &:not([class^="p-"]):not([class*=" p-"]) {
                        &:first-child,
                        &:last-child {
                            @apply px-8;

                            // TODO What the .. is this? :-)
                            > div {
                                @apply p-0;
                            }
                        }
                    }
                }

                tr {
                    // TODO What the .. is this? :-)
                    &:first-child,
                    &:last-child {
                        td:not([class^="p-"]):not([class*=" p-"]) {
                            &:first-child,
                            &:last-child {
                                @apply px-8;

                                // TODO What the .. is this? :-)
                                > div {
                                    @apply p-0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .family-member-selector-modal {
        &.modal-content {
            height: 570px;
        }

        .modal-header {
            @apply leading-none;
            height: 122px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 24px;
            padding: 0 60px 0 120px;

            &:after {
                height: 5px;
            }

            .modal-header-closing {
                width: 35px;
                height: 35px;
                border-width: 2px;
                font-size: 26px;
                top: 10px;
                right: 10px;
            }
            i {
                font-size: 60px;
            }
        }

        .modal-body {
            padding: 15px 15px 0;
        }

        .family-member-filter {
            @apply w-full;
            margin-bottom: 15px;

            input {
                height: 53px;
                border-radius: 5px;
            }

            i {
                font-size: 28px;
                right: 30px;
            }
        }

        .family-member-selector-modal-buttons {
            margin-top: 15px;
        }

        dc-table {
            .dc-table-container {
                @apply overflow-hidden;
            }

            .dc-table {
                @apply w-full;

                th {
                    @apply whitespace-normal leading-none;
                    height: 50px;
                    font-size: 14px;
                }

                td {
                    @apply px-4 whitespace-normal leading-none;
                    height: 50px;
                    font-size: 14px;

                    // TODO What the .. is this? :-)
                    &:not([class^="p-"]):not([class*=" p-"]) {
                        &:first-child,
                        &:last-child {
                            @apply px-4;

                            // TODO What the .. is this? :-)
                            > div {
                                @apply p-0;
                            }
                        }
                    }
                }

                tr {
                    // TODO What the .. is this? :-)
                    &:first-child,
                    &:last-child {
                        td:not([class^="p-"]):not([class*=" p-"]) {
                            &:first-child,
                            &:last-child {
                                @apply px-4;

                                // TODO What the .. is this? :-)
                                > div {
                                    @apply p-0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
