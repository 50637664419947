.seatmap-pax-journey-container {
    @apply bg-white overflow-hidden relative;
    padding: 0 16px 16px;
    border: 1px solid $gray-light;
    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
}

.seatmap-pax-container {
    @apply border border-solid border-new-ui-blue overflow-hidden common-transition;
    border-radius: 5px;
    padding: 0 3px;
    margin: 16px 0;
    max-height: 46px;

    &.open {
        max-height: 270px;
    }
}

.seatmap-pax-scroller {
    @apply overflow-hidden relative;
    padding: 0 7px;
    max-height: 220px;
}

.seatmap-pax-route {
    @apply flex items-center relative;
    margin: 10px 7px;
}

.seatmap-pax-plane {
    @apply text-new-ui-blue;
    margin-right: 10px;
    font-size: 18px;
}

.seatmap-pax-flight-number {
    @apply bg-new-ui-blue text-white font-black font-body leading-none rounded flex items-center;
    margin-right: 10px;
    height: 26px;
    font-size: 12px;
    padding: 1px 5px;
}

.seatmap-pax-station {
    @apply font-body text-new-ui-blue flex items-center flex-wrap leading-none;
    width: max-content;
    margin-right: 10px;
    font-size: 12px;

    span {
        @apply font-bold text-new-ui-blue whitespace-nowrap;
    }
}

.seatmap-pax-opener {
    @apply text-new-ui-blue absolute vertical-center common-transition;
    right: 5px;
    font-size: 22px;
}

.open {
    .seatmap-pax-opener {
        transform: translateY(-50%) rotate(90deg);
    }
}

ac-seat-passenger,
ac-banco-estato-56-seat-passenger {
    @apply block;
    margin-bottom: 10px;
}

.seat-pax {
    @apply flex items-center text-brand-secondary;

    label {
        @apply absolute font-black;
        top: 5px;
        left: 8px;
        font-size: 12px;
    }

    .subsection {
        @apply absolute border border-solid border-white bottom-0 text-white;
        min-width: 65px;
        max-width: 65px;
        max-height: 30px;
        min-height: 30px;
        top: 16%;
        right: 14px;
        border-radius: 5px;

        &.category-1 {
            @apply bg-seatmap-color1;
        }

        &.category-2 {
            @apply bg-seatmap-color2;
        }

        &.category-3 {
            @apply bg-seatmap-color3;
        }

        &.category-4 {
            @apply bg-seatmap-color4;
        }

        &.category-5 {
            @apply bg-seatmap-color5;
        }

        &.category-1,
        &.category-2,
        &.category-3,
        &.category-4,
        &.category-5 {
            &.banco-estado-color {
                @apply bg-be-old-orange;
            }
        }
    }

    .new-be-seat {
        @apply border-be-gray-3 border border-solid w-full flex items-end justify-between cursor-pointer;
        height: 56px;
        border-radius: 10px;

        &:hover {
            @apply bg-be-light-gray;
        }

        &.selected {
            @apply bg-be-blue text-white cursor-default;

            &:hover {
                @apply bg-be-blue text-white;
            }

            .nbs-name {
                @apply text-white;
            }
        }
    }
}

.seat-number {
    @apply flex items-center justify-center absolute vertical-center;
    right: 27px;
    width: 16px;
    height: 16px;
    font-size: 14px;
    padding-right: 15px;

    &.condensed {
        @apply pr-0 all-center;
    }
}

.delete-seat {
    @apply flex items-center justify-center content-center absolute vertical-center border border-solid border-brand-secondary rounded-full cursor-pointer font-black;
    right: 5px;
    width: 16px;
    height: 16px;
    font-size: 12px;

    &:hover {
        @apply bg-brand-primary border-brand-primary text-white;
    }
}

.seat-pax-name {
    @apply relative inline-flex border border-solid border-brand-secondary overflow-hidden whitespace-nowrap cursor-pointer font-bold items-center;
    flex: 1;
    padding: 5px 8px;
    border-radius: 5px;
    height: 45px;
    text-overflow: ellipsis;
    font-size: 14px;

    &:hover {
        @apply bg-brand-primary text-white;
    }

    &.selected {
        @apply bg-brand-secondary text-white;
    }

    &.non-selectable {
        @apply bg-white cursor-default;

        &:hover {
            @apply bg-white text-brand-secondary;
        }
    }
}

.seatmap-journey-price {
    @apply flex items-center justify-center relative border border-solid border-brand-secondary font-bold text-brand-secondary bg-white w-full;
    height: 33px;
    margin-top: 10px;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
}

.mobile-passenger-container,
.mobile-passengers,
.mobile-seat {
    display: none;
}

.seatmap-sidepanel-button-container{
    @apply w-full flex items-center justify-between mt-4;
}

/* MEDIA QUERIES */

@media #{$large} {
    .seatmap-pax-flight-number {
        font-size: 10px;
    }

    .seat-pax {
        @apply block;
    }

    .seat-pax-name {
        @apply w-full;
    }
}
