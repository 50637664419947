.register-terms-text {
    @apply relative;

    text-align: left;
    font-size: 14px;

    a {
        @apply underline text-new-ui-cyan;
    }

    a:hover {
        color: #9c3434;
    }
}
