.mdl-ripple is-animating {
    height: 26px;
    width: 115px;
}

select.mdl-textfield__input {
    background-size: 24px;
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
    appearance: none;
    outline: none;
    border: 1px solid $brand-secondary;
    color: $brand-secondary;
    font-size: 14px;
    border-radius: $border-radius-sm;

    &.placeholder {
        color: $gray-light;
    }
}

.mdl-textfield {
    width: 100%;
}

.mdl-textfield__input {
    display: block;
    width: 100%;
    margin: 0;
    padding: 4px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 16px;
    background: #fff;
    text-align: left;
    color: inherit;

    &.placeholder {
        color: rgba(0, 0, 0, 0.26);

        option {
            color: $text-color;
        }
    }
}

.material-icons {
    z-index: 99 !important;
}
