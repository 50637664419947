.seat-categories-container {
    @apply flex flex-col w-full relative mb-5;
    padding: 15px;
    border-radius: 10px;
    background: #f7f7f7;

    input[type="checkbox"] {
        display: none;
    }

    [type="checkbox"] + label[for] {
        @apply p-0 cursor-default h-5;

        font-family: Lato, sans-serif;
        color: #163a70;
        font-weight: 900;
        font-size: 18px;
        line-height: 20px;

        &:before {
            display: none;
        }
    }

    .open-icon {
        @apply absolute;
        display: none;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        i {
            @apply inline-block;
            font-size: 28px;
            transition: all $normal-accordion-delay;
        }
    }
}

.seat-categories {
    @apply grid grid-cols-3 gap-4;
    margin-top: 25px;
}

.seat-category {
    @apply flex items-center p-2;
    background: white;
    border-radius: 10px;
    min-height: 60px;

    &.category-1 {
        color: #263f6a;
    }
    &.category-2 {
        color: #00abc8;
    }
    &.category-3 {
        color: #2b7ea3;
    }
    &.category-4 {
        color: #263f6a;
    }
    &.category-5 {
        color: #61d6e5;
    }
    &.category-disabled {
        color: #bdbdbd;
    }
}

.seat-categories-icon-container {
    @apply flex justify-center items-center;
    i {
        font-size: 30px;
    }
}

.category-description {
    @apply flex flex-col justify-center ml-1;

    span {
        @apply font-bold;
        line-height: 12px;
        font-size: 11.5px;
        white-space: nowrap;
    }
}

.category-price {
    span {
        @apply font-bold;
        font-size: 10px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .category-description {
        margin-left: 0.3rem;

        span {
            line-height: 11px;
            font-size: 10.5px;
            white-space: normal;
        }
    }

    .category-price {
        span {
            line-height: 9px;
            font-size: 9px;
            font-weight: 500;
            white-space: nowrap;
        }
    }
}

@media #{$medium-down} {
    .seat-categories-label {
        @apply w-full;
        text-align: center;
    }

    .seat-categories-container {
        @apply mt-5;
    }

    .seat-categories-icon-container {
        i {
            font-size: 32px;
        }
    }

    .category-description {
        margin-left: 0.75rem;

        span {
            font-size: 13px;
        }
    }

    .category-price {
        span {
            font-size: 11px;
        }
    }
}

@media #{$xsmall} {
    .seat-categories-container {
        .open-icon {
            @apply inline-block;
        }

        input[type="checkbox"] {
            @apply inline;
            opacity: 0;
            margin-left: -10px;

            &:not(:checked) {
                @apply p-0;

                ~ .seat-categories {
                    @apply mt-0;
                }
            }

            &:checked {
                ~ .seat-categories {
                    max-height: 287px;
                }

                ~ label {
                    &:after {
                        transform: translateY(-50%) rotate(45deg);
                    }
                }

                ~ label .open-icon {
                    i {
                        -moz-transform: rotate(90deg);
                        -o-transform: rotate(90deg);
                        -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
                        transition: all $normal-accordion-delay;
                    }
                }
            }
        }
    }

    .seat-categories {
        @apply grid-cols-2 overflow-hidden common-transition;
        max-height: 0;
    }

    .seat-categories-label {
        text-align: left;
    }

    .seat-categories-icon-container {
        i {
            font-size: 31px;
        }
    }

    .category-description {
        margin-left: 0.3rem;

        span {
            font-size: 11px;
        }
    }
    .category-price {
        span {
            font-size: 10px;
        }
    }
}
