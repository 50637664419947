$bundleGray: #d9d9d9;
$bundleBlue: #1c355e;
$bundleLightBlue: #00abc8;
$bundleRed: #a61b27;
$bundleGold: #ffa400;
$bundleGreen: #73b84a;
$bundleOrange: #ff8000;
$bundleLightOrange: #ffa400;

.bundles-header {
    width: 1005px;
    max-width: 95%;
    margin: 0 auto 30px;
}

.bundles-title {
    margin-bottom: 10px;
    padding: 0;
    color: $bundleBlue;
    font-family: "ClanOT-Bold", sans-serif;
    font-size: 22px;
    line-height: 1;

    img {
        position: relative;
        top: 5px;
    }
}

.bundles-title-info {
    font-family: "Lato", sans-serif;
    color: $bundleBlue;
    line-height: 1;
    font-size: 15px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0;
}

.bundles-container {
    width: 1005px;
    max-width: 95%;
    margin: 0 auto 30px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    &.old {
        @apply flex-col;
    }

    .bundle-container {
        transition: transform 0.25s ease-in-out;

        &:hover {
            transform: scale(1.025);
            box-shadow: $box-shadow-medium;
        }
    }
}

.selected-bundle-container {
    transition: all 0.25s ease-in-out;

    &.reduced {
        transform: scale(0.01);
    }
}

.bundle-container,
.selected-flight .bundle-container,
.selected-flight .selected-bundle-container {
    width: 32%;
    border: 2px solid $bundleGray;
    border-radius: 25px;
    position: relative;

    &.selected {
        &:after {
            content: "";
            position: absolute;
            top: -15px;
            left: -15px;
            right: -15px;
            bottom: -15px;
            border: 4px solid $bundleGray;
            border-radius: 40px;
            z-index: -1;
        }
    }

    ul {
        li {
            position: relative;
            height: 50px;
            margin: 0 15px;
            padding: 0 10px;
            font-family: "Lato", sans-serif;
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: auto;
            border: none;
            border-bottom: 1px solid $bundleGray;
            line-height: 1;
            cursor: pointer;

            &:hover {
                .bundle-tooltip {
                    display: flex;
                }
            }

            &.unavailable {
                color: $bundleGray;

                i {
                    color: $bundleGray;
                }

                .ssr-line {
                    .ssr-line-name {
                        color: $bundleGray;
                    }
                }
            }

            &:last-child {
                border-bottom: none;
            }

            i {
                font-size: 18px;
                font-weight: 600;
            }

            .bundle-tooltip {
                display: none;
                align-items: center;
                justify-content: flex-start;
                position: absolute;
                bottom: 36px;
                left: 50%;
                transform: translateX(-50%);
                font-family: "Lato", sans-serif;
                font-size: 12px;
                color: $bundleBlue;
                background: #ececec;
                border: 1px solid $bundleBlue;
                border-radius: 10px;
                padding: 5px;
                z-index: 10;
                line-height: 1.4;
                min-width: 200px;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -16px;
                    left: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    width: 15px;
                    height: 15px;
                    border-bottom: 1px solid $bundleBlue;
                    border-right: 1px solid $bundleBlue;
                    background: #ececec;
                }

                h3 {
                    color: $bundleBlue;
                    font-weight: 900;
                    font-family: "Lato", sans-serif;
                    font-size: 14px;
                    white-space: normal;
                }

                i {
                    font-size: 45px;
                    color: $bundleBlue;
                    margin-right: 10px;
                    font-weight: 400;
                }
            }
        }
    }

    .line-container {
        position: relative;
        height: 2px;
        margin: 15px 0 25px;

        .line {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to right, $bundleGray 50%, transparent 50%);
            background-size: 38px 100%;
        }
    }

    i {
        &.js-bundle-circle-x-full {
            @apply text-brand-primary opacity-75;
        }

        &.js-bundle-circle-x {
            @apply text-be-gray-4;
        }

        &.js-bundle-circle-plus {
            @apply text-be-blue;
        }
    }
}

.ssr-line {
    @apply flex items-center;

    i {
        margin-right: 20px;
    }
}

.ssr-line-name {
    @apply font-body font-black;

    span {
        @apply inline-block font-body;
        font-size: 12px;

        &.allseats-extra {
            @apply font-bold not-italic;
            font-size: 13px;
            margin-top: 2px;
        }
    }
}

.selected-flight {
    transition: all 0.25s ease-in-out;

    &.show-offers {
        padding-bottom: 100px;
    }

    .bundles-header {
        width: 910px;
        margin: 0 auto 15px;
    }

    .bundles-container {
        width: 910px;
        margin: 0 auto 30px;
    }

    .bundle-container {
        width: 32%;

        ul {
            margin-bottom: 0;

            li {
                margin: 0 20px;
                padding: 0 15px;
            }
        }
    }
}

.bundle-ribbon {
    position: absolute;
    top: 25px;
    left: 31px;
    background: $bundleRed;
    transform: translate(-50%, -50%) rotate(-40deg);
    color: #fff;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-size: 9px;
    padding: 3px 30px;
    line-height: 1;
}

.bundle-header {
    @apply relative flex flex-col items-center justify-center overflow-hidden;
    height: 80px;
    border-bottom: 1px solid $bundleGray;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    &.selected {
        @apply justify-around;
    }

    &.no-round {
        @apply rounded-none;
    }

    &.full {
        .header-img {
            @apply relative;
            top: -12px;
        }
    }

    &.short-round {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 0 60px;
    }

    .header-img {
        height: 32px;
    }

    .best-price-label {
        @apply absolute bottom-0 w-full bg-bundle-best-price text-white;
        text-align: center;

        i {
            color: $bundleGreen;
            margin-right: 1px;
            height: 14px;
        }
    }
}

.bundle-price,
.bundle-price span {
    color: $bundleBlue;
    font-weight: 900;
    font-family: "Lato", sans-serif;
    font-size: 22px;
    text-align: center;

    &.american-price {
        font-size: 28px;
        color: #00abc8;

        span {
            font-size: 14px;
            color: #00abc8;
        }
    }
}

.bundle-price-info {
    color: $bundleBlue;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    margin: 5px 0 0;
    text-align: center;
}

.bundle-button {
    height: 40px;
    padding: 0 10px 0 47px;
    margin: 15px auto 30px;
    border-radius: 20px;
    border: 1px solid $bundleBlue;
    color: $bundleBlue;
    font-weight: 900;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    text-align: center;

    &:not(:disabled):hover {
        border-color: $bundleBlue;
        background: $bundleBlue;
        color: #fff;

        i {
            border-color: #fff;
            color: #fff;
        }
    }

    i {
        border: 1px solid $bundleBlue;
        border-radius: 50%;
        width: 23px;
        height: 23px;
        margin-left: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        position: relative;
        padding: 1px 0 0 1px;
    }
}

.selected-flight {
    .selected-bundle-container {
        width: 100%;
        border: none;
        position: relative;

        .selected-bundle {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            flex-wrap: wrap;
            max-height: 200px;

            &.taller {
                max-height: 250px;
            }

            li {
                width: 50%;
                margin: 0;
                padding: 0 15px;
                border: none;
                font-size: 13px;
                cursor: default;
            }
        }
    }
}

.fee-selector-header {
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s ease-in-out;

    &:not(.open) {
        border-color: transparent;
        margin: 0 auto;
        background: transparent;
    }

    &.open {
        max-height: 80px;
        max-width: 95%;
    }
}

.sbb-images {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fee-selector,
.selected-flight {
    .sbb-ssr-names {
        display: flex;
        height: 100%;
        align-items: center;
        margin-left: 15px;

        ul {
            display: flex;
            margin-bottom: 0;
            flex-direction: column;
            height: 120px;
            width: 100%;
            flex-wrap: wrap;

            li {
                position: relative;
                width: calc(50% - 20px);
                max-width: 100%;
                margin: 5px 10px;
                padding-left: 22px;
                font-weight: 600;
                white-space: normal;
                line-height: 1.4;
                height: calc(33% - 10px);

                i {
                    position: absolute;
                    top: 3px;
                    left: 0;
                    color: $bundleGreen;
                    font-size: 16px;
                }
            }
        }
    }

    .sbb-ssr-icons {
        ul {
            display: flex;
            margin-bottom: 0;

            li {
                width: unset;
                max-width: unset;
                margin: 20px 5px 0;

                i {
                    font-size: 44px;
                }
            }
        }
    }
}

.sbb-tick {
    height: 150px;
    width: 100%;
    position: relative;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 64px;
        color: $bundleBlue;
    }
}

.bd-bundle-opener {
    span {
        font-weight: 900;
        font-size: 13px;

        &:after {
            content: "\0276F";
            position: relative;
            transition: all 0.2s ease-in-out;
            display: inline-block;
            font-weight: 900;
            margin-left: 3px;
        }
    }

    &.open {
        span {
            &:after {
                transform: rotate(90deg);
                top: -1px;
            }
        }
    }
}

.bd-bundle-container {
    overflow: hidden;
    max-height: 0;
    transition: all 0.2s ease-in-out;

    &.open {
        max-height: 200px;
    }
}

.bundle-upgrade-offer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 25px 15px;

    .bundle-upgrade-box {
        @apply relative flex items-center justify-between overflow-hidden border border-solid;
        height: 96px;
        background: #f5f5f5;
        border-radius: 10px;
        padding: 0 10px 30px 120px;
    }

    .bundle-upgrade-box-corner {
        @apply absolute;
        content: "";
        width: 40px;
        height: 40px;
        bottom: -20px;
        right: -20px;
        transform: rotate(45deg);
    }

    .bundle-upgrade-title {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            font-family: "ClanOT-Black", sans-serif;
            display: block;

            &:first-child {
                font-size: 20px;
                margin-bottom: 5px;
            }

            &:last-child {
                font-size: 24px;
                text-transform: uppercase;
            }
        }
    }

    .bundle-upgrade-price {
        position: absolute;
        bottom: 10px;
        display: flex;
        align-items: flex-end;

        div {
            line-height: 1;

            &.bup-text {
                font-family: "Lato", sans-serif;
                font-size: 15px;
                letter-spacing: 0.8px;
            }

            &.bup-value {
                display: inline-block;
                margin: 0 6px;
                line-height: 1;

                span {
                    line-height: 1;

                    &.bup-currency {
                        font-family: "Lato", sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        position: relative;
                    }

                    &.bup-difference {
                        font-family: "ClanOT-Black", sans-serif;
                        font-size: 23px;
                        position: relative;
                        top: 3px;

                        .bup-miles {
                            font-family: "Lato", sans-serif;
                            font-weight: 600;
                            font-size: 16px;
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    .bundle-upgrade-logo {
        position: absolute;
        top: -5px;
        left: 13px;
        width: 95px;
        height: 75px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: $box-shadow-medium;

        img {
            max-height: 70%;
        }

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 47.5px 0 47.5px;
            border-top-color: inherit;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }
    }

    .bundle-upgrade-button {
        position: relative;
        width: 132px;
        height: 34px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 17px;
        background: $bundleRed;
        color: #fff;
        font-family: "Lato", sans-serif;
        font-size: 20px;
        font-weight: 900;
        cursor: pointer;

        i {
            position: absolute;
            top: 5px;
            right: 5px;
            color: #fff;
            font-size: 24px;
        }

        &:hover {
            background: $bundleBlue;
        }
    }
}

.bundle-savings-container {
    position: relative;
    width: 100%;
    height: 45px;
    margin-bottom: 15px;

    .bundle-savings {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -7px;
        right: -7px;
        background: #b02b32;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 12px 0;
            border-color: transparent #b02b32 transparent transparent;
        }

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 12px 7px;
            border-color: transparent transparent transparent #b02b32;
        }

        i {
            font-size: 57px;
            margin-right: 10px;
        }

        .bundle-savings-emphasis {
            font-family: "ClanOT-Black", sans-serif;
            font-size: 16px;
            line-height: 1;
            margin-bottom: 3px;
        }

        .bundle-savings-secondary {
            font-family: "ClanOT-Bold", sans-serif;
            font-size: 13px;
            line-height: 1;
        }
    }
}

.bundle-non-discounted-price {
    width: 100%;
    height: 20px;
    font-family: "Lato", sans-serif;
    font-size: 19px;
    font-weight: 300;
    text-decoration: line-through;
    color: #1d365d;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* MEDIA QUERIES */

@media #{$large} {
    .selected-flight {
        .bundle-container {
            ul {
                li {
                    margin: 0 15px;
                }
            }
        }
    }

    .bundle-upgrade-offer-container {
        .bundle-upgrade-title {
            span {
                &:first-child {
                    font-size: 17px;
                }

                &:last-child {
                    font-size: 20px;
                }
            }
        }

        .bundle-upgrade-price {
            div {
                &.bup-text {
                    font-size: 13px;
                }

                &.bup-value {
                    span {
                        &.bup-currency {
                            font-size: 14px;
                            top: 1px;
                        }
                        &.bup-difference {
                            font-size: 19px;

                            .bup-miles {
                                font-size: 14px;
                                top: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media #{$medium} {
    .bundle-container,
    .selected-flight .bundle-container,
    .selected-flight .selected-bundle-container {
        &.selected:after {
            top: -15px;
            left: -8px;
            right: -8px;
            bottom: -15px;
        }

        ul {
            li {
                @apply p-0;

                .ssr-line {
                    i {
                        margin-right: 10px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .ssr-line-name {
        font-size: 13px;

        span {
            &.allseats-extra {
                font-size: 11px;
                margin-top: 1px;
            }
        }
    }

    .selected-flight {
        .selected-bundle-container {
            .selected-bundle {
                li {
                    padding: 0 5px;

                    .ssr-line {
                        i {
                            margin: 0 8px 0 5px;
                        }
                    }
                }
            }
        }
    }

    .selected-flight {
        &.show-offers {
            padding-bottom: 130px;
        }
    }

    .bundle-upgrade-offer-container {
        .bundle-upgrade-logo {
            width: 80px;
            height: 55px;

            img {
                height: 45px;
            }

            &:after {
                border-width: 8px 40px 0 40px;
            }
        }

        .bundle-upgrade-box {
            @apply h-auto flex-col;
            padding: 0 10px 40px 100px;
        }

        .bundle-upgrade-title {
            margin-top: 10px;

            span {
                &:first-child {
                    font-size: 17px;
                }

                &:last-child {
                    font-size: 20px;
                }
            }
        }

        .bundle-upgrade-price {
            right: 20px;

            div {
                &.bup-text {
                    font-size: 13px;
                }

                &.bup-value {
                    span {
                        &.bup-currency {
                            font-size: 14px;
                            top: 1px;
                        }
                        &.bup-difference {
                            font-size: 19px;

                            .bup-miles {
                                font-size: 14px;
                                top: 1px;
                            }
                        }
                    }
                }
            }
        }

        .bundle-upgrade-button {
            width: 90px;
            height: 32px;
            font-size: 14px;
            margin-top: 5px;

            i {
                font-size: 20px;
            }
        }
    }
}

@media #{$medium-down} {
    .bundle-button {
        padding-right: 6px;
        padding-left: 15px;

        i {
            margin-left: 15px;
        }
    }

    .bundle-container,
    .selected-flight .bundle-container,
    .selected-flight .selected-bundle-container {
        ul {
            li {
                font-size: 14px;
                line-height: 1;
                margin: 0 10px;
            }
        }
    }

    .bundle-savings-container {
        .bundle-savings {
            i {
                font-size: 50px;
            }

            .bundle-savings-emphasis {
                font-size: 14px;
            }

            .bundle-savings-secondary {
                font-size: 10px;
            }
        }
    }
}

@media #{$small} {
    .bundle-ribbon {
        font-size: 6px;
        top: 14px;
        left: 31px;
        transform: translate(-50%, -50%) rotate(-32deg);
    }

    .bundle-upgrade-offer-container {
        .bundle-upgrade-logo {
            width: 70px;
            height: 59px;

            img {
                height: 35px;
            }

            &:after {
                border-width: 6px 35px 0 35px;
            }
        }

        .bundle-upgrade-box {
            height: 75px;
            padding: 0 10px 20px 90px;
        }

        .bundle-upgrade-title {
            align-items: flex-start;

            span {
                &:first-child {
                    font-size: 16px;
                    margin-bottom: 0;
                }

                &:last-child {
                    font-size: 16px;
                }
            }
        }

        .bundle-upgrade-price {
            right: 20px;

            div {
                &.bup-text {
                    font-size: 12px;
                }

                &.bup-value {
                    margin: 0 3px;

                    span {
                        &.bup-currency {
                            font-size: 12px;
                        }
                        &.bup-difference {
                            font-size: 18px;

                            .bup-miles {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .bundle-upgrade-button {
            width: 106px;
            height: 26px;
            font-size: 16px;

            i {
                top: 4px;
                font-size: 19px;
            }
        }
    }

    .selected-flight {
        .selected-bundle-container {
            .selected-bundle {
                max-height: 130px;

                &.taller {
                    max-height: 160px;
                }

                li {
                    height: 30px;

                    .ssr-line {
                        i {
                            margin: 0 5px;
                        }

                        .ssr-line-name {
                            font-size: 11px;

                            span {
                                font-size: 10px;

                                &.allseats-extra {
                                    font-size: 9px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ssr-line-name {
        font-size: 13px;

        span {
            &.allseats-extra {
                font-size: 12px;
                margin-top: 1px;
                margin-left: 1px;
            }
        }
    }

    .bundle-container,
    .selected-flight bundle-container,
    .selected-flight selected-bundle-container {
        ul {
            li {
                .ssr-line {
                    i {
                        font-size: 12px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .bundles-title {
        @apply leading-7;
        text-align: center;

        img {
            height: 30px;
        }
    }

    .fee-selector {
        .itinerary {
            &.narrow {
                width: 90%;
                margin: 0 auto;
            }
        }
    }

    .selected-flight {
        .bundles-container {
            @apply relative w-full overflow-x-scroll;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .bundles-row {
        @apply flex gap-x-2 whitespace-nowrap p-0;
        height: 605px;

        &.taller {
            height: 640px;
        }

        &.faded {
            @apply opacity-0;
        }

        .bundle-container {
            @apply inline-block pt-12;
            width: 195px;

            &:hover {
                @apply transform-none shadow-none;
            }
        }
    }

    .bundle-content {
        @apply relative mt-5 w-full cursor-pointer border border-solid border-be-gray-10 opacity-75 shadow-lg;
        padding-bottom: 35px;
        border-radius: 15px;

        &.active {
            @apply mt-0 cursor-default opacity-100;
        }

        .bundle-header {
            @apply justify-start text-white;
            padding: 14px;
            height: 165px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            border-bottom-left-radius: 50% 25%;
            border-bottom-right-radius: 50% 25%;

            .header-img {
                @apply top-0 m-0;
                height: 24px;
            }

            .bundle-non-discounted-price {
                @apply mb-1 mt-3 text-white;
                font-size: 16px;
            }

            .bundle-price {
                @apply mb-1 text-white;

                span {
                    @apply text-white;
                    font-size: 32px;
                }

                .bundle-price-miles{
                    @apply text-white;
                    font-size: 14px;

                    &.no-bundle{
                        @apply text-be-blue;
                    }
                }

                &.no-bundle {
                    @apply text-be-blue;
                    margin-top: 30px;
                }
            }

            .bundle-savings-container {
                @apply w-auto bg-white text-brand-primary;
                font-size: 16px;
                margin: 0 0 2px;
                padding: 0 2px;
                max-width: 175px;
                min-width: 135px;
                height: 25px;
                border-radius: 15px;
                text-align: center;
            }

            .bundle-price-info {
                @apply m-0 leading-4 text-white;
                font-size: 11px;

                &.no-bundle {
                    @apply text-be-gray-4;
                }
            }
        }

        .unavailable {
            display: none;
        }

        .bundle-ssr-items {
            @apply block;
            min-height: 315px;
            width: 194px;
        }
    }

    .best-price-container {
        @apply absolute w-full text-white;
        bottom: 100%;

        .best-price-background {
            @apply mx-auto border-b border-solid border-be-gray-4 bg-be-blue;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            width: 135px;
            text-align: center;

            .best-price-logos {
                @apply flex justify-center;
                height: 20px;
                font-size: 13px;

                .star-container {
                    @apply inline-block;

                    &.side-star {
                        padding: 3px 2px 0;
                    }
                }
            }

            .best-price-text {
                @apply pb-1;
                font-size: 18px;
            }
        }
    }

    .selected-flight {
        .bundle-ssr-items {
            ul {
                li {
                    margin: 0 10px;
                    padding: 0 5px;
                    height: 35px;
                }
            }

            .extra-line-container {
                @apply flex cursor-pointer items-center justify-between;
                margin: 0 10px;
                padding: 0 5px;
                height: 35px;

                &:hover {
                    @apply scale-105 transform;
                }

                .extra-line-text {
                    @apply text-be-gray-4;
                    font-size: 11px;
                    width: 140px;
                    text-align: center;
                }

                i {
                    font-size: 18px;
                }
            }
        }
    }

    .bundle-container,
    .selected-flight .bundle-container,
    .selected-flight .selected-bundle-container {
        ul {
            li {
                .bundle-tooltip {
                    @apply whitespace-normal;
                    max-width: 185px;
                    min-width: 165px;

                    i {
                        font-size: 35px;
                    }
                }
            }
        }
    }

    .selected-flight {
        .ssr-line {
            @apply whitespace-normal;

            i {
                margin-right: 10px;
            }

            .bundle-tooltip {
                .bundle-tooltip-text {
                    h3 {
                        @apply whitespace-normal;
                    }

                    div {
                        @apply whitespace-normal;
                        max-width: 120px;
                    }
                }
            }
        }
    }

    .ssr-line-name {
        @apply leading-3;
        font-size: 11px;

        span {
            @apply leading-3;
            font-size: 10px;

            &.allseats-extra {
                font-size: 9px;
                margin-top: 1px;
                margin-left: 1px;
            }
        }
    }

    .bundle-footer {
        @apply absolute w-full;
        margin-top: 15px;

        .bundle-button {
            @apply mx-auto my-0 border border-solid border-be-blue bg-be-blue text-white;

            &:not(:disabled):hover {
                @apply border-be-blue bg-white text-be-blue;

                i {
                    @apply border-be-blue text-be-blue;
                }
            }

            i {
                @apply border-white text-white;
            }
        }
    }

    .selector-buttons-container {
        @apply absolute flex justify-center;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);

        .selector-buttons {
            @apply mr-3 block h-8 w-8 cursor-pointer select-none text-be-blue;
            font-size: 30px;
            padding-bottom: 9px;
            padding-left: 2px;

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .bundle-container,
    .selected-flight .bundle-container,
    .selected-flight .selected-bundle-container {
        @apply w-full rounded-none border-0 bg-transparent;
        margin-bottom: 30px;

        &.selected {
            &:after {
                border: 1px solid $bundleGray;
                border-radius: 10px;
                top: -8px;
                bottom: -8px;
                right: -8px;
                left: -8px;
            }
        }
    }

    .mobile-bundle-ssrs {
        font-family: "Lato", sans-serif;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 30px;
    }

    .bundle-savings-container {
        .bundle-savings {
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;
            height: 45px;
            margin-left: -20px;
            margin-right: -20px;

            &:before,
            &:after {
                display: none;
            }

            i {
                font-size: 52px;
            }

            .bundle-savings-emphasis {
                font-size: 15px;
            }

            .bundle-savings-secondary {
                font-size: 12px;
            }
        }
    }

    .mobile-bundle-non-discounted-price {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 19px;
        text-decoration: line-through;
        color: #fff;
        text-align: center;
    }

    .selected-flight {
        &.show-offers {
            padding-bottom: 30px;
        }
    }

    .bundle-header {
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        &.per-leg {
            position: absolute;
            top: 13px;
            left: 13px;
            height: unset;
            z-index: 1;
        }
    }

    .bundle-header {
        @apply border-b border-solid border-white;
        height: 60px;

        .best-price-label {
            i {
                margin-right: 1px;
                height: 14px;
            }
        }

        &.none {
            border: 1px solid #e5e5e5;
        }
    }

    .sbb-tick {
        position: absolute;
        bottom: 10px;
        right: -24px;
        width: 48px;
        height: 48px;
        font-size: 48px;

        i {
            font-size: 48px;
        }
    }

    .sbb-images {
        margin-top: 10px;
    }

    .row {
        &.mobile-reverse {
            flex-direction: column-reverse;
        }
    }

    .fee-selector,
    .selected-flight {
        .sbb-ssr-icons {
            ul {
                li {
                    i {
                        font-size: 34px;
                    }
                }
            }
        }

        .sbb-ssr-names {
            ul {
                height: 140px;

                li {
                    font-size: 12px;
                }
            }
        }
    }

    .bundle-upgrade-offer-container {
        margin: 20px 0 0 0;

        .bundle-upgrade-box {
            border-color: #1c355e !important;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin: 0 -1px;
            height: 45px;
            padding: 0 20px 0 80px;

            .upgrade-p-smart {
                width: 50px;
            }

            .upgrade-p-full {
                width: 42px;
            }
        }

        .bundle-upgrade-price {
            position: relative;
            bottom: unset;
            flex-direction: column;
            align-items: flex-start;

            div {
                &.bup-text {
                    font-size: 8px;
                }

                &.bup-value {
                    margin: 0;
                    line-height: 0.8;

                    span {
                        &.bup-currency {
                            top: -2px;
                            font-size: 8px;
                        }

                        &.bup-difference {
                            top: 0;
                            font-size: 12px;

                            .bup-miles {
                                font-size: 8px;
                            }
                        }
                    }
                }
            }
        }

        .bundle-upgrade-button {
            order: 1;
            width: 71px;
            height: 19px;
            border-radius: 10px;
            font-size: 12px;
            padding-right: 10px;

            i {
                font-size: 13px;
                top: 3px;
                right: 2px;
            }
        }

        .bundle-upgrade-title {
            span {
                color: #fff;
                line-height: 1;

                &:first-child {
                    font-size: 10px;
                    margin-bottom: 0;
                }

                &:last-child {
                    font-size: 12px;
                }
            }
        }

        .bundle-upgrade-logo {
            width: 55px;
            height: 40px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            &:after {
                border-width: 5px 27.5px 0 27.5px;
            }
        }

        &.full-smart,
        &.full-dc,
        &.simple-smart,
        &.simple-dc {
            .bundle-upgrade-title {
                span {
                    color: #fff;
                }
            }
        }
    }

    .fee-selector {
        ul {
            li {
                &.wide {
                    .selected-flight {
                        &.taller {
                            height: 230px;
                        }
                    }
                }
            }
        }
    }

    .selected-flight {
        .selected-bundle-container {
            &.shorter {
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }
}
