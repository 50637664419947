.modal-content {
    &.chile-compra-summary {
        @apply min-w-[330px] p-0;
    }
}

.chile-compra-summary {
    .js-accordion {
        @apply text-sm;

        .price-bold {
            @apply text-sm;
        }

        .in-total {
            @apply text-sm;
        }

        label {
            @apply bg-transparent;
        }

        .tab-content {
            @apply bg-transparent;
        }

        input:checked {
            ~ label {
                .fa-angle-down {
                    @apply -translate-y-1/2 rotate-180;
                }
            }
        }
    }
}
