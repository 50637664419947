@tailwind base;

@tailwind components;

@tailwind utilities;

/* ------------------------------------------------------------- */
/* Pico libraries ---------------------------------------------- */
/* ------------------------------------------------------------- */
@import "./pico/pico.scss";

@import "./jetsmart-theme/variables";

/* ------------------------------------------------------------- */
/* Material-design-lite - Material Design Component ------- */
/* ------------------------------------------------------------- */
@import "./jetsmart-theme/material";
@import "./jetsmart-theme/mdl-overwrites";
/* ------------------------------------------------------------- */
/* Theme specific files --------------------------------------- */
/* ------------------------------------------------------------- */
@import "./jetsmart-theme/global";

@import "./jetsmart-theme/accordion";
@import "./jetsmart-theme/american/american-modals";
@import "./jetsmart-theme/baggages";
@import "./jetsmart-theme/banco-estado/banco-estado";
@import "./jetsmart-theme/banco-estado/banco-estado-bar";
@import "./jetsmart-theme/banco-estado/banco-estado-extras";
@import "./jetsmart-theme/banco-estado/banco-estado-footer";
@import "./jetsmart-theme/banco-estado/banco-estado-payment";
@import "./jetsmart-theme/banco-estado/banco-estado-profile";
@import "./jetsmart-theme/banco-estado/bundles-bar";
@import "./jetsmart-theme/banco-estado/credit-shell";
@import "./jetsmart-theme/booking";
@import "./jetsmart-theme/breadcrumbs";
@import "./jetsmart-theme/bundles";
@import "./jetsmart-theme/buttons";
@import "./jetsmart-theme/checkbox";
@import "../../src/components/ui/basic-checkbox/assistance-checkbox";
@import "../../src/components/ui/basic-checkbox/extras-checkin-type-checkbox";
@import "../../src/components/ui/basic-checkbox/checkin-select-pax-checkbox";
@import "../../src/components/ui/basic-checkbox/checkin-select-all-pax-checkbox";
@import "../../src/components/ui/basic-checkbox/checkin-security-terms-checkbox";
@import "../../src/components/ui/basic-checkbox/register-terms-checkbox";
@import "../../src/components/ui/basic-checkbox/payment-confirmation-modal-terms-checkbox";
@import "../../src/components/ui/basic-checkbox/payment-modal-commission-checkbox";
@import "../../src/components/ui/basic-checkbox/payment-on-hold-chechbox";
@import "../../src/components/ui/basic-checkbox/dc-banner-terms-checkbox";
@import "./jetsmart-theme/checkin";
@import "./jetsmart-theme/checkin-sidebar";
@import "./jetsmart-theme/cug";
@import "./jetsmart-theme/cug2-2/cug2contact";
@import "./jetsmart-theme/cug2-2/cug2edit-profile";
@import "./jetsmart-theme/cug2-2/cug2fflyers";
@import "./jetsmart-theme/cug2-2/cug2header";
@import "./jetsmart-theme/cug2-2/cug2table";
@import "./jetsmart-theme/cug2-2/cug2-table-grid";
@import "./jetsmart-theme/cug2-2/cug2layout";
@import "./jetsmart-theme/cug2-2/cug2login";
@import "./jetsmart-theme/cug2-2/cug2modal";
@import "./jetsmart-theme/cug2-2/cug2portal";
@import "./jetsmart-theme/cug2-2/cug2sidebar";
@import "./jetsmart-theme/cug2-2/cug2summary";
@import "./jetsmart-theme/cug2-2/cug2profile";
@import "./jetsmart-theme/cug2-2/cug2register";
@import "./jetsmart-theme/cug2-2/cug2passengers";
@import "./jetsmart-theme/cug2-2/cug2-notification";
@import "./jetsmart-theme/cug2-2/cug2-payment-page";
@import "./jetsmart-theme/cug2-2/cug2-summary-widgets";
@import "./jetsmart-theme/extras";
@import "./jetsmart-theme/family-member-selector-modal";
@import "./jetsmart-theme/family-member-error-modal";
@import "./jetsmart-theme/fare-lock";
@import "./jetsmart-theme/flight-select-banners";
@import "./jetsmart-theme/flight";
@import "./jetsmart-theme/flight-move/flight-move-page";
@import "./jetsmart-theme/flight-move/no-longer-available-modal";
@import "./jetsmart-theme/float-class";
@import "./jetsmart-theme/footer";
@import "./jetsmart-theme/forgot-password";
@import "./jetsmart-theme/forms";
@import "./jetsmart-theme/giftcard/giftcard";
@import "./jetsmart-theme/header";
@import "./jetsmart-theme/icons";
@import "./jetsmart-theme/icons-baggage";
@import "./jetsmart-theme/icons-be2";
@import "./jetsmart-theme/icons-cug";
@import "./jetsmart-theme/icons-covid";
@import "./jetsmart-theme/icons-bundle";
@import "./jetsmart-theme/icons-itinerary";
@import "./jetsmart-theme/icons-my-profile";
@import "./jetsmart-theme/icons-new";
@import "./jetsmart-theme/icons-refund";
@import "./jetsmart-theme/icons-employee-discount";
@import "./jetsmart-theme/icons-cug-notification";
@import "./jetsmart-theme/index";
@import "../../src/components/spa/extras/insurance/insurance";
@import "./jetsmart-theme/itinerary";
@import "./jetsmart-theme/itinerary-new/itinerary-new-bundle";
@import "../../src/components/shared/flight-journey-summary/flight-journey-summary";
@import "./jetsmart-theme/itinerary-new/itinerary-flight-move-modal";
@import "./jetsmart-theme/itinerary-new/flight-move-result-banner";
@import "./jetsmart-theme/itinerary-new/resend-itinerary-report-modal";
@import "./jetsmart-theme/itinerary-offline-payment";
@import "./jetsmart-theme/loaders";
@import "./jetsmart-theme/login";
@import "./jetsmart-theme/messages";
@import "./jetsmart-theme/modal";
@import "./jetsmart-theme/new-profile/profile-bundle";
@import "./jetsmart-theme/new-baggage/new-baggage-bundle";
@import "../../src/components/staff-benefit/staff-benefit";
@import "./jetsmart-theme/new-station-selector";
@import "./jetsmart-theme/passengers";
@import "./jetsmart-theme/payment";
@import "./jetsmart-theme/payment-footer-grid";
@import "./jetsmart-theme/profile";
@import "./jetsmart-theme/ps";
@import "./jetsmart-theme/refund-widget";
@import "./jetsmart-theme/register";
@import "./jetsmart-theme/review";
@import "../../src/components/sidebar/sidebar";
@import "../../src/components/sidebar/sidebar-segments";
@import "./jetsmart-theme/standalone-dc";
@import "./jetsmart-theme/connected-flight-transfer";
@import "../../src/components/ui/tooltip/tooltip";
@import "../../src/components/ui/useDatepicker/use-datepicker";
@import "./jetsmart-theme/transaction";
@import "./jetsmart-theme/peru-compras/peru-compras-login";
@import "../../src/components/cug-portal/widgets/widget-carousel";
@import "../../src/components/flight-select/flight-page-modals/punctuality-performance-modal/punctuality-performance-modal";
@import "../../src/components/payment/very-special-billing-type-selector-checkbox";
@import "../../src/components/payment/payment-terms-acceptance-container";
@import "../../src/components/spa/seatmap/plane/cf-plane";
@import "../../src/components/spa/seatmap/seat/cf-seat";
@import "../../src/components/spa/seatmap/recommendator/seatmap-recommendator";
@import "../../src/components/spa/seatmap/legend";
@import "../../src/components/spa/seatmap/seat-categories.scss";
@import "../../src/components/spa/seatmap/passengers/banco-estado-spec-seats";
@import "../../src/components/spa/seatmap/passengers/be-seat-passengers";
@import "../../src/components/spa/seatmap/passengers/seat-passengers";
@import "../../src/components/spa/seatmap/passengers/mobile-seat-passengers";
@import "../../src/components/spa/seatmap/side-panel/journey-selector";
@import "../../src/components/spa/seatmap/side-panel/seatmap-warning";
@import "../../src/components/spa/seatmap/side-panel/seatmap-info";
@import "../../src/components/spa/seatmap/mobile-review/mobile-review";
@import "../../src/components/spa/seatmap/modals/banco-estado-modals";
@import "../../src/components/spa/seatmap/modals/seatmap-modals";
@import "../../src/components/spa/seatmap/seatmap";
@import "../../src/components/multisegment-modal";
@import "../../src/components/multisegment-modal-opener";
@import "../../src/components/multileg-modal";
@import "../../src/components/spa/spa-modal.scss";
@import "../../src/components/ui/basic-checkbox/basic-checkbox";
@import "../../src/components//flight-select/flight-option-ordering";
@import "../../assets/css/jetsmart-theme/cug3/chile-compra-summary-modal";

@import "plugins/loader";
@import "plugins/slim-select/override";
@import "plugins/rp/rp-grid";
@import "plugins/dc-customization/dc-table";

@import "../../src/dc-components/dc-checkbox.scss";
@import "../../src/dc-components/dc-pager.scss";
@import "../../src/dc-components/dc-table.scss";
@import "../../src/dc-components/dc-table-grid.scss";
@import "../../src/dc-components/dc-datepicker.scss";

@import "../../src/components/spa/passengers/etd-during-booking-modal/etd-during-booking-modal";

@import "../../src/components/cug-portal/reservations/peru-compra-details-modal";
@import "../../src/components/cug-portal/reservations/peru-compra-confirmed-reservations";
@import "../../src/components/cug-portal/peru-compra-login-info";
@import "../css/jetsmart-theme/peru-compras/peru-compra-low-balance-modal";

@import "../css/jetsmart-theme/new-profile/modal-overlay";
@import "../../src/components/shared/flight-itinerary-info/flight-itinerary-info";
