.cug-mobile-only-nav {
    button {
        &.secondary-btn {
            padding: 12px 25px;
        }
    }
}

.cug-content {
    .cug-summary-table {
        td {
            .secondary-btn {
                margin-left: 40px;
            }

            &.text-left {
                .secondary-btn {
                    margin: 0;
                }
            }
        }
    }

    .secondary-btn {
        padding: 7px 21px;
        background: $cug-secondary-btn-color;
        color: #fff;
    }
}