.i2-passengers-container {
    margin-top: 25px;

    .mdl-textfield {
        padding: 7px 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 9px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }
    }

    .js-input {
        font-size: 16px;

        &.js-select {
            @apply text-brand-secondary;
            font-size: 16px;
            padding-left: 15px;
            padding-top: 8px;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            @apply absolute block text-gray-300;
            content: "\25BC";
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
        }
    }
}

.passengers-tab {
    margin-bottom: 40px;
}

.i2-tab-content {
    margin-left: 65px;
}

.i2-passengers-divider {
    @apply bg-be-gray-10;
    height: 1px;
    margin: 25px 0 35px 0;
}

.modal-button-container {
    @apply flex justify-end;

    .no-pax-data {
        @apply mr-6;
    }
}

.i2-passengers-index {
    @apply text-brand-secondary;
    font-size: 16px;
}

.i2-passengers-label {
    @apply text-brand-secondary;
    font-size: 16px;
    margin: 0 0 20px 65px;
}

.i2-passengers-name {
    @apply font-bold text-brand-secondary;
    margin: 6px 0 20px 0;
    font-size: 20px;

    .js-flight-tick {
        @apply ml-2 text-brand-secondary;
        font-size: 30px;
    }
}

.i2-passengers-provided-label {
    @apply text-gray-600;
    font-size: 16px;
}

.i2-passengers-provided-data {
    @apply text-gray-600;
    margin: 10px 0 20px;
    font-size: 18px;
}

.i2-passengers-phone-container {
    @apply flex justify-between;
}

.i2-passengers-phone-prefix {
    @apply w-1/2;
    margin-right: 15px;
}

.i2-passengers-phone-number {
    @apply flex-grow;
}

.i2-frequent-flyer-number-text {
    @apply font-bold text-brand-secondary;
    margin: 6px 0 20px 0;
    font-size: 18px;
}

.i2-frequent-flyer-number {
    @apply text-gray-600;
    font-size: 16px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-passengers-container {
        font-size: 18px;
        line-height: 22px;
    }

    .i2-passengers-divider {
        height: 1px;
        margin: 10px 0 20px 0;
    }

    .i2-passengers-provided-label {
        margin-top: 8px;
        font-size: 15px;
    }

    .i2-passengers-provided-data {
        margin: 10px 0;
        font-size: 16px;
    }
}

@media #{$medium} {
    .passengers-tab {
        margin-bottom: 24px;
    }

    .i2-tab-content {
        margin-left: 55px;
    }

    .i2-passengers-container {
        font-size: 16px;
        line-height: 20px;
    }

    .i2-passengers-divider {
        height: 1px;
        margin: 8px 0 18px 0;
    }

    .i2-passengers-provided-label {
        margin-top: 8px;
        font-size: 13px;
    }

    .i2-passengers-provided-data {
        margin: 10px 0;
        font-size: 13px;
    }
}

@media #{$small} {
    .passengers-tab {
        margin-bottom: 24px;
    }

    .i2-tab-content {
        margin-left: 40px;
    }

    .i2-passengers-container {
        margin-top: 20px;
        font-size: 13px;
        line-height: 15px;

        .js-input {
            font-size: 15px;

            &.js-select {
                font-size: 15px;
            }
        }
    }

    .i2-passengers-divider {
        height: 1px;
        margin: 7px 0 15px 0;
    }

    .modal-button-container {
        .no-pax-data {
            @apply mr-2;
        }
    }

    .i2-passengers-index {
        font-size: 13px;
    }

    .i2-passengers-label {
        font-size: 13px;
        margin-left: 40px;
    }

    .i2-passengers-name {
        margin: 6px 0 12px;
        line-height: 15px;
        font-size: 13px;

        .js-flight-tick {
            font-size: 22px;
        }
    }

    .i2-passengers-provided-label {
        margin-top: 8px;
        font-size: 13px;
    }

    .i2-passengers-provided-data {
        margin: 10px 0;
        font-size: 13px;
    }

    .i2-frequent-flyer-number-text {
        @apply font-bold text-brand-secondary;
        margin: 6px 0 12px 0;
        font-size: 13px;
    }

    .i2-frequent-flyer-number {
        @apply text-gray-600;
        font-size: 13px;
    }
}

@media #{$xsmall} {
    .i2-passengers-container {
        margin-top: 20px;
        font-size: 10px;
        line-height: 12px;

        .mdl-textfield {
            padding: 5px 0;
        }

        .js-input {
            font-size: 14px;

            &.js-select {
                font-size: 14px;
            }
        }

        [type="checkbox"] + label[for] {
            line-height: 12px;
        }
    }

    .i2-passengers-divider {
        height: 1px;
        margin: 15px 0 25px 0;
    }

    .i2-tab-title {
        &.passengers-tab {
            @apply items-center;
        }
    }

    .i2-tab-content {
        margin-left: 0;
        padding: 0 15px;
    }

    .modal-button-container {
        @apply mt-3 flex flex-col-reverse;

        .rounded-primary-btn {
            @apply m-auto;
        }

        .no-pax-data {
            @apply mt-4;
        }
    }

    .i2-passengers-index {
        font-size: 13px;
    }

    .i2-passengers-label {
        font-size: 13px;
        margin-left: 15px;
    }

    .i2-passengers-name {
        margin: 3px 0 6px;
        line-height: 15px;
        font-size: 13px;

        .js-flight-tick {
            font-size: 18px;
        }
    }

    .i2-passengers-provided-label {
        margin-top: 8px;
        font-size: 13px;
    }

    .i2-passengers-provided-data {
        margin: 10px 0;
        font-size: 14px;
    }

    .i2-passengers-phone-container {
        @apply flex flex-col justify-start;
    }

    .i2-passengers-phone-prefix {
        @apply m-0 w-full;
    }

    .i2-frequent-flyer-number-text {
        @apply font-bold text-brand-secondary;
        margin: 6px 0 6px 0;
        font-size: 13px;
    }

    .i2-frequent-flyer-number {
        @apply text-gray-600;
        font-size: 13px;
    }
}
