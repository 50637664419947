@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.extras-checkin-type-option-wrapper {
    @apply border border-solid border-brand-secondary bg-white;

    height: 75px;
    margin: 12px 15px 0;
    padding-left: 25px;
    border-radius: 10px;

    .checkin-type-label {
        @apply relative flex items-center w-full h-full;

        padding-left: 50px;

        &:before {
            @apply absolute overflow-hidden bg-transparent cursor-pointer left-0 border-2 border-solid border-new-ui-cyan;

            content: "";
            width: 34px;
            height: 34px;
            padding: 15px;
            border-radius: 10px;
        }

        &:after {
            @apply absolute;

            content: "";
            left: 5px;
            height: 24px;
            width: 24px;
            border-radius: 5px;
        }
    }

    .checkin-type-checkbox-input {
        &:disabled {
            ~ .checkin-type-label {
                &:after {
                    @apply absolute rounded-full;
                    margin-left: 6px;
                    height: 10px;
                    width: 10px;
                    border-top: 2px solid $checkbox-after-border-color;
                    border-right: 2px solid transparent;
                    background: unset;
                    animation: spinner 0.6s linear infinite;
                }
            }
        }
    }
}

.checkin-type-info-container {
    @apply flex flex-row items-center justify-between w-full h-full leading-none;

    .checkin-type-title {
        font-size: 15px;
    }

    .checkin-type-amount{
        font-size: 15px;

    }
}

// MEDIA QUERIES

@media #{$medium} {
    .extras-checkin-type-option-wrapper {
        padding-left: 16px;
    }

    .checkin-type-info-container {
        .checkin-type-title {
            width: 65%;
            font-size: 13px;
        }

        .checkin-type-amount{
            @apply justify-end;
            width: 35%;
            font-size: 13px;
            margin-right: 16px;
        }
    }
}

@media #{$small} {

    .checkin-type-info-container {
        .checkin-type-title {
            width: 65%;
            font-size: 13px;
        }

        .checkin-type-amount{
            @apply justify-end;
            width: 35%;
            font-size: 13px;
            margin-right: 16px;
        }
    }
}

@media #{$xsmall} {

    .checkin-type-info-container {
        .checkin-type-title {
            width: 65%;
            font-size: 13px;
        }

        .checkin-type-amount{
            @apply justify-end;
            width: 35%;
            font-size: 13px;
            margin-right: 16px;
        }
    }
}
