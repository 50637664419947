.main-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: $js-header-height;
    font-size: 1rem;
    text-align: center;
    transition: top $transition-medium-up ease-in-out;
    z-index: 200;

    &.collapsed {
        top: calc(#{-$js-header-height} - 4px);

        .right {
            border-bottom: none;
        }

        .opened {
            max-height: 0;
        }
    }

    &.condensed {
        height: $js-header-height;
    }

    .mobile-navigation[type="checkbox"] {
        display: none;
    }

    div.mobile-navigation {
        display: none;
    }

    .nav-bg {
        border-bottom: 4px solid $header-main-color;
        background: #fff;
    }

    .nav-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $js-header-height;
        margin-left: 345px;
        margin-right: 45px;
        max-width: 1155px;
        user-select: none;

        &.pcra-menu-container {
            @apply mx-auto my-0;
        }

        &.cug-header {
            margin-left: 310px;
        }

        &.full-width {
            margin: 0 auto;
        }

        .jetsmart-logo {
            display: flex;
            width: 204px;
            height: $js-header-height;
            margin: 0;
            align-items: center;
            cursor: pointer;

            img {
                height: 2.125rem;
            }
        }
    }
}

.mobile-navigation[type="checkbox"] {
    display: none;
}

div.mobile-navigation {
    display: none;
}

.menu-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.culture-selector {
    display: flex;
    position: relative;
    line-height: 0.5;
    cursor: default;

    > a {
        width: 180px;
        height: $js-header-height;
        position: relative;
        transition: background-color $transition-medium-up ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-medium;
        color: $header-secondary-color;

        &.no-arrow {
            &:hover {
                color: $header-secondary-color;
            }
        }

        &:not(.no-arrow) {
            cursor: pointer;

            &:hover,
            &.opened {
                background-color: $header-secondary-color;
                color: #fff;

                span {
                    color: #fff;
                }
            }

            &:hover {
                span {
                    &:last-child {
                        &:after {
                            border-top: 5px solid #fff;
                        }
                    }
                }
            }

            &.opened {
                span {
                    &:last-child {
                        &:after {
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid #fff;
                        }
                    }
                }
            }

            span {
                position: relative;

                &:last-child {
                    &:after {
                        display: block;
                        box-sizing: border-box;
                        content: " ";
                        position: absolute;
                        top: 50%;
                        right: -15px;
                        left: auto;
                        transform: translateY(-50%) scaleX(1);
                        width: 10px;
                        height: 10px;
                        border-bottom: 5px solid transparent;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid $header-secondary-color;
                    }
                }
            }
        }
    }

    .flag {
        background-image: url("/Images/Header/flags.svg");
        background-size: 350px 350px;
        background-repeat: no-repeat;
        border: 2px solid #fff;
        border-radius: 50%;
        border-bottom-right-radius: 0;
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }

    .flag-ar {
        background-position: -143px -184px;
    }
    .flag-bo {
        background-position: -244px -116px;
    }
    .flag-br {
        background-position: -294px -184px;
    }
    .flag-cl {
        background-position: -143px -116px;
    }
    .flag-co {
        background-position: -42px -184px;
    }
    .flag-cr {
        background-position: -294px -116px;
    }
    .flag-ec {
        background-position: -42px -116px;
    }
    .flag-gt {
        background-position: -294px -48px;
    }
    .flag-hn {
        background-position: -144px -48px;
    }
    .flag-mx {
        background-position: -244px -184px;
    }
    .flag-ni {
        background-position: -44px -48px;
    }
    .flag-pa {
        background-position: -94px -116px;
    }
    .flag-pe {
        background-position: -92px -184px;
    }
    .flag-py {
        background-position: -194px -48px;
    }
    .flag-sv {
        background-position: -94px -48px;
    }
    .flag-us {
        background-position: -194px -184px;
    }
    .flag-uy {
        background-position: -244px -48px;
    }
    .flag-ve {
        background-position: -194px -116px;
    }

    .cultures {
        @apply absolute right-0 block overflow-hidden leading-none text-white common-transition;
        top: $js-header-height;
        left: -27px;
        background: $header-secondary-color;
        z-index: 101;

        h2 {
            margin: 10px 20px;
            text-transform: uppercase;
            font-family: "Lato", sans-serif;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 1px solid #fff;
            text-align: left;
            line-height: 1;
            padding: 10px 0;
        }

        li {
            width: 100%;
            display: flex;
            padding: 10px 20px;
            align-items: center;
            cursor: pointer;
        }
    }
}

.login-container {
    display: flex;
    position: relative;
    line-height: 0.5;

    .help-center-item {
        display: none;
    }

    .american-user-data-container {
        span:first-child {
            @apply font-bold text-brand-tertiary;
        }

        &:hover {
            span:first-child {
                @apply text-white;
            }
        }
    }

    li {
        width: 185px;
        height: $js-header-height;
        display: inline-block;
        position: relative;
        transition: background-color $transition-medium-up ease-in-out;

        &.user-name {
            width: 200px;
        }

        &:hover,
        &.opened {
            background-color: $header-secondary-color;

            > a {
                color: #fff;

                span {
                    &:last-child {
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }

        > a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: $font-size-medium;
            color: $header-secondary-color;
            cursor: pointer;

            span {
                position: relative;
                display: block;
                line-height: 1;

                &.smaller {
                    display: block;
                    font-size: $font-size-xsmall;
                    margin-top: 3px;
                }

                &:last-child {
                    &:after {
                        box-sizing: border-box;
                        display: block;
                        content: "";
                        margin-top: 2px;
                        border-bottom: solid 2px #fff;
                        transform: scaleX(0);
                        transition: transform $transition-medium-up ease-in-out;
                    }
                }
            }
        }
    }

    .dropdown {
        @apply absolute left-0 right-0 overflow-hidden bg-brand-secondary;
        top: $js-header-height;
        z-index: 1000;
        max-height: 200px;
        transition: max-height $transition-medium-up ease-in-out;
        padding-bottom: 20px;

        .help-center-item {
            display: none;
        }

        a {
            justify-content: center;
            display: block;
            padding-top: 40px;

            &:first-child {
                padding-top: 20px;
            }

            span {
                position: relative;
                color: #fff;

                &:after {
                    position: absolute;
                    top: 25px;
                    left: 0;
                    right: 0;
                    box-sizing: border-box;
                    display: block;
                    content: "";
                    border-bottom: solid 2px #fff;
                    transform: scaleX(0);
                    transition: transform $transition-medium-up ease-in-out;
                }
            }

            &:hover {
                span {
                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}

.desktop-anonymus-btn-template {
    @apply flex justify-between;
}

.mobile-anonymus-btn-template {
    display: none;
}

/* MEDIA QUERIES */

@media #{$large-down} {
    .main-header {
        .nav-container {
            padding-left: 0;
            padding-right: 0;
            margin-right: 25px;

            &.full-width {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

@media #{$medium} {
    .main-header {
        .nav-container {
            &.full-width {
                margin: 0 25px;

                &.cug-header {
                    padding-left: 0;
                }
            }
        }
    }
}

@media #{$medium-up} {
    .dropdown {
        &.closed {
            @apply pb-0;
            max-height: 0;
        }
    }
}

@media #{$small-down} {
    .main-header {
        padding-left: 0;

        .nav-container {
            padding-left: 0;
            margin-left: 10px;
            margin-right: 10px;

            &.full-width {
                padding-left: 0;
                margin-left: 10px;
                margin-right: 10px;

                &.cug-header {
                    padding-left: 0;
                }
            }

            .jetsmart-logo {
                height: 40px;

                img {
                    max-height: 30px;
                }
            }
        }

        .right {
            display: block;
            position: fixed;
            top: $js-header-height;
            right: 0;
            left: 0;
            margin: 0;
            padding: 0;
            background: #fff;
            z-index: 99;
            border-bottom: 4px solid $header-main-color;
            max-height: 0;
            transition: all $transition-slow ease-in-out;
            overflow: hidden;

            .help-center-item {
                display: none;
            }

            li {
                display: block;
                margin: 0 auto;
                width: 50%;
                height: auto;
                min-height: $js-header-height;
                text-align: center;
                @include font-size($font-size-small);
                color: $header-secondary-color;

                // a {
                //     display: flex;
                // }
            }
        }

        .culture-selector {
            margin-right: 43px;

            .cultures {
                top: 55px;
            }

            a {
                width: 140px;
            }

            > a {
                width: 120px;
                height: 55px;
                padding-right: 10px;
            }
        }

        .login-container {
            display: block;
        }

        .login-container:not(.unlogged) {
            line-height: normal;

            > li,
            > li:hover {
                > a,
                > a:hover {
                    position: relative;
                    top: auto;
                    bottom: auto;
                    right: auto;
                    left: auto;
                    display: block;
                    background: #fff;
                    cursor: default;
                    padding-bottom: 20px;

                    span {
                        color: $header-secondary-color;
                    }
                }
            }

            .dropdown {
                @apply relative bg-white text-brand-secondary;
                display: block !important;
                top: unset;
                left: unset;
                right: unset;
                bottom: unset;

                a {
                    padding: 20px 0;
                    cursor: pointer;
                    color: $header-secondary-color;

                    span {
                        color: $header-secondary-color;
                        font-size: $font-size-medium;
                        cursor: pointer;
                    }

                    &:hover {
                        background: $header-secondary-color;

                        span {
                            color: #fff;
                        }
                    }
                }
            }
        }

        div.mobile-navigation {
            display: block;
            position: absolute;
            top: 40px;
            right: 0px;
            width: 40px;
            text-transform: uppercase;
            color: $header-secondary-color;
            font-size: 10px;
            text-align: center;
        }

        .mobile-navigation[type="checkbox"] {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: auto;
            bottom: 0;
            width: 54px;
            height: auto;
            margin: 0;
            border: 0;
            visibility: visible;
            opacity: 0.01;
            color: $header-main-color;
            z-index: 100;

            &:checked {
                & + .right {
                    max-height: 350px;
                    transition: all $transition-medium-up ease-in-out;
                }
            }

            &:after {
                display: none;
            }

            &:hover {
                cursor: pointer;
                background: transparent;

                &:after {
                    color: $brand-primary;
                }
            }
        }

        .menu-container {
            &:after {
                content: "|||";
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 14px;
                top: 10px;
                width: 20px;
                height: 30px;
                font-size: 30px;
                font-weight: 900;
                transform: rotate(-90deg) scaleY(1.3);
                @include transition(all $main-navigation-transition-time);
                color: $header-main-color;
                background: white;
            }

            &.no-mobile-opener {
                &:after {
                    display: none;
                }

                .culture-selector {
                    @apply mr-0;
                }
            }
        }
    }

    .culture-selector {
        .flag-pe {
            background-position: -93px -184px;
        }

        .flag-br {
            background-position: -295px -184px;
        }
    }
}

@media #{$small} {
    .main-header {
        .nav-container {
            &.cug-header {
                margin-left: 240px;
            }
        }
    }
}

@media #{$xsmall} {
    .main-header {
        z-index: 100000;

        &.pull-up {
            height: 66px;
        }

        .login-container {
            padding-top: 10px;

            .help-center-item {
                @apply block;
            }
        }

        .nav-container {
            &.cug-header {
                margin-left: 0;
            }

            &.full-width {
                margin-left: 10px;
                margin-right: 10px;
            }

            .jetsmart-logo {
                height: 40px;

                img {
                    max-height: 30px;
                }
            }
        }

        div.mobile-navigation {
            top: 35px;
        }

        .menu-container {
            &:after {
                top: 7px;
            }
        }

        .culture-selector {
            margin-right: 43px;

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 5px;
            }

            .cultures {
                top: 55px;

                h2 {
                    margin: 0 20px;
                    padding-bottom: 5px;
                }

                ul {
                    margin-top: 2px;
                }
            }

            > a {
                width: 120px;
                height: 55px;
                padding-right: 15px;
            }
        }

        .right {
            .help-center-item {
                @apply block;
            }
        }
    }

    .desktop-anonymus-btn-template {
        display: none;
    }

    .mobile-anonymus-btn-template {
        @apply mx-auto block h-auto w-1/2;
        text-align: center;
        min-height: 55px;
        font-size: 14pxrem;
    }
}
