/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: 'jetsmart-v2-forbidden';
    src:  url('../fonts/jetsmart-v2-forbidden.eot?ioge3i');
    src:  url('../fonts/jetsmart-v2-forbidden.eot?ioge3i#iefix') format('embedded-opentype'), url('../fonts/jetsmart-v2-forbidden.ttf?ioge3i') format('truetype'), url('../fonts/jetsmart-v2-forbidden.woff?ioge3i') format('woff'), url('../fonts/jetsmart-v2-forbidden.svg?ioge3i#jetsmart-v2-forbidden') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .js-icon-forbidden {
    font-family: 'jetsmart-v2-forbidden' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .js-battery:before {
    content: "\e900";
  }
  .js-blade:before {
    content: "\e901";
  }
  .js-cigarette:before {
    content: "\e902";
  }
  .js-firearm:before {
    content: "\e903";
  }
  .js-forbidden-circle:before {
    content: "\e904";
  }
  .js-general1:before {
    content: "\e905";
  }
  .js-general2:before {
    content: "\e906";
  }
  .js-general3:before {
    content: "\e907";
  }
  .js-general4:before {
    content: "\e908";
  }
  .js-general5:before {
    content: "\e909";
  }
  .js-general6:before {
    content: "\e90a";
  }
  .js-general7:before {
    content: "\e90b";
  }
  .js-general8:before {
    content: "\e90c";
  }
  .js-general9:before {
    content: "\e90d";
  }
  .js-general10:before {
    content: "\e90e";
  }
  .js-general11:before {
    content: "\e90f";
  }
  .js-pliers:before {
    content: "\e910";
  }
  .js-scissors:before {
    content: "\e911";
  }
  .js-sharp:before {
    content: "\e912";
  }
  .js-temperature:before {
    content: "\e913";
  }
  .js-tool:before {
    content: "\e914";
  }
  