.itinerary-navigation {
    a {
        &.itinerary-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 50px;
            width: 405px;
            max-width: 48%;
            box-sizing: border-box;
            border: 2px solid #7287a0;
            border-radius: $border-radius-lg;
            background-color: #7287a0;
            color: $gray-lightest;
            text-align: center;
            text-transform: none;
            box-shadow: none;
            font-weight: 600;
            font-size: $font-size-medium;

            &:hover {
                border-width: 2px;
                background: #fff;
                color: $brand-secondary;
            }

            &.revocation {
                @apply text-white;
                background-color: $brand-primary;

                &:hover {
                    @apply bg-white;
                    border-width: 2px;
                    color: $brand-primary;
                }
            }
        }
    }
}

@media #{$medium-down} {
    #mainContentItinerary {
        button {
            width: 100%;
            margin: $common-gap 0;
        }
    }
}

@media #{$xsmall} {
    .itinerary-navigation {
        a {
            &.itinerary-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: 50px;
                width: 100%;
                max-width: 100%;
                box-sizing: border-box;
                border: 2px solid #7287a0;
                border-radius: $border-radius-lg;
                background-color: #7287a0;
                color: $gray-lightest;
                text-align: center;
                text-transform: none;
                box-shadow: none;
                font-weight: 600;
                font-size: $font-size-medium;

                &:first-child {
                    margin-bottom: $common-gap;
                }
            }
        }
    }
}
