.cug2b-contact-header {
    @apply flex items-center bg-cug-gray-5;

    border-radius: 10px;
    height: 169px;
    margin-bottom: 15px;
    padding-left: 45px;

    i {
        @apply text-cug-dark-gray;
        font-size: 86px;
        margin-right: 35px;
    }

    h1 {
        @apply m-0 p-0 font-heavy text-cug-dark-gray;
        font-size: 27px;
        line-height: 32px;

        &:last-child {
            margin-bottom: 20px;
        }
    }

    h2 {
        @apply m-0 p-0 font-body text-cug-dark-gray;
        font-size: 19px;
        line-height: 23px;
    }

    &.chile-compra {
        height: 109px;

        h1 {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.cug2b-contact-content {
    @apply flex w-full items-stretch overflow-hidden;
    border-radius: 10px;
}

.cug2b-contact-img {
    @apply w-1/2;
    background: url("/Images/Cug22/contact-dt.png") no-repeat center center;
    background-size: cover;

    &.peru-compra {
        background: url("/Images/PeruCompra/peru-compra-contact-banner.png") no-repeat center center;
        background-size: cover;
        border-radius: 10px;
        margin: 0 10px 0 0;
    }
}

.cug2b-contact-info {
    @apply w-1/2 bg-cug-gray-5;
    padding: 30px 40px;

    h1 {
        @apply font-emphasis text-cug-dark-gray;
        font-size: 21px;
        line-height: 25px;
        margin-bottom: 25px;
    }

    h2 {
        @apply font-body font-bold text-cug-dark-gray;
        font-size: 15px;
        line-height: 18px;
    }

    p {
        @apply font-body text-cug-dark-gray;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 20px;

        &:last-child {
            @apply mb-0;
        }
    }

    i {
        @apply text-cug-dark-gray;
        font-size: 15px;
        margin-right: 6px;
    }

    &.peru-compra {
        min-height: 300px;

        h2 {
            @apply font-normal leading-3;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 15px;
        }

        span {
            @apply font-bold;
        }

        ul {
            display: list-item;
            list-style-type: disc;
            margin-left: 20px;

            li {
                font-size: 18px;
            }

            li:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}

// MEDIA QUERIES

@media #{$small} {
    .cug2b-contact-header {
        border-radius: 6px;
        height: 103px;
        margin-bottom: 10px;
        padding-left: 30px;

        i {
            font-size: 52px;
            margin-right: 20px;
        }

        h1 {
            font-size: 18px;
            line-height: 22px;

            &:last-child {
                margin-bottom: 8px;
            }
        }

        h2 {
            font-size: 13px;
            line-height: 16px;
        }

        &.chile-compra {
            height: 90px;
        }
    }

    .cug2b-contact-content {
        border-radius: 6px;
    }

    .cug2b-contact-info {
        padding: 25px 20px;

        h1 {
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 13px;
        }

        h2 {
            font-size: 11px;
            line-height: 13px;
        }

        p {
            font-size: 11px;
            line-height: 13px;
            margin-bottom: 15px;
        }

        i {
            font-size: 11px;
            margin-right: 5px;
        }

        &.peru-compra {
            min-height: 200px;

            h2 {
                font-size: 14px;
            }

            ul {
                li {
                    font-size: 14px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .cug2b-contact-header {
        border-radius: 4px;
        height: 81px;
        margin-bottom: 20px;
        padding-left: 15px;

        i {
            font-size: 42px;
            margin-right: 15px;
        }

        h1 {
            font-size: 14px;
            line-height: 17px;

            &:last-child {
                margin-bottom: 5px;
            }
        }

        h2 {
            font-size: 10px;
            line-height: 12px;
        }

        &.chile-compra {
            height: 90px;
        }
    }

    .cug2b-contact-content {
        @apply flex-col items-center;
        border-radius: 4px;
    }

    .cug2b-contact-img {
        @apply w-full;
        height: 145px;

        &.peru-compra {
            margin: 0 0 10px 0;
        }
    }

    .cug2b-contact-info {
        @apply w-full;
        padding: 15px;

        h1 {
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 13px;
        }

        h2 {
            font-size: 11px;
            line-height: 13px;
        }

        p {
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 15px;
        }

        i {
            font-size: 11px;
            margin-right: 5px;
        }

        &.peru-compra {
            min-height: 150px;

            h2 {
                font-size: 14px;
            }

            ul {
                li {
                    font-size: 14px;
                }
            }
        }
    }
}
