/*
 * Colors
 */

// Colors
//
$brand-success:     #5cb85c !default;
$brand-info:        #5bc0de !default;
$brand-warning:     #f0ad4e !default;
$brand-error:       #d9534f !default;

$body-bg:           #fff !default;

//Shades
//
$gray-darkest:      #212121 !default;
$gray-dark:         #373a3c !default;
$gray:              #55595c !default;
$gray-light:        #818a91 !default;
$gray-lighter:      #eceeef !default;
$gray-lightest:     #f7f7f9 !default;

//Palette
//
$brand-primary:      #4da64d !default;
$brand-light:        #fafafc !default;
$brand-dark:         #222222 !default;
$brand-secondary:    #663399 !default;

$text-color:         #232323 !default;

//Utility
//
$brand-inverse:      #222222 !default;