.pcra-cancel-booking-icon {
    i {
        @apply cursor-pointer;
        font-size: 20px;

        &:hover {
            @apply text-cug-red;
        }
    }
}

.cug-filter-container {
    margin-bottom: 20px;
}

.cug-filters {
    @apply flex flex-row justify-between;
}

.cug-label-and-filter {
    @apply flex flex-col relative;

    label {
        @apply mb-2;
    }
}

.cug-filter-input-fields {
    @apply flex items-center;
    width: 316px;
}

.cug-filter-user-input {
    @apply relative border border-solid border-cug-dark-gray text-cug-dark-gray rounded-lg w-full font-body;
    height: 42px;
    font-size: 19px;

    option {
        @apply text-dp-blue font-body;
        font-size: 16px;
    }
}

.pcra-hide-select-box {
    .dc-checkbox {
        + label {
            &.form-label {
                @apply opacity-0 pointer-events-none;
            }
        }
    }
}

// MEDIA QUERIES

@media #{$large} {
    .cug-filter-container {
        @apply mb-0;
    }

    .cug-label-and-filter {
        label {
            @apply mb-2;
        }
    }

    .cug-filter-input-fields {
        width: 280px;
    }

    .cug-filter-user-input {
        @apply w-full;
        height: 42px;
        font-size: 18px;
    }
}

@media #{$medium} {
    .cug-filter-container {
        @apply mb-0;
    }

    .cug-label-and-filter {
        label {
            @apply mb-2;
        }
    }

    .cug-filter-input-fields {
        width: 260px;
    }

    .cug-filter-user-input {
        @apply w-full;
        height: 42px;
        font-size: 16px;
    }
}

@media #{$small} {
    .cug-filter-container {
        @apply mb-0;
    }

    .cug-filters {
        @apply flex-col;
    }

    .cug-label-and-filter {
        @apply mb-6;

        label {
            @apply mb-2;
        }
    }

    .cug-filter-input-fields {
        @apply w-full;
    }

    .cug-filter-user-input {
        @apply w-full;
        height: 42px;
        font-size: 16px;
    }
}

@media #{$xsmall} {
    .cug-filter-container {
        @apply mb-0;
    }

    .cug-filters {
        @apply flex-col;
    }

    .cug-label-and-filter {
        @apply mb-3;

        label {
            @apply mb-0;
            font-size: 12px;
        }
    }

    .cug-filter-input-fields {
        @apply w-full;
    }

    .cug-filter-user-input {
        @apply w-full pl-8;
        height: 32px;
        font-size: 14px;
    }

    .cug2b-searchbox {
        @apply w-full;

        input {
            @apply w-full;
            height: 32px;
            font-size: 12px;
        }

        i {
            right: 1rem;
            font-size: 17px;
        }
    }
}
