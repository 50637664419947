/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "jetsmart-be2";
    src: url("../fonts/jetsmart-be2.eot?ioge3i");
    src: url("../fonts/jetsmart-be2.eot?ioge3i#iefix") format("embedded-opentype"),
        url("../fonts/jetsmart-be2.ttf?ioge3i") format("truetype"),
        url("../fonts/jetsmart-be2.woff?ioge3i") format("woff"),
        url("../fonts/jetsmart-be2.svg?ioge3i#jetsmart-be2") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-be2 {
    font-family: "jetsmart-be2" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-be2-stars-plus:before {
    content: "\e90e";
}
.js-be2-circle-star:before {
    content: "\e90d";
}
.js-be2-circle-group:before {
    content: "\e90c";
}
.js-be2-circle-seat-more:before {
    content: "\e909";
}
.js-be2-circle-seat:before {
    content: "\e90a";
}
.js-be2-circle-baggage:before {
    content: "\e90b";
}
.js-be2-base-seat-selected .path1:before {
    content: "\e902";
    color: rgb(204, 204, 204);
}
.js-be2-base-seat-selected .path2:before {
    content: "\e903";
    margin-left: -48px;
    margin-right: -13px;
    color: rgb(117, 120, 123);
}

/* MEDIA QUERY */
@media #{$small} {
    .js-be2-base-seat-selected .path2:before {
        margin-left: -43px;
        margin-right: -11px;
    }
}

.js-be2-first-seat-selected .path1:before {
    content: "\e904";
    color: rgb(204, 204, 204);
}
.js-be2-first-seat-selected .path2:before {
    content: "\e905";
    margin-left: -1em;
    color: rgb(117, 120, 123);
}
.js-be2-base-seat:before {
    content: "\e906";
}
.js-be2-first-seat:before {
    content: "\e907";
}
.js-be2-seats:before {
    content: "\e908";
}
.js-be2-circle-tick:before {
    content: "\e901";
}
.js-be2-circle-plus:before {
    content: "\e900";
}
.js-be2-star-1:before {
    content: "\e946";
}
.js-be2-star-2:before {
    content: "\e947";
}
.js-be2-star-3:before {
    content: "\e948";
}
.js-be2-star-4:before {
    content: "\e949";
}
.js-be2-bar-tick:before {
    content: "\e94a";
}
.js-be2-money:before {
    content: "\e94b";
}
.js-be2-percent:before {
    content: "\e94c";
}
.js-be2-priority:before {
    content: "\e94d";
}
.js-be2-seat:before {
    content: "\e94e";
}
