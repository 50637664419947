@import "./baggage-page-variables";

.b2-illustration {
    @apply flex flex-col items-center justify-end relative;
    height: $containerHeightXLargeLow;

    &.taller {
        height: $containerHeightXLargeHigh;
    }
}

.b2-free-cabin-img {
    height: 222px;
    width: 204px;
}

.b2-paid-cabin-img {
    height: 200px;
    width: 184px;
}

.b2-free-checked-img {
    width: 200px;
    height: 192px;
}

.b2-paid-checked-img {
    width: 200px;
    height: 192px;
}

.b2-weight {
    @apply font-body text-be-blue font-semibold flex items-center justify-center leading-none;
    font-size: 20px;
    height: 40px;
    text-align: center;
}

.b2-weight-container-dt {
    @apply flex items-center justify-center w-full;

    .b2-weight {
        width: 184px;
    }
}

.b2-dimensions {
    @apply text-bg-gray-3 font-news flex items-center justify-center leading-none;
    height: 25px;
    margin-bottom: 10px;
    font-size: 12px;
}

.b2-dimension-container-dt {
    @apply flex items-center w-full;

    .b2-dimensions {
        @apply w-1/2;
    }
}

.b2-bag-names-container {
    @apply flex items-center w-full;

    .b2-bag-names {
        @apply w-1/2 font-body font-semibold text-be-blue flex items-center justify-center;
        line-height: 1.1;
        height: 31px;
        margin-top: 10px;
        margin-bottom: 15px;
        font-size: 18px;
        text-align: center;
        max-width: 80%;

        &.bigger {
            height: 66px;
            max-width: 70%;
        }
    }
}

.b2-bag-name {
    @apply font-body font-semibold text-be-blue flex items-center justify-center;
    line-height: 1.1;
    height: 31px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 18px;
    text-align: center;
    max-width: 80%;

    &.bigger {
        height: 66px;
        max-width: 70%;
    }
}

.b2-illustration-ribbon {
    @apply absolute text-white font-emphasis flex items-center justify-center bg-be-cyan-2 leading-none;
    top: 15px;
    right: -22px;
    font-size: 17px;
    width: 242px;
    height: 42px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;

    &:after {
        @apply absolute right-0 w-0 h-0 border-solid;
        top: 100%;
        content: "";
        border-width: 14px 22px 0 0;
        border-color: #04909e transparent transparent transparent;
    }
}

.b2-checked-warning {
    @apply font-body font-semibold flex items-start text-be-red;
    font-size: 13px;
    line-height: 16px;
    max-width: 145px;

    i {
        @apply text-be-red;
        margin-right: 10px;
        font-size: 27px;
    }
}

.b2-tooltip {
    @apply absolute text-be-blue bg-bg-cyan horizontal-center;
    display: none;
    border-radius: 10px;
    padding: 40px 65px 35px 44px;
    bottom: 25px;

    &.one-dimension-container {
        padding: 40px 80px 35px 20px;
    }

    i {
        font-size: 95px;
    }

    span {
        @apply absolute font-body;
        font-size: 16px;

        &:nth-child(2) {
            bottom: 25px;
            left: 30px;

            &.one-dimension {
                @apply bottom-auto whitespace-normal;
                top: 50px;
                left: 115px;
                max-width: 80px;
                text-align: left;
            }
        }

        &:nth-child(3) {
            top: 20px;
            left: 77px;
        }

        &:nth-child(4) {
            top: 80px;
            right: 20px;
        }
    }

    &:after {
        @apply absolute bg-bg-cyan;
        content: "";
        width: 12px;
        height: 12px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

.b2-tooltip-opener {
    @apply relative inline-flex items-center justify-center text-white bg-be-gray-4 cursor-pointer rounded-full;
    pointer-events: all;
    width: 20px;
    height: 20px;
    margin-left: 7px;
    font-size: 11px;

    &:hover {
        .b2-tooltip {
            @apply block;
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .b2-illustration {
        height: $containerHeightLargeLow;

        &.taller {
            height: $containerHeightLargeHigh;
        }
    }

    .b2-weight {
        font-size: 18px;
    }

    .b2-dimensions {
        font-size: 10px;
    }

    .b2-bag-names-container {
        .b2-bag-names {
            font-size: 18px;

            &.bigger {
                max-width: 70%;
            }
        }
    }

    .b2-bag-name {
        font-size: 18px;

        &.bigger {
            max-width: 70%;
        }
    }

    .b2-illustration-ribbon {
        top: 13px;
        right: -22px;
        font-size: 15px;
        width: 233px;
        height: 36px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;

        &:after {
            border-width: 14px 22px 0 0;
        }
    }

    .b2-checked-warning {
        font-size: 13px;
        line-height: 16px;
        max-width: 145px;

        i {
            margin-right: 8px;
            font-size: 25px;
        }
    }

    .b2-tooltip {
        border-radius: 10px;
        padding: 40px 65px 35px 44px;
        bottom: 25px;

        i {
            font-size: 95px;
        }

        span {
            font-size: 16px;

            &:nth-child(2) {
                bottom: 25px;
                left: 30px;
            }

            &:nth-child(3) {
                top: 20px;
                left: 77px;
            }

            &:nth-child(4) {
                top: 80px;
                right: 20px;
            }
        }

        &:after {
            width: 12px;
            height: 12px;
        }
    }

    .b2-tooltip-opener {
        width: 18px;
        height: 18px;
        margin-left: 6px;
        font-size: 11px;
    }
}

@media #{$medium} {
    .b2-illustration {
        height: $containerHeightMediumLow;

        &.taller {
            height: $containerHeightMediumHigh;
        }
    }

    .b2-free-cabin-img {
        height: 160px;
        width: 147px;
    }

    .b2-paid-cabin-img {
        height: 150px;
        width: 138px;
    }

    .b2-free-checked-img {
        width: 157px;
        height: 150px;
    }

    .b2-paid-checked-img {
        width: 157px;
        height: 150px;
    }

    .b2-weight {
        font-size: 13px;
        height: 20px;
    }

    .b2-weight-container-dt {
        .b2-weight {
            width: 150px;
        }
    }

    .b2-dimensions {
        font-size: 8px;
        height: 13px;
        margin-bottom: 5px;
    }

    .b2-bag-names-container {
        .b2-bag-names {
            font-size: 14px;
            height: 15px;
            margin-bottom: 8px;

            &.bigger {
                height: 33px;
                max-width: 70%;
            }
        }
    }

    .b2-bag-name {
        font-size: 14px;
        height: 15px;
        margin-bottom: 8px;

        &.bigger {
            height: 33px;
            max-width: 70%;
        }
    }

    .b2-illustration-ribbon {
        top: 10px;
        right: -22px;
        font-size: 11px;
        width: 160px;
        height: 27px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;

        &:after {
            border-width: 14px 22px 0 0;
        }
    }

    .b2-checked-warning {
        font-size: 10px;
        line-height: 12px;
        max-width: 110px;

        i {
            margin-right: 6px;
            font-size: 17px;
        }
    }

    .b2-tooltip {
        border-radius: 10px;
        padding: 40px 65px 35px 44px;
        bottom: 25px;

        i {
            font-size: 95px;
        }

        span {
            font-size: 16px;

            &:nth-child(2) {
                bottom: 25px;
                left: 30px;
            }

            &:nth-child(3) {
                top: 20px;
                left: 77px;
            }

            &:nth-child(4) {
                top: 80px;
                right: 20px;
            }
        }

        &:after {
            width: 12px;
            height: 12px;
        }
    }

    .b2-tooltip-opener {
        width: 13px;
        height: 13px;
        margin-left: 5px;
        font-size: 10px;
    }
}

@media #{$small} {
    .b2-illustration {
        height: $containerHeightSmallLow;

        &.taller {
            height: $containerHeightSmallHigh;
        }
    }

    .b2-free-cabin-img {
        height: 130px;
        width: 119px;
    }

    .b2-paid-cabin-img {
        height: 110px;
        width: 101px;
    }

    .b2-free-checked-img {
        width: 123px;
        height: 118px;
    }

    .b2-paid-checked-img {
        width: 123px;
        height: 118px;
    }

    .b2-weight {
        font-size: 12px;
        height: 30px;
    }

    .b2-weight-container-dt {
        .b2-weight {
            width: 101px;
        }
    }

    .b2-dimensions {
        font-size: 9px;
        height: 12px;
        margin-bottom: 8px;
    }

    .b2-bag-names-container {
        .b2-bag-names {
            font-size: 14px;
            height: 16px;
            margin-bottom: 12px;

            &.bigger {
                height: 36px;
                max-width: 70%;
            }
        }
    }

    .b2-bag-name {
        font-size: 14px;
        height: 16px;
        margin-bottom: 12px;

        &.bigger {
            height: 36px;
            max-width: 70%;
        }
    }

    .b2-illustration-ribbon {
        top: 10px;
        right: -14px;
        font-size: 11px;
        width: 150px;
        height: 24px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;

        &:after {
            border-width: 7px 14px 0 0;
        }
    }

    .b2-checked-warning {
        font-size: 10px;
        line-height: 12px;
        max-width: 90px;

        i {
            margin-right: 4px;
            font-size: 16px;
        }
    }

    .b2-tooltip {
        border-radius: 6px;
        padding: 35px 52px 32px 38px;
        bottom: 25px;

        &.one-dimension-container {
            padding: 35px 52px 32px 10px;
        }

        i {
            font-size: 60px;
        }

        span {
            font-size: 12px;

            &:nth-child(2) {
                bottom: 22px;
                left: 32px;

                &.one-dimension {
                    left: 70px;
                    max-width: 80px;
                }
            }

            &:nth-child(3) {
                top: 19px;
                left: 57px;
            }

            &:nth-child(4) {
                top: 58px;
                right: 15px;
            }
        }

        &:after {
            width: 10px;
            height: 10px;
        }
    }

    .b2-tooltip-opener {
        width: 12px;
        height: 12px;
        margin-left: 2px;
        font-size: 9px;
    }
}

@media #{$xsmall} {
    .b2m-illustration {
        @apply w-1/2 flex flex-col items-center;

        .b2-backpack-and-large-cabin-bag-img {
            width: 140px;
            height: 90px;
        }
    }

    .b2-free-cabin-img {
        width: 90px;
        height: 85px;
    }

    .b2-paid-cabin-img-mb {
        width: 133px;
        height: 85px;
    }

    .b2-free-checked-img {
        width: 89px;
        height: 85px;
    }

    .b2-paid-checked-img {
        width: 89px;
        height: 85px;
    }

    .weight-container {
        @apply flex items-center justify-center my-1;
        width: 133px;

        .b2m-weight {
            @apply w-1/2;
        }
    }

    .b2m-weight {
        @apply font-semibold font-body text-be-blue;
        font-size: 11px;
        text-align: center;
        width: 90px;
        height: 12px;

        &.wider {
            @apply flex items-center mt-1;
            width: 133px;
        }

        span {
            @apply inline-block font-semibold font-body text-be-blue w-1/2;
            text-align: center;
        }
    }

    .b2m-bag-name {
        @apply flex items-center justify-center font-body font-semibold text-be-blue my-0 mx-auto;
        max-width: 133px;
        height: 45px;
        font-size: 12px;
        text-align: center;
        line-height: 11px;

        &.longer {
            max-width: 133px;
            height: 32px;

            &.dc {
                height: 23px;
            }
        }
    }

    .b2-illustration-ribbon {
        width: 145px;
        height: 19px;
        font-size: 10px;
        top: 4px;
        right: -14px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;

        &:after {
            border-width: 7px 14px 0 0;
        }
    }
}
