.credit-shell {
    display: flex;
    width: 100%;
    position: relative;
    padding: 14px;
    border: 1px solid $brand-secondary;
    border-radius: $border-radius-sm;
    color: $brand-secondary;
    background: white;
    align-items: center;
    justify-content: space-between;
}

.credit-shell-section {
    display: flex;
    align-items: center;

    img {
        width: 50px;
        margin-right: 15px;
    }
}

.credit-shell-caption {
    margin-right: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50px;
    padding-top: 5px;

    span {
        &.credit-shell-maincaption {
            color: $brand-secondary;
            font-family: "ClanOT-Bold", sans-serif;
            font-size: 22px;
            font-weight: 900;
            margin-bottom: 5px;
        }
        &.credit-shell-subcaption {
            color: $brand-secondary;
            font-family: "Lato", sans-serif;
            font-size: 16px;
        }
    }
}

.credit-shell-amount {
    color: $brand-secondary;
    font-family: "ClanOT-Bold";
    font-size: 38px;
    line-height: 1;
    padding-top: 8px;
    position: relative;
    top: 10px;

    span {
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        font-family: "Lato", sans-serif;
        font-size: 18px;
        font-weight: 400;
    }
}

.credit-shell-button {
    background: $brand-secondary;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 45px;
    border-radius: 10px;
    border: 2px solid transparent;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    letter-spacing: normal;
    text-transform: none;
    font-family: "Lato", sans-serif;

    &:hover:not(:disabled) {
        background: #fff;
        color: $brand-secondary;
    }
}

.credit-shell-applied {
    span {
        font-family: "ClanOT-Bold", sans-serif;
        font-size: 38px;
        color: $banco-estado-color;
        margin-right: 40px;
        line-height: 1;
        position: relative;
        top: -7px;
    }

    i {
        font-size: 52px;
        color: $brand-secondary;
    }
}

.credit-shell-used-info {
    color: $brand-secondary;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    padding: 15px;
    background: rgba(#ee801d, 0.5);
    border: 1px solid #8a8a8a;
    border-radius: 5px;
    margin-top: 15px;
}

.modal-content {
    &.payment-modal-content {
        &.credit-shell-modal {
            max-width: 780px;
        }
    }
}

#mainContentPayment {
    .tabs {
        nav {
            ul {
                li {
                    &.tab7,
                    &.tab8,
                    &.tab9,
                    &.tab-banco-estado-payment-method {
                        position: relative;

                        label {
                            display: flex;
                            flex-direction: column;
                            margin: 0 30px 30px 0;

                            span {
                                font-family: "Lato", sans-serif;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 1.2;
                            }

                            .be-payment-card-img {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 210px;
                                height: 110px;
                                border: solid 1px #8a8a8a;
                                border-radius: $border-radius-sm;
                                max-width: 100%;
                                -ms-interpolation-mode: bicubic;
                                background: #fff;
                                margin-bottom: 10px;
                                transition: all 0.25s ease-in-out;

                                .be-credit-cards {
                                    height: 65px;
                                }
                            }

                            &:hover {
                                .be-payment-card-img {
                                    transform: scale(1.1);
                                    box-shadow: $box-shadow-medium;
                                    border-color: $brand-secondary;
                                }
                            }
                        }
                    }
                }
            }
        }

        #tab7:checked ~ li.tab7 label,
        #tab8:checked ~ li.tab8 label,
        #tab9:checked ~ li.tab9 label,
        [id^="be_payment_tab_"]:checked + li label {
            .be-payment-card-img {
                border-color: #ab272f;
                border-width: 2px;
            }

            &:hover {
                .be-payment-card-img {
                    transform: scale(1);
                    box-shadow: none;
                    border-color: #ab272f;
                    border-width: 2px;
                }
            }

            cursor: default;

            + .payment-card-select-tick {
                transform: scale(1);
            }
        }
    }
}

.payment-card-select-tick {
    display: inline-block;
    width: 40px;
    position: absolute;
    top: -20px;
    left: 190px;
    z-index: 10;
    transform: scale(0);
    transition: all 0.25s ease-in-out;
}

.banco-estado-payment-form-info {
    margin-bottom: 30px;
    line-height: 1.2;

    span {
        font-size: 18px;
        font-family: "Lato", sans-serif;
        font-style: italic;
        color: $brand-secondary;

        &:nth-child(2) {
            font-weight: 600;
        }

        &:nth-child(3) {
            color: $banco-estado-color;
            font-weight: 600;
        }

        &:nth-child(5) {
            color: $banco-estado-color;
        }

        &:nth-child(6) {
            font-family: "Gotham-Book", sans-serif;
            font-style: normal;
            color: $banco-estado-color;
            font-size: 17px;
        }
    }

    img {
        position: relative;
        top: 1px;
        height: 15px;
    }
}

.credit-shell-message {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    padding: 10px 15px;
    color: $brand-secondary;
    border-radius: 5px;
    background: rgba($banco-estado-color, 0.5);
}

.credit-shell-remainder {
    @apply font-body font-semibold text-be-cyan-2;
    font-size: 16px;
    margin-top: 15px;

    span {
        @apply font-black;
    }
}

#mainContentPayment {
    .tabs {
        nav {
            ul {
                li {
                    &.tab8,
                    &.tab9,
                    &.tab-banco-estado-payment-method {
                        &.push-down {
                            margin-bottom: 50px;
                        }
                    }
                }
            }
        }
    }
}

#mainContentPayment {
    .tabs {
        nav {
            .banco-estado-credit-card-user-info {
                @apply pointer-events-none absolute bg-be-orange-3 font-body text-white;
                border-radius: 7px;
                padding: 12px 15px 12px 33px;
                max-width: 305px;
                font-size: 13px;
                top: 100%;
                left: 50%;
                transform: translate(-50%, 20px);

                &:after {
                    @apply absolute bg-be-orange-3;
                    content: "";
                    width: 20px;
                    height: 20px;
                    top: -10px;
                    left: 50%;
                    transform: translateX(-50%) rotate(45deg);
                }

                ul {
                    @apply flex flex-col;

                    li {
                        @apply nowrap relative;
                        line-height: 18px;
                        margin-left: 0;
                        text-align: left;

                        &:before {
                            @apply absolute text-white;
                            font-family: "jetsmart-v2" !important;
                            content: "\e92e"; // DEVNOTE Might change if icons change
                            font-size: 12px;
                            top: 50%;
                            left: -20px;
                            transform: translateY(-50%);
                        }

                        span {
                            &.emphasis {
                                @apply font-black text-white;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.be2-credit-header {
    @apply flex items-center font-body font-black text-be-blue;
    font-size: 22px;

    i {
        @apply font-semibold text-be-blue;
        font-size: 30px;
        margin-right: 10px;
    }
}

.be2-credit-subheader {
    @apply font-body font-bold text-be-blue;
    font-size: 20px;
}

.js-input {
    &.greyed-card-field {
        @apply bg-be-gray-6;
    }
}

.auto-cf-warning {
    @apply mx-auto my-2 flex items-center justify-center font-body font-semibold leading-tight text-be-red-2;
    font-size: 18px;
    text-align: left;
    max-width: 750px;

    i {
        margin-right: 10px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .credit-shell-caption {
        margin-right: 20px;
    }

    .credit-shell-button {
        padding: 17px 25px;
    }

    .auto-cf-warning {
        font-size: 18px;
        max-width: 650px;
    }
}

@media #{$medium} {
    .credit-shell-caption {
        margin-right: 10px;
    }

    .credit-shell-button {
        padding: 17px;
        margin-left: 10px;
    }

    .credit-shell-amount {
        font-size: 28px;
    }

    .credit-shell-caption {
        span {
            &.credit-shell-maincaption {
                font-size: 18px;
            }
            &.credit-shell-subcaption {
                font-size: 14px;
            }
        }
    }

    .auto-cf-warning {
        font-size: 16px;
        max-width: 470px;
    }
}

@media #{$small-down} {
    .credit-shell-caption {
        margin-right: 10px;
    }

    .credit-shell-button {
        padding: 17px;
        margin-left: 10px;
    }

    .credit-shell-amount {
        font-size: 28px;
    }

    .credit-shell-caption {
        span {
            &.credit-shell-maincaption {
                font-size: 18px;
            }
            &.credit-shell-subcaption {
                font-size: 14px;
            }
        }
    }
}

@media #{$small} {
    #mainContentPayment {
        .tabs {
            nav {
                .banco-estado-credit-card-user-info {
                    padding: 7px 7px 7px 17px;

                    &:after {
                        width: 13px;
                        height: 13px;
                        top: -7px;
                    }

                    ul {
                        li {
                            font-size: 9px;
                            line-height: 14px;

                            span {
                                &.emphasis {
                                    font-size: 9px;
                                }
                            }

                            &:before {
                                font-size: 7px;
                                left: -13px;
                            }
                        }
                    }
                }
            }
        }
    }

    .auto-cf-warning {
        font-size: 16px;
        max-width: 470px;
    }
}

@media #{$xsmall} {
    .credit-shell {
        @apply block;
    }

    .credit-shell-caption {
        @apply block;

        span {
            @apply block;
        }
    }

    .credit-shell-section {
        &:first-child {
            @apply block;
            text-align: center;
        }

        &:last-child {
            @apply justify-center;
            margin-top: 20px;
        }
    }

    #mainContentPayment {
        .tabs {
            nav {
                .banco-estado-credit-card-user-info {
                    padding: 10px 10px 10px 23px;

                    &:after {
                        width: 13px;
                        height: 13px;
                        top: -7px;
                    }

                    ul {
                        li {
                            font-size: 10px;
                            line-height: normal;
                            margin: 2px 0;

                            span {
                                &.emphasis {
                                    font-size: 10px;
                                }
                            }

                            &:before {
                                font-size: 12px;
                                left: -15px;
                            }
                        }
                    }
                }

                ul {
                    li {
                        &.tab7,
                        &.tab8,
                        &.tab9,
                        &.tab-banco-estado-payment-method {
                            @apply flex justify-center;

                            .payment-card-select-tick {
                                left: 50%;
                                transform: scale(0) translateX(70px);
                            }
                        }
                    }
                }
            }

            #tab7:checked ~ li.tab7 label,
            #tab8:checked ~ li.tab8 label,
            #tab9:checked ~ li.tab9 label,
            [id^="be_payment_tab_"]:checked + li label {
                + .payment-card-select-tick {
                    transform: scale(1) translateX(70px);
                }
            }
        }
    }

    .use-points {
        font-size: 13px;
    }

    .auto-cf-warning {
        @apply bg-white;
        font-size: 14px;
        max-width: 90%;
        border-radius: 5px;
        padding: 5px 10px;
    }
}
