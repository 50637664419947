$corner-size: 24px;
$corner-size-sm: 15px;

.i2-gds-optionals-info {
    @apply mb-6 font-black text-be-blue;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

dc-tabs {
    .tab-navigation {
        @apply flex w-full;
        padding: 0 30px;
        margin-bottom: 1px;

        li {
            @apply relative -mb-px flex w-1/4 items-center justify-center overflow-hidden border border-r-0 border-solid border-be-gray-11 bg-white font-body font-black text-be-blue shadow-medium;
            padding: 0 8px;
            height: 60px;
            font-size: 18px;
            text-overflow: ellipsis;
            clip-path: polygon(
                0 0,
                0 0,
                calc(100% - #{$corner-size}) 0%,
                100% $corner-size,
                100% 100%,
                100% 100%,
                0 100%,
                0 0
            );

            &:after {
                @apply absolute bg-be-gray-6;
                content: "";
                width: 50px;
                height: 1px;
                top: 10px;
                right: -37px;
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:hover {
                @apply cursor-pointer border-be-blue bg-be-blue text-white;

                &:after {
                    display: none;
                }
            }

            &.active {
                @apply cursor-default border-be-cyan-5 bg-be-cyan-5 text-white;

                &.with-warning {
                    @apply cursor-default border-brand-primary bg-brand-primary text-white;
                }

                &:after {
                    display: none;
                }
            }

            &.highlighted:not(.active) {
                @apply cursor-default border-r border-be-cyan-5 bg-white text-be-cyan-5;

                &:after {
                    @apply bg-be-cyan-5;
                }

                &:hover {
                    @apply cursor-pointer border-be-blue bg-be-blue text-white;
                }
            }

            &.with-warning:not(.active) {
                @apply cursor-default border-r border-brand-primary bg-white text-brand-primary;

                &:after {
                    @apply bg-brand-primary;
                }

                &:hover {
                    @apply cursor-pointer border-brand-primary bg-brand-primary text-white;
                }
            }

            .js-refund-rw-highlight-lines {
                @apply text-be-cyan-5;
                font-size: 25px;
            }

            &:last-child {
                @apply border-r;

                &:after {
                    right: -37px;
                }
            }
        }

        .highlight-icon-container {
            @apply relative text-be-cyan-5;

            &:after {
                @apply absolute mr-1 font-normal;
                right: -20px;
                top: -12px;
                font-family: "js-refund-icons" !important;
                font-size: 25px;
                content: "\e904"; // DEVNOTE Might change if icons change
            }
        }
    }
}

.tab-panel {
    @apply w-full border border-solid border-white bg-white shadow-md;
    border-radius: 12px;
    padding: 30px 40px;
    display: none;

    &.active {
        @apply block;
    }
}

.i2-itinerary-section {
    &.i2-tabs {
        @apply border-0 bg-transparent shadow-none;
        grid-area: tabs;

        &.divide-four {
            .tab-navigation {
                li {
                    width: 25%;
                }
            }
        }

        &.divide-five {
            .tab-navigation {
                li {
                    width: 20%;
                }
            }
        }

        &.divide-six {
            .tab-navigation {
                li {
                    width: 16.6666%;
                }
            }
        }
    }
}

.i2-tab-title {
    @apply flex items-center text-be-blue;
    font-size: 28px;
    margin-bottom: 30px;

    &.pull-up {
        margin-bottom: 20px;
    }

    > div {
        @apply font-body font-black;
    }

    i {
        @apply text-be-cyan-2;
        font-size: 50px;
        margin-right: 15px;
    }
}

.i2-tab-subtitle {
    @apply text-be-blue;
    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-gds-optionals-info {
        font-size: 14px;
    }

    dc-tabs {
        .tab-navigation {
            padding: 0 15px;

            li {
                font-size: 16px;
            }
        }
    }
}

@media #{$medium} {
    .i2-gds-optionals-info {
        font-size: 11px;
    }

    dc-tabs {
        .tab-navigation {
            padding: 0 15px;

            li {
                font-size: 13px;
                line-height: 1.3;
            }
        }
    }

    .tab-panel{
        padding: 30px;
    }

    .i2-tab-title {
        font-size: 24px;
        margin-bottom: 24px;

        &.pull-up {
            margin-bottom: 12px;
        }

        i {
            font-size: 44px;
            margin-right: 10px;
        }
    }
}

@media #{$small} {
    .i2-gds-optionals-info {
        font-size: 12px;
        line-height: 16px;
    }

    dc-tabs {
        .tab-navigation {
            padding: 0 20px;

            li {
                height: 36px;
                font-size: 12px;
                line-height: 1.3;
                clip-path: polygon(
                    0 0,
                    0 0,
                    calc(100% - #{$corner-size-sm}) 0%,
                    100% $corner-size-sm,
                    100% 100%,
                    100% 100%,
                    0 100%,
                    0 0
                );

                &:after {
                    right: -45px;
                }

                &:last-child {
                    &:after {
                        right: -46px;
                    }
                }
            }
        }
    }

    .i2-tab-title {
        font-size: 17px;
        margin-bottom: 24px;

        &.pull-up {
            margin-bottom: 12px;
        }

        i {
            font-size: 30px;
            margin-right: 10px;
        }
    }

    .i2-tab-subtitle {
        margin-top: 6px;
        font-size: 13px;
        line-height: 16px;
    }
}

@media #{$xsmall} {
    .i2-gds-optionals-info {
        font-size: 12px;
        line-height: 16px;
    }

    .i2-itinerary-section {
        &.i2-tabs {
            @apply overflow-hidden shadow-medium;
        }
    }

    .i2-accordion-header {
        @apply flex items-center justify-between bg-white text-be-blue;
        padding: 0 11px 0 22px;
        height: 38px;
        font-size: 17px;

        span {
            @apply font-body font-black;
        }

        i {
            @apply font-semibold text-be-blue;
            font-size: 25px;
            transition: all 0.25s ease-in-out;
        }

        .js-refund-rw-highlight-icon-mobile {
            font-size: 18px;
        }

        &.first {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        &.last {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        &.highlighted {
            @apply text-be-cyan-2;

            i {
                @apply text-be-cyan-2;
            }
        }

        &.open {
            @apply bg-be-cyan-2 text-white;

            i {
                @apply text-white;
                transform: rotate(90deg);
            }

            + .i2-accordion-content {
                max-height: 5000px;
                padding-bottom: 15px;
            }
        }
    }

    .i2-accordion-content {
        @apply overflow-hidden border-b border-solid border-be-gray-11 bg-white pb-0;
        max-height: 0;
        transition: all 0.25s ease-in-out;

        &:last-child {
            @apply border-b-0;
        }

        .etd-banner {
            margin: 15px;
        }
    }

    .i2-tab-title {
        font-size: 17px;
        margin-bottom: 5px;
        padding: 10px 15px;

        &.pull-up {
            margin-bottom: 5px;
        }

        i {
            font-size: 30px;
            margin-right: 10px;
        }
    }

    .i2-tab-subtitle {
        @apply mt-0;
        font-size: 13px;
        line-height: 16px;
    }
}
