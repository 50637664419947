.no-longer-available-modal-container {
    .modal-content {
        @apply flex flex-col;

        .modal-header {
            @apply font-bold;
        }

        .modal-body {
            .no-longer-available-modal-button-container {
                @apply flex w-full justify-center;
                text-align: center;
                margin-top: 30px;
            }
        }
    }
}

.flight-move-modal-link {
    @apply flex justify-center my-4 w-full;
    text-align: center;

    a {
        @apply underline text-be-blue cursor-pointer;
        text-align: center;

        &:hover {
            @apply text-be-red;
        }
    }
}

/* MEDIA QUERIES */

@media #{$xsmall} {
    .no-longer-available-modal-container {
        .modal-content {
            .modal-body {
                .no-longer-available-modal-button-container {
                    button {
                        @apply w-full;
                    }
                }
            }
        }
    }
}
