.free-seat-info {
    @apply font-semibold text-be-blue font-body;
    font-size: 16px;
    margin-right: 7px;
    max-width: 320px;

    span {
        @apply font-normal text-be-blue font-emphasis;
        font-size: 17px;
    }
}

.discounted-seat-info {
    line-height: 1.4;

    > div {
        @apply font-semibold text-be-blue font-body;
        font-size: 16px;
    }

    span {
        @apply font-normal text-be-blue font-emphasis inline;
        font-size: 17px;
    }
}

.be2-seats-panel {
    @apply bg-be-light-gray;
    margin-bottom: 18px;
    padding: 25px 20px;
    border-radius: 18px;
}

.be2-free-seats-panel {
    @apply flex items-center justify-between;

    > i {
        @apply text-be-cyan-2;
        font-size: 53px;
        margin-right: 7px;
    }
}

.be2-discounted-seats-panel {
    @apply flex items-center;

    > i {
        @apply text-be-cyan-2;
        font-size: 53px;
        margin-right: 15px;
    }
}

.be2-no-seats-panel {
    @apply bg-be-cyan-3;
    padding: 15px 20px;

    span {
        @apply block;
        margin-top: 8px;
    }
}

.free-seat-images {
    min-width: 70px;

    i {
        font-size: 32px;
    }

    .js-be2-base-seat,
    .js-be2-first-seat {
        @apply text-be-gray-3;
    }
}

.be2-seat-free-info {
    @apply flex items-center italic border-be-gray-3 border-l border-solid text-be-gray-3 font-extra;
    font-size: 15px;
    padding-left: 5px;
    margin: 0 10px 0 5px;
    width: 78px;

    i {
        @apply text-be-gray-3;
        font-size: 26px;
        margin-right: 7px;
    }
}

.be2-seat-discount-info {
    @apply flex flex-col border-be-gray-3 border-l border-solid text-be-gray-3;
    padding-left: 5px;
    margin: 0 10px 0 5px;
    width: 78px;
}

.be2-seat-discount-info-1 {
    @apply text-be-gray-3 font-extra;
    font-size: 10px;
    padding-left: 5px;
}

.be2-seat-discount-info-2 {
    @apply text-be-gray-3 font-emphasis whitespace-nowrap;
    font-size: 16px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .free-seat-info {
        font-size: 15px;

        span {
            font-size: 15px;
        }
    }

    .be2-seat-free-info {
        font-size: 14px;
        padding-left: 5px;
        margin: 0 10px 0 5px;
        width: 75px;

        i {
            font-size: 27px;
            margin-right: 5px;
        }
    }

    .be2-seat-discount-info {
        padding-left: 5px;
        margin: 0 10px 0 5px;
        width: 75px;

        .be2-seat-discount-info-1 {
            @apply mb-0;
        }

        .be2-seat-discount-info-2 {
            font-size: 15px;
        }
    }
}
