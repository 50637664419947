.checkin-security-terms-wrapper {
    @apply bg-white border border-solid border-brand-secondary;

    height: 44px;
    border-radius: 5px;
    margin-bottom: 20px;
    padding-left: 10px;

    .checkin-security-terms-label {
        .checkin-security-terms-text {
            @apply leading-none font-bold text-brand-secondary;

            font-size: 14px;
        }
    }
}

@media #{$xsmall} {
    .checkin-security-terms-wrapper {
        .checkin-security-terms-label {
            .checkin-security-terms-text {
                font-size: 12px;
            }
        }
    }
}
