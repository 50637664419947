.seatmap-recommendator-banner {
    @apply flex flex-col items-center justify-center text-white font-emphasis p-2;
    width: calc(100% + 30px);
    height: 180px;
    margin: -15px -15px 15px;
    background: url(/Images/Seatmap/recommendator-bg.png) no-repeat center center;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    span {
        @apply font-body font-bold;
        font-size: 18px;
        line-height: 22px;
    }
}

.seatmap-recommendator-buttons {
    @apply bg-brand-secondary text-white flex items-center relative;
    height: 50px;
    margin: 0 -25px;

    &:after {
        @apply absolute bg-white z-10;
        content: "";
        top: 7px;
        left: 50%;
        height: 36px;
        width: 1px;
    }
}
.seatmap-recommendator-button {
    @apply flex items-center relative font-black font-body w-1/2;
    padding: 0 45px 0 25px;
    font-size: 14px;
    line-height: 18px;

    &:after {
        @apply absolute font-normal vertical-center;
        font-family: "jetsmart-v2" !important;
        right: 15px;
        content: "\e9ba"; // DEVNOTE Might change if icons change
        font-size: 25px;
    }
}

/* MEDIA QUERIES */

@media #{$medium-down} {
    .seatmap-recommendator-banner {
        @apply p-4 rounded-none;
        height: 180px;
        margin: 0 -25px 0;
        font-size: 18px;
        line-height: 22px;
        width: calc(100% + 50px);

        span {
            font-size: 14px;
            line-height: 17px;
        }
    }
}

@media #{$xsmall} {
    .seatmap-recommendator-banner {
        height: 200px;
    }
}