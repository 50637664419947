.pcra-modal {
    @apply p-0 leading-normal;
    width: 700px;
    height: 536px;
    max-width: 95%;
    max-height: 95%;
}

.pcra-modal-header {
    @apply flex items-center justify-center p-6 font-extrabold;
    font-size: 22px;
    height: 72px;
}

.pcra-modal-footer {
    @apply flex items-center justify-center p-6;

    height: 72px;
    border-top: 2px solid #d9d9d9;
}

.pcra-details-content {
    @apply grid gap-8 grid-cols-2;

    padding: 24px;

    .column {
        .row {
            @apply m-0;

            span {
                @apply w-1/2 text-cug-dark-gray mx-0 my-1 font-semibold;

                &.pcra-row-value {
                    @apply text-new-ui-blue font-bold;
                }

                text-align: left;
                font-size: 16px;
            }
        }
    }
}




//MEDIA QUERIES

@media #{$small} {
    .pcra-modal {
        height: 444px;
    }

    .pcra-modal-footer,
    .pcra-modal-header {
        height: 62px;
    }
}

@media #{$xsmall} {
    .pcra-modal {
        height: 444px;
    }

    .pcra-details-content {
        @apply grid grid-cols-1;

        padding: 24px;

        .column {
            .row {
                @apply mb-1;

                span {
                    @apply leading-4;

                    font-size: 15px;
                }
            }
        }
    }

    .pcra-modal-footer,
    .pcra-modal-header {
        height: 62px;
    }
}
