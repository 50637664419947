.profile-benefit-widget-container {
    @apply overflow-hidden;
    grid-area: benefit;
    height: 120px;
    border-radius: 12px;
}

.profile-benefit-widget {
    @apply bg-white text-be-blue grid h-full w-full;
    grid-row-gap: 0;
    grid-column-gap: 0;
    grid-template-columns: 100px 160px auto 150px;
    grid-template-areas: "slogan journeys icons button";
}

.pbw-section-slogan {
    @apply border-r border-solid border-bg-gray-4 font-body font-bold flex flex-col items-center justify-center w-full h-full;
    grid-area: slogan;
    font-size: 16px;
    line-height: 19px;

    span {
        @apply font-heavy font-normal text-be-blue block;
        font-size: 21px;
        line-height: 25px;
    }
}

.pbw-section-journeys {
    @apply flex items-center flex-col justify-center w-full h-full;
    grid-area: journeys;
}

.pbw-trip-discount-ribbon {
    @apply bg-be-cyan-2 text-white font-semibold font-body flex items-center justify-center;
    width: 145px;
    height: 36px;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
}

.pbw-trip-discount-info {
    @apply text-be-blue font-body font-bold w-full;
    width: 130px;
    margin-top: 8px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;

    span {
        @apply font-black;
    }
}

.pbw-section-icons {
    @apply flex items-start w-full h-full;
    grid-area: icons;
}

.pbw-icon-description {
    @apply text-be-blue font-body font-semibold flex flex-col items-center w-1/4;
    margin-top: 15px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    i {
        @apply text-be-cyan-2;
        font-size: 52px;
        margin-bottom: 11px;
    }
}

.pbw-section-button {
    @apply border-l border-solid border-bg-gray-4 font-body flex flex-col items-center justify-center w-full h-full;
    grid-area: button;

    .rounded-primary-btn {
        @apply flex-col font-normal items-center whitespace-normal;
        margin: 5px;
        padding: 8px 35px 8px 15px;
        min-width: 96%;
        height: 40px;
        font-size: 14px;

        span {
            @apply inline-block;
        }
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    ac-profile-benefit-widget {
        @apply h-full;
        border-radius: 7px;
    }

    .profile-benefit-widget-container {
        height: 170px;
    }

    .profile-benefit-widget {
        grid-template-columns: auto 125px;
        grid-template-areas:
            "slogan slogan"
            "journeys journeys"
            "icons button";
    }

    .pbw-section-slogan {
        @apply border-none flex-row leading-none;
        height: 44px;

        span {
            @apply inline leading-none relative;
            font-size: 17px;
            margin-left: 3px;
            top: 1px;
        }
    }

    .pbw-section-journeys {
        @apply flex-row w-full;
    }

    .pbw-trip-discount-ribbon {
        width: 150px;
        font-size: 14px;
        margin-right: 6px;
        height: 28px;
    }

    .pbw-trip-discount-info {
        @apply w-auto m-0 leading-none;
    }

    .pbw-section-icons {
        padding: 0 5px;
    }

    .pbw-icon-description {
        @apply px-1;
        margin-top: 8px;
        font-size: 10px;
        line-height: 12px;

        i {
            font-size: 45px;
            margin-bottom: 6px;
        }
    }

    .pbw-section-button {
        @apply border-none;

        .rounded-primary-btn {
            @apply items-center;

            padding: 8px 27px 8px 15px;
            font-size: 12px;
        }
    }
}

@media #{$xsmall} {
    ac-profile-benefit-widget {
        @apply h-auto;
        border-radius: 6px;
    }

    .profile-benefit-widget-container {
        height: 340px;
    }

    .profile-benefit-widget {
        padding: 15px 0;
        grid-template-columns: 1fr;
        grid-template-areas:
            "slogan"
            "journeys"
            "icons"
            "button";
    }

    .pbw-section-slogan {
        @apply border-none flex-row leading-none;
        font-size: 13px;
        height: 25px;

        span {
            @apply inline leading-none relative;
            font-size: 13px;
            margin-left: 3px;
            top: 1px;
        }
    }

    .pbw-trip-discount-ribbon {
        width: 120px;
        height: 34px;
        font-size: 13.3px;
    }

    .pbw-trip-discount-info {
        @apply w-full leading-none;
        margin-top: 5px;
        font-size: 13px;
    }

    .pbw-section-icons {
        @apply grid grid-cols-2 place-content-center mx-auto;
        width: 215px;
        grid-area: icons;
    }

    .pbw-icon-description {
        @apply w-full;
        margin-top: 10px;

        i {
            font-size: 41px;
            margin-bottom: 6px;
        }
    }

    .pbw-section-button {
        @apply whitespace-normal flex-row;
        margin: 15px 0;

        .rounded-primary-btn {
            @apply font-black flex-row items-center font-normal;
            padding: 8px 35px 8px 15px;
            min-width: unset;
            width: 40%;
            height: 35px;
            font-size: 13px;

            span {
                @apply inline font-black font-normal;
                margin-left: 3px;
            }
        }
    }
}
