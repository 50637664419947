/*
 * Float classes
 */

 // float classes
.float-center {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.float-right {
    float: right;
}

.float-left {
    float: left
}