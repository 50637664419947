@import "./giftcard-colors";

.giftcard-sb-push-down {
    margin: 20px 0;
}

.giftcard-sidebar-info {
    position: relative;
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $giftcard-blue;
    color: #fff;
    text-transform: uppercase;
    font-family: "ClanOT-Bold", sans-serif;
    font-size: 32px;

    i {
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(68px);
        font-size: 22px;
        color: #fff;
    }

    span {
        font-family: "ClanOT-Book", sans-serif;
        font-size: 30px;
        color: #fff;
    }

    .giftcard-sidebar-logo {
        position: absolute;
        top: 54px;
        left: 50%;
        transform: translateX(19px);
        width: 200px;
        height: 14px;
        display: block;

        svg {
            height: 14px;
            fill: #fff;
        }
    }
}
