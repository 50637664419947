.profile-widget-container {
    position: relative;
    width: 100%;
    margin: 30px 0;
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 250px auto auto auto;
    grid-template-areas:
        "person person person person trips trips trips trips trips trips trips trips"
        "advert advert advert advert advert advert advert advert advert advert advert advert"
        "giftcard giftcard giftcard giftcard giftcard giftcard dc dc dc search search search";
    justify-items: stretch;
    align-items: stretch;
    transition: all 0.25s ease-in-out;

    &.staff {
        grid-template-rows: 120px 250px auto auto;
        grid-template-areas:
            "person person person person benefit benefit benefit benefit benefit benefit benefit benefit"
            "person person person person trips trips trips trips trips trips trips trips"
            "advert advert advert advert advert advert advert advert advert advert advert advert"
            "giftcard giftcard giftcard giftcard giftcard giftcard search search search search search search";
    }

    &.pwc-club-member {
        grid-template-rows: 298px auto auto;
    }

    &.banco-estado-5 {
        grid-template-rows: 326px auto auto;

        &.pwc-club-member {
            grid-template-rows: 356px auto auto;
        }
    }

    &.banco-estado-5,
    &.banco-estado-6 {
        grid-template-areas:
            "person person person person trips trips trips trips trips trips trips trips"
            "bancoe bancoe bancoe bancoe bancoe bancoe"
            "giftcard giftcard giftcard giftcard giftcard giftcard dc dc dc search search search";
    }
}

.profile-widget {
    @apply bg-white text-be-blue border border-solid border-be-light-gray;
    border-radius: 12px;
    transition: all 0.25s ease-in-out;
}

/* MEDIA QUERIES */

@media #{$medium} {
    .profile-widget-container {
        margin: 25px 20px 150px;
        width: calc(100% - 40px);
        column-gap: 20px;
        row-gap: 20px;
    }
}

@media #{$small} {
    .profile-widget-container {
        margin: 20px 10px;
        width: calc(100% - 20px);
        column-gap: 15px;
        row-gap: 15px;
        grid-template-rows: 140px auto;
        margin-bottom: 100px;

        &.pwc-club-member {
            grid-template-rows: 197px auto;

            &.banco-estado-6 {
                grid-template-rows: 173px auto;
            }
        }

        &.banco-estado-5 {
            grid-template-rows: 205px auto;

            &.pwc-club-member {
                grid-template-rows: 227px auto;
            }
        }

        &.staff {
            grid-template-rows: 170px 140px auto;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-areas:
                "person person benefit benefit benefit benefit"
                "trips trips trips trips trips trips"
                "advert advert advert advert advert advert"
                "giftcard giftcard giftcard search search search";
        }
    }
}

@media #{$xsmall} {
    .profile-widget-container {
        margin: 20px 10px;
        width: calc(100% - 20px);
        grid-template-areas:
            "trips"
            "advert"
            "giftcard"
            "dc"
            "search";
        row-gap: 15px;
        grid-template-columns: auto;
        grid-template-rows: auto;

        &.pwc-club-member,
        &.banco-estado-5,
        &.banco-estado-6,
        &.staff {
            grid-template-rows: auto;

            &.pwc-club-member {
                grid-template-rows: auto;
            }
        }

        &.banco-estado-5,
        &.banco-estado-6 {
            grid-template-areas:
                "trips"
                "bancoe"
                "giftcard"
                "dc"
                "search";
        }

        &.staff {
            grid-template-areas:
                "benefit"
                "trips"
                "advert"
                "giftcard"
                "dc"
                "search";
        }
    }
}
