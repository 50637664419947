.cug2b-title-container {
    @apply flex items-start;
    margin: 20px 0 35px;

    i {
        @apply border-l-2 border-solid border-cug-red text-cug-dark-gray;
        padding: 5px 0 5px 15px;
        margin-right: 8px;
        font-size: 39px;
    }

    h1 {
        @apply font-heavy text-cug-dark-gray;
        font-size: 27px;
        line-height: 32px;
    }

    h2 {
        @apply text-cug-dark-gray;
        font-size: 19px;
        line-height: 23px;

        span {
            @apply font-bold;
            font-size: 19px;
            line-height: 23px;
        }
    }

    &.peru-compra {
        span {
            @apply whitespace-nowrap;
        }
    }

    &.chile-compra {
        @apply flex items-center;
        margin: 20px 0;

        .chile-compra-title {
            @apply m-0 flex items-center;
        }
    }
}

.cug2b-page-subtitle {
    @apply font-emphasis text-cug-dark-gray;
    font-size: 21px;
    line-height: 25px;
}

.cug2-filter-by-date-container {
    margin-bottom: 20px;
}

.cug2-filter-by-date {
    @apply flex flex-row justify-between;
}

.cug2-label-and-datepickers {
    @apply relative flex flex-col;

    label {
        @apply mb-2;
    }
}

.cug2-datepickers {
    @apply flex items-center;
    width: 316px;
}

.cug2-filter-date-input {
    @apply relative border border-solid border-cug-dark-gray text-cug-dark-gray;
    height: 42px;
    font-size: 19px;
    width: 158px;
}

.cug2-filter-date-from {
    @apply rounded-l-lg border-r-0;
}

.cug2-filter-date-to {
    @apply rounded-r-lg;
}

.cug2-filter-button-container {
    @apply flex flex-col justify-end;
}

.cug2-empty-filters {
    @apply mt-4 inline-flex cursor-pointer justify-center text-cug-gray-3 underline;
    width: 180px;

    &:hover {
        @apply text-dc-dark-blue underline;
    }
}

.cug2b-search-and-title {
    @apply flex items-center justify-between;
    margin-bottom: 30px;

    &.push-down {
        margin-top: 60px;
    }
}

.cug2b-search-and-buttons {
    @apply flex items-center justify-between;
    margin-bottom: 25px;

    .cug2b-pill {
        &:not(:last-child) {
            margin-right: 25px;
        }
    }
}

.cug2b-searchbox {
    @apply relative;

    input {
        @apply border border-solid border-cug-dark-gray text-cug-dark-gray;
        border-radius: 5px;
        width: 235px;
        height: 55px;
        font-size: 20px;
    }

    i {
        @apply pointer-events-none absolute text-cug-dark-gray vertical-center;
        right: 1.5rem;
        font-size: 28px;
    }
}

.cug2b-pill {
    @apply relative inline-flex cursor-pointer items-center whitespace-normal rounded-full border border-solid border-cug-dark-gray font-body font-bold normal-case text-cug-dark-gray;
    text-align: left;
    width: min-content;
    font-size: 13px;
    line-height: 16px;
    height: 55px;
    padding: 0 20px;
    letter-spacing: normal;

    &.warning {
        @apply border-cug-red text-cug-red;

        i {
            @apply text-cug-red;
        }

        &:hover {
            @apply bg-cug-light-gray text-white;

            i {
                @apply text-white;
            }
        }
    }

    i {
        @apply text-cug-dark-gray common-transition;
        margin-right: 8px;
        font-size: 27px;
    }

    &:hover {
        @apply bg-cug-light-gray text-white;

        i {
            @apply text-white;
        }
    }
}

.cug2b-modal-question {
    font-size: 18px;
    line-height: 21px;

    span {
        @apply font-emphasis text-cug-red;
        font-size: 18px;
        line-height: 21px;
    }
}

.cug2b-change-status-modal {
    width: 500px;
    height: 370px;
}

.cug2b-save-success-modal {
    width: 470px;
    height: 265px;
}

.cug2b-reset-password-icon {
    @apply mr-4;
}

.cug2b-reset-password-modal {
    width: 600px;
    height: 350px;
}

.cug2b-edit-user-modal,
.cug2b-add-user-modal {
    @apply absolute cursor-default font-body;
    top: 31px;
    right: -15px;
    width: 760px;
    z-index: 100000;
    border-radius: 10px;
    background: rgba(#f6f6f6, 0.9);
    box-shadow: 0 0 0 1px #2c3438;
    padding: 25px;

    &:before {
        @apply absolute;
        content: "";
        top: -8px;
        right: 25px;
        width: 14px;
        height: 14px;
        border-top: 1px solid #2c3438;
        border-right: 1px solid #2c3438;
        z-index: 1;
        transform: rotate(-45deg);
    }

    &:after {
        @apply absolute;
        content: "";
        top: -11px;
        right: 20px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #f6f6f6;
        opacity: 0.9;
        z-index: -1;
    }

    header {
        @apply flex items-center;

        span {
            @apply font-body font-bold text-cug-dark-gray;
            font-size: 13px;
        }

        i {
            @apply mr-4 text-cug-dark-gray;
            font-size: 24px;
        }
    }

    h1 {
        @apply my-4 font-emphasis leading-none text-cug-dark-gray;
        font-size: 21px;
    }

    label {
        @apply font-body;
    }

    .mdl-textfield {
        padding: 5px 0;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }

        .mdl-textfield__label {
            top: 9px;
        }
    }

    .js-input {
        @apply font-body;

        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }

    button {
        &.cug2b-modal-closer {
            width: 32px;
            height: 32px;

            &:hover {
                @apply bg-cug-red text-white;
            }
        }
    }
}

.cug2b-add-user-modal {
    top: 60px;
    right: 37px;
}

.arrow-after {
    @apply relative cursor-pointer;

    > div > span {
        @apply absolute inset-0;
    }

    &:after {
        @apply pointer-events-none absolute vertical-center;
        content: "\25BC";
        right: 35px;
        font-size: $font-size-xsmall;
        color: $gray-light;
    }
}

.cug2b-users-info {
    margin-top: 25px;

    input[type="checkbox"] {
        display: none;
    }

    h1 {
        @apply font-emphasis text-cug-dark-gray;
        font-size: 21px;
        line-height: 25px;
        margin-bottom: 15px;
    }

    label {
        @apply font-black text-cug-red;
        font-size: 21px;
        line-height: 25px;
        margin-bottom: 10px;
        padding-left: 0;

        &:before {
            display: none;
        }
    }

    p {
        @apply text-cug-dark-gray;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 15px;
    }
}

.cug2b-save-message {
    @apply relative my-4 w-full bg-cug-dark-gray font-body text-white;
    border-radius: 12px;
    font-size: 20px;
    line-height: 24px;
    padding: 20px 20px 20px 80px;

    i {
        @apply absolute vertical-center;
        left: 20px;
        font-size: 35px;
    }
}

.cug2b-confirmed-reservation-scrolling-tip {
    @apply py-3 font-body font-bold text-be-blue;
    font-size: 14px;
}

.agency-reservations-button-container {
    @apply flex flex-row;

    .agency-reservation-checkin-btn {
        min-width: 110px;
        margin-left: 10px;
        padding: 6px 27px 6px 8px;
        font-size: 14px;
    }

    .agency-reservation-extras-btn {
        min-width: 125px;
        margin-left: 10px;
        padding: 6px 26px 6px 7px;
        font-size: 14px;
    }
}

.cug2b-travel-partner-container {
    @apply flex items-center justify-center p-6;
}

.cug2b-edit-user-container {
    @apply p-6;
    .mdl-textfield {
        padding: 10px 0;
    }

    select.mdl-textfield__input {
        font-size: 20px;
        padding-top: 10px;
    }

    input.mdl-textfield__input {
        padding-top: 25px;
    }

    .mdl-textfield--floating-label.is-dirty mdl-textfield__label {
        top: 1px;
    }
}

.cug2-button-container-in-success-modal {
    @apply mt-12 flex flex-col items-center justify-center;
}

// MEDIA QUERIES

@media #{$large} {
    .cug2-filter-by-date-container {
        @apply mb-0;
    }

    .cug2-label-and-datepickers {
        label {
            @apply mb-2;
        }
    }

    .cug2-datepickers {
        width: 280px;
    }

    .cug2-filter-date-input {
        height: 42px;
        font-size: 18px;
        width: 140px;
    }

    .cug2-empty-filters {
        width: 180px;
    }
}

@media #{$medium} {
    .cug2-filter-by-date-container {
        @apply mb-0;
    }

    .cug2-label-and-datepickers {
        label {
            @apply mb-2;
        }
    }

    .cug2-datepickers {
        width: 260px;
    }

    .cug2-filter-date-input {
        height: 42px;
        font-size: 16px;
        width: 130px;
    }

    .cug2-empty-filters {
        width: 180px;
    }

    .cug2b-title-container {
        margin: 15px 0 30px;

        i {
            padding: 4px 0 4px 8px;
            margin-right: 6px;
            font-size: 32px;
        }

        h1 {
            font-size: 22px;
            line-height: 26px;
        }

        h2 {
            font-size: 16px;
            line-height: 19px;

            span {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

    .cug2b-page-subtitle {
        font-size: 16px;
        line-height: 19px;
    }

    .cug2b-search-and-title {
        margin-bottom: 20px;

        &.push-down {
            margin-top: 40px;
        }
    }

    .cug2b-search-and-buttons {
        margin-bottom: 20px;

        .cug2b-pill {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    .cug2b-searchbox {
        input {
            width: 200px;
            height: 40px;
            font-size: 16px;
        }

        i {
            right: 1rem;
            font-size: 22px;
        }
    }

    .cug2b-pill {
        font-size: 10px;
        line-height: 13px;
        height: 40px;
        padding: 0 16px;

        i {
            margin-right: 8px;
            font-size: 21px;
        }
    }

    .cug2b-add-user-modal {
        top: 45px;
        right: 23px;
    }
}

@media #{$small} {
    .cug2-filter-by-date-container {
        @apply mb-0;
    }

    .cug2-filter-by-date {
        @apply flex-col;
    }

    .cug2-label-and-datepickers {
        @apply mb-6;

        label {
            @apply mb-2;
        }
    }

    .cug2-datepickers {
        @apply w-full;
    }

    .cug2-filter-date-input {
        @apply w-full;
        height: 42px;
        font-size: 16px;
    }

    .cug2-empty-filters {
        @apply mb-3 w-full;
    }

    .arrow-after {
        &:after {
            right: 15px;
        }
    }

    .cug2b-title-container {
        margin: 15px 0 25px;

        i {
            padding: 4px 0 4px 4px;
            margin-right: 4px;
            font-size: 24px;
        }

        h1 {
            font-size: 17px;
            line-height: 20px;
        }

        h2 {
            font-size: 12px;
            line-height: 14px;

            span {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    .cug2b-page-subtitle {
        font-size: 13px;
        line-height: 15px;
    }

    .cug2b-search-and-title {
        margin-bottom: 15px;

        &.push-down {
            margin-top: 40px;
        }
    }

    .cug2b-search-and-buttons {
        margin-bottom: 15px;

        .cug2b-pill {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    .cug2b-searchbox {
        input {
            width: 142px;
            height: 32px;
            font-size: 12px;
        }

        i {
            right: 1rem;
            font-size: 17px;
        }
    }

    .cug2b-pill {
        font-size: 8px;
        line-height: 9px;
        height: 32px;
        padding: 0 12px;

        i {
            margin-right: 8px;
            font-size: 16px;
        }
    }

    .cug2b-change-status-modal {
        width: 470px;
        height: 340px;
    }

    .cug2b-edit-user-modal,
    .cug2b-add-user-modal {
        top: 31px;
        right: -25px;
        width: 460px;
        border-radius: 8px;
        padding: 15px;

        header {
            span {
                font-size: 8px;
            }

            i {
                font-size: 15px;
            }
        }

        h1 {
            font-size: 13px;
        }

        button {
            &.cug2b-modal-closer {
                width: 20px;
                height: 20px;
                font-size: 14px;
            }
        }
    }

    .cug2b-add-user-modal {
        top: 36px;
        right: 14px;
    }

    .cug2b-users-info {
        margin-top: 25px;

        h1 {
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 15px;
        }

        label {
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 6px;
        }

        p {
            font-size: 11px;
            line-height: 13px;
            margin-bottom: 15px;
        }
    }

    .cug2b-save-message {
        border-radius: 7px;
        font-size: 12px;
        line-height: 15px;
        padding: 15px 15px 15px 55px;

        i {
            left: 15px;
            font-size: 21px;
        }
    }

    .cug2b-reset-password-modal {
        height: 325px;
    }

    .cug2-button-container-in-success-modal {
        @apply mt-20 flex-row;

        .rounded-primary-btn {
            min-width: 180px;
        }
    }

    .cug2b-modal-question {
        text-align: center;
    }

    .rounded-primary-btn,
    .rounded-primary-btn {
        &.agency-reservation-checkin-btn,
        &.agency-reservation-extras-btn {
            min-width: 121px;
            margin-left: 8px;
            padding: 6px 27px 6px 15px;
        }
    }
}

@media #{$xsmall} {
    .cug2b-save-success-modal {
        height: 255px;
    }

    .cug2-filter-by-date-container {
        @apply mb-0;
    }

    .cug2-filter-by-date {
        @apply flex-col;
    }

    .cug2-label-and-datepickers {
        @apply mb-3;

        label {
            @apply mb-0;
            font-size: 12px;
        }
    }

    .cug2-datepickers {
        @apply w-full;
    }

    .cug2-filter-date-input {
        @apply w-full pl-8;
        height: 32px;
        font-size: 14px;
    }

    .cug2-empty-filters {
        @apply mb-3 w-full;
    }

    .cug2-filter-button-container {
        @apply items-center justify-end;
    }

    .arrow-after {
        min-width: 100px;

        &:after {
            right: 15px;
        }
    }

    .cug2b-title-container {
        margin: 10px 0 20px;

        i {
            padding: 2px 0 2px 10px;
            margin-right: 4px;
            font-size: 24px;
        }

        h1 {
            font-size: 15px;
            line-height: 18px;
        }

        h2 {
            font-size: 12px;
            line-height: 14px;

            span {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    .cug2b-search-and-title {
        @apply flex-col items-start;
        margin-bottom: 15px;

        &.push-down {
            margin-top: 50px;
        }

        .cug2b-searchbox {
            @apply mt-4;
        }
    }

    .cug2b-page-subtitle {
        font-size: 14px;
        line-height: 17px;
    }

    .cug2b-search-and-buttons {
        @apply flex-col flex-wrap items-center justify-center;
        margin-bottom: 10px;

        .cug2b-searchbox {
            @apply mb-2;
        }

        .cug2b-pill {
            @apply m-2;

            &:not(:last-child) {
                @apply m-2;
            }
        }
    }

    .cug2b-searchbox {
        @apply w-full;

        input {
            @apply w-full;
            height: 32px;
            font-size: 12px;
        }

        i {
            right: 1rem;
            font-size: 17px;
        }
    }

    .cug2b-pill {
        font-size: 12px;
        line-height: 14px;
        height: 40px;
        padding: 0 20px;

        i {
            margin-right: 8px;
            font-size: 22px;
        }
    }

    .cug2b-modal-question {
        text-align: center;
        font-size: 14px;
        line-height: 14px;

        span {
            font-size: 11px;
            line-height: 13px;
        }
    }

    .cug2b-change-status-modal {
        width: 470px;
        height: 320px;
    }

    .cug2b-edit-user-modal,
    .cug2b-add-user-modal {
        @apply fixed all-center;
        height: 450px;
        max-height: 90%;
        width: 307px;
        border-radius: 8px;
        padding: 20px 25px;

        header {
            @apply mb-2;

            span {
                font-size: 12px;
            }

            i {
                @apply mr-2;
                font-size: 15px;
            }
        }

        h1 {
            @apply m-0 whitespace-normal;
            font-size: 14px;
        }

        button {
            &.cug2b-modal-closer {
                width: 32px;
                height: 32px;
            }
        }

        .mdl-textfield {
            margin-top: 5px;
        }

        .mdl-textfield__label {
            top: 0;
            font-size: 12px;
        }

        .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
        .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
            top: -2px;
            font-size: 10px;
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            padding-left: 6px;
            line-height: 32px;
        }

        .js-input {
            @apply font-body leading-none;
            font-size: 12px;
            height: 32px;
            padding-top: 14px;
            padding-left: 5px;

            &.js-select {
                @apply font-body;
                padding-top: 14px;
                padding-left: 5px;
                font-size: 12px;
                appearance: none;
            }
        }

        .js-select-arrow {
            &:after {
                top: 15px;
                right: 7px;
                font-size: 10px;
            }
        }

        &:after,
        &:before {
            content: none;
        }
    }

    .cug2b-users-info {
        margin-top: 25px;

        input[type="checkbox"] {
            display: inline;
            opacity: 0;
            margin-left: -10px;

            &:checked {
                ~ p {
                    max-height: 200px;
                }

                ~ label {
                    &:after {
                        transform: translateY(-50%) rotate(45deg);
                    }
                }
            }

            ~ label[for] {
                @apply h-auto leading-none;
            }
        }

        h1 {
            font-size: 11px;
            line-height: 13px;
            margin-bottom: 10px;
        }

        label {
            @apply relative inline-block h-auto pl-0 leading-none;
            width: 105px;
            font-size: 12px;
            line-height: 13px;
            margin-bottom: 5px;

            &:before {
                display: none;
            }

            &:after {
                @apply absolute right-0 origin-center border-b border-l-0 border-r border-t-0 border-solid border-cug-red common-transition;
                content: "";
                width: 7px;
                height: 7px;
                top: 50%;
                transform: translateY(-50%) rotate(-45deg);
            }
        }

        p {
            @apply overflow-hidden common-transition;
            max-height: 0;
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 10px;
        }
    }

    .cug2b-save-message {
        border-radius: 4px;
        font-size: 10px;
        line-height: 12px;
        padding: 10px 10px 10px 40px;

        i {
            left: 10px;
            font-size: 18px;
        }
    }

    .cug2b-reset-password-modal {
        width: 300px;
        height: 300px;
    }

    .cug2-button-container-in-success-modal {
        @apply mt-16 flex-row;

        .rounded-primary-btn {
            min-width: 175px;
        }
    }

    .agency-reservations-button-container {
        @apply flex flex-col;

        gap: 3px;

        .rounded-primary-btn,
        .rounded-primary-btn {
            &.agency-reservation-checkin-btn,
            &.agency-reservation-extras-btn {
                min-width: 85px;
                margin-left: 8px;
                padding: 2px 20px 2px 0px;
                font-size: 10px;

                &:after {
                    font-size: 14px;
                }
            }
        }
    }
}

.cug2b-add-user-modal-scroller {
    @apply max-h-full;
}
