form {
    &.margin-bottom {
        margin-bottom: 25px;
    }
}

// IOS bug fix
input[type="text"],
input[type="text"]:before,
input[type="text"]:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
}

#mainContentPayment {
    .invoice-select {
        margin-top: 20px;

        .ac-tooltip {
            @apply font-semibold relative;
            width: 13px;
            height: 13px;
            bottom: 2px;
            left: 1px;
        }
    }

    .ar-invoice-tooltip-opener{
        @apply relative inline-flex items-center justify-center rounded-full font-bold text-white cursor-pointer bg-brand-secondary;
        width: 13px;
        height: 13px;
        bottom: 2px;
        left: 1px;
        font-size: 11px;
        pointer-events: all;
    }

    .inner-box {
        padding: 15px;

        .mdl-textfield {
            padding-bottom: 0;
        }
    }

    .inner-deep-box {
        padding: 15px;
        margin-bottom: 15px;
        border-radius: $border-radius-lg;

        .giftcard-wrapper {
            display: block;
            width: 100%;
            position: relative;
            padding: 14px;
            border: 1px solid $brand-secondary;
            border-radius: $border-radius-sm;
            color: $brand-secondary;
            background: white;

            .giftcard-opener {
                display: block;
                position: relative;
                padding: 0;
                margin: 0;
                height: auto;
                line-height: 1;

                &:before {
                    position: absolute;
                    display: none;
                }

                .title-icon {
                    font-size: 45px;
                }

                header {
                    margin: 0;
                    max-width: 90%;
                }
            }

            input[type="checkbox"] {
                opacity: 0.01;
                position: absolute;

                &:checked {
                    ~ label .open-icon {
                        i {
                            -moz-transform: rotate(90deg);
                            -o-transform: rotate(90deg);
                            -webkit-transform: rotate(90deg);
                            transform: rotate(90deg);
                            transition: all 400ms;
                        }
                    }

                    ~ .giftcard-content {
                        height: 122px;
                    }
                }
            }

            .open-icon {
                position: absolute;
                top: 50%;
                right: 14px;
                transform: translateY(-50%);

                i {
                    display: inline-block;
                    font-size: 28px;
                    color: $brand-secondary;
                    transition: all 400ms;
                }
            }

            .giftcard-content {
                display: block;
                width: 100%;
                height: 0;
                overflow: hidden;
                transition: all 400ms;
            }

            .giftcard-content-inside {
                margin: 14px;
            }
        }
    }

    hr {
        margin: $margin-small 0;
        height: 1px;
        border-bottom: 1px solid $gray-light;
    }

    .payment-header {
        position: relative;
        margin: 0;
        justify-content: space-between;
    }

    .segments {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        font-size: $font-size-large;
        color: $brand-tertiary;
        line-height: 1.25;

        span {
            font-weight: $bold-font-weight;
        }
    }

    .tabs {
        -webkit-user-select: none;
        user-select: none;

        nav {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;
                    text-align: center;
                    margin-left: 10px;
                    vertical-align: top;

                    &:first-child {
                        margin-left: 0;
                    }

                    label {
                        display: inline-block;
                        position: relative;
                        text-align: center;
                        color: $brand-secondary;
                        font-weight: $semibold-font-weight;

                        span {
                            max-width: 200px;
                            display: inline-block;
                        }
                    }
                }
            }

            > input {
                display: none;
            }
        }

        #tab0:checked ~ .tab0 label,
        #tab1:checked ~ .tab1 label,
        #tab2:checked ~ .tab2 label,
        #tab3:checked ~ .tab3 label,
        #tab4:checked ~ .tab4 label,
        #tab5:checked ~ .tab5 label,
        #tab6:checked ~ .tab6 label,
        [id^="tabOffline_"]:checked ~ [id^="tabOfflineTab_"] label,
        [id^="payment_tab_"]:checked + li label {
            img {
                border: 4px solid $brand-primary;
                position: relative;
            }

            .agency-image-container {
                &.payment-card-img {
                    @apply relative;
                    border: 4px solid $brand-primary;

                    img {
                        @apply border-none absolute;
                    }
                }
            }

            .payment-method-warning {
                @apply block;
            }

            &:after {
                @apply absolute flex items-center justify-center;
                color: $brand-primary;
                top: 8px;
                right: 8px;
                width: 20px;
                height: 20px;
                content: "\2713";
                border: 1px solid $brand-primary;
                border-radius: 50%;
                font-size: $font-size-xsmall;
            }

            cursor: default;
        }
    }

    [type="radio"] {
        + label:after,
        + label:before {
            margin: 0;
            padding: 0;
            display: none;
        }
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            cursor: pointer;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 39px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.on-hold {
    margin: 25px 0 25px 15px;
}

.webpay-warning {
    display: block;
    width: 180px;
    white-space: normal;
    font-size: $font-size-small;
    text-align: center;
    margin: 0 auto;
    line-height: 1.2;
    margin-top: 6px;
    color: $brand-secondary;
}

.payment-method-warning {
    display: none;
    width: 180px;
    white-space: normal;
    font-size: $font-size-small;
    text-align: center;
    margin: 0 auto;
    line-height: 1.2;
    margin-top: 6px;
    color: $brand-secondary;
}

.payment-card-img {
    display: block;
    padding: 5px 0;
    border: solid 4px $gray-light;
    border-radius: $border-radius-sm;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    background: #fff;

    &:hover {
        border-color: $brand-secondary;
    }
}

.agency-payment-method-ribbon {
    @apply absolute flex justify-center items-center bg-new-ui-turqoise rounded-lg text-white font-extrabold horizontal-center;
    font-size: 14px;
    line-height: 16px;
    font-family: Lato;
    padding: 4px 6px;
    gap: 3px;
    top: 86px;
}

.agency-payment-tab {
    @apply relative;

    .agency-image-container.payment-card-img {
        @apply relative overflow-hidden;
        width: 200px;
        height: 122px;

        img {
            @apply absolute left-0;

            top: -9px;
        }
    }
}

.payment-mm-warning {
    @apply rounded-lg text-white my-4 px-6 py-4 flex items-center justify-center;
    background: #59c3d9;
}

.payment-pushdown {
    margin-top: 27px;
}

.payment-input-pushdown,
.payment-input-invalid-card {
    margin-top: 15px;
}

.group-label {
    position: absolute;
    color: $brand-secondary;
    font-size: $font-size-small;
    font-weight: 900;
    padding-top: 15px;
}

.card-result {
    position: absolute;
    right: $common-gap;
    top: 50%;
    transform: translateY(-50%) translateY(10px);
}

.non-chile-link {
    margin: $common-gap 0;

    a,
    a:visited,
    a:active {
        color: $brand-tertiary;
        font-size: $font-size-medium;
        text-decoration: underline;
        cursor: pointer;
    }

    a:hover {
        color: $brand-secondary;
        text-decoration: none;
    }
}

.payment-message {
    display: flex;
    align-items: center;
    height: 50px;
    color: $brand-secondary;
    font-size: $font-size-small;
    font-weight: 600;

    &.text-right {
        justify-content: flex-end;
    }
}

.payment-header-pushdown {
    margin-top: $common-gap * 2;
}

.cvv-tooltip,
.voucher-tooltip {
    position: relative;
    text-align: center;
    margin-top: 5px;
    font-size: $font-size-small;
    color: $brand-tertiary;
    cursor: pointer;

    &:active,
    &:hover {
        .cvv-tooltip-info,
        .voucher-tooltip-info {
            display: block;
        }
    }

    .cvv-tooltip-info,
    .voucher-tooltip-info {
        display: none;
        position: absolute;
        bottom: 22px;
        left: 50%;
        transform: translateX(-50%);
        color: $gray-darkest;

        span {
            display: block;
            margin: 10px;
        }

        .arrow-box {
            position: relative;
            width: 185px;
            padding: 14px;
            border: 1px solid transparent;
            border-radius: 6px;
            box-shadow: 5px 5px 10px $gray-light;
            background: $cvv-bg;
            z-index: 10000;

            img {
                width: 120px;
            }

            span {
                color: $brand-secondary;
                font-size: $font-size-xsmall;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        .arrow-box:after,
        .arrow-box:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        .arrow-box:after {
            border-color: transparent;
            border-top-color: $cvv-bg;
            border-width: 12px;
            margin-left: -12px;
        }

        .arrow-box:before {
            border-color: transparent;
            border-width: 13px;
            margin-left: -13px;
        }
    }
}

.voucher-tooltip {
    width: 145px;
    text-align: left;

    .voucher-tooltip-info {
        .arrow-box {
            text-align: center;

            span {
                margin: 0;
            }
        }
    }
}

.terms-container {
    display: inline-block;
}

.payment-button-container {
    @apply flex items-end justify-end;
    margin: 20px 0 50px;

    .elevated-error {
        position: absolute;
    }

    .rounded-primary-btn {
        @apply mb-1;
    }

    .rounded-secondary-btn {
        @apply mr-3;
        margin-bottom: 7px;
    }
}

#installmentMessage {
    text-align: center;
    width: 100%;
}

.elavon-installment-message,
.xml-installment-message {
    background: $warning-bg-color;
    color: #fff;
    padding: 5px 10px;
    font-size: $font-size-small;
    margin-top: 5px;
    display: block;
    border-radius: $border-radius-sm;
}

.disabled-installments {
    opacity: 0.5;
    cursor: pointer;
}

.peru-payment-banks {
    @apply block w-full relative border border-solid border-brand-secondary text-brand-secondary bg-white;
    padding: 14px;
    margin-top: 15px;
    border-radius: $border-radius-sm;
}

.peru-payment-info {
    @apply flex flex-col justify-center w-full h-full;
    text-align: center;

    h2 {
        @apply text-brand-secondary font-body leading-5 font-semibold;
        margin-bottom: 10px;
        font-size: 18px;
    }

    p {
        @apply text-brand-secondary font-body leading-5 m-0;
        font-size: $font-size-small;
    }
}

.peru-payment-logos {
    @apply grid grid-cols-4 gap-2;

    img {
        @apply m-auto;
        max-width: 75px;
    }
}

// tabs

.type-tabs {
    margin-top: 25px;
    -webkit-user-select: none;
    user-select: none;

    > nav {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;

                label {
                    display: inline-block;
                    position: relative;
                    width: 250px;
                    height: 80px;
                    line-height: 80px;
                    text-align: center;
                    margin: 0 5px 0 0;
                    background: $gray-lighter;
                    border-top: 1px solid transparent;
                    border-left: 1px solid transparent;
                    border-right: 1px solid transparent;
                    border-top-left-radius: $border-radius-lg;
                    border-top-right-radius: $border-radius-lg;
                    color: $brand-secondary;
                    font-weight: 600;
                    font-size: 18px;

                    &:hover {
                        color: $gray-lightest;
                        background: $secondary-btn-color;
                    }

                    span {
                        color: $brand-secondary;
                        font-weight: 600;
                        font-size: 18px;
                    }
                }
            }
        }

        > input {
            display: none;
        }
    }

    > .tab-content {
        background: #fff;
        padding: 25px;
        border: 1px solid $gray-light;
        border-bottom-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
    }

    #tab1o:checked ~ nav .tab1o label,
    #tab2o:checked ~ nav .tab2o label {
        height: 81px;
        bottom: -1px;
        background: #fff;
        border-top: 1px solid $gray-light;
        border-left: 1px solid $gray-light;
        border-right: 1px solid $gray-light;
        color: $brand-secondary;
        cursor: default;
    }
}

// EOF tabs

.tooltip-trigger {
    &.disabled {
        pointer-events: all;
        opacity: 1;

        &:hover {
            .installment-tooltip-container {
                display: block;
            }
        }
    }
}

.installment-tooltip-container {
    display: none;
    position: relative;
    width: 100%;
}

.installment-tooltip {
    background: $cvv-bg;
    color: $brand-secondary;
    padding: 15px;
    border-radius: $border-radius-sm;
    font-size: $font-size-xsmall;
    font-weight: 600;
    width: 200px;
    bottom: 53px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    box-shadow: 5px 5px 10px #ccc;

    &:after,
    &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: transparent;
        border-top-color: $cvv-bg;
        border-width: 12px;
        margin-left: -12px;
    }

    &:before {
        border-color: transparent;
        border-width: 13px;
        margin-left: -13px;
    }
}

.ar-invoice-information {
    font-size: $font-size-small;
    color: $brand-secondary;
    padding-top: $padding-xsmall;
}

.brasil-usd-message {
    margin-top: 20px;
    font-size: 14px;
    color: $brand-secondary;
    font-weight: 600;
}

.additional-email {
    position: relative;

    .additional-email-label {
        color: $brand-tertiary;
        font-size: 14px;
        margin-top: 18px;
        font-family: "Lato", sans-serif;
        cursor: pointer;

        &:hover {
            color: $brand-secondary;
        }

        &.open {
            ~ .additional-email-field {
                max-height: 130px;

                .additional-email-remover {
                    opacity: 1;
                    transition: all 0.25s 0.25s ease-in-out;
                }
            }
        }
    }

    .additional-email-field {
        max-height: 0;
        transition: all 0.25s ease-in-out;
        overflow: hidden;
    }

    .additional-email-remover {
        position: absolute;
        top: 72px;
        right: 20px;
        color: $brand-secondary;
        font-size: 14px;
        font-family: "Lato", sans-serif;
        transition: none;
        cursor: pointer;
        opacity: 0;

        &:hover {
            color: $brand-tertiary;
        }
    }
}

.payment-checkbox-terms-wrapper {
    &.payment {
        &.wide {
            width: 265px;

            #argentinaCompanyInfo {
                position: relative;
                top: 1px;
                left: 2px;
            }
        }
    }
}

.payment-confirmation-modal-url-container {
    @apply flex justify-center select-none;
    margin-top: 12px;

    .payment-confirmation-modal-url {
        @apply underline cursor-pointer;
    }
}

.card-result-image {
    width: 48px;
    height: 30px;
    background: #dedede;
    position: absolute;
    top: 31px;
    right: 10px;
    border-radius: 3px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .payment-pushdown {
        margin-top: 30px;
    }
}

@media #{$medium} {
    .payment-pushdown {
        margin-top: 6px;
    }

    #mainContentPayment {
        .payment-button-container {
            @apply flex-col justify-center items-center;
            margin: 0 0 10px;

            .payment-checkbox-terms-wrapper {
                &.payment {
                    display: block;

                    &.terms {
                        &.payment-wider {
                            max-width: unset;
                            height: unset;

                            .mdl-checkbox,
                            .mdl-checkbox.is-upgraded,
                            .mdl-checkbox.is-checked {
                                height: unset;
                            }
                        }
                    }
                }
            }

            .elevated-error {
                position: static;
            }

            .rounded-primary-btn {
                @apply mr-0 mt-6 mb-0;
            }

            .rounded-secondary-btn {
                @apply mr-0 mt-6 mb-0;
            }
        }
    }
}

@media #{$small} {
    .payment-message {
        margin: 25px;
    }

    #mainContentPayment {
        .payment-button-container {
            @apply flex-col justify-center items-center m-0;

            .payment-checkbox-terms-wrapper {
                &.payment {
                    display: block;
                }
            }

            .elevated-error {
                position: static;
            }

            .rounded-primary-btn,
            .rounded-secondary-btn {
                @apply mr-0 mt-6 mb-0;
            }
        }
    }

    .payment-input-invalid-card {
        margin-top: 0;
    }
}

@media #{$xsmall} {
    .ac-tooltip-balloon {
        &.ar-invoice-tooltip {
            max-width: 240px;
            padding: 12px;
        }
    }

    #mainContentPayment {
        .payment-header {
            display: block;
        }

        .segments {
            display: block;
            margin: 0 10px;
        }

        .booking-wrapper {
            padding: 0;
            border: none;
            max-width: 95%;
            margin: 25px auto;

            header {
                .title {
                    margin-right: 10px;
                }
            }

            .inner-box {
                padding: 25px 14px;
                border: none;
            }
        }

        .inner-deep-box {
            .giftcard-wrapper {
                input[type="checkbox"]:checked {
                    ~ .giftcard-content {
                        height: 192px;
                        overflow: visible;
                    }
                }
            }
        }

        .tabs {
            nav {
                ul {
                    li {
                        display: block;
                        margin: 10px auto;
                    }
                }
            }
        }

        .payment-card-img {
            width: 100%;

            &.agency-image-container {
                width: 200px;
            }
        }

        .payment-pushdown {
            margin-top: 0;
        }
    }

    #invoceDataContainer {
        margin-top: 25px;
    }

    .payment-message {
        margin-left: 14px;
    }

    .error-message-container {
        &.text-left {
            text-align: center;
        }
    }

    #mainContentPayment {
        .payment-button-container {
            @apply flex-col justify-center items-center;

            .elevated-error {
                position: relative;
            }

            .rounded-primary-btn {
                @apply mr-0 mt-6 mb-0;
            }

            .rounded-secondary-btn {
                @apply mr-0 mt-6 mb-0;
            }
        }
    }

    .terms-container {
        display: block;

        .payment {
            &.terms {
                margin: 0 auto;
                display: block;
            }
        }
    }

    .payment-checkbox-terms-wrapper {
        &.payment {
            margin: 20px auto;

            &.terms {
                &.payment-wider {
                    width: 340px;
                    height: 80px;

                    .mdl-checkbox,
                    .mdl-checkbox.is-upgraded,
                    .mdl-checkbox.is-checked {
                        height: 78px;
                    }
                }
            }
        }
    }

    .ar-invoice-information {
        padding-top: 0px;
        margin-top: 0px;
    }

    .additional-email {
        .additional-email-remover {
            top: 18px;
            right: 10px;
        }
    }

    .payment-input-invalid-card {
        margin-top: 0;
    }

    .peru-payment-logos {
        @apply grid-cols-2 mt-2;

        img {
            max-width: 65px;
        }
    }
}
