.b2-reset-button {
    @apply relative cursor-pointer font-emphasis text-be-blue underline;
    font-size: 15px;
    line-height: 18px;

    &:hover {
        @apply text-be-cyan-2 no-underline;
    }

    &:after {
        @apply absolute font-normal;
        font-family: "jetsmart-v2" !important;
        top: -1px;
        right: -25px;
        content: "\e9ba"; // DEVNOTE Might change if icons change
        font-size: 21px;
    }
}

button,
button:active,
button:focus {
    &.b2-primary-button {
        @apply relative m-0 inline rounded-full border border-solid border-be-red bg-be-red font-body font-black normal-case text-white;
        font-size: 18px;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        transition: all 0.25s ease-in-out;
        padding: 12px 35px 12px 20px;
        min-width: 180px;

        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 5px;
            content: "\e9ba"; // DEVNOTE Might change if icons change
            font-size: 25px;
        }

        &:hover {
            @apply rounded-full border border-solid border-be-red bg-white font-black text-be-red;
        }
    }

    &.b2-secondary-button {
        @apply relative m-0 inline rounded-full border border-solid border-be-blue bg-be-blue font-body font-black normal-case text-white;
        font-size: 18px;
        line-height: 1;
        letter-spacing: normal;
        transition: all 0.25s ease-in-out;
        padding: 12px 35px 12px 20px;
        min-width: 180px;
        text-align: center;

        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 5px;
            content: "\e9ba"; // DEVNOTE Might change if icons change
            font-size: 25px;
        }

        &:hover {
            @apply rounded-full border border-solid border-be-blue bg-white font-black text-be-blue;
        }

        &:disabled {
            @apply border-bg-gray-5 bg-bg-gray-5 text-white;

            &:focus {
                @apply border-bg-gray-5 bg-bg-gray-5 text-white;
            }
        }
    }
}

.b2-free-bag-option {
    &.unselected {
        .b2-secondary-button {
            @apply border-bg-gray-2 bg-bg-gray-2 text-white;
        }
    }

    &.selected {
        .b2-secondary-button {
            @apply bg-white text-be-blue;

            &:disabled {
                @apply opacity-100;
            }

            &:after {
                @apply border-b-4 border-l-0 border-r-4 border-t-0 border-solid border-be-blue;
                content: "";
                transform: translateY(-50%) rotate(45deg);
                width: 8px;
                height: 16px;
                right: 24px;
            }
        }
    }
}

.b2-paid-bag-option {
    &.unselected {
        .b2-primary-button {
            @apply border-bg-gray-2 bg-bg-gray-2 text-white;
        }
    }

    &.selected {
        .b2-primary-button {
            @apply bg-white text-be-red;

            &:disabled {
                @apply opacity-100;
            }

            &:after {
                @apply border-b-4 border-l-0 border-r-4 border-t-0 border-solid border-be-red;
                content: "";
                transform: translateY(-50%) rotate(45deg);
                width: 8px;
                height: 16px;
                right: 24px;
            }
        }
    }
}

.b2-button-tooltip {
    @apply pointer-events-none absolute whitespace-normal bg-bg-cyan font-body text-be-blue;
    left: 65%;
    top: 338px;
    padding: 15px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 14px;
    transform: translateX(-50%);
    min-width: 235px;
    z-index: 10;

    &:after {
        @apply absolute bg-bg-cyan;
        content: "";
        width: 12px;
        height: 12px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

.b2-per-booking-quantity-container {
    @apply flex items-center;
}

.b2-quantity-button,
.b2-quantity-button:not(:disabled):focus,
.b2-quantity-button:not(:disabled):active {
    @apply m-0 flex items-center justify-center rounded-full border-2 border-solid border-white bg-transparent p-0 font-body font-black leading-none text-white;
    width: 32px;
    height: 32px;
    letter-spacing: normal;
    font-size: 33px;
    transition: all 0.25s ease-in-out;

    &:hover {
        @apply border-2 border-white bg-white text-be-red;
    }

    &:disabled {
        @apply pointer-events-none border-bg-gray-2 text-bg-gray-2 opacity-100;

        &:hover {
            @apply bg-transparent text-bg-gray-2;
        }
    }
}

.b2-per-pax-quantity {
    .b2-quantity-button,
    .b2-quantity-button:not(:disabled):focus,
    .b2-quantity-button:not(:disabled):active {
        @apply border-be-blue text-brand-tertiary;

        &:hover {
            @apply border-be-blue bg-be-blue text-white;
        }

        &:disabled {
            @apply pointer-events-none border-bg-gray-2 text-bg-gray-2 opacity-100;

            &:hover {
                @apply border-bg-gray-2 bg-transparent text-bg-gray-2;
            }
        }
    }
}

.ac-select-oversized-bag {
    .b2-oversized-quantity {
        .b2-quantity-button,
        .b2-quantity-button:not(:disabled):focus,
        .b2-quantity-button:not(:disabled):active {
            @apply border-be-blue text-brand-tertiary;

            &:hover {
                @apply bg-be-blue text-white;
            }

            &:disabled {
                @apply pointer-events-none border-bg-gray-2 text-bg-gray-2 opacity-100;

                &:hover {
                    @apply bg-transparent text-bg-gray-2;
                }
            }
        }
    }

    .b2-primary-button {
        &:disabled {
            &.ticked {
                @apply pointer-events-none opacity-100;

                &:after {
                    @apply border-b-4 border-l-0 border-r-4 border-t-0 border-solid border-white;
                    content: "";
                    transform: translateY(-50%) rotate(45deg);
                    width: 8px;
                    height: 16px;
                    right: 24px;
                }
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .b2-reset-button {
        font-size: 13px;
        line-height: 16px;
    }

    button,
    button:active,
    button:focus {
        &.b2-primary-button {
            font-size: 16px;
            padding: 8px 33px 8px 18px;
            min-width: 176px;

            &:after {
                right: 4px;
                font-size: 23px;
            }
        }

        &.b2-secondary-button {
            font-size: 16px;
            padding: 8px 33px 8px 18px;
            min-width: 176px;

            &:after {
                right: 4px;
                font-size: 23px;
            }
        }
    }

    .b2-free-bag-option {
        &.selected {
            .b2-secondary-button {
                &:after {
                    width: 8px;
                    height: 16px;
                    right: 24px;
                }
            }
        }
    }

    .b2-paid-bag-option {
        &.selected {
            .b2-primary-button {
                &:after {
                    width: 8px;
                    height: 16px;
                    right: 24px;
                }
            }
        }
    }

    .b2-button-tooltip {
        left: 65%;
        top: calc(50% + 80px);
        padding: 15px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 14px;
        min-width: 235px;

        &:after {
            width: 12px;
            height: 12px;
        }
    }

    .b2-quantity-button,
    .b2-quantity-button:not(:disabled):focus,
    .b2-quantity-button:not(:disabled):active {
        width: 26px;
        height: 26px;
        font-size: 18px;
    }

    .ac-select-oversized-bag {
        .b2-primary-button {
            &:disabled {
                &.ticked {
                    &:after {
                        width: 8px;
                        height: 16px;
                        right: 24px;
                    }
                }
            }
        }
    }
}

@media #{$medium} {
    .b2-reset-button {
        font-size: 10px;
        line-height: 12px;
    }

    button,
    button:active,
    button:focus {
        &.b2-primary-button {
            font-size: 12px;
            padding: 6px 25px 6px 14px;
            min-width: 130px;

            &:after {
                right: 3px;
                font-size: 16px;
            }
        }

        &.b2-secondary-button {
            font-size: 12px;
            padding: 6px 25px 6px 14px;
            min-width: 130px;

            &:after {
                right: 3px;
                font-size: 16px;
            }
        }
    }

    .b2-free-bag-option {
        &.selected {
            .b2-secondary-button {
                &:after {
                    width: 6px;
                    height: 12px;
                    right: 16px;
                }
            }
        }
    }

    .b2-paid-bag-option {
        &.selected {
            .b2-primary-button {
                &:after {
                    width: 6px;
                    height: 12px;
                    right: 16px;
                }
            }
        }
    }

    .b2-button-tooltip {
        left: 65%;
        top: calc(50% + 80px);
        padding: 11px;
        border-radius: 6px;
        font-size: 10px;
        line-height: 12px;
        min-width: 200px;

        &:after {
            width: 12px;
            height: 12px;
        }
    }

    .b2-quantity-button,
    .b2-quantity-button:not(:disabled):focus,
    .b2-quantity-button:not(:disabled):active {
        width: 20px;
        height: 20px;
        font-size: 15px;
    }

    .ac-select-oversized-bag {
        .b2-primary-button {
            &:disabled {
                &.ticked {
                    &:after {
                        width: 6px;
                        height: 12px;
                        right: 16px;
                    }
                }
            }
        }
    }
}

@media #{$small} {
    .b2-reset-button {
        font-size: 10px;
        line-height: 12px;
    }

    button,
    button:active,
    button:focus {
        &.b2-primary-button {
            font-size: 11px;
            padding: 6px 25px 6px 13px;
            min-width: 110px;

            &:after {
                right: 5px;
                font-size: 15px;
            }
        }

        &.b2-secondary-button {
            font-size: 11px;
            padding: 6px 25px 6px 13px;
            min-width: 110px;

            &:after {
                right: 5px;
                font-size: 15px;
            }
        }
    }

    .b2-free-bag-option {
        &.selected {
            .b2-secondary-button {
                &:after {
                    width: 8px;
                    height: 14px;
                    right: 12px;
                }
            }
        }
    }

    .b2-paid-bag-option {
        &.selected {
            .b2-primary-button {
                &:after {
                    width: 8px;
                    height: 14px;
                    right: 12px;
                }
            }
        }
    }

    .b2-button-tooltip {
        left: 65%;
        top: 158px;
        padding: 15px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 14px;
        min-width: 235px;

        &:after {
            width: 12px;
            height: 12px;
        }
    }

    .b2-quantity-button,
    .b2-quantity-button:not(:disabled):focus,
    .b2-quantity-button:not(:disabled):active {
        width: 18px;
        height: 18px;
        font-size: 16px;
    }

    .ac-select-oversized-bag {
        .b2-primary-button {
            &:disabled {
                &.ticked {
                    &:after {
                        width: 8px;
                        height: 14px;
                        right: 12px;
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .b2-reset-button {
        margin-left: 10px;
        font-size: 10px;
        line-height: 12px;

        &:after {
            display: none;
        }
    }

    button,
    button:active,
    button:focus {
        &.b2-primary-button {
            font-size: 11px;
            padding: 7px 25px 7px 8px;
            min-width: 100px;

            &:after {
                right: 3px;
                font-size: 13px;
            }
        }

        &.b2-secondary-button {
            font-size: 11px;
            padding: 8px 25px 8px 6px;
            min-width: 110px;

            &:after {
                right: 3px;
                font-size: 16px;
            }
        }
    }

    .b2-quantity-button,
    .b2-quantity-button:not(:disabled):focus,
    .b2-quantity-button:not(:disabled):active {
        width: 18px;
        height: 18px;
        font-size: 12px;
    }

    .b2m-per-booking-selector {
        .b2-quantity-button,
        .b2-quantity-button:not(:disabled):focus,
        .b2-quantity-button:not(:disabled):active {
            @apply border-be-blue text-be-cyan-2;
            width: 18px;
            height: 18px;
            font-size: 12px;
            pointer-events: all;
        }

        .b2-quantity-button:disabled,
        .b2-quantity-button:disabled:focus,
        .b2-quantity-button:disabled:active {
            @apply border-be-blue text-bg-gray-5;
            pointer-events: all;
        }
    }

    .b2m-paid-cell {
        .b2-per-pax-quantity {
            .b2-quantity-button,
            .b2-quantity-button:not(:disabled):focus,
            .b2-quantity-button:not(:disabled):active,
            .b2-quantity-button:not(:disabled):hover {
                @apply border-be-blue bg-transparent text-be-cyan-2;
                width: 18px;
                height: 18px;
                font-size: 12px;
                pointer-events: all;
            }

            .b2-quantity-button:disabled,
            .b2-quantity-button:disabled:focus,
            .b2-quantity-button:disabled:active,
            .b2-quantity-button:disabled:hover {
                @apply border-be-blue bg-transparent text-bg-gray-5;
                pointer-events: all;
            }
        }
    }

    .ac-select-oversized-bag {
        .b2-primary-button {
            &:disabled {
                &.ticked {
                    &:after {
                        border-bottom-width: 3px;
                        border-right-width: 3px;
                        width: 6px;
                        height: 12px;
                        right: 8px;
                    }
                }
            }
        }
    }

    .b2-per-booking-quantity-container {
        .b2-quantity-button {
            @apply border-be-blue text-brand-tertiary;

            &:hover {
                @apply border-be-blue bg-transparent text-brand-tertiary;
            }

            &:disabled {
                @apply pointer-events-none border-bg-gray-2 text-bg-gray-2 opacity-100;

                &:hover {
                    @apply bg-transparent text-bg-gray-2;
                }
            }
        }
    }
}
