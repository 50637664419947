@mixin tick {
    content: "";
    width: 12px;
    height: 8px;
    border-left: 3px solid $checkin-sidebar-color;
    border-bottom: 3px solid $checkin-sidebar-color;
    transform: rotate(-45deg);
    position: absolute;
    left: -20px;
    top: 6px;
}

.destination-images {
    position: relative;

    .checkin-destination-img {
        margin-top: 20px;

        &:not(.cut) {
            border-radius: 10px;
        }

        &:nth-child(1) {
            &.cut {
                border-top-left-radius: 10px;
                -webkit-clip-path: polygon(98% 0, 0 98%, 0 0);
                clip-path: polygon(98% 0, 0 98%, 0 0);
            }
        }

        &:nth-child(2) {
            &.cut {
                position: absolute;
                top: 0;
                left: 0;
                border-bottom-right-radius: 10px;
                -webkit-clip-path: polygon(100% 2%, 2% 100%, 100% 100%);
                clip-path: polygon(100% 2%, 2% 100%, 100% 100%);
            }
        }
    }
}

.checkin-destination-route {
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    color: $brand-secondary;
    font-size: $font-size-xsmall;
    line-height: 36px;
    border-bottom: 1px solid $checkin-destination-route-border;
    text-align: center;
}

.checkin-destination-breadcrumbs {
    font-family: "Lato", sans-serif;
    font-size: $font-size-medium;
    font-weight: 700;
    color: $checkin-sidebar-color;
    margin: 15px 0;

    > li {
        margin: 15px 0;
        position: relative;

        &.openable {
            &:after {
                content: "";
                transform: rotate(45deg);
                position: absolute;
                right: 0;
                top: 5px;
                font-size: 40px;
                transition: all $transition-medium-up ease-in-out;
                display: flex;
                width: 10px;
                height: 10px;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid $checkin-sidebar-color;
                border-right: 1px solid $checkin-sidebar-color;
            }

            &.open {
                &:after {
                    top: 10px;
                    transform: rotate(225deg);
                }
            }
        }

        .bc-title {
            display: flex;
            align-items: center;

            > span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                background: $checkin-sidebar-color;
                border-radius: 50%;
                color: #fff;
                font-size: 15px;
                font-family: "Lato", sans-serif;
                font-weight: 700;
                margin-right: 15px;
            }

            &.no-bg {
                > span {
                    background: transparent;
                    color: $checkin-sidebar-color;
                    font-size: 24px;
                }
            }
        }

        ul {
            padding: 0 0 0 60px;

            li {
                font-family: "Lato", sans-serif;
                font-size: $font-size-small;
                font-weight: 500;
                position: relative;
                color: $checkin-sidebar-color;

                &.active {
                    color: $checkin-destination-active-breadcrumb-color;
                }
            }
        }

        &.past {
            ul {
                li {
                    &:after {
                        @include tick();
                    }
                }
            }
        }

        &.active {
            color: $checkin-destination-active-breadcrumb-color;

            .bc-title {
                > span {
                    background: $checkin-destination-active-breadcrumb-color;
                }
            }

            .past {
                color: $checkin-sidebar-color;

                &:after {
                    @include tick();
                }
            }
        }
    }
}

.checkin-destination-title {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: $brand-secondary;
    line-height: 30px;
    border-bottom: 3px dotted $brand-secondary;
    margin-top: 30px;
}

.checkin-destination-subtitle {
    font-family: "Lato", sans-serif;
    font-size: $font-size-small;
    font-weight: 700;
    color: $checkin-destination-subtitle-color;
    line-height: 30px;
    text-transform: uppercase;
}

.sidebar-content {
    p {
        &.checkin-destination-p {
            font-family: "Lato", sans-serif;
            font-size: $font-size-xsmall;
            line-height: 1.3;
            font-weight: 400;
            color: $brand-secondary;
            padding: 0;
        }
    }
}

.weather {
    margin: 30px 0;

    .checkin-destination-weather {
        max-width: 70px;
        max-height: 70px;
    }

    .weather-data {
        display: inline-block;
        margin: 0 auto;

        span {
            display: block;
            color: $checkin-services-color2;
            font-family: "Lato", sans-serif;
            font-weight: 700;
            font-size: 20px;
            margin: 10px 0 0 0;

            &:first-child {
                font-size: 24px;
                margin-top: 0;
            }

            &:last-child {
                font-size: 17px;
                font-weight: 400;
            }
        }
    }
}

.checkin-services {
    margin: 30px 0;

    &:last-child {
        border-top: 1px solid $checkin-services-color;
        padding-top: 30px;
    }

    i {
        font-size: 70px;
        color: $checkin-services-color;
    }

    span {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $checkin-services-color;
        font-size: $font-size-xsmall;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        text-align: center;
    }

    a,
    a:focus,
    a:active {
        width: 133px;
        height: 31px;
        border-radius: 17px;
        font-family: "ClanOT-Black";
        font-size: 13px;
        color: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-transform: none;
        line-height: 1;
        padding: 2px 0 0 0;
        position: relative;
        margin: 0;

        &.reserve-hotel {
            background: $checkin-services-color;

            &:hover {
                background: $gray;
                color: #fff;
            }
        }

        &.reserve-car {
            background: $checkin-services-color2;

            &:hover {
                background: $gray;
                color: #fff;
            }
        }

        i {
            position: absolute;
            right: 3px;
            top: 4px;
            width: 22px;
            height: 22px;
            font-size: $font-size-large;
            color: #fff;
        }
    }
}

.js-icon {
    &.mirrored {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
}

// MEDIA QUERIES

@media #{$xsmall} {
    .checkin-services {
        i {
            position: relative;
            top: 10px;
            font-size: 60px;
        }
    }
}
