.cug2b-profile-box {
    @apply relative border border-solid border-cug-dark-gray;
    border-radius: 10px;
    padding: 30px 0;

    &.chile-compra-balance {
        @apply mb-[30px];
    }
}

.cug2b-profile-title {
    @apply flex items-center bg-cug-dark-gray;
    width: 312px;
    height: 46px;
    margin-bottom: 25px;
    padding-left: 55px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &.push-down {
        margin-top: 50px;
    }

    &.chile-compra {
        @apply mb-0;
    }

    i {
        @apply text-white;
        font-size: 38px;
        margin-right: 15px;
    }

    span {
        @apply font-emphasis text-white;
        padding-top: 4px;
        font-size: 22px;
    }
}

.cug2b-profile-edit {
    @apply absolute flex items-center;
    top: 18px;
    right: 33px;

    i {
        @apply text-cug-dark-gray;
        margin-right: 7px;
        font-size: 24px;
    }

    span {
        @apply font-body text-cug-dark-gray;
        font-size: 13px;
    }

    &:hover {
        i,
        span {
            @apply text-cug-red;
        }
    }
}

.cug2b-profile-table {
    padding: 0 55px;
}

.cug2b-profile-line {
    @apply flex items-center border-b border-solid border-cug-gray-6;
    padding: 25px 0;

    &:first-child {
        @apply pt-0;
    }

    &:last-child {
        @apply border-b-0 pb-0;
    }

    &.chile-compra {
        &:last-child {
            @apply mb-[25px] border-b-0;
        }
    }
}

.cug2b-profile-item {
    @apply border-r border-solid border-cug-gray-6;
    padding: 5px 40px;

    &:first-child {
        @apply pl-0;
    }

    &:last-child {
        @apply border-r-0;
    }

    header {
        @apply font-body leading-none text-cug-gray-3;
        font-size: 17px;
        margin-bottom: 10px;
    }

    article {
        @apply font-body font-bold leading-none text-cug-dark-gray;
        font-size: 18px;
    }
}

// MEDIA QUERIES

@media #{$medium} {
    .cug2b-profile-box {
        border-radius: 6px;
        padding: 25px 0;
    }

    .cug2b-profile-title {
        width: 190px;
        height: 28px;
        margin-bottom: 15px;
        padding-left: 25px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        &.push-down {
            margin-top: 25px;
        }

        i {
            font-size: 23px;
            margin-right: 10px;
        }

        span {
            padding-top: 2px;
            font-size: 13px;
        }
    }

    .cug2b-profile-edit {
        top: 9px;
        right: 15px;

        i {
            margin-right: 7px;
            font-size: 15px;
        }

        span {
            font-size: 10px;
        }
    }

    .cug2b-profile-table {
        padding: 0 25px;
    }

    .cug2b-profile-line {
        padding: 17px 0;
    }

    .cug2b-profile-item {
        padding: 5px 40px;

        header {
            font-size: 12px;
            margin-bottom: 5px;
        }

        article {
            font-size: 14px;
        }
    }
}

@media #{$small} {
    .cug2b-profile-box {
        border-radius: 6px;
        padding: 25px 0;
    }

    .cug2b-profile-title {
        width: 190px;
        height: 28px;
        margin-bottom: 15px;
        padding-left: 25px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        &.push-down {
            margin-top: 25px;
        }

        i {
            font-size: 23px;
            margin-right: 10px;
        }

        span {
            padding-top: 2px;
            font-size: 13px;
        }
    }

    .cug2b-profile-edit {
        top: 9px;
        right: 15px;

        i {
            margin-right: 7px;
            font-size: 15px;
        }

        span {
            font-size: 10px;
        }
    }

    .cug2b-profile-table {
        padding: 0 25px;
    }

    .cug2b-profile-line {
        padding: 17px 0;
    }

    .cug2b-profile-item {
        padding: 5px 40px;

        header {
            font-size: 12px;
            margin-bottom: 5px;
        }

        article {
            font-size: 14px;
        }
    }
}

@media #{$xsmall} {
    .cug2b-profile-box {
        @apply rounded-none border-0 p-0;

        &.chile-compra-balance {
            @apply mb-[42px];
        }
    }

    .cug2b-profile-title {
        width: 190px;
        height: 28px;
        margin-bottom: 12px;
        padding-left: 22px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-left: -15px;

        &.push-down {
            margin-top: 30px;
        }

        i {
            font-size: 23px;
            margin-right: 10px;
        }

        span {
            padding-top: 2px;
            font-size: 13px;
        }
    }

    .cug2b-profile-edit {
        top: -15px;
        right: 0;

        i {
            margin-right: 4px;
            font-size: 15px;
        }

        span {
            font-size: 12px;
        }
    }

    .cug2b-profile-table {
        padding: 0 15px;
    }

    .cug2b-profile-line {
        @apply flex-col items-start border-0 p-0;
    }

    .cug2b-profile-item {
        @apply w-full border-0;
        padding: 0 0 12px;

        header {
            font-size: 12px;
            margin-bottom: 5px;
        }

        article {
            font-size: 14px;
        }
    }
}
