.cug2b-modal-layover {
    @apply fixed top-0 bottom-0 left-0 right-0;
    background: rgba(#1c355e, 0.4);
    z-index: 1000000;
}

.cug2b-modal {
    @apply absolute all-center bg-white shadow-modal;
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
}

button {
    &.cug2b-modal-closer {
        @apply absolute top-0 right-0 border-0 bg-black text-white cursor-pointer rounded-full flex items-center justify-center p-0 leading-none;
        transform: translate(50%, -50%);
        font-size: 36px;
        width: 40px;
        height: 40px;
        font-weight: normal;
        z-index: 1;
        letter-spacing: unset;

        &:hover {
            @apply text-be-blue no-underline border-0;
        }
    }
}

.cug2b-modal-header {
    @apply bg-cug-dark-gray text-white flex items-center justify-center relative px-8 font-black;
    font-size: 28px;
    line-height: 33px;
    min-height: 72px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    > span,
    > div {
        @apply font-black;
    }
}

.cug2b-modal-scroller {
    @apply absolute inset-0 overflow-hidden;
}

.cug2b-modal-content {
    @apply relative;
    font-size: 20px;
    line-height: 24px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.cug2b-useless-modal-decor {
    &:before {
        @apply absolute left-0 w-0 h-0;
        bottom: -65px;
        border-bottom-left-radius: 10px;
        content: "";
        border-left: 140px solid #2c3438;
        border-bottom: 45px solid #2c3438;
        border-right: 140px solid transparent;
        border-top: 45px solid transparent;
    }

    &:after {
        @apply absolute right-0 w-0 h-0;
        content: "";
        bottom: -65px;
        border-bottom-right-radius: 10px;
        border-right: 140px solid #2c3438;
        border-bottom: 45px solid #2c3438;
        border-left: 140px solid transparent;
        border-top: 45px solid transparent;
    }
}

// MEDIA QUERIES

@media #{$small} {
    .cug2b-modal {
        border-radius: 7px;

        button {
            &.cug2b-modal-closer {
                font-size: 28px;
                width: 36px;
                height: 36px;
            }
        }
    }

    .cug2b-modal-header {
        font-size: 20px;
        line-height: 24px;
        min-height: 51px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .cug2b-modal-content {
        font-size: 14px;
        line-height: 17px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }
}

@media #{$xsmall} {
    .cug2b-modal {
        border-radius: 8px;

        button {
            &.cug2b-modal-closer {
                font-size: 24px;
                width: 32px;
                height: 32px;
            }
        }
    }

    .cug2b-modal-scroller {
        @apply overflow-y-scroll;
    }

    .cug2b-modal-header {
        font-size: 14px;
        line-height: 17px;
        min-height: 60px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .cug2b-modal-content {
        font-size: 11px;
        line-height: 13px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .cug2b-useless-modal-decor {
        &:before {
            bottom: -33px;
            border-bottom-left-radius: 8px;
            border-left: 70px solid #2c3438;
            border-bottom: 17px solid #2c3438;
            border-right: 70px solid transparent;
            border-top: 17px solid transparent;
        }

        &:after {
            bottom: -33px;
            border-bottom-right-radius: 8px;
            border-right: 70px solid #2c3438;
            border-bottom: 17px solid #2c3438;
            border-left: 70px solid transparent;
            border-top: 17px solid transparent;
        }
    }
}
