dc-table {
    .dc-table {
        @apply w-full;
        table-layout: fixed;
        border-collapse: separate;

        th,
        td {
            position: relative;
        }

        th {
            user-select: none;
            vertical-align: middle;
            text-align: center;
        }

        td {
            vertical-align: middle;
        }

        &.use-ellipsis {
            td {
                @apply overflow-hidden whitespace-nowrap;
                text-overflow: ellipsis;

                > div {
                    @apply overflow-hidden whitespace-nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

// MEDIA QUERIES

@media #{$xsmall} {
    dc-table {
        .dc-table-container {
            @apply max-w-full w-full overflow-x-scroll;
            overscroll-behavior: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .dc-table {
            @apply w-auto;

            &:not(.pinned-cells) {
                @apply min-w-full;
            }

            &.pinned-cells {
                @apply absolute top-0 right-0;

                td,
                th {
                    &.pinned-on-mobile {
                        @apply w-12;
                    }
                    &:not(.pinned-on-mobile) {
                        display: none;
                    }
                }
            }
        }
    }
}
