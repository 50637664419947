.profile-search-widget {
    @apply relative;
    grid-area: search;
    height: 264px;
}

.search-widget-container {
    @apply absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 264px;
}

.search-widget-content {
    @apply relative bg-be-blue text-white flex flex-row justify-evenly;
    text-align: center;
    height: 264px;
    border-radius: 12px;
    padding: 25px 0 20px;
    font-size: 16px;

    a {
        @apply relative flex items-center justify-center font-black font-body bg-white text-be-blue normal-case border border-white border-solid;
        width: 185px;
        height: 30px;
        padding: 0 28px 0 10px;
        border-radius: 15px;
        letter-spacing: normal;
        margin: 20px auto 0;
        font-size: 14px;
        transition: all 0.25s ease-in-out;

        i {
            @apply absolute text-be-blue;
            font-size: 20px;
            top: 4px;
            right: 3px;
            transition: all 0.25s ease-in-out;
        }

        &:not(.disabled):hover {
            @apply border border-white border-solid bg-be-red text-white;
            padding: 0 28px 0 10px;

            i {
                @apply text-white;
            }
        }
    }
}

.search-flights-button-container {
    @apply flex items-center;

    a {
        @apply flex justify-center cursor-pointer bg-brand-primary text-white relative m-0 rounded-full tracking-normal normal-case font-bold appearance-none border-solid border-2 border-brand-primary leading-none font-body whitespace-normal;
        width: 180px;
        height: 60px;
        padding: 0 38px 0 10px;
        font-size: 18px;

        &:after {
            font-size: 30px;
            right: 7px;
        }

        &:not(.disabled):hover {
            @apply bg-white text-brand-primary border-solid border-2 border-brand-primary;
            padding: 0 38px 0 10px;
        }
    }
}

.swc-main {
    font-size: 25px;
    margin: 20px 0 0;
    @apply flex items-center justify-center;

    span {
        @apply font-body font-black;
        max-width: 110px;
        line-height: 30px;
        text-align: left;
    }

    i {
        font-size: 65px;
        margin-right: 10px;
        @apply text-be-cyan-2;
    }
}

.swc-info {
    font-size: 16px;
    line-height: 19px;
    max-width: 190px;
    margin: 15px auto;
    @apply font-body text-white;
}

/* MEDIA QUERIES */

@media #{$small} {
    .profile-search-widget {
        height: 185px;
    }

    .search-widget-container {
        height: 185px;
    }

    .search-widget-content {
        height: 185px;
        padding: 25px 0 20px;
        font-size: 16px;

        a {
            width: 116px;
            height: 22px;
            padding: 0 20px 0 10px;
            font-size: 12px;
            margin-top: 15px;

            i {
                font-size: 15px;
                top: 3px;
                right: 3px;
            }

            &:not(.disabled):hover {
                padding: 0 20px 0 10px;
            }
        }
    }

    .search-flights-button-container {
        @apply flex items-center;

        a {
            @apply m-0;
            height: 50px;
            font-size: 13px;
            padding: 0 38px 0 10px;

            &:after {
                font-size: 20px;
                right: 4px;
            }

            &:not(.disabled):hover {
                @apply bg-white text-brand-primary border-solid border-2 border-brand-primary;
                padding: 0 38px 0 10px;
            }
        }
    }

    .swc-main {
        font-size: 18px;
        margin: 5px 0 0;

        span {
            max-width: 80px;
            line-height: 22px;
        }

        i {
            font-size: 47px;
            margin-right: 10px;
        }
    }

    .swc-info {
        font-size: 12px;
        line-height: 14px;
        max-width: 142px;
        margin: 15px auto;
    }
}

@media #{$xsmall} {
    .profile-search-widget {
        height: 135px;
    }

    .search-widget-container {
        height: 135px;
    }

    .search-widget-content {
        height: 135px;
        padding: 0;
        @apply flex items-center justify-center;

        a {
            width: 120px;
            height: 22px;
            padding: 0 20px 0 10px;
            font-size: 12px;
            margin: 15px auto 0;

            i {
                font-size: 15px;
                top: 3px;
                right: 3px;
            }

            &:not(.disabled):hover {
                padding: 0 20px 0 10px;
            }
        }
    }

    .sw-actual-content{
        height: 80%;
        margin: 0 15px 0 0;
        @apply border-r border-solid border-white;
    }

    .swc-main {
        font-size: 18px;
        margin: 0 15px 0 0;

        span {
            max-width: 80px;
            line-height: 22px;
        }

        i {
            font-size: 33px;
            margin-right: 10px;
        }
    }

    .swc-info {
        font-size: 10px;
        line-height: 12px;
        max-width: 120px;
        margin: 0;
    }

    .search-flights-button-container{
        @apply border-r border-solid border-white;
        margin: 0 15px 0 0;
        padding: 0 15px 0 0;
        height: 80%;

        a {
            @apply m-0;
            height: 50px;
            font-size: 12px;
            padding: 0 38px 0 10px;

            &:after {
                font-size: 20px;
            }

            &:not(.disabled):hover {
                @apply bg-white text-brand-primary border-solid border-2 border-brand-primary;
                padding: 0 38px 0 10px;
            }
        }
    }
}
