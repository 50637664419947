$giftcard-blue: #21365a;
$giftcard-blue2: #122e66;
$giftcard-blue3: #1f3a6c;
$giftcard-red: #a03334;
$giftcard-red2: #ad272f;
$giftcard-cyan: #00abcd;
$giftcard-cyan2: #74c1d6;
$giftcard-cyan3: #00acc5;
$giftcard-cyan4: #00a8c4;
$giftcard-gray: #afafaf;
$giftcard-gray2: #606163;
$giftcard-darkgray: #1b365d;
$giftcard-darkgray2: #21355b;
