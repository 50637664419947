@import "./flight/flight-variables";
@import "./modal/booking-modal-content";
@import "./modal/cug-modal-content";
@import "./modal/flight-modal-content";
@import "./modal/payment-modal-content";

.modal {
    @apply fixed left-0 top-0 h-full w-full;
    background: rgba($brand-secondary, 0.4);
    z-index: 1001;

    &.primary-modal {
        .modal-content {
            @apply p-0;
            width: 595px;
            max-width: 90%;

            &.peru-compra {
                width: 29rem;
            }

            &.booking-modal-content {
                left: 50%;
                transform: translate(-50%, -50%);

                .modal-header {
                    @apply bg-be-red-2 text-white;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    span {
                        @apply relative top-0 m-auto pt-2 font-emphasis leading-5;
                        font-size: 23px;
                    }

                    i {
                        @apply text-white;
                        font-size: 35px;
                    }

                    &.flight-move {
                        @apply flex justify-center;

                        i {
                            @apply absolute vertical-center;
                            left: 20px;
                        }
                    }
                }

                .modal-body {
                    @apply text-brand-secondary;
                    padding: 25px 60px;

                    .description-container {
                        @apply leading-6;
                        text-align: center;

                        p {
                            @apply mb-2 font-body font-bold leading-5;
                            font-size: 14px;
                        }

                        span {
                            @apply leading-6;
                        }

                        a {
                            @apply inline leading-4 text-brand-primary;
                        }
                    }

                    .primary-modal-button-container {
                        @apply mb-2 mt-8 flex justify-center;
                    }

                    .primary-url {
                        text-align: center;
                    }
                }
            }
        }
    }

    &.secondary-modal {
        .modal-content {
            @apply p-0;
            width: 595px;
            max-width: 90%;

            &.booking-modal-content {
                left: 50%;
                transform: translate(-50%, -50%);

                .modal-header {
                    @apply bg-brand-secondary text-white;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    .secondary-modal-header-text {
                        @apply relative top-0 m-auto pt-2 font-emphasis leading-5;
                        font-size: 23px;
                    }

                    .modal-header-closing {
                        &:hover {
                            @apply text-brand-tertiary;
                        }
                    }

                    i {
                        @apply text-white;
                    }
                }

                .modal-body {
                    @apply text-brand-secondary;
                    padding: 30px 60px;

                    .description-container {
                        @apply leading-6 text-black;
                    }

                    .secondary-modal-button-container {
                        @apply mb-2 mt-8 flex justify-center;
                    }

                    .error-message-container {
                        @apply flex justify-center;
                    }
                }
            }
        }
    }

    &.cug-modal {
        .mdl-textfield {
            padding: 5px 0;
        }

        .mdl-textfield--floating-label {
            &.is-focused,
            &.is-dirty,
            &.has-placeholder {
                .mdl-textfield__label {
                    top: -3px;
                }
            }

            .mdl-textfield__label {
                top: 9px;
            }
        }

        .js-input {
            &.js-select {
                font-size: 20px;
                padding-left: 15px;
                padding-top: 8px;
                color: $brand-secondary;
                -webkit-appearance: none;

                option {
                    font-size: $font-size-medium;
                }
            }
        }

        .js-select-arrow {
            &:after {
                content: "\25BC";
                display: block;
                position: absolute;
                top: 25px;
                right: 15px;
                font-size: $font-size-xsmall;
                color: $gray-light;
            }
        }

        &.agency-modal {
            i {
                display: block;
                margin-top: 20px;
                font-size: 120px;
                color: rgba($brand-tertiary, 0.5);
            }

            h1 {
                margin: 20px auto;
                display: block;
                line-height: 1.2;
                font-size: 28px;

                &.contact {
                    max-width: 300px;
                }

                &.delete {
                    max-width: 250px;
                }

                &.activate {
                    max-width: 360px;
                    margin-bottom: 40px;
                }

                &.deactivate {
                    max-width: 360px;
                    margin-bottom: 40px;
                }

                &.reset-pw {
                    max-width: 360px;
                    margin-bottom: 40px;
                }

                &.add-user,
                &.edit-user {
                    margin: 20px 0 0 30px;

                    i {
                        display: inline;
                        font-size: $font-size-xlarge;
                        color: $brand-secondary;
                        margin-right: 10px;
                    }
                }

                span {
                    font-weight: 900;
                    font-style: italic;
                    color: $brand-primary;
                }
            }

            .spnr {
                color: $brand-secondary;
                margin-bottom: 40px;

                span {
                    color: $brand-tertiary;
                    font-size: 20px;
                }
            }

            .delete-warning {
                color: $brand-secondary;
                font-size: $font-size-small;
                text-align: center;

                i {
                    color: $brand-primary;
                    font-size: $font-size-medium;
                    display: inline;
                }
            }

            p {
                margin: 0 0 5px;
                color: $brand-secondary;
                font-size: $font-size-small;
                line-height: 1;

                &.phone {
                    margin-bottom: 40px;

                    a {
                        font-size: 20px;
                        font-weight: 600;
                        color: $brand-tertiary;

                        &:hover {
                            color: $brand-secondary;
                        }
                    }
                }
            }
        }
    }

    &.payment-modal {
        .mdl-textfield {
            padding: 5px 0;
        }

        .mdl-textfield--floating-label {
            &.is-focused,
            &.is-dirty,
            &.has-placeholder {
                .mdl-textfield__label {
                    top: -3px;
                }
            }

            .mdl-textfield__label {
                top: 9px;
            }
        }

        .js-input {
            &.js-select {
                font-size: 20px;
                padding-left: 15px;
                padding-top: 8px;
                color: $brand-secondary;
                -webkit-appearance: none;

                option {
                    font-size: $font-size-medium;
                }
            }
        }

        .js-select-arrow {
            &:after {
                content: "\25BC";
                display: block;
                position: absolute;
                top: 25px;
                right: 15px;
                font-size: $font-size-xsmall;
                color: $gray-light;
            }
        }
    }

    &.invalid-promo-code-modal {
        &.modal {
            .modal-content {
                border-radius: 20px;
                background: #fff;
                padding: 0;
                min-height: 150px;
                min-width: 320px;
                max-width: 95%;

                .modal-header {
                    @apply bg-be-red-2 text-white;
                    position: relative;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: "Lato", sans-serif;
                    font-size: 30px;
                    font-style: italic;
                    font-weight: 600;
                    height: 70px;

                    button {
                        position: absolute;
                        top: -14px;
                        right: -14px;
                        background: #000;
                        color: #fff;
                        width: 34px;
                        height: 34px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 25px;
                        border-radius: 50%;
                        border: none;
                        letter-spacing: normal;
                        padding: 0;
                        font-weight: 900;

                        &:hover {
                            background: $brand-secondary;
                        }
                    }
                }

                .modal-body {
                    @apply leading-6;
                    padding: 35px 30px 35px 60px;
                }
            }
        }
    }
    .modal-content {
        .modal-header {
            &.session-warning {
                #timeoutDisplayMinutes {
                    margin-left: 6px;
                }
            }
        }
    }
}

.modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: $border-radius-lg;
    background: #fff;
    z-index: 1001;
}

.cug-modal-yield {
    position: relative;
    z-index: 1000;
}

.modal {
    &.agent-difference-modal {
        .modal-content {
            .modal-body {
                .cancel-link-container {
                    @apply mt-4 flex justify-end;

                    .cancel-link {
                        @apply inline-block cursor-pointer select-none text-lg font-bold text-be-blue underline;
                        text-align: right;
                    }
                }
            }
        }
    }
}

.refund-bank-info-modal {
    &.modal-content {
        .modal-header {
            @apply flex justify-center px-2 py-3;

            i {
                @apply mr-2;
                font-size: 55px;
            }
        }
    }

    .bank-info-description {
        @apply leading-6 text-be-blue;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .js-input {
        font-size: 14px;

        &.js-select {
            -webkit-appearance: none;
            padding-top: 5px;
        }
    }

    .mdl-textfield {
        padding: 10px 0;
    }

    .mdl-textfield__label {
        top: 15px;
    }

    .mdl-textfield__input {
        font-size: 14px;
    }

    .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
    .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
    .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
        top: 0;
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 30px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.refund-bank-info-header-text {
    font-size: 20px;
}

.refund-bank-info-btn-container {
    @apply flex justify-center;
    margin-top: 30px;

    .rounded-primary-btn {
        @apply m-auto;
        width: 175px;
    }
}

.declined-modal-description-container {
    @apply leading-6;
}

.declined-modal-payment-subtitle {
    @apply mb-3 mt-2 font-semibold;
}

/* MEDIA QUERIES */

@media #{$small-down} {
    .result-box {
        h1 {
            font-size: 26px;
        }

        h2 {
            font-size: 20px;
        }

        p {
            width: 90%;
        }

        i {
            font-size: 170px;
        }
    }
}

@media #{$xsmall} {
    .modal {
        &.primary-modal {
            .modal-content {
                &.booking-modal-content {
                    .modal-body {
                        padding: 10px 15px;
                    }
                }
            }
        }

        &.secondary-modal {
            .modal-content {
                &.booking-modal-content {
                    .modal-header {
                        padding: 10px;
                    }

                    i {
                        @apply mr-0;
                    }
                    .modal-body {
                        padding: 10px 15px;
                    }
                }
            }
        }

        .modal-content {
            .modal-header {
                &.session-warning {
                    padding: 10px;
                    font-size: 18px;

                    i {
                        margin-right: 15px;
                        font-size: 30px;
                    }

                    #timeoutDisplayMinutes {
                        width: 85px;
                        margin-left: 4px;
                    }
                }
            }
        }
    }

    .modal-content {
        overflow-y: visible;

        .modal-header {
            overflow: visible;

            .header-with-subtitle {
                span {
                    display: contents;
                }
            }

            .ribbon-text {
                padding: 1px 15px;
                left: -5px;
                top: 0;
                transform: translateY(-33%);
                bottom: auto;
            }
        }
    }

    .argentina-prices-table {
        td {
            width: 50%;
            vertical-align: middle;
            text-align: left;
        }

        tr {
            &:first-child {
                td {
                    background: unset;
                    color: unset;
                }
            }

            td {
                background: unset;
                color: unset;

                &:first-child {
                    background: $brand-secondary;
                    color: #fff;
                }
            }
        }
    }

    .modal {
        &.agent-difference-modal {
            .modal-content {
                .modal-body {
                    .cancel-link-container {
                        @apply mt-5;
                    }
                }
            }
        }
    }

    .refund-bank-info-modal {
        &.modal-content {
            @apply p-0;
            height: 660px;
            width: 770px;
            max-height: 95%;
            max-width: 95%;

            .modal-header {
                i {
                    font-size: 36px;
                }
            }

            .refund-bank-info-scroller {
                @apply absolute bottom-0 left-0 right-0 overflow-hidden overflow-y-scroll;
                top: 45px;
            }
        }

        .bank-info-description {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 3px;
        }

        .mdl-textfield {
            padding: 5px 0;
        }

        .js-input {
            &.js-select {
                padding-top: 5px;
                -webkit-appearance: none;
            }
        }

        .js-select-arrow {
            &:after {
                top: 25px;
            }
        }

        option {
            font-size: 11px;
        }
    }

    .refund-bank-info-btn-container {
        margin-top: 20px;
    }

    .refund-bank-info-header-text {
        @apply font-bold;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }
}

.modal-scroller {
    @apply overflow-y-auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
