.payment-footer-grid {
    @apply relative grid w-full items-stretch gap-1;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "terms terms"
        "button-2 button-2";

    .payment-footer-grid-button-1 {
        display: none;
    }

    .payment-footer-grid-terms {
        .basic-checkbox-wrapper {
            &.payment-terms-accept-checkbox {
                [type="checkbox"] + label[for] {
                    height: auto;
                    margin: 8px 0;
                }

                input {
                    &:checked {
                        &:not(:disabled) {
                            ~ label {
                                &:after {
                                    @apply z-0;
                                }
                            }
                        }
                    }
                }

                label {
                    @apply text-brand-secondary;

                    &:before {
                        @apply z-0;

                        width: 40px;
                        height: 40px;
                        border-radius: 10px;
                        border: 2px solid #59c3d9;
                    }
                    &:after {
                        width: 40px;
                        height: 39px;
                    }

                    > span {
                        line-height: 1.2;
                        margin-left: 15px;
                    }
                }
            }
        }

        .payment-checkbox-terms-wrapper {
            &.payment {
                &.terms {
                    @apply mb-4 w-full;

                    &.limited {
                        max-width: 500px;
                    }
                }
            }
        }
    }

    &.without-teen {
        grid-template-columns: auto;
        grid-template-areas: "terms button-2";
        width: max-content;
        margin-left: auto;

        .payment-footer-grid-terms {
            .payment-checkbox-terms-wrapper {
                &.payment {
                    &.terms {
                        max-width: 500px;
                    }
                }
            }
        }
    }

    &.with-fee {
        grid-template-columns: 1fr auto auto;
        grid-template-areas:
            "terms terms terms"
            "button-0 button-1 button-2";

        .payment-footer-grid-button-1 {
            @apply flex items-start justify-start;
            grid-area: button-1;
        }
    }
}

.payment-footer-grid-terms {
    grid-area: terms;

    .payment-checkbox-terms-wrapper {
        &.payment {
            &.terms {
                max-width: unset;
            }
        }
    }
}

.payment-footer-grid-button-2 {
    @apply w-full;
    text-align: right;
    grid-area: button-2;

    .rounded-primary-btn {
        @apply inline-flex;
    }
}

/* MEDIA QUERIES */
@media #{$medium-down} {
    .payment-checkbox-terms-wrapper {
        &.payment {
            &.terms {
                &.payment-wider {
                    height: unset;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .payment-footer-grid {
        @apply gap-x-0;
        grid-template-columns: 1fr;
        grid-template-areas:
            "terms"
            "button-1";

        &.with-fee {
            grid-template-columns: 1fr;
            grid-template-areas:
                "terms"
                "button-1";

            .payment-footer-grid-button-1 {
                @apply mb-4 flex justify-center;
            }
        }

        &.without-teen {
            grid-template-columns: 1fr;
            grid-template-areas:
                "terms"
                "button-1";

            width: unset;
            margin-left: unset;

            .payment-footer-grid-terms {
                .payment-checkbox-terms-wrapper {
                    &.payment {
                        &.terms {
                            max-width: 95%;
                        }
                    }
                }
            }
        }

        .payment-footer-grid-terms {
            .payment-checkbox-terms-wrapper {
                &.payment {
                    &.terms {
                        max-width: 95%;

                        &.limited {
                            max-width: 95%;
                        }
                    }
                }
            }
        }
    }
}
