.payment-on-hold-checkbox-wrapper {
    @apply w-60 border border-solid border-brand-secondary bg-white pl-2.5;

    height: 52px;
    border-radius: 10px;
    margin-bottom: 20px;

    .payment-on-hold-checkbox-label {
        &:before {
            @apply h-10 w-10 border-2 border-new-ui-cyan;

            border-radius: 10px;
        }

        &:after {
            @apply h-8 w-8;

            left: 3px;
        }
    }

    input {
        &:checked {
            &:disabled {
                ~ label {
                    &:before {
                        @apply bg-new-ui-cyan;
                    }
                    &:after {
                        @apply bg-white;
                        z-index: 2;
                        clip-path: polygon(39% 75%, 86% 28%, 91% 32%, 39% 83%, 15% 65%, 19% 60%);
                    }
                }
            }
        }
    }
}

.payment-on-hold-checkbox-text {
    @apply font-bold text-brand-secondary;
    margin-left: 20px;
}
