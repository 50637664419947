.cf-segments-container {
    margin-bottom: 15px;
    padding: 0 25px 0 25px;

    .cf-journey-date {
        @apply font-light leading-none m-0 text-brand-secondary whitespace-normal;
        padding: 8px 0;
        font-weight: 400;
        font-size: 15px;

        span {
            padding-right: 5px;
            font-size: $font-size-small;
            font-weight: 900;
            color: $brand-primary;
            text-transform: uppercase;
        }
    }
}

.cf-segments-title {
    @apply w-full border-b-2 border-dotted border-brand-secondary text-brand-secondary font-semibold;
    margin-bottom: 15px;
    line-height: 26px;
    margin-top: 25px;
    font-size: 16px;
    padding-bottom: 8px;
}

.cf-sidebar-segment-container {
    @apply bg-new-ui-dark-cyan text-white rounded;
    margin-bottom: 10px;
}

.cf-sidebar-segment-times {
    @apply flex items-center justify-between;
    padding: 7px 10px;

    .decor {
        @apply text-white font-normal;
        font-size: 16px;
    }

    &.changed-times {
        .cf-sidebar-segment-endpoint {
            color: #80d5e6;

            span {
                &:nth-child(3) {
                    @apply relative;

                    &:after {
                        @apply absolute vertical-center left-0 right-0 bg-new-ui-blue;
                        content: "";
                        height: 1px;
                    }
                }
            }
        }

        .decor {
            color: #80d5e6;
        }
    }
}

.cf-sidebar-segment-endpoint {
    span {
        &:first-child {
            @apply font-extrabold;
            font-size: 12px;
        }

        &:nth-child(3) {
            @apply font-bold;
            font-size: 12px;
        }

        &.one-more-day {
            @apply text-new-ui-red font-extrabold;
            font-size: 11px;
            letter-spacing: -1px;
            line-height: 14px;
        }
    }

    i {
        @apply font-normal;
        font-size: 14px;
        margin: 0 3px 0 11px;
    }
}

.cf-sidebar-new-time {
    @apply font-bold italic;
    font-size: 14px;
    padding: 0 10px;

    + .cf-sidebar-segment-times {
        padding-top: 2px;
    }
}

/* MEDIA QUERIES */

@media #{$small-down} {
    .cf-segments-container {
        padding: 0 15px;
    }
}
