@import "./baggage-page-variables";

.b2-baggage-page {
    @apply select-none;
    padding-left: 50px;
    padding-right: 50px;
}

.b2-openable {
    @apply overflow-hidden common-transition;
    max-height: 0;

    &.b2-opened {
        max-height: 2350px;
    }
}

header {
    &.b2-header {
        margin-bottom: 15px;
    }

    &.b2-section-header {
        @apply block;
        margin-top: 25px;
        margin-bottom: 35px;
        background-color: #f2f2f2;
        border-radius: 10px;
        padding: 10px 20px 10px 40px;

        &.push-down {
            margin-top: 65px;
        }

        .b2-section-number {
            @apply block border-r-4 border-solid border-be-blue font-emphasis leading-none text-be-blue;
            font-size: 45px;
            padding: 7px 12px 0 0;
            margin-right: 15px;
        }

        h3 {
            @apply m-0 font-emphasis leading-none text-be-blue;
            font-size: 19px;
        }

        h4 {
            @apply m-0 font-body leading-none text-be-blue;
            font-size: 16px;
        }
    }
}

.b2-staff-benefit-message {
    @apply rounded-lg bg-be-cyan-2 px-6 py-2 leading-4 text-white;
    font-size: 14px;
}

.b2-free-bag-option {
    @apply relative h-full border border-r-0 border-solid border-bg-gray-1 bg-white common-transition;
    transform-origin: center center;
    border-top-left-radius: 13px;

    &.hoverable {
        @apply cursor-pointer;

        &:hover {
            @apply shadow-medium;
            transform: scale(1.05);
            z-index: 10;

            .b2-error {
                display: none;
            }
        }
    }

    &.unselected {
        .b2-per-booking-next-price {
            @apply text-bg-gray-2;
        }
    }

    &.selected {
        @apply border border-be-blue;

        .b2-free-option-btn-container {
            @apply bg-be-blue;
        }

        .b2-free-option-info {
            @apply text-white;
        }
    }

    &.per-pax {
        @apply border-b-0 border-r-0 border-be-blue;
    }
}

.b2-backpack-img {
    width: 222px;
    height: 200px;
}

.b2-backpack-and-large-cabin-bag-img {
    width: 281px;
    height: 180px;
}

.b2-without-checked-baggage-img {
    width: 206px;
    height: 200px;
    margin-bottom: 49px;

    &.pt-culture {
        margin-bottom: 25px;
    }

    &.en-culture {
        margin-bottom: 24px;
    }
}

.b2-checked-baggage-img {
    width: 210px;
    height: 205px;
}

.ac-per-booking-free-option {
    grid-area: free;
}

.ac-per-booking-paid-option {
    grid-area: paid;
}

.b2-paid-bag-option {
    @apply h-full border border-solid border-bg-gray-1 bg-white common-transition;
    transform-origin: center center;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;

    &.hoverable {
        @apply cursor-pointer;

        &:hover {
            @apply shadow-medium;
            transform: scale(1.05);
            z-index: 1;
        }
    }

    &.unselected {
        .b2-per-booking-next-price {
            @apply text-bg-gray-2;
        }
    }

    &.selected {
        @apply border border-be-red;

        .b2-paid-option-btn-container {
            @apply bg-be-red;
        }

        .b2-per-booking-next-price {
            @apply text-white;
        }

        .b2-per-booking-quantity {
            @apply text-white;
        }
    }

    &.per-pax {
        @apply rounded-br-none border-b-0 border-be-blue;
    }
}

.b2-view {
    grid-area: view;
}

.b2-view-switch {
    @apply border border-r-0 border-solid border-bg-gray-1 bg-be-light-gray;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    padding: 25px 18px;
}

.b2-view-info {
    @apply font-emphasis text-be-blue;
    font-size: 15px;
    line-height: 18px;
}

.b2-pax-amount {
    @apply font-body text-be-blue;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 15px;
}

.b2-free-option-btn-container {
    @apply flex flex-col items-center justify-center border-t border-solid border-bg-gray-1 bg-be-light-gray;
    height: calc(100% - #{$containerHeightXLargeLow});

    &.taller {
        height: calc(100% - #{$containerHeightXLargeHigh});
    }
}

.b2-free-option-info {
    @apply font-body font-semibold text-be-blue;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
}

.b2-paid-option-btn-container {
    @apply flex flex-col items-center justify-center border-t border-solid border-bg-gray-1 bg-be-light-gray;
    height: calc(100% - #{$containerHeightXLargeLow});
    border-bottom-right-radius: 10px;

    &.taller {
        height: calc(100% - #{$containerHeightXLargeHigh});
    }

    &.align-right {
        @apply items-end;
    }
}

.b2-per-booking-next-price {
    @apply font-body font-semibold text-be-blue;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 12px;

    &.push-left {
        @apply mb-0;
        margin-top: 8px;
        margin-right: 30px;
    }

    .b2-amount {
        @apply font-heavy;
        font-size: 20px;
    }

    &.original-price {
        @apply absolute font-body font-normal;
        font-size: 15px;
        top: -22px;
        left: 50%;
        transform: translateX(-50%);
        text-decoration: line-through;
    }
}

.b2-error {
    @apply pointer-events-none absolute bg-bg-berry font-body text-white;
    left: 61.6%;
    top: 355px;
    padding: 15px;
    border-radius: 7px;
    font-size: 12px;
    line-height: 14px;
    transform: translateX(-50%);
    max-width: 170px;
    z-index: 10;

    &:after {
        @apply absolute bg-bg-berry;
        content: "";
        width: 17px;
        height: 17px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &.b2-error-per-journey {
        top: -15px;
    }
}

.b2-add-another {
    @apply text-white;
    align-self: flex-end;
    margin: 10px 30px 0 0;

    &.padded-top {
        padding-top: 15px;
    }

    span {
        @apply font-heavy;
        font-size: 21px;
    }
}

.b2-per-booking-quantity {
    @apply font-emphasis text-brand-secondary;
    margin: 6px 20px 0;
    font-size: 27px;
}

.b2-baggage-dc-info {
    @apply inline-flex items-center bg-be-cyan-2 font-body font-semibold text-white;
    border-radius: 10px;
    padding: 8px 15px;
    font-size: 13px;
    margin: 20px 0 15px;

    i {
        @apply text-white;
        font-size: 20px;
        margin-right: 8px;
    }
}

.b2-baggage-bundle-info {
    @apply inline-flex items-center bg-be-blue font-body font-semibold text-white;
    border-radius: 10px;
    padding: 8px 15px;
    font-size: 13px;
    margin: 0 0 15px;

    &.push-down {
        margin-top: 20px;
    }

    i {
        @apply text-white;
        font-size: 20px;
        margin-right: 8px;
    }

    img {
        @apply relative;
        top: 1px;
        height: 13px;
        margin: 0 4px;
    }
}

.b2-baggage-bundle-per-pax-info {
    @apply flex items-center justify-center bg-be-blue font-body font-semibold text-white;
    height: 42px;
    font-size: 13px;

    i {
        @apply text-white;
        font-size: 20px;
        margin-right: 10px;
    }

    img {
        @apply relative;
        top: 1px;
        height: 13px;
    }
}

.b2-baggage-sold-out-per-pax-info {
    @apply flex items-center justify-center bg-be-red font-body text-white;
    height: 78px;
    font-size: 21px;
    padding: 0 135px;
    line-height: 26px;

    i {
        @apply text-white;
        font-size: 50px;
        margin-right: 5px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .b2-baggage-page {
        padding-left: 25px;
        padding-right: 25px;
    }

    header {
        &.b2-header {
            margin-bottom: 15px;
        }

        &.b2-section-header {
            margin-top: 25px;
            margin-bottom: 35px;

            &.push-down {
                margin-top: 65px;
            }

            .b2-section-number {
                font-size: 45px;
                padding: 7px 12px 0 0;
                margin-right: 15px;
            }

            h3 {
                font-size: 19px;
            }

            h4 {
                font-size: 16px;
            }
        }
    }

    .b2-backpack-img {
        width: 250px;
        height: 225px;
    }

    .b2-backpack-and-large-cabin-bag-img {
        width: 304px;
        height: 195px;
    }

    .b2-without-checked-baggage-img {
        margin-bottom: 44px;

        &.pt-culture {
            margin-bottom: 19px;
        }

        &.en-culture {
            margin-bottom: 42px;
        }
    }

    .b2-free-bag-option {
        border-top-left-radius: 13px;
    }

    .b2-paid-bag-option {
        border-top-right-radius: 13px;
        border-bottom-right-radius: 13px;
    }

    .b2-view-switch {
        border-top-left-radius: 13px;
        border-bottom-left-radius: 13px;
        padding: 25px 18px;
    }

    .b2-view-info {
        font-size: 13px;
        line-height: 16px;
    }

    .b2-pax-amount {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 15px;
    }

    .b2-free-option-btn-container {
        height: calc(100% - #{$containerHeightLargeLow});

        &.taller {
            height: calc(100% - #{$containerHeightLargeHigh});
        }
    }

    .b2-free-option-info {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
    }

    .b2-paid-option-btn-container {
        height: calc(100% - #{$containerHeightLargeLow});
        border-bottom-right-radius: 10px;

        &.taller {
            height: calc(100% - #{$containerHeightLargeHigh});
        }
    }

    .b2-per-booking-next-price {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 12px;

        &.push-left {
            margin-right: 22px;
        }

        .b2-amount {
            font-size: 18px;
        }
    }

    .b2-error {
        left: 61.6%;
        top: 335px;
        padding: 15px;
        border-radius: 7px;
        font-size: 12px;
        line-height: 14px;
        max-width: 170px;

        &:after {
            width: 17px;
            height: 17px;
        }

        &.b2-error-per-journey {
            top: -15px;
        }
    }

    .b2-add-another {
        margin: 10px 20px 0 0;

        span {
            font-size: 21px;
        }
    }

    .b2-per-booking-quantity {
        margin: 5px 12px 0;
        font-size: 25px;
    }

    .b2-baggage-dc-info {
        border-radius: 10px;
        padding: 8px 15px;
        font-size: 13px;
        margin: 20px 0 15px;

        i {
            font-size: 20px;
            margin-right: 8px;
        }
    }

    .b2-baggage-bundle-info {
        border-radius: 10px;
        padding: 8px 15px;
        font-size: 13px;
        margin: 0 0 15px;

        &.push-down {
            margin-top: 20px;
        }

        i {
            font-size: 20px;
            margin-right: 8px;
        }

        img {
            top: 1px;
            height: 13px;
        }
    }

    .b2-baggage-bundle-per-pax-info {
        height: 42px;
        font-size: 13px;

        i {
            font-size: 20px;
            margin-right: 10px;
        }

        img {
            top: 1px;
            height: 13px;
        }
    }

    .b2-baggage-sold-out-per-pax-info {
        height: 78px;
        font-size: 18px;
        padding: 0 45px;
        line-height: 26px;

        i {
            font-size: 40px;
            margin-right: 5px;
        }
    }
}

@media #{$medium} {
    .b2-baggage-page {
        padding-left: 25px;
        padding-right: 25px;
    }

    header {
        &.b2-header {
            margin-bottom: 10px;
        }

        &.b2-section-header {
            margin-top: 20px;
            margin-bottom: 27px;

            &.push-down {
                margin-top: 50px;
            }

            .b2-section-number {
                font-size: 34px;
                padding: 6px 9px 0 0;
                margin-right: 11px;
            }

            h3 {
                font-size: 15px;
            }

            h4 {
                font-size: 12px;
            }
        }
    }

    .b2-backpack-img {
        width: 189px;
        height: 170px;
    }

    .b2-backpack-and-large-cabin-bag-img {
        width: 193px;
        height: 124px;
    }

    .b2-without-checked-baggage-img {
        width: 175px;
        height: 170px;
        margin-bottom: 17px;

        &.pt-culture {
            margin-bottom: 13px;
        }

        &.en-culture {
            margin-bottom: 17px;
        }
    }

    .b2-checked-baggage-img {
        width: 182px;
        height: 177px;
    }

    .b2-free-bag-option {
        border-top-left-radius: 9px;
    }

    .b2-paid-bag-option {
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
    }

    .b2-view-switch {
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        padding: 17px 13px;
    }

    .b2-view-info {
        font-size: 10px;
        line-height: 12px;
    }

    .b2-pax-amount {
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 12px;
    }

    .b2-free-option-btn-container {
        height: calc(100% - #{$containerHeightMediumLow});

        &.taller {
            height: calc(100% - #{$containerHeightMediumHigh});
        }
    }

    .b2-free-option-info {
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 8px;
    }

    .b2-paid-option-btn-container {
        height: calc(100% - #{$containerHeightMediumLow});
        border-bottom-right-radius: 10px;

        &.taller {
            height: calc(100% - #{$containerHeightMediumHigh});
        }
    }

    .b2-per-booking-next-price {
        font-size: 11px;
        line-height: 12px;
        margin-bottom: 8px;

        &.push-left {
            margin-top: 6px;
            margin-right: 15px;
        }

        .b2-amount {
            font-size: 13px;
        }

        &.original-price {
            font-size: 13px;
            top: -15px;
        }
    }

    .b2-error {
        left: 61.6%;
        top: 240px;
        padding: 12px;
        border-radius: 6px;
        font-size: 10px;
        line-height: 12px;
        max-width: 135px;

        &:after {
            width: 17px;
            height: 17px;
        }

        &.b2-error-per-journey {
            top: -60px;
        }
    }

    .b2-add-another {
        margin: 8px 15px 0 0;
        font-size: 15px;

        span {
            font-size: 15px;
        }
    }

    .b2-per-booking-quantity {
        margin: 4px 9px 0;
        font-size: 21px;
    }

    .b2-baggage-dc-info {
        border-radius: 8px;
        padding: 6px 13px;
        font-size: 10px;
        margin: 16px 0 11px;

        i {
            font-size: 16px;
            margin-right: 6px;
        }
    }

    .b2-baggage-bundle-info {
        border-radius: 8px;
        padding: 6px 11px;
        font-size: 10px;
        margin: 0 0 11px;

        &.push-down {
            margin-top: 16px;
        }

        i {
            font-size: 16px;
            margin-right: 6px;
        }

        img {
            top: 1px;
            height: 10px;
        }
    }

    .b2-baggage-bundle-per-pax-info {
        height: 35px;
        font-size: 10px;

        i {
            font-size: 16px;
            margin-right: 8px;
        }

        img {
            top: 1px;
            height: 10px;
        }
    }

    .b2-baggage-sold-out-per-pax-info {
        height: 69px;
        font-size: 14px;
        padding: 0 26px;
        line-height: 21px;

        i {
            font-size: 39px;
            margin-right: 4px;
        }
    }
}

@media #{$small} {
    header {
        &.b2-header {
            margin-bottom: 8px;
        }

        &.b2-section-header {
            margin-top: 14px;
            margin-bottom: 22px;

            &.push-down {
                margin-top: 40px;
            }

            .b2-section-number {
                font-size: 27px;
                padding: 9px 12px 0 0;
                margin-right: 6px;
            }

            h3 {
                font-size: 13px;
            }

            h4 {
                font-size: 11px;
            }
        }
    }

    .b2-staff-benefit-message {
        @apply rounded-md px-4 py-2;
        font-size: 12px;
    }

    .b2-backpack-img {
        width: 144px;
        height: 130px;
    }

    .b2-backpack-and-large-cabin-bag-img {
        width: 171px;
        height: 110px;
    }

    .b2-without-checked-baggage-img {
        width: 121px;
        height: 118px;
        margin-bottom: 39px;

        &.pt-culture {
            margin-bottom: 18px;
        }

        &.en-culture {
            margin-bottom: 20px;
        }
    }

    .b2-checked-baggage-img {
        width: 125px;
        height: 121px;
    }

    .b2-free-bag-option {
        border-top-left-radius: 8px;
    }

    .b2-paid-bag-option {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .b2-view-switch {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 8px 11px;
    }

    .b2-view-info {
        font-size: 10px;
        line-height: 12px;
    }

    .b2-pax-amount {
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 5px;
    }

    .b2-free-option-btn-container {
        height: calc(100% - #{$containerHeightSmallLow});

        &.taller {
            height: calc(100% - #{$containerHeightSmallHigh});
        }
    }

    .b2-free-option-info {
        font-size: 11px;
        line-height: 17px;
        margin-bottom: 8px;
    }

    .b2-paid-option-btn-container {
        height: calc(100% - #{$containerHeightSmallLow});
        border-bottom-right-radius: 8px;

        &.taller {
            height: calc(100% - #{$containerHeightSmallHigh});
        }
    }

    .b2-per-booking-next-price {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 4px;

        &.push-left {
            margin-top: 3px;
            margin-right: 15px;
        }

        .b2-amount {
            font-size: 12px;
        }

        &.original-price {
            font-size: 10px;
            top: -15px;
        }
    }

    .b2-error {
        left: 61.6%;
        top: 175px;
        padding: 15px;
        border-radius: 7px;
        font-size: 12px;
        line-height: 14px;
        max-width: 170px;

        &:after {
            width: 17px;
            height: 17px;
        }

        &.b2-error-per-journey {
            top: -20px;
        }
    }

    .b2-add-another {
        margin: 5px 15px 0 0;
        font-size: 10px;

        span {
            font-size: 13px;
        }
    }

    .b2-per-booking-quantity {
        margin: 3px 10px 0;
        font-size: 17px;
    }

    .b2-baggage-dc-info {
        border-radius: 6px;
        padding: 5px 12px;
        font-size: 10px;
        margin: 15px 0 20px;

        i {
            font-size: 15px;
            margin-right: 5px;
        }
    }

    .b2-baggage-bundle-info {
        border-radius: 6px;
        padding: 5px 12px;
        font-size: 10px;
        margin: 0 0 20px;

        &.push-down {
            margin-top: 15px;
        }

        i {
            font-size: 15px;
            margin-right: 5px;
        }

        img {
            top: 1px;
            height: 11px;
        }
    }

    .b2-baggage-bundle-per-pax-info {
        height: 30px;
        font-size: 9px;

        i {
            font-size: 14px;
            margin-right: 6px;
        }

        img {
            top: 1px;
            height: 9px;
        }
    }

    .b2-baggage-sold-out-per-pax-info {
        height: 47px;
        font-size: 13px;
        padding: 0 85px;
        line-height: 15px;

        i {
            font-size: 30px;
            margin-right: 3px;
        }
    }
}

@media #{$xsmall} {
    header {
        &.b2-header {
            margin-bottom: 8px;
        }

        &.b2-section-header {
            margin-top: 14px;
            margin-bottom: 18px;

            &.push-down {
                margin-top: 40px;
            }

            .b2-section-number {
                font-size: 27px;
                padding: 9px 6px 0 0;
                margin-right: 6px;
            }

            h3 {
                font-size: 14px;
            }

            h4 {
                font-size: 12px;
            }
        }
    }

    .b2-staff-benefit-message {
        @apply rounded-md px-4 py-2;
        font-size: 12px;
    }

    .b2-backpack-img {
        width: 131px;
        height: 110px;
    }

    .b2-backpack-and-large-cabin-bag-img {
        width: 148px;
        height: 95px;
    }

    .b2-without-checked-baggage-img {
        width: 121px;
        height: 118px;
        margin-bottom: 14px;

        &.per-journey-view {
            width: 98px;
            height: 95px;
            margin-bottom: 33px;
        }
    }

    .b2-checked-baggage-img {
        width: 121px;
        height: 118px;

        &.per-journey-view {
            width: 98px;
            height: 95px;
            // margin-bottom: -15px;
        }
    }

    .b2-error {
        padding: 10px;
        font-size: 11px;
        line-height: 12px;
        left: 50%;
        top: -60px;

        &.b2-error-per-journey {
            top: 5px;
        }
    }

    .b2-baggage-dc-info {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 13px;
        padding: 8px 14px;
        border-radius: 8px;
    }

    .b2-baggage-bundle-info {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 13px;
        padding: 8px 14px;
        border-radius: 8px;

        img {
            height: 9px;
        }
    }

    .b2-baggage-bundle-per-pax-info {
        font-size: 12px;
        line-height: 13px;
        padding: 8px 14px;

        img {
            height: 10px;
        }
    }

    .b2-baggage-sold-out-per-pax-info {
        @apply h-auto;
        font-size: 10px;
        line-height: 12px;
        padding: 8px 14px;

        i {
            display: none;
        }
    }
}
