ac-flight-journey-summary {
    @apply flex w-3/4 items-center justify-between;
}

.i2-multisegment-journey-container {
    @apply mb-4;

    ac-flight-journey-summary {
        @apply block w-full;
    }
}

.cf-multisegment-journey-grid {
    @apply grid w-full gap-x-8 gap-y-4;
    grid-template-columns: 3fr 1fr 3fr;
    grid-template-areas:
        "endpoint-header-origin transfer-header endpoint-header-destination"
        "endpoint-origin transfer endpoint-destination";
}

.cf-multisegment-journey-endpoint-header {
    @apply flex items-center justify-center rounded bg-cf-light-blue text-new-ui-turqoise;
    height: auto;
    max-height: 80px;
    padding: 7px;

    &.origin {
        grid-area: endpoint-header-origin;
    }

    &.destination {
        grid-area: endpoint-header-destination;
    }

    span {
        font-size: 14px;
    }

    i {
        font-size: 16px;
        margin: 0 10px;
    }
}

.cf-multisegment-journey-transfer-header {
    @apply flex items-center justify-center rounded bg-cf-light-blue text-new-ui-blue;
    grid-area: transfer-header;
    height: 36px;

    span {
        font-size: 14px;
    }

    i {
        font-size: 20px;
        margin-right: 5px;
    }
}

.cf-multisegment-journey-endpoint {
    @apply w-full;

    &.origin {
        grid-area: endpoint-origin;
    }

    &.destination {
        grid-area: endpoint-destination;
    }
}

.cf-multisegment-journey-transfer {
    @apply flex flex-col items-center justify-center rounded border border-dashed border-new-ui-blue;
    padding: 16px;
    grid-area: transfer;

    > div {
        @apply flex flex-col items-center justify-center;
    }
}

.cf-multisegment-journey-transfer-iata {
    @apply flex items-center justify-center font-body text-new-ui-blue;
    height: 32px;
    font-size: 30px;
}

.cf-multisegment-journey-transfer-name {
    @apply flex items-center justify-center whitespace-nowrap font-body text-new-ui-blue;
    height: 16px;
    font-size: 12px;
    margin-bottom: 10px;
}

.cf-multisegment-journey-stops {
    @apply flex items-center whitespace-nowrap font-body font-semibold italic text-new-ui-turqoise;
    font-size: 12px;
    margin-bottom: 10px;
}

.cf-multisegment-journey-stops-tooltip-opener {
    @apply flex cursor-pointer items-center justify-center rounded-full bg-new-ui-dark-cyan font-body font-bold not-italic text-white;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    font-size: 14px;
}

.cf-multisegment-journey-transfer-time {
    @apply font-body text-new-ui-turqoise;
    font-size: 11px;
    text-align: center;

    span {
        @apply font-bold;
    }
}

.cf-multisegment-journey-decor {
    @apply relative;
    width: calc(100% - 120px);

    &:after {
        @apply absolute left-0 right-0 bg-be-blue;
        content: "";
        top: 40px;
        height: 1px;
    }
}

.cf-decor-plane {
    @apply absolute z-10 bg-white;
    padding: 0 10px;
    top: 40px;
    left: 50%;
    transform: translate(-50%, -50%);

    i {
        @apply font-normal;
        font-size: 20px;
    }
}

.cf-decor-info {
    @apply absolute whitespace-nowrap font-body font-semibold italic text-new-ui-turqoise horizontal-center;
    top: 56px;
    font-size: 12px;
}

.cf-multisegment-journey-info {
    @apply flex w-full items-center justify-between;
    height: 40px;

    span {
        @apply text-new-ui-blue;
        font-size: 16px;

        > span {
            @apply font-black;
        }
    }
}

.flight-journey-summary {
    @apply w-1/4 flex-shrink-0;
}

.flight-journey-summary-info {
    @apply block;
}

.flight-journey-overview-info {
    @apply font-body text-new-ui-blue;
    font-size: 16px;
    line-height: 26px;

    span {
        @apply font-body font-black text-new-ui-blue;
        font-size: 16px;
        line-height: 26px;
    }
}

.flight-journey-flight-plan {
    @apply flex items-start justify-center;
    width: 75%;
}

.flight-journey-pill {
    @apply inline-block whitespace-nowrap rounded-full bg-new-ui-turqoise font-body font-extrabold text-white;
    padding: 7px 20px;
    font-size: 18px;
    margin-bottom: 10px;
}

.flight-journey-time {
    @apply relative text-new-ui-turqoise;
    font-size: 14px;

    &.changed {
        @apply relative inline-block text-new-ui-gray;

        &:after {
            @apply absolute left-0 right-0 rounded-full bg-new-ui-red vertical-center;
            height: 2px;
            content: "";
        }
    }

    .one-more-day {
        @apply absolute top-0 font-extrabold leading-none text-new-ui-red;
        right: -13px;
        font-size: 11px;
        letter-spacing: -1px;
    }
}

.flight-journey-flight-left,
.flight-journey-flight-right {
    width: 120px;

    > div {
        @apply relative font-body;
        line-height: 1.15;

        &:nth-child(1) {
            @apply text-new-ui-gray;
            font-size: 14px;
        }

        &:nth-child(2) {
            @apply text-new-ui-blue;
            font-size: 42px;
        }

        &:nth-child(3) {
            @apply mb-1 text-new-ui-blue;
            font-size: 14px;
        }
    }
}

.flight-journey-flight-left {
    text-align: left;
    margin-right: 20px;
}

.flight-journey-flight-right {
    text-align: right;
    margin-left: 20px;
}

.flight-journey-stops-info {
    @apply relative flex flex-col items-center;
    margin: 0 25px;
    top: 9px;

    i {
        @apply text-new-ui-blue;
        font-size: 37px;
        margin-bottom: 10px;
    }
}

.flight-journey-stops-tooltip-opener {
    @apply relative flex cursor-pointer items-center justify-center rounded-full bg-new-ui-dark-cyan font-body text-white;
    width: 24px;
    height: 24px;
    font-size: 13px;
    margin-bottom: 12px;

    &:after,
    &:before {
        @apply absolute bg-new-ui-blue vertical-center;
        content: "";
        width: 22px;
        height: 1px;
    }

    &:before {
        left: -30px;
    }

    &:after {
        right: -30px;
    }
}

.flight-journey-stops-number {
    @apply cursor-pointer whitespace-nowrap font-body italic text-new-ui-cyan;
    width: 120px;
    font-size: 16px;
    text-align: center;
}

.cf-journey-segment-new-time {
    @apply whitespace-nowrap text-new-ui-gray;
    letter-spacing: 1.25px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px;
}

.cf-journey-segment-time {
    @apply inline-flex items-center rounded font-bold text-new-ui-turqoise;
    background: #caf8ff;
    padding: 5px;
    font-size: 14px;

    i {
        @apply text-new-ui-turqoise;
        font-size: 15px;
        margin-right: 5px;
    }

    .one-more-day {
        @apply font-extrabold text-new-ui-red;
        font-size: 11px;
        letter-spacing: -1px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .flight-journey-stops-info {
        @apply m-0;
    }

    .i2-itinerary-container {
        .flight-journey-stops-number {
            width: 105px;
        }

        .flight-journey-flight-left,
        .flight-journey-flight-right {
            width: 100px;
        }
    }

    .flight-journey-pill {
        font-size: 16px;
    }
}

@media #{$medium} {
    .cf-multisegment-journey-info {
        span {
            font-size: 14px;
        }
    }

    .flight-journey-pill {
        padding: 6px 18px;
        margin-bottom: 12px;
    }

    .flight-journey-overview-info {
        span {
            font-size: 14px;
        }
    }

    .i2-itinerary-container {
        .flight-journey-stops-info {
            @apply mx-0;
        }

        .flight-journey-stops-number {
            width: 90px;
        }

        .flight-journey-flight-left,
        .flight-journey-flight-right {
            width: 90px;

            > div {
                &:nth-child(2) {
                    font-size: 38px;
                }
            }
        }

        .flight-journey-pill {
            font-size: 12px;
        }

        .flight-journey-summary {
            width: 30%;
        }

        .flight-journey-flight-plan {
            width: 70%;
        }
    }
}

@media #{$small} {
    .cf-multisegment-journey-grid {
        @apply gap-x-4 gap-y-2;
    }

    .cf-multisegment-journey-transfer-header {
        i {
            font-size: 16px;
            margin-right: 10px;
        }
    }

    .cf-multisegment-journey-transfer {
        padding: 10px;
    }

    .cf-multisegment-journey-info {
        span {
            font-size: 14px;

            > span {
                font-size: 14px;
            }
        }
    }

    .flight-journey-pill {
        padding: 11px 30px;
        font-size: 14px;
        margin-bottom: 16px;
    }

    .flight-journey-overview-info {
        @apply leading-none;

        span {
            font-size: 14px;
        }
    }

    .flight-journey-time {
        font-size: 12px;
    }

    .flight-journey-flight-left,
    .flight-journey-flight-right {
        width: 95px;

        > div {
            &:nth-child(2) {
                font-size: 35px;
            }

            &:nth-child(3) {
                font-size: 12px;
            }
        }
    }

    .flight-journey-flight-left {
        @apply mr-0;
    }

    .flight-journey-flight-right {
        @apply ml-0;
    }

    .flight-journey-stops-info {
        @apply m-0;
        top: 9px;

        i {
            font-size: 30px;
            margin-bottom: 9px;
        }
    }

    .flight-journey-stops-tooltip-opener {
        margin-bottom: 9px;
    }

    .flight-journey-stops-number {
        width: 75px;
        font-size: 12px;
    }

    .cf-decor-info {
        top: 52px;
    }
}

@media #{$xsmall} {
    ac-flight-journey-summary {
        @apply w-full flex-wrap;
    }

    .i2-itinerary-container {
        ac-flight-journey-summary {
            @apply w-full;
        }
    }

    .cf-multisegment-journey-grid {
        @apply mx-auto mb-8 grid gap-x-0 gap-y-2;
        width: calc(100% - 30px);
        grid-template-columns: 1fr;
        grid-template-areas:
            "endpoint-header-origin"
            "endpoint-origin"
            "transfer-header"
            "transfer"
            "endpoint-header-destination"
            "endpoint-destination";
    }

    .flight-journey-summary {
        @apply w-full;
    }

    .flight-journey-summary-info {
        @apply flex items-center justify-between;
    }

    .flight-journey-overview-info {
        font-size: 16px;
        margin: 0 15px 15px;

        span {
            font-size: 16px;
        }
    }

    .flight-journey-flight-plan {
        @apply w-full justify-between;
        padding: 0 15px;
        margin-bottom: 15px;
    }

    .flight-journey-pill {
        @apply leading-none;
        font-size: 14px;
        padding: 8px 10px;
        margin: 0 0 8px 15px;
    }

    .cf-multisegment-journey-info {
        height: 26px;
        font-size: 16px;

        span {
            font-size: 16px;
        }
    }

    .cf-multisegment-journey-transfer-header {
        @apply mt-4;
    }

    .cf-multisegment-journey-transfer {
        @apply mb-4 flex-row;

        > div {
            &:first-child {
                @apply w-1/3 items-end;
                margin-right: 18px;
            }

            &:last-child {
                @apply items-start;
            }
        }
    }

    .cf-multisegment-journey-stops {
        @apply mb-1;
    }

    .cf-multisegment-journey-transfer-time {
        text-align: left;

        span {
            @apply block;
            text-align: left;
        }
    }

    .cf-multisegment-journey-transfer-name {
        @apply mb-0 h-auto whitespace-normal;
        text-align: right;
    }

    .cf-multisegment-journey-decor {
        &:after {
            left: -10px;
            right: -10px;
        }
    }

    .flight-journey-flight-left,
    .flight-journey-flight-right {
        width: 80px;

        > div {
            &:nth-child(1) {
                font-size: 14px;
                line-height: 18px;
            }

            &:nth-child(2) {
                font-size: 30px;
                line-height: 36px;
            }

            &:nth-child(3) {
                font-size: 12px;
                line-height: 14px;
            }

            &:nth-child(4) {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    .flight-journey-flight-left {
        margin-right: 20px;

        .flight-journey-time {
            .one-more-day {
                @apply right-auto text-new-ui-red;
                left: 38px;
            }
        }
    }

    .flight-journey-flight-right {
        margin-left: 20px;
    }

    .flight-journey-stops-info {
        margin: 0 14px;
        top: 3px;

        i {
            font-size: 22px;
            margin-bottom: 7px;
        }
    }

    .flight-journey-stops-tooltip-opener {
        width: 14px;
        height: 14px;
        font-size: 8px;
        margin-bottom: 8px;

        &:after,
        &:before {
            width: 40px;
        }

        &:before {
            left: -50px;
        }

        &:after {
            right: -50px;
        }
    }

    .flight-journey-stops-number {
        @apply w-auto;
        font-size: 9px;
    }

    .cf-journey-segment-new-time {
        @apply whitespace-nowrap;
    }

    .cf-journey-segment-time {
        padding: 5px;
        font-size: 14px;

        i {
            font-size: 15px;
            margin-right: 5px;
        }
    }
}
