.dc-banner-container {
    @apply relative leading-none;
    padding: 18px 0 0 100px;

    a {
        @apply text-dc-dark-blue leading-none underline cursor-pointer;

        &:hover {
            @apply text-be-red leading-none underline;
        }

        &.selected {
            @apply text-be-red;
        }
    }

    .dc-banner-sidecard {
        @apply absolute left-0 top-0 flex justify-center items-center rounded-lg;
        width: 310px;
        height: 515px;
        background-image: url("/Images/DcStandalone/dc-sidecard.png");
        background-repeat: no-repeat;
        background-size: cover;

        &.banco-estado {
            background-image: url("/Images/DcStandalone/dc-sidecard-be.png");
        }
    }

    .dc-banner-content {
        @apply bg-white rounded-lg shadow-md text-dc-dark-blue;
        padding: 22px 0 22px 220px;
    }
}

.dc-banner-content-header {
    @apply text-white flex shadow-md;
    padding: 0 25px;
    min-height: 90px;

    i {
        @apply h-full my-auto;
        font-size: 50px;
        margin-right: 8px;
    }

    .dc-banner-content-header-text {
        @apply font-emphasis m-auto;
        text-align: center;
        line-height: 32px;
        font-size: 25px;
    }
}

.dc-banner-content-body {
    padding: 50px 20px 0 20px;

    .no-discount-url-container {
        text-align: center;
        font-size: 18px;

        &.get {
            margin-bottom: 20px;
            font-size: 20px;
        }
    }
}

.dc-prices-block {
    @apply mx-auto flex justify-center;
    margin-bottom: 35px;
}

.dc-prices-title {
    @apply whitespace-nowrap;
    text-align: end;
    font-size: 17px;
    margin-bottom: 4px;
}

.dc-prices-membership {
    @apply text-be-gray-15 font-body align-top w-1/2;
    padding: 0 20px;

    &.english {
        text-align: right;

        .dc-prices-title {
            @apply whitespace-normal;
            max-width: 300px;
        }

        .dc-prices-you-save {
            font-size: 17px;

            .dc-saved-price-amount {
                font-size: 17px;
            }
        }
    }

    &.portuguese {
        .dc-prices-you-save {
            font-size: 20px;

            .dc-saved-price-amount {
                font-size: 21px;
            }
        }
    }

    .dc-prices-total {
        text-align: end;
        margin-bottom: 5px;

        .dollar-sign {
            @apply font-black;
            font-size: 28px;
            padding-bottom: 3px;
        }

        .dc-prices-amount {
            @apply font-bold relative;
            font-size: 48px;
        }
    }

    &:not(.with-dc) {
        @apply border-l border-solid border-be-gray-15;
    }

    &.with-dc {
        @apply text-be-cyan;
        padding: 0 20px 0 0;
    }
}

.dc-prices-you-save {
    @apply bg-be-cyan-2 text-white rounded-lg whitespace-nowrap;
    text-align: center;
    padding: 5px 15px;
    font-size: 23px;

    .dc-saved-price-amount {
        @apply font-black;
        font-size: 25px;
    }
}

.select-membership-title {
    @apply relative;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.membership-type-title {
    @apply font-black relative;
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;

    &.pull-left {
        left: -21px;
    }

    i {
        @apply text-be-red relative;
        font-size: 38px;
        top: 9px;
        left: 8px;
    }
}

.membership-selector-container {
    @apply flex justify-around;
    margin-bottom: 20px;

    .get-membership-section {
        @apply w-1/2;
        text-align: center;

        &.disabled {
            @apply opacity-100 pointer-events-none;

            i,
            .membership-type-title,
            .get-membership-description {
                @apply text-be-gray-16;
            }

            .rounded-primary-btn {
                &.dc-yellow {
                    @apply bg-be-gray-16 border-be-gray-16;
                }
            }
        }

        .get-membership-description {
            @apply font-light;
            font-size: 17px;
            margin-bottom: 20px;
        }

        .rounded-primary-btn {
            @apply mx-auto;
            font-size: 21px;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
}

.already-member-section {
    @apply font-light;
    text-align: center;

    .already-member-url {
        @apply font-normal;
    }
}

.upgrade-membership-description {
    @apply leading-6;
    font-size: 20px;
    text-align: center;
    margin: 0 0 40px;
    max-width: 595px;
}

.dc-checkbox-container {
    margin-bottom: 30px;

    [type="checkbox"] + label[for] {
        @apply font-light;
        font-size: 17px;
        height: 20px;
        line-height: 20px;

        a {
            @apply underline text-be-cyan-2;
            font: inherit;

            &:hover {
                @apply no-underline text-be-blue;
            }
        }
    }
}

.upgrade-price-emphasis {
    font-family: "ClanOT-UltraItalic", sans-serif;
}

.dc-group-membership-description {
    @apply leading-6;
    font-size: 17px;
    margin-bottom: 30px;

    .attention-text {
        @apply font-bold italic;
    }
}

.dc-membership-btn-container {
    @apply flex justify-center;
    margin-bottom: 20px;

    .rounded-primary-btn {
        font-size: 23px;
        min-width: 250px;
    }
}

.extend-membership-list {
    margin-bottom: 40px;
    font-size: 20px;

    .extend-membership-list-line {
        @apply flex items-center;
        margin-bottom: 5px;

        i {
            font-size: 26px;
            margin-right: 7px;
        }
    }
}

.extend-membership-description {
    @apply italic font-semibold;
    margin-bottom: 30px;
    font-size: 17px;
}

.banner-container-loader {
    @apply h-56 w-56 mx-auto;

    .RTloader {
        .rt-loader-overlay {
            @apply bg-transparent;
        }
    }
}

.dc-tooltip-container {
    @apply absolute;
    top: 5px;
    right: 0;
}

.dc-tooltip-opener {
    @apply rounded-full bg-be-cyan text-white font-black inline-flex items-center justify-center absolute inset-0 cursor-pointer leading-none;
    width: 15px;
    height: 15px;
    font-size: 10px;
    padding-bottom: 1px;

    &:hover {
        ~ .dc-tooltip {
            @apply block;
        }
    }
}

.dc-tooltip {
    @apply absolute border border-solid border-be-cyan-2 z-10 p-2 bg-white shadow-medium;
    border-radius: 5px;
    bottom: 20px;
    left: 7px;
    transform: translateX(-50%);
    display: none;

    &:after {
        @apply border-b border-r border-t-0 border-l-0 border-solid border-be-cyan-2 absolute bg-white;
        content: "";
        z-index: -1;
        width: 30px;
        height: 30px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(15px) rotate(45deg);
    }

    span {
        @apply font-black font-body text-be-cyan-2 block;
        text-align: left;
        font-size: 15px;
    }

    ul {
        @apply py-1;

        li {
            @apply text-be-blue relative;
            text-align: left;
            font-size: 10px;
            line-height: 12px;
            padding: 0 15px;

            &:after {
                @apply border-solid border-t-0 border-l-0 border-r-2 border-b-2 border-be-cyan-2 absolute;
                content: "";
                width: 5px;
                height: 8px;
                left: 0;
                top: 50%;
                transform: translateY(-50%) translateY(-2px) rotate(45deg);
            }
        }
    }
}

// MEDIA QUERIES

@media #{$large} {
    .dc-banner-content-header {
        .dc-banner-content-header-text {
            font-size: 20px;
        }
    }

    .dc-banner-container {
        .dc-banner-sidecard {
            width: 240px;
            height: 405px;

            img {
                @apply relative;
                top: -10px;
                width: 230px;
            }
        }

        .dc-banner-content {
            padding-left: 150px;
        }
    }

    .dc-banner-content-body {
        padding: 30px 15px 0 15px;
    }

    .dc-prices-membership {
        .dc-prices-total {
            .dc-prices-amount {
                font-size: 40px;
            }
        }

        &.english {
            text-align: right;

            .dc-prices-title {
                @apply whitespace-normal;
                max-width: 290px;
            }

            .dc-prices-you-save {
                font-size: 14px;

                .dc-saved-price-amount {
                    font-size: 14px;
                }
            }
        }

        &.portuguese {
            .dc-prices-title {
                font-size: 17px;
            }

            .dc-prices-you-save {
                font-size: 17px;

                .dc-saved-price-amount {
                    font-size: 17px;
                }
            }
        }
    }

    .dc-prices-you-save {
        font-size: 20px;

        .dc-saved-price-amount {
            font-size: 21px;
        }
    }

    .membership-selector-container {
        .get-membership-section {
            .get-membership-description {
                font-size: 15px;
            }

            .rounded-primary-btn {
                @apply w-auto mx-auto;
            }
        }
    }

    .select-membership-title {
        @apply relative;
        font-size: 19px;
    }

    .membership-type-title {
        @apply mx-auto;
        max-width: 215px;
        font-size: 19px;

        &.pull-left {
            left: -18px;
        }

        i {
            font-size: 31px;
            top: 9px;
            left: 6px;
        }
    }
}

@media #{$medium} {
    .dc-checkbox-container {
        [type="checkbox"] {
            + label[for] {
                font-size: 14px;
            }
        }
    }

    .dc-banner-container {
        padding: 15px 0 0 45px;

        .dc-banner-sidecard {
            width: 170px;
            height: 280px;

            img {
                max-height: 45px;
            }
        }

        .dc-banner-content {
            padding: 11px 0 11px 120px;
        }
    }

    .dc-banner-content-header {
        padding: 0 12px;
        min-height: 45px;

        i {
            font-size: 25px;
            margin-right: 5px;
        }

        .dc-banner-content-header-text {
            line-height: 16px;
            font-size: 14px;
        }
    }

    .dc-banner-content-body {
        padding: 20px 12px 0 20px;
        text-align: center;

        .no-discount-url-container {
            font-size: 11px;

            &.get {
                margin-bottom: 12px;
                font-size: 12px;
            }
        }
    }

    .dc-prices-block {
        margin-bottom: 25px;
    }

    .dc-prices-title {
        font-size: 12px;
        margin-bottom: 3px;
    }

    .dc-prices-membership {
        padding: 0 20px;

        .dc-prices-total {
            margin-bottom: 3px;

            .dollar-sign {
                font-size: 16px;
                padding-bottom: 2px;
            }

            .dc-prices-amount {
                font-size: 28px;
            }
        }

        &.english {
            text-align: right;

            .dc-prices-title {
                @apply whitespace-normal;
            }

            .dc-prices-you-save {
                font-size: 12px;

                .dc-saved-price-amount {
                    font-size: 12px;
                }
            }
        }

        &.portuguese {
            .dc-prices-title {
                @apply whitespace-normal;
                font-size: 14px;
            }

            .dc-prices-you-save {
                font-size: 13px;

                .dc-saved-price-amount {
                    font-size: 15px;
                }
            }
        }
    }

    .dc-prices-you-save {
        padding: 3px 10px;
        font-size: 15px;

        .dc-saved-price-amount {
            font-size: 17px;
        }
    }

    .select-membership-title {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .membership-type-title {
        font-size: 15px;
        margin-bottom: 6px;

        &.pull-left {
            left: -16px;
        }

        i {
            font-size: 28px;
            top: 7px;
            left: 5px;
        }
    }

    .membership-selector-container {
        margin-bottom: 10px;

        .get-membership-section {
            .get-membership-description {
                font-size: 12px;
                margin-bottom: 10px;
            }

            .rounded-primary-btn {
                @apply mx-auto;
                font-size: 14px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }

    .already-member-section {
        font-size: 12px;
    }

    .upgrade-membership-description {
        @apply leading-5;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .dc-checkbox-container {
        .dc-checkbox-label {
            font-size: 13px;
        }
    }

    .dc-group-membership-description {
        @apply leading-5;
        font-size: 12px;
        margin-bottom: 12px;
    }

    .dc-membership-btn-container {
        margin-bottom: 15px;

        .rounded-primary-btn {
            @apply mx-auto;
            font-size: 14px;
            min-width: 145px;
        }
    }

    .extend-membership-list {
        @apply inline-block;
        margin-bottom: 17px;
        font-size: 13px;

        .extend-membership-list-line {
            text-align: left;
            margin-bottom: 5px;

            i {
                font-size: 16px;
                margin-right: 4px;
            }
        }
    }

    .extend-membership-description {
        font-size: 11px;
    }
}

@media #{$small} {
    .dc-checkbox-container {
        margin-bottom: 10px;

        [type="checkbox"] {
            + label[for] {
                font-size: 13px;
            }
        }
    }

    .dc-banner-container {
        padding: 15px 0 0 45px;

        .dc-banner-sidecard {
            width: 170px;
            height: 280px;

            img {
                max-height: 45px;
            }
        }

        .dc-banner-content {
            padding: 11px 0 11px 120px;
        }
    }

    .dc-banner-content-header {
        padding: 0 12px;
        min-height: 45px;

        i {
            font-size: 25px;
            margin-right: 5px;
        }

        .dc-banner-content-header-text {
            line-height: 16px;
            font-size: 14px;
        }
    }

    .dc-banner-content-body {
        padding: 20px 12px 0 20px;
        text-align: center;

        .no-discount-url-container {
            font-size: 11px;

            &.get {
                margin-bottom: 12px;
                font-size: 12px;
            }
        }
    }

    .dc-prices-block {
        margin-bottom: 25px;
    }

    .dc-prices-title {
        font-size: 12px;
        margin-bottom: 3px;
    }

    .dc-prices-membership {
        padding: 0 20px;

        .dc-prices-total {
            margin-bottom: 3px;

            .dollar-sign {
                font-size: 16px;
                padding-bottom: 2px;
            }

            .dc-prices-amount {
                font-size: 28px;
            }
        }

        &.with-dc {
            padding: 0 20px 0 0;
        }

        &.english {
            text-align: right;

            .dc-prices-title {
                @apply whitespace-normal;
                max-width: unset;
            }

            .dc-prices-you-save {
                font-size: 14px;

                .dc-saved-price-amount {
                    font-size: 14px;
                }
            }
        }

        &.portuguese {
            .dc-prices-title {
                @apply whitespace-normal;
                max-width: unset;
                font-size: 14px;
            }

            .dc-prices-you-save {
                font-size: 15px;

                .dc-saved-price-amount {
                    font-size: 17px;
                }
            }
        }
    }

    .dc-prices-you-save {
        padding: 3px 10px;
        font-size: 15px;

        .dc-saved-price-amount {
            font-size: 17px;
        }
    }

    .select-membership-title {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .membership-type-title {
        font-size: 15px;
        margin-bottom: 6px;

        &.pull-left {
            left: -14px;
        }

        i {
            font-size: 25px;
            top: 7px;
            left: 5px;
        }
    }

    .membership-selector-container {
        margin-bottom: 10px;

        .get-membership-section {
            .get-membership-description {
                font-size: 12px;
                margin-bottom: 10px;
            }

            .rounded-primary-btn {
                @apply mx-auto;
                font-size: 14px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }

    .already-member-section {
        font-size: 12px;
    }

    .upgrade-membership-description {
        @apply leading-5;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .dc-checkbox-container {
        .dc-checkbox-label {
            font-size: 13px;
        }
    }

    .dc-group-membership-description {
        @apply leading-5;
        font-size: 12px;
        margin-bottom: 12px;
    }

    .dc-membership-btn-container {
        margin-bottom: 15px;

        .rounded-primary-btn {
            font-size: 14px;
            min-width: 145px;
        }
    }

    .extend-membership-list {
        @apply inline-block;
        margin-bottom: 17px;
        font-size: 13px;

        .extend-membership-list-line {
            text-align: left;
            margin-bottom: 5px;

            i {
                font-size: 16px;
                margin-right: 4px;
            }
        }
    }

    .extend-membership-description {
        font-size: 11px;
        margin-bottom: 15px;
    }
}

@media #{$xsmall} {
    .dc-checkbox-container {
        margin-bottom: 15px;

        [type="checkbox"] {
            + label[for] {
                @apply leading-none;
                font-size: 12px;
                text-align: left;
            }
        }
    }

    .dc-banner-container {
        padding: 100px 0 0;

        .dc-banner-sidecard {
            @apply inset-x-0 w-full rounded-b-none bg-be-orange-2 flex flex-col justify-center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height: 104px;
            background-image: none;

            img {
                max-height: 45px;

                &:nth-child(2) {
                    margin-top: 5px;
                }
            }

            &.banco-estado {
                @apply bg-center bg-no-repeat bg-auto;
                background-image: url("/Images/DcStandalone/dc-be-logo.svg");
            }
        }

        .dc-banner-content {
            padding: 10px 0px 0px;
        }
    }

    .dc-banner-content-header {
        padding: 0 12px;
        min-height: 55px;

        i {
            font-size: 25px;
            margin-right: 5px;
        }

        .dc-banner-content-header-text {
            line-height: 16px;
            font-size: 14px;
            text-align: center;
        }
    }

    .dc-banner-content-body {
        padding: 25px 12px 0 20px;
        text-align: center;

        .no-discount-url-container {
            font-size: 11px;
            padding-bottom: 20px;

            &.get {
                margin-bottom: 12px;
                font-size: 12px;
            }
        }
    }

    .dc-prices-block {
        @apply block w-full;
        text-align: center;
        margin-bottom: 25px;
    }

    .dc-prices-title {
        text-align: center;
        font-size: 12px;
        margin-bottom: 3px;
    }

    .dc-prices-membership {
        @apply mx-auto;
        width: 200px;
        padding-top: 15px;

        .dc-prices-total {
            text-align: center;
            margin-bottom: 3px;

            .dollar-sign {
                font-size: 16px;
                padding-bottom: 2px;
            }

            .dc-prices-amount {
                font-size: 28px;
            }
        }

        &:not(.with-dc) {
            @apply border-0 border-t;
        }

        &.with-dc {
            @apply pt-0;
            padding-bottom: 5px;
        }

        &.english {
            text-align: right;
            width: 240px;

            .dc-prices-title {
                @apply whitespace-normal;
            }

            .dc-prices-you-save {
                font-size: 14px;

                .dc-saved-price-amount {
                    font-size: 16px;
                }
            }
        }

        &.portuguese {
            width: 240px;

            .dc-prices-title {
                @apply whitespace-normal;
                font-size: 12px;
            }

            .dc-prices-you-save {
                font-size: 14px;

                .dc-saved-price-amount {
                    font-size: 16px;
                }
            }
        }
    }

    .dc-prices-you-save {
        @apply rounded;
        text-align: center;
        padding: 3px 10px;
        font-size: 12px;

        .dc-saved-price-amount {
            font-size: 13px;
        }
    }

    .select-membership-title {
        @apply relative;
        font-size: 15px;
        margin-bottom: 20px;
    }

    .membership-type-title {
        font-size: 15px;
        margin-bottom: 6px;

        &.pull-left {
            left: -11px;
        }

        i {
            font-size: 25px;
            top: 7px;
            left: 5px;
        }
    }

    .membership-selector-container {
        @apply block;

        .get-membership-section {
            @apply w-auto;
            margin-bottom: 17px;

            .get-membership-description {
                font-size: 12px;
                margin-bottom: 10px;
            }

            .rounded-primary-btn {
                @apply mx-auto;
                width: 165px;
                font-size: 14px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }

    .already-member-section {
        font-size: 12px;
        padding-bottom: 20px;
    }

    .upgrade-membership-description {
        @apply leading-4;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .dc-checkbox-container {
        .dc-checkbox-label {
            font-size: 13px;
        }

        &.basic-checkbox-wrapper {
            label {
                display: block;
            }
        }
    }

    .dc-group-membership-description {
        @apply leading-4;
        text-align: left;
        font-size: 12px;
        margin-bottom: 12px;
    }

    .dc-membership-btn-container {
        margin-bottom: 15px;

        .rounded-primary-btn {
            font-size: 14px;
            min-width: 145px;
        }
    }

    .extend-membership-list {
        @apply inline-block;
        margin-bottom: 17px;
        font-size: 13px;

        .extend-membership-list-line {
            text-align: left;
            margin-bottom: 5px;

            i {
                font-size: 16px;
                margin-right: 10px;
            }
        }
    }

    .extend-membership-description {
        text-align: left;
        font-size: 11px;
    }

    .dc-view-more {
        text-align: right;

        span {
            &:first-child {
                @apply font-body text-be-cyan-2 underline inline-block mr-2;
                font-size: 10px;
            }
        }
    }

    .dc-view-more-arrow {
        @apply font-body text-be-cyan-2 common-transition inline-block relative;
        font-size: 16px;

        &.open {
            top: 4px;
            transform: rotate(90deg);
        }
    }

    .dc-benefits {
        @apply overflow-hidden common-transition;
        max-height: 0;

        &.open {
            @apply py-2;
            max-height: 600px;
        }
    }
}
