/*
 * Sidebar
 */

.sidebar-wrapper {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 320px;
    padding: 0;
    background: #fff;
    box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 210;

    &.cug-sidebar {
        width: 285px;
    }

    .sidebar-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        .sidebar-scroller {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
        }

        .total-final,
        .total-original-booking,
        .total-new-booking {
            position: relative;
            margin-top: 25px;
            background: $sidebar-primary-color;
            text-align: center; //padding-right: 50px;

            &.redemption-miles {
                background: $brand-secondary;
            }

            &.padded {
                padding-bottom: 10px;
            }

            p {
                line-height: 30px;
                color: #fff; //padding-left: 30px;

                &.breakdown-installments-info {
                    font-size: 11px;
                    font-weight: 400;
                    position: absolute;
                    top: 15px;
                    left: 18px;
                }
            }

            .total-original-booking {
                background: $brand-secondary;

                p {
                    line-height: 37px;
                }
            }

            .total-new-booking {
                margin-top: 25px;
                background: $brand-tertiary;

                p {
                    line-height: 37px;
                }
            }
        }
    }
}

.total-final-row {
    @apply relative flex justify-between;
}

.total-left {
    &.pad-left {
        text-align: left;
        padding-left: 25px;
    }
}

.total-right {
    &.pad-right {
        padding-right: 25px;
    }
}

.sidebar-content {
    .tab {
        font-size: 13px;
    }

    h3 {
        margin-top: 25px;
        font-size: $font-size-medium;
        line-height: 16px;
        color: $brand-secondary;
        font-weight: $semibold-font-weight;
    }

    p {
        @apply m-0 font-black leading-none text-brand-secondary;
        padding: 8px 0;
        font-size: $font-size-small;

        &.pull-up-top {
            padding-top: 0;
            font-size: 13px;
            font-weight: 600;
        }

        &.pull-up-bottom {
            padding-bottom: 0;
        }
    }

    .notification-icon {
        position: absolute;
        top: 7px;
        left: 10px;
        font-size: 34px;
        color: $brand-secondary;

        &.fas {
            font-size: 25px;
            top: 11px;
            color: $brand-tertiary;
        }
    }

    .sidebar-inner-wrapper {
        padding: 15px 25px 15px 25px;
        background: $gray-lightest;
    }

    .information-small-content {
        font-size: 10px;
        color: $information-small-content-color;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        text-align: left;
        line-height: 15px;
        text-align: center;

        &.argentin-modal-link {
            cursor: pointer;

            &:hover {
                color: $brand-primary;
            }
        }
    }

    .prices-wrapper {
        padding: 10px 25px 10px 25px;

        .price-bold {
            margin: 0;
        }

        &.fare-lock-breakdown {
            background: #cee6ed;

            .js-accordion label,
            .js-accordion .tab-content {
                background: #cee6ed;
            }
        }
    }

    .tab-content {
        font-size: 13px;
        line-height: 1;

        ul,
        li {
            line-height: 1.1;
        }

        &.last-elem {
            padding-bottom: 20px;
            color: $brand-secondary;

            span {
                color: $brand-secondary;
            }
        }
    }

    .bottom-border {
        width: 100%;
        margin-bottom: 15px;
        border-bottom: 2px $brand-secondary dotted;
        line-height: 26px;
    }

    .bottom-border-inner {
        width: 100%;
        border-bottom: 2px $brand-secondary dotted;
    }
}

.price-bold {
    font-weight: 900;
    font-size: 13px;

    i {
        margin-right: 5px;
    }
}

.in-total {
    font-weight: 900;
    font-size: 13px;
    margin: 14px 0 3px 0;

    .in-total-tooltip {
        font-weight: 900;
        display: inline-block;
        white-space: collapse balance;
    }

    span {
        font-weight: 900;
        font-size: 13px;
    }
}

.sidebar-opener {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: $brand-secondary;
    z-index: 99;
    color: white;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    .total-section {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 60px;
        padding: 0 10px;
        font-size: $font-size-medium;
        font-weight: $semibold-font-weight;

        &.right {
            justify-content: flex-end;
        }

        .js-icon {
            margin: 0 5px;
            font-size: 24px;
        }
    }
}

.airfare {
    margin: 8px 0;

    .emphasis {
        font-weight: 900;
        color: $brand-secondary;
    }

    .row {
        margin-top: 7px;
        margin-bottom: 7px;
    }
}

.total-row {
    margin-bottom: 14px;
    text-transform: uppercase;
}

.total-miles-row {
    margin-bottom: 14px;
}

.peru-compra-discount-row {
    margin: 8px 0 12px 0;
}

.fas {
    &.fa-angle-down {
        &.sidebar-details-open-icon {
            width: 12px;
            height: 12px;
            border: 1px solid $brand-secondary;
            border-radius: 50%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            min-width: 12px;
            max-width: 12px;
            margin-left: 3px;
        }
    }
}

#promoOpener {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.01;

    &:not(:checked) {
        ~ .promo-code-content {
            max-height: 0;
        }
    }

    &:checked {
        ~ .promo-code-header {
            .promo-open-icon {
                transform: translateY(-50%) rotate(-90deg);
            }
        }

        ~ .promo-code-content {
            max-height: 260px;
        }
    }
}

.promo-code-header {
    &.condensed {
        padding: 0 20px 0 50px;
        font-family: "ClanOT-Medium", sans-serif;
        font-size: 14px;
        line-height: 1.2;

        &.taller {
            font-family: "Lato", sans-serif;
            font-weight: 600;
            height: 100px;
        }
    }
}

.promo-code-header,
[type="checkbox"] + label[for].promo-code-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 59px;
    padding: 0;
    background: $sidebar-secondary-color;
    color: #fff;
    font-family: ClanOT-Medium;
    font-size: $font-size-medium;
    line-height: 1;

    span {
        text-transform: uppercase;
        display: block;
        font-size: 17px;
        margin-top: 5px;
        font-family: ClanOT-Black;
    }

    &:before {
        opacity: 0.01;
    }

    .promo-code-icon {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 48px;
        color: #fff;
    }

    .promo-code-accepted-icon {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 20px;
        color: #fff;
    }

    .promo-open-icon {
        position: absolute;
        display: block;
        top: 50%;
        right: 15px;
        transform: translateY(-50%) rotate(90deg);
        font-size: 54px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 50%;
        padding: 3px;
        font-size: $font-size-medium;
        transition: all 0.25s ease-in-out;
    }

    ~ .promo-code-content {
        overflow: hidden;
        transition: max-height $transition-medium-up ease-in-out;
        background: $sidebar-secondary-color;
        color: #fff;
        position: relative;

        .mdl-textfield {
            margin: 5px 20px 10px;
            padding: 5px 0;
            width: calc(100% - 40px);
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            top: 9px;
        }

        .mdl-textfield--floating-label {
            &.is-focused,
            &.is-dirty,
            &.has-placeholder {
                .mdl-textfield__label {
                    top: -3px;
                }
            }
        }
    }
}

.total-currency {
    position: relative;

    &.underlined {
        margin-right: 5px;
        padding-bottom: 3px;
        border-bottom: 1px solid #fff;
    }

    .currency-arrow {
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%) rotate(-270deg);
    }
}

.total-amount {
    font-weight: 900;
}

.sidebar-breadcrumbs {
    padding: 25px 25px 0;

    .step {
        margin: 10px 0 0;
        display: flex;
        align-items: center;
        position: relative;

        &.active {
            span {
                &:first-child {
                    background: $brand-tertiary;
                }

                &:last-child {
                    color: $brand-tertiary;
                }
            }
        }

        &.past {
            span:last-child {
                position: relative;

                &:after {
                    content: "";
                    width: 15px;
                    height: 10px;
                    border-left: 4px solid $gray;
                    border-bottom: 4px solid $gray;
                    transform: rotate(-45deg);
                    position: absolute;
                    right: -20px;
                    top: 3px;
                }
            }
        }

        span {
            font-family: "Lato", sans-serif;
            font-weight: 600;
            font-size: $font-size-medium;

            &:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                color: #fff;
                background: $gray;
                margin-right: 10px;
                font-size: $font-size-small;
            }

            &:last-child {
                color: $gray;
            }
        }
    }

    .fly {
        margin: 10px 0 0;
        display: flex;
        align-items: center;
        font-family: "Lato", sans-serif;
        font-size: $font-size-medium;

        i {
            font-size: 28px;
            color: $gray;
            margin-right: 7px;
        }

        span:last-child {
            color: $gray;
            font-weight: 600;
        }

        &.active {
            i {
                color: $brand-tertiary;
            }
            span {
                &:first-child {
                    color: $brand-tertiary;
                }

                &:last-child {
                    color: $brand-tertiary;
                }
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$small-down} {
    .new-mobile-total {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        height: 60px;
    }

    .total-currency {
        min-width: 50px;
        text-align: center;
    }

    .sidebar-content {
        .prices-wrapper {
            padding: 10px 15px;
        }
    }

    .promo-code-header .promo-code-icon,
    [type="checkbox"] {
        + label[for] {
            &.promo-code-header {
                .promo-code-icon {
                    left: 5px;
                    font-size: 40px;
                }
            }
        }
    }

    .sidebar-opener {
        position: absolute;
        top: 0;
        left: auto;
        right: -145px;
        width: 85px;
        height: 80px;
        background: $sidebar-small-down-bg;
        z-index: 99;
        color: white;
        cursor: pointer;
        box-shadow: $box-shadow-medium;
        border-top-right-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
        transition: right $transition-sidebar-small-down;

        &.under-redemption-review {
            top: 60px;
        }

        &.push-down {
            top: 55px;
        }

        &.destination {
            width: 50px;
            height: 61px;
            right: -115px;
            text-transform: uppercase;
            text-align: center;
        }

        &.slim {
            height: 55px;
        }

        &.small {
            right: -50px;
            transition: right $transition-sidebar-small-down-small;

            &:not(.destination) {
                right: -42px;
                top: 17px;
                width: 42px;
                height: 42px;
            }
        }

        .total-section {
            @apply absolute inset-0 flex flex-col items-center justify-center whitespace-nowrap;
            font-size: 11px;
            height: unset;

            i {
                @apply block text-white;
                font-size: 20px;
            }
        }
    }

    .sidebar-wrapper {
        width: 100%;
        top: 0;
        bottom: 0;
        height: auto;
        transition: margin $transition-sidebar-small-down-wrapper;
        z-index: 100000;
        background: transparent;
        overflow: visible;
        margin-left: -100%;
        box-shadow: none;
        // JET-6443 iOS scroll bug
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);

        &.cug-sidebar {
            width: 225px;
        }

        &.opened {
            margin-left: 0;

            .sidebar-container {
                box-shadow: $box-shadow-medium;
            }
        }

        .sidebar-container {
            left: 0;
            right: 65px;
            top: 60px;
            width: unset;
            background: #fff;
            overflow: visible;

            .sidebar-scroller {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                &.destination {
                    bottom: 0;
                }
            }
        }
    }

    .promo-code-header,
    [type="checkbox"] + label[for].promo-code-header {
        height: 81px;
        padding-left: 30px;
    }

    .mobile-total-closer {
        font-size: 30px;
        display: block;
        margin-bottom: 2px;
    }

    .mobile-total-closer-caption {
        display: block;
    }

    .mobile-sol-template {
        @apply bg-brand-primary text-white;
        padding: 10px 15px;
        line-height: 30px;
        font-size: 13px;

        span {
            @apply font-black;
        }

        .pull-left {
            @apply relative;
            left: -15px;
        }

        &.farelock {
            @apply bg-brand-secondary;
        }
    }
}

@media #{$xsmall} {
    .sidebar-wrapper {
        &.cug-sidebar {
            display: none;
        }
    }
}
