.payment-checkbox-terms-wrapper {
    &.payment {
        display: inline-block;
        min-height: 0;
        width: 240px;
        margin: 0 15px 0 0;
        padding: 5px 8px;
        border: 1px $brand-secondary solid;
        border-radius: 10px;
        background: #fff;

        &.terms {
            width: unset;
            max-width: 500px;

            &.payment-wider {
                width: 360px;
            }

            &.longer {
                width: unset;
                max-width: 730px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: $brand-tertiary;

        &:hover {
            color: $brand-primary;
        }
    }
}

// MEDIA QUERIES
@media #{$large} {
    .payment-checkbox-terms-wrapper {
        &.payment {
            &.terms {
                &.longer {
                    max-width: 630px;
                }

                &.payment-wider {
                    max-width: 350px;
                }
            }
        }
    }
}

@media #{$medium} {
    .payment-checkbox-terms-wrapper {
        &.payment {
            &.terms {
                &.longer {
                    max-width: 460px;
                    height: 80px;
                }

                &.payment-wider {
                    max-width: 210px;
                    height: 100px;
                }
            }
        }
    }
}

@media #{$small} {
    .payment-checkbox-terms-wrapper {
        &.payment {
            &.terms {
                &.longer {
                    max-width: 430px;
                    height: 80px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .payment-checkbox-terms-wrapper {
        &.payment {
            &.terms {
                label {
                    height: unset;
                }

                &.longer {
                    max-width: 99%;
                    height: auto;
                }
            }
        }
    }
}
