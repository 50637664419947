/*
 * Variables
 */

 /* ------------------------------------------------------------ */
/* Themes specific colors ------------------------------------- */
/* ------------------------------------------------------------- */
$brand-chk:             #6CB535;
$brand-primary:         #9C3434;
$brand-secondary:       #163a70;
$brand-tertiary:        #59C3D9;
$header-bg:             #def3f7;
$footer-bg:             #fff;
$tick-green:            #83b245;
$membership-accent-color: #ffa400;
$membership-link-color: #80bfd4;
$flight-switch-color:   #5ac1d7;
$flight-calendar-month-color: #c5cfd8;
$flight-calendar-day-color: #72869f;
$tick-green:            #83b245;
$secondary-btn-color:   #7287A0;
$sidebar-primary-color: #b92234;
$sidebar-secondary-color: #17386C;
$transaction-header-color: #00abc8;

$gray-lightest:         #f7f7f7;    // gray-100
$gray-lighter:          #E2E2E2;    // gray-200
$gray-light:            #ccc;       // gray-300
$gray-darkest:          #333;       // gray-700

$primary-btn-bg:        #b92234;
$primary-btn-border:    #B2292E;
$primary-btn-hover-bg:  lighten(#b92234, 10%);

$tertiary-btn-bg:       #1b365d;

$warning-bg-color:      #f0ad4e;

$error-color:           #b92234;

$alert-bg:              #e299a1;
$alert-color:           #163a6f;
$invalid-border-color:  #e299a1;
$invalid-bg:            #f9d4d8;

$modal-border-color:    #7287A0;
$modal-button-bg:       #7287A0;

$baggage-weight-color:  #aeb5ba;

$cvv-bg:                #cee6ed;

$breadcrumb-booking-step-bg: #BABABA;

$itinerary-circle-bg:   $brand-secondary;

$checkin-sidebar-color: #9a9999;
$checkin-destination-route-border: #1c355e;
$checkin-destination-active-breadcrumb-color: #00abc8;
$checkin-destination-subtitle-color: #b2292e;
$checkin-services-color: #1c355e;
$checkin-services-color2: #00abc8;

$checkbox-after-border-color: #07d;

$payment-secondary-btn-color: #6d829a;
$cug-secondary-btn-color: #6d829a;
$checkin-secondary-btn-color: #6d829a;
$review-secondary-btn-color: #7287a0;
$boarding-btn-bg: #7287a0;
$giftcard-btn-bg: #7287a0;

$cug-mobile-nav-bg: #b6c1ce;
$cug-mobile-active-nav-bg: #72859e;

$header-main-color: #A62733;
$header-secondary-color: #204071;
$footer-main-color: #903235;
$footer-secondary-color: #1d4071;
$footer-link-color: #70706f;
$footer-link-border-color: #E9B22A;

$form-textarea-hovered-color: #fafafa;

$profile-dc-user-icon-path-color: #868686;
$profile-route-unflown-color: #F7F6F6;
$profile-route-unflown-stroke-color: #1A3968;
$profile-route-flown-color: #5AC1D7;
$profile-dc-circle-path-color: #ececec;
$profile-trip-acc-table-th-bg: #72849d;

$information-small-content-color: #727272;
$sidebar-small-down-bg: #00AEC7;

$banco-estado-color: #EE801D;
$banco-estado-secondary-color: #464547;

/* ------------------------------------------------------------ */
/*  Dimensions variables --------------------------------------- */
/* ------------------------------------------------------------- */
$js-header-height:      55px;
$common-gap:            20px;
$small-gap:             10px;
$border-radius-lg:      10px;
$border-radius-sm:      5px;

$normal-accordion-height: 1000px;

/* ------------------------------------------------------------ */
/*  Font variables --------------------------------------- */
/* ------------------------------------------------------------- */

$font-size-xlarge:       		26px;
$font-size-large:        		22px;
$font-size-medium:       		16px;
$font-size-small:        		14px;
$font-size-xsmall:        		12px;

/* ------------------------------------------------------------ */
/*  Transition, animation variables -------------------------- */
/* ------------------------------------------------------------- */

$loader-line-animation: 2300ms;
$transition-medium-up: 0.25s;
$normal-accordion-delay: 400ms;
$cug-topup-transition: 400ms;
$cug-miniloader-animation: 1s;

$transition-sidebar-small-down: 1.5s;
$transition-sidebar-small-down-small: 0.25s;
$transition-sidebar-small-down-wrapper: 0.5s;
