.seatmap-info {
    nav {
        > input {
            display: none;
        }
    }

    .seatmap-journey-selector {
        @apply select-none;
        margin-top: 25px;
    }

    .seatmap-journey-selector-radio {
        &:checked ~ label {
            @apply bg-white text-brand-secondary cursor-default z-10;
            border-top: 1px solid $gray-light;
            border-left: 1px solid $gray-light;
            border-right: 1px solid $gray-light;
            height: 55px;

            &:hover {
                @apply bg-white text-brand-secondary cursor-default;
            }
        }

        + label {
            @apply whitespace-normal inline-block box-border relative m-0 w-full h-full;
            background: $gray-lightest;
            border-top: 1px solid transparent;
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 12px;

            &:hover {
                color: $gray-lightest;
                background: $gray-light;
            }

            &.no-click {
                @apply cursor-default pointer-events-none;
            }
        }

        + label:after,
        + label:before {
            @apply m-0 p-0;
            display: none;
        }
    }

    .be-2 {
        .seatmap-journey-selector-radio {
            &:checked ~ label {
                height: 68px;
            }
        }
    }

    .direction-switch-container {
        @apply relative block box-border list-none p-0 m-0 whitespace-nowrap;
        height: 54px;
    }

    .be-2 {
        .direction-switch-container {
            height: 67px;
        }
    }

    .direction-switch {
        @apply inline-block box-border absolute p-0 m-0 w-1/2;
        min-width: 170px;
        height: 54px;

        &:first-child {
            @apply top-0 left-0;
        }

        &:last-child {
            @apply top-0 right-0;
        }
    }

    .be-2 {
        .direction-switch {
            height: 67px;

            label {
                height: 67px;
            }
        }
    }

    .direction {
        @apply absolute inline-block font-black uppercase text-new-ui-red;
        top: 8px;
        left: 10px;
        width: 44px;
        font-size: 14px;
    }

    .be-2 {
        .direction {
            @apply text-be-blue font-body font-semibold;
            font-size: 14px;
            top: 12px;
            left: 18px;
        }
    }

    .date {
        @apply absolute text-new-ui-blue whitespace-nowrap;
        bottom: 8px;
        left: 10px;
        font-size: 14px;
    }

    .be-2 {
        .date {
            @apply text-be-blue font-body;
            font-size: 15px;
            top: 27px;
            left: 18px;
        }
    }

    .departure {
        @apply absolute inline-block text-brand-secondary whitespace-nowrap;
        top: 5px;
        right: 10px;
    }

    .arrival {
        @apply absolute block text-brand-secondary whitespace-nowrap;
        bottom: 5px;
        right: 10px;
    }

    .station {
        @apply font-black;
    }



    &.no-change {
        nav {
            .direction-switch-container {
                .direction-switch {
                    label {
                        @apply cursor-default;

                        &:hover {
                            background: $gray-lightest;
                            color: inherit;
                        }
                    }
                }
            }
        }

        .no-click {
            @apply pointer-events-none;
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .be-2 {
        .direction-switch {
            min-width: unset;
        }
    }
}
