$exit: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26 32" style="enable-background:new 0 0 26 32;" xml:space="preserve"><g><polygon points="6.8,31 0.9,31 10.8,16.4 0.9,1 6.8,1 16.7,16.4"/><polygon points="15.2,31 9.3,31 19.2,16.4 9.3,1 15.2,1 25.1,16.4"/></g></svg>');

.legend-row {
    @apply inline-block w-full border-b-2 border-dotted border-brand-secondary;
    height: 45px;

    &:last-child {
        @apply border-b-0;
    }

    .legend-item {
        @apply w-full flex items-center;
        height: 45px;
    }

    label {
        @apply text-brand-secondary font-semibold;
        padding-right: 20px;
        font-size: 14px;

        &:nth-child(3) {
            margin-left: 20px;
        }

        &:nth-child(5) {
            margin-left: 20px;
        }
    }

    .cf-seat {
        @apply cursor-default;
        height: 24px;
        width: 24px;

        &:before {
            -webkit-mask-size: 22px;
            mask-size: 22px;
        }
    }
}

.toilet {
    width: 24px;
    height: 24px;

    svg {
        width: 24px;
        height: 24px;
    }

    .st0 {
        fill: #ebebec;
        stroke: #163a70;
        stroke-width: 0.5;
        stroke-miterlimit: 10;
    }
    .st1 {
        fill: none;
        stroke: #163a70;
        stroke-width: 0.5;
        stroke-miterlimit: 10;
    }
}

.exit {
    @apply inline-block bg-brand-primary;
    width: 22px;
    height: 22px;
    -webkit-mask-image: $exit;
    -webkit-mask-size: auto 22px;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    mask-image: $exit;
    mask-size: auto 22px;
    mask-repeat: no-repeat;
    mask-position: center center;
}

/* MEDIA QUERIES */

@media #{$large} {
    .legend-row {
        height: 38px;

        label {
            padding-right: 10px;
            font-size: 12px;
        }

        .cf-seat {
            width: 20px;
            height: 20px;

            &:before {
                -webkit-mask-size: 20px;
                mask-size: 20px;
            }
        }
    }

    .toilet {
        width: 20px;
        height: 20px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .exit {
        width: 20px;
        height: 20px;
        -webkit-mask-size: auto 20px;
        mask-size: auto 20px;
    }
}
