/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "js-employee-discount";
    src: url("../fonts/js-employee-discount.eot?3kun2h");
    src: url("../fonts/js-employee-discount.eot?3kun2h#iefix") format("embedded-opentype"),
        url("../fonts/js-employee-discount.ttf?3kun2h") format("truetype"),
        url("../fonts/js-employee-discount.woff?3kun2h") format("woff"),
        url("../fonts/js-employee-discount.svg?3kun2h#js-employee-discount") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-employee-discount {
    font-family: 'js-employee-discount' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.js-ed-circle-giftcard:before {
    content: "\e900";
}
.js-ed-circle-pets:before {
    content: "\e901";
}
.js-ed-circle-baggage:before {
    content: "\e902";
}
.js-ed-circling-planes:before {
    content: "\e903";
}
.js-ed-two-passengers-in-cicle:before {
    content: "\e904";
}
