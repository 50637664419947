$font-color: $brand-secondary !default;
$height: auto !default;
$border-color: #dcdee2 !default;
$border-radius: 0 !default;
$white: #ffffff !default;

.ss-main {
    position: relative;
    display: inline-block;
    user-select: none;
    color: $font-color;
    width: 100%;

    .ss-single-selected {
        display: flex;
        cursor: pointer;
        width: 100%;
        height: $height;
        padding: 1rem 1rem;
        font-size: 1.2rem;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        background-color: $white;
        outline: 0;
        box-sizing: border-box;
        transition: background-color .3s;

        &.ss-disabled {
            background-color: $border-color;
            cursor: not-allowed;
        }

        &.ss-open-above {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }

        &.ss-open-below {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .placeholder {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1 1 100%;
            text-align: left;
            width: calc(100% - 30px);
            font-size: 1.2rem;
            line-height: 1.3rem;

            .code {
                @include font-size($font-size-small);
                display: inline-block;
                margin: 3px 0 0 10px;
            }

            * {
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }

            .ss-disabled {
                cursor: pointer;
                color: $border-color;
            }
        }

        .ss-arrow {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 0 1 auto;
            margin: -1px 4px 0 5px;

            span {
                border: solid $font-color;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transition: transform .2s, margin .2s;

                &.arrow-up {
                    transform: rotate(-135deg);
                    margin: 3px 0 0 0;
                }

                &.arrow-down {
                    transform: rotate(45deg);
                    margin: -3px 0 0 0;
                }
            }
        }
    }

    .ss-multi-selected {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        min-height: $height;
        width: 100%;
        padding: 0 0 0 3px;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        background-color: $white;
        outline: 0;
        box-sizing: border-box;
        transition: background-color .3s;

        &.ss-disabled {
            background-color: $border-color;
            cursor: not-allowed;

            .ss-values {
                .ss-disabled {
                    color: $font-color;
                }

                .ss-value {
                    .ss-value-delete {
                        cursor: not-allowed;
                    }
                }
            }
        }

        &.ss-open-above {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }

        &.ss-open-below {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .ss-values {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            flex: 1 1 100%;
            width: calc(100% - 30px);

            .ss-disabled {
                display: flex;
                padding: 4px 5px;
                margin: 2px 0px;
                line-height: 1em;
                align-items: center;
                width: 100%;
                color: $border-color;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            @keyframes scaleIn {
                0% {
                    transform: scale(0);
                    opacity: 0;
                }

                100% {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            @keyframes scaleOut {
                0% {
                    transform: scale(1);
                    opacity: 1;
                }

                100% {
                    transform: scale(0);
                    opacity: 0;
                }
            }

            .ss-value {
                display: flex;
                user-select: none;
                align-items: center;
                font-size: 12px;
                padding: 3px 5px;
                margin: 3px 5px 3px 0px;
                color: $white;
                background-color: $brand-secondary;
                border-radius: $border-radius;
                animation-name: scaleIn;
                animation-duration: .2s;
                animation-timing-function: ease-out;
                animation-fill-mode: both;

                &.ss-out {
                    animation-name: scaleOut;
                    animation-duration: .2s;
                    animation-timing-function: ease-out;
                }

                .ss-value-delete {
                    margin: 0 0 0 5px;
                    cursor: pointer;
                }
            }
        }

        .ss-add {
            display: flex;
            flex: 0 1 3px;
            margin: 9px 12px 0 5px;

            .ss-plus {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $font-color;
                position: relative;
                height: 10px;
                width: 2px;
                transition: transform .2s;

                &:after {
                    background: $font-color;
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 10px;
                    left: -4px;
                    top: 4px;
                }

                &.ss-cross {
                    transform: rotate(45deg);
                }
            }
        }
    }

    .ss-content {
        position: absolute;
        width: 100%;
        margin: -1px 0 0 0;
        box-sizing: border-box;
        border-left: 5px solid $brand-secondary;
        z-index: 1010;
        background-color: $white;
        transform-origin: center top;
        transition: transform .2s, opacity .2s;
        opacity: 0;
        transform: scaleY(0);

        &.ss-open {
            display: block;
            top: 0;
            opacity: 1;
            transform: scaleY(1);
            border-left: 5px solid $brand-secondary;
        }

        .ss-search {
            padding: 8px 10px 8px 10px;
            display: none !important;

            input {
                display: none !important;
                font-size: inherit;
                width: 100%;
                height: 30px;
                padding: 5px 10px;
                border: 1px solid $border-color;
                border-radius: $border-radius;
                background-color: $white;
                outline: 0;
                box-sizing: border-box;

                &::placeholder {
                    color: $brand-secondary;
                }
            }
        }

        .ss-list {
            max-height: 200px;
            overflow-x: hidden;
            overflow-y: auto;
            text-align: left;

            .ss-optgroup {
                .ss-optgroup-label {
                    padding: 6px 10px 6px 10px;
                    font-weight: bold;
                }

                .ss-option {
                    padding: 10px 10px 10px 25px;
                }
            }

            .ss-option {
                display: flex;
                justify-content: space-between;
                padding: 10px 15px;
                cursor: pointer;
                user-select: none;
                font-size: 20px;

                .code {
                    @include font-size($font-size-small);
                }

                // border-bottom: 1px solid $brand-secondary;
                * {
                    display: inline-block;
                    vertical-align: middle;
                }

                &:hover,
                &.ss-highlighted {
                    color: $white;
                    background-color: $brand-secondary;
                }

                &.ss-disabled {
                    cursor: default;
                    color: $border-color;
                    background-color: $white;
                }

                &.ss-hide {
                    display: none;
                }
            }
        }
    }
}

.form-group {
    select.error[required] {
        &+.ss-main {
            .ss-single-selected {
                border: 1px solid $brand-error;
                color: $brand-error;
            }
        }
    }
}

.form-group-with-loader {
    position: relative;

    .loader-container {
        position: absolute;
        top: -22px;
        left: auto;
        right: 82px;
        width: auto;
        height: auto;
        background: transparent;
        padding: 0;

        &.group-loader {
            top: -28px;
        }

        * {
            transform: scale(0.3);
        }

        .loader-line {
            top: 0;
            left: 0;
            transform-origin: 49px 49px;
        }

        .loader-line:nth-child(1) {
            @include animation-delay(-57.5ms);
        }

        .loader-line:nth-child(2) {
            @include animation-delay(-172.5ms);
        }

        .loader-line:nth-child(3) {
            @include animation-delay(-287.5ms);
        }

        .loader-line:nth-child(1) .loader-line-wrap {
            border-color: $loader-color;
            height: 88px;
            width: 88px;
            top: 7px;
        }

        .loader-line:nth-child(2) .loader-line-wrap {
            border-color: $loader-color;
            height: 60px;
            width: 60px;
            top: 20px;
        }

        .loader-line:nth-child(3) .loader-line-wrap {
            border-color: $loader-color;
            height: 33px;
            width: 33px;
            top: 34px;
        }
    }
}

/* Small Loader*/

$sm-loader-color: #fff;

.small-loader-container {
    display: block;
    border-radius: 3px;
    font-size: 20px;
    padding: 10px 0;
    position: relative;
    text-align: center;
    transition: .3s color, .3s border, .3s transform, .3s opacity;
}

.small-loader {
    border-radius: 50%;
    box-shadow: 0 1em 0 -.2em $sm-loader-color;
    position: relative;
    animation: loader-anim 0.8s ease-in-out alternate infinite;
    animation-delay: 0.32s;
    top: -1em;
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;

    &:after,
    &:before {
        content: '';
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        box-shadow: inherit;
        animation: inherit;
    }

    &:before {
        left: -1em;
        animation-delay: 0.48s;
    }

    &:after {
        right: -1em;
        animation-delay: 0.16s;
    }
}

@keyframes loader-anim {
    0% {
        box-shadow: 0 2em 0 -.2em $sm-loader-color;
    }

    100% {
        box-shadow: 0 1em 0 -.2em $sm-loader-color;
    }
}

/* SlimSelect override for larger screens */
@media #{$medium-up} {
    .form-group:not(.keep-slim-select) {
        .last-in-line {
            .ss-main {
                .ss-content {
                    .ss-list {
                        right: 0;
                        left: auto;
                    }
                }
            }
        }

        .ss-main {
            .ss-content {
                .ss-search {
                    padding: 0;

                    input {
                        display: none !important;
                    }
                }

                &.ss-open {
                    z-index: 100000;
                    border-left: none;
                }

                .ss-list {
                    max-height: unset;
                    display: flex;
                    margin-top: 56px;
                    padding-bottom: 1rem;
                    position: absolute;
                    z-index: 1000;
                    background: rgba(255, 255, 255, 1);
                    white-space: nowrap;

                    .ss-optgroup {
                        .ss-optgroup-label {
                            padding: 1rem 1rem 0;
                            color: $brand-primary;
                            font-size: 1rem;
                            text-transform: uppercase;
                            height: 30px;
                        }

                        .ss-option {
                            margin: 0.2rem 0.3rem;
                            padding: 0.55rem 0.7rem;
                            font-size: 1rem !important;

                            &.ss-disabled {
                                color: $brand-secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}