.checkin-select-pax-checkbox-wrapper {
    @apply border border-solid border-brand-secondary text-brand-secondary;

    min-height: 66px;
    margin: 10px 0 20px;
    padding-left: 16px;

    .checkin-select-pax-label {
        &::before {
            @apply border-2 border-solid border-new-ui-cyan;

            width: 34px;
            height: 34px;
            border-radius: 10px;
        }

        &::after {
            left: 3px;
            width: 28px;
            height: 28px;
        }
    }
}
.checkin-select-pax-text {
    @apply inline-block font-bold text-brand-secondary;
    font-size: 16px;
    padding: 4px 0 6px;
    margin-left: 20px;
}

@media #{$medium} {
    .checkin-select-pax-checkbox-wrapper {
        .checkin-select-pax-label {
            &::before {
                @apply border-2;

                width: 30px;
                height: 30px;
            }

            &::after {
                @apply left-0;
            }
        }
    }
    .checkin-select-pax-text {
        font-size: 13px;
    }
}
