header {
    &.b2-oversized-header {
        @apply bg-be-light-gray block;
        margin: 50px 0;
        border-radius: 13px;

        .b2-per-pax-view-switch {
            &.b2-view-switch {
                // DEVNOTE The following cannot be written with @apply, because of the other borders
                border-top: 1px solid #e6e6e6;
                border-radius: 0;
            }
        }

        .b2-per-pax-reset {
            // DEVNOTE The following cannot be written with @apply, because of the other borders
            border-top: 1px solid #e6e6e6;
        }
    }
}

.b2-oversized-title {
    @apply text-be-blue font-emphasis flex items-center;
    font-size: 16px;

    i {
        @apply text-be-cyan-2;
        font-size: 80px;
        margin-right: 15px;
    }
}

.b2-oversized-opener {
    @apply text-be-blue flex items-center common-transition;

    i {
        @apply text-be-blue cursor-pointer;
        font-size: 22px;
        margin-left: 15px;

        &.rotated {
            transform: rotate(90deg);
        }
    }
}

.b2-oversized-top {
    @apply flex items-center justify-between w-full;
    padding: 10px 20px 10px 40px;

    &.per-pax {
        @apply border-t border-l border-r border-solid border-be-blue;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
    }
}

.b2-oversize-open-top {
    @apply text-bg-gray-3 border-t border-solid border-bg-gray-1 font-news;
    grid-area: opentop;
    padding: 0 40px;
    font-size: 13px;
    line-height: 16px;

    &.bordered {
        // DEVNOTE The following cannot be written with @apply, because of the other borders
        border-left: 1px solid #1c355e;
        border-right: 1px solid #1c355e;
        padding-bottom: 30px;
    }
}

.b2-per-pax-view-switch-oversized {
    margin-top: 30px;
}

.b2-oversize-info {
    max-width: 406px;

    &.push-down {
        margin-top: 30px;
    }
}

.b2-oversized-top-amount {
    @apply font-body text-be-blue;
    font-size: 16px;

    span {
        @apply font-heavy;
        font-size: 20px;
    }
}

.b2-add-another-oversized {
    @apply font-body font-semibold text-be-blue self-end;
    margin: 15px 0 0 0;
    font-size: 15px;
    line-height: 18px;

    .b2-amount {
        @apply font-heavy;
        font-size: 20px;
    }
}

.b2-oversized-quantity {
    @apply flex items-center;
    margin-left: 70px;
}

/* MEDIA QUERIES */

@media #{$medium} {
    header {
        &.b2-oversized-header {
            margin: 39px 0;
            border-radius: 10px;
        }
    }

    .b2-oversized-title {
        font-size: 12px;

        i {
            font-size: 60px;
            margin-right: 11px;
        }
    }

    .b2-oversized-opener {
        i {
            font-size: 19px;
            margin-left: 12px;
        }
    }

    .b2-oversized-top {
        padding: 8px 16px 8px 30px;

        &.per-pax {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    .b2-oversize-open-top {
        padding: 0 30px;
        font-size: 10px;
        line-height: 12px;
    }

    .b2-per-pax-view-switch-oversized {
        margin-top: 23px;
    }

    .b2-oversize-info {
        max-width: 310px;

        &.push-down {
            margin-top: 23px;
        }
    }

    .b2-oversized-top-amount {
        font-size: 12px;

        span {
            @apply whitespace-nowrap;
            font-size: 16px;
        }
    }

    .b2-add-another-oversized {
        margin: 11px 0 0 0;
        font-size: 11px;
        line-height: 15px;

        .b2-amount {
            font-size: 16px;
        }
    }

    .b2-oversized-quantity {
        margin-left: 52px;
    }
}

@media #{$small} {
    header {
        &.b2-oversized-header {
            margin: 30px 0;
            border-radius: 10px;
        }
    }

    .b2-oversized-title {
        font-size: 11px;

        i {
            font-size: 48px;
            margin-right: 7px;
        }
    }

    .b2-oversized-opener {
        i {
            font-size: 13px;
            margin-left: 7px;
        }
    }

    .b2-oversized-top {
        padding: 8px 12px 8px 22px;

        &.per-pax {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    .b2-oversize-open-top {
        padding: 0 22px;
        font-size: 10px;
        line-height: 12px;
    }

    .b2-per-pax-view-switch-oversized {
        margin-top: 25px;
    }

    .b2-oversize-info {
        max-width: 275px;

        &.push-down {
            margin-top: 20px;
        }
    }

    .b2-oversized-top-amount {
        font-size: 10px;

        span {
            font-size: 13px;
        }
    }

    .b2-add-another-oversized {
        margin: 10px 0 0 0;
        font-size: 10px;
        line-height: 12px;

        .b2-amount {
            font-size: 13px;
        }
    }

    .b2-oversized-quantity {
        margin-left: 25px;
    }
}

@media #{$xsmall} {
    header {
        &.b2-oversized-header {
            margin: 30px 0;
            border-radius: 10px;
        }
    }

    .b2-oversized-title {
        font-size: 12px;

        i {
            @apply absolute vertical-center;
            left: 5px;
            font-size: 39px;
            margin-right: 5px;
        }
    }

    .b2-oversized-opener {
        @apply self-end;

        i {
            font-size: 15px;
            margin-left: 7px;
        }
    }

    .b2-oversized-top {
        @apply flex-col items-start relative;
        padding: 10px 5px 10px 50px;

        &.per-pax {
            @apply border-0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    .b2-oversize-open-top {
        @apply border-b border-solid border-bg-gray-1;
        padding: 0 15px 5px;
        font-size: 10px;
        line-height: 12px;

        &.bordered {
            // DEVNOTE The following cannot be written with @apply, because of the other borders
            border-left: none;
            border-right: none;
            @apply pb-4;
        }
    }

    .b2-oversize-info {
        max-width: 275px;

        &.push-down {
            margin-top: 20px;
        }
    }

    .b2-oversized-top-amount {
        font-size: 11px;

        span {
            font-size: 13px;
        }
    }

    .b2-add-another-oversized {
        margin: 10px 0 0 0;
        font-size: 10px;
        line-height: 12px;

        .b2-amount {
            font-size: 12px;
        }
    }

    .b2-oversized-quantity {
        @apply block ml-0;

        .ac-per-booking-quantity-buttons {
            @apply flex items-center;
            margin-left: 70px;

            .b2-per-booking-quantity-container {
                @apply static left-0 bottom-0;
            }
        }
    }
}
