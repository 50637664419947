.inner-package-box {
    position: relative;
    height: 280px;
    margin-bottom: $small-gap;
    padding: 15px;
    border: 1px solid $brand-secondary;
    border-radius: $border-radius-lg;
    background: white; //margin: 12px;

    .jetsmart-number-selector {
        margin-top: 15px;
    }

    &.invalid {
        border-color: $invalid-border-color;
        background: $invalid-bg;
    }

    &.sold-out {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: $border-radius-lg;
            background: rgba(255, 255, 255, 0.8);
            z-index: 1000;
        }
    }

    .sold-out-warning {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 30px;
        border-radius: $border-radius-lg;
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $brand-tertiary;
        font-family: "ClanOT-Medium", sans-serif;
        font-size: 16px;
        text-align: center;
        line-height: 1.6;
    }
}

.outer-package-box {
    position: relative;
    margin-bottom: $margin-xlarge;
    padding: 15px;
    border: 1px solid $brand-secondary;
    border-radius: $border-radius-lg;
    background: white; //margin: 12px;

    .jetsmart-number-selector {
        margin-top: 5px;
    }
}

.checked-bag-dimensions {
    font-size: 9px;
    color: $brand-secondary;
    font-family: "ClanOT-News", sans-serif;
    display: block;
    padding: 5px 0 76px;
    text-align: center;
}

.bag-title {
    display: block;
    margin: 110px 0 15px 0;
    color: $brand-primary;
    font-weight: $semibold-font-weight;
    font-size: $font-size-small;
    text-align: center;
    position: relative;
    cursor: default;

    span {
        font-weight: $semibold-font-weight;
    }

    &.banco-estado {
        color: $banco-estado-color;

        &:hover {
            .banco-estado-tooltip {
                display: flex;
            }
        }
    }

    .original-price-hint {
        position: absolute;
        top: 14px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: 900;
        text-decoration: line-through;
        color: $brand-secondary;
    }

    .banco-estado-tooltip {
        position: absolute;
        top: -58px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 50px;
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid $banco-estado-color;
        border-radius: 5px;
        background: #fff;
        z-index: 1000;

        span {
            &:nth-child(1) {
                color: #000;
                font-family: "Gotham-Book";
                font-size: 11px;
            }

            &:nth-child(2) {
                color: $banco-estado-color;
                font-family: "Gotham-Book";
                font-size: 11px;
            }
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            width: 10px;
            height: 10px;
            transform: rotate(45deg) translateX(-50%);
            border-bottom: 1px solid $banco-estado-color;
            border-right: 1px solid $banco-estado-color;
            background: #fff;
        }
    }
}

.package-step {
    .package-title {
        margin-bottom: 15px;
        padding-top: 10px;
        line-height: 21px;
        font-size: 18px;
        font-weight: $semibold-font-weight;
        color: $brand-secondary;
    }

    .inner-deep-box {
        h4 {
            margin: 5px 0 0px 0;
            text-align: center;
            font-weight: $semibold-font-weight;
            font-size: $font-size-small;
            text-transform: uppercase;
            color: $brand-secondary;
            line-height: 15px;
        }
    }

    .filled-column {
        box-sizing: border-box;
        padding: 0;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        max-width: calc(50% - 1rem);
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
        background: #f7f7f7;
    }
}

.booking-wrapper {
    &.checkin-info-emphasis,
    &.change-route-info-emphasis {
        min-height: unset;
        background: #58c3d9;
        color: #fff;
        margin-top: 0;

        header {
            .title,
            .main-title,
            .subtitle {
                color: #fff;
            }
        }
    }

    &.change-route-info-emphasis {
        padding: 25px 25px 8px;
        border-radius: 0;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 320px;
            margin-right: 45px;
            max-width: 1155px;
            user-select: none;
        }

        .main-title {
            font-size: $font-size-medium;
            font-weight: 600;
            line-height: 1.45;
        }
    }
}

.baggages-total {
    margin: 17px 0 15px;
    max-width: 240px;
    border: 1px solid $brand-secondary;
    border-radius: $border-radius-lg;
    text-align: center;
    line-height: 40px;
    font-size: $font-size-xsmall;
    font-weight: 900;
    color: $brand-secondary;
}

.services-icon {
    margin-top: 12px;
    margin-bottom: $small-gap;
    text-align: center;

    .services-price {
        color: $brand-primary;
        font-weight: bold;
    }
}

.jetsmart-number-selector {
    position: relative;
    margin-top: 25px; //max-width: 100px;
    border: 2px solid $brand-secondary;
    border-radius: $border-radius-lg;
    text-align: center;
    font-size: $font-size-xsmall;
    font-weight: $semibold-font-weight;
    line-height: 25px;
    user-select: none;

    .plus-btn,
    .minus-btn {
        position: absolute;
        width: 18px;
        height: 18px;
        line-height: 18px;
        cursor: pointer;
    }

    .plus-btn {
        right: 0;
        margin: 3px 3px 0 4px;
        border-radius: 99px;
        background: $brand-primary;
        color: white;
    }

    .minus-btn {
        left: 0;
        margin: 3px 0 0 4px;
        border-radius: 99px;
        background: $gray-light;
        color: $brand-secondary;
    }

    .btn-disabled {
        pointer-events: none;
    }
}

.bike-icon {
    color: $brand-tertiary;
    font-size: 50px;
}

.select-dependent-text {
    span {
        &:first-child {
            display: inline;
        }

        &:nth-child(2) {
            display: none;
        }
    }

    &.selected {
        span {
            &:first-child {
                display: none;
            }

            &:nth-child(2) {
                display: inline;
            }
        }
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.bag-icon-wrapper {
    position: relative;
    cursor: pointer;

    img {
        width: 100px;
    }

    i:not(.icon-spaced) {
        font-size: 82px;
    }

    &:hover {
        .js-icon {
            color: $brand-secondary;
        }
    }

    &.selected {
        cursor: default;

        .js-icon {
            color: $brand-tertiary;
        }
    }
}

.material-icons {
    &.small-bag-help,
    &.big-bag-help {
        color: $brand-secondary;
        position: absolute;
        bottom: 51px;
        z-index: 50;
        font-size: $font-size-medium;
        cursor: pointer;
    }

    &.small-bag-help {
        left: 68px;
    }

    &.big-bag-help {
        left: 75px;
    }
}

.bag1-weight-custom {
    position: absolute;
    bottom: 0;
    z-index: 99;
    margin-bottom: 5px;
    margin-left: 5px;

    .js-bag1-weight {
        font-size: 145px;
        font-weight: $regular-font-weight;
        color: $baggage-weight-color;
    }
}

.bag1-image-custom {
    position: absolute;
    bottom: 0;
    z-index: 99;
    margin-bottom: 5px;
    margin-left: 5px;

    .js-bag1-image {
        font-size: 145px;
        font-weight: $regular-font-weight;
        color: $baggage-weight-color;
    }
}

.bag2-image-custom {
    position: absolute;
    bottom: 0;
    z-index: 99;
    margin-bottom: 5px;
    margin-left: 5px;

    .js-bag2-image {
        font-size: 145px;
        font-weight: $regular-font-weight;
        color: $baggage-weight-color;
    }
}

.bag3-image-custom {
    z-index: 99;
    margin-bottom: $common-gap;
    margin-top: $small-gap;
    margin-left: 50%;
    transform: translateX(-50%);
    overflow: hidden;

    .js-bag3-image {
        font-weight: $regular-font-weight;
        color: $baggage-weight-color;
        position: relative;
        left: -17px;
    }
}

.bag4-image-custom {
    position: absolute;
    bottom: 0;
    z-index: 99;
    margin-bottom: 5px;
    margin-left: 5px;

    .js-bag4-image {
        font-size: 145px;
        font-weight: $regular-font-weight;
        color: $baggage-weight-color;
    }
}

.weight-value {
    color: $gray-lightest;
    font-weight: $regular-font-weight;
    position: absolute;
    top: 30px;
    left: 1px;
    font-size: 10px;
    text-align: center;
    font-family: "Lato", sans-serif;
}

.weight2-value {
    color: $gray-lightest;
    font-weight: $regular-font-weight;
    position: absolute;
    top: 32px;
    left: 1px;
    font-size: 10px;
    text-align: center;
    font-family: "Lato", sans-serif;
}

.img-move-down {
    position: relative;
    top: 6px;
}

.baggage-left {
    position: absolute;
    left: 5px;
    top: 40px;
}

.baggage-right {
    position: absolute;
    right: 5px;
    top: 40px;
}

.baggage-left-center {
    position: absolute;
    left: 25%;
    top: 40px;
    transform: translateX(-50%);
}

.baggage-right-center {
    position: relative;
    margin: 0 auto;
    display: block;
    left: auto;
    transform: none;
    top: 5px;

    ~ .bag-title {
        margin: 12px 0 15px 0;
    }
}

#baggagesForm {
    .booking-wrapper {
        &.spoilage-fee-warning {
            color: $brand-primary;
            line-height: 1.5;

            span {
                font-weight: 600;
            }

            a {
                color: $brand-secondary;
            }
        }
    }
}

.baggage-primary-button-container {
    @apply flex justify-end;
}

/* MEDIA QUERIES */
@media #{$large-down} {
    .bag-icon-wrapper {
        width: 140px;

        img {
            width: 140px;
        }

        i:not(.icon-spaced) {
            font-size: 126px;
        }

        .small-bag-help,
        .big-bag-help {
            bottom: 71px;
        }

        .small-bag-help {
            left: 90px;
        }

        .big-bag-help {
            left: 103px;
        }

        .weight-value {
            top: 50px;
            left: 7px;
            font-size: $font-size-xsmall;
        }

        .weight2-value {
            top: 53px;
            left: 10px;
            font-size: $font-size-xsmall;
        }

        .bag2-image-custom {
            margin-bottom: 3px;
        }

        .bag3-image-custom {
            margin-bottom: 18px;
            margin-top: 6px;
        }

        .bag4-image-custom {
            margin-bottom: 1px;
        }
    }

    .inner-package-box {
        height: 320px;
    }

    .bag-title {
        margin-top: 147px;
    }

    .baggage-left {
        position: relative;
        margin: 0 auto;
        display: block;
        left: auto;
        transform: none;
        top: 5px;

        ~ .bag-title {
            margin: 12px 0 15px 0;
        }
    }

    .baggage-right {
        position: relative;
        margin: 0 auto;
        display: block;
        left: auto;
        transform: none;
        top: 5px;

        ~ .bag-title {
            margin: 12px 0 15px 0;
        }
    }

    .baggage-right-center {
        ~ .bag-title {
            margin: 12px 0 15px 0;
        }
    }

    .bag3-image-custom {
        .js-bag3-image {
            left: -25px;
        }
    }

    .checked-bag-dimensions {
        padding-bottom: 114px;
    }
}

@media #{$xsmall} {
    .bag1-image-custom {
        .js-bag1-image {
            &::before {
                font-size: 100px;
            }
        }
    }

    .bag1-image-custom {
        position: absolute;
        bottom: 0;
        z-index: 99;
        margin-bottom: 5px;
        margin-left: 5px;

        .js-bag1-image {
            &::before {
                font-size: 145px;
                font-weight: $regular-font-weight;
            }
        }
    }

    .inner-package-box {
        margin-bottom: $small-gap;
    }

    .bag1-image-custom {
        bottom: 0;
        margin-bottom: 0px;
        margin-left: -5px;
    }
}

// FIX ME: New Breakpoint in PICO
// PICO xxsmall fast breakpoint overwrite solution by:adam  320px-480px
@media screen and (min-width: 320px) and (max-width: 480px) {
    .xxs {
        .col-xs-1-2 {
            flex-basis: 100%;
            max-width: 100%;

            .bag-icon-wrapper {
                display: block;
                margin-right: auto;
                margin-left: auto;

                & .baggage-left {
                    left: 50%;
                    transform: translateX(-50%);
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        .baggage-right {
            right: auto;
            transform: none;
            top: 5px;
        }

        .baggage-left-center {
            left: 50%;
        }

        .baggage-right-center {
            right: auto;
            top: 5px;
        }
    }

    .inner-package-box {
        height: 565px;
    }
}
