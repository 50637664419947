/*
 * forms, input, textarea
 */

label,
select,
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
    cursor: pointer;
}

input::-ms-clear {
    display: none;
}

.js-input {
    height: 53px;
    padding-top: $common-gap;
    padding-left: 14px;
    border: 1px solid $brand-secondary;
    border-radius: $border-radius-sm;
    line-height: 53px;
    font-size: 20px;
    color: $brand-secondary;
    background: white;
}

.input-inner-title {
    display: inline-block;
    position: relative;
    color: $brand-secondary;
    font-family: "Lato", sans-serif;
    font-size: $font-size-small;
    font-weight: 900;
}

.birthdate-container,
.expiration-date-container {
    display: flex;
    justify-content: space-between;
    padding-top: 41px;
    position: relative;

    &.cug2b {
        padding-top: 25px;

        .input-inner-title {
            top: 3px;
        }
    }

    .dob-selector-container {
        margin-right: $common-gap;
        width: 100%;
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        select {
            height: 32px;
            padding-left: 15px;
        }

        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 9px;
            right: 9px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }

    .input-inner-title {
        position: absolute;
        top: $common-gap;
        left: 0;
    }
}

.expiry-date-container {
    display: flex;
    justify-content: space-between;
    padding-top: 26px;
    position: relative;

    .dob-selector-container {
        margin-right: $common-gap;
        width: 100%;
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        select {
            height: 32px;
            padding-left: 15px;
        }
    }

    .input-inner-title {
        position: absolute;
        top: 5px;
        left: 0;
    }
}

.sticky-invalid {
    border-color: $invalid-border-color;
    background: $invalid-bg;

    option {
        @apply bg-white;
    }
}

select {
    &.mdl-textfield__input {
        &.invalid {
            border-color: $invalid-border-color;
            background: $invalid-bg;
        }
    }
}

.invalid {
    ~ .ss-main {
        .ss-single-selected {
            border-color: $invalid-border-color;
            background: $invalid-bg;
        }
    }
}

.js-input {
    &.invalid {
        border-color: $invalid-border-color;
        background: $invalid-bg;
    }
}

.pw-help {
    position: absolute;
    top: 5px;
    right: 5px;
}

/* MEDIA QUERIES */

@media #{$medium-down} {
    .birthdate-container,
    .expiration-date-container {
        margin-bottom: $common-gap;

        .input-inner-title {
            top: 0;
        }
    }

    .expiry-date-container {
        margin-bottom: $common-gap;
    }
}

@media #{$small-down} {
    .birthdate-container,
    .expiration-date-container {
        &.cug2b {
            @apply mb-0;
            padding-top: 20px;
            margin-bottom: 0;

            .dob-selector-container {
                margin-right: 5px;

                &:after {
                    top: 4px;
                }

                select {
                    padding-top: 4px;
                    padding-left: 5px;
                    font-size: 12px;
                    height: 22px;
                }

                &:last-child {
                    @apply mr-0;
                }
            }

            .input-inner-title {
                @apply top-0;
                font-size: 10px;
            }
        }
    }
}
