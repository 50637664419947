.profile-bancoe-widget {
    @apply relative;
    grid-area: bancoe;
    height: 172px;
}

.bancoe-widget-container {
    @apply absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 172px;
    transition: all 0.5s ease-in-out;

    &.open {
        .bancoe-widget-content {
            max-height: 1000px;
            z-index: 1000000;
        }
    }
}

.bancoe-widget-content {
    @apply relative bg-white;
    max-height: 172px;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
}

.bewc-header {
    @apply bg-be-gray-8 flex items-center justify-center font-emphasis text-white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-size: 20px;
    height: 42px;
}

.bewc-container {
    @apply relative overflow-hidden border-b border-solid border-be-gray-9;
    height: 65px;
    transition: all 0.5s ease-in-out;

    &.open {
        height: 177px;

        .bewc-opener {
            height: 42px;

            i {
                transform: rotate(-90deg);
            }
        }
    }

    &:last-child {
        @apply border-b-0;
    }
}

.bewc-opener {
    @apply flex items-center justify-between text-be-blue cursor-pointer;
    height: 65px;
    font-size: 17px;
    padding: 0 25px;
    transition: all 0.5s ease-in-out;

    i {
        @apply text-be-blue;
        font-size: 22px;
        transform: rotate(90deg);
        margin-left: 20px;
        transition: all 0.5s ease-in-out;
    }
}

.bewc-label {
    @apply font-body text-be-blue;
    font-size: 10px;
    margin-right: 15px;
}

.bewc-amount {
    @apply text-be-blue;
    font-family: "ClanOT-Medium", sans-serif;
    font-size: 18px;
}

.bewc-table-header {
    @apply bg-be-gray-8 text-white flex w-full;
    height: 30px;

    span {
        @apply font-body font-semibold flex items-center justify-center;
        font-size: 13px;
        width: 33%;
    }
}

.bewc-scroller,
.bewc-scroller-2 {
    @apply absolute;
    top: 73px;
    bottom: 0;
    left: 0;
    right: 0;

    li {
        @apply bg-be-light-gray flex items-center w-full;
        padding: 0 20px;
        height: 32px;

        &:nth-child(even) {
            background: #ddd;
        }

        i {
            @apply text-be-blue;
            font-size: 23px;
            margin-right: 15px;
        }

        span {
            @apply flex items-center font-body text-be-blue justify-center leading-none;
            width: 33%;
            font-size: 14px;
            border-right: 1px solid #ccc;

            &:first-child {
                @apply justify-start;
            }

            &:nth-child(2) {
                font-size: 16px;
            }

            &:last-child {
                @apply border-r-0;
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    .profile-bancoe-widget {
        height: 115px;
    }

    .bancoe-widget-container {
        height: 115px;
    }

    .bancoe-widget-content {
        max-height: 115px;
    }

    .bewc-header {
        height: 29px;
        font-size: 12px;
    }

    .bewc-container {
        height: 43px;
    }

    .bewc-opener {
        height: 43px;
        font-size: 12px;
        padding: 0 15px;

        i {
            font-size: 13px;
        }
    }

    .bewc-label {
        margin-right: 5px;
    }

    .bewc-amount {
        @apply relative;
        font-size: 12px;
        top: 1px;
    }

    .bewc-table-header {
        height: 17px;

        span {
            font-size: 10px;
        }
    }

    .bewc-scroller,
    .bewc-scroller-2 {
        @apply absolute;
        top: 59px;
        bottom: 0;
        left: 0;
        right: 0;

        li {
            padding: 0 15px;
            height: 20px;

            i {
                font-size: 13px;
                margin-right: 10px;
            }

            span {
                font-size: 10px;

                &:nth-child(2) {
                    font-size: 10px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .profile-bancoe-widget {
        height: 92px;
    }

    .bancoe-widget-container {
        height: 92px;
    }

    .bancoe-widget-content {
        max-height: 92px;
    }

    .bewc-header {
        height: 30px;
        font-size: 13px;
    }

    .bewc-container {
        height: 31px;
    }

    .bewc-opener {
        height: 31px;
        font-size: 11px;
        padding: 0 10px;

        i {
            font-size: 13px;
        }
    }

    .bewc-label {
        margin-right: 12px;
    }

    .bewc-amount {
        @apply relative;
        font-size: 11px;
        top: 1px;
    }

    .bewc-table-header {
        height: 15px;

        span {
            font-size: 10px;
        }
    }

    .bewc-scroller,
    .bewc-scroller-2 {
        @apply absolute;
        top: 57px;
        bottom: 0;
        left: 0;
        right: 0;

        li {
            padding: 0 10px;
            height: 20px;

            i {
                font-size: 12px;
                margin-right: 7px;
            }

            span {
                font-size: 10px;

                &:nth-child(2) {
                    font-size: 10px;
                }
            }
        }
    }
}
