.peru-compras-login-content {
    @apply fixed inset-0;
    background: #f7f6f6 url("/Images/Cug22/register-bg.png") no-repeat top center;
    background-size: cover;
    z-index: -1;
}

#mainContentWrapper {
    &.login-page {
        .booking-wrapper {
            &.peru-compra {
                @apply p-0;
                margin: 120px 25px;

                .peru-compras-login-banner {
                    @apply overflow-hidden relative bg-no-repeat bg-center w-1/2;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    background-image: url("/Images/PeruCompra/peru-compra-login-banner.png");
                    background-size: cover;
                }

                .peru-compra-form {
                    @apply w-1/2;
                    padding: 30px 40px;

                    h1 {
                        @apply text-be-blue font-heavy mb-8;
                        font-size: 28px;
                        line-height: 33px;
                    }

                    div {
                        &:not(.form-error-message) {
                            > a {
                                @apply text-cug-light-gray underline inline-block mt-2;
                                font-size: 18px;
                                line-height: 22px;

                                &:hover {
                                    @apply text-cug-red no-underline;
                                }
                            }

                            .mdl-textfield {
                                padding-top: 20px;
                                padding-bottom: 0;
                            }
                        }
                    }

                    .login-button-container,
                    .contact-button-container {
                        @apply flex-row items-end;

                        .rounded-primary-btn,
                        .rounded-secondary-btn {
                            @apply font-bold;
                            margin-top: 10px;
                            font-size: 18px;
                            width: 54%;
                            max-width: 170px;
                        }
                    }

                    .login-button-container,
                    .contact-button-container,
                    .contact-text-container {
                        @apply flex justify-end mt-2;
                    }

                    .contact-text-container {
                        @apply text-be-blue font-bold mt-8;
                        text-align: right;
                        font-size: 24px;
                        line-height: 25px;
                        margin-top: 45px;
                    }

                    .contact-button-container {
                        margin-top: 13px;
                    }

                    .mdl-radio {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .booking-wrapper {
        .peru-compras-login-banner {
            border-radius: 8px;
        }
    }
}

@media #{$small} {
    .booking-wrapper {
        .peru-compras-login-banner {
            background-image: url("/Images/PeruCompra/peru-compra-tablet-login-banner.png");
            background-size: cover;
        }

        .peru-compra-form {
            padding: 20px 20px;

            .login-button-container {
                @apply flex-row;
            }

            h1 {
                font-size: 22px;
                line-height: 20px;
            }

            a {
                font-size: 13px;
            }
        }
    }
}

@media #{$xsmall} {
    .peru-compras-login-content {
        background: #f2f2f2;
    }

    #mainContentWrapper.login-page .booking-wrapper {
        margin-left: 10px;
        margin-right: 10px;

        .row {
            @apply flex-col;

            .peru-compras-login-banner {
                @apply w-auto;
                background-image: url("/Images/PeruCompra/peru-compra-mobile-login-banner.png");
                border-radius: 10px 10px 0 0;
                height: 211px;
                margin-left: 8px;
                margin-right: 8px;
            }

            .peru-compra-form {
                @apply w-full;
                padding: 20px 20px;

                .login-button-container {
                    .rounded-primary-btn {
                        padding-right: 28px;
                    }
                }

                .contact-text-container,
                a {
                    font-size: 18px;
                }

                h1 {
                    font-size: 28px;
                    line-height: 20px;
                }
            }
        }
    }
}
