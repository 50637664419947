.multileg-icon {
    @apply relative bg-be-cyan-2 rounded-full cursor-pointer text-white font-semibold flex items-center justify-center font-body no-underline;
    width: 15px;
    min-width: 15px;
    height: 15px;
    font-size: 10px;
    padding-bottom: 2px;

    &:hover {
        @apply bg-brand-secondary;

        .multileg-link {
            @apply text-brand-secondary;
        }
    }
}

.multileg-link {
    @apply absolute italic font-body underline font-semibold whitespace-nowrap cursor-pointer;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #00abc8;
    line-height: 15px;
    text-align: center;

    &.itinerary {
        @apply relative top-0 left-0 transform-none;
        font-size: inherit;

        &:hover {
            @apply text-brand-secondary;
        }
    }
}

.multileg-modal {
    &.modal {
        .modal-content {
            @apply p-0 bg-white;
            border-radius: 20px;
            min-height: 150px;
            max-width: 95%;

            .modal-header {
                @apply relative text-white flex items-center justify-center font-body italic font-semibold;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                background: #00aac3;
                font-size: 30px;
                height: 70px;

                .modal-header-closing {
                    @apply absolute bg-black text-white flex items-center justify-center rounded-full border-none p-0 font-black;
                    top: -14px;
                    right: -14px;
                    width: 34px;
                    height: 34px;
                    font-size: 25px;
                    letter-spacing: normal;

                    &:hover {
                        @apply bg-brand-secondary;
                    }
                }
            }

            .modal-body {
                padding: 35px 30px 35px 60px;
            }
        }
    }
}

.multileg-place {
    @apply flex items-center leading-none;
    color: #1b365d;
    margin-bottom: 25px;

    &:last-child {
        @apply mb-0;
    }

    .multileg-time {
        @apply relative font-body font-semibold whitespace-nowrap;
        font-size: 19px;
        margin-right: 15px;
    }

    .multileg-city {
        @apply font-body font-semibold whitespace-nowrap;
        font-size: 19px;
        margin-right: 5px;
    }

    .multileg-iata {
        @apply font-body font-semibold whitespace-nowrap;
        font-size: 14px;
        margin-right: 5px;
    }

    .multileg-wait {
        @apply flex items-center italic font-body;
        color: #b2292e;
        font-size: 10px;

        span {
            margin-right: 8px;

            &:first-child {
                @apply whitespace-nowrap;
            }
        }

        i {
            @apply font-semibold;
            font-size: 12px;
            margin-right: 2px;
        }
    }

    .multileg-place-icon {
        @apply absolute rounded-full text-white flex items-center justify-center leading-none vertical-center;
        left: -25px;
        width: 15px;
        height: 15px;
        background-color: #00abc8;
        font-size: 10px;
    }
}

.multileg-route {
    @apply flex items-center;
    margin-bottom: 25px;

    i {
        transform: rotate(90deg);
        font-size: 25px;
        font-weight: 500;
        color: #1b365d;
        margin: 0 15px 0 30px;
    }

    .multileg-details {
        span {
            @apply block leading-none whitespace-nowrap;
            margin-bottom: 2px;
        }

        .multileg-flight {
            @apply font-body font-black;
            font-size: 14px;
            color: #1b365d;
        }

        .multileg-duration {
            @apply font-semibold font-body;
            font-size: 11px;
            color: #00abc8;
        }

        .multileg-carrier {
            @apply font-body mb-0;
            font-weight: 400;
            font-size: 12px;
            color: #1b365d;
        }
    }
}

.multileg-break {
    flex-basis: 100%;
}

/* MEDIA QUERIES */

@media #{$xsmall} {
    .multileg-modal {
        &.modal {
            .modal-content {
                .modal-body {
                    padding: 25px;
                }
            }
        }
    }

    .multileg-place {
        @apply flex-wrap;

        .multileg-place-icon {
            left: -20px;
        }

        .multileg-wait {
            @apply items-center;
            padding-left: 64px;
            margin-top: 3px;

            span {
                &:first-child {
                    display: none;
                }
            }
        }
    }

    .multileg-link {
        top: 18px;
        line-height: 12px;
        font-size: 11px;
    }
}
