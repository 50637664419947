.etd-description-container {
    @apply leading-6;
    text-align: center;

    .etd-journey{
        @apply m-0;
    }

    p {
        @apply leading-5 font-body font-bold;
        margin: 10px 0 0 0;
        font-size: 16px;
    }

    span {
        @apply leading-6;
    }

    a {
        @apply text-brand-primary inline leading-4;
    }
}

.etd-flights{
    @apply flex flex-col leading-5;
    font-size: 15px;
}


.etd-modal-btn-container {
    @apply flex justify-center;
    margin-top: 20px;

    .rounded-secondary-btn {
        margin: 0 15px 0 0;
    }

    button{
        font-size: 14px;
        width: 179px;
    }
}

/* MEDIA QUERIES */
@media #{$xsmall} {

    .etd-description-container {

        p {
            font-size: 15px;
        }
    }

    .etd-flights{
        font-size: 14px;
    }

    .etd-modal-btn-container {
        @apply flex items-center flex-col-reverse;

        .rounded-secondary-btn {
            @apply mr-0;
            margin: 10px 0 4px 0;
        }
    }
}
