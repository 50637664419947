@import "./giftcard-colors";

.giftcard-email-tooltip {
    display: none;
    position: absolute;
    top: 30%;
    background: $giftcard-cyan2;
    padding: 12px;
    color: #fff;
    width: 240px;
    left: 285px;
    transform: translateY(-50%) translateY(10px);
    border-radius: 10px;
    font-family: "Lato", sans-serif;
    font-size: 11px;
    font-weight: 600;
    box-shadow: $box-shadow-medium;
    z-index: 10;

    &:after {
        width: 20px;
        height: 20px;
        transform: translateY(-50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: -10px;
        content: "";
        background: $giftcard-cyan2;
        z-index: -1;
    }
}

@keyframes hideTooltip {
    to {
        visibility: hidden;
    }
}

.show-email-tooltip {
    position: relative;

    &:hover {
        .giftcard-email-tooltip {
            display: block;
            animation: hideTooltip 0s 6s forwards;
        }
    }
}

.booking-wrapper {
    header {
        .main-title {
            &.gift-card-logo {
                position: relative;
                font-family: "Lato", sans-serif;
                font-weight: 900;
                font-size: 26px;
                color: $brand-secondary;

                span {
                    text-transform: uppercase;

                    &:first-child {
                        font-family: "ClanOT-Bold", sans-serif;
                        font-size: 32px;
                        color: #a03334;
                    }
                    &:nth-child(2) {
                        font-family: "ClanOT-Book", sans-serif;
                        font-size: 30px;
                        color: $brand-secondary;
                    }
                }

                img {
                    width: 51px;
                    position: absolute;
                    top: 27px;
                    right: 2px;
                }
            }
        }
    }
}

.giftcard-switches-container {
    position: absolute;
    top: 14px;
    right: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gift-card-opener-info {
    position: relative;
    background: $giftcard-cyan3;
    color: #fff;
    padding: 15px 50px 15px 10px;
    border-radius: 10px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-style: italic;
    font-size: 20px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background: $giftcard-blue2;
    }

    &:focus,
    &:active {
        background: $giftcard-cyan3;
    }
}

#mainContentPayment {
    .inner-deep-box {
        .new-giftcard-wrapper {
            .gift-card-opener-info {
                .open-icon {
                    i {
                        color: #fff;

                        &.open {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }
}

.new-giftcard-opener {
    display: block;
    position: relative;
    padding: 14px;
    margin: 0;
    height: auto;
    line-height: 1;

    &:before {
        position: absolute;
        display: none;
    }

    .title-icon {
        font-size: 45px;
    }

    header {
        margin: 0;
        max-width: 90%;
        min-height: 82px;
    }
}

.gift-card-opener-toggle {
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    text-decoration: underline;
    color: $giftcard-blue2;
    cursor: pointer;

    &.small {
        font-size: 12px;
    }

    &:hover {
        color: $giftcard-red;
        text-decoration: none;
    }
}

.new-giftcard-content {
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: all 0.25s ease-in-out;

    &.open {
        max-height: 500px;
    }
}

.new-giftcard-btn {
    background: $giftcard-blue2;
    color: #fff;
    padding: 8px 35px 8px 10px;
    position: relative;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    height: 32px;
    margin: 5px 25px 5px 0;
    cursor: pointer;

    i {
        position: absolute;
        color: #fff;
        font-size: 23px;
        top: 4px;
        right: 5px;
    }

    &:hover {
        background: $giftcard-red;
    }
}

.new-giftcard-form-input {
    position: relative;
}

.new-giftcard-input-tooltip {
    display: none;
    background: $brand-secondary;
    color: #fff;
    font-family: "Lato", sans-serif;
    font-size: 13px;
    padding: 10px 15px;
    border-radius: 10px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 220px;
    line-height: 1.4;
    box-shadow: $box-shadow-medium;

    &:after {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 30px;
        height: 30px;
        background: $brand-secondary;
        z-index: -1;
    }

    span {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: $giftcard-cyan;
        }
    }
}

.new-giftcard-input {
    position: relative;
    padding: 11px 15px;
    border-radius: 5px;
}

.new-giftcard-form-info-container {
    position: absolute;
    top: 25px;
    right: 83px;
    text-align: center;
}

.new-giftcard-form-info {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    color: $giftcard-gray2;
    margin-bottom: 5px;
}

.js-input,
.new-giftcard-input {
    &:focus {
        ~ .new-giftcard-input-tooltip {
            display: block;
        }
    }
}

input[type="checkbox"] {
    &:checked {
        ~ .giftcard-content {
            // DEVNOTE Hack to overcome the different overflow setting css bug
            animation-name: overflowSwitch;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }
    }
}

.giftcard-payment-list {
    .gpl-row {
        width: 100%;
        padding: 10px 0;
        border-top: 1px solid $giftcard-darkgray2;

        &:last-child {
            border-bottom: none;
        }

        div {
            display: inline-block;
            position: relative;
            width: 16%;
            padding-left: 30px;
            vertical-align: middle;
            font-family: "Lato", sans-serif;
            font-size: 18px;
            color: $giftcard-blue2;
            font-weight: 300;
            font-style: italic;
            white-space: nowrap;

            &:nth-child(3) {
                width: 14%;
            }

            &:nth-child(4) {
                width: 20%;
            }

            &:nth-child(5) {
                width: 14%;
            }

            &.amount {
                padding-left: 30px;
                width: 22%;
                font-weight: 900;
                font-style: normal;
            }

            &.with-tick {
                &:after {
                    content: "\2714";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 5px;
                    font-size: 20px;
                    color: #107538;
                    font-weight: 600;
                }
            }

            &.utilize {
                text-align: right;
                width: 6%;
                padding-left: 0;
                cursor: pointer;

                &:hover {
                    span {
                        background: $giftcard-cyan;
                    }
                }

                span {
                    position: relative;
                    background: $giftcard-blue2;
                    color: #fff;
                    height: 32px;
                    border-radius: 16px;
                    padding: 0 35px 0 15px;
                    display: inline-flex;
                    align-items: center;
                    font-family: "Lato", sans-serif;
                    font-size: 16px;
                    font-weight: 900;
                    font-style: normal;

                    i {
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        font-size: 23px;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.new-giftcard-wrapper {
    display: block;
    width: 100%;
    position: relative;
    border: 1px solid $brand-secondary;
    border-radius: $border-radius-sm;
    color: $brand-secondary;
    background: white;

    .open-icon {
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);

        i {
            display: inline-block;
            font-size: 28px;
            color: $brand-secondary;
            transition: all 400ms;
        }
    }

    .giftcard-content {
        display: block;
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: all 400ms;
    }

    .giftcard-content-inside {
        margin: 0;
    }
}

.giftcard-last-payment-message {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    color: $giftcard-cyan3;
    margin-bottom: 20px;
    position: relative;
    top: -10px;
    line-height: 1.4;
}

/* MEDIA QUERIES */

@media #{$large} {
    .giftcard-payment-list {
        .gpl-row {
            div {
                font-size: 16px;
                white-space: normal;

                &.amount {
                    padding-left: 20px;
                }

                &:nth-child(4) {
                    width: 15%;
                }

                &:nth-child(5) {
                    width: 15%;
                }
            }
        }
    }

    .giftcard-switches-container {
        right: 20px;
    }

    .gift-card-opener-info {
        font-size: 16px;
        padding: 15px 40px 15px 10px;
    }

    .new-giftcard-wrapper {
        .open-icon {
            right: 10px;

            i {
                font-size: 24px;
            }
        }
    }

    .new-giftcard-opener {
        header {
            min-height: 72px;
        }

        .title-icon {
            font-size: 40px;
        }
    }

    .booking-wrapper {
        header {
            .main-title {
                &.gift-card-logo {
                    font-size: 24px;

                    span {
                        &:first-child {
                            font-size: 28px;
                        }

                        &:nth-child(2) {
                            font-size: 26px;
                        }
                    }

                    img {
                        width: 48px;
                        top: 25px;
                        right: 0;
                    }
                }
            }
        }
    }
}

@media #{$medium} {
    .giftcard-payment-list {
        .gpl-row {
            div {
                font-size: 14px;
                width: 20%;
                padding-left: 35px;
                white-space: normal;

                &.with-tick {
                    &:after {
                        left: 15px;
                    }
                }

                &.amount {
                    width: 100%;
                    padding-left: 20px;
                    font-size: 16px;
                }

                &:nth-child(3) {
                    width: 20%;
                }

                &:nth-child(4) {
                    width: 20%;
                }

                &:nth-child(5) {
                    width: 20%;
                }

                &.utilize {
                    span {
                        height: 26px;
                        font-size: 14px;
                        padding: 0 25px 0 10px;

                        i {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .giftcard-switches-container {
        right: 10px;
    }

    .gift-card-opener-info {
        font-size: 13px;
        margin-bottom: 5px;
    }

    .gift-card-opener-toggle {
        font-size: 14px;
    }

    #mainContentPayment {
        .inner-deep-box {
            .new-giftcard-wrapper {
                .gift-card-opener-info {
                    padding: 10px 35px 10px 10px;

                    .open-icon {
                        right: 5px;

                        i {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .new-giftcard-opener {
        header {
            min-height: 54px;
        }

        .title-icon {
            font-size: 35px;
        }
    }

    .booking-wrapper {
        header {
            .main-title {
                &.gift-card-logo {
                    font-size: 18px;

                    img {
                        width: 33px;
                        top: 19px;
                        right: -1px;
                    }

                    span {
                        &:first-child {
                            font-size: 21px;
                        }

                        &:nth-child(2) {
                            font-size: 19px;
                        }
                    }
                }
            }
        }
    }
}

@media #{$small} {
    .giftcard-email-tooltip {
        left: 50%;
        transform: translateX(-50%);
        top: unset;
        bottom: 45px;
        width: 205px;
        font-size: 10px;
        line-height: 15px;
        padding: 10px;

        &:after {
            top: calc(100% - 10px);
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        }
    }

    .giftcard-switches-container {
        top: 13px;
        right: 35px;
    }

    .gift-card-opener-info {
        margin-bottom: 5px;
        font-size: 15px;
        max-width: 220px;
        padding: 5px 35px 5px 18px;

        &:hover {
            background: $giftcard-cyan3;
        }
    }

    .gift-card-opener-toggle {
        font-size: 12px;
        line-height: 14px;
    }

    .new-giftcard-wrapper {
        .open-icon {
            right: 8px;

            i {
                font-size: 25px;
            }
        }
    }

    .new-voucher-form-box {
        &.inner-deep-box {
            min-height: unset;
            padding: 10px;
        }
    }

    .new-giftcard-opener {
        padding: 0 14px;

        .title-icon {
            font-size: 36px;
        }

        .voucher-tooltip {
            font-size: 12px;
            margin-top: 0;
        }

        header {
            .title {
                margin-left: 5px;
            }
        }
    }

    .booking-wrapper {
        header {
            .main-title {
                &.gift-card-logo {
                    margin: 0;
                    font-size: 18px;

                    span {
                        &:first-child {
                            font-size: 22px;
                        }

                        &:nth-child(2) {
                            font-size: 21px;
                        }
                    }

                    img {
                        width: 36px;
                        top: 19px;
                        right: 0px;
                    }
                }
            }

            .title {
                &.reduced {
                    margin-left: 5px;
                }
            }
        }
    }

    .giftcard-payment-list {
        .gpl-row {
            position: relative;

            div {
                font-size: 14px;
                width: 20%;
                padding-left: 20px;
                white-space: normal;

                &.with-tick {
                    &.first {
                        padding-left: 35px;
                        &:after {
                            left: 15px;
                        }
                    }

                    &:after {
                        left: 0;
                    }
                }

                &.amount {
                    width: 100%;
                    padding-left: 20px;
                    font-size: 16px;
                    margin-bottom: 6px;
                }

                &:nth-child(3) {
                    width: 20%;
                }

                &:nth-child(4) {
                    width: 20%;
                }

                &:nth-child(5) {
                    width: 20%;
                }

                &.utilize {
                    position: static;

                    span {
                        height: 26px;
                        font-size: 14px;
                        padding: 0 25px 0 10px;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);

                        i {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .js-gift-card {
        &.js-icon {
            &.title-icon {
                &.reduced {
                    font-size: 36px;
                }
            }
        }
    }

    .voucher-tooltip {
        &.reduced {
            font-size: 12px;
            margin-top: 0;
        }
    }

    .new-giftcard-form-info-container {
        top: 12px;
        right: 25px;
    }

    .new-giftcard-form-info {
        font-size: 14px;
    }
}

@media #{$xsmall} {
    .giftcard-email-tooltip {
        left: 50%;
        transform: translateX(-50%);
        top: unset;
        bottom: 45px;
        width: 205px;
        font-size: 10px;
        line-height: 12px;
        padding: 10px;

        &:after {
            top: calc(100% - 10px);
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        }
    }

    .giftcard-payment-list {
        .gpl-row {
            div {
                font-size: 14px;
                width: 49%;
                padding-left: 35px;

                &.with-tick {
                    &:after {
                        left: 15px;
                    }
                }

                &.amount {
                    width: 100%;
                    padding-left: 20px;
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                &:nth-child(3) {
                    width: 49%;
                }

                &:nth-child(4) {
                    width: 49%;
                }

                &:nth-child(5) {
                    width: 49%;
                }

                &.utilize {
                    width: 100%;
                    text-align: center;
                    margin-top: 15px;

                    span {
                        height: 26px;
                        font-size: 14px;
                        padding: 0 25px 0 10px;

                        i {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .giftcard-switches-container {
        position: relative;
        top: 0;
        left: 0;
        right: unset;
    }

    .new-giftcard-opener {
        .title-icon {
            display: none;
        }

        header {
            min-height: unset;
        }

        .voucher-tooltip {
            font-size: 10px;
            margin-top: 0;
        }
    }

    .booking-wrapper {
        header {
            &.new-gc-mobile {
                display: block;
            }

            .main-title {
                margin-bottom: 0;

                &.gift-card-logo {
                    display: inline-block;
                    font-size: 13px;
                    white-space: nowrap;

                    img {
                        @apply relative;
                        top: 6px;
                        right: unset;
                        width: 81px;
                        left: 2px;
                    }

                    span {
                        &:first-child {
                            font-size: 17px;
                        }

                        &:nth-child(2) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .new-giftcard-wrapper {
        .open-icon {
            right: 8px;
        }
    }

    .gift-card-opener-info {
        font-size: 12px;
        line-height: 14px;
        padding: 10px 42px 10px 10px;
        margin-top: 12px;

        &:hover {
            background: $giftcard-cyan3;
        }
    }

    .gift-card-opener-toggle {
        font-size: 12px;
    }

    .js-gift-card {
        &.js-icon {
            &.title-icon {
                &.reduced {
                    display: none;
                }
            }
        }
    }

    .new-giftcard-form-info-container {
        position: static;
        top: unset;
        right: unset;
    }

    .new-giftcard-form-info {
        margin-top: 10px;
    }

    .new-giftcard-input {
        margin-top: 15px;
    }

    .voucher-tooltip {
        &.reduced {
            font-size: 10px;
            margin-top: 0;
            line-height: 1;
        }
    }

    .new-giftcard-btn {
        margin: 15px 0 0;
    }

    .text-center-xs {
        text-align: center;
    }

    .mobile-gc-tooltip-container {
        position: relative;

        .new-giftcard-input-tooltip {
            bottom: 100px;
        }
    }

    #mainContentPayment {
        .inner-deep-box {
            .giftcard-wrapper {
                label {
                    .open-icon {
                        right: -5px;
                    }
                }
            }
        }
    }
}
