.profile-dc-widget {
    @apply relative;
    grid-area: dc;
    height: 264px;
}

.dc-widget-container {
    @apply absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 264px;
}

.dc-widget-content {
    @apply relative bg-be-cyan-2;
    text-align: center;
    height: 264px;
    border-radius: 12px;
    padding: 15px;
    font-size: 14px;

    img {
        height: 62px;
        margin-bottom: 10px;
    }

    > span {
        @apply block;
        margin-bottom: 10px;
    }

    a,
    a:focus,
    a:active {
        @apply relative flex items-center justify-center font-black font-body bg-be-blue text-white normal-case border border-be-blue border-solid;
        width: 190px;
        height: 30px;
        padding: 0 28px 0 10px;
        border-radius: 15px;
        letter-spacing: normal;
        margin: 0 auto;
        font-size: 14px;
        transition: all 0.25s ease-in-out;

        i {
            @apply absolute text-white;
            font-size: 20px;
            top: 4px;
            right: 3px;
            transition: all 0.25s ease-in-out;
        }

        &:not(.disabled):hover {
            @apply border border-be-blue border-solid bg-white text-be-blue;
            padding: 0 28px 0 10px;

            i {
                @apply text-be-blue;
            }
        }
    }
}

.dcwc-details {
    margin: 0 0 10px;

    span {
        @apply font-body text-white;

        &:nth-child(1) {
            font-size: 12px;
        }

        &:nth-child(2) {
            font-size: 14px;
            @apply font-semibold underline uppercase;
        }

        &:nth-child(3) {
            font-size: 10px;
            @apply italic font-semibold;
        }
    }
}

.dcwc-expiration {
    @apply font-body text-white;
    margin: 0 0 10px;
    font-size: 12px;
}

.dcwc-savings {
    width: 200px;
    height: 47px;
    margin: 0 auto 10px;
    padding: 0 8px;
    border-radius: 7px;
    font-size: 12px;
    @apply flex items-center justify-center text-be-blue bg-white font-body;

    span {
        text-align: left;

        &:last-child {
            @apply font-semibold whitespace-nowrap;
            font-size: 17px;
        }
    }

    &.portuguese {
        span {
            &:last-child {
                white-space: nowrap;
            }
        }
    }
}

.dcwc-expiration-warning {
    font-size: 12px;
    @apply font-body font-black;
}

/* MEDIA QUERIES */

@media #{$medium} {
    .dc-widget-content {
        padding: 15px;
        font-size: 13px;

        img {
            height: 54px;
            margin-bottom: 8px;
        }

        > span {
            margin-bottom: 8px;
        }

        a {
            width: 190px;
            height: 30px;
            padding: 0 28px 0 10px;
            font-size: 14px;

            i {
                font-size: 20px;
                top: 4px;
                right: 3px;
            }

            &:not(.disabled):hover {
                padding: 0 28px 0 10px;
            }
        }
    }
}

@media #{$small} {
    .profile-dc-widget {
        height: 185px;
    }

    .dc-widget-container {
        height: 185px;
    }

    .dc-widget-content {
        height: 185px;
        padding: 10px;
        font-size: 10px;
        line-height: 1.2;

        img {
            height: 40px;
            margin-bottom: 7px;
        }

        > span {
            margin-bottom: 7px;
            line-height: 1.2;
        }

        a {
            width: 150px;
            height: 22px;
            padding: 0 20px 0 10px;
            font-size: 12px;

            i {
                font-size: 14px;
                top: 3px;
                right: 3px;
            }

            &:not(.disabled):hover {
                padding: 0 20px 0 10px;
            }
        }
    }

    .dcwc-details {
        margin: 0 0 7px;

        span {
            @apply font-body text-white;

            &:nth-child(1) {
                font-size: 10px;
            }

            &:nth-child(2) {
                font-size: 11px;
            }

            &:nth-child(3) {
                font-size: 10px;
            }
        }
    }

    .dcwc-expiration {
        margin: 0 0 7px;
        font-size: 10px;
    }

    .dcwc-savings {
        width: 150px;
        height: unset;
        line-height: 1.2;
        margin: 0 auto 7px;
        padding: 5px 5px;
        border-radius: 7px;
        font-size: 10px;

        span {
            &:last-child {
                font-size: 12px;
            }
        }

        &.english {
            @apply leading-none;
        }
    }

    .dcwc-expiration-warning {
        font-size: 10px;
    }
}

@media #{$xsmall} {
    .profile-dc-widget {
        height: 207px;

        &.compressed {
            height: 177px;

            .dc-widget-container {
                height: 177px;
            }

            .dc-widget-content {
                height: 177px;
            }
        }
    }

    .dc-widget-container {
        height: 207px;
    }

    .dc-widget-content {
        height: 207px;
        padding: 15px 0;
        font-size: 12px;

        img {
            height: 37px;
            margin-bottom: 0;
        }

        > span {
            padding: 10px 20px;
            margin-bottom: 0;
        }

        a {
            width: 150px;
            height: 22px;
            padding: 0 14px 0 5px;
            font-size: 12px;

            i {
                font-size: 14px;
                top: 4px;
                right: 3px;
            }

            &:not(.disabled):hover {
                padding: 0 14px 0 5px;
            }
        }
    }

    .dcwc-details {
        margin: 10px 0 0;
        text-align: left;
        padding-left: 30px;

        span {
            @apply font-body text-white;

            &:nth-child(1) {
                font-size: 12px;
            }

            &:nth-child(2) {
                font-size: 12px;
            }

            &:nth-child(3) {
                font-size: 12px;
            }
        }
    }

    .dcwc-expiration {
        margin: 0 0 10px;
        font-size: 12px;
        text-align: left;
        padding-left: 30px;
    }

    .dcwc-savings {
        width: 193px;
        height: 30px;
        line-height: 1.2;
        margin: 0 auto 10px;
        padding: 0 10px;
        border-radius: 7px;
        font-size: 10px;

        span {
            &:last-child {
                font-size: 14px;
            }
        }

        &.english {
            span {
                &:first-child {
                    max-width: 114px;
                }
            }
        }
    }

    .dcwc-expiration-warning {
        font-size: 10px;
    }
}
