@import "../flight/flight-variables";

.modal-content {
    &.flight-modal-content {
        min-width: 1030px;
        max-width: 90%;

        &.with-sidebar {
            width: 1005px;
            padding-left: 290px;
            min-height: 540px;

            &.many-modal {
                width: 892px;
                min-height: 418px;
                min-width: unset;
            }
        }

        &.login-modal {
            min-height: 430px;
        }

        &.taller {
            min-height: 660px;
        }

        &.upgrade-modal {
            min-height: 570px;

            .modal-header {
                margin-bottom: 50px;
            }

            .flight-modal-list {
                margin-bottom: 0;
            }
        }

        .mdl-textfield {
            padding: 20px 0 0;
        }

        .js-input {
            &.js-select {
                font-size: 20px;
                padding-left: 15px;
                padding-top: 8px;
                color: $brand-secondary;
                -webkit-appearance: none;

                option {
                    font-size: $font-size-medium;
                }
            }
        }

        .js-select-arrow {
            &:after {
                content: "\25BC";
                display: block;
                position: absolute;
                top: 39px;
                right: 15px;
                font-size: $font-size-xsmall;
                color: $gray-light;
            }
        }

        .cb-title {
            line-height: 1.4;

            a {
                color: $membership-link-color;

                &:hover {
                    color: $brand-secondary;
                }
            }
        }

        .flight-modal-sidebar {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 290px;
            background: #00aac3 url("../images/dc-logo-2.png") no-repeat center center;
            border-top-left-radius: $border-radius-lg;
            border-bottom-left-radius: $border-radius-lg;

            &.english {
                background: #00aac3 url("../images/dc-logo-2-en.png") no-repeat center center;
            }

            &.portuguese {
                background: #00aac3 url("../images/dc-logo-2-pt.png") no-repeat center center;
            }
        }

        .modal-header {
            font-size: 18px;
            color: $brand-secondary;
            margin: 25px 20px 15px 310px;
            text-align: center;
            font-weight: 400;

            &.with-subtitle {
                text-align: left;

                .subtitle {
                    display: block;
                    margin-top: 10px;
                    font-size: $font-size-small;
                    font-weight: 600;
                    text-transform: none;
                }
            }

            span {
                font-weight: 900;
            }
        }

        .modal-body {
            margin-left: 290px;

            &.bordered {
                margin: 20px 20px 20px 310px;
                border: 1px solid $gray-light;
                border-radius: $border-radius-lg;
            }
        }

        .modal-body-inner-box {
            margin: 20px;
            padding: 0 20px;
            background: $gray-lightest;
            border-radius: $border-radius-lg;
        }

        .modal-button-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0;

            &.padded {
                margin: 20px;
            }

            &.buttons-right {
                justify-content: flex-end;
            }

            &.buttons-center {
                justify-content: center;
            }

            &.get-membership,
            &.upgrade-buttons {
                .primary-btn,
                .secondary-btn {
                    width: unset;
                    height: 34px;
                    padding: 0 40px 0 15px;
                    display: flex;
                    align-items: center;
                    border-radius: 17px;
                    font-family: "Lato", sans-serif;
                    font-size: 14px;
                    font-weight: 900;
                    border-width: 1px;

                    i {
                        position: absolute;
                        top: 50%;
                        right: 5px;
                        transform: translateY(-50%) translateY(-1px);
                        font-size: 23px;
                    }
                }
            }

            &.many-modal-buttons {
                justify-content: center;

                .primary-btn {
                    width: 140px;
                    height: 36px;
                    border: 2px solid #ab272f;
                    border-radius: 18px;
                    background: #fff;
                    padding: 0 23px 0 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    transition: background-color 0.2s ease-in-out;

                    span {
                        color: #ab272f;
                        font-family: "Lato", sans-serif;
                        font-weight: 700;
                        font-size: 15px;
                        letter-spacing: normal;
                        line-height: normal;
                    }

                    i {
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        font-size: 25px;
                        font-weight: 400;
                        color: #ab272f;
                    }

                    &:hover {
                        border: 2px solid #ab272f;
                        background: #ab272f;
                        width: 140px;
                        height: 36px;
                        border-radius: 18px;
                        padding: 0 23px 0 0;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        line-height: normal;
                        transition: background-color 0.2s ease-in-out;

                        span {
                            color: #fff;
                            font-family: "Lato", sans-serif;
                            font-weight: 700;
                            font-size: 15px;
                            letter-spacing: normal;
                            line-height: normal;
                        }

                        i {
                            font-size: 25px;
                            color: #fff;
                            font-weight: 400;
                            position: absolute;
                            top: 4px;
                            right: 4px;
                        }
                    }
                }
            }
        }

        .error-wrapper {
            background: #fff;
            box-shadow: none;
            color: $brand-primary;
        }

        .flight-modal-list-container {
            width: 545px;
            margin: 0 auto;

            &.english {
                width: 625px;
            }

            &.portuguese {
                width: 585px;
            }
        }

        .flight-modal-list {
            margin: 30px 0;

            &.condensed {
                margin-bottom: 0;
            }

            li {
                position: relative;
                color: $brand-secondary;
                font-family: "Lato", sans-serif;
                font-size: 18px;

                &:after {
                    content: "\2714";
                    position: absolute;
                    top: 0;
                    left: -20px;
                    color: $tick-green;
                }
            }
        }

        .membership-container {
            display: inline-block;
            margin: 0 10px;
        }

        .membership-title {
            color: $brand-secondary;
            font-family: "Lato", sans-serif;
            font-style: italic;
            font-weight: 300;
            font-size: 20px;
        }

        .membership-info {
            color: $brand-secondary;
            font-family: "Lato", sans-serif;
            font-weight: 300;
        }

        .membership-btn {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
            padding: 10px 20px;
            color: $membership-accent-color;
            border-radius: $border-radius-lg;
            border: $membership-accent-color solid 2px;
            line-height: 0.8;

            span {
                font-family: "Lato", sans-serif;
                font-size: 25px;
                font-weight: 900;
                text-transform: none;

                span {
                    font-weight: 400;
                    font-size: 18px;
                    font-style: italic;
                }
            }

            i {
                margin-right: 20px;
                font-size: 70px;
                font-weight: 300;
            }

            &:not(:disabled):hover {
                color: $membership-accent-color;
                background: rgba($membership-accent-color, 0.2);
                border: $membership-accent-color solid 2px;
            }

            &.selected,
            &:not(:disabled):focus {
                color: #fff;
                background: $membership-accent-color;
                border: $membership-accent-color solid 2px;
                cursor: default;

                &:after {
                    content: "\2714";
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    font-size: $font-size-medium;
                    color: #fff;
                }

                &:not(:disabled):hover {
                    color: #fff;
                    background: $membership-accent-color;
                    border: $membership-accent-color solid 2px;
                }
            }
        }

        .membership-link-container {
            display: inline-block;
            margin: 20px 0 0;
            font-size: 15px;

            a {
                color: $membership-link-color;

                &:hover {
                    color: $brand-secondary;
                }
            }
        }

        .forgotten-pw-link-container {
            display: inline-block;
            margin: 20px 0;

            a {
                color: $membership-link-color;

                &:hover {
                    color: $brand-secondary;
                }
            }
        }

        .membership-warning {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 20px;
            padding: 5px;
            background: rgba($brand-tertiary, 0.2);
            color: $brand-secondary;
            border-radius: $border-radius-sm;

            span {
                font-size: $font-size-xsmall;
                font-weight: 600;
            }

            .exclamation {
                margin-right: 10px;
                font-size: 30px;
                color: $brand-tertiary;
            }
        }

        .membership-modal-banner {
            position: relative;
            margin: 0 auto;
            padding: 15px 30px 15px 65px;
            color: #fff;
            background: $brand-secondary;
            text-align: center;
            font-size: 23px;
            font-weight: 600;
            font-style: italic;
            font-family: "Lato", sans-serif;
            text-transform: uppercase;
            border-radius: 10px;
            white-space: nowrap;

            i {
                font-size: 37px;
                position: absolute;
                top: 50%;
                left: 15px;
                transform: translateY(-50%);
            }

            span {
                font-weight: 900;
            }
        }

        .extend-slogan {
            color: #ffa400;
            font-family: "Lato", sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 2;
        }

        .extend-info {
            color: $brand-secondary;
            font-family: "Lato", sans-serif;
            font-size: 18px;
            font-weight: 600;
        }

        .upgrade-info {
            font-size: 18px;
            color: $brand-secondary;
            line-height: 1.2;
            max-width: 666px;
            margin: 0 auto;

            &.english {
                max-width: 730px;
            }

            &.portuguese {
                max-width: 730px;
            }

            span {
                line-height: 1.2;
                font-size: 18px;
                color: $brand-secondary;
                font-weight: 600;
            }
        }

        .upgrade-warning-message-container {
            background: #ab272f;
            color: #fff;
            border-radius: 10px;
            padding: 15px;
            font-family: "Lato", sans-serif;
            font-size: 14px;
            font-weight: 900;
            display: flex;
            align-items: center;
            max-width: 666px;
            margin: 20px auto;

            i {
                font-size: 40px;
                margin-right: 15px;
            }
        }

        .flight-login-button-container {
            @apply mb-5 flex justify-between;
        }
    }
}

.flight-modal-close {
    position: absolute;
    top: -17px;
    right: -17px;
    width: 34px;
    height: 34px;
    background: $gray-darkest;
    color: #fff;
    border-radius: 50%;
    border: none;
    font-size: 26px;
    z-index: 100;
    user-select: none;
    padding: 0;
    line-height: 1;
    cursor: pointer;

    &:not(disabled):hover {
        border: none;
        background: $brand-secondary;
        color: #fff;
    }
}

.too-many-pax-info {
    max-width: 450px;
    margin: 80px auto 50px;
    display: flex;
    align-items: center;
    color: #183264;
    font-family: "Lato", sans-serif;
    font-size: 24px;
    text-align: left;
    line-height: 1.4;

    span {
        color: #183264;
        font-weight: 900;
        font-style: italic;
    }

    i {
        font-weight: 400;
        color: #ab272f;
        font-size: 57px;
        margin-right: 30px;
    }

    .pax-text-container {
        text-align: center;
    }
}

.modal-content {
    &.flight-modal-content {
        .membership-container {
            &.disabled {
                -webkit-filter: grayscale(1);
                filter: grayscale(1);
            }
        }
    }
}

.discount-club-modal {
    @apply rounded-lg leading-none;

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            @apply pointer-events-none absolute block;
            content: "\25BC";
            top: 41px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }

    .modal-content {
        @apply p-0;
        height: 650px;
        width: 770px;
        max-height: 95%;
        max-width: 95%;

        &:not(.dc-limit-info) {
            height: 600px;
        }

        .dc-modal-header {
            @apply rounded-t-lg bg-be-cyan-2;
            padding: 20px 0;
        }
    }

    .flight-scroller {
        top: 100px;
    }

    .dob-selector-container {
        &:after {
            @apply pointer-events-none absolute block;
            content: "\25BC";
            top: 11px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.dc-modal-header-content {
    @apply mx-auto flex items-center justify-around;

    .dc-modal-header-img {
        height: 55px;
    }

    .dc-modal-header-texts {
        @apply my-auto align-middle text-white;
        text-align: center;

        .dc-modal-header-smaller-text {
            @apply font-body;
            font-size: 15px;
            margin-bottom: 10px;
        }

        .dc-modal-header-main-text {
            @apply font-emphasis;
            font-size: 28px;
        }
    }
}

.dc-modal-body {
    @apply m-auto text-dc-dark-blue;
    max-width: 700px;
    padding: 20px 0 25px 0;

    .dc-modal-body-title {
        @apply font-emphasis;
        font-size: 18px;
        margin-bottom: 3px;
    }

    .dc-modal-body-description {
        @apply font-body;
        font-size: 16px;
    }

    .ts-error-container {
        margin-bottom: 6px;
    }

    .dc-modal-btn-container {
        @apply flex justify-center;

        .rounded-secondary-btn {
            margin-right: 25px;
        }
    }
}

.dc-modal-form {
    .mdl-textfield {
        padding: 20px 0 0;
    }

    .js-input.js-select {
        font-size: 20px;
        padding-left: 15px;
        padding-top: 8px;
        color: #163a70;
        -webkit-appearance: none;
    }

    .dc-modal-membership-warning {
        @apply mt-4 flex items-center rounded-lg bg-be-cyan-6;
        padding: 8px 3px 8px 20px;

        i {
            @apply mr-3 text-be-cyan-2;
            font-size: 30px;
        }

        .dc-modal-membership-warning-text {
            @apply m-auto;
            font-size: 16px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .modal-content {
        &.flight-modal-content {
            &.extension-modal {
                max-height: 570px;
            }

            &.upgrade-modal {
                max-height: 570px;
            }

            &.membership-modal {
                max-height: 570px;
            }

            &.login-modal {
                height: 430px;
            }

            &.register-modal {
                min-height: unset;
                height: 660px;
                max-height: 95%;
            }
        }
    }
}

@media #{$small} {
    .modal-content {
        &.flight-modal-content {
            &.with-sidebar {
                width: 666px;
                min-width: unset;
                height: unset;
                min-height: 395px;
                padding-left: 192px;

                &.many-modal {
                    width: 548px;
                    min-height: 260px;
                }
            }

            &.extension-modal {
                min-height: 465px;
            }

            &.taller {
                min-height: 720px;
            }

            &.upgrade-modal {
                min-height: 435px;

                .modal-header {
                    margin: 35px 0 15px 192px;
                }

                .cb-title {
                    font-size: 12px;

                    a {
                        font-size: 12px;
                    }
                }
            }

            .flight-modal-sidebar {
                width: 192px;
                background-size: 90%;

                &.english,
                &.portuguese {
                    background-size: 90%;
                }
            }

            .flight-scroller {
                top: 0;
            }

            .modal-header {
                margin: 16px 0 10px 192px;
                font-size: 12px;

                &.with-subtitle {
                    padding-left: 20px;
                }
            }

            .modal-body {
                margin-left: 192px;

                &.bordered {
                    margin-left: 212px;
                }
            }

            .membership-modal-banner {
                font-size: 15px;
                padding: 10px 20px 10px 45px;

                i {
                    font-size: 25px;
                    left: 10px;
                }
            }

            .flight-modal-list-container {
                width: 350px;

                &.english {
                    width: 415px;
                }

                &.portuguese {
                    width: 385px;
                }
            }

            .flight-modal-list {
                margin: 20px 0;

                li {
                    font-size: 12px;
                    line-height: 1.4;
                }
            }

            .membership-title {
                font-size: 13px;
            }

            .membership-container {
                margin: 0;
            }

            .membership-btn {
                padding: 10px;
                margin: 5px 0;

                span {
                    font-size: 19px;

                    span {
                        font-size: 13px;
                    }
                }

                i {
                    font-size: 45px;
                }
            }

            .membership-info {
                font-size: 12px;
                font-weight: 400;
            }

            .membership-link-container {
                font-size: 12px;
            }

            .modal-button-container {
                margin: 10px;

                &.get-membership,
                &.upgrade-buttons {
                    .primary-btn,
                    .secondary-btn {
                        height: 20px;
                        font-size: 12px;
                        border-radius: 10px;
                        padding: 0 30px 0 10px;
                        line-height: 1;

                        i {
                            font-size: 15px;
                        }
                    }
                }

                &.many-modal-buttons {
                    .primary-btn {
                        width: 85px;
                        height: 22px;
                        border-radius: 11px;
                        padding: 0 16px 0 0;

                        span {
                            font-size: 11px;
                        }

                        i {
                            top: 1px;
                            right: 1px;
                            font-size: 16px;
                        }
                    }
                }
            }

            .upgrade-info {
                font-size: 12px;
                max-width: 440px;

                span {
                    font-size: 12px;
                }
            }

            .upgrade-warning-message-container {
                max-width: 440px;
                font-size: 12px;
                padding: 10px;
                margin-bottom: 0;

                i {
                    font-size: 27px;
                }
            }
        }
    }

    .too-many-pax-info {
        max-width: 300px;
        margin: 70px auto 10px;
        font-size: 12px;

        .pax-text-container {
            text-align: left;
        }

        i {
            font-size: 35px;
            margin-right: 15px;
        }
    }

    .discount-club-modal {
        .modal-content {
            width: 500px;
            height: 640px;

            .birthdate-container {
                .input-inner-title {
                    top: 20px;
                }
            }
        }

        .flight-scroller {
            top: 90px;
        }

        .dob-selector-container {
            &:after {
                right: 4px;
            }
        }
    }

    .dc-modal-header-content {
        .dc-modal-header-img {
            height: 50px;
        }

        .dc-modal-header-texts {
            .dc-modal-header-smaller-text {
                font-size: 11px;
                margin-bottom: 8px;
            }

            .dc-modal-header-main-text {
                font-size: 20px;
            }
        }
    }

    .dc-modal-body {
        max-width: 435px;

        .dc-modal-body-title {
            font-size: 14px;
        }

        .dc-modal-body-description {
            margin-bottom: 8px;
            font-size: 11px;
        }

        .ts-error-container {
            margin-bottom: 5px;
        }

        .dc-modal-btn-container {
            .rounded-secondary-btn {
                margin-right: 12px;
            }
        }
    }

    .dc-modal-form {
        .dc-modal-membership-warning {
            padding: 8px 3px 8px 20px;

            i {
                font-size: 25px;
            }

            .dc-modal-membership-warning-text {
                font-size: 13px;
            }
        }
    }

    .mdl-checkbox-wrapper {
        .mdl-checkbox {
            &.mdl-js-checkbox {
                &.is-upgraded {
                    margin-top: 4px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .modal {
        z-index: 100000 !important;
    }

    .modal-content {
        &.flight-modal-content {
            max-height: 95%;
            max-width: 95%;
            min-width: unset;
            height: 570px;

            &.with-sidebar {
                padding-top: 70px;
                height: 590px;
                padding-left: 0;

                &.many-modal {
                    min-height: unset;
                    height: 366px;
                    width: 280px;
                }

                &.membership-modal {
                    height: 620px;
                }

                &.login-modal {
                    height: 530px;
                }
            }

            &.upgrade-modal {
                .modal-header {
                    margin-bottom: 0;
                }

                .upgrade-info {
                    font-size: 13px;

                    span {
                        font-size: 13px;
                    }
                }
            }

            &.extension-modal {
                .flight-modal-list-container {
                    width: 90%;
                }
            }

            &.extension-modal,
            &.upgrade-modal {
                .flight-modal-list {
                    width: 500%;

                    li {
                        width: 20%;
                    }
                }

                .modal-button-container {
                    &.padded {
                        margin-top: 30px;
                    }
                }
            }

            &.extension-modal,
            &.upgrade-modal {
                .modal-button-container {
                    display: block;
                }
            }

            &.login-modal {
                height: 650px;
                max-height: 95%;
            }

            &.register-modal {
                .modal-button-container {
                    &.push-down {
                        margin-top: 30px;
                    }
                }
            }

            .modal-header {
                margin: 0 auto;
                line-height: 1;
                max-width: 70%;
                padding: 15px 0;
                font-size: 14px;
            }

            .modal-body {
                margin: 0 auto;
                max-width: 95%;

                &.bordered {
                    margin: 0 2.5% 2.5%;
                }
            }

            .modal-button-container {
                margin: 5px 20px 20px;

                &.padded {
                    margin: 5px 20px 20px;
                }

                &.buttons-right {
                    justify-content: center;
                }

                &.get-membership,
                &.get-membership,
                &.upgrade-buttons,
                &.upgrade-buttons {
                    .primary-btn,
                    .secondary-btn {
                        height: 35px;
                        font-size: 15px;
                        margin: 10px auto;

                        i {
                            font-size: 25px;
                            right: 3px;
                        }
                    }
                }

                &.many-modal-buttons {
                    padding: 0;
                    margin: 0;
                    text-align: center;

                    .primary-btn {
                        display: inline-flex;
                        margin: 0 auto;
                        width: 100px;
                        height: 26px;

                        span {
                            font-size: 12px;
                        }

                        i {
                            font-size: 18px;
                            top: 2px;
                            right: 2px;
                        }
                    }
                }
            }

            .flight-modal-sidebar {
                height: 73px;
                background-size: auto 50px;
                bottom: unset;
                width: unset;
                right: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 10px;

                &.english,
                &.portuguese {
                    background-size: auto 50px;
                }
            }

            .flight-scroller {
                top: 70px;
            }

            .membership-container {
                display: block;
                margin: 10px auto;
            }

            .membership-modal-banner {
                line-height: 1;
                font-size: 12px;

                span {
                    display: block;
                    font-size: 17px;
                }

                i {
                    font-size: 30px;
                }
            }

            .flight-modal-list-container {
                width: 80%;
                overflow: hidden;
                white-space: nowrap;
                position: relative;

                &.english {
                    width: 80%;
                }

                &.portuguese {
                    width: 80%;
                }
            }

            .flight-modal-list {
                display: block;
                overflow: visible;
                width: 400%;
                height: 30px;
                white-space: nowrap;
                margin: 15px 0 5px;

                li {
                    float: left;
                    width: 25%;
                    height: 30px;
                    white-space: normal;
                    font-size: 12px;
                    padding: 0 20px;
                    line-height: 1.4;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:after {
                        display: none;
                    }
                }
            }

            .flight-modal-list-dots {
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #dadada;
                    margin: 10px 4px;
                    cursor: pointer;
                    transition: all 0.5s ease-in-out;

                    &.selected {
                        background: #193969;
                    }
                }
            }

            .membership-btn {
                padding: 10px 15px;
                margin: 0 0 5px 0;

                i {
                    font-size: 49px;
                    margin-right: 10px;
                }

                span {
                    &:first-child {
                        font-style: italic;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 1.7;
                    }

                    &:nth-child(2) {
                        font-size: 19px;
                    }
                }
            }

            .membership-info {
                font-size: 13px;
                font-weight: 400;
            }

            .membership-link-container {
                font-size: 13px;
            }

            .extend-slogan {
                line-height: 1;
                margin: 10px;
                font-size: 18px;
            }

            .extend-info {
                font-size: 18px;
            }

            .upgrade-warning-message-container {
                padding: 20px;
                max-width: 95%;
                margin: 20px 2.5% 0;
            }

            .cb-title {
                font-size: 12px;
                color: $brand-secondary;

                a {
                    font-size: 12px;
                }
            }

            .mobile-carousel-left,
            .mobile-carousel-right {
                position: absolute;
                top: 15px;
                font-size: 25px;
                color: $brand-secondary;
                cursor: pointer;
                font-family: "ClanOT-Bold", sans-serif;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .mobile-carousel-left {
                left: -10px;
            }

            .mobile-carousel-right {
                right: -10px;
            }
        }
    }

    .flight-modal-close {
        top: -10px;
        right: -10px;
        width: 30px;
        height: 30px;
    }

    .too-many-pax-info {
        flex-direction: column;
        margin: 20px auto 55px;
        max-width: 240px;
        padding: 0 20px;
        text-align: center;
        font-size: 13px;

        i {
            font-size: 40px;
            margin: 0 0 15px 0;
        }
    }

    .discount-club-modal {
        .modal-content {
            @apply pl-0;
            height: 95%;
            width: 90%;

            .dc-modal-header {
                padding: 20px 5px;

                .dc-modal-header-content {
                    @apply block w-full;
                    text-align: center;
                }
            }

            .birthdate-container {
                .input-inner-title {
                    top: 20px;
                }
            }
        }

        .flight-scroller {
            top: 160px;
        }
    }

    .dc-modal-header-content {
        .dc-modal-header-img {
            height: 65px;
            margin-bottom: 15px;
        }

        .dc-modal-header-texts {
            .dc-modal-header-smaller-text {
                font-size: 11px;
            }

            .dc-modal-header-main-text {
                font-size: 20px;
            }
        }
    }

    .dc-modal-body {
        padding-left: 10px;
        padding-right: 10px;

        .dc-modal-body-title {
            font-size: 15px;
            margin-bottom: 6px;
        }

        .dc-modal-body-description {
            font-size: 12px;
        }

        .ts-error-container {
            margin-bottom: 6px;
        }

        .dc-modal-btn-container {
            @apply flex justify-center;
            margin-top: 20px;

            .rounded-secondary-btn {
                margin-right: 5px;
            }
        }
    }

    .dc-modal-form {
        .dc-modal-membership-warning {
            .dc-modal-membership-warning-text {
                font-size: 12px;
            }
        }
    }
}
