/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "jetsmart-itinerary";
    src: url("../fonts/jetsmart-itinerary.eot?kp2w3z");
    src: url("../fonts/jetsmart-itinerary.eot?kp2w3z#iefix") format("embedded-opentype"),
        url("../fonts/jetsmart-itinerary.ttf?kp2w3z") format("truetype"),
        url("../fonts/jetsmart-itinerary.woff?kp2w3z") format("woff"),
        url("../fonts/jetsmart-itinerary.svg?kp2w3z#jetsmart-itinerary") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-it {
    font-family: "jetsmart-itinerary" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-it-guitar-surf-golf:before {
    content: "\e90e";
}
.js-it-chubb:before {
    content: "\e90c";
}
.js-it-insurance:before {
    content: "\e90d";
}
.js-it-giftcard:before {
    content: "\e900";
}
.js-it-landing-plane:before {
    content: "\e901";
}
.js-it-money:before {
    content: "\e902";
}
.js-it-money-list:before {
    content: "\e903";
}
.js-it-pets:before {
    content: "\e904";
}
.js-it-plane-calendar:before {
    content: "\e905";
}
.js-it-plane-plus:before {
    content: "\e906";
}
.js-it-plane-shield:before {
    content: "\e907";
}
.js-it-priority:before {
    content: "\e908";
}
.js-it-bike:before {
    content: "\e909";
}
.js-it-boarding-card:before {
    content: "\e90a";
}
.js-it-edit:before {
    content: "\e90b";
}
