/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "jetsmart-my-profile";
    src: url("../fonts/jetsmart-my-profile.eot?ioge3i");
    src: url("../fonts/jetsmart-my-profile.eot?ioge3i#iefix") format("embedded-opentype"),
        url("../fonts/jetsmart-my-profile.ttf?ioge3i") format("truetype"),
        url("../fonts/jetsmart-my-profile.woff?ioge3i") format("woff"),
        url("../fonts/jetsmart-my-profile.svg?ioge3i#jetsmart-be2") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-mp {
    font-family: "jetsmart-my-profile" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-mp-name-tag:before {
    content: "\e900";
}
.js-mp-no-flight:before {
    content: "\e901";
}
.js-mp-percent-label:before {
    content: "\e902";
}
.js-mp-person:before {
    content: "\e903";
}
.js-mp-phone:before {
    content: "\e904";
}
.js-mp-plane-right:before {
    content: "\e905";
}
.js-mp-search:before {
    content: "\e906";
}
.js-mp-view:before {
    content: "\e907";
}
.js-mp-circle-plane-right:before {
    content: "\e908";
}
.js-mp-edit:before {
    content: "\e909";
}
.js-mp-lock:before {
    content: "\e90a";
}
.js-mp-logo .path1:before {
    content: "\e90b";
    color: rgb(159, 39, 52);
}
.js-mp-logo .path2:before {
    content: "\e90c";
    margin-left: -1em;
    color: rgb(28, 53, 94);
}
.js-mp-logo .path3:before {
    content: "\e90d";
    margin-left: -1em;
    color: rgb(28, 53, 94);
}
.js-mp-logo .path4:before {
    content: "\e90e";
    margin-left: -1em;
    color: rgb(28, 53, 94);
}
.js-mp-logo .path5:before {
    content: "\e90f";
    margin-left: -1em;
    color: rgb(28, 53, 94);
}
.js-mp-logo .path6:before {
    content: "\e910";
    margin-left: -1em;
    color: rgb(28, 53, 94);
}
.js-mp-logo .path7:before {
    content: "\e911";
    margin-left: -1em;
    color: rgb(159, 39, 52);
}
