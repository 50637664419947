dc-table {
    .dc-table {
        border-radius: 10px;

        th {
            @apply bg-cug-dark-gray text-white font-body font-bold;
            font-size: 15px;
            height: 50px;

            &:first-child {
                border-top-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;

                dc-table-header > div {
                    @apply border-r-0 border-solid border-white;
                }
            }

            dc-table-header > div {
                @apply border-r border-solid border-white;
            }

            &:not([class^="p-"]):not([class*=" p-"]) {
                @apply py-2;

                &:first-child {
                    @apply pl-2;
                }
            }
        }

        td {
            @apply bg-cug-gray-5 text-be-blue-2 border-b border-solid border-cug-gray-6;
            vertical-align: middle;
            height: 50px;
            font-size: 13px;

            &:first-child {
                border-left: 1px solid #0e223e;
            }

            &:last-child {
                border-right: 1px solid #0e223e;
            }

            &.text-center {
                > div {
                    @apply justify-center;
                }
            }

            &:not([class^="p-"]):not([class*=" p-"]) {
                > div {
                    @apply px-4;
                }

                &:first-child {
                    @apply pl-2;

                    > div {
                        @apply p-0;
                    }
                }

                &:last-child {
                    @apply pr-2;

                    > div {
                        @apply pl-6;
                    }
                }
            }
        }

        tr {
            &:last-child {
                td {
                    @apply border-0 border-b border-cug-lightest-gray w-full;

                    &:first-child {
                        @apply border-l border-solid border-cug-lightest-gray;
                        border-bottom-left-radius: 10px;
                    }

                    &:last-child {
                        @apply border-r border-solid border-cug-lightest-gray;
                        border-bottom-right-radius: 10px;
                    }

                    &:not([class^="p-"]):not([class*=" p-"]) {
                        &:first-child {
                            @apply p-0;

                            > div {
                                @apply pl-2;
                            }
                        }

                        &:last-child {
                            @apply p-0;

                            > div {
                                @apply pr-6;
                            }
                        }
                    }
                }
            }
        }
    }

    .action-cell {
        @apply h-full flex items-center justify-center cursor-pointer;

        i {
            @apply text-cug-dark-gray;
            font-size: 18px;

            &.text-red {
                @apply text-be-red;
            }
        }

        &:hover {
            @apply text-be-red;

            i {
                @apply text-be-red;

                &.text-red {
                    @apply text-cug-dark-gray;
                }
            }
        }
    }

    .action-cell-user {
        @apply h-full flex items-center justify-center;

        i {
            @apply text-cug-dark-gray cursor-pointer;
            font-size: 18px;

            &:hover {
                @apply text-be-red;
            }
        }
    }

    .material-icons {
        @apply ml-2;
        font-size: 16px;
        // DEVNOTE Sorry, Bauer G. gave it 99 and I have no time to find out why.
        z-index: 0 !important;
    }
}

// MEDIA QUERIES

@media #{$medium} {
    dc-table {
        .dc-table {
            th {
                font-size: 12px;
                line-height: 13px;
                height: 32px;
            }

            td {
                font-size: 12px;
                line-height: 13px;
                height: 32px;
            }
        }

        .action-cell {
            i {
                font-size: 14px;
            }
        }

        .material-icons {
            font-size: 12px;
        }
    }
}

@media #{$small} {
    dc-table {
        .dc-table {
            th {
                font-size: 10px;
                line-height: 11px;
                height: 32px;

                &:not([class^="p-"]):not([class*=" p-"]) {
                    @apply py-0;
                }
            }

            td {
                font-size: 10px;
                line-height: 11px;
                height: 32px;

                &:not([class^="p-"]):not([class*=" p-"]) {
                    > div {
                        @apply px-2;
                    }

                    &:last-child {
                        @apply pr-1;

                        > div {
                            @apply pl-3;
                        }
                    }
                }
            }

            tr {
                &:last-child {
                    td {
                        &:not([class^="p-"]):not([class*=" p-"]) {
                            &:last-child {
                                > div {
                                    @apply pr-3;
                                }
                            }
                        }
                    }
                }
            }
        }

        .action-cell {
            i {
                font-size: 12px;
            }
        }

        .material-icons {
            font-size: 10px;
        }

        .action-cell-user {
            @apply h-full flex items-center justify-center;

            i {
                @apply text-cug-dark-gray cursor-pointer;
                font-size: 14px;

                &:hover {
                    @apply text-be-red;
                }
            }
        }
    }

    dc-pager {
        .dc-pager {
            @apply mt-4;

            .dc-pager-number {
                @apply m-1;
                width: 17px;
                height: 14px;
                font-size: 10px;
            }

            .dc-pager-control {
                @apply my-1;
                width: 14px;
                height: 14px;
                font-size: 18px;
                padding-bottom: 3px;
            }
        }
    }
}

@media #{$xsmall} {
    dc-table {
        .dc-table-container {
            border-radius: 10px;
        }

        .dc-table {
            &.pinned-cells {
                @apply rounded-l-none;
            }

            th {
                @apply w-auto whitespace-nowrap p-0;
                font-size: 12px;
                line-height: 14px;
                height: 32px;

                &:not([class^="p-"]):not([class*=" p-"]) {
                    @apply p-0;

                    &:first-child {
                        @apply p-0;
                    }
                }

                dc-table-header {
                    @apply px-0;

                    > div {
                        @apply px-2;
                    }
                }
            }

            td {
                @apply w-auto whitespace-nowrap;
                font-size: 10px;
                line-height: 12px;
                height: 32px;

                &:not([class^="p-"]):not([class*=" p-"]) {
                    > div {
                        @apply px-2;
                    }

                    &:first-child {
                        @apply pl-2;
                    }

                    &:last-child {
                        @apply pr-1;

                        > div {
                            @apply pl-2;
                        }
                    }
                }
            }

            tr {
                &:last-child {
                    td {
                        &:not([class^="p-"]):not([class*=" p-"]) {
                            &:first-child {
                                > div {
                                    @apply px-2;
                                }
                            }

                            &:last-child {
                                > div {
                                    @apply pr-3;
                                }
                            }
                        }
                    }
                }
            }
        }

        .action-cell {
            i {
                font-size: 10px;
            }
        }
    }

    dc-pager {
        .dc-pager {
            @apply mt-4;

            .dc-pager-number {
                @apply m-1;
                width: 24px;
                height: 20px;
                font-size: 14px;
            }

            .dc-pager-control {
                @apply my-1;
                width: 20px;
                height: 20px;
                font-size: 24px;
                padding-bottom: 4px;
            }
        }
    }
}
