.b2-per-pax {
    @apply border-l border-r border-b border-solid border-be-blue bg-white;
    grid-area: per-pax;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
}

.b2-per-pax-view-switch {
    grid-area: view2;

    &.b2-view-switch {
        @apply rounded-bl-none;
        border-top: 1px solid #1c355e;
        border-left: 1px solid #1c355e;
    }
}

.b2-per-pax-reset {
    @apply border border-solid border-bg-gray-1 border-t-0 flex items-center justify-center bg-be-light-gray;
    grid-area: reset;
    border-top: 1px solid #1c355e;
    border-right: 1px solid #1c355e;
}

.b2-journey-heading {
    @apply w-full bg-bg-gray-4 flex items-center justify-center font-emphasis text-be-blue relative border-b border-solid border-bg-gray-1;
    height: 51px;
    font-size: 18px;

    i {
        @apply text-be-blue;
        font-size: 33px;
        margin-right: 15px;
    }
}

.b2-pax-info {
    @apply font-emphasis text-be-gray-4;
    font-size: 16px;
    line-height: 20px;
}

.b2-pax-name {
    @apply font-body font-black text-be-blue;
    font-size: 18px;
    line-height: 22px;
}

.b2-pax-option-info {
    @apply text-be-blue font-body font-semibold;
    font-size: 15px;
    margin-bottom: 10px;

    span {
        @apply font-heavy;
        font-size: 20px;
    }
}

.b2-pax-option {
    @apply flex items-center;
    max-width: 94%;

    label {
        @apply font-body font-black text-be-blue;
        text-align: left;
        max-width: 240px;

        &.checked {
            @apply pointer-events-none;
        }

        &.disabled {
            @apply opacity-100 pointer-events-none text-bg-gray-2;
        }
    }

    [type="radio"] {
        &:not(:checked) {
            + label {
                @apply h-auto relative;
                font-size: 18px;

                &:before {
                    @apply vertical-center border-be-blue;
                }

                &:after {
                    top: 50%;
                    transform: translateY(-50%) scale(0.5);
                }
            }
        }

        &:checked {
            @apply pointer-events-none;

            + label {
                @apply pointer-events-none h-auto relative;
                font-size: 18px;

                &:before {
                    @apply border-be-blue vertical-center;
                }

                &:after {
                    @apply bg-be-blue border-be-blue;
                    top: 50%;
                    transform: translateY(-50%) scale(0.5);
                }
            }
        }

        &:disabled {
            &:checked {
                + label {
                    @apply text-be-blue opacity-100;
                    font-size: 18px;

                    &:before {
                        @apply border-be-blue;
                    }

                    &:after {
                        @apply bg-be-blue;
                    }
                }
            }

            &:not(:checked) {
                + label {
                    @apply text-bg-gray-2;
                    font-size: 18px;

                    &:before {
                        @apply border-bg-gray-2;
                    }

                    &:after {
                        @apply opacity-0;
                    }
                }
            }
        }
    }

    + .b2-add-another {
        @apply font-body font-semibold text-be-blue mr-0;
        font-size: 16px;

        span {
            @apply font-heavy;
            font-size: 21px;
        }
    }
}

.b2-per-pax-quantity {
    @apply flex items-center;
    margin-left: 25px;
}

.b2-pax-cell {
    @apply flex flex-col justify-center border-b border-r border-solid border-bg-gray-4;
    padding: 25px 0 25px 35px;
}

.b2-free-cell {
    @apply flex flex-col items-center justify-center border-b border-r border-solid border-bg-gray-4;
    padding: 25px 20px;

    // TODO B2 Make this better
    &.b2-sold-out {
        div,
        [type="radio"]:disabled + label {
            @apply text-bg-gray-2;
        }
    }
}

.b2-pax-grid {
    &:last-child {
        .b2-pax-cell {
            @apply border-b-0;
        }

        .b2-free-cell {
            @apply border-b-0;
        }

        .b2-paid-cell {
            @apply border-b-0;
        }
    }
}

.b2-paid-cell {
    @apply flex flex-col items-center justify-center border-b border-solid border-bg-gray-4;
    padding: 25px 20px;

    // TODO B2 Make this better
    &.b2-sold-out {
        div,
        [type="radio"]:disabled + label {
            @apply text-bg-gray-2;
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .b2-journey-heading {
        height: 47px;
        font-size: 16px;

        i {
            font-size: 31px;
            margin-right: 13px;
        }
    }

    .b2-pax-info {
        font-size: 14px;
        line-height: 16px;
    }

    .b2-pax-name {
        font-size: 16px;
        line-height: 19px;
    }

    .b2-pax-option-info {
        font-size: 13px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
        }
    }

    .b2-pax-option {
        [type="radio"] {
            &:not(:checked),
            &:checked {
                + label {
                    font-size: 16px;
                }
            }

            &:disabled {
                &:not(:checked),
                &:checked {
                    + label {
                        font-size: 16px;
                    }
                }
            }
        }

        + .b2-add-another {
            font-size: 14px;

            span {
                font-size: 19px;
            }
        }
    }

    .b2-per-pax-quantity {
        margin-left: 16px;
    }

    .b2-pax-cell {
        padding: 21px 0 21px 21px;
    }

    .b2-free-cell {
        padding: 21px 5px;
    }

    .b2-paid-cell {
        padding: 21px 5px;
    }
}

@media #{$medium} {
    .b2-journey-heading {
        height: 35px;
        font-size: 12px;

        i {
            font-size: 25px;
            margin-right: 10px;
        }
    }

    .b2-pax-info {
        font-size: 10px;
        line-height: 12px;
    }

    .b2-pax-name {
        font-size: 12px;
        line-height: 15px;
    }

    .b2-pax-option-info {
        font-size: 11px;
        margin-bottom: 8px;

        span {
            font-size: 15px;
        }
    }

    .b2-pax-option {
        [type="radio"] {
            &:not(:checked),
            &:checked {
                + label {
                    font-size: 12px;
                }
            }

            &:disabled {
                &:not(:checked),
                &:checked {
                    + label {
                        font-size: 12px;
                    }
                }
            }
        }

        + .b2-add-another {
            font-size: 11px;

            span {
                font-size: 15px;
            }
        }
    }

    .b2-per-pax-quantity {
        margin-left: 12px;
    }

    .b2-pax-cell {
        padding: 15px 0 15px 15px;
    }

    .b2-free-cell {
        padding: 15px 4px;
    }

    .b2-paid-cell {
        padding: 15px 4px;
    }
}

@media #{$small} {
    .b2-journey-heading {
        height: 31px;
        font-size: 11px;

        i {
            font-size: 19px;
            margin-right: 12px;
        }
    }

    .b2-pax-info {
        font-size: 10px;
        line-height: 12px;
    }

    .b2-pax-name {
        font-size: 11px;
        line-height: 13px;
    }

    .b2-pax-option-info {
        font-size: 11px;
        margin-bottom: 0px;

        span {
            font-size: 12px;
        }
    }

    .b2-pax-option {
        [type="radio"] {
            &:not(:checked),
            &:checked {
                + label {
                    font-size: 11px;
                }
            }

            &:disabled {
                &:not(:checked),
                &:checked {
                    + label {
                        font-size: 11px;
                    }
                }
            }
        }

        [type="radio"] {
            &:not(:disabled),
            &:disabled {
                + label {
                    font-size: 11px;
                }
            }
        }

        + .b2-add-another {
            font-size: 10px;

            span {
                font-size: 13px;
            }
        }
    }

    .b2-per-pax-quantity {
        margin-left: 13px;
    }

    .b2-pax-cell {
        padding: 15px 0 15px 20px;
    }

    .b2-free-cell {
        padding: 15px 5px;
    }

    .b2-paid-cell {
        padding: 15px 5px;
    }
}

@media #{$xsmall} {
    .b2-baggage-page {
        .b2-pax-option {
            max-width: 94%;
            [type="radio"] {
                &:not(:checked),
                &:checked {
                    + label {
                        @apply static;

                        &:before,
                        &:after {
                            top: 50%;
                            left: 88%;
                        }
                    }
                }
            }
        }
    }
    .b2-journey-heading {
        height: 31px;
        font-size: 11px;

        i {
            font-size: 19px;
            margin-right: 12px;
        }
    }

    .b2-pax-info {
        font-size: 10px;
        line-height: 12px;
    }

    .b2-pax-name {
        font-size: 11px;
        line-height: 13px;
    }

    .b2-pax-option-info {
        font-size: 11px;
        margin-bottom: 0px;

        span {
            font-size: 12px;
        }
    }

    .b2-pax-option {
        [type="radio"] {
            &:not(:checked),
            &:checked {
                + label {
                    font-size: 11px;
                }
            }

            &:not(:disabled),
            &:disabled {
                + label {
                    font-size: 11px;
                }
            }
        }

        + .b2-add-another {
            font-size: 10px;

            span {
                font-size: 13px;
            }
        }
    }

    .b2-per-pax-quantity {
        margin-left: 13px;
    }

    .b2-pax-cell {
        padding: 15px 0 15px 20px;
    }

    .b2-free-cell {
        padding: 15px 5px;
    }

    .b2-paid-cell {
        padding: 15px 5px;
    }
}
