.error-wrapper {
    position: relative;
    margin: 50px 0 44px 0;
    padding: $common-gap;
    z-index: 40;
    background: $alert-bg;
    border-radius: $border-radius-lg;
    color: $alert-color;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.15);

    &.condensed {
        margin: 0 0 $common-gap;
    }

    h4 {
        font-weight: $semibold-font-weight;
    }
}

.p2-installment-message {
    @apply relative flex w-full items-center justify-center text-white;
    margin: 5px 0 8px;
    padding: 10px;
    border-radius: 10px;
    background-color: #f0ad4e;
    font-size: 14px;
}

.full-width-information {
    @apply relative flex w-full items-center justify-start;

    margin-top: 5px;
    margin-bottom: 8px;
    padding: 10px;
    border-radius: $border-radius-lg;
    background-color: $warning-bg-color;
    color: #fff;
    font-size: $font-size-small;

    &.push-down {
        margin-top: 30px;
    }

    span {
        font-size: $font-size-small;
        font-weight: $semibold-font-weight;
        line-height: 18px;
    }

    i {
        padding-right: 10px;
    }

    &.voucher-result {
        margin-top: $common-gap;
    }

    &.cug-register {
        background: rgba($brand-tertiary, 0.2);
        color: $brand-secondary;
        margin-top: 20px;

        i {
            color: $brand-tertiary;
        }
    }

    &.credit-shell {
        border-color: $brand-tertiary;
        background: $brand-tertiary;
        margin-bottom: 20px;
        line-height: 1.6;
    }

    &.no-flights {
        @apply justify-center;
    }
}

.flightless {
    .full-width-information {
        margin: 0;
    }
}

.extras-page {
    .full-width-information {
        margin-top: $small-gap;
    }
}

.error-message-container {
    width: 100%;
    text-align: center;

    &.text-left {
        text-align: left;
    }

    &.text-right {
        text-align: right;
    }

    .form-error-message,
    > span {
        display: inline-block;
        padding: 10px 25px;
        background: $alert-bg;
        color: $alert-color;
        font-size: $font-size-small;
        margin: $small-gap auto 20px;
        border-radius: $border-radius-sm;
    }
}

.staff-exclamation {
    @apply relative rounded-lg bg-be-red text-white;
    padding: 10px 25px 10px 50px;
    text-align: left;

    &:after {
        @apply absolute font-semibold vertical-center;
        left: 10px;
        font-size: 30px;
        content: "\e943"; // DEVNOTE Might change if icons change
        font-family: "jetsmart-v2";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

/* MEDIA QUERIES */

@media #{$xsmall} {
    .full-width-information {
        padding: 15px;

        p {
            margin: 0;
            padding: 0 0 0 $common-gap;
            line-height: 16px;
            text-align: left;
        }
    }
}
