@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.special-assistance-checkbox-wrapper {
    @apply flex select-none items-center bg-white;

    height: 35px;
    margin: 12px 0 15px;
    padding-left: 15px;
    border: 1px $brand-secondary solid;
    border-radius: 10px;

    .special-assistance-label {
        @apply relative m-0 flex w-full cursor-pointer select-none items-center justify-start bg-transparent font-bold;

        height: 35px;
        padding-left: 25px;
        border-radius: 10px;

        &:before {
            @apply absolute left-0 cursor-pointer overflow-hidden bg-transparent;

            content: "";
            height: 16px;
            width: 16px;
            border-radius: 5px;
            border: 2px solid $brand-tertiary;
            z-index: 1;
        }

        &:after {
            @apply absolute left-0;

            content: "";
            top: unset;
            right: unset;
            height: 16px;
            width: 16px;
            border-radius: 5px;
        }
    }

    .special-assistance-checkbox-input {
        @apply absolute m-0 h-0 w-0 appearance-none border-none p-0 opacity-0;
    }

    .special-assistance-checkbox-input {
        &:disabled {
            ~ .special-assistance-label {
                @apply pointer-events-none;

                .cb-title {
                    opacity: 0.5;
                }
            }
        }
    }

    .special-assistance-checkbox-input {
        &:disabled {
            ~ .special-assistance-label {
                &:after {
                    @apply absolute rounded-full;
                    margin-left: 3px;
                    height: 10px;
                    width: 10px;
                    border-top: 2px solid $checkbox-after-border-color;
                    border-right: 2px solid transparent;
                    background: unset;
                    animation: spinner 0.6s linear infinite;
                }
            }
        }
    }
}
