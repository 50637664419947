/*
 * Global
 */

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

$screen-xxs-min: 20rem !default;
$screen-xxs-max: $screen-xs-min - calc(1 / $rem-value) !default;
$xxsmall: #{"(max-width: #{$screen-xxs-max})"} !default;

@font-face {
    font-family: ClanOT-UltraItalic;
    font-weight: bold;
    src:
        url("../fonts/ClanOT-UltraItalic.otf") format("opentype"),
        url("../fonts/ClanOT-UltraItalic.woff") format("woff");
}

@font-face {
    font-family: ClanOT-Bold;
    font-weight: bold;
    src:
        url("../fonts/ClanOT-Bold.otf") format("opentype"),
        url("../fonts/ClanOT-Bold.woff") format("woff");
}

@font-face {
    font-family: ClanOT-Book;
    font-weight: bold;
    src:
        url("../fonts/ClanOT-Book.otf") format("opentype"),
        url("../fonts/ClanOT-Book.woff") format("woff");
}

@font-face {
    font-family: ClanOT-News;
    font-weight: normal;
    src:
        url("../fonts/ClanOT-News.otf") format("opentype"),
        url("../fonts/ClanOT-News.woff") format("woff");
}

@font-face {
    font-family: ClanOT-Medium;
    font-weight: normal;
    src:
        url("../fonts/ClanOT-Medium.otf") format("opentype"),
        url("../fonts/ClanOT-Medium.woff") format("woff");
}

@font-face {
    font-family: ClanOT-Black;
    font-weight: normal;
    src:
        url("../fonts/ClanOT-Black.otf") format("opentype"),
        url("../fonts/ClanOT-Black.woff") format("woff");
}

@font-face {
    font-family: ClanOT-Ultra;
    font-weight: normal;
    src: url("../fonts/ClanOT-Ultra.otf") format("opentype");
}

@font-face {
    font-family: Gotham-Bold;
    font-weight: normal;
    src: url("../fonts/Gotham-Bold.otf") format("opentype");
}

@font-face {
    font-family: Gotham-Book;
    font-weight: normal;
    src: url("../fonts/Gotham-Book.otf") format("opentype");
}

.change-flow-ribbon,
.change-route-ribbon,
.checkin-ribbon {
    background: $brand-primary;
    color: #fff;
    left: 0;
    right: 0;
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    z-index: 100;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 345px;
}

.checkin-ribbon {
    background: $brand-tertiary;
}

.change-route-ribbon {
    background: $brand-primary;
}

.nav-container {
    &.full-width {
        .change-flow-ribbon,
        .checkin-ribbon,
        .change-route-ribbon {
            padding-left: 0;
        }
    }
}

body,
html {
    font-family: "Lato", sans-serif;
    font-weight: $regular-font-weight;
    font-size: 1rem;
    line-height: 1rem;
    max-width: 100%;
}

body {
    overflow-x: hidden;
}

.body-background {
    background: $gray-lightest !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
}

a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

a {
    color: white;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;

    &:active,
    &:hover {
        text-decoration: none;
    }
}

p {
    margin-bottom: 1rem;
    line-height: 1.6rem;
    font-size: 1rem;
    white-space: normal;
    font-family: "Lato", sans-serif;
}

strong {
    font-weight: 900;
}

.breakable {
    word-break: break-all;
}

// pico overwrite
.container {
    position: relative;
}

.content-wrapper {
    padding-top: $js-header-height;
    margin-left: 345px;
    margin-right: 45px;
    min-height: calc(100vh - 463px);
    z-index: 0;
    max-width: 1155px;
    transition: all 0.25s ease-in-out;

    &.condensed {
        margin: 0 auto;
    }

    &.with-breadcrumbs {
        padding-top: calc($js-header-height + 64px);

        &.with-ribbon {
            padding-top: calc($js-header-height + 86px);
        }
    }
}

.elevated-row {
    position: relative;
    z-index: 1;

    &:nth-child(2) {
        z-index: 10;
    }
}

// custom theme specific css
.row-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.custom-alert {
    padding-top: 5px;
    color: #fff;
    font-size: 30px;
}

.chevron-down {
    margin-right: $common-gap;
    margin-top: 5px;
    color: $brand-secondary;
    font-size: 25px;
    width: 25px;
    height: 25px;
    -moz-transform: rotate(-270deg);
    -o-transform: rotate(-270deg);
    -webkit-transform: rotate(-270deg);
    transform: rotate(-270deg);
}

.title-icon {
    color: $brand-tertiary;
    font-size: 50px;
}

.emphasis {
    color: $brand-primary;
    font-weight: $semibold-font-weight;
}

.non-breaking-price {
    position: relative;
    left: -15px;
    white-space: nowrap;
}

.nowrap {
    white-space: nowrap !important;
}

.nav-container {
    &.full-width {
        .change-flow-ribbon,
        .be-ribbon {
            padding-left: 0;
        }
    }
}

.is-past {
    opacity: 0.33;
    pointer-events: none;
}

[type="checkbox"] + label[for] {
    height: 35px;
    line-height: 35px;
    font-size: $font-size-small;
    cursor: pointer;
}

/* MEDIA QUERIES */

@media #{$large-down} {
    .content-wrapper {
        margin-left: 345px;
        margin-right: 25px;
    }
}

@media #{$small} {
    .content-wrapper {
        margin-left: 25px;
    }
}

@media #{$small-down} {
    .mobile-sidebar-opened {
        &:after {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 1;
        }
    }

    .checkin-ribbon {
        padding-left: 0;
    }

    .change-flow-ribbon,
    .change-route-ribbon {
        padding-left: 0;
        top: 61px;
    }

    html,
    body {
        height: unset;
    }

    body {
        display: block;
    }
}

@media #{$xsmall} {
    .content-wrapper {
        padding-left: 0px;
        padding-right: 0px;
        margin: 0;
        z-index: 1;
    }
}
