.seatmap-info {
    @apply h-full;
    top: 25px;
    left: 25px;
    right: 490px;

    .full-width-information {
        &.info {
            @apply text-brand-secondary;
            background: rgba($brand-tertiary, 0.2);

            .custom-alert {
                @apply text-brand-tertiary;
            }
        }
    }

    .inner-border-box {
        @apply m-0;
        padding: 15px;
    }
}

.seatmap-info-content {
    padding: 15px;
    border-radius: 10px;
    background: #f7f7f7;
}

.seatmap-info-title {
    @apply font-body font-black text-brand-secondary;
    font-size: 18px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .seatmap-info {
        right: 365px;
    }
}

@media #{$medium-down} {
    .seatmap-info {
        display: none;
    }

    .banco-estado-56 {
        .seatmap-info {
            @apply relative block;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;
            font-size: 14px;

            > span {
                font-size: 14px;
            }
        }
    }
}
