@import "./giftcard-colors";

.giftcard-transaction-button-container {
    @apply flex justify-end;
    padding: 20px;
    white-space: nowrap;

    &.push-right {
        @apply pr-0;
    }

    a {
        &:first-child {
            margin-right: 10px;
        }
    }
}

.giftcard-options {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 200px;

    a:not(.btn) {
        color: $giftcard-blue;
        text-decoration: underline;
        font-family: "Lato", sans-serif;
        font-size: 18px;
        cursor: pointer;

        &:hover {
            color: $giftcard-red;
            text-decoration: none;
        }
    }
}

.send-giftcard-email-container {
    position: absolute;
    top: 70px;
    left: 0;
    background: $giftcard-blue;
    border-radius: 5px;
    width: 395px;
    max-width: 100%;
    min-height: 165px;
    padding: 15px 25px;
    box-shadow: $box-shadow-medium;
    display: none;

    .emailSendingClose {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 18px;
        color: #fff;
        padding: 5px;
        background: transparent;
        border: none;
        cursor: pointer;

        &:hover {
            color: $giftcard-cyan;
            background: transparent;
            border: none;
        }
    }

    &.open {
        display: block;
    }

    &:after {
        content: "";
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 20px;
        height: 20px;
        background: $giftcard-blue;
    }

    .send-giftcard-title {
        color: #fff;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        margin-bottom: 15px;
    }

    input {
        padding: 15px 10px;
        border-radius: 5px;
        background: #fff;
        color: $giftcard-darkgray;
        margin-bottom: 15px;
    }

    .primary-btn {
        width: 95px;
        height: 40px;
        margin: 0;
        padding: 0;
        border-radius: 5px;

        &:hover {
            background: $giftcard-cyan;
        }
    }
}

.send-giftcard-email-success,
.send-giftcard-email-error {
    color: $giftcard-cyan;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    margin: 15px 0 0 0;
    line-height: 1.4;
}

.giftcard-image {
    margin-top: 30px;

    img {
        display: block;
    }

    &.second-card {
        position: relative;
        top: 10px;
        left: -10px;
        border: 1px solid $giftcard-cyan;
        border-radius: 27px;

        &.for-gift {
            border-color: $giftcard-blue;

            &:before {
                border-color: $giftcard-blue;
                background: $giftcard-cyan;
            }

            &:after {
                border-color: $giftcard-blue;
                background: $giftcard-cyan;
            }
        }

        &:before {
            position: absolute;
            content: "";
            left: 10px;
            right: -10px;
            top: -10px;
            bottom: 10px;
            border: 1px solid $giftcard-cyan;
            border-radius: 27px;
            background: $giftcard-blue;
            z-index: -1;
        }
    }

    &.third-card {
        &:after {
            position: absolute;
            content: "";
            left: 20px;
            right: -20px;
            top: -20px;
            bottom: 20px;
            border: 1px solid $giftcard-cyan;
            border-radius: 27px;
            background: #21365a;
            z-index: -2;
        }
    }
}

.gc-itinerary-quantity {
    margin: 30px 0 15px 0;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: $font-size-medium;
    color: $giftcard-blue;

    span {
        font-weight: 600;
        font-style: italic;
        color: $giftcard-blue;
    }
}

/* MEDIA QUERIES */
@media #{$large} {
    .giftcard-options {
        a {
            &:not(.btn) {
                max-width: 43%;
            }
        }
    }
}

@media #{$medium} {
    .giftcard-options {
        @apply flex-wrap;

        a {
            &:not(.btn) {
                width: 100%;
                min-width: 100%;
                margin: 22px 0 0;
            }
        }
    }

    .giftcard-transaction-button-container {
        &.push-right {
            @apply w-full;
            padding: 22px 0;
        }
    }
}

@media #{$small} {
    .giftcard-options {
        flex-wrap: wrap;

        a {
            &:not(.btn) {
                width: 100%;
                min-width: 100%;
                margin: 22px 0 0;
            }
        }
    }

    .giftcard-transaction-button-container {
        &.push-right {
            @apply w-full;
            padding: 22px 0;
        }
    }

    .send-giftcard-email-container {
        top: 50px;
    }
}

@media #{$xsmall} {
    .giftcard-options {
        flex-wrap: wrap;

        a {
            &:not(.btn) {
                width: 100%;
                min-width: 100%;
                margin: 15px 0 0;
                font-size: 10px;
                text-align: center;
            }
        }
    }

    .giftcard-transaction-button-container {
        @apply flex flex-col-reverse items-center;

        &.push-right {
            @apply w-full;
            padding: 15px 0;
        }

        a {
            margin: 0 0 10px 0;

            &:first-child {
                @apply mr-0;
            }
        }
    }

    .send-giftcard-email-container {
        top: 38px;
        left: 50%;
        transform: translateX(-50%);
    }

    .gc-itinerary-quantity {
        margin: 30px 15px 15px;
    }

    .giftcard-image {
        width: 313px;
        margin-left: auto;
        margin-right: auto;

        &.second-card {
            width: 340px;
        }
    }

    .itinerary-giftcard-container {
        margin: 0 15px;
    }
}
