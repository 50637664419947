@media #{$medium-down} {
    .seatmap-pax-plane {
        @apply text-new-ui-turqoise;
        font-size: 20px;
        margin: 0 11px;
    }

    .mobile-seat {
        @apply block font-semibold;
        text-align: center;
        margin-top: 5px;
        font-size: 14px;
    }

    .mobile-passengers {
        @apply relative flex flex-nowrap overflow-x-auto items-stretch bg-white;
        -webkit-overflow-scrolling: touch;
        z-index: 1;

        &::-webkit-scrollbar {
            display: none;
        }

        &.division-1 {
            ac-mobile-seat-passenger {
                @apply w-full;
            }
        }

        &.division-2 {
            @apply whitespace-nowrap;

            ac-mobile-seat-passenger {
                @apply inline-block m-0;
                width: calc(50% - 10px);
                max-width: calc(100% - 70px);

                + ac-mobile-seat-passenger {
                    margin-left: 20px;
                }
            }
        }
    }

    .mobile-passenger-container {
        @apply flex items-center justify-center relative bg-white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        z-index: 1;
        margin-bottom: 16px;

        .journey {
            @apply w-1/2 flex items-center font-body font-semibold justify-start text-brand-secondary;
            height: 23px;
            font-size: 15px;

            > span {
                @apply flex font-body font-semibold h-full items-center;
                width: calc(100% - 10px);
            }

            &:first-child {
                @apply border-r border-solid border-be-blue;
            }

            &:last-child {
                @apply border-r-0;
            }

            &:nth-child(2) {
                > span {
                    margin-left: 10px;
                }
            }

            &.selected {
                > span {
                    @apply border-be-blue border-b border-solid;
                }
            }

            .direction {
                @apply font-semibold text-brand-primary;
            }
        }

        .direction-switch {
            font-size: 22px;

            &.no-click {
                @apply cursor-default pointer-events-none;
            }
        }
    }

    .cf-mobile-header-segments {
        @apply flex;
        margin-bottom: 16px;
    }

    .cf-mobile-segment-flight-number {
        @apply bg-new-ui-gray text-white rounded font-body font-extrabold;
        padding: 5px 10px;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .cf-mobile-segment-endpoint {
        @apply font-semibold font-body text-new-ui-gray flex items-center leading-none;
        font-size: 14px;

        .seatmap-pax-plane {
            @apply text-new-ui-gray;
        }

        &:last-child {
            margin-top: 5px;
        }
    }

    .cf-mobile-header-segment {
        @apply flex flex-col items-center justify-center w-1/2;

        &:first-child {
            @apply border-r border-solid border-new-ui-dark-cyan;
        }

        &.active {
            .cf-mobile-segment-flight-number {
                @apply bg-new-ui-dark-cyan;
            }

            .cf-mobile-segment-endpoint {
                @apply text-new-ui-dark-cyan;

                .seatmap-pax-plane {
                    @apply text-new-ui-dark-cyan;
                }
            }
        }
    }

    ac-mobile-seat-passenger {
        @apply border border-solid border-new-ui-gray relative;
        border-radius: 3px;
        width: 295px;
        height: 50px;
        flex: 0 0 auto;

        label {
            display: none;
        }

        + ac-mobile-seat-passenger {
            margin-left: 16px;
        }
    }

    .mobs-discount-info {
        @apply font-emphasis leading-none italic text-new-ui-gray absolute flex items-start justify-center whitespace-nowrap;
        font-size: 10px;
        bottom: 5px;
        right: 16px;
        width: 50px;
        text-align: center;
    }

    .mobs-name {
        @apply font-body font-normal text-new-ui-blue absolute;
        left: 16px;
        top: 10px;
        width: calc(100% - 82px);
        font-size: 12px;
    }

    .mobs-seat {
        @apply absolute flex items-center justify-center font-body font-bold border border-solid border-new-ui-gray;
        font-size: 16px;
        width: 50px;
        height: 18px;
        border-radius: 3px;
        top: 10px;
        right: 16px;

        &.selected {
            @apply bg-new-ui-gray text-white;
        }
    }

    .free-seat-info {
        max-width: unset;
    }

    .banco-estado-56 {
        .be2-seats-panel {
            padding: 20px 15px;
        }

        .be2-free-seats-panel {
            @apply pt-0 pb-0;
            height: 80px;

            > i {
                font-size: 40px;
            }
        }

        .free-seat-images {
            min-width: 140px;
        }
    }
}

@media #{$small} {
    .mobile-passenger-container {
        @apply justify-start;

        .journey {
            @apply w-auto;
            max-width: 50%;

            > span {
                @apply w-auto whitespace-nowrap;
                padding: 10px 0;
                margin-right: 10px;
            }
        }
    }

    .free-seat-info {
        font-size: 13px;

        span {
            font-size: 13px;
        }
    }

    .banco-estado-56 {
        .be2-free-seats-panel {
            > i {
                font-size: 38px;
            }
        }

        .free-seat-images {
            i {
                font-size: 28px;
            }
        }
    }
}

@media #{$xsmall} {
    ac-mobile-seat-passenger {
        height: 82px;
        width: 50%;
        min-width: 145px;
    }

    .mobs-discount-info {
        font-size: 14px;
        bottom: 16px;
        right: 10px;
        width: 50px;
        height: 28px;
    }

    .mobs-seat {
        right: 10px;
    }

    .mobs-name {
        @apply whitespace-normal;
        left: 10px;
        top: 10px;
        width: calc(100% - 70px);
    }

    .cf-mobile-segment-endpoint {
        @apply block;
    }

    .mobile-passenger-container {
        @apply overflow-x-auto w-full justify-start;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }

        .journey {
            @apply whitespace-nowrap;
            width: unset;
            font-size: 12px;

            &:first-child {
                padding-right: 10px;
                margin-right: 10px;
            }

            > span {
                @apply w-auto;
            }
        }
    }

    .seat-pax-name {
        @apply whitespace-normal;
        font-size: 13px;
    }

    .free-seat-info {
        font-size: 10px;
        line-height: 1.2;

        > div {
            @apply leading-none;
            font-size: 10px;
        }

        span {
            @apply leading-none;
            font-size: 10px;
        }
    }

    .discounted-seat-info {
        font-size: 10px;
        line-height: 1.2;

        > div {
            @apply leading-none;
            font-size: 10px;
        }

        span {
            @apply leading-none;
            font-size: 10px;
        }
    }

    .banco-estado-56 {
        .be2-seats-panel {
            padding: 10px 8px;
            border-radius: 8px;
        }

        .be2-discounted-seats-panel,
        .be2-free-seats-panel {
            height: unset;

            > i {
                font-size: 30px;
                margin-right: 7px;
            }
        }

        .free-seat-images {
            display: none;
        }
    }
}
