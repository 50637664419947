.review-page {
    .itinerary-navigation {
        margin: 50px 0 30px;
    }

    .flight-container-title {
        margin: 0 0 20px 0;
        font-weight: 400;
    }

    .flight-chart,
    .flight-mobile-chart {
        margin-bottom: $common-gap;

        &:last-child {
            margin-bottom: 0;
        }

        .flight-chart-body,
        .flight-chart-row {
            .flight-chart-cell {
                padding: 10px;
                vertical-align: middle;

                span {
                    display: block;
                    line-height: 22px;

                    &.first-name {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .flight-mobile-chart {
        .flight-chart-row:nth-child(even) {
            .flight-chart-cell {
                font-weight: 400;
                background: #fff;
            }
        }
    }

    .inner-deep-box-header {
        margin-top: $common-gap;
        padding: 30px 15px;
        border-radius: $border-radius-lg;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background: $brand-secondary;
        color: $gray-lightest;
        font-size: 18px;
        font-weight: 600;
    }

    .inner-deep-box {
        border-radius: 0;
        padding: 15px;
        background: $gray-lightest;

        &:last-child {
            border-bottom-left-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            margin-bottom: $common-gap;
        }
    }

    .station-names {
        color: $brand-primary;
        font-weight: 600;
    }
}

.review-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &.wide {
        justify-content: space-between;
    }
}

.extras-changes {
    .flight-chart {
        .flight-chart-body {
            .flight-chart-cell {
                width: 33.3333%;
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium-down} {
    .review-button-container {
        &.wide {
            flex-direction: column;
            align-items: flex-end;

            .payment-checkbox-terms-wrapper {
                &.payment {
                    margin-right: 0;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .terms-container {
                margin-bottom: 10px;
            }
        }
    }
}

@media #{$xsmall} {
    .review-page {
        .booking-wrapper {
            padding: 25px 0;
        }
    }
}
