.be-bundles-bar-details {
    @apply relative mx-auto flex items-center justify-between border-b border-l border-r border-t-0 border-solid border-be-gray-3 border-opacity-0 bg-white font-body italic text-be-gray-3;
    width: 963px;
    max-width: 95%;
    height: 0;
    padding: 0 41px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;

    i {
        font-size: 30px;
        margin-right: 10px;
    }

    .bbbd-header {
        @apply font-body font-black not-italic leading-none;
        max-width: 145px;
        font-size: 15px;

        span {
            @apply font-body font-black not-italic leading-none;
            font-size: 13px;
        }
    }

    .bbbd-body {
        @apply font-body font-normal italic leading-none;
        max-width: 145px;
        font-size: 13px;
    }

    .bbbd-section {
        @apply flex items-center;
    }

    .category-5 {
        i,
        .bbbd-header,
        .bbbd-body {
            @apply text-be-orange;
        }
    }
}

.be-bundles-bar {
    @apply relative mx-auto mb-2 mt-2 flex cursor-pointer items-center justify-between whitespace-nowrap font-body font-semibold text-white;
    height: 60px;
    width: 963px;
    max-width: 95%;
    font-size: 20px;
    border-radius: 7px;
    padding: 0 25px;
    transition: all 0.25s ease-in-out;

    img {
        @apply relative;
        top: 2px;
        height: 18px;
        margin-left: 5px;
        transition: all 0.25s ease-in-out;
    }

    .bbb-benefit-icons {
        @apply flex flex-auto items-center justify-between font-normal;
        font-size: 34px;
        margin: 0 40px;
        transition: all 0.25s ease-in-out;
    }

    .bbb-opener {
        font-size: 36px;
        transition: all 0.25s ease-in-out;

        &.smaller {
            font-size: 17px;
        }
    }

    &.open {
        height: 36px;
        font-size: 17px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0 24px;
        margin-bottom: 0;

        img {
            height: 14px;
            margin-left: 2px;
        }

        .bbb-benefit-icons {
            @apply opacity-0;
            font-size: 23px;
        }

        .bbb-opener {
            font-size: 23px;
            right: 16px;
            transform: rotate(90deg);

            &.smaller {
                font-size: 12px;
                transform: rotate(-90deg);
            }
        }

        ~ .be-bundles-bar-details {
            @apply mb-2 border-opacity-100;
            height: 75px;
            padding: 0 24px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .be-bundles-bar {
        padding: 0 15px;
        height: 60px;
        font-size: 18px;

        &.open {
            padding: 0 15px;
        }

        img {
            height: 16px;
            top: 1px;
            margin-left: 2px;
        }

        .bbb-benefit-icons {
            font-size: 28px;
            max-width: 250px;
            margin: 0 20px;
        }

        .bbb-opener {
            font-size: 28px;
        }
    }

    .be-bundles-bar-details {
        .bbbd-header {
            font-size: 13px;

            span {
                font-size: 12px;
            }
        }

        &.open {
            ~ .be-bundles-bar-details {
                padding: 0 15px;
            }
        }

        .bbbd-body {
            max-width: 110px;
        }

        i {
            margin-right: 7px;
        }
    }
}

@media #{$medium} {
    .be-bundles-bar {
        padding: 0 10px;
        height: 48px;
        font-size: 14px;

        &.open {
            padding: 0 10px;
            font-size: 14px;

            ~ .be-bundles-bar-details {
                padding: 0 10px;

                i {
                    font-size: 24px;
                }
            }
        }

        img {
            height: 13px;
            top: 1px;
            margin-left: 2px;
        }

        .bbb-benefit-icons {
            font-size: 28px;
            max-width: 250px;
            margin: 0 20px;
        }

        .bbb-opener {
            font-size: 28px;
        }
    }

    .be-bundles-bar-details {
        .bbbd-header {
            font-size: 11px;

            span {
                font-size: 12px;
            }
        }

        &.open {
            ~ .be-bundles-bar-details {
                padding: 0 15px;
            }
        }

        .bbbd-body {
            max-width: 110px;
            font-size: 11px;
        }

        i {
            margin-right: 7px;
        }
    }
}

@media #{$small} {
    .be-bundles-bar {
        padding: 0 25px;
        height: 48px;
        font-size: 14px;

        &.open {
            padding: 0 10px;
            font-size: 14px;

            ~ .be-bundles-bar-details {
                padding: 0 10px;
                height: 55px;

                i {
                    font-size: 24px;
                }
            }
        }

        img {
            height: 12px;
            top: 1px;
            margin-left: 2px;
        }

        .bbb-benefit-icons {
            font-size: 21px;
            max-width: 160px;
            margin: 0 20px;
        }

        .bbb-opener {
            font-size: 21px;
        }
    }

    .be-bundles-bar-details {
        @apply justify-center;

        .bbbd-section {
            padding: 0 15px;

            &:not(:last-child):not(.category-5) {
                @apply flex items-center border-r border-solid border-be-gray-3;
                height: 32px;
            }

            &:not(.category-5) {
                i {
                    display: none;
                }
            }

            &.category-5 {
                @apply flex items-center;
            }
        }

        .bbbd-header {
            font-size: 10px;

            span {
                font-size: 10px;
            }
        }

        &.open {
            ~ .be-bundles-bar-details {
                padding: 0 15px;
            }
        }

        .bbbd-body {
            font-size: 9px;
        }
    }
}

@media #{$xsmall} {
    .be-bundles-bar {
        height: 70px;

        &.open {
            .bbb-opener {
                right: 4px;
                font-size: 25px;
            }

            ~ .be-bundles-bar-details {
                height: 129px;
                padding: 10px;

                &.be-category-5 {
                    height: 87px;
                }
            }
        }

        .be-bundles-bar-caption {
            @apply absolute whitespace-normal;
            top: 7px;
            left: 4px;
            font-size: 11px;
            width: unset;
            line-height: 1.2;

            img {
                height: 9px;
                margin-left: 2px;
                top: 1px;
            }
        }

        .bbb-opener {
            @apply absolute;
            font-size: 25px;
            top: 4px;
            right: 4px;
        }

        .bbb-benefit-icons {
            @apply absolute m-0;
            font-size: 26px;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);

            i {
                margin-right: 15px;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }

    .be-bundles-bar-details {
        @apply flex-col flex-wrap items-start justify-start;

        i {
            font-size: 20px;
        }

        .bbbd-section {
            @apply flex items-center;

            &:not(.category-5) {
                i {
                    display: inline;
                }
            }
        }

        .bbbd-header {
            font-size: 10px;

            &:first-child {
                margin-top: 4px;
            }
        }

        .bbbd-body {
            font-size: 9px;
            margin-bottom: 4px;
        }
    }
}
