@mixin divider($color) {
    @apply absolute;
    background-color: $color;
    content: "";
    top: 0;
    left: 8px;
    right: 8px;
    height: 1px;
    z-index: 10;
}

.cug2b-sidebar {
    @apply bg-cug-dark-gray text-white h-full py-20;
    width: 275px;
    border-bottom-left-radius: 10px;
    text-align: center;

    &.rounded {
        border-top-left-radius: 10px;
    }
}

.cug2b-sidebar-item {
    @apply w-full flex items-center px-12 py-4 bg-cug-dark-gray text-white relative;
    text-align: left;
    transition: all 0.25s ease-in-out;

    i {
        @apply mr-6;
        font-size: 44px;
    }

    span {
        @apply font-body font-bold;
        font-size: 20px;
        line-height: 24px;
    }

    &.condensed {
        span {
            letter-spacing: -1px;
        }
    }

    &:not(.active) {
        @apply cursor-pointer;

        &:hover {
            @apply bg-cug-light-gray text-cug-dark-gray;
        }

        &:focus {
            @apply bg-cug-dark-gray text-white;

            &:hover {
                @apply bg-cug-light-gray text-cug-dark-gray;
            }
        }
    }

    &.active {
        @apply bg-white text-cug-dark-gray cursor-default pointer-events-none;

        &:hover {
            @apply bg-white text-cug-dark-gray;
        }

        &:after {
            @apply absolute vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 1rem;
            content: "\e9ba"; // DEVNOTE Might change if icons change
            font-size: 32px;
        }

        &.can-click-active {
            @apply pointer-events-auto cursor-pointer;

            &:hover {
                @apply bg-cug-light-gray text-cug-dark-gray;
            }
        }
    }

    &.pcompra-org-label {
        letter-spacing: -1px;

        &:after {
            right: 0.5rem;
        }
    }
}

.cug2b-sidebar-sub-item {
    @apply w-full flex items-center px-12 py-4 bg-white text-cug-dark-gray relative;
    text-align: left;
    transition: all 0.25s ease-in-out;

    span {
        @apply font-body font-bold;
        font-size: 20px;
        line-height: 24px;
    }

    &:after {
        @apply absolute vertical-center;
        font-family: "jetsmart-v2" !important;
        right: 1.6rem;
        content: "\e919"; // DEVNOTE Might change if icons change
        font-size: 14px;
    }

    &:not(.active) {
        @apply cursor-pointer;

        &:hover {
            @apply bg-cug-light-gray text-cug-dark-gray;
        }

        &:not(.no-border):before {
            @include divider(#2c3438);
        }
    }

    &.active {
        @apply bg-cug-gray-2 text-cug-dark-gray cursor-default;

        &:hover {
            @apply bg-cug-gray-2 text-cug-dark-gray;
        }
    }
}

// MEDIA QUERIES

@media #{$medium} {
    .cug2b-sidebar {
        width: 200px;
    }

    .cug2b-sidebar-item {
        @apply px-6;

        i {
            @apply mr-3;
            font-size: 28px;
        }

        span {
            font-size: 16px;
            line-height: 19px;
        }

        &.active {
            &:after {
                font-size: 21px;
                right: 0.5rem;
            }
        }
    }

    .cug2b-sidebar-sub-item {
        @apply px-6;

        &:after {
            font-size: 9px;
            right: 1rem;
        }

        span {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

@media #{$small} {
    .cug2b-sidebar {
        width: 168px;
    }

    .cug2b-sidebar-item {
        @apply px-6;

        i {
            @apply mr-3;
            font-size: 26px;
        }

        span {
            font-size: 14px;
            line-height: 17px;
        }

        &.active {
            &:after {
                font-size: 19px;
                right: 0.5rem;
            }
        }
    }

    .cug2b-sidebar-sub-item {
        @apply px-6;

        &:after {
            font-size: 9px;
            right: 0.9rem;
        }

        span {
            font-size: 14px;
            line-height: 17px;
        }
    }
}

@media #{$xsmall} {
    .cug2b-sidebar {
        @apply flex w-full flex-wrap p-0 overflow-hidden;
        border-radius: 10px;

        &:not(.rounded) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .cug2b-sidebar-item {
        @apply flex-col inline-flex p-2 justify-center border-solid border-be-blue w-1/3;
        text-align: center;
        height: 75px;

        &:nth-child(1) {
            @apply border-b border-r;
        }
        &:nth-child(2) {
            @apply border-b border-r;
        }
        &:nth-child(3) {
            @apply border-b;
        }
        &:nth-child(4) {
            @apply border-r;
        }
        &:nth-child(5) {
            @apply border-r;
        }

        i {
            @apply m-0;
            font-size: 35px;
            opacity: 0.4;
        }

        span {
            @apply mt-2;
            font-size: 10px;
            line-height: 11px;
            opacity: 0.4;
        }

        &.active {
            @apply bg-transparent text-white;

            i,
            span {
                @apply opacity-100;
            }

            &:after {
                display: none;
            }
        }
    }

    .cug2b-sidebar-sub-item {
        display: none;
    }

    .cug2b-sidebar-item {
        &.pcompra-org-label {
            letter-spacing: 0;
        }
    }
}
