dc-table-grid {
    .dc-table-grid-shell {
        @apply block overflow-hidden;
        border-radius: 10px;
    }

    .dc-table-grid {
        .dctg-header-cell {
            @apply relative bg-cug-dark-gray px-2 font-body font-bold text-white;
            font-size: 15px;
            height: 50px;
            min-width: 60px;

            &:not(.pinned) {
                &:after {
                    @apply absolute right-0 bg-white;
                    content: "";
                    top: 0.5rem;
                    bottom: 0.5rem;
                    width: 1px;
                }
            }

            &.last-column {
                &:after {
                    display: none;
                }
            }
        }

        .dctg-body-cell {
            @apply border-b border-solid border-cug-gray-6 bg-cug-gray-5 text-be-blue-2;
            height: 50px;
            font-size: 13px;

            &.text-center {
                > div {
                    @apply justify-center;
                }
            }

            &.cug2b-cell-ellipsis {
                span {
                    @apply overflow-hidden whitespace-nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .action-cell {
        @apply flex h-full cursor-pointer items-center justify-center;

        &.pcra-action-cell {
            i {
                @apply mx-4;
            }
        }

        i {
            @apply ml-2 text-cug-dark-gray;
            font-size: 18px;

            &.text-red {
                @apply text-be-red;
            }

            &:hover {
                @apply text-be-red;

                &.text-red {
                    @apply text-cug-dark-gray;
                }

                &.cug2b-reset-password-icon {
                    @apply text-be-cyan-2;
                }
            }
        }

        .action-cell-text {
            @apply whitespace-nowrap;

            &:hover {
                @apply text-be-red;
            }
        }

        button:not(.cug2b-modal-closer) {
            button {
                @apply ml-2 whitespace-nowrap;
                min-width: unset;
                font-size: 13px;
                padding: 5px 27px 5px 10px;

                &:after {
                    right: 2px;
                    font-size: 18px;
                }
            }
        }

        .action-cell-user {
            @apply flex h-full items-center justify-center;

            i {
                @apply cursor-pointer text-cug-dark-gray;
                font-size: 18px;

                &:hover {
                    @apply text-be-red;

                    &.cug2b-reset-password-icon {
                        @apply text-be-cyan-2;
                    }
                }
            }
        }

        .material-icons {
            @apply ml-2;
            font-size: 16px;
            // DEVNOTE Sorry, Bauer G. gave it 99 and I have no time to find out why.
            z-index: 0 !important;
        }

        .cug2b-cell-ellipsis {
            span {
                @apply overflow-hidden whitespace-nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    // MEDIA QUERIES

    @media #{$medium} {
        dc-table-grid {
            .dc-table-grid {
                .dctg-header-cell {
                    font-size: 12px;
                    line-height: 13px;
                    height: 32px;
                }

                .dctg-body-cell {
                    font-size: 12px;
                    line-height: 13px;
                    height: 32px;
                }
            }

            .action-cell {
                i {
                    font-size: 14px;
                }
            }

            .material-icons {
                font-size: 12px;
            }
        }
    }

    @media #{$small} {
        dc-table-grid {
            .dc-table-grid {
                .dctg-header-cell {
                    font-size: 10px;
                    line-height: 11px;
                    height: 32px;
                }

                .dctg-body-cell {
                    font-size: 10px;
                    line-height: 11px;
                    height: 32px;
                }
            }

            .action-cell {
                i {
                    font-size: 12px;
                }

                button:not(.cug2b-modal-closer) {
                    font-size: 10px;
                    padding: 3px 20px 3px 8px;

                    &:after {
                        font-size: 14px;
                    }
                }
            }

            .material-icons {
                font-size: 10px;
            }

            dc-pager {
                .dc-pager {
                    @apply mt-4;

                    .dc-pager-number {
                        @apply m-1;
                        width: 17px;
                        height: 14px;
                        font-size: 10px;
                    }

                    .dc-pager-control {
                        @apply my-1;
                        width: 14px;
                        height: 14px;
                        font-size: 18px;
                        padding-bottom: 3px;
                    }
                }
            }
        }
    }

    @media #{$xsmall} {
        dc-table-grid {
            .dc-table-grid {
                &:not(.pinned-cells) {
                    .cug2b-edit-user-modal {
                        display: none;
                    }
                }

                .dctg-header-cell {
                    @apply whitespace-nowrap;
                    font-size: 12px;
                    line-height: 14px;
                    height: 32px;
                }

                .dctg-body-cell {
                    @apply whitespace-nowrap;
                    font-size: 10px;
                    line-height: 12px;
                    height: 32px;
                }
            }

            .action-cell {
                i {
                    font-size: 10px;
                }

                button:not(.cug2b-modal-closer) {
                    font-size: 10px;
                    padding: 3px 20px 3px 8px;

                    &:after {
                        font-size: 14px;
                    }
                }
            }

            dc-pager {
                .dc-pager {
                    @apply mt-4;

                    .dc-pager-number {
                        @apply m-1;
                        width: 24px;
                        height: 20px;
                        font-size: 14px;
                    }

                    .dc-pager-control {
                        @apply my-1;
                        width: 20px;
                        height: 20px;
                        font-size: 24px;
                        padding-bottom: 4px;
                    }
                }
            }
        }
    }
}
