@import "../flight/flight-variables";

.modal-content {
    &.cug-modal-content {
        width: 500px;
        max-height: calc(95% - 66px);

        &.step-1 {
            width: 730px;
            height: 972px;
        }

        &.step-2 {
            width: 730px;
            height: 844px;
        }

        &.step-3 {
            width: 730px;
            height: 866px;
        }

        &.step-4 {
            width: 530px;
            height: 580px;
        }

        &.add-user {
            width: 530px;
            height: 650px;
        }

        &.edit-user {
            width: 530px;
            height: 580px;
        }

        .cug-modal-content-box {
            border: 1px solid $brand-secondary;
            margin: 10px;
            border-radius: $border-radius-lg;
            padding: 20px;

            .cug-modal-button-container {
                @apply flex justify-end;
                margin-top: 20px;
            }
        }

        .cug-modal-title {
            margin: 20px 0;
            font-size: 24px;
            font-weight: 900;
            color: $brand-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: ClanOT-Bold;

            i {
                margin-right: 10px;
                color: $brand-tertiary;
                font-size: 45px;
                font-weight: 600;
            }
        }

        .cug-modal-close {
            position: absolute;
            top: -17px;
            right: -17px;
            width: 34px;
            height: 34px;
            background: $gray-darkest;
            color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            z-index: 100;
            user-select: none;
            cursor: pointer;

            &:hover {
                background: $brand-primary;
            }
        }

        .cug-scroller {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
        }

        .breadcrumbs {
            top: 10px;
        }

        .breadcrumbs-bg {
            width: 100%;
            max-width: 100%;
            margin: 10px 0;
        }

        .breadcrumb-section {
            span {
                top: -8px;
                left: 28px;
                font-size: $font-size-xsmall;
                font-family: ClanOT-News;
            }
        }

        .booking-step {
            width: 20px;
            height: 20px;
            margin-top: 0;
            font-size: $font-size-xsmall;
        }

        h1 {
            margin: 0;
            font-size: 24px;
            font-weight: 900;
            color: $brand-secondary;
            font-family: ClanOT-Bold;

            &.no-subtitle {
                margin-bottom: 20px;
            }
        }

        h2 {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: $brand-secondary;

            i {
                color: $brand-secondary;
                margin-left: 10px;
                cursor: pointer;

                &:hover {
                    color: $brand-primary;
                }
            }
        }

        .subtitle {
            margin-bottom: 20px;
            color: $brand-secondary;
            font-weight: 600;
            font-size: $font-size-xsmall;
            padding-left: 28px;
            font-family: ClanOT-News;
        }

        .group-caption {
            color: $brand-secondary;
            font-weight: 600;
            font-size: $font-size-medium;
            margin-bottom: 10px;
            display: block;
        }

        .cb-title {
            font-weight: 600;
            color: $brand-secondary;
            font-size: $font-size-medium;
        }

        .summary-list {
            padding-left: 20px;
            font-size: $font-size-xsmall;
            line-height: 1.3;
            margin-bottom: 20px;
        }

        .result-box {
            @apply m-0 h-full;
            text-align: center;

            h1 {
                margin: 20px 0 0;
                font-size: 45px;
                color: $brand-secondary;
                font-weight: 900;
                line-height: 1;
                text-transform: uppercase;
                font-family: ClanOT-Bold;
            }

            h2 {
                margin: 0 0 20px;
                font-size: 26px;
                color: $brand-secondary;
                font-weight: 900;
                line-height: 1;
                text-transform: uppercase;
                font-family: ClanOT-Bold;
            }

            p {
                @apply block text-brand-secondary;
                margin: 0 auto 30px;
                text-align: center;
                width: 340px;
                max-width: 95%;

                span {
                    color: $brand-primary;
                }
            }

            i {
                @apply inline-block text-brand-secondary;
                font-size: 190px;
            }
        }
    }
}

@media #{$medium-up} {
    .modal-content {
        &.cug-modal-content {
            &.add-user {
                height: 600px;
            }

            &.edit-user {
                height: 530px;
            }
        }
    }
}

@media #{$small} {
    .modal-content {
        &.cug-modal-content {
            &.add-user {
                height: 590px;
            }

            &.edit-user {
                height: 530px;
            }
        }
    }
}

@media #{$small-down} {
    .modal-content {
        &.cug-modal-content {
            position: fixed;
            top: 50%;
            left: 50%;
            width: 500px;
            transform: translate(-50%, -50%);
            padding: 10px;
            border-radius: $border-radius-lg;
            background: #fff;
            z-index: 1001;

            &.step-1,
            &.step-2,
            &.step-3,
            &.step-4 {
                width: 90%;
            }

            &.step-4 {
                height: auto;
            }
        }
    }
}

@media #{$xsmall} {
    .modal-content {
        &.cug-modal-content {
            left: 5%;
            width: 90%;
            max-height: 90%;
            transform: translateY(-50%);
            overflow: visible;
            overflow-y: visible;
            margin-top: 33px;

            &.argentina-modal,
            &.edit-profile {
                left: 2%;
                width: 96%;
                max-height: 95%;
            }

            &.pending-payment {
                left: 5%;
                width: 90%;
                max-height: 95%;
            }

            .modal-button-container {
                display: block;

                a {
                    margin: $small-gap 0 0 0;
                    width: 100%;
                    display: block;
                }
            }

            .result-box {
                i {
                    font-size: 150px;
                }
            }
        }
    }

    .agency-modal {
        .modal-content {
            &.cug-modal-content {
                width: 88%;
            }
        }
    }
}
