.cug2b-add-travel-partners-btn-container {
    @apply mb-8;
}

.cug2b-add-travel-partners-btn {
    @apply relative rounded-full border border-solid border-cug-dark-gray flex items-center cursor-pointer text-cug-dark-gray common-transition;
    padding: 0 70px;
    height: 52px;

    span {
        @apply text-cug-dark-gray font-body font-bold common-transition;
        font-size: 22px;
    }

    i {
        @apply absolute vertical-center common-transition;
        font-size: 38px;

        &:first-child {
            left: 15px;
        }

        &:last-child {
            right: 15px;
        }
    }

    &:hover {
        @apply bg-cug-dark-gray text-white;

        span {
            @apply text-white;
        }

        i {
            @apply text-white;
        }
    }
}

.cug2b-add-travel-partners-to-booking-modal {
    width: 840px;
    height: 800px;
    max-height: 95%;
    position: relative;
}

.cug2b-travel-partners-to-booking-modal-header {
    @apply border-b-4 border-solid border-bundle-red flex items-center justify-center w-full;
    height: 165px;
    margin: 0 -33px;
    width: calc(100% + 65px);

    i {
        @apply font-normal mr-4;
        font-size: 100px;
    }

    h1 {
        @apply font-black leading-none;
        font-size: 40px;
    }

    h2 {
        @apply font-black leading-none;
        font-size: 52px;
    }
}

.cug2b-search-travel-partner-booking {
    @apply my-4 relative;
    width: 390px;

    i {
        @apply absolute vertical-center text-cug-dark-gray;
        font-size: 28px;
        right: 20px;
    }

    input {
        @apply bg-white;
        border-radius: 5px;
        height: 52px;
    }
}

.cug2b-quantity-error {
    @apply font-body text-white bg-bundle-red relative;
    font-size: 20px;
    line-height: 24px;
    border-radius: 10px;
    padding: 15px 15px 15px 75px;
    margin-bottom: 20px;

    i {
        @apply absolute vertical-center;
        left: 15px;
        font-size: 45px;
    }

    span {
        @apply font-bold;
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .cug2b-add-travel-partners-btn-container {
        @apply mb-4;
    }

    .cug2b-add-travel-partners-btn {
        padding: 0 50px;
        height: 42px;

        span {
            font-size: 18px;
        }

        i {
            font-size: 30px;

            &:first-child {
                left: 12px;
            }

            &:last-child {
                right: 12px;
            }
        }
    }

    .cug2b-add-travel-partners-to-booking-modal {
        width: 690px;
    }
}

@media #{$small} {
    .cug2b-add-travel-partners-btn-container {
        @apply mb-2;
    }

    .cug2b-add-travel-partners-btn {
        padding: 0 40px;
        height: 32px;

        span {
            font-size: 13px;
        }

        i {
            font-size: 22px;

            &:first-child {
                left: 10px;
            }

            &:last-child {
                right: 10px;
            }
        }
    }

    .cug2b-add-travel-partners-to-booking-modal {
        width: 690px;
        height: 700px;
    }

    .cug2b-travel-partners-to-booking-modal-header {
        height: 105px;

        i {
            @apply mr-4;
            font-size: 62px;
        }

        h1 {
            font-size: 24px;
        }

        h2 {
            font-size: 32px;
        }
    }

    .cug2b-search-travel-partner-booking {
        width: 260px;

        i {
            font-size: 18px;
            right: 20px;
        }

        input {
            height: 35px;
        }
    }

    .cug2b-quantity-error {
        font-size: 12px;
        line-height: 15px;
        border-radius: 8px;
        padding: 15px 15px 15px 50px;
        margin-bottom: 15px;

        i {
            left: 15px;
            font-size: 27px;
        }
    }
}

@media #{$xsmall} {
    .cug2b-add-travel-partners-btn-container {
        @apply w-full my-4;
    }

    .cug2b-add-travel-partners-btn {
        @apply w-full justify-center;
        padding: 0 55px;
        height: 36px;

        span {
            font-size: 14px;
        }

        i {
            font-size: 26px;

            &:first-child {
                left: 8px;
            }

            &:last-child {
                right: 8px;
            }
        }
    }

    .cug2b-add-travel-partners-to-booking-modal {
        width: 690px;
        height: 770px;
    }

    .cug2b-travel-partners-to-booking-modal-header {
        height: 100px;

        i {
            @apply mr-2;
            font-size: 50px;
        }

        h1 {
            font-size: 20px;
        }

        h2 {
            font-size: 26px;
        }
    }

    .cug2b-search-travel-partner-booking {
        @apply w-full;

        i {
            font-size: 16px;
            right: 16px;
        }

        input {
            height: 32px;
        }
    }

    .cug2b-quantity-error {
        font-size: 12px;
        line-height: 14px;
        border-radius: 8px;
        padding: 15px 15px 15px 55px;
        margin-bottom: 15px;

        i {
            left: 15px;
            font-size: 30px;
        }
    }
}
