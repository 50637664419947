.register-page,
.gca-register {
    .form-captcha-container {
        margin-top: 10px;
    }

    .mdl-textfield {
        padding: 5px 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 9px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }

    .birthdate-container {
        padding-top: 26px;

        .input-inner-title {
            top: 8px;
        }
    }

    h1 {
        color: $brand-secondary;
        font-size: 18px;
        font-weight: 600;
        margin: 17px 0;
    }
}

.register-button-container {
    @apply flex justify-end;
    margin: 20px 0;

    &.text-center {
        text-align: center;
    }
}

.register-terms {
    margin: 17px 0;

    a {
        color: $brand-secondary;

        &:hover {
            color: $brand-primary;
        }
    }
}
