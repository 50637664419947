.person-wc-scroller {
    @apply absolute;
    top: 500px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -10;
    transition: opacity 0.25s ease-in-out;
}

.open {
    .person-wc-scroller {
        top: 166px;
        z-index: 0;
    }
}

.profile-person-widget {
    @apply relative w-full overflow-visible;
    grid-area: person;
    height: 250px;
}

.person-widget-container {
    @apply relative overflow-hidden;
    height: 250px;
    transition: all 0.25s ease-in-out;

    &.open {
        height: 1000px;
        z-index: 1000000;

        .person-widget-content {
            transition: all 0.25s ease-in-out;
            height: 550px;
        }

        .pw-editor {
            @apply opacity-100;
            transition: all 0.5s ease-in-out;
        }
    }
}

.person-widget-content {
    @apply relative bg-white;
    height: 250px;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
}

.pw-main {
    @apply relative flex items-center;
    height: 250px;
    padding: 0 35px 0 195px;

    > i {
        @apply absolute text-be-cyan-2;
        font-size: 130px;
        top: 30px;
        left: 25px;
    }
}

.pw-name {
    @apply font-body font-semibold text-be-blue;
    font-size: 20px;
    line-height: 24px;
}

.pw-edit {
    @apply absolute cursor-pointer select-none font-body font-semibold text-be-blue;
    top: 10px;
    right: 15px;
    font-size: 16px;

    i {
        font-size: 15px;
    }
}

.pw-editor {
    padding: 0 20px 35px;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    font-family: "Lato", sans-serif;

    .mdl-textfield {
        padding: 0;
        margin-top: 12px;
    }

    .mdl-textfield__label {
        top: 0;
        font-size: 13px;
    }

    .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
    .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
    .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
        top: -8px;
        font-size: 12px;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        padding-left: 10px;
    }

    .js-input {
        @apply font-body;
        font-size: 13px;
        height: 42px;
        padding-top: 17px;
        padding-left: 9px;
        line-height: unset;
    }

    .js-select {
        @apply font-body;
        appearance: none;
    }

    .js-select-arrow {
        &:after {
            @apply pointer-events-none absolute block;
            content: "\25BC";
            top: 14px;
            right: 10px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.pw-editor-title {
    @apply relative mb-1 flex cursor-pointer items-center font-body font-semibold text-be-blue;
    height: 30px;
    font-size: 16px;
    padding-top: 6px;

    i {
        @apply font-semibold text-be-cyan-2;
        font-size: 24px;
        margin-right: 7px;

        &.js-mp-edit {
            @apply font-normal text-be-blue;
            margin-left: 10px;
            font-size: 16px;
        }
    }

    .pw-section-opener {
        @apply absolute right-0 top-0 font-bold text-be-blue common-transition;
        font-size: 24px;
        margin-top: 6px;

        &.open {
            transform: rotate(90deg);
        }
    }
}

.pw-section {
    @apply overflow-hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 0;

    &.pw-section-open {
        max-height: 610px;
    }
}

.bank-data-description {
    @apply mt-2;
    line-height: 19px;
    font-size: 15px;
}

.pw-bankdata-missing {
    @apply m-auto mb-4 flex items-center bg-brand-primary px-4 py-3 text-white;

    i {
        @apply mr-3;
        font-size: 30px;
    }
}

.profile-form {
    @apply border-b border-solid border-be-gray-9 pb-4;
}

.pw-inner-label {
    @apply relative font-body font-semibold text-be-blue;
    top: 6px;
    font-size: 12px;
}

.pw-cta {
    @apply relative inline-flex items-center border border-solid border-be-blue bg-be-blue font-body font-black normal-case text-white;
    width: 120px;
    height: 30px;
    border-radius: 15px;
    letter-spacing: normal;
    font-size: 17px;
    padding-right: 30px;
    transition: all 0.25s ease-in-out;

    i {
        @apply absolute text-white;
        top: 3px;
        right: 2px;
        font-size: 22px;
        transition: all 0.25s ease-in-out;
    }

    &:not(.disabled):hover {
        @apply border border-solid border-be-blue bg-white text-be-blue;

        i {
            @apply text-be-blue;
        }
    }
}

.pw-badge-container {
    @apply relative;
    margin-top: 10px;
}

.pw-badge {
    @apply relative flex items-center whitespace-nowrap rounded-full border border-solid border-be-blue font-emphasis text-be-blue;
    padding: 8px 7px 5px 29px;
    margin-top: 10px;
    font-size: 14px;

    &:first-child {
        margin-top: 0;
    }

    i {
        @apply absolute text-be-blue;
        left: 5px;
        top: 3px;
        font-size: 21px;
    }

    &.banco-estado-badge {
        @apply text-be-gray-3;
        font-size: 16px;
        padding: 8px 25px 5px;

        img {
            @apply relative;
            top: -1px;
            margin-left: 3px;
            height: 14px;
        }

        .smart-logo {
            height: 22px;
        }
    }

    &.staff-badge {
        width: 180px;
        height: 37px;
        padding-left: 50px;

        i {
            &:first-child {
                font-size: 24px;
                left: 15px;
                top: 5px;
                @apply text-be-blue;
            }

            &:last-child {
                font-size: 78px;
                left: 90px;
                top: -23px;
            }
        }
    }
}

.pw-savings {
    @apply absolute flex flex-col items-center justify-center bg-dc-bundle-simple font-emphasis text-white;
    top: 182px;
    height: 82px;
    width: 100%;
    left: 0;
    text-align: center;

    span {
        font-size: 18px;
        line-height: 24px;
        max-width: 95%;

        &:last-child {
            @apply block font-body leading-none text-white;
            margin-top: 2px;
            font-size: 9px;
        }
    }

    i {
        @apply relative cursor-pointer;
        font-size: 15px;
        top: -6px;
        left: -2px;
    }
}

.pw-savings-tooltip {
    @apply absolute bg-be-gray-8 font-body text-white shadow-medium;
    font-size: 13px;
    line-height: 18 px;
    padding: 10px;
    border-radius: 6px;
    transform: translateX(-50%) translateX(8px) translateY(-102px);
    width: 340px;

    &:after {
        @apply absolute bg-be-gray-8;
        content: "";
        width: 20px;
        height: 20px;
        bottom: -11px;
        right: 50%;
        transform: translateX(50%) rotate(45deg);
    }

    ul {
        margin-top: 8px;
    }

    span,
    li {
        line-height: 16px;
    }
}

.pw-upgrade-smart {
    @apply absolute flex items-center justify-center bg-be-gray-7;
    height: 78px;
    width: 100%;
    left: 0;
    top: 248px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 0 15px;

    span {
        @apply font-emphasis text-white;
        font-size: 16px;
        line-height: 20px;
    }

    img {
        @apply relative;
        margin-left: 4px;
        height: 12px;
        top: 0px;
    }

    a {
        @apply relative flex items-center justify-center whitespace-nowrap border border-solid border-white bg-white normal-case text-be-blue;
        width: 157px;
        height: 24px;
        padding: 0 28px 0 10px;
        border-radius: 12px;
        letter-spacing: normal;
        margin-left: 5px;
        font-size: 13px;

        img {
            height: 10px;
            top: 0;
        }

        i {
            @apply absolute text-be-blue;
            font-size: 18px;
            top: 3px;
            right: 5px;
        }

        &:not(.disabled):hover {
            @apply border border-solid border-white;
            padding: 0 28px 0 10px;
        }
    }
}

.pwc-close-btn {
    @apply absolute flex cursor-pointer items-center justify-center rounded-full bg-black font-semibold leading-none text-white;
    width: 20px;
    height: 20px;
    top: -10px;
    right: -10px;
    font-size: 16px;
    padding-bottom: 2px;

    &:hover {
        @apply bg-brand-primary text-white;
    }
}

.staff {
    .pw-main {
        > i {
            @apply text-be-red;
        }
    }
}

.pw-staff-info {
    @apply absolute flex items-center justify-center font-body text-be-blue;
    left: 0;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    top: 190px;
    padding-top: 15px;
    background-image: linear-gradient(to right, #1c355e 20%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 10px 2px;
    background-repeat: repeat-x;

    > span {
        @apply block;
        max-width: 310px;
        text-align: left;
    }
}

.pw-required-error {
    padding: 10px 25px;
    background: $alert-bg;
    color: $alert-color;
    font-size: $font-size-small;
    margin: $small-gap auto 20px;
    border-radius: $border-radius-sm;
}

.staff {
    .profile-person-widget {
        height: 390px;
    }

    .person-widget-container {
        height: 390px;

        &.open {
            height: 1000px;
        }
    }

    .person-widget-content {
        height: 390px;
    }

    .pw-main {
        @apply flex-col items-start justify-start;
        height: 390px;
        padding: 40px 0 0 170px;
    }
}

.pwc-club-member {
    .profile-person-widget {
        height: 298px;
    }

    .person-widget-container {
        height: 298px;

        &.open {
            height: 1000px;
        }
    }

    .person-widget-content {
        height: 298px;
    }

    .pw-main {
        @apply flex-col items-start justify-start;
        height: 298px;
        padding: 40px 0 0 170px;

        > i {
            @apply text-dc-bundle-simple;
        }
    }

    &.banco-estado-6 {
        .pw-main {
            > i {
                @apply text-be-gray-8;
            }
        }

        .pw-savings {
            @apply bg-be-gray-8;
            top: 200px;
        }

        .pw-savings-tooltip {
            transform: translateX(-50%) translateX(8px) translateY(-50px);
            @apply bg-be-orange;

            &:after {
                @apply bg-be-orange;
            }
        }
    }
}

.banco-estado-5 {
    .profile-person-widget {
        height: 326px;
    }

    .person-widget-container {
        height: 326px;

        &.open {
            height: 1000px;
        }
    }

    .person-widget-content {
        height: 326px;
    }

    .pw-main {
        @apply flex-col items-start justify-start;
        height: 326px;
        padding: 40px 20px 0 170px;

        > i {
            @apply text-dc-bundle-simple;
        }
    }

    .pw-name {
        font-size: 17px;
        line-height: 22px;
    }

    .pw-savings {
        top: 166px;
        @apply bg-be-orange;
    }

    &.pwc-club-member {
        .profile-person-widget {
            height: 356px;
        }

        .person-widget-container {
            height: 356px;

            &.open {
                height: 1000px;

                .person-widget-content {
                    transition: all 0.25s ease-in-out;
                    height: 560px;
                }
            }
        }

        .person-widget-content {
            height: 356px;
        }

        .pw-main {
            padding-top: 30px;
            height: 356px;
        }

        .pw-savings {
            top: 196px;
        }

        .pw-upgrade-smart {
            top: 278px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .pw-main {
        padding: 0 25px 0 150px;

        > i {
            font-size: 100px;
            top: 45px;
            left: 25px;
        }
    }

    .pwc-club-member {
        .pw-main {
            padding: 40px 0 0 140px;
        }
    }

    .staff {
        .pw-main {
            padding: 40px 0 0 140px;
        }
    }

    .banco-estado-5 {
        .pw-main {
            padding: 40px 20px 0 140px;
        }

        .pw-name {
            font-size: 15px;
            line-height: 19px;
        }

        .pw-savings {
            i {
                top: 0;
                left: 0;
            }
        }

        &.pwc-club-member {
            .pw-main {
                padding-top: 40px;
            }
        }
    }

    .banco-estado-6 {
        &.pwc-club-member {
            .pw-savings {
                i {
                    top: 0;
                }
            }
        }
    }

    .pw-upgrade-smart {
        span {
            font-size: 13px;
            line-height: 17px;
        }

        a {
            width: 130px;
            font-size: 10px;

            img {
                height: 9px;
            }

            i {
                font-size: 17px;
                right: 3px;
            }
        }
    }

    .pw-badge {
        padding: 5px 7px 2px 29px;
        font-size: 13px;

        i {
            top: 2px;
            font-size: 16px;
        }

        &.banco-estado-badge {
            font-size: 13px;
            padding: 6px 20px 2px;

            img {
                height: 11px;
                top: -2px;
            }
        }

        &.staff-badge {
            width: 160px;
            height: 30px;
            padding-left: 40px;

            i {
                &:first-child {
                    font-size: 20px;
                    top: 3px;
                }

                &:last-child {
                    font-size: 73px;
                    left: 77px;
                    top: -23px;
                }
            }
        }
    }

    .pw-savings {
        span {
            font-size: 15px;
            line-height: 18px;
        }
    }

    .pw-staff-info {
        font-size: 14px;
        line-height: 16px;

        > span {
            max-width: 270px;
        }
    }
}

@media #{$small} {
    .open {
        .person-wc-scroller {
            top: 120px;
        }
    }

    .profile-person-widget {
        height: 140px;
    }

    .person-widget-container {
        height: 140px;
    }

    .pw-main {
        height: 140px;
        padding: 0 20px 0 120px;

        > i {
            font-size: 80px;
            top: 30px;
            left: 20px;
        }
    }

    .staff {
        .profile-person-widget {
            height: 170px;
        }

        .person-widget-container {
            height: 170px;

            &.open {
                height: 1000px;

                .person-widget-content {
                    height: 550px;
                }
            }
        }

        .person-widget-content {
            height: 170px;
        }

        .pw-main {
            height: 170px;
            padding: 30px 0 0 105px;

            > i {
                top: 20px;
                left: 15px;
            }
        }
    }

    .pwc-club-member {
        .profile-person-widget {
            height: 197px;
        }

        .person-widget-container {
            height: 197px;

            &.open {
                height: 1000px;

                .person-widget-content {
                    height: 550px;
                }
            }
        }

        .person-widget-content {
            height: 197px;
        }

        .pw-main {
            height: 197px;
            padding: 30px 0 0 105px;

            > i {
                top: 15px;
                left: 15px;
            }
        }

        &.banco-estado-5 {
            .pw-main {
                padding-top: 22px;
            }

            .pw-badge {
                margin-top: 5px;

                &.banco-estado-badge {
                    margin-top: 0;
                }
            }
        }

        &.banco-estado-6 {
            .profile-person-widget {
                height: 173px;
            }

            .person-widget-container {
                height: 173px;

                &.open {
                    height: 1000px;

                    .person-widget-content {
                        height: 550px;
                    }
                }
            }

            .person-widget-content {
                height: 173px;
            }

            .pw-main {
                height: 173px;
                padding: 25px 10px 0 105px;

                > i {
                    top: 15px;
                    left: 15px;
                }
            }

            .pw-name {
                font-size: 12px;
                line-height: 14px;
            }

            .pw-savings {
                top: 105px;
                height: 50px;

                span {
                    font-size: 12px;
                    line-height: 1;
                }

                i {
                    top: 1px;
                }
            }
        }
    }

    .banco-estado-5 {
        .profile-person-widget {
            height: 205px;
        }

        .person-widget-container {
            height: 205px;

            &.open {
                height: 1000px;

                .person-widget-content {
                    height: 550px;
                }
            }
        }

        .person-widget-content {
            height: 205px;
        }

        .pw-main {
            height: 205px;
            padding: 25px 10px 0 105px;

            > i {
                top: 15px;
                left: 15px;
            }
        }

        .pw-name {
            font-size: 12px;
            line-height: 14px;
        }

        .pw-savings {
            top: 105px;
            height: 50px;

            span {
                font-size: 12px;
                line-height: 1;
            }

            i {
                top: 1px;
            }
        }

        &.pwc-club-member {
            .profile-person-widget {
                height: 225px;
            }

            .person-widget-container {
                height: 225px;

                &.open {
                    height: 1000px;
                }
            }

            .person-widget-content {
                height: 225px;
            }

            .pw-main {
                padding-top: 30px;
                height: 225px;
            }

            .pw-savings {
                top: 125px;
            }

            .pw-upgrade-smart {
                top: 175px;
            }
        }
    }

    .pw-upgrade-smart {
        top: 155px;
        height: 50px;
        font-size: 9px;
        line-height: 11px;
        padding: 0 10px;

        img {
            margin-left: 3px;
            height: 9px;
            top: 0px;
        }

        span {
            font-size: 9px;
            line-height: 11px;
        }

        a {
            width: 113px;
            height: 21px;
            font-size: 10px;

            img {
                height: 8px;
                top: 1px;
                margin-left: 3px;
            }

            i {
                font-size: 14px;
                right: 3px;
            }
        }
    }

    .pw-name {
        font-size: 14px;
        line-height: 16px;
    }

    .pw-edit {
        font-size: 11px;
        top: 8px;
        right: 8px;

        i {
            font-size: 10px;
        }
    }

    .pw-editor-title {
        font-size: 11px;
        height: unset;
        padding-top: 0;

        i {
            font-size: 14px;

            &.js-mp-edit {
                font-size: 10px;
                margin-left: 5px;
            }
        }

        .pw-section-opener {
            @apply mt-0;
            font-size: 18px;
        }
    }

    .pw-inner-label {
        font-size: 8px;
    }

    .pw-editor {
        padding: 0 10px 20px;

        .mdl-textfield {
            margin-top: 5px;
        }

        .mdl-textfield__label {
            top: 0;
            font-size: 10px;
        }

        .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
        .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
            top: -5px;
            font-size: 8px;
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            padding-left: 5px;
            line-height: 25px;
        }

        .js-input {
            @apply leading-none;
            font-size: 10px;
            height: 25px;
            padding-top: 10px;
            padding-left: 5px;
        }

        .js-select {
            @apply font-body;
            appearance: none;
        }

        .js-select-arrow {
            &:after {
                top: 5px;
                right: 7px;
                font-size: 8px;
            }
        }
    }

    .pw-cta {
        width: 73px;
        height: 18px;
        font-size: 12px;
        padding: 0 10px 0 7px;

        i {
            font-size: 12px;
            top: 2px;
            right: 2px;
        }
    }

    .pw-badge-container {
        margin-top: 3px;
    }

    .pw-badge {
        font-size: 9px;
        padding: 3px 7px 0px 21px;

        i {
            font-size: 14px;
            left: 3px;
            top: 2px;
        }

        &.banco-estado-badge {
            font-size: 10px;
            padding: 3px 11px 0;

            img {
                height: 9px;
                top: -1px;
            }
        }

        &.staff-badge {
            width: 120px;
            height: 23px;
            padding-left: 30px;

            i {
                &:first-child {
                    font-size: 14px;
                    left: 10px;
                    top: 3px;
                }

                &:last-child {
                    font-size: 50px;
                    left: 57px;
                    top: -15px;
                }
            }
        }
    }

    .pw-savings {
        top: 105px;
        height: 74px;

        span {
            font-size: 14px;
            line-height: 16px;

            &:last-child {
                font-size: 9px;
            }
        }
    }

    .pw-savings-tooltip {
        z-index: 1000000;
        padding: 20px;
        width: 570px;
        transform: translateX(-50%) translateX(12px) translateY(-440px);

        span,
        li {
            line-height: 1.6;
        }

        &.pw-mobile-tooltip {
            @apply fixed all-center;

            &:after {
                display: none;
            }
        }
    }

    .pwc-club-member {
        &.banco-estado-6 {
            .pw-savings {
                top: 120px;
                height: 55px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;

                span {
                    font-size: 12px;
                    line-height: 12px;

                    &:last-child {
                        font-size: 9px;
                    }
                }

                i {
                    top: 0;
                }
            }

            .pw-badge {
                &:last-child {
                    margin-top: 5px;
                }
            }
        }
    }

    .pw-staff-info {
        top: 113px;
        font-size: 11px;
        line-height: 14px;
        padding-top: 8px;

        > span {
            max-width: 210px;
        }

        &.portuguese {
            font-size: 11px;
            line-height: 12px;
        }
    }

    .bank-data-description {
        @apply mt-1;
        line-height: 15px;
        font-size: 11px;
    }

    .pw-bankdata-missing {
        @apply mb-3 px-2;

        i {
            @apply mr-2;
            font-size: 22px;
        }
    }
    .pw-bankdata-missing-text {
        font-size: 12px;
    }

    .profile-form {
        @apply pb-3;
    }
}

@media #{$xsmall} {
    .person-wc-scroller {
        @apply relative mt-4;
        // DEVNOTE To overwrite perfect scrollbar
        top: unset !important;
        left: unset;
        right: unset;
        bottom: unset;

        &.ps {
            // DEVNOTE To overwrite perfect scrollbar
            overflow: auto !important;
        }
    }

    .profile-person-widget {
        grid-area: unset;
        height: 0;
        z-index: 1000000000;
    }

    .person-widget-container {
        @apply fixed overflow-y-auto;
        top: 90px;
        left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 240px;
        height: unset;
        max-width: 240px;
        bottom: 0;
        margin-left: -240px;
        transition: all 0.5s ease-in-out;

        &.open {
            height: unset;

            .person-widget-content {
                height: unset;
            }
        }

        &.mobile-open {
            margin-left: 0;

            ~ .pw-mobile-opener {
                left: 240px;
            }
        }
    }

    .pw-mobile-opener {
        @apply fixed bg-be-blue font-black text-white shadow-medium;
        top: 137px;
        left: 0;
        width: 50px;
        height: 60px;
        transition: all 0.5s ease-in-out;
        font-size: 11px;
        padding-top: 45px;
        text-align: center;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;

        i {
            @apply absolute border-b border-solid border-white font-normal;
            top: 4px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 38px;
            padding-bottom: 4px;
            width: 46px;
        }

        &.closed {
            width: 25px;
            font-size: 12px;
            padding: 0;
            @apply flex items-center justify-center font-emphasis text-white;

            span {
                transform: rotate(90deg);
            }
        }
    }

    .staff {
        .profile-person-widget {
            height: 0;
        }

        .person-widget-container {
            height: unset;

            &.mobile-open {
                margin-left: 0;

                ~ .pw-mobile-opener {
                    left: 240px;
                }
            }

            &.open {
                height: unset;

                .person-widget-content {
                    height: unset;
                }

                .pw-main {
                    height: 140px;
                }
            }
        }

        .person-widget-content {
            height: unset;
        }

        .pw-main {
            background: url(../images/mobile-profile-widget-top-staff.png) no-repeat top center;
            height: 350px;
            padding: 110px 15px 0 15px;
            text-align: center;
        }

        .pw-badge-container {
            @apply relative flex w-full transform-none justify-center p-0;
            border-radius: 10px;
            height: unset;
            margin: 22px 0;
            top: unset;
            left: unset;
        }
    }

    .pwc-club-member {
        .profile-person-widget {
            height: 0;
        }

        .person-widget-container {
            height: unset;

            &.mobile-open {
                margin-left: 0;

                ~ .pw-mobile-opener {
                    left: 240px;
                }
            }

            &.open {
                height: unset;

                .person-widget-content {
                    height: unset;
                }

                .pw-main {
                    height: 140px;
                }
            }
        }

        .person-widget-content {
            height: unset;
        }

        .pw-main {
            background: url(../images/mobile-profile-widget-top-dc.png) no-repeat top center;
            height: 330px;
            padding: 110px 15px 0 15px;
            text-align: center;

            > i {
                top: 15px;
                left: 15px;
            }
        }
    }

    .banco-estado-5 {
        .pw-main {
            height: 440px;
        }
    }

    .banco-estado-5 {
        &.pwc-club-member {
            .person-widget-container {
                height: unset;

                &.open {
                    height: unset;
                }
            }

            .person-widget-content {
                height: unset;
            }

            .pw-main {
                padding-top: 110px;
            }

            .profile-person-widget {
                height: 0;
            }

            .pw-savings {
                top: unset;
            }

            .pw-upgrade-smart {
                top: unset;
            }
        }
    }

    .banco-estado-6 {
        .pw-main {
            height: 320px;
        }
    }

    .banco-estado-5,
    .banco-estado-6 {
        .profile-person-widget {
            height: 0;
        }

        .person-widget-container {
            height: unset;
            top: 120px;

            &.mobile-open {
                margin-left: 0;

                ~ .pw-mobile-opener {
                    left: 240px;
                }
            }

            &.open {
                height: unset;

                .person-widget-content {
                    height: unset;
                }

                .pw-main {
                    height: 140px;
                }
            }
        }

        .pw-mobile-opener {
            top: 167px;
        }

        .person-widget-content {
            height: unset;
        }

        .pw-main {
            background: url(../images/mobile-profile-widget-top-dc.png) no-repeat top center;
            padding: 110px 15px 0 15px;
            text-align: center;

            > i {
                top: 15px;
                left: 15px;
            }

            > img {
                margin: 0 auto 10px;
            }
        }

        .pw-badge-container {
            @apply relative w-full transform-none p-0;
            border-radius: 10px;
            height: unset;
            margin-bottom: 10px;
            top: unset;
            left: unset;
        }

        .pw-savings {
            @apply relative;
            border-radius: 10px;
            height: unset;
            padding: 15px 10px;
            top: unset;
            left: unset;
            margin-bottom: 10px;

            span {
                font-size: 12px;
            }

            i {
                top: 0;
            }
        }

        .pw-upgrade-smart {
            @apply relative flex-col;
            border-radius: 10px;
            height: unset;
            top: unset;
            left: unset;
            padding: 15px 10px;

            span {
                font-size: 12px;
            }

            img {
                height: 12px;
                top: 0px;
            }

            a {
                width: 130px;
                height: 21px;
                font-size: 10px;
                margin-top: 10px;

                img {
                    height: 8px;
                    top: 0;
                    margin-left: 3px;
                }

                i {
                    font-size: 14px;
                    right: 3px;
                }
            }
        }
    }

    .banco-estado-6 {
        &.pwc-club-member {
            .pw-savings {
                top: unset;
            }
        }
    }

    .pw-main {
        background: url(../images/mobile-profile-widget-top.png) no-repeat top center;
        height: 150px;
        padding: 107px 50px 0;
        text-align: center;
    }

    .person-widget-content {
        background: #fff url(../images/mobile-profile-widget-bottom.png) no-repeat bottom center;
        height: unset;
        border-radius: 23px;
        border-bottom-left-radius: 0;
        padding-bottom: 64px;
    }

    .pw-edit {
        @apply text-white;
        top: 15px;
        right: 15px;
        font-size: 12px;

        i {
            font-size: 12px;
            margin-left: 5px;
        }
    }

    .pw-name {
        font-size: 15px;
        line-height: 18px;
        margin: 0 auto;
    }

    .pw-editor-title {
        @apply pt-0;
        font-size: 11px;
        height: 13px;

        i {
            font-size: 14px;
            margin-right: 5px;

            &.js-mp-edit {
                margin-left: 5px;
                font-size: 12px;
            }
        }

        .pw-section-opener {
            @apply mt-0;
            font-size: 18px;
        }
    }

    .pw-inner-label {
        top: 5px;
        font-size: 10px;
    }

    .pw-editor {
        padding: 0 10px 20px;

        .mdl-textfield {
            margin-top: 5px;
        }

        .mdl-textfield__label {
            top: 0;
            font-size: 12px;
        }

        .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
        .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
            top: -8px;
            font-size: 10px;
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            padding-left: 6px;
            line-height: 30px;
        }

        .js-input {
            @apply leading-none;
            font-size: 12px;
            height: 30px;
            padding-top: 13px;
            padding-left: 5px;
        }

        .js-select {
            @apply font-body;
            appearance: none;
        }

        .js-select-arrow {
            &:after {
                top: 7px;
                right: 7px;
                font-size: 10px;
            }
        }
    }

    .pw-cta {
        @apply bg-be-red;
        width: 72px;
        height: 18px;
        padding: 0 0 0 8px;
        font-size: 11px;

        i {
            top: 2px;
            font-size: 13px;
        }

        &:not(.disabled):hover {
            @apply bg-be-red text-white;

            i {
                @apply text-white;
            }
        }
    }

    .pw-badge-container {
        @apply absolute mt-0;
        top: 163px;
        left: 50%;
        transform: translateX(-50%);
    }

    .pw-badge {
        @apply border-0 bg-be-cyan-2 text-white;
        height: 32px;
        width: 160px;
        padding: 1px 0 0 35px;
        border-radius: 7px;
        text-align: center;
        font-size: 12px;

        i {
            @apply text-white;
            font-size: 20px;
            top: 5px;
            left: 7px;
        }

        &.banco-estado-badge {
            @apply flex w-full items-center justify-center bg-be-orange p-0 text-white;
            border-radius: 6px;
            font-size: 12px;
            height: 32px;
        }

        &.staff-badge {
            @apply bg-be-red text-white;
            width: 160px;
            height: 33px;
            font-size: 14px;
            padding-left: 45px;

            i {
                &:first-child {
                    @apply text-white;
                }

                &:last-child {
                    font-size: 69px;
                    left: 85px;
                    top: -20px;

                    span {
                        &.path1,
                        &.path2,
                        &.path3,
                        &.path4,
                        &.path5,
                        &.path6,
                        &.path7 {
                            &:before {
                                @apply text-white;
                            }
                        }
                    }
                }
            }
        }
    }

    .pw-savings {
        top: 206px;
        height: 103px;

        span {
            font-size: 14px;
            line-height: 16px;
            max-width: 100%;

            &:last-child {
                font-size: 9px;
            }
        }
    }

    .pw-savings-tooltip {
        z-index: 1000000;
        width: 309px;
        transform: translateX(-50%) translateX(12px) translateY(-440px);

        &.pw-mobile-tooltip {
            @apply fixed all-center;

            &:after {
                display: none;
            }
        }
    }

    .pwc-club-member {
        &.banco-estado-5,
        &.banco-estado-6 {
            .pw-badge-container {
                @apply flex items-center;
                justify-content: stretch;
                margin-top: 10px;

                .pw-badge {
                    @apply m-0;
                    width: 50%;

                    &:not(:last-child) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &:last-child {
                        @apply whitespace-normal border border-solid border-be-cyan-2 bg-white text-be-cyan-2;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        font-size: 11px;
                        line-height: 12px;
                        text-align: left;
                        border-left: none;

                        i {
                            @apply text-be-cyan-2;
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
    }

    .pwc-club-member {
        &.banco-estado-6 {
            .pw-badge-container {
                .pw-badge {
                    &:not(:last-child) {
                        @apply bg-be-gray-8;
                    }
                }
            }
        }
    }

    .pw-staff-info {
        @apply relative border-2 border-dotted border-be-blue;
        border-radius: 10px;
        height: unset;
        padding: 15px;
        top: unset;
        left: unset;
        background: none;
        font-size: 14px;
        line-height: 17px;
    }

    .bank-data-description {
        @apply mt-1;
        line-height: 15px;
        font-size: 11px;
    }

    .pw-bankdata-missing {
        @apply mb-3 px-2 py-1;

        i {
            @apply mr-2;
            font-size: 22px;
        }
    }

    .pw-bankdata-missing-text {
        font-size: 12px;
    }

    .profile-form {
        @apply pb-3;
    }
}
