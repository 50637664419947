.aa-modal {
    @apply leading-normal;

    a {
        @apply text-be-cyan-2;

        &:hover {
            @apply text-be-red-2;
        }
    }
}
