ac-tooltip {
    @apply inline-flex items-center justify-center cursor-pointer;
}

.ac-tooltip {
    @apply flex items-center justify-center bg-be-blue text-white rounded-full w-4 h-4;
    font-size: 12px;
}

.ac-tooltip-balloon {
    @apply fixed bg-bg-cyan text-be-blue font-bold inline-block pointer-events-none shadow-medium font-body;
    max-width: 320px;
    min-width: 140px;
    font-size: 11px;
    line-height: 14px;
    padding: 16px;
    z-index: 1000000;
    border-radius: 2px;
    text-align: center;
    transform: translate(-50%, -100%) translateY(-10px);

    &:before {
        @apply block absolute w-0 h-0 pointer-events-none;
        content: ".";
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border: 10px solid #cee6ed;
        border-color: #cee6ed transparent transparent;
        color: transparent;
    }

    &.down {
        transform: translateX(-50%) translateY(25px);

        &:before {
            @apply top-auto;
            bottom: 100%;
            transform: translateX(-50%);
            border-color: transparent transparent #cee6ed;
        }
    }

    &.left {
        transform: translate(-100%, -50%) translate(-20px, 7px);
        width: 280px;

        &:before {
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            border-color: transparent transparent transparent #cee6ed;
        }
    }

    &.right {
        transform: translate(-25%, -100%) translateY(-10px);
        width: 280px;

        &:before {
            top: 100%;
            left: 25%;
            transform: translateX(-50%);
            border-color: #cee6ed transparent transparent;
        }
    }

    /* TODO TT down */
}
