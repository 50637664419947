.profile-trips-widget {
    @apply relative;
    grid-area: trips;
}

.trips-widget-container {
    @apply absolute inset-0;
}

.twc-tabs {
    @apply absolute border-b border-solid border-be-gray-9 w-full flex;
    height: 51px;
    top: 0;
    left: 0;

    span {
        @apply flex items-center justify-center font-body font-semibold text-be-blue cursor-pointer;
        width: 50%;
        height: 50px;
        font-size: 16px;

        &:hover {
            @apply bg-be-blue-2 text-white;
        }

        &.selected {
            @apply text-white bg-be-blue cursor-default;
        }

        &:first-child {
            border-top-left-radius: 12px;
        }

        &:last-child {
            border-top-right-radius: 12px;
        }
    }
}

.twc-header {
    @apply absolute text-be-blue font-body font-semibold flex items-center;
    top: 52px;
    left: 0;
    height: 50px;
    padding-left: 25px;

    i {
        @apply text-be-cyan-2;
        font-size: 29px;
        margin-right: 12px;
    }
}

.twc-table-header {
    @apply absolute bg-be-blue text-white flex w-full;
    height: 35px;
    top: 50px;
    left: 0;
    border-top: 1px solid white;

    span {
        @apply font-body font-semibold flex items-center justify-center;
        font-size: 13px;
        width: 22%;

        &:last-child {
            width: 12%;

            i {
                @apply text-white;
                font-size: 14px;
            }
        }
    }
}

.pwc-scroller {
    @apply absolute inset-0;
}

.twc-table {
    @apply absolute;
    top: 85px;
    left: 0;
    right: 0;
    bottom: 0;

    li {
        @apply flex border-be-gray-9 border-solid border-b;

        &.twc-no-trips {
            @apply border-none;
        }
    }

    span {
        @apply text-be-blue font-emphasis flex items-center justify-center;
        font-size: 14px;
        width: 22%;
        height: 40px;

        i {
            margin: 0 15px;
        }

        &:last-child {
            width: 12%;

            i {
                @apply text-be-blue cursor-pointer;
                margin: 0;
                font-size: 14px;
            }
        }
    }
}

.twc-no-trips {
    @apply absolute flex flex-col items-center justify-center font-body italic;
    top: -50px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 20px;

    i {
        @apply text-be-red;
        font-size: 54px;
        margin-right: 25px;
    }

    button {
        @apply relative flex items-center justify-center font-body bg-be-blue text-white normal-case border border-be-blue border-solid;
        width: 146px;
        height: 30px;
        padding: 0 28px 0 10px;
        border-radius: 15px;
        letter-spacing: normal;
        margin-top: 25px;
        font-size: 14px;
        transition: all 0.25s ease-in-out;

        i {
            @apply absolute text-white;
            font-size: 20px;
            top: 4px;
            right: 5px;
            margin: 0;
            transition: all 0.25s ease-in-out;
        }

        &:not(.disabled):hover {
            @apply border border-be-blue border-solid bg-white text-be-blue;
            padding: 0 28px 0 10px;

            i {
                @apply text-be-blue;
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    .twc-tabs {
        height: 31px;

        span {
            height: 30px;
            font-size: 12px;
        }
    }

    .twc-header {
        top: 32px;
        height: 30px;
        font-size: 11px;
        padding-left: 15px;

        i {
            font-size: 20px;
            margin-right: 7px;
        }
    }

    .twc-table-header {
        top: 30px;
        height: 26px;

        span {
            font-size: 10px;
        }
    }

    .twc-table {
        top: 58px;

        span {
            font-size: 9px;
            height: 20px;
        }
    }

    .twc-no-trips {
        top: -30px;
        button {
            margin-top: 5px;
        }
    }
}

@media #{$xsmall} {
    .profile-trips-widget {
        height: 140px;
    }

    .twc-tabs {
        height: 31px;

        span {
            height: 30px;
            font-size: 12px;
        }
    }

    .twc-header {
        top: 32px;
        height: 30px;
        font-size: 11px;
        padding-left: 15px;

        i {
            font-size: 20px;
            margin-right: 7px;
        }
    }

    .twc-table-header {
        top: 30px;
        height: 26px;

        span {
            font-size: 10px;

            &:nth-child(1) {
                @apply leading-none;
                width: 20%;
                text-align: center;
            }

            &:nth-child(2) {
                width: 20%;
            }

            &:nth-child(3) {
                width: 20%;
            }

            &:nth-child(4) {
                width: 28%;
            }
        }
    }

    .twc-table {
        top: 58px;

        span {
            font-size: 10px;
            height: 20px;
            white-space: nowrap;

            i {
                margin: 0 5px;
            }

            &:nth-child(1) {
                width: 20%;
            }

            &:nth-child(2) {
                width: 20%;
            }

            &:nth-child(3) {
                width: 20%;
            }

            &:nth-child(4) {
                width: 28%;
                @apply font-body font-semibold;
            }

            &:nth-child(5) {
                width: 28%;
            }

            &:last-child {
                i {
                    margin: 0;
                }
            }
        }
    }

    .twc-no-trips {
        top: -30px;
        font-size: 18px;

        i {
            font-size: 44px;
            margin-right: 15px;
        }

        button {
            margin-top: 15px;
        }
    }
}
