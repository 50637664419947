.btn-package {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    padding: 5px;
    border-radius: $border-radius-lg;
    border: 2px solid $brand-secondary;
    background: $gray-lightest;
    text-align: center;
    vertical-align: middle;
    font-weight: $semibold-font-weight;
    font-size: $font-size-small;
    cursor: pointer;
    user-select: none;

    &.btn-package-free {
        font-weight: normal;
    }

    &:not(.disabled):hover {
        background: $gray-lighter;
        color: $gray;
    }

    &.selected {
        &.selected {
            background: $brand-secondary;
            color: $gray-lightest;
            border: 2px solid $brand-secondary;
            position: relative;
            cursor: default;

            ~.jetsmart-number-selector {
                display: block;
            }

            &.ticked-button {
                &:before {
                    content: "\f00c";
                    display: inline-block;
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    color: $brand-chk;
                    font-family: Font Awesome\ 5 Free;
                    font-weight: 900;
                }
            }
        }
    }

    ~.jetsmart-number-selector {
        display: none;
    }

    &:not(.disabled):hover {
        border-color: $tertiary-btn-bg;
    }
}

@media #{$xsmall} {
    .btn-package {
        margin-bottom: 0;
    }
}