.cug-menu {
    width: 285px;
    // height: 100%;
    box-shadow: $box-shadow-medium;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;

    .menu-item {
        width: 235px;
        height: 48px;
        background: $gray-lightest;
        color: $brand-secondary;
        padding-left: 75px;
        display: flex;
        align-items: center;
        position: relative;
        border-radius: $border-radius-sm;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 600;
        transition: all 100ms;

        i {
            position: absolute;
            left: 25px;
            top: 50%;
            transform: translateY(-50%);
            font-size: $font-size-xlarge;
            font-weight: 600;
        }

        &.active {
            background: $brand-secondary;
            color: #fff;
            pointer-events: none;
        }

        &:hover {
            background: $brand-secondary;
            color: #fff;
        }

        &:first-child {
            margin-top: 20px;
        }

        &:last-child {
            margin: 56px 0;
            background: $brand-primary;
            color: #fff;

            &:hover {
                background: $brand-secondary;
                color: #fff;
            }
        }
    }
}

cug-company {
    td {
        &:first-child {
            font-family: ClanOT-Bold;
        }
    }
}

.cug-content {
    .cug-summary-table {
        width: 100%;

        td {
            vertical-align: middle;
            height: 42px;
            color: $brand-secondary;
            word-break: break-word;

            &.vertical-top {
                vertical-align: top;
            }

            &:first-child {
                font-weight: 600;
                padding-right: 20px;
            }

            &:last-child {
                font-family: "Lato", sans-serif;
            }

            .in-cell-icon {
                margin: 0 10px;
            }

            &.text-left {
                text-align: left;
            }
        }
    }

    .help-icon {
        color: $brand-secondary;
        cursor: pointer;
    }
}

.hoverable-icon {
    cursor: pointer;

    &:hover {
        color: $brand-primary;
    }
}

.cug-box {
    margin: 40px 0;
    padding: 40px;
    background: #fff;
    box-shadow: $box-shadow-medium;
    border-radius: $border-radius-lg;

    h1 {
        color: $brand-secondary;
        font-weight: 900;
        font-size: 30px;
        margin-left: 30px;
        font-family: ClanOT-Bold;

        span {
            font-size: $font-size-small;
            color: $brand-secondary;
            display: block;
            font-weight: 600;
            font-family: "Lato", sans-serif;
        }
    }

    h2 {
        color: $brand-secondary;
        font-weight: 600;

        &.bigger {
            font-size: 20px;
        }
    }

    h3 {
        color: $brand-secondary;
        font-weight: 600;
        font-size: $font-size-small;
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.cug-border-box {
    margin: 20px 0;
    border: 1px solid $gray-light;
    border-radius: $border-radius-lg;

    &.padded {
        padding: 20px 30px 0;
    }
}

.cug-background-box {
    margin: 20px;
    padding: 20px;
    border-radius: $border-radius-lg;
    background: $gray-lightest;
}

.cug-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    position: relative;

    > span:not(.flight-tooltip):not(.ac-tooltip) {
        font-size: 20px;
        color: $brand-secondary;
        line-height: 1;
        font-family: ClanOT-Medium;

        &.pointer-cursor {
            cursor: pointer;

            &:hover {
                color: $brand-primary;
            }
        }

        &:last-child:not(.flight-tooltip):not(.ac-tooltip) {
            margin-top: 30px;
            font-size: 80px;
            font-weight: 900;
            padding-bottom: 0;
        }
    }
}

.cug-info-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $brand-secondary;
    cursor: pointer;

    &.cug-register {
        right: 13px;
        top: 17px;
        z-index: 1;
    }
}

.agent-email:focus + .email-tooltip {
    display: block;
}

.agent-username:focus + .username-tooltip {
    display: block;
}

.email-tooltip, .username-tooltip {
    display: none;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    color: $gray-darkest;

    .arrow-box {
        position: relative;
        width: 320px;
        padding: 15px;
        border: 1px solid #333;
        border-radius: 6px;
        box-shadow: 5px 5px 10px #ccc;
        background: #fff;
        z-index: 10000;
        line-height: 1.1;
    }

    .arrow-box:after,
    .arrow-box:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .arrow-box:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #fff;
        border-width: 30px;
        margin-left: -30px;
    }

    .arrow-box:before {
        border-color: rgba(51, 51, 51, 0);
        border-top-color: #333;
        border-width: 31px;
        margin-left: -31px;
    }
}

.expiry-warning {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    padding: 10px;
    background: $brand-primary;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: $border-radius-sm;

    i {
        margin-right: 10px;
    }
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.miniloader {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    color: $brand-secondary;
    font-size: $font-size-small !important;
    line-height: 1;
    position: relative;
    top: -2px;

    &:after {
        content: "|";
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-animation: rotating $cug-miniloader-animation linear infinite;
        -moz-animation: rotating $cug-miniloader-animation linear infinite;
        -ms-animation: rotating $cug-miniloader-animation linear infinite;
        -o-animation: rotating $cug-miniloader-animation linear infinite;
        animation: rotating $cug-miniloader-animation linear infinite;
    }
}

.cug-mobile-link {
    margin: 0 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to right, $brand-secondary 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 7px 2px;
    background-repeat: repeat-x;

    &:hover {
        background: $brand-secondary;

        span,
        i {
            color: $gray-lightest;
        }

        &:last-child {
            span,
            i {
                color: $gray-lightest;
            }
        }
    }

    &:first-child {
        margin-top: 20px;
    }

    &:last-child {
        span,
        i {
            color: $brand-primary;
        }
    }

    span,
    i {
        font-size: $font-size-large;
        color: $brand-secondary;
        font-weight: 600;
    }
}

.cug-form-page {
    h2 {
        margin: 20px 0 0 40px;
        color: $brand-secondary;
        font-weight: 600;
        font-size: 24px;
    }

    .group-caption {
        color: $brand-secondary;
        font-weight: 600;
        font-size: $font-size-medium;
        margin-bottom: 10px;
        display: block;
    }

    .mdl-textfield {
        padding: 5px 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 9px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }
    }
}

.cug-mobile-only-nav {
    margin: 20px;
    text-align: center;

    .navidot {
        display: inline-block;
        margin: 10px 5px 20px;
        background: $cug-mobile-nav-bg;
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.active {
            background: $cug-mobile-active-nav-bg;
        }
    }
}

.cug-mobile-menu {
    margin: 0px 20px 20px;
}

.cug-button-container {
    @apply flex justify-end;
    margin: 20px 0 10px;
}

.cug-button-container-centered {
    @apply flex justify-center;
    margin: 20px 0 10px;
}

.edit-icon {
    color: $brand-secondary;
    cursor: pointer;

    &:hover {
        color: $brand-primary;
    }
}

.cug-grid-container {
    position: relative;

    .grid-search {
        position: absolute;
        top: 12px;
        right: 0;
        width: 200px;
        padding: 5px 27px 5px 5px;
        border-radius: $border-radius-sm;
        border: 1px solid $brand-secondary;
        z-index: 1;
    }

    > i {
        position: absolute;
        top: 19px;
        right: 7px;
        z-index: 2;
    }
}

confirmed-reservations-grid-container {
    .balance-due {
        color: $brand-primary;

        i {
            font-weight: 600;
        }
    }
}

.topup-container {
    min-height: 61px;
}

.topup {
    transition: width $cug-topup-transition ease-in-out;
    display: inline-block;
    width: 100%;
    text-align: right;

    &.closed {
        width: 0;
        padding: 0;
        border: none;
    }
}

.amount-warning {
    display: block;
    font-size: $font-size-xsmall;
    color: $brand-secondary;
    text-align: left;
    margin-top: 5px;

    &.closed {
        opacity: 0;
    }
}

.amount-error {
    @apply block font-body text-cug-red;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-top: 20px;

    span {
        @apply font-bold;
    }
}

.cug-summary-table {
    td {
        width: 50%;
    }
}

.cug-tutorial {
    width: 100%;
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.tutorial-video {
    width: 910px;
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    display: block;
    position: relative;
    margin: 0 auto;
}

/* MEDIA QUERIES */

@media #{$medium-down} {
    .cug-info-box {
        span:not(.flight-tooltip):not(.ac-tooltip) {
            font-size: 18px;

            &:last-child {
                font-size: 56px;
            }
        }
    }

    .cug-summary-table {
        td {
            &:first-child {
                width: 75%;
            }

            &:last-child {
                width: 25%;
            }
        }
    }
}

@media #{$medium} {
    .cug-tutorial {
        height: 390px;
    }

    .tutorial-video {
        height: 390px;
    }
}

@media #{$small} {
    .cug-menu {
        width: 225px;

        .menu-item {
            width: 195px;
            padding-left: 55px;
            font-size: $font-size-medium;

            i {
                left: 15px;
            }
        }
    }

    .cug-content {
        td {
            .in-cell-icon {
                display: block;
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }

    .cug-box {
        margin: 20px 10px;
        padding: 20px;
    }

    .cug-info-box {
        span:not(.flight-tooltip):not(.ac-tooltip) {
            font-size: $font-size-small;
        }
    }

    .expiry-warning {
        font-size: $font-size-small;
    }

    .cug-tutorial {
        height: 340px;
    }

    .tutorial-video {
        height: 340px;
    }
}

@media #{$xsmall} {
    .cug-border-box {
        &.padded {
            padding: 20px 10px 0;
        }
    }

    .cug-content {
        width: 100%;
        max-width: unset;

        td {
            .in-cell-icon {
                display: block;
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }

    .cug-box {
        max-width: 97%;
        margin: 10px 2%;
        padding: 10px;

        h1 {
            margin-left: 10px;
        }
    }

    .cug-background-box {
        margin: 10px;
        padding: 10px;
    }

    .cug-info-icon {
        top: 5px;
        right: 5px;
    }

    .cug-info-box {
        min-height: 186px;

        span:not(.flight-tooltip):not(.ac-tooltip) {
            text-align: center;
            font-size: 13px;
            max-width: 100px;

            &:last-child {
                margin-top: 20px;
            }
        }

        > span:not(.flight-tooltip):not(.ac-tooltip):last-child:not(.flight-tooltip):not(.ac-tooltip) {
            font-size: 50px;
        }
    }

    .expiry-warning {
        @apply leading-none justify-center items-center;
        text-align: center;
        font-size: 12px;
        bottom: 5.5px;
        left: 5.5px;
        right: 5.5px;
        padding: 5.5px;

        i {
            display: none;
        }
    }

    .cug-form-page {
        h2 {
            margin: 10px 0 20px 10px;
        }

        .cug-border-box {
            border: none;
        }

        .cug-background-box {
            margin: 0;
            padding: 0 10px;
            border-radius: 0;
            background: transparent;
        }
    }

    .cug-button-container {
        &.text-center-sm {
            @apply justify-center;
        }
    }

    .cug-grid-container {
        .grid-search {
            width: 126px;
        }
    }

    cug-company {
        td:first-child {
            font-size: $font-size-small;
        }
    }

    .cug-tutorial {
        height: 240px;
    }

    .tutorial-video {
        height: 240px;
    }
}
