dc-checkbox {
    @apply block w-full;

    input {
        &.dc-checkbox {
            &[type="checkbox"] {
                &[checked] {
                    + label {
                        &[for] {
                            &:after {
                                @apply absolute mt-0;
                                content: "";
                                top: 4px;
                                left: 2px;
                                width: 6px;
                                height: 12px;
                                border-right: 3px solid #008d36;
                                border-bottom: 3px solid #008d36;
                                transform: rotate(40deg);
                                transform-origin: 100% 100%;
                            }
                        }
                    }
                }

                &:not(:disabled) {
                    @apply m-0 absolute cursor-pointer;
                    z-index: 500;
                    height: 20px;
                    width: 20px;
                    opacity: 0.01;

                    + label {
                        &[for] {
                            @apply relative select-none inline-block cursor-pointer leading-none w-auto;
                            height: 20px;

                            &:before {
                                @apply border border-solid border-cug-lightest-gray absolute duration-200 top-0 left-0 mt-0 rounded-md z-0 transform-none bg-cug-gray-5;
                                content: "";
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// MEDIA QUERIES

@media #{$small} {
    dc-checkbox {
        input {
            &.dc-checkbox {
                &[type="checkbox"] {
                    &[checked] {
                        + label {
                            &[for] {
                                &:after {
                                    top: 1px;
                                    left: 0px;
                                    width: 5px;
                                    height: 10px;
                                }
                            }
                        }
                    }

                    &:not(:disabled) {
                        height: 12px;
                        width: 12px;

                        + label {
                            &[for] {
                                height: 12px;

                                &:before {
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    dc-checkbox {
        input {
            &.dc-checkbox {
                &[type="checkbox"] {
                    &[checked] {
                        + label {
                            &[for] {
                                &:after {
                                    top: 2px;
                                    left: 1px;
                                    width: 5px;
                                    height: 10px;
                                }
                            }
                        }
                    }

                    &:not(:disabled) {
                        height: 14px;
                        width: 14px;

                        + label {
                            &[for] {
                                height: 14px;
                                padding-left: 10px;

                                &:before {
                                    width: 14px;
                                    height: 14px;
                                    border-radius: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
