﻿.from-to-input-container {
    @apply relative;

    .options-opener-arrow {
        @apply flex absolute items-center justify-center pointer-events-none cursor-pointer;
        transform: rotate(90deg);
        top: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
        color: #818a91;
    }
}

.country-label {
    @apply flex items-center font-semibold absolute top-0 left-0 w-2/5 text-brand-secondary;
    height: 40px;
    padding-left: 25px;
    z-index: 10000;
}

.city-label {
    @apply flex items-center font-semibold absolute top-0 right-0 w-3/5 text-brand-secondary;
    height: 40px;
    padding-left: 25px;
    z-index: 10000;
}

.options-container {
    @apply absolute flex bg-white shadow-select;
    display: none;
    z-index: 1000;
    width: 440px;
    height: 300px;

    &:after {
        @apply top-0 bottom-0 w-px absolute;
        content: "";
        border-left: 1px solid #eceef1;
        left: 40%;
    }

    &.no-hide {
        max-height: 300px;

        &:before,
        &:after {
            display: none;
        }
    }
}

.options-opener {
    @apply relative cursor-pointer text-brand-secondary border border-solid border-brand-secondary;
    height: 54px;
    border-radius: 5px;

    &:hover {
        @apply border border-solid border-brand-secondary;
    }

    &:disabled {
        @apply border border-solid border-brand-secondary;
        background: #e2e2e2;
        cursor: not-allowed;

        &:hover {
            @apply border border-solid border-brand-secondary;
            background: #e2e2e2;
        }
    }

    &.active {
        ~ .options-container {
            @apply block;
        }
    }

    &.invalid {
        border-color: #e299a1;
        background: #f9d4d8;
    }
}

.no-hide {
    .from-to-countries {
        display: none;
    }

    .from-to-cities {
        @apply w-full top-0 left-0;
    }
}

.from-to-countries {
    @apply absolute w-2/5 overflow-hidden bottom-0 left-0;
    top: 40px;
}

.from-to-cities {
    @apply absolute w-3/5 overflow-hidden bottom-0;
    left: 40%;
    top: 40px;
}

.country-name {
    @apply font-semibold relative;
    padding-left: 25px;
}

.country-opt {
    @apply w-full flex items-center cursor-pointer relative text-brand-secondary bg-white;
    height: 40px;

    &:after {
        @apply absolute vertical-center flex items-center justify-center rounded-full text-brand-secondary border border-solid border-brand-secondary;
        content: "\276F";
        right: 25px;
        width: 20px;
        height: 20px;
    }

    &.no-hide {
        @apply block h-auto cursor-default bg-white text-brand-secondary;
        width: unset;

        &:hover {
            @apply bg-white text-brand-secondary;
        }

        .country-name {
            @apply block;
            padding: 12px 25px;

            &:after {
                display: none;
            }
        }

        &.active {
            @apply bg-white text-brand-secondary;

            .country-name {
                &:after {
                    display: none;
                }
            }
        }
    }

    &.active,
    &:hover {
        @apply text-white bg-brand-secondary;

        &:after {
            @apply text-white border-white;
        }
    }
}

.region-opt {
    @apply whitespace-nowrap text-brand-secondary bg-white;
    min-height: 54px;
    line-height: 34px;

    &.no-label {
        @apply min-h-0 pt-0;
    }
}

.region-name {
    @apply font-semibold;
    padding-left: 25px;
}

.station-opt {
    @apply relative flex items-center w-full whitespace-normal leading-none cursor-pointer text-brand-secondary bg-white;
    min-height: 40px;
    padding: 5px 55px 5px 25px;

    &:hover,
    &.selected {
        @apply text-white bg-brand-secondary;

        span {
            @apply text-white;
        }
    }
}

.station-new {
    @apply absolute font-semibold uppercase text-brand-secondary;
    top: 14px;
    right: 10px;
    font-size: 12px;
}

/* MEDIA QUERIES */

@media #{$small-down} {
    .search-container {
        .form-group {
            &.from-to-input-container {
                margin-bottom: 15px;
            }
        }
    }

    .mobile-scroller {
        @apply w-full h-full overflow-y-scroll;
    }

    .options-container {
        @apply w-auto;
        left: 0.5rem;
        right: 0.5rem;
        text-align: left;

        &:after {
            display: none;
        }
    }

    .mobile-regions {
        @apply overflow-hidden common-transition;
        max-height: 0;
    }

    .country-opt {
        &.active {
            + .mobile-regions {
                @apply max-h-screen;
            }

            &:after {
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    .station-opt {
        @apply pl-12;
    }
}
