@import "./giftcard-colors";

.giftcard-activation-header {
    position: relative;
    width: 100%;
    margin: 55px 0 40px;
    text-align: center;
    line-height: 1;

    i {
        position: absolute;
        top: -23px;
        left: 50%;
        transform: translateX(260px);
        font-size: 39px;
        color: $giftcard-cyan;
    }

    span {
        &:nth-child(2) {
            font-family: "Lato", sans-serif;
            font-weight: 900;
            font-size: 42px;
            color: $giftcard-blue;
            margin-right: 10px;
        }

        &:nth-child(3) {
            color: $giftcard-red;
            text-transform: uppercase;
            font-family: "ClanOT-Bold", sans-serif;
            font-size: 59px;
        }

        &:nth-child(4) {
            text-transform: uppercase;
            font-family: "ClanOT-Book", sans-serif;
            font-size: 54px;
            color: $giftcard-blue;
        }
    }

    .giftcard-header-logo {
        position: absolute;
        top: 49px;
        left: 50%;
        transform: translateX(182px);
        width: 93px;
        height: 21px;
        display: block;

        img {
            height: 21px;
        }
    }
}

.giftcard-activation-breadcrumbs {
    color: $giftcard-cyan4;
    display: flex;
    align-items: center;
    justify-content: center;

    span:not(.step) {
        width: 22px;
        height: 1px;
        border-bottom: 1px solid $giftcard-cyan4;

        + span {
            margin-left: 10px;
        }
    }

    .step {
        width: 47px;
        height: 47px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $giftcard-cyan4;
        font-family: "Lato", sans-serif;
        font-size: 26px;
        font-weight: 600;
        border: 1px solid $giftcard-cyan4;
        margin: 0 15px;

        &.active,
        &.past {
            background: $giftcard-cyan4;
            color: #fff;
        }
    }
}

.giftcard-activation-step-info {
    color: $giftcard-cyan4;
    font-family: "Lato", sans-serif;
    font-size: 27px;
    font-weight: 600;
    font-style: italic;
    text-align: center;
    margin: 20px 0 100px;

    &.pull-up {
        margin-bottom: 45px;
    }
}

.step-form {
    display: none;

    &.active {
        display: block;
    }
}

.gca-btn-container {
    @apply flex justify-end;
    padding-bottom: 100px;

    .rounded-secondary-btn {
        margin: 0 15px 0 0;
    }
}

.gca-error {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    color: $giftcard-red2;
    text-align: center;
    margin-bottom: 35px;
    line-height: 1.4;
}

.gca-container {
    width: 575px;
    margin: 0 auto;
    max-width: 100%;

    .form-captcha-container {
        margin-bottom: 35px;
        text-align: right;

        > div {
            display: inline-block;
        }
    }
}

// Activation code page
.gca-enter-code {
    width: 745px;
    margin: 0 auto;
    max-width: 95%;

    input {
        height: 75px;
        border: 1px solid $giftcard-darkgray;
        border-radius: 5px;
        font-family: "Lato", sans-serif;
        font-size: 28px;
        padding: 0 25px;
        margin-bottom: 35px;

        &::placeholder {
            color: #3d3d3d;
        }
    }
}

.gca-enter-code-label {
    position: relative;
    font-family: "Lato", sans-serif;
    font-size: 25px;
    color: $giftcard-darkgray;
    margin-bottom: 20px;
    display: inline-block;

    .gca-enter-code-tooltip {
        position: absolute;
        top: -60px;
        right: 0;
        transform: translateX(270px);
        background: $giftcard-blue;
        border-radius: 5px;
        padding: 10px;
        z-index: 0;
        display: none;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            left: -15px;
            width: 30px;
            height: 30px;
            background: $giftcard-blue;
            z-index: -1;
        }
    }

    .gca-enter-code-tooltip-trigger {
        position: absolute;
        top: -15px;
        right: 0;
        transform: translateX(20px);
        width: 20px;
        height: 20px;
        background: $giftcard-blue;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;

        &:active,
        &:focus,
        &:hover {
            + .gca-enter-code-tooltip {
                display: block;
            }
        }
    }
}

// Email/RUT/Password page
.gca-enter-email,
.gca-enter-rut,
.gca-enter-password {
    width: 745px;
    margin: 0 auto;
    max-width: 95%;

    .giftcard-activation-step-info {
        margin: 20px 0 35px;
    }

    input {
        height: 75px;
        border: 1px solid $giftcard-darkgray;
        border-radius: 5px;
        font-family: "Lato", sans-serif;
        font-size: 28px;
        padding: 0 25px;
        margin-bottom: 15px;

        &::placeholder {
            color: #3d3d3d;
        }
    }

    .gca-email-link {
        font-family: "Lato", sans-serif;
        font-size: 17px;
        color: $giftcard-blue;
        text-decoration: underline;
        margin-bottom: 35px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: $giftcard-cyan;
        }
    }

    .gca-email-warning {
        font-family: "Lato", sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: $giftcard-blue;
        margin-bottom: 50px;

        span {
            font-weight: 900;
        }
    }
}

.gca-enter-password {
    input {
        margin-top: 60px;
    }

    .gca-password-link {
        font-family: "Lato", sans-serif;
        font-size: 16px;
        font-weight: 900;
        color: $giftcard-blue;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: $giftcard-cyan;
        }
    }
}

.modal {
    &.giftcard-activation-modal {
        .modal-content {
            width: 657px;
            max-width: 95%;
            padding: 70px 95px 70px;

            &.blocking {
                @apply p-8;

                .giftcard-modal-title {
                    @apply not-italic uppercase;
                }
            }
        }

        .giftcard-header {
            margin: 0 0 30px 0;
            padding: 0;
            border: none;

            .giftcard-header-logo {
                top: 60px;
            }

            i {
                top: -10px;
            }
        }

        .modal-close {
            position: absolute;
            top: -17px;
            right: -17px;
            width: 34px;
            height: 34px;
            background: $gray-darkest;
            color: #fff;
            border-radius: 50%;
            border: none;
            font-size: 26px;
            z-index: 100;
            user-select: none;
            padding: 0;
            line-height: 1;
            cursor: pointer;

            &:not(disabled):hover {
                border: none;
                background: $brand-secondary;
                color: #fff;
            }
        }
    }
}

.giftcard-modal-btn-container {
    @apply flex justify-center;

    .rounded-secondary-btn {
        margin: 0 15px 0 0;
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    ac-giftcard-activation-container {
        .booking-wrapper {
            max-width: 95%;
            margin: 25px auto;
        }
    }

    .giftcard-activation-header {
        max-width: 290px;
        margin: 10px auto 40px;

        &.portuguese {
            max-width: 250px;
        }

        i {
            font-size: 27px;
            top: 24px;
            transform: translateX(90px);
        }

        span {
            &:nth-child(2) {
                font-size: 30px;
                line-height: 1.3;
            }

            &:nth-child(3) {
                font-size: 41px;
            }

            &:nth-child(4) {
                font-size: 38px;
            }
        }

        .giftcard-header-logo {
            width: 63px;
            height: 15px;
            top: 74px;
            transform: translateX(32px);

            img {
                height: 15px;
            }
        }
    }

    .giftcard-activation-breadcrumbs {
        .step {
            width: 38px;
            height: 38px;
            font-size: 23px;
            margin: 0 8px;
        }

        span:not(.step) {
            width: 18px;

            + span {
                margin-left: 8px;
            }
        }
    }

    .giftcard-activation-step-info {
        font-size: 22px;
    }

    .gca-enter-email,
    .gca-enter-rut,
    .gca-enter-password {
        .giftcard-activation-step-info {
            margin: 20px 0;
        }
    }

    .gca-enter-code-label {
        font-size: 18px;
    }

    .gca-enter-code {
        input {
            height: 60px;
            font-size: 20px;
        }
    }

    .gca-btn-container {
        padding-bottom: 35px;
    }

    .gca-enter-email,
    .gca-enter-rut,
    .gca-enter-password {
        input {
            height: 60px;
            font-size: 20px;
        }

        .gca-email-link {
            font-size: 16px;
            margin-bottom: 15px;
        }

        .gca-email-warning {
            font-size: 16px;
            margin-bottom: 40px;
        }
    }

    .gca-register {
        register {
            input,
            select {
                height: 43px;
                font-size: 14px;
            }

            .mdl-textfield--floating-label {
                .mdl-textfield__label {
                    top: 4px;
                }
            }

            h1 {
                font-size: 13px;
                margin: 12px 0;
                line-height: 1;
            }

            .js-select-arrow {
                &:after {
                    top: 19px;
                }
            }

            .birthdate-container {
                padding-top: 16px;
                margin-bottom: 0;

                .input-inner-title {
                    top: -1px;
                }
            }
        }
    }

    .modal {
        &.giftcard-activation-modal {
            .giftcard-header {
                i {
                    top: 6px;
                }

                .giftcard-header-logo {
                    top: 53px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    ac-giftcard-activation-container {
        .booking-wrapper {
            max-width: 95%;
            margin: 65px auto 15px;
        }
    }

    .giftcard-activation-header {
        max-width: 180px;
        margin: 0 auto 30px;

        &.portuguese {
            max-width: 170px;
        }

        i {
            font-size: 18px;
            top: 13px;
            transform: translateX(59px);
        }

        span {
            &:nth-child(2) {
                font-size: 18px;
                line-height: 1.3;
            }

            &:nth-child(3) {
                font-size: 27px;
            }

            &:nth-child(4) {
                font-size: 25px;
            }
        }

        .giftcard-header-logo {
            width: 43px;
            height: 10px;
            top: 43px;
            transform: translateX(21px);

            img {
                height: 10px;
            }
        }
    }

    .giftcard-activation-breadcrumbs {
        position: absolute;
        top: -43px;
        left: 0;
        right: 0;

        .step {
            width: 25px;
            height: 25px;
            font-size: 14px;
            margin: 0 3px;
        }

        span:not(.step) {
            width: 2px;
            height: 2px;
            background: $giftcard-cyan4;
            border: none;
            border-radius: 50%;

            + span {
                margin-left: 3px;
            }
        }
    }

    .giftcard-activation-step-info {
        display: none;
    }

    .gca-enter-code-label {
        font-size: 13px;
        margin-bottom: 8px;

        .gca-enter-code-tooltip {
            top: -134px;
            transform: translateX(104px);

            &:after {
                top: calc(100% - 15px);
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
            }

            img {
                width: 170px;
            }
        }
    }

    .gca-enter-code {
        input {
            height: 43px;
            font-size: 14px;
            margin-bottom: 20px;
        }
    }

    .gca-btn-container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding-bottom: 15px;

        .rounded-secondary-btn {
            margin-right: 0;
            margin-top: 15px;
        }
    }

    .gca-enter-email,
    .gca-enter-rut,
    .gca-enter-password {
        input {
            height: 43px;
            font-size: 14px;
            margin-bottom: 15px;
            margin-top: 0;
        }

        .gca-email-link {
            font-size: 10px;
            margin-bottom: 8px;
        }

        .gca-email-warning {
            font-size: 10px;
            margin-bottom: 20px;
            line-height: 11px;
        }

        .gca-password-link {
            font-size: 10px;
            margin-bottom: 25px;
            font-weight: 400;
        }

        .gca-enter-code-label {
            font-size: 13px;
            margin-bottom: 8px;
        }
    }

    .gca-register {
        register {
            input,
            select {
                height: 43px;
                font-size: 14px;
            }

            .mdl-textfield--floating-label {
                .mdl-textfield__label {
                    top: 4px;
                }
            }

            h1 {
                font-size: 13px;
                margin: 10px 0;
                line-height: 1;
                font-weight: 600;
            }

            .js-select-arrow {
                &:after {
                    top: 19px;
                }
            }

            .birthdate-container {
                padding-top: 20px;
                margin-bottom: 0;

                .input-inner-title {
                    top: -10px;
                    font-size: 13px;
                    margin: 10px 0;
                    line-height: 1;
                    font-weight: 600;
                }
            }
        }
    }

    .modal {
        &.giftcard-activation-modal {
            .giftcard-header {
                i {
                    top: -24px;
                }

                .giftcard-header-logo {
                    top: 15px;
                }
            }
        }
    }

    .giftcard-modal-btn-container {
        @apply flex items-center flex-col-reverse;

        .rounded-secondary-btn {
            @apply mr-0;
            margin-top: 20px;
        }
    }
}
