@import "./giftcard-colors";

.giftcard-header {
    position: relative;
    width: 100%;
    height: 88px;
    margin: 55px 0 45px;
    padding-bottom: 45px;
    border-bottom: 1px solid $giftcard-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $giftcard-red;
    text-transform: uppercase;
    font-family: "ClanOT-Bold", sans-serif;
    font-size: 59px;

    i {
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(120px);
        font-size: 39px;
        color: $giftcard-cyan;
    }

    span {
        font-family: "ClanOT-Book", sans-serif;
        font-size: 54px;
        color: $giftcard-blue;
    }

    .giftcard-header-logo {
        position: absolute;
        top: 36px;
        left: 50%;
        transform: translateX(45px);
        width: 93px;
        height: 21px;
        display: block;

        img {
            height: 21px;
        }
    }
}

.giftcard-section-header {
    display: flex;
    align-items: center;

    &.push-down {
        margin-top: 35px;
    }

    i {
        color: $giftcard-cyan;
        font-size: 50px;
        margin-right: 15px;
    }

    .giftcard-section-title {
        font-family: "Lato", sans-serif;
        font-weight: 900;
        color: $giftcard-blue;
        font-size: 22px;
        line-height: 1;
    }

    .giftcard-section-subtitle {
        font-family: "Lato", sans-serif;
        color: $giftcard-blue;
        font-size: 14px;
        line-height: 1;
    }
}

.booking-wrapper {
    &.giftcard-wrapper {
        padding: 25px 80px 80px 80px;
        margin-bottom: 35px;

        .mdl-textfield {
            padding: 20px 0 0;
        }

        .js-input {
            &.js-select {
                font-size: 20px;
                padding-left: 15px;
                padding-top: 8px;
                color: #163a70;
                -webkit-appearance: none;

                option {
                    font-size: $font-size-medium;
                }
            }
        }

        .js-select-arrow {
            &:after {
                content: "\25BC";
                display: block;
                position: absolute;
                top: 39px;
                right: 15px;
                font-size: 12px;
                color: #ccc;
            }
        }
    }
}

.giftcard-selection-form-inputs {
    margin-bottom: 10px;
}

.giftcard-terms-and-conditions-link {
    @apply underline;
    color: #59c3d9;

    &:hover {
        @apply text-brand-primary no-underline;
    }
}

.giftcard-terms-and-conditions-text {
    @apply text-brand-secondary;
}

.giftcard-terms-and-conditions-text,
.giftcard-terms-and-conditions-link {
    @apply font-body font-normal;
    font-size: 19px;
}

.basic-checkbox-wrapper {
    &.giftcard {
        margin-top: 25px;
        min-height: unset;

        .cb-title {
            @apply font-body font-normal text-brand-secondary;
            font-size: 19px;
        }

        [type="checkbox"] + label[for] {
            line-height: 25px;
        }

        label {
            @apply block;
            &:after {
                @apply top-0;
            }
        }
    }
}

.giftcard-section-btn-container {
    @apply flex justify-end;
    padding-bottom: 100px;

    .rounded-secondary-btn {
        margin: 0 15px 0 0;
    }
}

.giftcard-design-selector {
    .giftcard-design-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 0px;
        white-space: nowrap;
        transition: margin-left 0.25s ease-in-out;
    }

    .giftcard-design-option {
        text-align: center;
        display: inline-block;
        width: 23.5%;
        white-space: normal;
    }

    img {
        margin: 25px 0 20px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        width: 100%;

        &:hover {
            transform: scale(1.1);
            box-shadow: $box-shadow-medium;
        }
    }

    [type="radio"] {
        &:checked {
            + label {
                padding-left: 20px;

                &:before {
                    border: 1px solid $giftcard-gray;
                }

                &:after {
                    border: 1px solid $giftcard-gray;
                    background-color: $giftcard-blue;
                    z-index: 0;
                    -webkit-transform: scale(0.66);
                    -ms-transform: scale(0.66);
                    transform: scale(0.66);
                }
            }
        }

        &:not(:checked) {
            + label {
                padding-left: 20px;

                &:before {
                    border: 2px solid $giftcard-gray;
                }
            }
        }
    }
}

.giftcard-user-message-container {
    position: relative;
    margin-top: 20px;
    border: 1px solid $giftcard-darkgray;
    border-radius: 5px;
    padding: 10px;

    &.invalid {
        background: $invalid-bg;

        textarea {
            &.giftcard-user-message {
                background: $invalid-bg;
            }
        }
    }

    textarea {
        &.giftcard-user-message {
            padding: 10px 0;
            background: #fff;
            border: none;
            color: #979797;
            font-family: "Lato", sans-serif;
            font-size: 18px;
            min-height: 56px;
            max-height: 56px;
            -webkit-resize: none;
            resize: none;
        }
    }

    .giftcard-user-message-label {
        color: $giftcard-blue;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        font-weight: 900;
    }
}

.giftcard-design-wrapper {
    position: relative;
    max-height: 0;
    transition: max-height 0.25s ease-in-out;
    overflow: hidden;

    &.open {
        max-height: 450px;
        animation-name: overflowSwitch;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
}

.giftcard-error {
    margin: 25px 0;
    color: $giftcard-red;
    font-family: "Lato", sans-serif;
    font-size: 20px;

    &.pull-up {
        margin-bottom: 0;
    }
}

.giftcard-amount-warning {
    background: $giftcard-cyan;
    color: #fff;
    border-radius: 5px;
    font-family: "Lato", sans-serif;
    font-size: 14x;
    padding: 8px 15px;
    margin-top: 25px;
}

.design-scroll-left,
.design-scroll-right {
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    border-left: 3px solid $giftcard-blue;
    border-bottom: 3px solid $giftcard-blue;
    background: #fff;
    z-index: 10000;
}

.design-scroll-left {
    left: -20px;
}

.design-scroll-right {
    right: -20px;
}

.message-length-counter {
    position: absolute;
    right: 10px;
    bottom: 65px;
    font-size: 12px;
    font-weight: 600;

    &.warning {
        color: $banco-estado-color;
    }

    &.error {
        color: $giftcard-red;
    }
}

.forced-gc-be {
    width: 745px;
    margin: 0 auto;
    max-width: 95%;

    .forced-gc-be-info {
        font-family: "Lato", sans-serif;
        font-size: 16px;
        font-weight: 900;
        color: $giftcard-blue;
        margin-bottom: 20px;
    }

    .fgb-container {
        width: 575px;
        margin: 0 auto;
        max-width: 100%;

        input {
            border-color: $banco-estado-color;
        }

        .fgb-password-link {
            font-family: "Lato", sans-serif;
            font-size: 16px;
            font-weight: 900;
            color: $banco-estado-color;
            text-decoration: underline;
            margin: 35px 0;
            cursor: pointer;

            &:hover {
                color: $brand-primary;
                text-decoration: none;
            }
        }

        .fgb-cancel-link {
            font-family: "Lato", sans-serif;
            font-size: 16px;
            font-weight: 600;
            color: $giftcard-blue;
            margin: 0 0 35px;

            span {
                font-weight: 600;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: $brand-secondary;
                    text-decoration: none;
                }
            }
        }
    }

    .fgb-btn-container {
        @apply flex justify-center;
        padding-bottom: 100px;

        .rounded-secondary-btn {
            margin: 0 15px 0 0;
        }
    }
}

// DEVNOTE Hack to overcome the different overflow setting css bug
@keyframes overflowSwitch {
    0% {
        overflow: hidden;
    }
    100% {
        overflow: visible;
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .xs-order-1 {
        order: 1;
    }

    .xs-order-2 {
        order: 2;
    }

    .xs-order-3 {
        order: 3;
    }
}

@media #{$small} {
    .giftcard-header {
        border: none;
        margin: 25px 0 0;
        font-size: 41px;

        span {
            font-size: 37px;
        }

        i {
            top: -14px;
            transform: translateX(85px);
            font-size: 27px;
        }

        .giftcard-header-logo {
            top: 31px;
            transform: translateX(31px);

            img {
                height: 15px;
            }
        }
    }

    .giftcard-section-header {
        .giftcard-section-title {
            font-size: 18px;
        }
    }

    .mdl-checkbox-wrapper {
        &.giftcard {
            .cb-title {
                font-size: 16px;

                a {
                    font-size: 16px;
                }
            }
        }
    }

    .giftcard-design-wrapper {
        overflow: unset;
        overflow-y: hidden;

        &.open {
            animation-name: none;
            animation-duration: unset;
            animation-fill-mode: unset;
        }
    }

    .giftcard-design-selector {
        position: relative;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 10px;
        overflow-x: hidden;

        .giftcard-design-container {
            &.fourth {
                margin-left: -33.33%;
            }
        }

        .giftcard-design-option {
            text-align: center;
            display: inline-block;
            width: 33.3%;
            min-width: 33.3%;
            padding: 0 10px;
        }

        img {
            z-index: 10001;

            &:hover {
                transform: none;
                box-shadow: none;
            }
        }
    }

    .design-scroll-left {
        -webkit-transform: translate3d(0, 0, 0) translateY(-35px) rotate(45deg);
        transform: translateY(-35px) rotate(45deg);
        left: 5px;
    }

    .design-scroll-right {
        -webkit-transform: translate3d(0, 0, 0) translateY(-35px) rotate(-135deg);
        transform: translateY(-35px) rotate(-135deg);
        right: 5px;
    }

    .xs-order-1 {
        order: 1;
    }

    .xs-order-2 {
        order: 2;
    }

    .xs-order-3 {
        order: 3;
    }
}

@media #{$xsmall} {
    // Safari hack for elements disappearing on scroll
    giftcard-select-page {
        * {
            &:not(label) {
                &:not(.mdl-textfield) {
                    &:not(select) {
                        &:not(.design-scroll-right) {
                            &:not(.design-scroll-left) {
                                &:not(i) {
                                    &:not(.giftcard-header-logo) {
                                        -webkit-transform: translate3d(0, 0, 0);
                                        transform: translate3d(0, 0, 0);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .booking-wrapper {
        &.giftcard-wrapper {
            @apply bg-white;
            margin: 15px;
            width: unset;
            padding: 30px 15px 50px 15px;
        }
    }

    .giftcard-header {
        margin: 30px;
        height: unset;
        width: unset;
        border: none;
        padding: 0;
        font-size: 35px;

        span {
            font-size: 32px;
        }

        i {
            font-size: 23px;
            top: -25px;
            -webkit-transform: translateX(72px) translate3d(0, 0, 0);
            transform: translateX(72px) translate3d(0, 0, 0);
        }

        .giftcard-header-logo {
            top: 14px;
            -webkit-transform: translateX(27px) translate3d(0, 0, 0);
            transform: translateX(27px) translate3d(0, 0, 0);

            img {
                height: 13px;
            }
        }
    }

    .giftcard-section-header {
        align-items: flex-start;

        .giftcard-section-title {
            font-size: 13px;
        }

        .giftcard-section-subtitle {
            font-size: 12px;
        }

        i {
            font-size: 30px;
        }
    }

    .giftcard-design-wrapper {
        &.open {
            max-height: 800px;
            animation-name: none;
            animation-duration: unset;
            animation-fill-mode: unset;
        }
    }

    .giftcard-design-selector {
        width: 90%;
        margin: 0 auto;
        position: relative;
        overflow-x: hidden;
        background: #fff;

        .giftcard-design-container {
            &.second {
                margin-left: -100%;
            }

            &.third {
                margin-left: -200%;
            }

            &.fourth {
                margin-left: -300%;
            }
        }

        .giftcard-design-option {
            text-align: center;
            display: inline-block;
            width: 100%;
            min-width: 100%;
            padding: 0 10px;
            background: #fff;
        }

        img {
            &:hover {
                transform: none;
                box-shadow: none;
            }
        }
    }

    .design-scroll-left {
        left: 5px;
    }

    .design-scroll-right {
        right: 5px;
    }

    .design-scroll-left {
        -webkit-transform: translateY(-60px) rotate(45deg) translate3d(0, 0, 0);
        transform: translateY(-60px) rotate(45deg) translate3d(0, 0, 0);
    }

    .design-scroll-right {
        -webkit-transform: translateY(-60px) rotate(-135deg) translate3d(0, 0, 0);
        transform: translateY(-60px) rotate(-135deg) translate3d(0, 0, 0);
    }

    .giftcard-error {
        margin: 30px 0 25px 0;
    }

    .mdl-checkbox-wrapper {
        &.giftcard {
            margin: 20px 0 0 15px;

            .cb-title {
                font-size: 14px;

                a {
                    font-size: 13px;
                }
            }
        }
    }

    .giftcard-user-message-container {
        .giftcard-user-message-label {
            font-size: 13px;
        }

        textarea {
            &.giftcard-user-message {
                font-size: 13px;
                min-height: 100px;
                max-height: 100px;
            }
        }
    }

    .giftcard-section-btn-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;

        .rounded-secondary-btn {
            margin: 15px 0 0;
        }
    }

    .forced-gc-be {
        .mdl-textfield--floating-label {
            .mdl-textfield__label {
                z-index: 1;
            }
        }

        .fgb-btn-container {
            @apply flex flex-col-reverse items-center;

            .rounded-secondary-btn {
                @apply mr-0;
                margin-top: 20px;
            }
        }
    }

    .message-length-counter {
        bottom: 109px;
    }

    .xs-order-1 {
        order: 1;
    }

    .xs-order-2 {
        order: 2;
    }

    .xs-order-3 {
        order: 3;
    }
}
