.no-scroll {
    overflow: hidden !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .main-header {
        height: 70px !important;
    }
}

.no-scroll2 {
    overflow: hidden !important;
    padding-right: 10px;
}

.new-optional-label {
    position: absolute;
    top: 30px;
    right: 25px;
    padding: 5px 20px;
    background: #00abcd;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:after {
        content: "";
        width: 10px;
        height: 10px;
        background: #fff;
        transform: rotate(45deg);
        position: absolute;
        top: -5px;
        right: -5px;
    }

    span {
        text-transform: uppercase;
        color: #fff;
        font-family: "Lato", sans-serif;

        &:first-child {
            font-size: 18px;
            font-weight: 900;
        }

        &:last-child {
            font-weight: 600;
            font-size: 14px;
        }
    }
}

.extras-page {
    .booking-wrapper {
        &.forced-flexi-fee {
            border: 2px solid $brand-tertiary;
        }

        &.insurance-container {
            @apply p-0;
        }

        header {
            align-items: flex-start;

            &.insurance-header {
                @apply items-center;
            }

            .title {
                margin-top: 3px;
            }

            .subtitle {
                margin-bottom: $margin-small;

                &.pull-up {
                    margin-bottom: 0;
                }

                &.short {
                    max-width: 650px;

                    .footnote {
                        font-size: 13px;
                    }
                }

                &.condensed {
                    margin-bottom: 0;
                }
            }
        }
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 24px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }

    .mdl-textfield {
        padding: 5px 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 9px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }
    }

    .outer-box {
        position: relative;
        margin-bottom: $margin-xlarge;
        padding: 15px;
        border: 1px solid $brand-secondary;
        border-radius: $border-radius-lg;
        background: white; //margin: 12px;
    }

    .filled-column {
        box-sizing: border-box;
        padding: 0;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        max-width: calc(50% - 1rem);
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
        background: $gray-lightest;

        &.invalid {
            background: $invalid-bg;
            border-left: 1px solid $invalid-border-color;
            border-right: 1px solid $invalid-border-color;
        }
    }

    .inner-box {
        min-height: 90px;
        padding: 0 0 15px;
        border-radius: $border-radius-lg;
        background: $gray-lightest;
        border: none;
        margin-bottom: 0;

        &.padded {
            padding: 15px 0;
        }

        &.upper-half {
            padding-bottom: 0;
            min-height: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            h3 {
                margin-bottom: 0;
            }
        }

        &.bottom-half {
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            &.padded-bottom-half {
                padding-top: 15px;
            }
        }

        h3 {
            margin-bottom: 15px;
            padding: 15px 15px 0;
            line-height: 21px;
            font-size: 18px;
            font-weight: $semibold-font-weight;
            color: $brand-secondary;
        }
    }

    .inner-border-box {
        position: relative;
        margin: 0 15px 15px;
        padding: 15px;
        border: 1px solid $brand-secondary;
        border-radius: $border-radius-lg;
        background: white; //margin: 12px;

        &.sold-out {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: $border-radius-lg;
                background: rgba(255, 255, 255, 0.8);
                z-index: 1000;
            }
        }

        .sold-out-warning {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 30px;
            border-radius: $border-radius-lg;
            z-index: 1001;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $brand-tertiary;
            font-family: "ClanOT-Medium", sans-serif;
            font-size: 16px;
            text-align: center;
            line-height: 1.6;
        }
    }

    priority-boarding {
        &:last-child {
            .inner-border-box {
                margin-bottom: 0;
            }
        }
    }

    .extras-binary-name {
        display: flex;
        align-items: center;
        height: 100%;

        &.pet {
            height: unset;
        }
    }

    .insurance-old {
        .insurance-accept-checkbox-wrapper {
            display: block;
            font-size: 12px;
            color: $information-small-content-color;
            float: right;
            .insurance-accept-checkbox-label {
                background: $gray-lightest;
            }
        }

        .chubb-logo {
            position: absolute;
            top: 20px;
            right: 30px;
            display: block;
            background: #fff url("../images/chubb-logo.png") no-repeat center right;
            background-size: contain;
            width: 100px;
            height: 32px;
        }

        .chubb-info {
            position: absolute;
            display: block;
            top: 28px;
            right: 140px;
            font-size: 12px;
        }

        .insurance-info {
            font-weight: 600;
            font-size: $font-size-small;
            color: $brand-secondary;
            margin: 0 0 15px 15px;
        }

        .insurance-passengers-form {
            padding: 0 15px;

            .passenger-title {
                margin: 10px 0 0;
            }
        }
    }
}

.insurance-title {
    @apply flex items-start;
}

.insurance-opened-baggage-insurance-container {
    @apply ml-10 bg-be-light-gray rounded-b-lg border border-solid border-be-light-gray;
}

.online-checkin-help {
    @apply absolute font-semibold;
    top: -7px;
    right: -18px;

    .ac-tooltip {
        @apply bg-brand-secondary;
        width: 13px;
        height: 13px;
        padding-left: 1px;
    }
}

.extras-page {
    .checkin-form {
        label {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &.invalid {
            border: 1px solid $invalid-border-color;
            border-radius: $border-radius-lg;
            background: $invalid-bg;

            &.upper-half {
                border-bottom-color: $invalid-bg;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-left: none;
                border-right: none;
            }

            &.bottom-half {
                border-top-color: $invalid-bg;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .inner-box {
                background: $invalid-bg;
            }
        }
    }
}

.pet-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.no-more-pets-warning {
    color: $brand-primary;
    font-size: $font-size-xsmall;
    font-weight: 600;
    margin-bottom: 5px;
}

a {
    &:not(.btn) {
        &.pet-terms-link {
            @apply underline text-be-blue block;
            margin: 15px 0 0 15px;

            &:hover {
                @apply text-brand-secondary;
            }
        }
    }
}

.covid-insurance-banner {
    @apply bg-be-cyan-2 flex items-center text-white font-body font-semibold;
    margin: 0 -25px 20px;
    height: 73px;
    font-size: 18px;
    padding-left: 55px;
    line-height: 1.3;

    i {
        font-size: 54px;
    }

    .insurance-banner-text {
        @apply w-full inline-block;
        padding: 0 25px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .extras-page {
        .booking-wrapper {
            header {
                .subtitle {
                    &.short {
                        max-width: 510px;
                    }
                }
            }
        }
    }
}

@media #{$medium} {
    .extras-page {
        .booking-wrapper {
            header {
                .subtitle {
                    &.short {
                        max-width: 360px;
                    }
                }
            }
        }
    }

    .new-optional-label {
        span {
            &:first-child {
                font-size: 16px;
            }

            &:last-child {
                font-size: 12px;
            }
        }
    }
}

@media #{$small} {
    .no-scroll2 {
        padding-right: 0;
    }

    .extras-page {
        .booking-wrapper {
            header {
                .subtitle {
                    &.short {
                        max-width: 420px;
                    }
                }
            }
        }
    }

    .new-optional-label {
        span {
            &:first-child {
                font-size: 16px;
            }

            &:last-child {
                font-size: 12px;
            }
        }
    }

    .covid-insurance-banner {
        margin: 0 -25px 15px;
        height: 53px;
        font-size: 13px;
        padding-left: 30px;

        i {
            font-size: 40px;
        }

        .insurance-banner-text {
            padding: 0 10px;
        }
    }
}

@media #{$xsmall} {
    .no-scroll2 {
        padding-right: 0;
    }

    .no-scroll3 {
        overflow: hidden !important;
    }

    .new-optional-label {
        top: -20px;
        right: 0;

        span {
            &:first-child {
                font-size: 16px;
            }

            &:last-child {
                font-size: 12px;
            }
        }
    }

    .extras-page {
        .booking-wrapper {
            header {
                .subtitle {
                    @apply mb-0;

                    &.short {
                        max-width: 100%;
                    }
                }
            }
        }

        .checkin-form {
            margin-bottom: $common-gap;

            &.invalid {
                &.bottom-half {
                    border-top: 1px solid $invalid-border-color;
                    border-top-left-radius: $border-radius-lg;
                    border-top-right-radius: $border-radius-lg;
                }
            }
        }

        .inner-box {
            &.bottom-half {
                border-top-left-radius: $border-radius-lg;
                border-top-right-radius: $border-radius-lg;

                &.padded-bottom-half {
                    padding-top: 0;
                }
            }
        }

        .chubb {
            display: flex;
            justify-content: flex-end;

            .chubb-info {
                position: relative;
                display: inline-flex;
                margin-bottom: 20px;
                top: 0px;
                right: 10px;
            }

            .chubb-logo {
                position: relative;
                display: inline-flex;
                top: -7px;
                right: 0px;
            }
        }

        .insurance-terms-container {
            @apply w-full px-1;
        }
    }

    .covid-insurance-banner {
        @apply font-normal;
        margin: 0 -25px 12px;
        height: 53px;
        font-size: 12px;
        padding-left: 10px;

        i {
            font-size: 40px;
        }

        .insurance-banner-text {
            padding: 0 10px;
        }
    }
}
