.basic-checkbox-wrapper {
    .dc-banner-terms-label {
        @apply flex items-center;

        height: 44px;
    }
}

@media #{$xsmall} {
    .basic-checkbox-wrapper {
        .dc-banner-terms-label {
            @apply block gap-0;

            font-size: 13px;

            &:after {
                @apply top-0;
            }
        }
    }
}
