/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "jetsmart-covid";
    src: url("../fonts/jetsmart-covid.eot?n07mp0");
    src: url("../fonts/jetsmart-covid.eot?n07mp0#iefix") format("embedded-opentype"),
        url("../fonts/jetsmart-covid.ttf?n07mp0") format("truetype"),
        url("../fonts/jetsmart-covid.woff?n07mp0") format("woff"),
        url("../fonts/jetsmart-covid.svg?n07mp0#jetsmart-covid") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-covid {
    font-family: "jetsmart-covid" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-cv-dollar-sign:before {
    content: "\e91b";
}
.js-cv-eye-icon:before {
    content: "\e919";
}
.js-cv-file-icon:before {
    content: "\e91a";
}
.js-cv-cat-xl-seat:before {
    content: "\e918";
}
.js-cv-cat1-seat:before {
    content: "\e917";
}
.js-cv-filled-plane-up:before {
    content: "\e916";
}
.js-cv-waiting:before {
    content: "\e915";
}
.js-cv-plane-down:before {
    content: "\e913";
}
.js-cv-plane-up:before {
    content: "\e914";
}
.js-cv-timer:before {
    content: "\e912";
}
.js-cv-covid-shield-plane:before {
    content: "\e90f";
}
.js-cv-covid-shield-tick-2:before {
    content: "\e910";
}
.js-cv-covid-shield-tick-suitcase:before {
    content: "\e911";
}
.js-cv-covid-shield-tick .path1:before {
    content: "\e905";
    color: rgb(255, 255, 255);
}
.js-cv-covid-shield-tick .path2:before {
    content: "\e906";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.js-cv-covid-shield-tick .path3:before {
    content: "\e907";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.js-cv-covid-shield-tick .path4:before {
    content: "\e908";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.js-cv-covid-shield-tick .path5:before {
    content: "\e909";
    margin-left: -1em;
    color: rgb(0, 171, 200);
}
.js-cv-covid-shield-tick .path6:before {
    content: "\e90a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.js-cv-covid-shield-tick .path7:before {
    content: "\e90b";
    margin-left: -1em;
    color: rgb(0, 171, 200);
}
.js-cv-covid-shield-tick .path8:before {
    content: "\e90c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.js-cv-covid-shield-tick .path9:before {
    content: "\e90d";
    margin-left: -1em;
    color: rgb(0, 171, 200);
}
.js-cv-covid-shield:before {
    content: "\e90e";
}
.js-cv-card:before {
    content: "\e900";
}
.js-cv-fly-ok:before {
    content: "\e901";
}
.js-cv-hazmat-suit:before {
    content: "\e902";
}
.js-cv-mask:before {
    content: "\e903";
}
.js-cv-virus:before {
    content: "\e904";
}
