.profile-banco-estado {
    &.booking-wrapper {
        header {
            .main-title,
            .subtitle {
                color: $banco-estado-secondary-color;
            }

            .title-icon {
                color: $banco-estado-color;
            }
        }
    }

    .profile-name {
        color: $banco-estado-color;
    }

    .profile-subtitle,
    .profile-user-data {
        color: $banco-estado-secondary-color;

        i {
            color: $banco-estado-color;
        }
    }

    .profile-container {
        .profile-content {
            margin: 0;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            width: calc(100% - 300px);
            position: relative;
        }

        .profile-content,
        .profile-sidebar {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-color: $banco-estado-secondary-color;
        }
    }

    .inner-deep-box {
        background: transparent;
        &.padded {
            padding: 0;
        }
    }
}

.banco-estado-profile-header {
    background: $banco-estado-secondary-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 15px 0 10px;
    text-align: center;
    margin-top: 30px;

    span {
        &:first-child {
            font-family: "ClanOT-Bold", sans-serif;
            font-size: 22px;
            font-weight: 500;
            color: #fff;
            line-height: 1;
        }

        &:last-child {
            font-family: "ClanOT-News", sans-serif;
            font-size: 22px;
            font-style: italic;
            color: #fff;
            line-height: 1;
        }
    }
}

.booking-wrapper {
    .profile-banco-estado {
        header {
            display: block;
            text-align: right;

            a {
                font-family: "Lato", sans-serif;
                font-size: 24px;
                color: #333;
                text-decoration: underline;

                &:not(:disabled):hover {
                    color: $brand-secondary;
                }
            }
        }

        hr {
            background: #666;
            margin: 30px 0;
        }
    }
}

.profile-credit-shell-info,
.profile-no-credit-shell-info {
    width: 450px;
    margin: 30px auto 70px;
    border-radius: 10px;
    border: 1px solid $banco-estado-color;

    .pcs-top,
    .pcs-bottom {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .pcs-top {
        background: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding-top: 10px;

        span {
            &:nth-child(1) {
                font-family: "Lato", sans-serif;
                font-size: 23px;
                font-style: italic;
                color: #333;
                margin-bottom: 5px;
            }

            &:nth-child(2) {
                font-family: "Lato", sans-serif;
                font-size: 13px;
                color: #333;
            }
        }
    }

    .pcs-bottom {
        background: $banco-estado-color;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        span {
            &:nth-child(1) {
                font-family: "Lato", sans-serif;
                font-size: 29px;
                color: #fff;
                font-weight: 600;
                margin-bottom: 5px;
            }

            &:nth-child(2) {
                font-family: "Lato", sans-serif;
                font-weight: 300;
                font-size: 11px;
                color: #fff;
            }
        }
    }
}

.profile-credit-shell-info {
    .pcs-bottom {
        span {
            &:nth-child(1) {
                font-family: "Lato", sans-serif;
                font-size: 12px;
                color: #fff;
                font-weight: 300;
                margin-bottom: 5px;
            }
            &:nth-child(2) {
                font-family: "Lato", sans-serif;
                font-size: 27px;
                color: #fff;
                font-weight: 600;
                margin-bottom: 5px;
            }

            &:nth-child(3) {
                a {
                    font-family: "Lato", sans-serif;
                    font-weight: 300;
                    font-size: 11px;
                    color: #fff;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                        color: $gray;
                    }
                }
            }
        }
    }
}

.profile-no-credit-shell-info {
    position: relative;
    width: 678px;
    max-width: 99%;

    i {
        font-size: 72px;
        color: #333;
        border-radius: 50%;
        border: 3px solid $banco-estado-color;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;
        z-index: 1;
        background: #fff;
    }

    .pcs-bottom {
        background: #333;
        padding-top: 5px;
    }
}

.profile-be-slogan {
    margin: 10px 0;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 25px;
    color: $banco-estado-color;
    font-style: italic;

    span {
        font-weight: 600;
    }
}

.profile-be-benefits {
    margin: 20px 0 50px;
    border-radius: 10px;
    border: 1px solid $banco-estado-color;

    .pbb-title {
        background: $banco-estado-color;
        font-size: 24px;
        font-family: "Lato", sans-serif;
        font-style: italic;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .pbb-dc {
        margin: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #cacaca;

        .pbb-dc-left {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 33%;
            margin-right: 1%;

            span {
                display: block;

                &:nth-child(1) {
                    color: $banco-estado-color;
                    font-size: 32px;
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    font-family: "Lato", sans-serif;
                    font-size: 17px;
                    font-weight: 600;
                    max-width: 170px;
                    color: $brand-secondary;
                }
            }
        }

        .pbb-dc-right {
            display: flex;
            align-items: center;
            width: 66%;

            li {
                font-family: "Lato", sans-serif;
                font-size: 13px;
                line-height: 1.4;
            }
        }
    }

    .pbb-others {
        display: flex;
        padding: 0 10px;
        margin-bottom: 10px;
    }

    .pbb-item {
        padding: 10px;
        border-right: 1px solid #cacaca;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border-right: none;
            padding-right: 0;
        }

        .pbb-item-title {
            display: flex;
            align-items: center;

            span {
                display: block;

                &:nth-child(1) {
                    color: $banco-estado-color;
                    font-size: 32px;
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    font-family: "Lato", sans-serif;
                    font-size: 17px;
                    font-weight: 600;
                    max-width: 170px;
                    color: $brand-secondary;
                }
            }
        }

        .pbb-item-info {
            font-family: "Lato", sans-serif;
            font-size: 13px;
            line-height: 1.4;
            margin-left: 36px;
        }
    }
}

.profile-tick-icon {
    color: #2b3c45;
    border-radius: 50%;
    border: 3px solid $banco-estado-color;
    font-size: 26px;
    margin-right: 5px;
}

.be-profile-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;

    img {
        &:first-child {
            height: 30px;
            margin-right: 15px;
        }

        &:last-child {
            height: 24px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .profile-be-benefits {
        .pbb-dc {
            .pbb-dc-left {
                span {
                    &:nth-child(1) {
                        font-size: 22px;
                    }

                    &:nth-child(2) {
                        font-size: 15px;
                    }
                }
            }
        }

        .pbb-item {
            .pbb-item-title {
                span {
                    &:nth-child(1) {
                        font-size: 22px;
                    }

                    &:nth-child(2) {
                        font-size: 15px;
                    }
                }
            }

            .pbb-item-info {
                margin-left: 28px;
            }
        }
    }
}

@media #{$small} {
    .profile-be-benefits {
        .pbb-dc {
            .pbb-dc-left {
                span {
                    &:nth-child(1) {
                        font-size: 22px;
                    }

                    &:nth-child(2) {
                        font-size: 15px;
                    }
                }
            }
        }

        .pbb-others {
            flex-wrap: wrap;
        }

        .pbb-item {
            flex: 1;
            width: 50%;

            &:first-child {
                padding-left: 10px;
            }

            &:last-child {
                padding-right: 10px;
            }

            .pbb-item-title {
                span {
                    &:nth-child(1) {
                        font-size: 22px;
                    }

                    &:nth-child(2) {
                        font-size: 15px;
                    }
                }
            }

            .pbb-item-info {
                margin-left: 28px;
            }
        }
    }

    .profile-credit-shell-info {
        width: 350px;
    }

    .profile-be-slogan {
        line-height: 1.2;
    }

    .profile-banco-estado {
        .profile-container {
            .profile-content {
                width: calc(100% - 240px);
            }
        }
    }

    .profile-no-credit-shell-info {
        i {
            font-size: 50px;
            left: 10px;
        }
    }

    .profile-no-credit-shell-info {
        .pcs-top {
            align-items: flex-end;
            padding-right: 15px;
        }
    }
}

@media #{$xsmall} {
    .profile-be-benefits {
        .pbb-dc {
            display: block;

            .pbb-dc-left {
                width: 100%;
                justify-content: flex-start;

                span {
                    &:nth-child(1) {
                        font-size: 22px;
                    }

                    &:nth-child(2) {
                        font-size: 15px;
                    }
                }
            }

            .pbb-dc-right {
                width: 100%;

                li {
                    margin-left: 28px;
                }
            }
        }

        .pbb-others {
            display: block;
        }

        .pbb-item {
            &:first-child {
                padding-left: 10px;
            }

            &:last-child {
                padding-right: 10px;
            }

            .pbb-item-title {
                span {
                    &:nth-child(1) {
                        font-size: 22px;
                    }

                    &:nth-child(2) {
                        font-size: 15px;
                    }
                }
            }

            .pbb-item-info {
                margin-left: 28px;
            }
        }
    }

    .booking-wrapper {
        .profile-banco-estado {
            header {
                text-align: center;
                margin: 20px 0;
            }
        }
    }

    .profile-banco-estado {
        .profile-container {
            .profile-content {
                width: 100%;
            }
        }
    }

    .profile-be-slogan {
        line-height: 1.2;
    }

    .be-profile-logos {
        position: relative;
    }

    .profile-credit-shell-info,
    .profile-no-credit-shell-info {
        max-width: 100%;

        .pcs-top {
            span {
                &:nth-child(1) {
                    font-size: 20px;
                    text-align: center;
                    line-height: 1;
                }
            }
        }
    }

    .profile-no-credit-shell-info {
        i {
            font-size: 30px;
            left: 10px;
            top: 98px;
        }
    }
}
