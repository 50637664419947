.offline-payment-itinerary {
    header {
        background: $brand-tertiary;
        color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 70px;
        display: flex;
        align-items: center;
        padding: 0 30px;

        label {
            line-height: 1;
            font-family: "ClanOT-Bold", sans-serif;
            font-size: 32px;
            position: relative;
            top: 3px;
        }

        span {
            font-size: 32px;
            color: #fff;
            border: 1px solid #fff;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            font-family: "Lato", sans-serif;
        }
    }

    .opi-title {
        font-family: "ClanOT-Bold", sans-serif;
        font-size: 23px;
        color: $brand-secondary;
        padding-bottom: 25px;
        border-bottom: 1px solid #1b3b6d;
        margin-top: 30px;
    }

    .opi-subtitle {
        margin-top: 15px;
        font-family: "Lato", sans-serif;
        font-size: 16px;
        color: $brand-secondary;
    }

    .opi-container {
        width: 930px;
        max-width: 95%;
        margin: 0 auto;
    }

    .opi-illustration {
        margin: 45px 0 100px;
    }

    .opi-payment-image {
        margin: 30px 0 0 75px;
    }

    .opi-warning {
        background: rgba(#59c3d9, 0.2);
        color: $brand-secondary;
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 15px;
        font-family: "Lato", sans-serif;
        font-size: 20px;
        margin: 60px 0 80px;
        line-height: 1.4;

        i {
            color: $brand-tertiary;
            font-size: 40px;
            margin-right: 15px;
        }

        span {
            font-family: "Lato", sans-serif;
            font-size: 20px;
            color: $brand-secondary;
            font-weight: 600;
        }
    }
}

/* MEDIA QUERIES */
@media #{$medium-down} {
    .offline-payment-itinerary {
        header {
            label {
                font-size: 28px;
            }
        }

        .opi-payment-image {
            margin: 30px 0 0 30px;
        }

        .opi-warning {
            margin: 30px 0 30px;
        }
    }
}

@media #{$xsmall} {
    .offline-payment-itinerary {
        header {
            label {
                font-size: 24px;
            }

            span {
                font-size: 24px;
                width: 40px;
                height: 35px;
            }
        }

        .opi-illustration {
            margin: 30px 0 30px;
        }

        .opi-warning {
            margin: 20px 0 20px;
            font-size: 18px;
        }
    }
}
