.widget-carousel-dot-navigation {
    @apply absolute bottom-0 horizontal-center flex;
}

.widget-carousel-dot {
    @apply rounded-full border-white border-2 border-solid cursor-pointer w-4 h-4 m-2;
}

.widget-carousel-slide-container {
    @apply ease-in-out flex whitespace-nowrap absolute top-0 left-0 h-full;
}

.widget-carousel-slide {
    @apply overflow-hidden bg-cover bg-center h-full;
}

.widget-carousel-arrow-navigation {
    @apply absolute top-0 bottom-0 flex items-center justify-center text-white opacity-50 cursor-pointer;
    max-width: 100px;
    z-index: 1;
    width: 15%;
    text-align: center;
    transition: opacity 0.15s ease;

    &:before {
        @apply absolute;
        content: "\e919";
        font-family: "jetsmart-v2" !important;
        font-size: 2rem;
        transform: scaleY(1.5);
    }

    &.left {
        @apply left-0;

        &:before {
            left: 40%;
            transform: rotate(180deg) scaleY(1.5);
        }
    }

    &.right {
        @apply right-0;

        &:before {
            right: 40%;
        }
    }

    &:hover {
        @apply text-be-red;
        opacity: 0.9;
    }
}

/* MEDIA QUERIES */

@media #{$medium-up} {
    .widget-carousel-dot {
        &:hover {
            @apply bg-white;
        }
    }
}

@media #{$small-down} {
    .widget-carousel-arrow-navigation {
        &:hover {
            @apply text-white opacity-50;
        }
    }
}
