.modal-content {
    &.gds-data-modal-content {
        @apply p-0 relative;
        width: 800px;
        height: 655px;
        max-height: 95%;
    }
}

.gds-data-modal-title {
    @apply uppercase font-overkill text-white absolute vertical-center;
    right: 35px;
    font-size: 54px;
}

.gds-data-modal-header {
    @apply relative rounded-tl-md rounded-tr-md bg-cover bg-center bg-no-repeat;
    background-image: url("/Images/GDS/gds-modal-header-dt.png");
    height: 250px;
}

.gds-data-modal-ribbon {
    @apply w-full bg-be-cyan-2 text-white font-body;
    padding: 5px 0;
    font-size: 19px;
    line-height: 23px;
    text-align: center;
}

.gds-data-modal-form {
    width: 525px;
    margin: 20px auto 0;

    .mdl-textfield {
        padding: 7px 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 9px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }
    }

    .js-input {
        &.js-select {
            @apply text-brand-secondary;
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            -webkit-appearance: none;

            option {
                // font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            @apply absolute block text-gray-300;
            content: "\25BC";
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
        }
    }
}

.gds-data-modal-button-container {
    @apply flex justify-center;
    padding: 35px 0;
}

/* MEDIA QUERIES */

@media #{$small} {
    .modal-content {
        &.gds-data-modal-content {
            width: 550px;
            height: 550px;
        }
    }

    .gds-data-modal-title {
        right: 35px;
        font-size: 37px;
    }

    .gds-data-modal-header {
        background-image: url("/Images/GDS/gds-modal-header-sm.png");
        height: 170px;
    }

    .gds-data-modal-ribbon {
        padding: 5px 0;
        font-size: 15px;
        line-height: 16px;
    }

    .gds-data-modal-form {
        width: 355px;
        margin: 20px auto 0;
    }

    .gds-data-modal-button-container {
        padding: 20px 0;
    }
}

@media #{$xsmall} {
    .modal-content {
        &.gds-data-modal-content {
            width: 95%;
            height: 585px;
        }
    }

    .gds-data-modal-title {
        @apply all-center;
        right: unset;
        top: 50%;
        bottom: unset;
        font-size: 29px;
    }

    .gds-data-modal-header {
        background-image: url("/Images/GDS/gds-modal-header-xs.png");
        height: 160px;
    }

    .gds-data-modal-ribbon {
        padding: 5px;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
    }

    .gds-data-modal-form {
        width: 90%;
        margin: 10px auto 0;
    }

    .gds-data-modal-button-container {
        padding: 15px 0;
    }
}
