/*
 * Text
 */

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-justify {
	text-align: justify;
}

.text-center {
	text-align: center;
}