// TODO Kill this when MDL is removed
// I beg you not to re-use this anywhere else.
// I'm serious.
// This is a hack, not to be re-used.
// If you use it anywhere else, your PR will be abandoned, and you will be fired.

.vstbs-checkbox-container {
    @apply inline-block min-h-0 bg-white relative select-none outline-none border border-solid border-brand-secondary;
    width: 250px;
    margin: 0 15px 0 0;
    padding: 0 8px;
    border-radius: 10px;

    label {
        @apply inline-block w-auto m-0 bg-white font-bold relative cursor-pointer z-0;
        height: 50px;
        padding: 0 0 0 24px;

        span {
            @apply flex items-center justify-between text-brand-secondary font-semibold relative w-full h-full m-0 select-none cursor-pointer whitespace-nowrap;
            padding-left: 25px;
            text-align: left;
            line-height: 1.2;
            font-size: 14px;

            span {
                @apply relative m-0 p-0 border-0 outline-none;
            }
        }
    }
}

.vsbts-checkbox {
    @apply left-0 m-0 cursor-pointer float-none bg-transparent absolute inline-block box-border border-2 border-solid border-new-ui-cyan;
    top: 5px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    z-index: 2;

    &:after {
        @apply common-transition opacity-0 absolute float-none;
        content: "";
        top: -2px;
        left: -2px;
        height: 40px;
        width: 40px;
        border-radius: 10px;
        background: #59c3d9
            url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCjwvc3R5bGU+DQo8cG9seWxpbmUgY2xhc3M9InN0MCIgcG9pbnRzPSIzMS42LDE1LjYgMTcuNywyOS41IDExLDIyLjggIi8+DQo8L3N2Zz4NCg==);
    }
    &.checked {
        &:after {
            @apply opacity-100;
        }
    }
}

@media #{$xsmall} {
    .vstbs-checkbox-container {
        width: 100%;
        margin: 0 0 15px 0;

        label {
            span {
                @apply whitespace-normal;
            }
        }
    }
}
