@import "./icons-forbidden";

.etd-banner {
    @apply mb-4 flex items-center rounded-2xl bg-cug-gray-5 p-4 text-be-red-2;
    font-size: 16px;
    line-height: 19px;

    i {
        @apply mr-4;
        font-size: 36px;
    }

    span {
        @apply whitespace-nowrap;

        &:first-child {
            @apply font-bold;
        }

        &:last-child {
            @apply font-black italic;
        }
    }
}

.inter-colombia-warning {
    @apply flex items-center text-brand-secondary;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: justify;
}

.one-more-day {
    &.in-checkin {
        @apply block font-semibold text-new-ui-red;
        line-height: 1.2;
        margin: 1px -24px 0 2px;
        font-size: 15px;
    }
}

.checkin-passengers {
    .itinerary {
        @apply flex items-center justify-start;
        margin: 30px 0 40px;

        .station {
            @apply flex flex-col items-center;
            margin: 0 10px;
            font-size: 20px;

            span {
                @apply relative;

                &:nth-child(1) {
                    color: $brand-secondary;
                    text-align: center;
                    font-weight: 600;
                    margin-bottom: 2px;
                }

                &:nth-child(2) {
                    display: block;
                    color: $brand-secondary;
                    font-size: $font-size-small;
                    font-weight: 600;
                }

                &:nth-child(3) {
                    @apply block font-body font-black italic;
                    font-size: 16px;
                    line-height: 28px;
                    color: #00b0ca;
                }

                &:nth-child(4) {
                    @apply block font-black font-semibold;
                    font-size: 20px;
                    padding: 5px 10px 5px 35px;
                    color: #00b0ca;
                    background-color: #caf8ff;
                    border-radius: 3px;

                    i {
                        @apply absolute vertical-center;
                        left: 10px;
                        font-size: 20px;
                        color: #00b0ca;
                    }
                }
            }

            &.crossed-time {
                span {
                    &:nth-child(1) {
                        &:after {
                            @apply absolute left-0 right-0 rounded-full bg-be-red-2 vertical-center;
                            content: "";
                            height: 2px;
                        }
                    }
                }
            }
        }

        .divider {
            border-bottom: 1px solid $gray-light;
            width: 70px;
            margin: 0 5px;
        }

        .plane-icon {
            font-size: 35px;
            color: $brand-secondary;
        }

        .help-icon {
            color: $brand-tertiary;
            cursor: pointer;
        }
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 39px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.checkin-doc {
    .expiration-date-container {
        padding-top: 20px;
        padding-bottom: 31px;

        .input-inner-title {
            top: 0px;
        }
    }
}

.ac-checkin-additional-info-page {
    .checkin-passengers {
        .js-select-arrow {
            &:after {
                top: 20px;
            }
        }
    }

    .mdl-textfield {
        @apply py-0;
    }

    .js-input {
        font-size: 20px;
        padding-top: 17px;

        &.js-select {
            padding-top: 10px;
        }
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        font-size: 14px;
        top: 4px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                font-size: 14px;
                top: -10px;
            }
        }
    }
}

.checkin-btn-container {
    @apply flex items-end justify-end;
    margin-top: 50px;

    .rounded-secondary-btn {
        &:not(.single-btn) {
            @apply mr-3;
        }
    }
}

.checkin-pax-select {
    .chkbox-btn-wrapper {
        min-height: 66px;
        margin: 10px 0 20px;
        padding-left: 16px;
        border: 1px $brand-secondary solid;
        color: $brand-secondary;

        &.small {
            @apply bg-transparent pl-0;

            min-height: unset;
            margin: 5px 0;
            border: none;

            &:last-child {
                @apply mb-0;
            }

            &.bordered {
                @apply border border-solid border-brand-secondary bg-white;

                padding: 0 10px 15px;
            }

            &.inline {
                display: inline-block;
            }
        }

        &.non-checkbox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-left: 30px;
        }

        .cb-title {
            font-weight: 600;
            font-size: $font-size-medium;
            color: $brand-secondary;
            padding: 4px 0 6px;
            display: inline-block;
        }
    }

    .full-width-information {
        @apply mt-3;
    }
}

.checkin-note {
    font-size: $font-size-small;
    font-weight: 600;
}

.travel-time {
    font-size: 18px;
    font-weight: 900;
    color: $brand-secondary;
    margin: 10px;
}

.checkin-pax-name {
    color: $brand-secondary;
    font-weight: 900;
    font-size: 18px;
}

.checkin-pax-type {
    color: $brand-secondary;
    font-size: $font-size-small;
}

.checkin-pax-container {
    margin-bottom: 40px;

    .tab-content {
        @apply bg-white;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

$prohibited-accent-color: #ba2334;
$prohibited-color: #0a3a6e;

.prohibited-title {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.prohibited-info {
    span {
        display: block;
        color: $prohibited-color;
        font-size: 19px;
        font-family: "Lato", sans-serif;

        &:nth-child(2) {
            margin-top: 5px;
            text-transform: uppercase;
            font-size: $font-size-medium;
        }
    }
}

.prohibited-number {
    font-size: 19px;
    color: #fff;
    font-family: "Lato", sans-serif;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background: $prohibited-accent-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.prohibited-category {
    display: flex;
    align-items: stretch;
    margin: 30px 10px;

    img {
        margin: 0 30px 0 0;
    }

    .category-type {
        width: 175px;
        text-align: center;
        border-right: 2px solid $prohibited-accent-color;
    }

    &.checked-baggage {
        img {
            width: 140px;
            height: 130px;
        }
    }

    &.hand-baggage {
        img {
            width: 50px;
            height: 160px;
        }
    }

    &.hand-and-checked-baggage {
        img {
            width: 131px;
            height: 160px;
        }
    }

    .category-list {
        max-width: 660px;

        li {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            width: 94px;
            position: relative;
            margin: 0 5px 10px;

            &.general-items-info {
                width: unset;
                display: block;
                color: $prohibited-color;
                font-weight: 600;
                font-size: $font-size-xsmall;
                line-height: 1.1;
                margin: 10px 17px 0;

                &:nth-child(13) {
                    margin-top: 0;
                    font-weight: 400;
                }
            }

            i {
                width: 70px;
                font-size: 70px;
                color: $prohibited-color;

                &.js-forbidden-circle {
                    position: absolute;
                    top: 0;
                    left: 12px;
                    color: $prohibited-accent-color;
                }
            }

            span {
                display: block;
                text-align: center;
                color: $prohibited-color;
                font-weight: 600;
                font-size: $font-size-xsmall;
                line-height: 1.1;
                margin-top: 10px;

                &:last-child {
                    margin-top: 0;
                    font-weight: 400;
                }
            }
        }

        &.pictogram {
            li {
                i {
                    color: #000;

                    &.js-n-pictogram-outline {
                        position: absolute;
                        top: 0;
                        left: 12px;
                    }
                }
            }
        }
    }
}

boarding-pass {
    .inner-box {
        margin-bottom: 0;
    }

    .full-width-information {
        margin-bottom: 30px;
    }
}

.boarding-pass-btn-container {
    @apply flex justify-start;
    margin: 17px;

    .rounded-transaction-btn {
        &.download {
            @apply ml-3;
        }
    }
}

.boarding-pass-accordion-content {
    @apply overflow-hidden common-transition;
    max-height: 0;

    .inner-box {
        @apply mb-0 border-none;
    }
}

.boarding-pass-accordion {
    @apply rounded-xl border border-solid border-new-ui-blue;
    padding: 17px 0;
    margin-bottom: 24px;

    label {
        @apply relative flex items-center justify-between;
        padding: 0 17px;

        span {
            @apply text-new-ui-blue;

            &:nth-child(1) {
                @apply font-body font-bold;
                font-size: 20px;
            }

            &:nth-child(2) {
                font-size: 18px;
                margin-right: 50px;
            }
        }

        i {
            @apply absolute text-new-ui-blue common-transition;
            right: 17px;
            font-size: 18px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    &.open {
        label {
            i {
                transform: translateY(-50%) rotate(-90deg);
            }
        }

        .boarding-pass-accordion-content {
            max-height: 3200px;
        }
    }
}

.multi-download {
    text-align: center;
    font-family: "Lato", sans-serif;
    color: $brand-secondary;
    margin: 30px 0;
    font-weight: 600;
    font-style: italic;
    font-size: 18px;
    line-height: 1.4;

    a {
        color: $brand-secondary;
        text-decoration: underline;
        font-weight: 900;
        cursor: pointer;

        &:hover {
            color: $brand-primary;
            text-decoration: none;
        }
    }
}

.multi-download-passengers {
    text-align: left;
    font-family: "Lato", sans-serif;
    color: $brand-secondary;
    margin: 20px 0 0;
    font-weight: 600;
    font-style: italic;
    font-size: 16px;
    line-height: 1.4;

    &.push-down {
        margin-top: 40px;
    }

    a {
        color: $brand-secondary;
        text-decoration: underline;
        font-weight: 900;
        cursor: pointer;

        &:hover {
            color: $brand-primary;
            text-decoration: none;
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .boarding-pass-btn-container {
        @apply flex w-1/2 flex-col;
        margin: 15px auto 30px;

        .rounded-transaction-btn {
            &.download {
                @apply ml-0 mt-3;
            }
        }
    }

    .checkin-doc {
        .expiration-date-container {
            padding-top: 41px;

            .input-inner-title {
                top: 0;
            }
        }
    }
}

@media #{$small} {
    .etd-banner {
        @apply rounded-xl;
        font-size: 13px;
        line-height: 16px;
    }

    .travel-time {
        display: inline-block;
        position: relative;
        top: 7px;
    }

    .checkin-passengers {
        .itinerary {
            display: inline-flex;
            margin-left: 20px;
        }
    }

    .boarding-pass-btn-container {
        @apply flex w-1/2 flex-col;
        margin: 15px auto 30px;

        .rounded-transaction-btn {
            &.download {
                @apply ml-0 mt-3;
            }
        }
    }

    .checkin-passengers {
        .itinerary {
            .station {
                span {
                    &:nth-child(3) {
                        font-size: 14px;
                        line-height: 21px;
                    }

                    &:nth-child(4) {
                        font-size: 14px;
                        padding: 2px 5px 2px 23px;

                        i {
                            left: 5px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    .checkin-doc {
        .expiration-date-container {
            padding-top: 41px;

            .input-inner-title {
                top: 0;
            }
        }
    }
}

@media #{$xsmall} {
    .boarding-pass-accordion {
        padding: 12px 0;
        margin-bottom: 16px;

        label {
            padding: 0 12px;

            span {
                &:nth-child(1) {
                    font-size: 16px;
                }

                &:nth-child(2) {
                    font-size: 12px;
                    margin-right: 50px;
                }
            }

            i {
                right: 17px;
                font-size: 18px;
            }
        }
    }

    .etd-banner {
        @apply mb-2 rounded-xl p-2;
        font-size: 12px;
        line-height: 14px;
    }

    .boarding-pass-btn-container {
        @apply flex flex-col;
        margin: 15px auto 30px;
        width: calc(100% - 34px);

        .rounded-transaction-btn {
            &.download {
                @apply ml-0 mt-3;
            }
        }
    }

    .inter-colombia-warning {
        font-size: 11px;
        line-height: 1.1;
    }

    .checkin-passengers {
        .itinerary {
            .station {
                span {
                    &:nth-child(3) {
                        @apply whitespace-nowrap;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    &:nth-child(4) {
                        font-size: 14px;
                        padding: 2px 5px 2px 20px;

                        i {
                            left: 5px;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    img {
        &.barcode {
            width: 150px;
        }
    }

    boarding-pass {
        .inner-box {
            margin-bottom: 0;
            padding: 0;
            border: none;
        }
    }

    .inner-box {
        &.prohibited {
            padding: 0;

            .inner-deep-box {
                border-radius: $border-radius-lg;
            }
        }
    }

    .prohibited-materials {
        margin: 15px 0;
        max-width: 100%;
    }

    .checkin-btn-container {
        @apply flex-col items-center justify-center;
        margin: 0 0 10px;

        .rounded-secondary-btn {
            &:not(.single-btn) {
                @apply mr-0;
            }
        }

        .rounded-primary-btn {
            @apply mb-0 mt-6;
        }

        .rounded-secondary-btn {
            @apply mb-0 mt-6;
        }
    }

    .prohibited-category {
        display: block;

        .category-type {
            width: 100%;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-right: none;
            border-bottom: 2px solid $prohibited-accent-color;
        }

        .category-list {
            li {
                margin: 0 1px 10px;
            }
        }
    }

    .prohibited-number {
        @apply flex-shrink-0;

        margin-right: 10px;
    }

    .checkin-doc {
        .expiration-date-container {
            padding-top: 41px;

            .input-inner-title {
                top: 0;
            }
        }
    }
}
