.rw-content {
    @apply fixed overflow-y-auto right-0 bottom-0;
    top: 90px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 380px;
    max-height: 85%;
    max-width: 380px;
    margin-right: -380px;
    transition: all 0.5s ease-in-out;
    z-index: 1000000;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    &.open {
        margin-right: 0;

        ~ .rw-opener {
            right: 380px;
        }
    }
}

.rw-opener {
    @apply fixed bg-be-blue text-white font-black shadow-medium cursor-pointer select-none right-0;
    top: 110px;
    width: 80px;
    height: 100px;
    transition: all 0.5s ease-in-out;
    font-size: 11px;
    padding-top: 45px;
    text-align: center;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    z-index: 10;

    &.opened {
        z-index: 1000000;
    }

    i {
        @apply absolute font-normal;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 40px;
        padding-bottom: 4px;
        width: 46px;
    }

    &.closed {
        width: 25px;
        font-size: 12px;
        padding: 0;
        @apply font-emphasis flex items-center justify-center;

        span {
            transform: rotate(90deg);
        }
    }
}

.rw-opener-text {
    @apply border-b border-solid border-white;
    text-align: center;
    margin-bottom: 2px;
    padding-bottom: 2px;
}

.rwc-close-btn {
    @apply absolute rounded-full bg-black text-white flex items-center justify-center cursor-pointer leading-none font-semibold;
    width: 30px;
    height: 30px;
    top: -15px;
    left: -15px;
    font-size: 24px;
    padding-bottom: 2px;

    &:hover {
        @apply bg-brand-primary text-white;
    }
}

.rw-content-container {
    @apply bg-white text-be-blue;
    padding: 20px 28px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.rw-title {
    @apply font-black leading-6;
    margin-bottom: 20px;
    text-align: center;
    font-size: 21px;
}

.rw-title-line {
    @apply font-black;

    &:first-child {
        @apply mb-1;
    }
}

.rw-description {
    @apply leading-5;
    font-size: 16px;
    margin-bottom: 15px;
}

.rw-terms-container {
    @apply text-be-gray;
    font-size: 16px;
    padding: 0 10px;
    margin-bottom: 15px;
}

.rw-terms-link {
    @apply underline text-be-gray;

    &:hover {
        @apply cursor-pointer underline;
    }
}

.rw-primary-btn-container {
    @apply justify-center;
    margin-bottom: 15px;

    .rounded-primary-btn {
        @apply m-auto;
    }
}

.rw-forgot-pw {
    @apply underline text-be-blue block;
    text-align: center;
    padding-bottom: 20px;

    &:hover {
        @apply cursor-pointer underline;
    }
}

.rw-bottom-container {
    @apply justify-center border-t border-solid border-gray-600 font-black;
    padding-top: 15px;
    text-align: center;

    .rounded-primary-btn {
        @apply mx-auto mt-3;
    }
}

.rw-bottom-link {
    @apply text-be-cyan-2 underline font-black;

    &:hover {
        @apply cursor-pointer underline;
    }
}

.rw-registration-content {
    margin-bottom: 10px;

    .mdl-textfield {
        padding: 6px 0;
    }

    .js-input {
        padding-top: 15px;
    }

    .mdl-textfield__label {
        top: 10px;
    }

    .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
    .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
    .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
        top: 0;
    }
}

@media #{$small} {
    .rw-title {
        @apply leading-5;
        font-size: 20px;
    }

    .rw-title-line {
        &:first-child {
            @apply mb-1;
        }
    }

    .rw-forgot-pw {
        display: none;
    }
}

@media #{$xsmall} {
    .rw-content {
        max-height: 80%;
        width: 220px;
        max-width: 220px;

        &.open {
            ~ .rw-opener {
                right: 220px;
            }

            .rw-checkbox-label {
                @apply h-16;
            }
        }
    }

    .rw-opener {
        width: 67px;
        top: 110px;
    }

    .rw-content-container {
        padding: 20px 13px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .rw-title {
        @apply leading-5;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .rw-title-line {
        &:first-child {
            @apply mb-1;
        }
    }

    .rw-terms-container {
        padding: 0 5px;
        font-size: 14px;

        label {
            font-size: 12px;
        }
    }

    .rw-description {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .rw-forgot-pw {
        display: none;
    }

    .rw-bottom-container {
        @apply leading-5;
        font-size: 14px;
    }
}
