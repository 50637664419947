.fare-lock-selector-container {
    height: 80px;
    border: 1px solid $brand-secondary;
    padding: 10px 15px;
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;

    &.no-padding {
        @apply p-0;
    }

    .RTloader-container-svg {
        transform-origin: top center;
        transform: scale(0.6);

        svg {
            &.RTmainSVG {
                margin-top: 5px;
            }
        }
    }

    i {
        position: relative;
        top: -7px;
        color: $brand-secondary;
        font-size: 42px;
        margin: 10px 10px 0 0;
    }

    .fare-lock-selector {
        span {
            display: block;

            &:first-child {
                color: $brand-secondary;
                font-family: "Lato", sans-serif;
                font-weight: 900;
                font-size: 12px;
                text-transform: uppercase;
            }

            &:nth-child(2) {
                color: $brand-secondary;
                font-family: "ClanOT-Bold", sans-serif;
                font-size: 18px;
                text-transform: uppercase;
                margin-top: 5px;
            }
        }
    }

    .fare-lock-button {
        color: $brand-secondary;
        border: 1px solid $brand-secondary;
        letter-spacing: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px 0 0;
        border-radius: 16px;
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-size: 18px;
        width: 135px;
        height: 32px;
        text-transform: none;
        position: absolute;
        right: 25px;
        transition: all 0.2s ease-in-out;

        i {
            position: absolute;
            top: 3px;
            right: 5px;
            margin: 0 0 0 15px;
            font-size: 24px;
            color: $brand-secondary;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            background: $brand-secondary;
            color: #fff;
            border: 1px solid $brand-secondary;

            i {
                color: #fff;
            }
        }

        &.selected {
            @apply cursor-default;
            background: $brand-secondary;
            color: #fff;
            border: 1px solid $brand-secondary;

            i {
                transform: rotate(90deg);
                color: #fff;
            }
        }
    }
}

.farelock-help-icon {
    @apply inline;

    .ac-tooltip {
        height: 14px;
        width: 14px;
        top: 0;
        background: $brand-tertiary;
    }
}

.fare-lock-duration-selector {
    @apply relative mx-auto my-0 overflow-hidden p-0 opacity-0;
    width: 1005px;
    max-width: 95%;
    margin: 5px auto 5px;
    border: 1px solid $brand-secondary;
    background: #fff;
    border-radius: 10px;
    max-height: 0;
    transition: all 0.2s ease-in-out;

    * {
        opacity: 0;
        transition: all 0.2s 0.2s ease-in-out;
    }

    &.active {
        @apply opacity-100;
        margin: 20px auto 10px;
        padding: 10px 15px;
        max-height: 350px;

        * {
            opacity: 1;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: -26px;
        right: 60px;
        width: 50px;
        height: 50px;
        border-left: 1px solid $brand-secondary;
        border-top: 1px solid $brand-secondary;
        background: #fff;
        transform: rotate(45deg);
    }

    i {
        position: relative;
        top: -6px;
        color: $brand-secondary;
        font-size: 42px;
        margin: 10px 10px 0 0;
    }

    header {
        font-family: "Lato", sans-serif;
        font-size: 18px;
        font-weight: 400;
        color: $brand-secondary;
        margin-bottom: 20px;
    }

    span {
        display: block;
    }

    .fare-lock-duration-options {
        display: flex;
        justify-content: space-evenly;
    }

    .fare-lock-duration-option {
        margin: 10px 0 25px;
        color: $brand-secondary;
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-size: 18px;

        &.condensed {
            font-size: 14px;
            max-width: 225px;
            color: #5a5b5b;
            margin-bottom: 8px;
        }
    }

    .fare-lock-duration-btn {
        background: #fff;
        border: 1px solid $brand-secondary;
        letter-spacing: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 5px;
        width: 220px;
        height: 61px;
        text-transform: none;
        transition: all 0.2s ease-in-out;
        line-height: 1;
        margin: 0 auto 20px;

        &.cug2 {
            text-transform: uppercase;
            border-color: #797a7a;
            pointer-events: none;

            span {
                &:first-child {
                    margin-top: 5px;
                    color: #797a7a;
                }
            }
        }

        span {
            line-height: 1;
            transition: all 0.2s ease-in-out;

            &:first-child {
                font-family: "ClanOT-Bold", sans-serif;
                font-weight: unset;
                font-size: 27px;
                color: $brand-secondary;
            }

            &:nth-child(2) {
                font-family: "Lato", sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: $brand-secondary;
            }
        }

        &:hover {
            background: $brand-secondary;
            border: 1px solid $brand-secondary;
            color: #fff;

            span {
                color: #fff;
            }
        }

        &.selected {
            background: $brand-secondary;
            border: 1px solid $brand-secondary;
            position: relative;
            cursor: default;
            color: #fff;

            span {
                color: #fff;
            }

            &:hover {
                background: $brand-secondary;

                span {
                    color: #fff;
                }
            }

            &:after {
                content: "";
                position: absolute;
                border-bottom: 8px solid #fff;
                border-right: 8px solid #fff;
                top: 14px;
                right: 15px;
                width: 16px;
                height: 26px;
                transform: rotate(45deg);
            }
        }
    }
}

.fare-lock-per-leg-summary {
    background: #fff;
    color: $brand-secondary;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 80px;
    width: 1005px;
    max-width: 95%;
    margin: 20px auto 0;
    padding: 0 25px;
    position: relative;
    border: 1px solid $brand-secondary;

    i {
        position: relative;
        font-size: 50px;
        margin-right: 10px;
        top: -4px;

        &:last-child {
            font-size: 60px;
            margin-left: 10px;
            top: 4px;
        }
    }

    .fare-lock-per-leg-info {
        font-family: "Lato", sans-serif;
        font-size: 18px;

        span {
            display: inline-block;
            font-weight: 900;
            font-size: 20px;
            position: relative;
            top: 1px;
            text-transform: uppercase;
        }
    }

    .flpls-cancel-button {
        background: $brand-secondary;
        color: #fff;
        border: 1px solid #fff;
        letter-spacing: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px 0 0;
        border-radius: 16px;
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-size: 18px;
        width: 135px;
        height: 32px;
        text-transform: none;
        position: absolute;
        right: 25px;
        transition: all 0.2s ease-in-out;

        i {
            position: absolute;
            top: 3px;
            right: 5px;
            margin: 0 0 0 15px;
            font-size: 24px;
            color: #fff;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            background: #fff;
            color: $brand-secondary;
            border: 1px solid $brand-secondary;

            i {
                color: $brand-secondary;
            }
        }
    }
}

.booking-wrapper {
    &.payment-fare-lock {
        padding: 0;
        min-height: unset;

        &.be-push-down {
            margin-top: 70px;
        }

        header {
            background: $brand-secondary;
            border-radius: 10px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            padding-left: 25px;

            i {
                font-size: 34px;
                margin: 0 25px;

                &:last-child {
                    font-size: 42px;
                    margin: 0 10px;
                }
            }

            span {
                color: #fff;
                font-family: "Lato", sans-serif;
                font-size: 20px;
                position: relative;
            }
        }

        article {
            padding: 25px;

            .fare-lock-info {
                font-family: "Lato", sans-serif;
                font-size: 16px;
                color: $brand-secondary;
                margin-bottom: 25px;
                text-align: center;

                span {
                    font-weight: 600;
                    color: $brand-secondary;
                }
            }

            .fare-lock-expiry {
                font-family: "Lato", sans-serif;
                font-size: 20px;
                color: $brand-secondary;
                text-align: center;
                font-weight: 600;
                line-height: 1.4;
            }
        }
    }
}

.fare-lock-sidebar-total {
    background: $brand-secondary;
    color: #fff;
    line-height: 30px;
}

.fare-lock-sidebar-total-left {
    padding: 10px 0;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    display: flex;
    align-items: center;

    i {
        font-size: 30px;
        margin: 0 10px 0 25px;
    }

    span {
        white-space: nowrap;
    }
}

.fare-lock-sidebar-total-sol-ref-left {
    padding: 10px 0;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 65px;

    span {
        white-space: nowrap;
    }
}

.fare-lock-sidebar-total-right {
    padding: 10px 25px 0 0;

    span {
        font-family: "Lato", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }
}

.fare-lock-flightless-date-info {
    font-family: "Lato", sans-serif;
    font-size: 21px;
    color: $brand-secondary;
    margin-bottom: 45px;
    line-height: 1.4;

    span {
        font-weight: 600;
    }
}

.fare-lock-flightless-btn-container {
    margin-top: 32px;
    text-align: right;
}
.booking-wrapper {
    &.fare-lock-pax-info-emphasis {
        min-height: unset;
        background: #163a70;
        color: #fff;
        margin-top: 10px;

        header {
            .main-title {
                color: #fff;
            }

            .subtitle {
                color: #fff;
            }
        }
    }
}

.fare-lock-duration-close {
    display: block;
    text-align: center;
    transition: all 0.2s ease-in-out;
    margin: 0 auto 15px;
    text-decoration: underline;
    color: #4d4e4f;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        color: $brand-tertiary;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .fare-lock-selector-container {
        .fare-lock-selector {
            span {
                &:nth-child(2) {
                    font-size: 15px;
                }
            }
        }
    }
}

@media #{$medium} {
    .fare-lock-selector-container {
        text-align: left;

        .fare-lock-selector {
            span {
                &:first-child {
                    font-size: 11px;
                }

                &:nth-child(2) {
                    font-size: 12px;
                }
            }
        }
    }

    .fare-lock-per-leg-summary {
        .fare-lock-per-leg-info {
            font-size: 14px;
            width: 340px;

            span {
                font-size: 16px;
            }
        }

        i {
            &:last-child {
                position: absolute;
                left: 270px;
                top: 10px;
            }
        }
    }

    .fare-lock-duration-selector {
        .fare-lock-duration-btn {
            width: 190px;
        }

        .fare-lock-duration-option {
            font-size: 14px;

            &.condensed {
                max-width: 185px;
                font-size: 11px;
            }
        }
    }
}

@media #{$small} {
    .fare-lock-selector-container {
        text-align: left;

        .fare-lock-selector {
            padding-right: 150px;

            span {
                &:first-child {
                    font-size: 11px;
                }

                &:nth-child(2) {
                    font-size: 14px;
                }
            }
        }
    }

    .fare-lock-per-leg-summary {
        background: $brand-secondary;
        color: #fff;

        .fare-lock-per-leg-info {
            font-size: 14px;

            span {
                font-size: 16px;
                font-weight: 600;
            }
        }

        .flpls-cancel-button {
            right: 20px;
        }
    }

    .booking-wrapper {
        &.payment-fare-lock {
            &.be-push-down {
                margin-top: 25px;
            }
        }
    }

    .fare-lock-duration-selector {
        .fare-lock-duration-btn {
            width: 185px;
            height: 40px;

            &.cug2 {
                margin: 0 auto 20px;
            }

            span {
                &:first-child {
                    font-size: 16px;
                }

                &:nth-child(2) {
                    font-size: 11px;
                }
            }

            &.selected {
                &:after {
                    top: 5px;
                    right: 8px;
                }
            }
        }

        .fare-lock-duration-option {
            font-size: 12px;

            &.condensed {
                font-size: 11px;
                max-width: 175px;
            }
        }
    }

    .fare-lock-duration-options {
        img {
            height: 70px;
        }
    }
}

@media #{$xsmall} {
    .fare-lock-selector-container {
        width: 100%;
        text-align: left;
        height: unset;
        display: block;
        padding-left: 65px;

        > i {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            margin: 0;
        }

        .fare-lock-selector {
            span {
                &:first-child {
                    font-size: 12px;
                    font-weight: 600;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    margin: 10px 0;
                }
            }
        }

        .fare-lock-button {
            position: relative;
            top: 0;
            left: 0;
            right: unset;
        }
    }

    .fare-lock-duration-selector {
        &.active {
            max-height: 690px;
            max-width: 90%;

            &.taller {
                max-height: 890px;
            }
        }

        header {
            span {
                text-align: left;
                line-height: 1.4;
                z-index: 1;
            }
        }

        &:after {
            left: calc(50% - 17px);
        }

        i {
            z-index: 10;
        }

        > span {
            margin: 16px 0;
        }

        .fare-lock-duration-options {
            display: block;
        }

        .fare-lock-duration-btn {
            margin: 0 auto 15px;
            width: 210px;
            height: 40px;

            span {
                &:first-child {
                    font-size: 17px;
                }

                &:nth-child(2) {
                    font-size: 12px;
                }
            }

            &.selected {
                &:after {
                    position: absolute;
                    border-bottom: 4px solid #fff;
                    border-right: 4px solid #fff;
                    top: 4px;
                    right: 10px;
                    width: 11px;
                    height: 26px;
                }
            }
        }

        .fare-lock-duration-option {
            margin-bottom: 10px;

            &.condensed {
                margin: 0 auto 9px;
                font-size: 12px;
            }
        }

        .fare-lock-duration-options {
            img {
                height: 70px;
            }
        }
    }

    .fare-lock-per-leg-summary {
        margin: 20px auto;
        border-radius: 10px;
        height: unset;
        padding: 15px 15px 15px 70px;
        display: block;
        background: $brand-secondary;
        color: #fff;
        max-width: 90%;

        > i {
            &:first-child {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
            }
        }

        .flpls-cancel-button {
            position: relative;
            top: 0;
            left: 0;
            right: unset;
            margin-top: 20px;
        }

        .fare-lock-per-leg-info {
            line-height: 1.4;

            span {
                font-family: "Lato", sans-serif;
                font-size: 18px;
                font-weight: 400;
                text-transform: none;
            }
        }
    }

    .booking-wrapper {
        &.payment-fare-lock {
            header {
                padding: 0 10px;

                i {
                    margin: 0 15px;
                }

                span {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }

            &.be-push-down {
                margin-top: 25px;
            }
        }
    }

    .fare-lock-duration-close {
        margin-bottom: 30px;
    }
}
