.multisegment-modal-opener {
    @apply flex flex-col items-center justify-center relative;
    top: 6px;

    .flight-journey-stops-tooltip-opener {
        &:after,
        &:before {
            display: none;
        }
    }

    .flight-journey-stops-number {
        @apply w-auto absolute horizontal-center;
        top: 30px;
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .multisegment-modal-opener {
        top: 3px;

        .flight-journey-stops-number {
            top: 30px;
        }
    }
}

@media #{$small} {
    .multisegment-modal-opener {
        top: 3px;

        .flight-journey-stops-number {
            @apply leading-none;
            top: 22px;
        }
    }
}

@media #{$xsmall} {
    .multisegment-modal-opener {
        top: 4px;

        .flight-journey-stops-number {
            @apply leading-none;
            top: 22px;
        }
    }
}
