$max-columns: 20;

@mixin colsWithSelector($colNr) {
    grid-template-columns: 50px repeat($colNr, minmax(130px, 250px)) min-content;

    @media #{$small-down} {
        grid-template-columns: 50px repeat($colNr, minmax(100px, 150px)) min-content;
    }
}

@mixin colsWithSelectorWithoutPinnedColumn($colNr) {
    grid-template-columns: 50px repeat($colNr, minmax(130px, 250px));

    @media #{$small-down} {
        grid-template-columns: 50px repeat($colNr, minmax(100px, 150px));
    }
}

@mixin colsWithoutSelector($colNr) {
    grid-template-columns: repeat($colNr, minmax(130px, 250px)) min-content;

    @media #{$small-down} {
        grid-template-columns: repeat($colNr, minmax(100px, 150px)) min-content;
    }
}

@mixin colsCollapsed($colNr) {
    grid-template-columns: 0 repeat($colNr, minmax(0, 0)) min-content;
}

@mixin colsCollapsedWithoutSelector($colNr) {
    grid-template-columns: repeat($colNr, minmax(0, 0)) min-content;
}

dc-table-grid {
    .dc-table-grid-shell {
        @apply relative w-full;
    }

    .dc-table-grid-container {
        @apply overflow-x-scroll relative w-full;
        overscroll-behavior: none;
    }

    .dc-table-grid {
        @apply relative grid;

        // TODO PCRA REMOVE
        // .dctg-body-cell {
        //     &:nth-child(even) {
        //         @apply bg-cug-light-gray;
        //     }
        // }

        &:not(.pinned-cells) {
            .dctg-header-cell,
            .dctg-body-cell {
                &.pinned {
                    @apply opacity-0 pointer-events-none;
                }
            }
        }

        &.pinned-cells {
            @apply absolute top-0 right-0;

            @for $i from 1 through $max-columns {
                &.cols-#{$i} {
                    @include colsCollapsed($i);
                }
            }

            .dctg-header-cell,
            .dctg-body-cell {
                &:not(.pinned) {
                    @apply opacity-0 pointer-events-none w-0;
                    z-index: -100;
                }
            }
        }
        &.no-select {
            &.pinned-cells {
                @for $i from 1 through $max-columns {
                    &.cols-#{$i} {
                        @include colsCollapsedWithoutSelector($i);
                    }
                }
            }
        }

        &.no-pinned-column{
            &:not(.no-select) {
                &:not(.pinned-cells) {
                    @for $i from 1 through $max-columns {
                        &.cols-#{$i} {
                            @include colsWithSelectorWithoutPinnedColumn($i);
                        }
                    }
                }
            }
        }

        .dctg-header-cell {
            i {
                @apply absolute;
                top: 4px;
                right: 4px;
            }
        }

        .dctg-body-cell {
            @apply bg-white;
        }

        .dctg-header-cell,
        .dctg-body-cell {
            @apply relative flex items-center justify-center;

            dc-checkbox {
                width: unset;
            }

            .ac-tooltip {
                @apply text-cug-dark-gray bg-white absolute;
                top: 4px;
                right: 4px;
            }
        }

        .dctg-header-cell {
            @apply select-none;
            text-align: center;
        }

        &.use-ellipsis {
            .dctg-body-cell {
                @apply overflow-hidden whitespace-nowrap;
                text-overflow: ellipsis;

                span {
                    @apply overflow-hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &:not(.no-select) {
            &:not(.pinned-cells) {
                @for $i from 1 through $max-columns {
                    &.cols-#{$i} {
                        @include colsWithSelector($i);
                    }
                }
            }
        }

        &.no-select {
            &:not(.pinned-cells) {
                @for $i from 1 through $max-columns {
                    &.cols-#{$i} {
                        @include colsWithoutSelector($i);
                    }
                }
            }
        }
    }
}

.pcompra-whitespace-normal {
    div {
        @apply whitespace-normal;
    }
}

@media (max-width: 63.9375rem) {
    dc-table-grid .dc-table-grid:not(.pinned-cells):not(.no-select).cols-10 {
        grid-template-columns: 50px repeat(10, minmax(130px, 250px)) -webkit-min-content;
        grid-template-columns: 50px repeat(10, minmax(130px, 250px)) min-content;
    }
}
