.i2-journey-container {
    @apply flex items-center justify-between;
    flex-wrap: nowrap;
}

.i2-journey-divider {
    @apply w-full bg-top bg-repeat-x;
    margin: 30px 0;
    height: 2px;
    background-image: linear-gradient(to right, #1c355e 10%, rgba(255, 255, 255, 0) 0%);
    background-size: 8px 2px;
}

.i2-btn {
    + div {
        @apply font-body italic text-be-gray-4;
        font-size: 16px;
        margin-top: 14px;
        padding: 0 10px;
    }
}

.i2-button-tooltip-opener {
    @apply absolute right-0 top-0 flex cursor-pointer items-center justify-center rounded-full bg-be-cyan-2 font-body text-white;
    width: 24px;
    height: 24px;
    font-size: 13px;
    margin-bottom: 12px;

    &.pull-out {
        top: 0;
        right: -16px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-journey-divider {
        margin: 24px 0;
    }

    .i2-button-tooltip-opener {
        width: 22px;
        height: 22px;
        font-size: 12px;
        margin-bottom: 10px;
    }
}

@media #{$medium} {
    .i2-journey-divider {
        margin: 20px 0;
    }

    .i2-btn {
        + div {
            font-size: 13px;
        }
    }

    .i2-button-tooltip-opener {
        width: 18px;
        height: 18px;
        font-size: 10px;
        margin-bottom: 8px;
    }
}

@media #{$small} {
    .i2-journey-divider {
        margin: 16px 0;
    }

    .i2-btn {
        + div {
            font-size: 10px;
            margin-top: 8px;
        }

        &.disabled {
            + div {
                font-size: 10px;
                margin-top: 8px;
            }
        }
    }

    .i2-button-tooltip-opener {
        width: 14px;
        height: 14px;
        font-size: 8px;
        margin-bottom: 8px;
    }
}

@media #{$xsmall} {
    .i2-journey-container {
        @apply flex-wrap;
    }

    .i2-journey-divider {
        margin: 16px 0;
    }

    .i2-btn {
        &.disabled {
            + div {
                font-size: 10px;
                margin-top: 8px;
            }
        }
    }

    .i2-button-tooltip-opener {
        width: 14px;
        height: 14px;
        font-size: 8px;
        margin-bottom: 8px;

        &.pull-out {
            top: unset;
            bottom: 40px;
            right: 30px;
        }
    }
}
