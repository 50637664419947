/*
 * Border Radius
 */


//Border Radius
@mixin border-radius ($border-radius) {
	border-radius: $border-radius;
	background-clip: padding-box;
}

//Single Side Border Radius
@mixin border-top-radius($radius) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
	border-bottom-right-radius: $radius;
	border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
	border-bottom-left-radius: $radius;
	border-top-left-radius: $radius;
}