$leftSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 33 28" style="enable-background:new 0 0 33 28;" xml:space="preserve"><g><g><path d="M32.7,16h-1.7V5c0-2.6-1.6-4.8-3.5-4.8H8.2C6.2,0.3,4.7,2.4,4.7,5v11H4H2.5c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h0.3v3.9v0.2c0,2.6,0.3,2.9,2,2.9c0.5,0,1.1,0,1.8,0H29c0.7,0,1.3,0,1.8,0c1.4,0,1.9-0.2,2-1.6h0v-1.3v-0.2V16H32.7z M5.3,18.4H7V16V5c0-1.4,0.8-2.4,1.1-2.4h19.3c0.4,0,1.1,0.9,1.1,2.4v11v2.4h1.8V22H5.3v-1.2V18.4z"/></g></g></svg>');
$middleSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 33 28" style="enable-background:new 0 0 33 28;" xml:space="preserve"><g><g><path d="M31.5,16h-1.7V5c0-2.6-1.6-4.8-3.5-4.8H6.9C5,0.3,3.4,2.4,3.4,5v11H3.3H2.7H1.6v8.6l0,0c0,0,0,0,0,0.1v0.2c0,2.6,0.3,2.9,2,2.9c0.5,0,1.1,0,1.8,0h22.3c0.7,0,1.3,0,1.8,0c1.7,0,2-0.3,2-2.9l0,0v-0.2L31.5,16L31.5,16z M5.8,18.4V16V5c0-1.4,0.8-2.4,1.1-2.4h19.3c0.4,0,1.1,0.9,1.1,2.4v11v2.4H29V22H4v-3.6H5.8z"/></g></g></svg>');
$rightSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 33 28" style="enable-background:new 0 0 34 29;" xml:space="preserve"><g><g><path d="M30.5,16.5h-1.3H29h-0.7v-11c0-2.6-1.6-4.8-3.5-4.8H5.5c-1.9,0-3.5,2.1-3.5,4.8v11H1.8H1.4H0.2v8.6h0c0,0,0,0,0,0.1v0.2c0,2.6,0.3,2.9,2,2.9c0.5,0,1.1,0,1.8,0h22.3c0.7,0,1.3,0,1.8,0c1.7,0,2-0.3,2-2.9v-0.2v-3.9h0.3c1.3,0,2.4-1.1,2.4-2.4C32.8,17.6,31.8,16.5,30.5,16.5z M4.3,18.9v-2.4v-11c0-1.4,0.8-2.4,1.1-2.4h19.3c0.4,0,1.1,0.9,1.1,2.4v11v2.4h1.8v2.4v1.2H2.5v-3.6H4.3z"/></g></g></svg>');
$xlLeftSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 29" style="enable-background:new 0 0 28 29;" xml:space="preserve"><g><g><path d="M27.6,18.7h-1.4V4.5c0-2.2-1.3-4-3-4H7c-1.6,0-3,1.8-3,4v14.2H3.5H2.3c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h0.3v3.3v0.1c0,2.2,0.2,2.5,1.7,2.5c0.4,0,0.9,0,1.5,0h18.7c0.6,0,1.1,0,1.5,0c1.2,0,1.6-0.2,1.7-1.4h0v-1.1v-0.1L27.6,18.7L27.6,18.7zM17.3,3.1H18V8h2.3v0.6h-3.1V3.1z M11.5,3.1h0.8L13,4.4c0.2,0.4,0.4,0.6,0.5,0.9h0C13.7,5,13.8,4.7,14,4.4l0.8-1.3h0.8l-1.7,2.7l1.7,2.8h-0.8l-0.7-1.2c-0.3-0.5-0.5-0.8-0.6-1.1h0c-0.2,0.3-0.3,0.6-0.6,1.1l-0.7,1.2h-0.8l1.7-2.8L11.5,3.1z M25.7,23.7H4.6v-1v-2h1.5v-2v-6.2c0-1.2,0.6-2,1-2h16.2c0.3,0,1,0.8,1,2v6.2v2h1.5V23.7z"/></g></g></svg>');
$xlMiddleSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26 29" style="enable-background:new 0 0 26 29;" xml:space="preserve"><g><g><path d="M25.5,18.7h-1.4V4.5c0-2.2-1.3-4-3-4H4.9c-1.6,0-3,1.8-3,4v14.2H1.8H1.4H0.5v7.2h0c0,0,0,0,0,0.1v0.1c0,2.2,0.2,2.5,1.7,2.5c0.4,0,0.9,0,1.5,0h18.7c0.6,0,1.1,0,1.5,0c1.5,0,1.7-0.2,1.7-2.5v0v-0.1L25.5,18.7L25.5,18.7z M14.5,3.1h0.7V8h2.3v0.6h-3.1V3.1z M8.7,3.1h0.8l0.7,1.3c0.2,0.4,0.4,0.6,0.5,0.9h0C11,5,11.1,4.7,11.3,4.4l0.8-1.3h0.8l-1.7,2.7l1.7,2.8h-0.8l-0.7-1.2c-0.3-0.5-0.5-0.8-0.6-1.1h0c-0.2,0.3-0.3,0.6-0.6,1.1L9.5,8.6H8.7l1.7-2.8L8.7,3.1z M23.5,23.7H2.5v-3h1.5v-2v-6.2c0-1.2,0.6-2,1-2h16.2c0.3,0,1,0.8,1,2v6.2v2h1.5V23.7z"/></g></g></svg>');
$xlRightSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 29" style="enable-background:new 0 0 28 29;" xml:space="preserve"><g><g><path d="M25.7,18.7h-1.1h-0.1h-0.6V4.5c0-2.2-1.3-4-3-4H4.8c-1.6,0-3,1.8-3,4v14.2H1.7H1.4H0.3v7.2h0c0,0,0,0,0,0.1v0.1c0,2.2,0.2,2.5,1.7,2.5c0.4,0,0.9,0,1.5,0h18.7c0.6,0,1.1,0,1.5,0c1.5,0,1.7-0.2,1.7-2.5v-0.1v-3.3h0.3c1.1,0,2-0.9,2-2C27.7,19.6,26.8,18.7,25.7,18.7z M13.9,3.1h0.7V8H17v0.6h-3.1V3.1z M8.1,3.1h0.8l0.7,1.3C9.9,4.7,10,5,10.2,5.3h0c0.2-0.3,0.3-0.6,0.5-0.9l0.8-1.3h0.8l-1.7,2.7l1.7,2.8h-0.8l-0.7-1.2c-0.3-0.5-0.5-0.8-0.6-1.1h0C9.9,6.6,9.8,6.9,9.5,7.4L8.8,8.6H8l1.7-2.8L8.1,3.1z M23.4,22.7v1H2.3v-3h1.5v-2v-6.2c0-1.2,0.6-2,1-2H21c0.3,0,1,0.8,1,2v6.2v2h1.5V22.7z"/></g></g></svg>');
$selectedLeftSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 34 28" style="enable-background:new 0 0 34 28;" xml:space="preserve"><g><g><path d="M33.2,16h-1.7V5c0-2.6-1.6-4.8-3.5-4.8H8.7C6.7,0.3,5.2,2.4,5.2,5v11H4.5H3c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h0.3v3.9v0.2c0,2.6,0.3,2.9,2,2.9c0.5,0,1.1,0,1.8,0h22.3c0.7,0,1.3,0,1.8,0c1.4,0,1.9-0.2,2-1.6h0v-1.3v-0.2V16H33.2z"/></g></g></svg>');
$selectedMiddleSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 34 28" style="enable-background:new 0 0 34 28;" xml:space="preserve"><g><g><path d="M32,16h-1.7V5c0-2.6-1.6-4.8-3.5-4.8H7.4C5.5,0.3,3.9,2.4,3.9,5v11H3.8H3.2H2.1v8.6l0,0c0,0,0,0,0,0.1v0.2c0,2.6,0.3,2.9,2,2.9c0.5,0,1.1,0,1.8,0h22.3c0.7,0,1.3,0,1.8,0c1.7,0,2-0.3,2-2.9l0,0v-0.2L32,16L32,16z"/></g></g></svg>');
$selectedRightSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 34 28" style="enable-background:new 0 0 34 28;" xml:space="preserve"><g><g><path d="M0.7,16v8.7v0.2v1.3h0c0.1,1.5,0.6,1.6,2,1.6c0.5,0,1.1,0,1.8,0h22.3c0.7,0,1.3,0,1.8,0c1.7,0,2-0.3,2-2.9v-0.2v-3.9H31c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4h-1.4h-0.7V5c0-2.6-1.6-4.8-3.5-4.8H6C4,0.3,2.4,2.4,2.4,5v11H0.8H0.7z"/></g></g></svg>');
$selectedXlLeftSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 29" style="enable-background:new 0 0 28 29;" xml:space="preserve"><g><g><path d="M27.7,18.7h-1.1h-0.3h-0.1V4.5c0-2.2-1.3-4-3-4H7c-1.6,0-3,1.8-3,4v14.2H3.5H3.4H2.3c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h0.3v3.3v0.1c0,2.2,0.2,2.5,1.7,2.5c0.4,0,0.9,0,1.5,0h18.7c0.6,0,1.1,0,1.5,0c1.5,0,1.7-0.2,1.7-2.5v-0.1c0,0,0,0,0-0.1h0V18.7zM14.1,8.4l-0.7-1.2c-0.3-0.5-0.5-0.8-0.6-1.1h0c-0.2,0.3-0.3,0.6-0.6,1.1l-0.7,1.2h-0.8l1.7-2.8l-1.6-2.7h0.8l0.7,1.3c0.2,0.4,0.4,0.6,0.5,0.9h0c0.2-0.3,0.3-0.6,0.5-0.9l0.8-1.3h0.8l-1.7,2.7l1.7,2.8H14.1z M19.6,8.4h-3.1V2.9h0.7v4.9h2.3V8.4z"/></g></g></svg>');
$selectedXlMiddleSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26 29" style="enable-background:new 0 0 26 29;" xml:space="preserve"><g><g><path d="M25.5,18.7h-1.4V4.5c0-2.2-1.3-4-3-4H4.9c-1.6,0-3,1.8-3,4v14.2H1.8H1.4H0.5v7.2h0c0,0,0,0,0,0.1v0.1c0,2.2,0.2,2.5,1.7,2.5c0.4,0,0.9,0,1.5,0h18.7c0.6,0,1.1,0,1.5,0c1.5,0,1.7-0.2,1.7-2.5v0v-0.1L25.5,18.7L25.5,18.7z M12.1,8.6l-0.7-1.2c-0.3-0.5-0.5-0.8-0.6-1.1h0c-0.2,0.3-0.3,0.6-0.6,1.1L9.5,8.6H8.7l1.7-2.8L8.7,3.1h0.8l0.7,1.3c0.2,0.4,0.4,0.6,0.5,0.9h0C11,5,11.1,4.7,11.3,4.4l0.8-1.3h0.8l-1.7,2.7l1.7,2.8H12.1z M17.6,8.6h-3.1V3.1h0.7V8h2.3V8.6z"/></g></g></svg>');
$selectedXlRightSeat: url('data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 29" style="enable-background:new 0 0 28 29;" xml:space="preserve"><g><g><path d="M25.7,18.7h-1.1h-0.1h-0.6V4.5c0-2.2-1.3-4-3-4H4.8c-1.6,0-3,1.8-3,4v14.2H1.7H1.4H0.3v7.2l0,0c0,0,0,0,0,0.1v0.1c0,2.2,0.2,2.5,1.7,2.5c0.4,0,0.9,0,1.5,0h18.7c0.6,0,1.1,0,1.5,0c1.5,0,1.7-0.2,1.7-2.5V26v-3.3h0.3c1.1,0,2-0.9,2-2C27.7,19.6,26.8,18.7,25.7,18.7z M12.1,8.4l-0.7-1.2c-0.3-0.5-0.5-0.8-0.6-1.1l0,0c-0.2,0.3-0.3,0.6-0.6,1.1L9.5,8.4H8.7l1.7-2.8L8.7,2.9h0.8l0.7,1.3c0.2,0.4,0.4,0.6,0.5,0.9l0,0c0.2-0.3,0.3-0.6,0.5-0.9L12,2.9h0.8l-1.7,2.7l1.7,2.8H12.1z M17.6,8.4h-3.1V2.9h0.7v4.9h2.3v0.6H17.6z"/></g></g></svg>');

.cf-seat {
    @apply box-border inline-block relative select-none cursor-pointer;
    height: 23px;
    width: 27px;
    text-align: center;
    font-size: 12px;
    z-index: 1;

    &.open {
        @apply z-50;

        &:hover {
            @apply z-50;
        }
    }

    &:before {
        @apply block absolute inset-0;
        content: "";
        -webkit-mask-size: auto 23px;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        mask-size: auto 23px;
        mask-repeat: no-repeat;
        mask-position: center center;
        z-index: -1;
    }

    &.xl {
        height: 28px;
        padding-top: 8px;

        &:before {
            -webkit-mask-size: auto 28px;
            mask-size: auto 28px;
        }

        .seat-letter {
            top: 60%;
        }
    }

    &.category-1 {
        &:before {
            @apply bg-seatmap-color1;
        }

        .seat-letter,
        .seat-tooltip {
            @apply text-seatmap-color1;
        }

        .mobile-seat-btn {
            @apply bg-seatmap-color1;
        }
    }

    &.category-2 {
        &:before {
            @apply bg-seatmap-color2;
        }

        .seat-letter,
        .seat-tooltip {
            @apply text-seatmap-color2;
        }

        .mobile-seat-btn {
            @apply bg-seatmap-color2;
        }
    }

    &.category-3 {
        &:before {
            @apply bg-seatmap-color3;
        }

        .seat-letter,
        .seat-tooltip {
            @apply text-seatmap-color3;
        }

        .mobile-seat-btn {
            @apply bg-seatmap-color3;
        }
    }

    &.category-4 {
        &:before {
            @apply bg-seatmap-color4;
        }

        .seat-letter,
        .seat-tooltip {
            @apply text-seatmap-color4;
        }

        .mobile-seat-btn {
            @apply bg-seatmap-color4;
        }
    }

    &.category-5 {
        &:before {
            @apply bg-seatmap-color5;
        }

        .seat-letter,
        .seat-tooltip {
            @apply text-seatmap-color5;
        }

        .mobile-seat-btn {
            @apply bg-seatmap-color5;
        }
    }

    &.category-1,
    &.category-2,
    &.category-3,
    &.category-4,
    &.category-5 {
        &.unavailable {
            @apply cursor-default;

            .seat-letter {
                color: #dfdfdf;
            }

            &:before {
                background-color: #dfdfdf;
            }
        }

        &.non-selectable {
            @apply cursor-default;
        }

        &.selected {
            @apply cursor-default;

            .seat-letter {
                @apply text-white;
            }

            &.recommended {
                @apply cursor-pointer pointer-events-auto;

                &:hover {
                    @apply z-10;

                    .seat-tooltip:not(.mobile-seat-info) {
                        @apply block;
                    }
                }
            }
        }

        &.banco-estado-color {
            &:before {
                @apply bg-be-old-orange;
            }

            &.unavailable:not(.filled-unavailable) {
                &:before {
                    background: #c4c4c4;
                }

                .seat-letter {
                    color: #c4c4c4;
                }

                &.is-banco-estado-category-56 {
                    &:before {
                        background: rgba(0, 0, 0, 0.15);
                    }

                    .seat-letter {
                        color: rgba(0, 0, 0, 0.15);
                    }
                }
            }

            &.selected {
                .seat-letter {
                    @apply text-white;
                    color: #fff;
                }
            }

            .mobile-seat-btn {
                @apply bg-be-old-orange text-white;
            }

            .seat-letter {
                @apply text-be-old-orange;
            }

            .seat-tooltip {
                @apply text-be-old-orange;

                span {
                    @apply text-be-old-orange;

                    &.category-name {
                        @apply text-brand-secondary leading-none;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &.s-left {
        .seat-letter {
            padding-left: 2px;
        }

        &:before {
            -webkit-mask-image: $leftSeat;
            mask-image: $leftSeat;
        }

        &.xl {
            &:before {
                -webkit-mask-image: $xlLeftSeat;
                mask-image: $xlLeftSeat;
            }
        }

        &.selected {
            &:before {
                -webkit-mask-image: $selectedLeftSeat;
                mask-image: $selectedLeftSeat;
            }

            &.xl {
                &:before {
                    -webkit-mask-image: $selectedXlLeftSeat;
                    mask-image: $selectedXlLeftSeat;
                }
            }
        }
    }

    &.middle {
        &:before {
            -webkit-mask-image: $middleSeat;
            mask-image: $middleSeat;
        }

        &.xl {
            &:before {
                -webkit-mask-image: $xlMiddleSeat;
                mask-image: $xlMiddleSeat;
            }
        }

        &.selected {
            &:before {
                -webkit-mask-image: $selectedMiddleSeat;
                mask-image: $selectedMiddleSeat;
            }

            &.xl {
                &:before {
                    -webkit-mask-image: $selectedXlMiddleSeat;
                    mask-image: $selectedXlMiddleSeat;
                }
            }
        }
    }

    &.s-right {
        .seat-letter {
            padding-right: 3px;
        }

        &:before {
            -webkit-mask-image: $rightSeat;
            mask-image: $rightSeat;
        }

        &.xl {
            &:before {
                -webkit-mask-image: $xlRightSeat;
                mask-image: $xlRightSeat;
            }
        }

        &.selected {
            &:before {
                -webkit-mask-image: $selectedRightSeat;
                mask-image: $selectedRightSeat;
            }

            &.xl {
                &:before {
                    -webkit-mask-image: $selectedXlRightSeat;
                    mask-image: $selectedXlRightSeat;
                }
            }
        }
    }

    &:hover {
        z-index: 100;

        .seat-tooltip:not(.mobile-seat-info) {
            @apply block;
        }

        .be2-seat-tooltip {
            @apply flex;
        }
    }

    &.elevated {
        z-index: 1000;
    }
}

.seat-letter {
    @apply absolute all-center;
    z-index: 1;
    font-size: 12px;
}

.seat-tooltip {
    @apply absolute horizontal-center bg-white font-semibold whitespace-normal shadow-medium;
    top: 33px;
    width: 105px;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 12;
    font-size: 14px;
    display: none;

    &.wide {
        width: 155px;
    }

    &:after {
        @apply absolute h-0 w-0 pointer-events-none;
        content: " ";
        bottom: 100%;
        left: 50%;
        margin-left: -10px;
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 10px;
    }
}

.seat-tooltip:not(.mobile-seat-info) {
    display: none;
}

.be2-seat-tooltip {
    @apply absolute bg-white shadow-medium flex-col items-center justify-center;
    display: none;
    top: 22px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 7px;
    border-radius: 8px;
    z-index: 100000;

    &:after {
        @apply absolute bg-white transform rotate-45 -translate-x-1/2;
        content: "";
        width: 10px;
        height: 10px;
        top: -5px;
        left: 50%;
    }

    svg {
        @apply ml-1;
        height: 8px;
    }
}

.be2stt-seat-number {
    @apply font-body font-semibold text-be-blue;
    font-size: 14px;
}

.be2stt-seat-price {
    @apply font-body font-semibold text-be-blue;
    font-size: 14px;
}

.be2stt-discount {
    @apply bg-be-orange text-white font-body font-semibold relative ml-2 inline-block leading-none;
    border-radius: 3px;
    height: 14px;
    padding: 1px 6px;
    font-size: 11px;

    &:after {
        @apply bg-be-orange absolute transform rotate-45;
        content: "";
        left: -2px;
        top: 5px;
        width: 4px;
        height: 4px;
    }

    span {
        font-size: 8px;
    }
}

.be2stt-client {
    @apply flex items-center mt-1 font-body font-semibold;
    font-size: 11px;
}

.be-mobile-seat-tooltip-btn {
    @apply flex items-center uppercase justify-center font-body font-semibold p-0 bg-be-orange text-white border-be-orange border border-solid;
    font-size: 12px;
    width: 85px;
    height: 26px;
    border-radius: 7px;
    letter-spacing: normal;
    margin-top: 5px;
}

/* MEDIA QUERIES */

@media #{$medium-down} {
    .cf-seat {
        @apply inline-block h-auto;
        margin: 2% 0 0 8%;
        padding: 34% 0;
        width: 82%;

        &:before {
            -webkit-mask-size: cover;
            mask-size: cover;
        }

        &.xl {
            @apply h-auto;
            padding-top: 50%;

            &:before {
                -webkit-mask-size: cover;
                mask-size: cover;
            }
        }

        &.open {
            .mobile-seat-info {
                @apply block;
            }
        }
    }

    .seat-letter {
        font-size: 12px;
    }

    .be-mobile-seat-tooltip-btn {
        font-size: 12px;
        width: 52px;
        height: 18px;
        padding: 0;
        border-radius: 3px;
    }
}
