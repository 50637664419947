.js-accordion {
    position: relative;
    width: 100%;
    .accordion-label {
        padding-left: $common-gap;
    }
    .special-assistance-accordion-label {
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 35px;
        padding-left: 10px;
        line-height: 35px;
        font-size: $font-size-small;
        cursor: pointer;
        background: $gray-lighter;
        font-weight: bold;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .tab {
        position: relative;
        width: 100%;
        margin-bottom: 1px;
        overflow: hidden;
        color: $brand-secondary;

        .icon-spaced {
            margin-left: $common-gap;
            font-size: 25px;
            font-weight: $regular-font-weight;
        }
    }
    .tab-content {
        position: relative;
        max-height: 0;
        overflow: hidden;
        background: $gray-lightest;
        -webkit-transition: max-height 1000ms ease-in-out;
        -o-transition: max-height 1000ms ease-in-out;
        transition: max-height 1000ms ease-in-out;
        p {
            margin: 1em;
        }
        .number-selector-container {
            padding: 0 25px 0 15px;
        }
        .jetsmart-number-selector {
            margin-top: 30px;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
    input:checked {
        ~ .tab-content {
            max-height: 100vh;
        }

        ~ label {
            .fa-angle-down {
                @apply rotate-180;
            }

            .chevron-down {
                -moz-transform: rotate(270deg);
                -o-transform: rotate(270deg);
                -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
            }
        }
    }
    .tab-content {
        position: relative;
        max-height: 0;
        overflow: hidden;
        background: $gray-lightest;
        -webkit-transition: max-height 1000ms ease-in-out;
        -o-transition: max-height 1000ms ease-in-out;
        transition: max-height 1000ms ease-in-out;

        p {
            margin: 1em;
        }

        .number-selector-container {
            padding: 0 25px 0 15px;
        }

        .jetsmart-number-selector {
            margin-top: 30px;
        }

        &.sold-out {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: $border-radius-lg;
                background: rgba(255, 255, 255, 0.8);
                z-index: 1000;
            }

            .sold-out-warning {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 30px;
                border-radius: $border-radius-lg;
                z-index: 1001;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $brand-tertiary;
                font-family: "ClanOT-Medium", sans-serif;
                font-size: 16px;
                text-align: center;
                line-height: 1.6;
            }
        }
    }

    label {
        display: flex;
        justify-content: space-between;
        position: relative;
        background: $gray-lighter;
        font-weight: bold;
        cursor: pointer;

        &::after {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 3em;
            height: 3em;
            text-align: center;
        }
    }
    .label-inner {
        display: flex;
        align-items: center;
        font-weight: $semibold-font-weight;
        line-height: 1;
    }
}

.checkin-form {
    .accordion-label {
        padding-left: 10px;
    }

    .special-assistance-label {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

[ref="passengers"] {
    .inner-box {
        .js-accordion {
            .accordion-label {
                position: relative;
                display: flex;
                justify-content: space-between;
                height: 35px;
                padding-left: 10px;
                line-height: 35px;
                font-size: 14px;
                cursor: pointer;
                background: $gray-lighter;
                font-weight: bold;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }
    }
}

.checkin-destination-breadcrumbs {
    > li {
        ul {
            &.accordion {
                max-height: 0;
                overflow: hidden;
                transition: max-height $transition-medium-up ease-in-out;

                &.open {
                    max-height: 100px;
                }
            }
        }
    }
}

/*accordion wrapper*/

.accordion-wrapper {
    display: block;
    width: 100%;
    position: relative;
    border-radius: $border-radius-lg;
    background: $gray-lightest;
    margin-bottom: $common-gap;

    .accordion-opener {
        position: relative;
        border-radius: $border-radius-lg;
        transition: all $normal-accordion-delay;

        &:before {
            position: absolute;
            display: none;
        }
    }

    input[type="checkbox"] {
        opacity: 0.01;
        position: absolute;

        &:checked {
            ~ .accordion-opener {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            ~ label .open-icon {
                i {
                    -moz-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
                    transition: all $normal-accordion-delay;
                }
            }

            ~ .accordion-content {
                max-height: $normal-accordion-height;

                &.extended {
                    max-height: $normal-accordion-height * 2;
                }

                &.circles {
                    max-height: 3250px;
                }
            }
        }
    }

    .itinerary-accordion-checkbox + label[for] {
        line-height: 1;
    }

    .open-icon {
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);

        i {
            display: inline-block;
            font-size: 28px;
            transition: all $normal-accordion-delay;
        }
    }

    .accordion-content {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 0;
        overflow: hidden;
        background: $gray-lightest;
        transition: all $normal-accordion-delay ease-in-out;

        p {
            margin: 1em;
        }

        .number-selector-container {
            padding: 0 25px 0 15px;
        }

        .jetsmart-number-selector {
            margin-top: 30px;
        }
    }

    .accordion-content-inside {
        margin: 14px;
    }
}

/*itinerary accordion*/
.accordion-wrapper {
    &.itinerary-accordion-wrapper {
        margin-bottom: $common-gap * 2;

        &.fixed {
            .accordion-opener {
                cursor: default;
            }

            .accordion-content {
                background: #fff;
                max-height: unset;

                .accordion-content-inside {
                    margin: 14px 0;
                }
            }

            &.sold-out {
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: $border-radius-lg;
                    background: rgba(255, 255, 255, 0.8);
                    z-index: 1000;
                }

                .sold-out-warning {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: 30px;
                    border-radius: $border-radius-lg;
                    z-index: 1001;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $brand-tertiary;
                    font-family: "ClanOT-Medium", sans-serif;
                    font-size: 16px;
                    text-align: center;
                    line-height: 1.6;
                }
            }
        }

        .accordion-opener {
            display: block;
            padding: 10px;
            margin: 0;
            height: 63px;
            line-height: 1;
            color: $gray-lightest;
            background: $brand-secondary;

            .title-icon {
                font-weight: 400;
                font-size: 45px;
                color: $gray-lightest;
            }

            header {
                margin: 0;
                height: 100%;

                .main-title,
                .subtitle {
                    color: $gray-lightest;
                    margin: 0;
                }

                .main-title {
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 0;
                }

                .subtitle {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: $font-size-small;
                    font-weight: 400;
                    white-space: nowrap;

                    &.with-icon {
                        max-width: 90%;
                    }
                }
            }
        }
    }
}

/*seatmap accordion*/

.seatmap-accordion-wrapper {
    margin-top: $common-gap;

    .accordion-content-inside {
        margin: 0 14px;

        .inner-border-box {
            padding: 0;
        }
    }

    .accordion-opener {
        display: flex;
        align-items: center;
        height: 40px !important;
        padding-left: 0;
        background: $gray-lightest;

        header {
            margin: 0;

            .title {
                margin-left: 14px;
            }

            .main-title {
                color: $brand-secondary;
            }

            .main-title {
                font-size: 18px;
                font-weight: 900;
            }
        }
    }

    .open-icon {
        line-height: 1;
    }
}

/*sidebar accordion*/

.sidebar-content {
    .js-accordion {
        padding: 0;

        label,
        .tab-content {
            background: white;
        }

        label {
            align-items: center;
            line-height: 1;
            padding: 0;
            font-size: 13px;
        }

        .price-bottom-margin {
            margin-bottom: $common-gap;
        }
    }

    input {
        &:checked {
            ~ .tab-content {
                max-height: 1100px;
            }
        }
    }
}

/*MEDIA QUERIES*/

@media #{$large} {
    .accordion-wrapper {
        input[type="checkbox"] {
            &:checked {
                ~ .accordion-content {
                    &.circles {
                        max-height: 3080px;
                    }
                }
            }
        }
    }
}

@media #{$medium} {
    .accordion-wrapper {
        input[type="checkbox"] {
            &:checked {
                ~ .accordion-content {
                    &.circles {
                        max-height: 5650px;
                    }
                }
            }
        }
    }
}

@media #{$small} {
    .js-accordion {
        .tab-content {
            .number-selector-container {
                padding: 0 25px 0 15px;
            }
        }
    }

    .accordion-wrapper {
        &.itinerary-accordion-wrapper {
            .accordion-opener {
                header {
                    .subtitle {
                        white-space: normal;
                    }
                }
            }
        }

        input[type="checkbox"] {
            &:checked {
                ~ .accordion-content {
                    &.circles {
                        max-height: 2940px;
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .js-accordion {
        .tab-content {
            .number-selector-container {
                padding: 0 40px 0 9px;
            }
        }
    }

    .accordion-wrapper {
        &.itinerary-accordion-wrapper {
            .accordion-opener {
                &.xl {
                    height: 100px;

                    header {
                        .main-title {
                            margin-top: 0;
                        }
                    }
                }

                header {
                    .subtitle {
                        white-space: normal;
                    }
                }
            }
        }

        input[type="checkbox"] {
            &:checked {
                ~ .accordion-content {
                    &.circles {
                        max-height: 5650px;
                    }
                }
            }
        }
    }
}

@media #{$xxsmall} {
    .js-accordion {
        > .tab {
            > label {
                > .js-icon {
                    &.special-pax {
                        @apply block;
                    }
                }

                > .js-icon,
                .icon-spaced {
                    display: none;
                }
            }
        }

        .label-inner {
            font-size: 13px;
        }
    }
}
