.i2-itinerary-section {
    &.i2-optionals-banner {
        @apply border-0;
        border-radius: 22px;

        img {
            @apply block w-full max-w-full;
        }
    }
}
