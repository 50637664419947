.select-all-pax-checkbox-wrapper {
    @apply text-brand-secondary bg-transparent;

    margin: 5px 0;

    .select-all-passengers-label {
        &:before {
            @apply border-2 border-new-ui-cyan;

            width: 18px;
            height: 18px;
            border-radius: 3px;
        }

        &:after {
            left: 1px;
            width: 15px;
            height: 16px;
        }
    }
}

.select-all-pax-text {
    @apply font-bold text-brand-secondary;
    font-size: 16px;
    padding: 4px 0 6px;
}

@media #{$medium} {
    .select-all-pax-text {
        font-size: 14px;
    }
}

@media #{$xsmall} {
    .select-all-pax-text {
        font-size: 14px;
    }
}


