.cug2b-edit-profile-form {
    label {
        @apply font-body;
    }

    .mdl-textfield {
        padding: 5px 0;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }

        .mdl-textfield__label {
            top: 9px;
        }
    }

    .js-input {
        @apply font-body;

        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }

    h1 {
        @apply font-emphasis text-cug-dark-gray relative w-full flex items-center justify-between;
        font-size: 21px;
        margin-bottom: 15px;

        .cug2b-profile-edit {
            @apply relative top-0 right-0 text-cug-dark-gray;

            &:hover {
                i,
                span {
                    @apply text-cug-dark-gray;
                }
            }
        }
    }
}

// MEDIA QUERIES

@media #{$small} {
    .cug2b-edit-profile-form {
        .mdl-textfield {
            margin-top: 5px;
        }

        .mdl-textfield__label {
            top: 0;
            font-size: 12px;
        }

        .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
        .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
            top: -2px;
            font-size: 10px;
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            top: 5px;
            padding-left: 6px;
            line-height: 32px;
        }

        .js-input {
            @apply leading-none font-body;
            font-size: 12px;
            height: 32px;
            padding-top: 14px;
            padding-left: 5px;

            &.js-select {
                @apply font-body;
                padding-top: 14px;
                padding-left: 5px;
                font-size: 12px;
                appearance: none;
            }
        }

        .js-select-arrow {
            &:after {
                top: 15px;
                right: 7px;
                font-size: 10px;
            }
        }

        h1 {
            font-size: 14px;
            margin-bottom: 12px;
        }

        .cug-info-icon {
            &.cug-register {
                right: 7px;
                top: 13px;
            }
        }
    }
}

@media #{$xsmall} {
    .cug2b-edit-profile-form {
        .mdl-textfield {
            margin-top: 5px;
        }

        .mdl-textfield__label {
            top: 0;
            font-size: 12px;
        }

        .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
        .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
            top: -2px;
            font-size: 10px;
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            top: 5px;
            padding-left: 6px;
            line-height: 32px;
        }

        .js-input {
            @apply leading-none font-body;
            font-size: 12px;
            height: 32px;
            padding-top: 14px;
            padding-left: 5px;

            &.js-select {
                @apply font-body;
                padding-top: 14px;
                padding-left: 5px;
                font-size: 12px;
                appearance: none;
            }
        }

        .js-select-arrow {
            &:after {
                top: 15px;
                right: 7px;
                font-size: 10px;
            }
        }

        h1 {
            font-size: 14px;
            margin-bottom: 12px;
        }

        .cug-info-icon {
            &.cug-register {
                right: 7px;
                top: 13px;
            }
        }
    }
}
