.cug2b-login-content {
    @apply fixed inset-0;
    background: #f7f6f6 url("/Images/Cug22/register-bg.png") no-repeat top center;
    background-size: cover;
    z-index: -1;
}

.cug2b-login-main {
    @apply bg-white shadow-medium w-full flex items-stretch;
    border-radius: 10px;
    margin: 60px auto;

    &.push-down {
        margin: 120px auto;
    }
}

.cug2b-login-banner {
    @apply overflow-hidden relative bg-no-repeat bg-center w-1/2;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.cug2b-login-header {
    @apply flex items-center mb-8;

    h1 {
        @apply text-be-blue font-heavy;
        font-size: 28px;
        line-height: 33px;
    }

    i {
        @apply text-be-blue;
        font-size: 51px;
        margin-right: 20px;
    }
}

.cug2b-login-form {
    @apply w-1/2;
    padding: 30px 40px;

    div {
        &:not(.form-error-message) {
            > a {
                @apply text-cug-light-gray underline inline-block mt-2;
                font-size: 18px;
                line-height: 22px;

                &:hover {
                    @apply text-cug-red no-underline;
                }
            }
        }
    }

    .form-error-message {
        a {
            @apply font-normal;
        }
    }

    .mdl-textfield {
        padding-bottom: 0;
    }
}

.cug2b-login-register {
    @apply mt-4;

    h1 {
        @apply font-heavy text-be-blue mt-8;
        font-size: 28px;
        line-height: 33px;
    }

    p {
        @apply font-bold text-be-blue;
        font-size: 21px;
        line-height: 25px;
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .cug2b-login-main {
        max-width: 95%;
    }
}

@media #{$small} {
    .cug2b-login-main {
        max-width: 95%;
        border-radius: 8px;
    }

    .cug2b-login-banner {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .cug2b-login-header {
        @apply mb-4;

        h1 {
            font-size: 17px;
            line-height: 20px;
        }

        i {
            font-size: 31px;
            margin-right: 15px;
        }
    }

    .cug2b-login-form {
        padding: 20px 20px;

        a {
            font-size: 13px;
        }

        .mdl-radio {
            font-size: 12px;
        }

        .radio-with-img {
            img {
                height: 8px;
            }
        }
    }

    .cug2b-login-register {
        @apply mt-2;

        h1 {
            @apply mt-4;
            font-size: 17px;
            line-height: 20px;
        }

        p {
            font-size: 13px;
            line-height: 15px;
        }
    }
}

@media #{$xsmall} {
    .cug2b-login-content {
        background: #f2f2f2 !important;
    }

    .cug2b-login-main {
        @apply flex-col items-start;
        max-width: 95%;
        border-radius: 6px;
        margin: 20px auto;

        &.push-down {
            margin: 20px auto;
        }
    }

    .cug2b-login-banner {
        @apply w-full border-b border-solid border-be-blue;
        height: 190px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0;
    }

    .cug2b-login-header {
        @apply mb-4;

        h1 {
            font-size: 17px;
            line-height: 20px;
        }

        i {
            font-size: 31px;
            margin-right: 15px;
        }
    }

    .cug2b-login-form {
        @apply w-full;
        padding: 15px 15px 30px;

        a {
            font-size: 11px;
        }

        .mdl-radio {
            font-size: 13px;
        }

        .radio-with-img {
            img {
                height: 9px;
            }
        }
    }

    .cug2b-login-register {
        @apply mt-4;

        h1 {
            @apply mt-4;
            font-size: 17px;
            line-height: 20px;
        }

        p {
            font-size: 11px;
            line-height: 13px;
        }
    }
}
