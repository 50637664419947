.banco-estado-bar-open {
    .backend-errors {
        margin-top: 95px;
    }

    .content-wrapper {
        #mainContentWrapper {
            margin-top: 95px;
        }
    }
}

.bebtt-membership-info {
    @apply font-body;
    font-size: 12px;

    span {
        @apply font-black;
    }
}

.beb-tooltip {
    @apply absolute whitespace-normal font-body font-semibold shadow-modal;
    top: 25px;
    right: 50%;
    transform: translateX(50%);
    width: 250px;
    border-radius: 7px;
    padding: 15px;
    font-size: 13px;
    line-height: 1.4;
    display: none;

    .bett-emphasis {
        @apply font-body font-black;
        font-size: 13px;
    }

    &:after {
        @apply absolute bg-be-gray-2;
        content: "";
        width: 20px;
        height: 20px;
        top: -11px;
        right: 50%;
        transform: translateX(50%) rotate(45deg);
        z-index: -1;
    }

    &.bg-cyan {
        @apply bg-be-cyan;

        &:after {
            @apply bg-be-cyan;
        }
    }
}

.beb-tooltip-opener {
    @apply absolute block cursor-pointer vertical-center;
    font-size: 15px;
    left: unset;
    right: 0;

    &:hover,
    &:focus,
    &:active {
        z-index: 100000;

        .beb-tooltip {
            @apply block;
            z-index: 100000;
        }
    }
}

.full-width {
    .be-bar {
        .be-bar-container {
            margin: 0 auto;
            max-width: 1155px;
        }
    }
}

.collapsed {
    .be-bar {
        @apply h-0;
    }
}

.be-bar {
    @apply overflow-hidden common-transition;
    height: 70px;

    &.closed {
        height: 0px;
        overflow: hidden;

        .be-bar-container {
            opacity: 0;
            transition: all 0.1s ease-in-out;
        }
    }

    .be-bar-container {
        @apply relative flex items-center justify-between;
        height: 70px;
        margin-left: 345px;
        margin-right: 45px;
        opacity: 1;
        transition: all 0.25s 0.25s ease-in-out;
        max-width: calc(100% - 390px);
        width: 1155px;

        > img {
            width: 95px;
        }
    }

    &.bg-be-gray {
        .beb-tooltip-opener {
            .beb-tooltip {
                &:after {
                    @apply bg-be-orange;
                }

                &.bg-cyan {
                    @apply bg-be-cyan;

                    &:after {
                        @apply bg-be-cyan;
                    }
                }
            }
        }
    }
}

.beb-divider {
    @apply bg-white;
    height: 42px;
    width: 3px;
    border-radius: 1.5px;
}

.be-tooltip-btn {
    @apply relative inline-flex cursor-pointer rounded-full border-2 border-solid border-white py-2 pl-3 pr-12 font-body font-black;
    font-size: 17px;

    i {
        @apply absolute vertical-center;
        font-size: 28px;
        right: 4px;
    }

    &:not(.btn):hover {
        @apply bg-brand-primary text-white;
    }
}

.beb-section {
    @apply relative flex flex-col items-start justify-center font-body text-white;
    font-size: 11px;
    padding: 0 10px 0 50px;

    span {
        @apply font-emphasis font-black;
        font-size: 13px;
    }

    &.beb-section-1 {
        padding-left: 0;

        span {
            @apply uppercase;
        }

        > img {
            width: 40px;
        }
    }

    &.beb-section-2 {
        padding-left: 0;
        padding-right: 20px;

        .bebs2-2 {
            @apply font-normal;
            font-size: 11px;
        }
    }

    &.beb-section-3 {
        padding-right: 20px;

        span {
            font-size: 11px;
        }
    }

    &.beb-section-4 {
        span {
            font-size: 12px;
        }
    }
}

.beb-section-icon {
    @apply absolute vertical-center;
    left: 7px;
    font-size: 36px;
}

.bett-xs {
    font-size: 12px;
}

.bett-points-example {
    @apply font-body;
    font-size: 11px;
}

.bebtt-list-plus {
    @apply relative pl-8;

    i {
        @apply absolute vertical-center;
        left: 0;
        font-size: 20px;
    }

    .emphasis {
        @apply font-body font-black text-white;
        font-size: 12px;
    }
}

.beb-section-tick {
    position: absolute;
    top: 21px;
    left: 30px;
}

.be-upgrade-membership-bubble {
    @apply absolute whitespace-normal bg-be-cyan p-4 text-white shadow-modal;
    top: 45px;
    left: 50%;
    width: 250px;
    transform: translateX(-50%);
    border-radius: 7px;
    font-size: 13px;
    z-index: 2;

    > i {
        font-size: 45px;
    }

    &:after {
        @apply absolute bg-be-cyan;
        top: -8px;
        left: 50%;
        width: 20px;
        height: 20px;
        content: "";
        transform: translateX(-50%) rotate(45deg);
    }

    .bumb-close-btn {
        @apply absolute flex cursor-pointer items-center justify-center rounded-full bg-black font-semibold leading-none text-white;
        width: 30px;
        height: 30px;
        top: -15px;
        right: -15px;
        font-size: 20px;
        padding-bottom: 2px;

        &:hover {
            @apply bg-brand-primary text-white;
        }
    }

    .bancoe-bar-terms-label {
        @apply block;
        &[for] {
            @apply font-body leading-4 text-be-blue-2;
            text-align: left;
            font-size: 11px;
            height: unset;

            a {
                @apply font-body leading-4 text-be-blue-2 underline;
                font-size: 11px;
                text-align: left;

                &:hover {
                    @apply text-white no-underline;
                }
            }
        }

        &:before,
        &:after {
            top: 5px;
        }
    }
}

.be-bar-mobile-tooltip {
    display: none;
}

.beb-tooltip-close-btn {
    display: none;
}

/* MEDIA QUERIES */

@media #{$large} {
    .beb-section-icon {
        font-size: 26px;
        left: 2px;
    }

    .be-bar {
        .be-bar-container {
            > img {
                width: 68px;
            }
        }
    }

    .beb-section {
        padding-left: 32px;
        font-size: 10px;
        line-height: 1.4;

        span {
            font-size: 11px;
        }

        > span {
            font-size: 10px;
        }
    }

    .beb-section-tick {
        left: 17px;
        width: 14px;
        top: 19px;
    }
}

@media #{$medium} {
    .beb-section-icon {
        font-size: 22px;
        left: 0;
    }

    .be-bar {
        .be-bar-container {
            margin-left: 325px;
            margin-right: 25px;
            max-width: calc(100% - 350px);

            > img {
                width: 60px;
            }
        }
    }

    .full-width {
        .be-bar {
            .be-bar-container {
                max-width: calc(100% - 50px);
                margin: 0 25px;
            }
        }
    }

    .beb-section {
        @apply whitespace-nowrap;
        padding-left: 24px;
        font-size: 9px;
        line-height: 1.4;

        span {
            line-height: 1;
        }

        > span,
        > div > span {
            font-size: 9px;
        }

        &.beb-section-2 {
            .bebs2-2 {
                font-size: 9px;
            }
        }

        &.beb-section-3 {
            > span {
                font-size: 9px;
            }
        }

        &.beb-section-4 {
            span {
                font-size: 9px;
            }
        }
    }

    .beb-section-tick {
        left: 14px;
        width: 12px;
        top: 19px;
    }
}

@media #{$small} {
    .be-bar {
        height: 51px;

        .be-bar-container {
            @apply m-0;
            height: 51px;
            max-width: 100%;
            padding: 0 10px;

            > img {
                width: 80px;
            }
        }
    }

    .full-width {
        .be-bar {
            .be-bar-container {
                max-width: 100%;
            }
        }
    }

    .beb-section {
        font-size: 10px;
        line-height: 1.1;

        &.beb-section-1 {
            padding-right: 40px;

            img {
                @apply absolute right-0 top-0 w-auto;
                height: 20px;
            }
        }

        &.beb-section-3,
        &.beb-section-4,
        &.beb-section-6,
        &.beb-section-5 {
            padding-right: 0;

            &.open {
                padding-right: 10px;
            }

            .beb-tooltip-opener {
                right: -10px;
            }

            .beb-section-tick {
                top: 6px;
                left: 30px;

                &.push-down {
                    top: 18px;
                }
            }
        }

        span {
            font-size: 12px;
        }
    }

    .beb-section-icon {
        font-size: 34px;
    }

    .beb-section-tick {
        top: 18px;
        left: 30px;
    }
}

@media #{$xsmall} {
    .be-bar,
    .full-width .be-bar {
        height: 53px;

        .be-bar-container {
            @apply m-0 justify-start overflow-x-auto whitespace-nowrap;
            padding: 0 15px 0 20px;
            height: 53px;
            -webkit-overflow-scrolling: touch;
            max-width: unset;
            width: unset;

            &::-webkit-scrollbar {
                display: none;
            }

            > img {
                margin-right: 5px;
            }
        }
    }

    .beb-section {
        @apply inline-flex h-full;
        font-size: 9px;
        line-height: 1.2;
        padding: 0px 10px 0 27px;

        span {
            font-size: 9px;
        }

        &.beb-section-1 {
            width: 55px;

            img {
                height: 19px;
            }
        }

        &.beb-section-2 {
            .bebs2-2 {
                font-size: 9px;
            }
        }

        &.beb-section-3 {
            @apply whitespace-normal;
            width: 88px;
            text-align: left;

            span {
                font-size: 9px;
            }

            .beb-tooltip-opener {
                @apply transform-none;
                top: 18px;
            }
        }

        &.beb-section-4 {
            @apply flex-shrink-0 whitespace-normal pr-0;
            width: 88px;
            text-align: left;

            span {
                font-size: 9px;
            }
        }
    }

    .beb-divider {
        @apply block flex-shrink-0;
        height: 33px;
        width: 2px;
        margin: 10px 5px;
    }

    .beb-section-icon {
        font-size: 24px;
        left: 0;
    }

    .beb-section-tick {
        top: 32px;
        left: 15px;
        width: 7px;
    }

    .beb-tooltip-opener {
        .beb-tooltip,
        ~ .be-upgrade-membership-bubble {
            display: none;
        }

        &:hover,
        &:focus,
        &:active {
            .beb-tooltip {
                display: none;
            }
        }
    }

    .be-bar-mobile-tooltip {
        @apply text-white;

        &.open {
            @apply block;
        }

        .beb-tooltip {
            @apply fixed block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 100000;

            &:after {
                display: none;
            }
        }
    }

    .beb-tooltip-close-btn {
        @apply absolute flex cursor-pointer items-center justify-center rounded-full bg-black font-semibold leading-none text-white;
        width: 30px;
        height: 30px;
        top: -15px;
        right: -15px;
        font-size: 20px;
        padding-bottom: 2px;

        &:hover {
            @apply bg-brand-primary text-white;
        }
    }

    .be-upgrade-membership-bubble {
        @apply fixed block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100000;

        &:after {
            display: none;
        }
    }
}
