/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "jetsmart-v2";
    src: url("../fonts/jetsmart-v2.eot?ua8ksm");
    src: url("../fonts/jetsmart-v2.eot?ua8ksm#iefix") format("embedded-opentype"),
        url("../fonts/jetsmart-v2.ttf?ua8ksm") format("truetype"),
        url("../fonts/jetsmart-v2.woff?ua8ksm") format("woff"),
        url("../fonts/jetsmart-v2.svg?ua8ksm#jetsmart-v2") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon {
    font-family: "jetsmart-v2" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-circle-percent-document:before {
    content: "\e948";
}
.js-plane-ticket:before {
    content: "\e904";
}
.js-money:before {
    content: "\e946";
}
.js-gift-card1:before {
    content: "\e944";
}
.js-payment:before {
    content: "\e945";
}
.js-broken-circle-exclamation:before {
    content: "\e943";
}
.js-medal:before {
    content: "\e942";
}
.js-triangle-exclamation:before {
    content: "\e941";
}
.js-fare-lock-24:before {
    content: "\e913";
}
.js-fare-lock-48:before {
    content: "\e914";
}
.js-fare-lock:before {
    content: "\e912";
}
.js-report:before {
    content: "\e911";
}
.js-seats-all:before {
    content: "\e90c";
}
.js-star:before {
    content: "\e90d";
}
.js-boarding-card:before {
    content: "\e90e";
}
.js-priority:before {
    content: "\e90f";
}
.js-bigbag:before {
    content: "\e910";
}
.js-backpack:before {
    content: "\e90a";
}
.js-flexi-fee:before {
    content: "\e905";
}
.js-cr-seats:before {
    content: "\e909";
}
.js-cr-baggage:before {
    content: "\e908";
}
.js-cr-seats1:before {
    content: "\e90b";
}
.js-cr-baggage1:before {
    content: "\e906";
}
.js-cr-calendar:before {
    content: "\e907";
}
.js-cr-cards:before {
    content: "\e915";
}
.js-checkin-bed:before {
    content: "\e902";
}
.js-checkin-car:before {
    content: "\e903";
}
.js-plane-x:before {
    content: "\e901";
}
.js-trolley:before {
    content: "\e900";
}
.js-promo-code-2:before {
    content: "\e93c";
}
.js-eye:before {
    content: "\e929";
}
.js-money-bag:before {
    content: "\e92a";
}
.js-pet:before {
    content: "\e928";
}
.js-phone:before {
    content: "\e916";
}
.js-no:before {
    content: "\e927";
}
.js-add:before {
    content: "\e917";
}
.js-close:before {
    content: "\e918";
}
.js-chevron-right:before {
    content: "\e919";
}
.js-danger:before {
    content: "\e91a";
}
.js-bin:before {
    content: "\e91b";
}
.js-search:before {
    content: "\e91c";
}
.js-question-broken-circle:before {
    content: "\e91d";
}
.js-key:before {
    content: "\e91e";
}
.js-reservation:before {
    content: "\e91f";
}
.js-tutorial:before {
    content: "\e920";
}
.js-contact:before {
    content: "\e921";
}
.js-my-users:before {
    content: "\e922";
}
.js-my-agency:before {
    content: "\e923";
}
.js-tick-broken-circle:before {
    content: "\e924";
}
.js-document:before {
    content: "\e925";
}
.js-group-dc:before {
    content: "\e926";
}
.js-group-dc1:before {
    content: "\e92b";
}
.js-flight-help:before {
    content: "\e92c";
}
.js-flight-plane:before {
    content: "\e92d";
}
.js-flight-tick:before {
    content: "\e92e";
}
.js-edit:before {
    content: "\e92f";
}
.js-dc-exclamation:before {
    content: "\e930";
}
.js-dc-single:before {
    content: "\e931";
}
.js-dc-group:before {
    content: "\e932";
}
.js-dc-group1:before {
    content: "\e933";
}
.js-medal1 .path1:before {
    content: "\e934";
    color: rgb(104, 29, 29);
}
.js-medal1 .path2:before {
    content: "\e935";
    margin-left: -0.5537109375em;
    color: rgb(133, 55, 55);
}
.js-medal1 .path3:before {
    content: "\e936";
    margin-left: -0.5537109375em;
    color: rgb(252, 196, 82);
}
.js-medal1 .path4:before {
    content: "\e937";
    margin-left: -0.5537109375em;
    color: rgb(255, 164, 0);
}
.js-medal1 .path5:before {
    content: "\e947";
    margin-left: -0.5537109375em;
    color: rgb(255, 255, 255);
}
.js-download:before {
    content: "\e938";
}
.js-print:before {
    content: "\e939";
}
.js-circle-tick:before {
    content: "\e93a";
}
.js-seatmap:before {
    content: "\e93b";
}
.js-lock2:before {
    content: "\e93d";
}
.js-gift-card:before {
    content: "\e93e";
}
.js-circle-payment:before {
    content: "\e93f";
}
.js-promo-code:before {
    content: "\e9b3";
}
.js-promo-code1:before {
    content: "\e940";
}
.js-arrow:before {
    content: "\e9b4";
}
.js-special-wheelchair:before {
    content: "\e9b5";
}
.js-calendar:before {
    content: "\e9b6";
}
.js-terminal-watch:before {
    content: "\e9b7";
}
.js-circle-user:before {
    content: "\e9b8";
}
.js-flight3:before {
    content: "\e9b9";
}
.js-circle-chevron-right:before {
    content: "\e9ba";
}
.js-circle-baggage:before {
    content: "\e9bb";
}
.js-bag1-image:before {
    content: "\e9bc";
}
.js-bag1-weight:before {
    content: "\e9bd";
}
.js-bag2-image:before {
    content: "\e9be";
}
.js-bag2-weight:before {
    content: "\e9bf";
}
.js-bag3-image:before {
    content: "\e9c0";
}
.js-bag4-image:before {
    content: "\e9c1";
}
.js-bag4-weight:before {
    content: "\e9c2";
}
.js-bike:before {
    content: "\e9c3";
}
.js-airplane-on-shield:before {
    content: "\e9c4";
}
.js-boarding-man:before {
    content: "\e9c5";
}
.js-boarding-pass:before {
    content: "\e9c6";
}
