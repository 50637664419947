.basic-checkbox-wrapper {
    @apply flex select-none items-center;

    // TODO: Remove this from pico
    input[type="checkbox"] {
        & + label[for] {
            line-height: 14px;
        }
    }

    label {
        @apply relative flex w-full cursor-pointer select-none items-center justify-start font-bold;

        height: 44px;
        padding-left: 30px;
        font-size: $font-size-medium;

        &.fontnormal {
            @apply font-normal;
        }

        &:before {
            @apply absolute left-0 cursor-pointer overflow-hidden bg-transparent;

            content: "";
            height: 22px;
            width: 22px;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.54);
            z-index: 1;
        }

        > span {
            line-height: 14px;
        }

        &:after {
            @apply absolute left-0;

            content: "";
            height: 22px;
            width: 21px;
            border-radius: 5px;
        }
    }

    .basic-checkbox-input {
        @apply m-0 h-0 w-0 appearance-none border-none p-0 opacity-0;

        &:checked {
            &:not(:disabled) {
                ~ label {
                    &:before {
                        background-color: #59c3d9;
                    }
                    &:after {
                        @apply bg-white;
                        z-index: 2;
                        clip-path: polygon(39% 75%, 86% 28%, 91% 32%, 39% 83%, 15% 65%, 19% 60%);
                    }
                }
            }
        }
    }
}
