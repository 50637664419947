@import "../flight/flight-variables";

.modal-content {
    &.payment-modal-content {
        max-height: calc(95% - 66px);

        h1 {
            margin: 0;
            font-size: 24px;
            font-weight: 900;
            color: $brand-secondary;
            font-family: ClanOT-Bold;

            &.no-subtitle {
                margin-bottom: 20px;
            }
        }

        h2 {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: $brand-secondary;

            i {
                color: $brand-secondary;
                margin-left: 10px;
                cursor: pointer;

                &:hover {
                    color: $brand-primary;
                }
            }
        }

        .subtitle {
            margin-bottom: 20px;
            color: $brand-secondary;
            font-weight: 600;
            font-size: $font-size-xsmall;
            padding-left: 28px;
            font-family: ClanOT-News;
        }

        .group-caption {
            color: $brand-secondary;
            font-weight: 600;
            font-size: $font-size-medium;
            margin-bottom: 10px;
            display: block;
        }

        .cb-title {
            font-weight: 600;
            color: $brand-secondary;
            font-size: $font-size-small;
            line-height: 1.2;
        }

        .summary-list {
            padding-left: 20px;
            font-size: $font-size-xsmall;
            line-height: 1.3;
            margin-bottom: 20px;
        }

        .payment-modal-title {
            margin: 20px 0;
            font-size: 24px;
            font-weight: 900;
            color: $brand-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: ClanOT-Bold;

            i {
                margin-right: 10px;
                color: $brand-tertiary;
                font-size: 45px;
                font-weight: 600;
            }
        }

        .payment-modal-content-box {
            margin: 10px;
            padding: 20px;
            border-radius: $border-radius-lg;
            background: $gray-lightest;

            .commission-info {
                font-size: $font-size-medium;
                color: $brand-secondary;
                margin: 10px 10px 20px;
                text-align: center;
                line-height: 1.4;
                font-weight: 600;
            }

            .percent-sign {
                position: relative;

                &:after {
                    content: "%";
                    position: absolute;
                    top: 23px;
                    right: -20px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .payment-modal-button-container {
                @apply flex justify-center;
                margin-top: 20px;
            }

            .amount-footnote {
                font-size: $font-size-xsmall;
                color: $brand-secondary;
                font-weight: 600;
                margin-top: 5px;
            }
        }

        .payment-modal-close {
            @apply absolute flex cursor-pointer select-none items-center justify-center rounded-full border-0 p-0 text-white;
            top: -17px;
            right: -17px;
            width: 34px;
            height: 34px;
            background: $gray-darkest;
            font-size: $font-size-xlarge;
            z-index: 100;
            letter-spacing: normal;

            &:hover {
                background: $brand-primary;
            }
        }
    }
}

@media #{$small-down} {
    .modal-content {
        &.payment-modal-content {
            position: fixed;
            top: 50%;
            left: 50%;
            width: 500px;
            max-width: 95%;
            transform: translate(-50%, -50%);
            padding: 10px;
            border-radius: $border-radius-lg;
            background: #fff;
            z-index: 1001;
        }
    }
}

@media #{$xsmall} {
    .modal-content {
        &.payment-modal-content {
            left: 5%;
            width: 90%;
            max-height: 90%;
            transform: translateY(-50%);
            overflow: visible;
            overflow-y: visible;

            &.argentina-modal,
            &.edit-profile {
                left: 2%;
                width: 96%;
                max-height: 95%;
            }

            &.pending-payment {
                left: 5%;
                width: 90%;
                max-height: 95%;
            }

            .modal-button-container {
                display: block;

                a {
                    margin: $small-gap 0 0 0;
                    width: 100%;
                    display: block;
                }
            }

            .payment-modal-title {
                margin: 10px 0;
            }
        }
    }
}
