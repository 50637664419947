/* FIXED BUTTONS */

.rounded-primary-btn {
    @apply relative flex cursor-pointer appearance-none justify-center whitespace-normal rounded-full border-2 border-solid border-brand-primary bg-brand-primary font-body font-bold normal-case leading-none tracking-normal text-white;
    text-align: center;
    font-size: 18px;
    min-width: 180px;
    padding: 10px 35px 10px 15px;

    &:not(.no-default-icon):after {
        @apply absolute font-normal vertical-center;
        font-family: "jetsmart-v2" !important;
        right: 5px;
        content: "\e9ba";
        font-size: 25px;
    }

    &.dc-yellow {
        @apply border-2 border-solid border-dc-bundle-simple bg-dc-bundle-simple text-white;

        &.selected {
            @apply border-2 border-solid border-dc-bundle-simple bg-white text-dc-bundle-simple;

            &:not(.disabled):focus,
            &:not(.disabled):active {
                @apply border-2 border-solid border-dc-bundle-simple bg-white text-dc-bundle-simple;
            }

            &:after {
                @apply absolute border-b-2 border-l-0 border-r-2 border-t-0 border-solid border-dc-bundle-simple;
                content: "";
                width: 10px;
                height: 20px;
                right: 14px;
                top: 50%;
                transform: translateY(-12px) rotate(45deg);
            }
        }
    }

    &:not(.disabled):hover {
        @apply border-2 border-solid border-brand-primary bg-white text-brand-primary;

        &.dc-yellow {
            @apply border-2 border-solid border-dc-bundle-simple bg-white text-dc-bundle-simple;
        }

        &:after {
            @apply font-bold;
        }

        &.banco-estado-color {
            @apply border-2 border-solid border-be-old-orange bg-white text-be-old-orange;
        }

        &.dc-yellow {
            @apply border-2 border-solid border-dc-bundle-simple bg-white text-dc-bundle-simple;
        }

        &.white-blue {
            @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
        }

        &.white-red {
            @apply border-2 border-solid border-brand-primary bg-brand-primary text-white;
        }

        &.gray-blue {
            @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-be-light-gray;
        }

        &.blue-white {
            @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
        }

        &.blue-gray {
            @apply border-2 border-solid border-brand-secondary bg-be-light-gray text-brand-secondary;
        }

        &.cug-blue {
            @apply border-2 border-solid border-be-blue bg-white text-be-blue;
        }

        &.cug-widget-cyan {
            @apply text-white;
            background-color: #00b0ca;
            border-color: #00b0ca;

            &.i {
                @apply text-white;
            }
        }

        &.cug2b-white {
            @apply border-be-red bg-be-red text-white;

            i {
                @apply text-white;
            }
        }
    }

    &:not(.disabled):focus,
    &:not(.disabled):active {
        @apply border-2 border-solid border-brand-primary bg-brand-primary text-white;

        &.banco-estado-color {
            @apply border-2 border-solid border-be-old-orange bg-be-old-orange text-white;
        }

        &.dc-yellow {
            @apply border-2 border-solid border-dc-bundle-simple bg-dc-bundle-simple text-white;
        }

        &.white-blue {
            @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
        }

        &.white-red {
            @apply border-2 border-solid border-brand-primary bg-white text-brand-primary;
        }

        &.gray-blue {
            @apply border-2 border-solid border-brand-secondary bg-be-light-gray text-brand-secondary;
        }

        &.blue-white {
            @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
        }

        &.blue-gray {
            @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-be-light-gray;
        }

        &.cug-widget-cyan {
            @apply items-center bg-white;
            border-color: #00b0ca;
            color: #00b0ca;

            &.i {
                color: #00b0ca;
            }
        }

        &:hover {
            @apply border-2 border-solid border-brand-primary bg-white text-brand-primary;

            &.banco-estado-color {
                @apply bg-white text-be-old-orange;
            }

            &.dc-yellow {
                @apply bg-white text-dc-bundle-simple;
            }

            &.white-blue {
                @apply border-2 border-solid border-white bg-brand-secondary text-white;
            }

            &.white-red {
                @apply border-2 border-solid border-white bg-brand-primary text-white;
            }

            &.gray-blue {
                @apply border-2 border-solid border-be-light-gray bg-brand-secondary text-be-light-gray;
            }

            &.blue-white {
                @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
            }

            &.blue-gray {
                @apply border-2 border-solid border-brand-secondary bg-be-light-gray text-brand-secondary;
            }

            &.cug-widget-cyan {
                @apply items-center bg-white;
                border-color: #00b0ca;
                color: #00b0ca;

                &.i {
                    color: #00b0ca;
                }
            }
        }
    }

    &.locked-btn {
        &:before {
            @apply mr-1 font-normal;
            margin-top: 3px;
            font-family: "jetsmart-v2" !important;
            font-size: $font-size-xsmall;
            content: "\e93d";
        }
    }

    &.banco-estado-color {
        @apply border-2 border-solid border-be-old-orange bg-be-old-orange text-white;
    }

    &.dc-yellow {
        @apply border-2 border-solid border-dc-bundle-simple bg-dc-bundle-simple text-white;
    }

    &.cug2b-white {
        @apply border-cug-dark-gray bg-white text-cug-dark-gray;
    }

    &.cug-widget-white {
        @apply border-white bg-white text-be-blue;

        &.narrow {
            min-width: 130px;
        }
    }

    &.cug-widget-cyan {
        @apply items-center bg-white;
        border-color: #00b0ca;
        color: #00b0ca;
        height: 30px;
        font-size: 16px;

        &.narrow {
            @apply inline-flex;
            min-width: 130px;
            padding-right: 43px;
        }
    }

    &.cug-blue {
        @apply border-2 border-solid border-be-blue bg-be-blue text-white;
    }

    &.white-blue {
        @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
    }

    &.white-red {
        @apply border-2 border-solid border-brand-primary bg-white text-brand-primary;
    }

    &.gray-blue {
        @apply border-2 border-solid border-brand-secondary bg-be-light-gray text-brand-secondary;
    }

    &.blue-white {
        @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
    }

    &.blue-gray {
        @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-be-light-gray;
    }

    &.no-click {
        @apply cursor-default opacity-50;
    }

    &.mobile-seatmap-direction-btn {
        @apply fixed inset-x-0 bottom-0 w-full transform-none rounded-none;
        display: none;
        z-index: 1000;
    }

    &.seat-select {
        @apply flex items-center;

        width: calc(50% - 30px);
        font-size: 13px;
        height: 43px;
        min-width: 165px;

        &.disabled {
            display: none;
        }
    }

    &.return-seats {
        margin: 20px auto 0;
    }

    &.dc-register {
        @apply block w-full min-w-0;
    }

    &.mobile-cug-sidebar {
        @apply border-cug-dark-gray bg-cug-dark-gray text-white;

        &:hover {
            @apply border-cug-dark-gray bg-cug-dark-gray text-white;
        }
    }

    &.cug2b-btn {
        &.disabled {
            @apply border-cug-gray-2 bg-cug-gray-2 text-white;
        }
    }

    &.with-x-logo {
        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "js-bundle-icons" !important;
            right: 5px;
            content: "\e904";
            font-size: 25px;
        }
    }
}

.rounded-secondary-btn {
    @apply relative flex cursor-pointer appearance-none justify-center whitespace-normal rounded-full border-2 border-solid border-brand-secondary bg-brand-secondary font-body font-bold normal-case leading-none tracking-normal text-white;
    text-align: center;
    font-size: 18px;
    min-width: 180px;
    padding: 10px 15px;

    &:not(.disabled):hover {
        @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;

        &.banco-estado-color {
            @apply border-2 border-solid border-be-old-orange bg-be-old-orange text-white;
        }

        &.cug2b-white {
            @apply border-be-red bg-be-red text-white;

            i {
                @apply text-white;
            }
        }
    }

    &:not(.disabled):focus,
    &:not(.disabled):active {
        @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;

        &.banco-estado-color {
            @apply border-2 border-solid border-be-old-orange bg-white text-be-old-orange;
        }
    }

    &.inverted {
        @apply bg-white text-brand-secondary;

        &:not(.disabled):hover {
            @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
        }

        &:not(.disabled):focus,
        &:not(.disabled):active {
            @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
        }
    }

    &.with-arrow {
        padding: 10px 35px 10px 15px;

        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 5px;
            content: "\e9ba";
            font-size: 25px;
        }
    }

    &.xs {
        font-size: 12px;
    }

    &.banco-estado-color {
        @apply border-2 border-solid border-be-old-orange bg-white font-bold text-be-old-orange;
    }

    &.no-click {
        opacity: 0.5;
        cursor: default;
    }

    &.mobile-seatmap-direction-btn {
        @apply fixed inset-x-0 bottom-0 w-full transform-none rounded-none;
        display: none;
        z-index: 1000;

        &.elevated {
            bottom: 49px;
            padding: 13px;
        }
    }

    &.seat-select {
        @apply mb-1;
        width: 100%;
        font-size: 13px;

        &.disabled {
            display: none;
        }
    }

    &.same-seats-segment {
        width: calc(50% - 16px);
        font-size: 13px;

        &.disabled {
            @apply opacity-50;
        }
    }

    &.no-seat {
        width: max-content;
        font-size: 13px;

        &.disabled {
            display: none;
        }
    }

    &.recommendator {
        width: calc(50% - 16px);
        font-size: 13px;
    }

    &.dc-register {
        @apply block w-full min-w-0;
    }

    &.seating-for-all {
        @apply mr-2;
    }

    &.with-arrow {
        @apply pr-10;

        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 5px;
            content: "\e9ba";
            font-size: 25px;
        }
    }

    &.cug2b-white {
        @apply border-cug-dark-gray bg-white text-cug-dark-gray;
    }

    &.seat-for-all {
        @apply mr-3;
    }

    &.select-no-seat {
        @apply flex items-center;

        width: calc(50% - 30px);
        font-size: 13px;
        height: 43px;
        min-width: 165px;

        &.disabled {
            display: none;
        }
    }
}
.rounded-tertiary-btn {
    @apply relative flex cursor-pointer appearance-none justify-center whitespace-nowrap rounded-full border-2 border-solid border-be-cyan-5 bg-be-cyan-5 font-body font-bold normal-case  leading-none tracking-normal text-white;
    text-align: center;
    font-size: 18px;
    min-width: 180px;
    padding: 10px 15px;

    &:not(.disabled):hover {
        @apply border-2 border-solid border-be-cyan-5 bg-white text-be-cyan-5;

        &.banco-estado-color {
            @apply border-2 border-solid border-be-old-orange bg-be-old-orange text-white;
        }

        &.cug2b-white {
            @apply border-be-red bg-be-red text-white;

            i {
                @apply text-white;
            }
        }
    }

    &:not(.disabled):focus,
    &:not(.disabled):active {
        @apply border-2 border-solid border-be-cyan-5 bg-be-cyan-5 text-white;

        &.banco-estado-color {
            @apply border-2 border-solid border-be-old-orange bg-white text-be-old-orange;
        }
    }

    &.inverted {
        @apply bg-white text-be-cyan-5;

        &:not(.disabled):hover {
            @apply border-2 border-solid border-be-cyan-5 bg-be-cyan-5 text-white;
        }

        &:not(.disabled):focus,
        &:not(.disabled):active {
            @apply border-2 border-solid border-be-cyan-5 bg-white text-be-cyan-5;
        }
    }

    &.with-arrow {
        padding: 10px 35px 10px 15px;

        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 5px;
            content: "\e9ba";
            font-size: 25px;
        }
    }

    &.xs {
        font-size: 12px;
    }

    &.banco-estado-color {
        @apply border-2 border-solid border-be-old-orange bg-white font-bold text-be-old-orange;
    }

    &.no-click {
        opacity: 0.5;
        cursor: default;
    }

    &.mobile-seatmap-direction-btn {
        @apply fixed inset-x-0 bottom-0 w-full transform-none rounded-none;
        display: none;
        z-index: 1000;

        &.elevated {
            bottom: 49px;
            padding: 13px;
        }
    }

    &.seat-select {
        @apply mb-1;
        width: 100%;
        font-size: 13px;

        &.disabled {
            display: none;
        }
    }

    &.same-seats-segment {
        width: calc(50% - 16px);
        font-size: 13px;

        &.disabled {
            @apply opacity-50;
        }
    }

    &.no-seat {
        width: max-content;
        font-size: 13px;

        &.disabled {
            display: none;
        }
    }

    &.recommendator {
        width: calc(50% - 16px);
        font-size: 13px;
    }

    &.dc-register {
        @apply block w-full min-w-0;
    }

    &.seating-for-all {
        @apply mr-2;
    }

    &.with-arrow {
        @apply pr-10;

        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 5px;
            content: "\e9ba";
            font-size: 25px;
        }
    }

    &.cug2b-white {
        @apply border-cug-dark-gray bg-white text-cug-dark-gray;
    }

    &.seat-for-all {
        @apply mr-3;
    }

    &.select-no-seat {
        @apply flex items-center;

        width: calc(50% - 30px);
        font-size: 13px;
        height: 43px;
        min-width: 165px;

        &.disabled {
            display: none;
        }
    }
}

.rounded-transaction-btn {
    @apply relative flex cursor-pointer appearance-none justify-center whitespace-normal rounded-full border-2 border-solid border-brand-secondary bg-brand-secondary font-body font-bold normal-case leading-none tracking-normal text-white;
    text-align: center;
    font-size: 18px;
    min-width: 180px;
    padding: 10px 38px 10px 15px;

    &:not(.disabled):hover {
        @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
    }

    &.print {
        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 9px;
            content: "\e939";
            font-size: 25px;
        }
    }

    &.download {
        &:after {
            @apply absolute font-normal vertical-center;
            font-family: "jetsmart-v2" !important;
            right: 9px;
            content: "\e938";
            font-size: 25px;
        }
    }

    &.more-transactions-button {
        padding-right: 25px;
        padding-left: 25px;
    }

    &.light-blue {
        @apply border-2 border-solid border-be-cyan-2 bg-be-cyan-2 text-white;

        &:not(.disabled):hover {
            @apply border-2 border-solid border-be-cyan-2 bg-white text-be-cyan-2;
        }
    }

    &.banco-estado-color {
        @apply border-2 border-solid border-be-old-orange bg-be-old-orange text-white;

        &:not(.disabled):hover {
            @apply border-2 border-solid border-be-old-orange bg-white text-be-old-orange;
        }
    }

    &.no-click {
        @apply cursor-default opacity-50;
    }
}

.rounded-reverse-btn {
    @apply relative inline-flex cursor-pointer appearance-none justify-center whitespace-normal rounded-full border-2 border-solid border-brand-secondary bg-white font-body font-bold normal-case leading-none tracking-normal text-brand-secondary;
    text-align: center;
    font-size: 18px;
    min-width: 180px;
    padding: 10px 15px 10px 35px;

    &:after {
        @apply absolute rotate-180 transform font-normal;
        font-family: "jetsmart-v2" !important;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        left: 5px;
        content: "\e9ba";
        font-size: 25px;
    }

    &:not(.disabled):hover {
        @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
    }

    &:not(.disabled):focus,
    &:not(.disabled):active {
        @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;

        &:hover {
            @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
        }
    }
}

.cta-primary-btn {
    cursor: pointer;
    box-sizing: border-box;
    border: 2px solid $primary-btn-bg;
    border-radius: $border-radius-lg;
    background: $primary-btn-bg;
    color: $gray-lightest;
    font-family: "Lato", sans-serif;
    font-weight: $bold-font-weight;
    font-size: $font-size-small;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-transform: none;
    line-height: 22px;
    padding: 12px 30px;
    position: relative;
    height: auto;
    text-align: center;
    letter-spacing: normal;

    &:not(.disabled):hover {
        border: 2px solid $primary-btn-hover-bg;
        background: $primary-btn-hover-bg;
        color: $gray-lightest;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        font-family: "Lato", sans-serif;
        font-weight: $bold-font-weight;
        font-size: $font-size-small;
        text-transform: none;
        letter-spacing: normal;
    }

    &.no-click {
        opacity: 0.5;
        cursor: default;
    }

    &.cta-big-payment-button {
        height: 72px;
    }

    &.cta-flight-button {
        min-width: 135px;

        &.push-down {
            margin-top: 40px;
        }
    }

    &.cta-my-trip {
        float: none;
        position: absolute;
        bottom: 15px;
        right: 15px;
    }

    &.cta-dc-login {
        display: block;
        width: 100%;
    }
}

.cta-secondary-btn {
    box-sizing: border-box;
    border: 2px solid $secondary-btn-color;
    border-radius: $border-radius-lg;
    background-color: white;
    color: $secondary-btn-color;
    font-family: "Lato", sans-serif;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    font-weight: bold;
    font-size: $font-size-small;
    padding: 12px 30px;
    position: relative;
    height: auto;
    line-height: 22px;

    &:not(.disabled):hover {
        border: 2px solid $brand-secondary;
        background: $brand-secondary;
        color: #fff;
    }

    &.no-click {
        opacity: 0.5;
        cursor: default;
    }

    &.cta-dc-login {
        display: block;
        width: 100%;
        color: #163a70;
        border-color: #163a70;
    }

    &.cta-review-button {
        color: #fff;
        border: 2px solid #7287a0;
        background: #7287a0;
        margin-right: 20px;
    }

    &.cta-commission-button {
        background: #6d829a;
        color: #fff;
        margin-right: 20px;
        padding: 13px 30px;
    }
}

/*
 * Buttons
 */

button,
input[type="button"] {
    cursor: pointer;
}

button:not(:disabled):hover,
button:not(:disabled):focus,
.btn:not(:disabled):hover,
.btn:not(:disabled):focus {
    border-width: 2px;
}

.primary-btn {
    cursor: pointer;
    box-sizing: border-box;
    border: 2px solid $primary-btn-bg;
    border-radius: $border-radius-lg;
    background: $primary-btn-bg;
    color: $gray-lightest;
    box-shadow: none;
    font-family: "Lato", sans-serif;
    font-weight: $bold-font-weight;
    font-size: $font-size-small;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-transform: none;
    line-height: 22px;
    padding: 12px 30px;
    position: relative;
    height: auto;
    text-align: center;
    &:not(.disabled):hover {
        border: 2px solid $primary-btn-hover-bg;
        background: $primary-btn-hover-bg;
        color: $gray-lightest;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        font-family: "Lato", sans-serif;
        font-weight: $bold-font-weight;
        font-size: $font-size-small;
        text-transform: none;
    }

    &.no-click {
        opacity: 0.5;
        cursor: default;
    }
}

.secondary-btn {
    box-sizing: border-box;
    border: 2px solid $secondary-btn-color;
    border-radius: $border-radius-lg;
    background-color: white;
    color: $secondary-btn-color;
    font-family: "Lato", sans-serif;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    font-weight: bold;
    font-size: $font-size-small;
    padding: 12px 30px;
    position: relative;
    height: auto;
    line-height: 22px;
    &:not(.disabled):hover {
        border: 2px solid $brand-secondary;
        background: $brand-secondary;
        color: #fff;
    }

    &.no-click {
        opacity: 0.5;
        cursor: default;
    }
}

.bag-btn-check {
    position: absolute;
    top: 3px;
    right: 3px;
    color: $brand-chk;
}

a {
    &:not(.btn) {
        &:hover,
        &:focus {
            color: $brand-primary;
            text-decoration: none;
        }
    }
}

.review-btn {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 13px 30px;
    border: 2px solid transparent;
    border-radius: $border-radius-lg;
    background: $review-secondary-btn-color;
    color: $gray-lightest;
    text-align: center;
    font-weight: 700;
    font-size: $font-size-small;
    user-select: none;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    text-transform: none;
    letter-spacing: normal;

    &:hover {
        border-color: $brand-secondary;
        background: $brand-secondary;
        color: $gray-lightest;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .rounded-primary-btn {
        &:not(.disabled):hover {
            &:after {
                @apply font-normal;
            }
        }

        &.seat-select {
            font-size: 13px;
            min-width: 160px;
        }
    }

    .rounded-reverse-btn {
        &:not(.disabled):hover {
            &:after {
                @apply font-normal;
            }
        }
    }

    .rounded-secondary-btn {
        &.select-no-seat {
            font-size: 12.5px;
            min-width: 160px;
        }
    }

    .rounded-tertiary-btn {
        font-size: 17px;
        padding-top: 7px;
        padding-bottom: 7px;

        &.select-no-seat {
            font-size: 12.5px;
            min-width: 160px;
        }

        &.with-arrow {
            font-size: 17px;
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}

@media #{$medium} {
    .rounded-primary-btn {
        &:not(.disabled):hover {
            &:after {
                @apply font-normal;
            }
        }

        &.mobile-seatmap-direction-btn {
            @apply bottom-0 block;
            left: 390px;
            width: calc(100% - 456px);
        }

        &.review-seats {
            margin-right: 20px;
            height: 44px;
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }
    }

    .rounded-reverse-btn {
        &:not(.disabled):hover {
            &:after {
                @apply font-normal;
            }
        }
    }

    .rounded-secondary-btn {
        &.mobile-seatmap-direction-btn {
            @apply block;
            left: 390px;
            width: calc(100% - 456px);
        }

        &.review-seats {
            margin-right: 20px;
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }
    }

    .rounded-tertiary-btn {
        @apply whitespace-nowrap;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;

        &.mobile-seatmap-direction-btn {
            @apply block;
            left: 390px;
            width: calc(100% - 456px);
        }

        &.review-seats {
            margin-right: 20px;
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }

        &.with-arrow {
            font-size: 14px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .passed-content {
        .rounded-primary-btn,
        .rounded-secondary-btn,
        .rounded-tertiary-btn {
            &.mobile-seatmap-direction-btn {
                display: none;
            }
        }
    }

    .cta-primary-btn,
    .cta-secondary-btn {
        &.cta-big-payment-button {
            @apply block;
            margin: 25px auto;
        }
    }

    .primary-btn,
    .secondary-btn {
        &.no-click {
            background: $gray-light;
            color: $gray-lighter;
            border-color: $gray-light;
            opacity: 1;
            cursor: default;

            &:focus,
            &:hover {
                background: $gray-light;
                color: $gray-lighter;
                border-color: $gray-light;
            }
        }
    }
}

@media #{$small} {
    .rounded-primary-btn {
        font-size: 15px;
        min-width: 130px;

        &:after {
            font-size: 20px;
        }

        /* DEVNOTE - This section is for countering stuck hover effects on touch devices */
        &:not(.disabled):hover {
            &:after {
                @apply font-normal;
            }

            &.white-blue {
                @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
            }

            &.white-red {
                @apply border-2 border-solid border-brand-primary bg-white text-brand-primary;
            }

            &.gray-blue {
                @apply border-2 border-solid border-brand-secondary bg-be-light-gray text-brand-secondary;
            }

            &.blue-white {
                @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
            }

            &.blue-gray {
                @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-be-light-gray;
            }
        }

        &:not(.disabled):focus,
        &:not(.disabled):active {
            &:hover {
                &.white-blue {
                    @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
                }

                &.white-red {
                    @apply border-2 border-solid border-brand-primary bg-white text-brand-primary;
                }

                &.gray-blue {
                    @apply border-2 border-solid border-brand-secondary bg-be-light-gray text-brand-secondary;
                }

                &.blue-white {
                    @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
                }

                &.blue-gray {
                    @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-be-light-gray;
                }
            }
        }

        &.locked-btn {
            &:before {
                margin-top: 1px;
            }
        }

        &.mobile-seatmap-direction-btn {
            @apply fixed inset-x-0 bottom-0 m-0 block w-full transform-none rounded-none;
            padding: 15px 27px 15px 7px;

            &.disabled {
                display: none;
            }
        }

        &.review-seats {
            margin-right: 20px;
            height: 40px;
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }

        &.dc-register {
            padding-right: 7px;
        }

        &.cug-widget-white {
            &.narrow {
                min-width: 110px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        &.seat-select {
            &:not(.disabled) {
                &:hover,
                &:focus {
                    @apply bg-brand-primary text-white;
                }
            }
        }

        &.with-x-logo {
            &:after {
                font-size: 20px;
            }
        }
    }

    .rounded-secondary-btn {
        font-size: 15px;
        min-width: 130px;

        &.mobile-seatmap-direction-btn {
            @apply block py-4;
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }

        &.dc-register {
            @apply inline-block;
            margin-right: unset;
        }

        &.with-arrow {
            &:after {
                font-size: 20px;
            }
        }
    }

    .rounded-tertiary-btn {
        font-size: 12px;
        min-width: 130px;
        padding-top: 5px;
        padding-bottom: 5px;

        &.mobile-seatmap-direction-btn {
            @apply block py-4;
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }

        &.dc-register {
            @apply inline-block;
            margin-right: unset;
        }

        &.with-arrow {
            font-size: 12px;
            padding-top: 5px;
            padding-bottom: 5px;

            &:after {
                font-size: 20px;
            }
        }
    }

    .rounded-transaction-btn {
        font-size: 15px;
        min-width: 130px;

        &.print {
            &:after {
                font-size: 20px;
            }
        }

        &.download {
            &:after {
                font-size: 20px;
            }
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }
    }

    .rounded-reverse-btn {
        font-size: 15px;
        min-width: 130px;

        &:after {
            font-size: 20px;
        }

        &:not(.disabled):hover {
            &:after {
                @apply font-normal;
            }
        }
    }

    .cta-primary-btn {
        &.cta-big-payment-button {
            display: block;
            margin: 25px auto;
        }
    }

    .cta-secondary-btn {
        &.cta-dc-login {
            display: inline-block;
            margin-right: unset;
        }

        &.cta-commission-button {
            display: block;
            margin: 25px auto;
        }
    }

    .primary-btn,
    .secondary-btn {
        &.no-click {
            background: $gray-light;
            color: $gray-lighter;
            border-color: $gray-light;
            opacity: 1;
            cursor: default;

            &:focus,
            &:hover {
                background: $gray-light;
                color: $gray-lighter;
                border-color: $gray-light;
            }
        }
    }

    .passed-content {
        .rounded-primary-btn,
        .rounded-secondary-btn,
        .rounded-tertiary-btn {
            &.mobile-seatmap-direction-btn {
                display: none;
            }
        }
    }
}

@media #{$xsmall} {
    .rounded-primary-btn {
        font-size: 15px;
        min-width: 130px;

        &:after {
            font-size: 20px;
        }

        /* DEVNOTE - This section is for countering stuck hover effects on touch devices */
        &:not(.disabled):hover {
            @apply border-2 border-solid border-brand-primary bg-brand-primary text-white;

            &:after {
                @apply font-normal;
            }

            &.white-blue {
                @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
            }

            &.white-red {
                @apply border-2 border-solid border-brand-primary bg-white text-brand-primary;
            }

            &.gray-blue {
                @apply border-2 border-solid border-brand-secondary bg-be-light-gray text-brand-secondary;
            }

            &.blue-white {
                @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
            }

            &.blue-gray {
                @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-be-light-gray;
            }
        }

        &:not(.disabled):focus,
        &:not(.disabled):active {
            @apply border-2 border-solid border-brand-primary bg-brand-primary text-white;

            &:hover {
                @apply border-2 border-solid border-brand-primary bg-brand-primary text-white;

                &.white-blue {
                    @apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
                }

                &.white-red {
                    @apply border-2 border-solid border-brand-primary bg-white text-brand-primary;
                }

                &.gray-blue {
                    @apply border-2 border-solid border-brand-secondary bg-be-light-gray text-brand-secondary;
                }

                &.blue-white {
                    @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
                }

                &.blue-gray {
                    @apply border-2 border-solid border-brand-secondary bg-brand-secondary text-be-light-gray;
                }
            }
        }

        &.cug2b-btn {
            width: 210px;
        }

        &.cug-blue {
            min-width: 110px;
        }

        &.locked-btn {
            @apply fixed inset-x-0 bottom-0 w-full rounded-none;
            padding: 15px 27px 15px 7px;

            &:before {
                display: none;
            }
        }

        &.booking {
            @apply fixed inset-x-0 bottom-0 w-full rounded-none;
            z-index: 1000;
            padding: 15px 27px 15px 7px;

            &.disabled {
                display: none;
            }
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }

        &.mobile-seatmap-direction-btn {
            @apply fixed inset-x-0 bottom-0 m-0 block w-full rounded-none;
            padding: 15px 27px 15px 7px;

            &.disabled {
                display: none;
            }
        }

        &.seat-for-all {
            @apply block w-full;
            padding-right: 7px;
        }

        &.review-seats {
            margin-right: unset;
        }

        &.dc-register {
            padding-right: 7px;
        }

        &.cug-widget-white {
            &.narrow {
                min-width: 110px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        &.seat-select {
            &:not(.disabled) {
                &:hover,
                &:focus {
                    @apply bg-brand-primary text-white;
                }
            }
        }

        &.with-x-logo {
            &:after {
                font-size: 20px;
            }
        }
    }

    .rounded-secondary-btn {
        font-size: 15px;
        min-width: 130px;

        &.mobile-seatmap-direction-btn {
            @apply block py-4;
        }

        &.seat-for-all {
            @apply block w-full;
            margin: 10px 0;
        }

        &.dc-register {
            @apply inline-block;
            margin-right: unset;
        }

        &.with-arrow {
            &:after {
                font-size: 20px;
            }
        }
    }

    .rounded-tertiary-btn {
        @apply whitespace-nowrap;
        min-width: 130px;
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px;

        &.mobile-seatmap-direction-btn {
            @apply block py-4;
        }

        &.seat-for-all {
            @apply block w-full;
            margin: 10px 0;
        }

        &.dc-register {
            @apply inline-block;
            margin-right: unset;
        }

        &.with-arrow {
            font-size: 12px;
            padding-top: 5px;
            padding-bottom: 5px;

            &:after {
                font-size: 20px;
            }
        }
    }

    .rounded-reverse-btn {
        font-size: 15px;
        min-width: 130px;

        &:after {
            font-size: 20px;
        }

        &:not(.disabled):hover {
            &:after {
                @apply font-normal;
            }
        }
    }

    .check-in-flow {
        .rounded-primary-btn {
            &.mobile-seatmap-direction-btn {
                bottom: 0;

                &.elevated {
                    bottom: 49px;
                }
            }
        }
    }

    .rounded-transaction-btn {
        font-size: 15px;
        min-width: 130px;

        &.print {
            &:after {
                font-size: 20px;
            }
        }

        &.download {
            &:after {
                font-size: 20px;
            }
        }

        &.no-click {
            @apply cursor-default bg-gray-500 text-gray-200 opacity-100;
            border-color: $gray-light;

            &:focus,
            &:hover {
                @apply bg-gray-500 text-gray-200;
                border-color: $gray-light;
            }
        }
    }

    .cta-primary-btn {
        float: none;
        display: block;
        margin-right: auto;
        margin-left: auto;

        &.no-click {
            background: $gray-light;
            color: $gray-lighter;
            border-color: $gray-light;
            opacity: 1;
            cursor: default;

            &:focus,
            &:hover {
                background: $gray-light;
                color: $gray-lighter;
                border-color: $gray-light;
            }
        }

        &.cta-flight-button {
            width: 100%;
            border-radius: 0;
        }

        &.cta-passengers-submit {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            width: 100%;
            border-radius: 0;
            z-index: 1000;

            &.disabled {
                display: none;
            }
        }

        &.cta-review-button {
            margin: 0 20px 0 0;
        }

        &.cta-baggage-submit {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            width: 100%;
            border-radius: 0;
            z-index: 1000;

            &.disabled {
                display: none;
            }
        }

        &.cta-flight-button {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 1000;
            border-radius: 0;
        }

        &.cta-big-payment-button {
            width: 300px;
        }
    }

    .cta-secondary-btn {
        &.cta-dc-login {
            display: inline-block;
            margin-right: unset;
        }

        &.cta-commission-button {
            display: block;
            margin: 20px auto;
            width: 300px;
        }
    }

    .passed-content {
        .rounded-primary-btn,
        .rounded-secondary-btn,
        .rounded-tertiary-btn {
            &.mobile-seatmap-direction-btn {
                display: none;
            }
        }
    }

    .primary-btn {
        float: none;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

    .login-page {
        .secondary-btn {
            display: block;
            margin: 40px 0 20px;
        }
    }

    .primary-btn,
    .secondary-btn {
        &.no-click {
            background: $gray-light;
            color: $gray-lighter;
            border-color: $gray-light;
            opacity: 1;
            cursor: default;

            &:focus,
            &:hover {
                background: $gray-light;
                color: $gray-lighter;
                border-color: $gray-light;
            }
        }
    }
}

@import "./button/baggages-buttons";
@import "./button/cug-buttons";
@import "./button/extras-buttons";
@import "./button/itinerary-buttons";
@import "./button/modal-buttons";
@import "./button/payment-buttons";
@import "./button/register-buttons";
@import "./button/seatmap-buttons";
@import "./button/sidebar-buttons";
