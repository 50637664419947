.i2-modify-container {
    @apply text-be-blue font-body font-semibold;
    max-width: 65%;
    font-size: 22px;
    line-height: 26px;
    margin-right: 20px;

    > div {
        &:first-child {
            @apply font-emphasis font-normal;
            font-size: 22px;
            line-height: 27px;
        }
    }
}

.i2-modify-divider {
    @apply bg-be-gray-10;
    height: 1px;
    margin: 25px -40px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-modify-container {
        font-size: 18px;
        line-height: 22px;

        > div {
            &:first-child {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }

    .i2-modify-divider {
        height: 1px;
        margin: 20px -40px;
    }
}

@media #{$medium} {
    .i2-modify-container {
        font-size: 16px;
        line-height: 20px;

        > div {
            &:first-child {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    .i2-modify-divider {
        height: 1px;
        margin: 18px -40px;
    }
}

@media #{$small} {
    .i2-modify-container {
        font-size: 13px;
        line-height: 15px;

        > div {
            &:first-child {
                font-size: 13px;
                line-height: 16px;
            }
        }
    }

    .i2-modify-divider {
        height: 1px;
        margin: 15px -40px;
    }
}

@media #{$xsmall} {
    .i2-modify-container {
        max-width: unset;
        width: calc(100% - 30px);
        font-size: 10px;
        line-height: 12px;
        padding: 0 15px;

        > div {
            &:first-child {
                font-size: 12px;
                line-height: 14px;
            }
        }

        + .i2-btn {
            @apply self-end;
            margin: 25px 15px 0 0;
        }
    }

    .i2-modify-divider {
        height: 1px;
        margin: 25px 0;
    }
}
