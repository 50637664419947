.cug2b-frame {
    @apply overflow-hidden bg-white shadow-medium;
    margin: 220px auto 30px;
    width: 1600px;
    max-width: 95%;
    border-radius: 10px;

    &.cug-pcra-frame,
    &.chile-compra-frame {
        margin-top: 118px;
    }
}

.cug2b-summary-banner {
    @apply w-full bg-cover bg-center;
    height: 186px;
}

.cug2b-content {
    @apply flex items-stretch;
}

.cug2b-container {
    @apply mx-auto;
    width: 1600px;
    max-width: 95%;
}

.cug2b-main {
    width: calc(100% - 275px);
    padding: 30px 25px;
}

.cug2b-footer {
    @apply relative bg-cover bg-center bg-no-repeat;
    margin: 0 auto 30px;
    width: 1600px;
    height: 216px;
    max-width: 95%;
    border-radius: 10px;

    h2 {
        @apply font-heavy leading-none text-white;
        font-size: 44px;
    }

    h1 {
        @apply font-heavy leading-none text-white;
        font-size: 82px;
    }

    button {
        @apply absolute vertical-center;
        right: 60px;
    }
}

.cug2b-footer-caption {
    @apply absolute vertical-center;
    left: 120px;
}

ac-cug-group-booking-widget,
ac-cug-devolutions-small-widget,
ac-cug-destinations-widget,
ac-cug-pcra-select-flight-widget {
    @apply block;
    width: calc(50% - 12px);
    margin: 0 0 45px 0;
}

ac-cug-agency-fee-widget {
    @apply block w-full;
    margin: 0 0 45px 0;

    .cug2b-fee-widget-line {
        @apply font-body text-white;
        margin-left: 50%;

        &:nth-child(1) {
            @apply font-bold;
            font-size: 36px;
            line-height: 44px;
        }

        &:nth-child(2) {
            font-size: 24px;
            line-height: 28px;

            span {
                @apply font-bold;
            }
        }

        &:nth-child(3) {
            @apply mt-2 inline-block border-t border-solid border-white pt-2;
            font-size: 21px;
        }
    }
}

.cug2b-widget {
    @apply relative bg-cover bg-center bg-no-repeat;
    height: 133px;
    border-radius: 10px;

    &.no-img {
        @apply bg-cug-gray-5;

        h2 {
            @apply font-emphasis text-be-blue;
            font-size: 25px;
            line-height: 30px;
        }
    }

    h2 {
        @apply font-body font-bold leading-none text-white;
        font-size: 22px;
    }

    h1 {
        @apply font-heavy leading-none text-white;
        font-size: 36px;
    }

    h3 {
        @apply font-body leading-none text-white;
        font-size: 16px;
    }

    button {
        @apply absolute vertical-center;
        right: 15px;
    }
}

.cug2b-widget-caption {
    @apply absolute vertical-center;
    left: 20px;

    i {
        font-size: 72px;
        margin: 0 48px;
    }

    &.with-button {
        left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        right: 20px;

        button {
            @apply relative right-0 top-0 mt-4 transform-none;
        }

        &.left {
            left: 20px;
            right: auto;
        }

        &.with-added-caption {
            @apply items-start;
        }
    }
}

// MEDIA QUERIES

@media #{$medium} {
    .cug2b-frame {
        margin-top: 175px;
        border-radius: 8px;

        &.cug-pcra-frame,
        &.chile-compra-frame {
            margin-top: 130px;
        }
    }

    .cug2b-main {
        width: calc(100% - 200px);
        padding: 40px 20px;
    }
}

@media #{$small} {
    .cug2b-summary-banner {
        height: 81px;

        &.pcra-banner {
            height: 186px;
        }

        &.chile-compra-banner {
            height: 140px;
        }
    }

    .cug2b-frame {
        margin-top: 105px;
        border-radius: 6px;
    }

    .cug2b-main {
        padding: 20px 15px;
        width: calc(100% - 168px);
    }

    .cug2b-footer {
        height: 146px;
        border-radius: 6px;

        h2 {
            font-size: 27px;
        }

        h1 {
            font-size: 50px;
        }

        button {
            right: 40px;
        }
    }

    .cug2b-footer-caption {
        left: 70px;
    }

    ac-cug-group-booking-widget,
    ac-cug-devolutions-small-widget,
    ac-cug-destinations-widget,
    ac-cug-pcra-select-flight-widget {
        width: calc(50% - 7px);
        margin: 0 0 30px 0;
    }

    ac-cug-agency-fee-widget {
        margin: 0 0 30px 0;

        .cug2b-fee-widget-line {
            margin-left: 25%;

            &:nth-child(1) {
                font-size: 24px;
                line-height: 28px;
            }

            &:nth-child(2) {
                font-size: 18px;
                line-height: 21px;
            }

            &:nth-child(3) {
                font-size: 14px;
            }
        }
    }

    .cug2b-widget {
        height: 81px;
        border-radius: 6px;

        &.no-img {
            h2 {
                font-size: 16px;
                line-height: 19px;
            }
        }

        h2 {
            font-size: 16px;
        }

        h1 {
            font-size: 25px;
        }

        h3 {
            font-size: 11px;
        }

        button {
            right: 8px;
        }
    }

    .cug2b-widget-caption {
        left: 8px;

        i {
            font-size: 45px;
            margin: 0 28px;
        }
    }
}

@media #{$xsmall} {
    .cug2b-summary-banner {
        height: 186px;

        &.chile-compra-banner {
            @apply rounded-t-xl;

            height: 75px;
        }

        &.pcra-banner {
            height: 81px;
        }
    }

    .cug2b-frame {
        @apply mx-0 max-w-full shadow-none;
        margin-top: 80px;
        margin-bottom: 0;
        padding: 20px;

        &.cug-pcra-frame,
        &.chile-compra-frame {
            margin-top: 59px;
        }
    }

    .cug2b-content {
        @apply flex-col;
    }

    .cug2b-main {
        @apply mx-0 my-4 w-full max-w-full shadow-medium;
        padding: 20px 15px;
        border-radius: 10px;
    }

    .cug2b-footer {
        margin: 0 20px;
        max-width: calc(100% - 40px);
        height: 130px;
        border-radius: 6px;

        h2 {
            @apply font-body font-bold;
            font-size: 17px;
        }

        h1 {
            font-size: 29px;
        }

        button {
            @apply relative right-0 top-0 mt-2 transform-none;
        }
    }

    .cug2b-footer-caption {
        @apply left-auto flex flex-col items-center;
        right: 15px;
    }

    ac-cug-group-booking-widget,
    ac-cug-devolutions-small-widget,
    ac-cug-destinations-widget,
    ac-cug-pcra-select-flight-widget {
        @apply w-full;
        margin: 0 0 20px 0;
    }

    ac-cug-agency-fee-widget {
        margin: 0 0 20px 0;

        .cug2b-fee-widget-line {
            margin-left: 2.5%;

            &:nth-child(1) {
                font-size: 18px;
                line-height: 21px;
            }

            &:nth-child(2) {
                font-size: 14px;
                line-height: 16px;
            }

            &:nth-child(3) {
                font-size: 10px;
            }
        }
    }

    .cug2b-widget {
        height: 81px;
        border-radius: 8px;

        &.no-img {
            h2 {
                font-size: 16px;
                line-height: 19px;
            }
        }

        h2 {
            font-size: 16px;
        }

        h1 {
            font-size: 25px;
        }

        h3 {
            font-size: 11px;
        }

        button {
            right: 12px;
        }
    }

    .cug2b-widget-caption {
        left: 8px;

        &.with-button {
            right: 15px;

            button {
                @apply relative right-0 top-0 mt-2 transform-none;
            }
        }

        i {
            font-size: 37px;
            margin: 0 6px 0 0;
        }
    }
}
