/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "js-baggage-icons";
    src: url("../fonts/js-baggage-icons.eot?x3m9xm"); //todo
    src: url("../fonts/js-baggage-icons.eot?x3m9xm#iefix") format("embedded-opentype"),
        url("../fonts/js-baggage-icons.ttf?x3m9xm") format("truetype"),
        url("../fonts/js-baggage-icons.woff?x3m9xm") format("woff"),
        url("../fonts/js-baggage-icons.svg?x3m9xm#js-baggage-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-bag {
    font-family: "js-baggage-icons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-bag-broken-circle-bag:before {
    content: "\e90d";
}
.js-bag-zipper-bag-one-dimension:before {
    content: "\e90c";
}
.js-bag-regular-baggage-with-dimensions:before {
    content: "\e90b";
}
.js-bag-zipper-baggage:before {
    content: "\e903";
}
.js-bag-crossed-out-baggage:before {
    content: "\e905";
}
.js-bag-arrow-clock:before {
    content: "\e907";
}
.js-bag-plane-landing:before {
    content: "\e908";
}
.js-bag-zipper-baggage-checked:before {
    content: "\e909";
}
.js-bag-guitar-surf-golf-checked:before {
    content: "\e90a";
}
.js-bag-backpack-with-dimensions:before {
    content: "\e904";
}
.js-bag-regular-baggage:before {
    content: "\e900";
}
.js-bag-guitar-surf-golf:before {
    content: "\e906";
}
.js-bag-plane-take-off:before {
    content: "\e901";
}
.js-bag-backpack:before {
    content: "\e902";
}
