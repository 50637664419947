.agency-partial-payment-info-container {
    @apply flex items-center gap-2 bg-cf-light-blue rounded-lg leading-none text-new-ui-blue;
    font-size: 12px;
    margin-top: 16px;
    padding: 9px;
}

.edit-amount-info-container {
    @apply flex items-center bg-cf-light-blue rounded-lg leading-none text-new-ui-blue;
    padding: 7px;
    margin-left: 10px;
    margin-bottom: 10px;
}

@media #{$xsmall} {
    .edit-amount-info-container {
        display: none;
    }
}
