@import "../flight/flight-variables";

.modal-content {
    &.booking-modal-content {
        width: 500px;

        &.pending-payment {
            width: 330px;

            a {
                color: $brand-primary;

                &:hover {
                    color: $brand-secondary;
                }
            }

            .modal-body {
                line-height: 1.6;
            }
        }

        &.argentina-modal {
            width: 1080px;
            max-width: 95%;
        }

        &.edit-profile {
            width: 500px;
            height: 90%;
        }

        .modal-header {
            display: flex;
            align-items: center;
            position: relative;
            padding: $common-gap;
            border-radius: $border-radius-lg;
            background: $header-bg;
            color: $brand-secondary;
            font-weight: 900;
            font-size: $font-size-large;
            user-select: none;
            text-align: left;
            overflow: hidden;

            i {
                margin-right: $common-gap;
                font-size: 36px;
                color: $brand-tertiary;
            }

            &.text-center {
                text-align: center;
                justify-content: center;
            }

            .ribbon-text {
                color: white;
                background: $brand-primary;
                display: block;
                padding: 2px 45px;
                position: absolute;
                bottom: 27px;
                left: -42px;
                transform: rotate(-25deg);
                font-size: $font-size-small;
            }

            .header-with-subtitle {
                display: inline-flex;
                align-items: flex-end;
                flex-direction: column;
                justify-content: flex-end;

                span {
                    display: block;
                    font-weight: 600;

                    &:last-child {
                        font-size: $font-size-small;
                    }
                }
            }
        }

        .modal-body {
            padding: $common-gap;
            &.checkin,
            &.text-line-height {
                line-height: 1.6;
            }
        }

        .modal-button-container {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            &.modification-buttons-container {
                justify-content: center;
            }

            a {
                cursor: pointer;
                margin: 0 $small-gap 0 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .extend-slogan {
            margin-top: 20px;
            font-size: 28px;
            font-weight: 600;
            color: $membership-accent-color;
            line-height: 1.2;
        }

        .extend-info {
            font-size: 20px;
            font-weight: 600;
            color: $brand-secondary;
            line-height: 1;
        }

        .upgrade-icon {
            position: absolute;
            top: -10px;
            right: -35px;
            font-size: 90px;
        }

        .booking-modal-scroller {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 10px;
        }

        .argentina-prices-table {
            td {
                padding: 10px;
                text-align: center;
                vertical-align: top;
                border: 1px solid $gray-lighter;
                line-height: 1.5;
                width: 14.2857%;
            }

            tr {
                &:first-child {
                    td {
                        background: $brand-secondary;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .modal-content {
        &.booking-modal-content {
            left: 5%;
            width: 90%;
            max-height: 90%;
            transform: translateY(-50%);
            overflow: visible;
            overflow-y: visible;

            &.argentina-modal,
            &.edit-profile {
                left: 2%;
                width: 96%;
                max-height: 95%;
            }

            &.pending-payment {
                left: 5%;
                width: 90%;
                max-height: 95%;
            }

            .modal-button-container {
                display: block;

                a {
                    margin: $small-gap 0 0 0;
                    width: 100%;
                    display: block;
                }

                &.modification-buttons-container {
                    .rounded-primary-btn {
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
