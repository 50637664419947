.index-page {
    &.tabs {
        margin-top: 25px;
        user-select: none;

        nav {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;

                    label {
                        display: inline-block;
                        position: relative;
                        width: 250px;
                        height: 80px;
                        line-height: 80px;
                        text-align: center;
                        margin: 0 5px 0 0;
                        background: $gray-lightest;
                        border-top: 1px solid transparent;
                        border-left: 1px solid transparent;
                        border-right: 1px solid transparent;
                        border-top-left-radius: $border-radius-lg;
                        border-top-right-radius: $border-radius-lg;
                        color: $brand-secondary;
                        font-weight: 600;

                        &.darker {
                            background: $gray-lighter;
                        }

                        &:hover {
                            color: $gray-lightest;
                            background: $secondary-btn-color;
                        }

                        span {
                            color: $brand-secondary;
                            font-weight: 600;
                            font-size: 18px;
                        }
                    }
                }
            }

            >input {
                display: none;
            }
        }

        .tab-content,
        .tab-content2 {
            background: #fff;
            padding: 25px;
            border: 1px solid $gray-light;
            border-bottom-left-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
        }

        #tab1:checked~nav .tab1 label,
        #tab2:checked~nav .tab2 label {
            height: 81px;
            bottom: -1px;
            background: #fff;
            border-top: 1px solid $gray-light;
            border-left: 1px solid $gray-light;
            border-right: 1px solid $gray-light;
            color: $brand-secondary;
            cursor: default;
        }

        #tab1:checked~.tab-content,
        #tab2:checked~.tab-content2 {
            display: block;
        }

        .tab-content,
        .tab-content2 {
            display: none;
        }
    }

    .my-trip-title {
        text-transform: uppercase;
        color: $brand-secondary;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.home-spacer {
    height: 100px;
}

// MEDIA QUERIES

@media #{$small-down} {
    .index-page {
        .change-search {
            .primary-btn {
                position: static;
                margin-top: 40px;
            }
        }
    }
}

@media #{$small} {
    .index-page {
        .change-search {
            max-width: calc(100% - 70px);
            text-align: right;
        }
    }
}