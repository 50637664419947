.travel-partner-breadcrumb-container {
    @apply flex items-center;
    margin-bottom: 35px;
}

.travel-partner-breadcrumb {
    @apply font-extra italic text-cug-dark-gray relative;
    font-size: 20px;

    &.past {
        @apply cursor-pointer;

        &:after {
            @apply absolute left-0 right-0 bg-cug-dark-gray;
            content: "";
            bottom: -5px;
            height: 2px;
        }

        &:hover {
            @apply text-cug-red;

            &:after {
                @apply bg-cug-red;
            }
        }
    }

    &.divider {
        margin: 0 8px;
    }
}

.travel-partner-hero {
    @apply font-body text-cug-dark-gray border border-solid border-cug-dark-gray;
    font-size: 17px;
    line-height: 20px;
    border-radius: 7px;
    padding: 15px 25px;
    margin-bottom: 30px;

    h1 {
        @apply font-emphasis text-cug-dark-gray;
        font-size: 21px;
        margin-bottom: 5px;
    }
}

.travel-partner-title {
    @apply flex items-center;
    margin-bottom: 30px;

    i {
        @apply text-cug-dark-gray;
        font-size: 37px;
        margin-right: 15px;
    }

    h2 {
        @apply font-emphasis text-cug-dark-gray leading-none;
        font-size: 21px;
    }

    &.pull-up {
        @apply mb-0;
    }
}

.travel-partner-group-name {
    @apply flex items-center mb-0;

    h2 {
        @apply font-body font-bold text-cug-dark-gray leading-none;
        font-size: 20px;
    }
}

.travel-partner-form {
    label {
        @apply font-body;
    }

    .error-message-container {
        @apply whitespace-normal leading-none;
    }

    .mdl-textfield {
        padding: 15px 0;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: 5px;
            }
        }

        .mdl-textfield__label {
            top: 20px;
        }
    }

    .birthdate-container.cug2b {
        padding-top: 35px;

        .input-inner-title {
            top: 10px;
        }
    }

    .js-input {
        @apply font-body;

        &.js-select {
            @apply appearance-none;
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            @apply block absolute;
            content: "\25BC";
            top: 30px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.travel-partner-back-btn-container {
    @apply flex items-center cursor-pointer;

    i {
        @apply text-be-blue;
        transform: rotate(-180deg);
        margin-right: 30px;
        font-size: 38px;
    }

    span {
        @apply text-be-blue font-body font-bold;
        font-size: 20px;
    }

    &:hover {
        i,
        span {
            @apply text-be-red;
        }
    }
}

.travel-partner-input-and-button {
    margin-bottom: 50px;

    input {
        &::placeholder {
            @apply italic text-cug-light-gray;
        }
    }

    button {
        @apply whitespace-nowrap;
        margin-left: 45px;
    }

    &.pull-up {
        margin-bottom: 20px;
    }
}

.travel-partner-text-and-button {
    @apply flex items-center font-body text-cug-dark-gray w-full;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 50px;

    > div {
        @apply w-full;
    }

    button {
        @apply whitespace-nowrap;
        margin-left: 45px;
    }

    &.pull-up {
        @apply mb-0;
    }
}

.travel-partner-stripe {
    @apply text-cug-dark-gray;
    margin: 0 -25px 15px;
    padding: 23px 35px;
    font-size: 17px;
    line-height: 20px;
    background-color: #eaeaeb;
}

.cug2b-group-admin-group-container {
    @apply flex items-center flex-wrap;
    padding: 0 60px 30px;
}

.cug2b-group-admin-group {
    @apply relative border border-solid border-cug-dark-gray flex flex-col justify-center mx-2 my-2 w-1/2;
    border-radius: 6px;
    min-height: 82px;
    padding: 10px 45px;
    flex-basis: calc(50% - 1rem);
    word-break: break-word;

    .cug2b-group-admin-checkbox {
        @apply absolute vertical-center;
        left: 12px;
    }

    h1 {
        @apply leading-none font-bold text-cug-dark-gray;
        font-size: 20px;
        line-height: 24px;
    }

    h2 {
        @apply leading-none text-cug-dark-gray;
        font-size: 17px;
        line-height: 20px;

        span {
            @apply font-bold;
        }
    }

    i {
        @apply absolute;
        top: 8px;
        right: 10px;
        font-size: 24px;

        &:hover {
            @apply text-be-red;
            cursor: pointer;
        }
    }
}

.cug2b-group-admin-link {
    @apply text-cug-red font-body font-bold cursor-pointer;
    text-align: center;
    font-size: 15px;

    &:hover {
        @apply text-be-blue;
    }

    span {
        @apply ml-1 font-bold inline-block relative;
        top: 3px;
        font-size: 28px;
    }
}

.cug2b-are-you-sure-group-modal {
    width: 920px;
    height: 400px;

    &.delete-only-group-or-also-members {
        height: 270px;
    }
}

.cug2b-are-you-sure-modal-content {
    padding: 30px 0;
}

.cug2b-are-you-sure-modal-table-header {
    @apply bg-cug-gray-2 flex items-center justify-around;
    padding: 15px 30px;

    span {
        @apply font-body font-bold;
        width: 20%;
        text-align: center;
        font-size: 16px;
        line-height: 19px;
    }
}

.cug2b-are-you-sure-modal-table-row {
    @apply flex items-center justify-around;
    padding: 10px 30px;

    span {
        @apply text-cug-gray-3;
        width: 20%;
        text-align: center;
        font-size: 15px;
        line-height: 18px;
    }
}

.cug2b-are-you-sure-modal-info {
    margin-top: 50px;
    text-align: center;
    padding: 0 30px;
    font-size: 20px;
    line-height: 24px;
}

.cug2b-are-you-sure-modal-group-delete {
    @apply p-8;
    text-align: center;
}

.cug2b-are-you-sure-modal-group-list {
    @apply flex flex-wrap;

    &.solo-item {
        @apply justify-center;
    }
}

.cug2b-are-you-sure-modal-group-list-item {
    @apply m-2 border border-solid border-cug-dark-gray;
    padding: 25px 50px;
    border-radius: 6px;
    width: calc(50% - 1rem);

    h1 {
        @apply leading-none font-bold;
        font-size: 20px;
        line-height: 24px;
    }

    h2 {
        @apply leading-none whitespace-nowrap;
        font-size: 17px;
        line-height: 20px;
    }
}

.cug2b-are-you-sure-modal-group-list-info {
    @apply text-cug-dark-gray;
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
}

.cug2b-are-you-sure-modal {
    height: 300px;
    width: 600px;
}

// MEDIA QUERIES

@media #{$small-down} {
    .travel-partner-form {
        .mdl-textfield {
            padding: 5px 0;
        }

        .mdl-textfield__label {
            top: 0;
            font-size: 12px;
        }

        .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
        .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
            top: -2px;
            font-size: 10px;
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            top: 5px;
            padding-left: 6px;
            line-height: 32px;
        }

        .js-input {
            @apply leading-none font-body;
            font-size: 12px;
            height: 32px;
            padding-top: 14px;
            padding-left: 5px;

            &.js-select {
                @apply font-body appearance-none;
                padding-top: 14px;
                padding-left: 5px;
                font-size: 12px;
            }
        }

        .birthdate-container.cug2b {
            margin-bottom: 5px;
            padding-top: 15px;

            .input-inner-title {
                top: 0;
            }
        }

        .js-select-arrow {
            &:after {
                top: 15px;
                right: 7px;
                font-size: 10px;
            }
        }
    }
}

@media #{$small} {
    .travel-partner-breadcrumb-container {
        margin-bottom: 20px;
    }

    .travel-partner-breadcrumb {
        font-size: 15px;

        &.past {
            &:after {
                bottom: -5px;
                height: 2px;
            }
        }

        &.divider {
            margin: 0 8px;
        }
    }

    .travel-partner-hero {
        font-size: 11px;
        line-height: 13px;
        border-radius: 4px;
        padding: 8px 13px;
        margin-bottom: 15px;

        h1 {
            font-size: 13px;
            margin-bottom: 5px;
        }
    }

    .travel-partner-title {
        margin-bottom: 15px;

        i {
            font-size: 22px;
            margin-right: 10px;
        }

        h2 {
            font-size: 13px;
        }

        &.pull-up {
            @apply mb-0;
        }
    }

    .travel-partner-back-btn-container {
        i {
            margin-right: 25px;
            font-size: 30px;
        }

        span {
            font-size: 16px;
        }
    }

    .travel-partner-input-and-button {
        margin-bottom: 30px;

        button {
            margin-left: 25px;
        }
    }

    .travel-partner-text-and-button {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 30px;

        button {
            margin-left: 25px;
        }

        &.pull-up {
            @apply mb-0;
        }
    }

    .cug2b-group-admin-group-container {
        padding: 0 40px 20px;
    }

    .cug2b-group-admin-group {
        border-radius: 4px;
        height: 52px;
        padding: 0 32px;
        flex-basis: calc(50% - 1rem);

        .cug2b-group-admin-checkbox {
            left: 10px;
        }

        h1 {
            font-size: 13px;
            line-height: 15px;
        }

        h2 {
            font-size: 11px;
            line-height: 13px;
        }

        i {
            top: 5px;
            right: 8px;
            font-size: 15px;
        }
    }

    .cug2b-group-admin-link {
        font-size: 10px;

        span {
            @apply ml-1;
            top: 1px;
            font-size: 14px;
        }
    }

    .travel-partner-edit-group-info {
        font-size: 10px;
    }

    .cug2b-are-you-sure-modal-content {
        padding: 20px 0;
    }

    .cug2b-are-you-sure-modal-table-header {
        padding: 10px 20px;

        span {
            font-size: 11px;
            line-height: 14px;
        }
    }

    .cug2b-are-you-sure-modal-table-row {
        padding: 8px 20px;

        span {
            font-size: 11px;
            line-height: 13px;
        }
    }

    .cug2b-are-you-sure-modal-info {
        margin-top: 25px;
        padding: 0 20px;
        font-size: 14px;
        line-height: 17px;
    }

    .cug2b-are-you-sure-modal {
        width: 600px;
        height: 200px;
    }

    .cug2b-are-you-sure-group-modal {
        height: 300px;

        &.delete-only-group-or-also-members {
            height: 250px;
        }
    }

    .cug2b-are-you-sure-modal-group-list-info {
        font-size: 16px;
    }
}

@media #{$xsmall} {
    .travel-partner-breadcrumb-container {
        @apply flex-wrap;
        margin-bottom: 20px;
    }

    .travel-partner-breadcrumb {
        @apply my-1;
        font-size: 13px;

        &.past {
            &:after {
                bottom: -5px;
                height: 2px;
            }
        }

        &.divider {
            margin: 0 8px;
        }
    }

    .travel-partner-hero {
        font-size: 12px;
        line-height: 14px;
        border-radius: 4px;
        padding: 13px 8px;
        margin-bottom: 5px;

        h1 {
            font-size: 13px;
            margin-bottom: 5px;
        }
    }

    .travel-partner-title {
        @apply mb-0;

        i {
            font-size: 22px;
            margin-right: 10px;
        }

        h2 {
            font-size: 13px;
        }
    }

    .travel-partner-input-and-button {
        margin-bottom: 20px;

        button {
            @apply ml-0;
            margin-top: 15px;
        }
    }

    .travel-partner-text-and-button {
        @apply flex-col;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 20px;

        button {
            @apply ml-0;
            margin-top: 15px;
        }

        &.pull-up {
            @apply mb-0;
        }
    }

    .cug2b-group-admin-group-container {
        @apply p-0;
    }

    .cug2b-group-admin-group {
        @apply mx-0 my-1 w-full;
        border-radius: 4px;
        height: 62px;
        padding: 0 35px;
        flex-basis: 100%;

        .cug2b-group-admin-checkbox {
            left: 10px;
        }

        h1 {
            font-size: 14px;
            line-height: 17px;
        }

        h2 {
            font-size: 12px;
            line-height: 14px;
        }

        i {
            top: 6px;
            right: 6px;
            font-size: 15px;
        }
    }

    .cug2b-group-admin-link {
        font-size: 12px;

        span {
            @apply ml-1;
            top: 1px;
            font-size: 14px;
        }
    }

    .travel-partner-edit-group-info {
        @apply mb-4;
        font-size: 10px;
    }

    .cug2b-are-you-sure-modal-content {
        padding: 20px 0;

        button {
            width: calc(100% - 30px);
        }
    }

    .cug2b-are-you-sure-modal-table-header {
        padding: 6px 15px;

        span {
            width: 33.3333%;
            font-size: 11px;
            line-height: 14px;

            &:nth-child(3),
            &:nth-child(4) {
                display: none;
            }
        }
    }

    .cug2b-are-you-sure-modal-table-row {
        padding: 5px 15px;

        span {
            width: 33.3333%;
            font-size: 11px;
            line-height: 13px;

            &:nth-child(3),
            &:nth-child(4) {
                display: none;
            }
        }
    }

    .cug2b-are-you-sure-modal-info {
        margin-top: 20px;
        padding: 0 15px;
        font-size: 10px;
        line-height: 12px;
    }

    .travel-partner-stripe {
        margin: 0 -15px 15px;
        padding: 10px 15px;
        font-size: 12px;
        line-height: 14px;
    }

    .cug2b-are-you-sure-modal {
        width: 300px;
        height: 250px;
    }

    .cug2b-are-you-sure-group-modal {
        height: 320px;

        &.delete-only-group-or-also-members {
            height: 315px;
        }
    }
    .cug2b-are-you-sure-modal-group-list-item {
        @apply w-auto;
    }

    .cug2b-are-you-sure-modal-group-list-info {
        font-size: 16px;
    }
}
