.i2-itinerary-section {
    &.i2-insurance {
        @apply flex items-center justify-start bg-be-cyan-2 text-white;
        grid-area: insurance;
        padding: 35px 50px 35px 50px;
        border-radius: 20px;
    }

    &.i2-pax-insurance {
        @apply relative;
        border-radius: 22px;
        height: 253px;
        padding: 46px 0 0 387px;

        img {
            @apply block absolute top-0 left-0 bottom-0 max-h-full;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        header {
            @apply italic text-be-cyan-5 font-heavy;
            max-width: 580px;
            font-size: 28px;
            line-height: 34px;
        }

        article {
            @apply italic text-be-blue font-heavy;
            font-size: 19px;
            line-height: 28px;
            margin-bottom: 25px;
        }
    }
}

.post-booking-insurance-ribbon {
    @apply absolute flex justify-center items-center left-0 text-white font-bold;
    width: 326px;
    height: 39px;
    font-size: 18px;
    background: rgba(0, 176, 202, 0.8);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    bottom: 15px;
}

.post-booking-insurance-image-container {
    @apply absolute flex justify-center top-0 left-0 bottom-0 max-h-full;
    width: 326px;
    height: 253px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.i2-insurance-logos {
    @apply border-r-2 border-solid border-white;
    margin: 0 30px 0 0;
    padding: 30px 30px 30px 0;

    i {
        font-size: 153px;
        margin-right: 32px;
    }

    span {
        @apply font-emphasis inline-block;
        text-align: center;
        font-size: 29px;
        line-height: 34px;
        max-width: 225px;

        &:first-child {
            margin-bottom: 20px;
        }

        i {
            @apply mr-0;
            font-size: 19px;
            color: #2d2a26;
        }
    }
}

.i2-insurance-info {
    li {
        @apply font-body;
        font-size: 18px;
        line-height: 24px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-itinerary-section {
        &.i2-insurance {
            padding: 25px 40px 25px 40px;
            border-radius: 20px;
        }

        &.i2-pax-insurance {
            height: 200px;
            padding: 20px 0 0 290px;

            header {
                font-size: 26px;
            }
        }
    }

    .i2-insurance-logos {
        margin: 0 25px 0 0;
        padding: 25px 25px 25px 0;

        i {
            font-size: 136px;
            margin-right: 28px;
        }

        span {
            font-size: 27px;
            line-height: 32px;
            max-width: 225px;

            &:first-child {
                margin-bottom: 20px;
            }

            i {
                @apply mr-0;
                font-size: 17px;
            }
        }
    }

    .i2-insurance-info {
        li {
            font-size: 17px;
            line-height: 21px;

            &:last-child {
                max-width: 250px;
            }
        }
    }

    .post-booking-insurance-ribbon {
        width: 258px;
    }
}

@media #{$medium} {
    .i2-itinerary-section {
        &.i2-insurance {
            padding: 20px 36px 20px 36px;
            border-radius: 20px;
        }

        &.i2-pax-insurance {
            height: 150px;
            padding: 30px 0 0 230px;

            header {
                max-width: 350px;
                font-size: 17px;
                line-height: 20px;
            }

            article {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 10px;
            }
        }
    }

    .i2-insurance-logos {
        margin: 0 25px 0 0;
        padding: 25px 25px 25px 0;

        i {
            font-size: 120px;
            margin-right: 24px;
        }

        span {
            font-size: 21px;
            line-height: 24px;
            max-width: 225px;

            &:first-child {
                margin-bottom: 16px;
            }

            i {
                @apply mr-0;
                font-size: 15px;
            }
        }
    }

    .i2-insurance-info {
        li {
            font-size: 15px;
            line-height: 19px;

            &:last-child {
                max-width: 250px;
            }
        }
    }

    .post-booking-insurance-ribbon {
        width: 193px;
    }
}

@media #{$small} {
    .i2-itinerary-section {
        &.i2-insurance {
            padding: 18px 25px 18px 25px;
            border-radius: 12px;
        }

        &.i2-pax-insurance {
            height: 150px;
            padding: 30px 0 0 230px;

            header {
                max-width: 350px;
                font-size: 17px;
                line-height: 20px;
            }

            article {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 10px;
            }
        }

        .post-booking-insurance-ribbon {
            width: 193px;
        }
    }

    .i2-insurance-logos {
        margin: 0 18px 0 0;
        padding: 18px 18px 18px 0;

        i {
            font-size: 96px;
            margin-right: 5px;
        }

        span {
            font-size: 21px;
            line-height: 24px;
            max-width: 162px;

            &:first-child {
                margin-bottom: 12px;
            }

            i {
                @apply mr-0;
                font-size: 15px;
            }
        }
    }

    .i2-insurance-info {
        li {
            font-size: 18px;
            line-height: 24px;

            &:last-child {
                max-width: 270px;
            }
        }
    }
}

@media #{$xsmall} {
    .i2-itinerary-section {
        &.i2-insurance {
            @apply flex flex-col items-center justify-start;
            padding: 16px 8px 16px 8px;
            border-radius: 12px;
        }

        &.i2-pax-insurance {
            height: 126px;
            padding: 10px 20px 0 140px;
            border-radius: 5px;

            header {
                @apply mb-0;
                font-size: 11px;
                line-height: 13px;
            }

            img {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            article {
                font-size: 10px;
                line-height: 12px;
                margin-bottom: 10px;
            }

            a {
                padding: 2px 30px 4px 15px;
                max-width: 125px;
            }
        }
    }

    .i2-insurance-logos {
        @apply border-0 p-0;
        margin: 0 0 30px 0;

        i {
            font-size: 71px;
            margin-right: 20px;
        }

        span {
            font-size: 20px;
            line-height: 24px;
            max-width: 155px;

            &:first-child {
                margin-bottom: 0;
            }

            i {
                font-size: 11px;
                margin-right: 0;
            }
        }
    }

    .i2-insurance-info {
        max-width: 240px;
        margin: 0 auto;

        li {
            font-size: 15px;
            line-height: 21px;

            &:last-child {
                max-width: unset;
            }
        }
    }

    .post-booking-insurance-ribbon {
        width: 125px;
        font-size: 13px;
        height: 30px;
    }
}
