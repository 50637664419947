.login-page {
    margin-top: 30px;

    .login-link {
        color: $brand-primary;

        &:hover {
            color: $brand-secondary;
        }
    }

    .booking-wrapper {
        margin: 0 auto;
        max-width: 1155px;
        width: unset;

        header {
            .title {
                margin-left: 0;

                &.title-with-image {
                    display: flex;
                    justify-content: space-between;

                    img {
                        display: inline-block;
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    .login-member-list {
        list-style: none;
        margin: 15px 0 10px 25px;

        li {
            margin: 4px 16px;
            font-size: $font-size-medium;
            line-height: 24px;

            &:before {
                content: "\2022";
                display: block;
                position: relative;
                max-width: 0;
                max-height: 0;
                left: -15px;
                top: 0px;
                color: $brand-secondary;
                font-size: 21px;
            }
        }
    }

    .chilecompra-video {
        width: 1000px;
        height: 340px;
        max-width: 90%;
        margin: 0 auto;
        display: block;
    }

    h3 {
        text-transform: none;
        font-size: 24.5px;
        line-height: 40px;
        margin: 10px 0;
        font-weight: 600;
        text-rendering: optimizelegibility;
    }

    .modal-button-container {
        @apply flex justify-end;
    }

    .cc-link {
        color: $brand-primary;

        &:hover {
            color: $brand-secondary;
        }
    }

    .move-down {
        margin-top: 40px;
    }
}

.logintype-tabs {
    text-align: left;

    label {
        margin-right: 24px;
    }
}

.mdl-checkbox-wrapper {
    &.cug {
        &.argentina {
            position: relative;

            #argentinaCompanyInfo {
                position: relative;
                top: 3px;
                left: 2px;
            }
        }

        .cb-title {
            line-height: 1;
        }
    }
}

.login-error {
    color: $error-color;
    margin-bottom: $common-gap;
    line-height: 1.4;
}

.progress-bar {
    margin-bottom: $common-gap;
}

.login-body {
    line-height: 1.5;
}

.captcha-load-error {
    color: $brand-primary;
    margin: 20px 0;
}

.login-img {
    display: block;
    margin: 40px auto 20px;
    width: 66%;
    height: auto;

    &.small {
        width: 45%;
    }
}

.float-login-modal {
    user-select: none;

    .mdl-textfield {
        padding-bottom: 0;
    }
}

// DEVNOTE Refund scenario, indentations are given.
.refund-login {
    .modal-content {
        &.booking-modal-content {
            .modal-header {
                @apply overflow-visible;
            }

            i {
                display: none;
            }

            .modal-button-container {
                @apply flex justify-around;
            }
        }
    }

    .modal-header {
        .cancel-login {
            @apply absolute top-0 right-0 flex items-center justify-center p-0 font-normal leading-none border-0 text-white bg-black cursor-pointer rounded-full;
            transform: translate(50%, -50%);
            font-size: 36px;
            width: 40px;
            height: 40px;
            z-index: 1;
            letter-spacing: unset;

            &:hover {
                @apply border-0 no-underline bg-gray-700 text-white;
            }
        }

        .cancel-login {
            &:hover {
                @apply text-white;
            }
        }
    }

    .mdl-textfield {
        @apply w-3/4;
    }

    .logintype-tabs {
        @apply flex justify-start;

        label {
            @apply mr-4;
        }
    }
}

/* MEDIA QUERIES */

@media #{$large-down} {
    .login-page {
        .booking-wrapper {
            margin-left: 25px;
            margin-right: 25px;
        }
    }
}

@media #{$medium-down} {
    .login-page {
        .booking-wrapper {
            margin-left: 25px;
        }
    }
}

@media #{$small-down} {
    .login-page {
        .booking-wrapper {
            .push-down {
                margin-top: 40px;
            }
        }
    }
}

@media #{$xsmall} {
    .refund-login {
        .modal-content {
            &.booking-modal-content {
                .modal-button-container {
                    @apply flex flex-col mt-2 mx-auto w-48;
                }
            }
        }

        .modal-header {
            .cancel-login {
                font-size: 30px;
                width: 30px;
                height: 30px;
            }
        }

        .mdl-textfield {
            @apply w-full;
        }

        .logintype-tabs {
            @apply block;

            label {
                margin-top: 5px;
                margin-right: 10px;
            }
        }
    }

    .float-login-modal {
        .mdl-textfield {
            padding-top: 15px;
        }

        .modal-content {
            padding: 8px;

            &.booking-modal-content {
                .modal-header {
                    padding: 12px;
                }

                .modal-body {
                    padding: 10px 5px;

                    .modal-button-container {
                        margin-top: 20px;

                        a {
                            margin-top: 8px;
                        }
                    }

                    .banco-estado-login-footnote {
                        font-size: 13px;
                        margin-top: 8px;
                    }
                }
            }
        }
    }
}
