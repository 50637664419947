.emergency-warning {
    @apply relative inline-block w-full border border-solid border-cug-gray-2 bg-white text-brand-secondary;
    margin-top: 10px;
    padding: 5px 8px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.seatmap-warning-modal {
    .booking-modal-content {
        max-width: 90%;
        width: 820px;
        line-height: 1.8;
        height: 680px;
        max-height: 90%;

        .modal-header {
            @apply leading-none;
            margin: 20px;
        }
    }

    .terms-modal-list {
        list-style-type: square;
        line-height: 1.8;

        li {
            margin-left: 20px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium-down} {
    .emergency-warning {
        @apply border-none;
    }

    .seatmap-warning-modal {
        .rounded-primary-btn {
            @apply text-white;
        }
    }
}
