.i2-itinerary-container {
    @apply relative row-auto grid w-full;
    padding: 30px 0;
    grid-template-columns: 1fr;
    grid-template-areas:
        "header"
        "dc"
        "insurance"
        "tabs"
        "itinerary-banners-container"
        "optionals"
        "third-party";

    ac-flight-journey-summary {
        width: 75%;
    }
}

.itinerary-banners-container {
    grid-area: itinerary-banners-container;
}

.i2-itinerary-section {
    @apply w-full border-be-gray-10 bg-white shadow-medium;
    border-radius: 12px;
    margin-bottom: 30px;

    .i2-btn {
        @apply relative m-0 inline-block min-w-0 cursor-pointer select-none rounded-full border-2 border-solid border-be-red bg-be-red font-body font-black normal-case not-italic text-white;
        letter-spacing: normal;
        font-size: 18px;
        transition: all 0.25s ease-in-out;

        &.disabled {
            @apply pointer-events-none;
            background-color: #ccc;
            border-color: #ccc;

            &:hover {
                @apply text-white;
                background-color: #aaa;
                border-color: #aaa;
            }
        }

        &:not(.btn):focus,
        &:not(.btn):active {
            @apply border-2 border-solid border-be-red bg-be-red text-white;
        }

        &:not(.disabled) {
            &:hover {
                @apply border-2 border-solid border-be-red bg-white font-body font-black normal-case text-be-red;
                letter-spacing: normal;

                &:after {
                    @apply text-be-red;
                }
            }
        }

        &.i2-btn-blue {
            @apply border-be-blue bg-be-blue;
            width: 270px;

            &:not(.btn):focus,
            &:not(.btn):active {
                @apply border-be-blue bg-be-blue;
            }

            &:not(.disabled) {
                &:hover {
                    @apply border-be-blue text-be-blue;

                    &:after {
                        @apply text-be-blue;
                    }
                }
            }
        }
    }
}

.i2-book-hotel-section {
    @apply flex justify-center;
    text-align: center;
    margin-bottom: 30px;
    grid-area: third-party;

    .i2-book-hotel-content {
        @apply relative italic text-be-blue;
        max-width: 525px;
        font-family: "ClanOT-Black", sans-serif;
        font-size: 28px;
        line-height: 30px;

        &:after,
        &:before {
            @apply absolute bg-be-blue vertical-center;
            content: "";
            width: 45px;
            height: 1px;
        }

        &:before {
            left: -55px;
        }

        &:after {
            right: -55px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-itinerary-section {
        .i2-btn {
            font-size: 16px;
        }
    }
}

@media #{$medium} {
    .i2-itinerary-section {
        .i2-btn {
            font-size: 13px;

            &.i2-btn-blue {
                width: 220px;
            }
        }
    }
}

@media #{$small} {
    .i2-itinerary-section {
        .i2-btn {
            @apply leading-none;
            font-size: 11px;

            &.i2-btn-blue {
                width: 190px;
            }
        }
    }

    .i2-book-hotel-section {
        .i2-book-hotel-content {
            @apply max-w-full;
            max-width: 400px;
            font-size: 22px;
        }
    }
}

@media #{$xsmall} {
    ac-itinerary-page {
        display: block;
        width: 100%;
        overflow: hidden;
    }

    .i2-itinerary-section {
        @apply mx-auto;
        border-radius: 6px;
        width: 90%;

        .i2-btn {
            @apply leading-none;
            font-size: 11px;

            &.i2-checkin-btn {
                font-size: 15px;
                width: calc(100% - 20px);
            }

            &.i2-btn-blue {
                width: calc(100% - 30px);
            }
        }
    }

    .i2-book-hotel-section {
        .i2-book-hotel-content {
            @apply max-w-full;
            font-size: 12px;
            max-width: 65%;
            line-height: 20px;

            &:after,
            &:before {
                content: "";
                width: 30px;
            }

            &:before {
                left: -35px;
            }

            &:after {
                right: -35px;
            }
        }
    }
}
