@import "./flight-variables";

.flight-itinerary {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid $brand-secondary;
    border-radius: $border-radius-lg;
    background: #fff;
    padding: 0 230px 0 30px;
    height: $flight-widget-height;
    width: $flight-widget-width;
    max-width: 95%;
    margin: 0 auto;
    font-family: "Lato", sans-serif;

    &.push-down {
        margin-top: 110px;
    }

    &.change-route {
        max-width: 100%;
        width: 100%;
        border-top-right-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        margin: 20px 0 40px;
        border-color: #000;
    }

    .station {
        max-width: 200px;
        line-height: 20px;

        span {
            font-size: 20px;
            font-weight: 900;
            color: $brand-secondary;

            &:last-child {
                margin-top: 3px;
                display: block;
                font-size: $font-size-medium;
                font-weight: 300;
                color: $brand-secondary;
                text-transform: uppercase;
            }
        }
    }

    .flight-date {
        position: relative;
        font-size: $font-size-xsmall;
        font-weight: 600;
        color: $brand-secondary;
        width: 17%;
        margin: 0 10px 15px;
        border-bottom: 1px solid $brand-secondary;
        text-align: center;
        white-space: nowrap;
    }

    .flight-edit {
        @apply absolute vertical-center;
        right: 270px;
        font-size: 18px;
        color: $brand-secondary;
        cursor: pointer;

        &:hover {
            color: $brand-primary;
        }
    }

    .flight-switch {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        width: 260px;
        border: 1px solid $flight-switch-color;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: $flight-switch-color;
        color: white;
        font-size: $font-size-small;
    }
}

/* MEDIA QUERIES */
@media #{$large} {
    .flight-itinerary {
        &.push-down {
            margin-top: 130px;
        }

        .station {
            max-width: 180px;
            line-height: 18px;

            span {
                font-size: 18px;
            }
        }
    }
}

@media #{$medium} {
    .flight-itinerary {
        overflow: visible;
        margin-bottom: 60px;
        padding: 0 50px 0 30px;

        &.push-down {
            margin-top: 150px;
        }

        .station {
            max-width: 180px;
            line-height: 18px;

            span {
                font-size: 18px;
            }
        }
    }
}

@media #{$small} {
    .booking-wrapper {
        &.change-route-info-emphasis {
            .title {
                margin-left: 25px;
                margin-right: 25px;
            }
        }
    }

    .flight-itinerary {
        overflow: visible;
        margin-bottom: 60px;
        padding: 0 50px 0 30px;

        &.push-down {
            margin-top: 30px;
        }

        .station {
            max-width: 180px;
            line-height: 18px;

            span {
                font-size: 18px;
            }
        }
    }
}

@media #{$xsmall} {
    .booking-wrapper {
        &.change-route-info-emphasis {
            .title {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    .flight-itinerary {
        padding-left: 20px;
        max-width: 90%;

        &.push-down {
            margin-top: 30px;
        }

        .station {
            span {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    font-size: 24px;
                    font-weight: 900;
                    color: $brand-secondary;
                }
            }
        }

        .flight-date {
            width: 80px;
            white-space: nowrap;

            span {
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                font-size: $font-size-xsmall;
                font-weight: 600;
                color: $brand-secondary;
                white-space: nowrap;
            }
        }

        .flight-switch {
          width: 110px;
        }


        .flight-edit {
            top: 12px;
            right: 115px;
        }

    }
}
