/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "js-refund-icons";
    src: url("../fonts/js-refund-icons.eot?8lmy5b");
    src: url("../fonts/js-refund-icons.eot?8lmy5b#iefix") format("embedded-opentype"),
        url("../fonts/js-refund-icons.ttf?8lmy5b") format("truetype"),
        url("../fonts/js-refund-icons.woff?8lmy5b") format("woff"),
        url("../fonts/js-refund-icons.svg?8lmy5b#js-refund-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-refund {
    font-family: "js-refund-icons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-refund-rw-highlight-icon-mobile:before {
  content: "\e905";
}

.js-refund-rw-highlight-lines:before {
    content: "\e904";
}

.js-refund-rw-passengers:before {
    content: "\e903";
}

.js-refund-refund-person:before {
    content: "\e900";
}

.js-refund-rw-bank-info:before {
    content: "\e902";
}

.js-refund-rw-profile:before {
    content: "\e901";
}