dc-pager {
    .dc-pager {
        @apply flex select-none items-center justify-center;
    }

    .dc-pager-items {
        @apply flex select-none;
    }

    .dc-pager-number {
        @apply border border-solid border-cug-light-gray cursor-pointer font-body font-bold m-2 flex items-center justify-center w-10 h-8 text-cug-light-gray;
        font-size: 19px;
        border-radius: 5px;

        &.current-page {
            @apply border-cug-red text-cug-red cursor-default;

            &:hover {
                @apply border-cug-red text-cug-red bg-transparent;
            }
        }

        &:hover {
            @apply text-white bg-cug-red;
        }
    }

    .dc-pager-control {
        @apply border border-solid border-transparent cursor-pointer font-body font-bold flex my-2 items-center justify-center w-8 h-8 text-cug-dark-gray;
        font-size: 32px;
        padding-bottom: 6px;

        &:hover {
            @apply text-white bg-cug-red;
        }
    }

    .dc-pager-page-size-selector {
        @apply mr-2 inline-block;
    }
}
