@import "./flight/flight-variables";
@import "./flight/flight-itinerary";
@import "./flight/flight-fee-selector";
@import "./flight/flight-change-search";
@import "./flight/flight-calendar";

#mainContentFlight {
    .booking-wrapper {
        padding: 0;
        border: none;
        box-shadow: none;
        background: transparent;

        &.no-bottom-margin {
            margin-bottom: 0;
        }
    }
}

.flight-farelock-button-container {
    width: $flight-widget-width;
    max-width: 95%;
    margin: 40px auto 0;
    position: relative;

    &.pull-up {
        margin-top: 20px;
    }
}

.flight-scroller {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.flight-currency-select {
    background: transparent;
    -webkit-appearance: none;
    border: none;
    margin: 0;
    padding: 10px 10px 10px 5px;
    width: unset;
    display: inline-block;
    color: #fff;
    font-weight: 600;

    option {
        display: inline-block;
        color: $gray;
    }
}

/* MEDIA QUERIES */

@media #{$small-down} {
    .flight-scroller {
        top: 170px;
    }
}

@media #{$xsmall} {
    .flight-scroller {
        top: 110px;
    }
}
