.booking-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 130px;
    margin: 25px 0;
    padding: 25px;
    border-radius: $border-radius-lg;
    background: #fff;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.15);

    &.transaction-wrapper {
        padding: 0;
        margin-bottom: 0;
        min-height: unset;

        .full-width-information {
            margin-bottom: 0;
        }
    }

    &.push-down {
        margin-top: 50px;
    }

    &.no-min {
        min-height: unset;
    }

    header {
        display: flex;
        align-items: center;
        margin-bottom: $small-gap;

        .title {
            margin-left: $small-gap;

            &.full-width-title {
                width: 100%;
            }
        }

        .main-title {
            margin: 0 0 5px 0;
            color: $brand-secondary;
            font-family: "Lato", sans-serif;
            font-weight: 900;
            font-size: $font-size-large;
            line-height: 1;
        }

        .subtitle {
            font-size: $font-size-small;
            font-weight: $semibold-font-weight;
            color: $brand-secondary;
        }

        &.transactions-header {
            position: relative;
            background: $transaction-header-color;
            border-top-left-radius: $border-radius-lg;
            border-top-right-radius: $border-radius-lg;
            padding: 12px 25px;
            margin-top: 60px;

            .close-transaction {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -26px;
                right: -26px;
                width: 52px;
                height: 52px;
                background: #000;
                color: #fff;
                border-radius: 50%;
                font-size: 31px;
                line-height: 1;
                font-weight: 600;
                cursor: pointer;

                &:hover {
                    background: $brand-primary;
                }
            }

            i {
                &.title-icon {
                    font-size: 43px;
                    color: #fff;
                }

                &.standalone-dc {
                    font-size: 30px;
                }
            }

            .main-title {
                color: #fff;
                font-size: 32px;
                line-height: 1;
                font-family: "ClanOT-Bold", sans-serif;
                margin: 6px 0 0;

                &.standalone-dc {
                    font-family: "Lato", sans-serif;
                    font-weight: 900;
                    font-size: 22px;
                    margin: 0;
                }
            }
        }

        &.transaction-header {
            margin-bottom: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid $gray-lighter;

            i {
                &.title-icon {
                    font-size: 38px;
                }
            }

            .main-title {
                font-size: 20px;
                line-height: 1;
                font-family: ClanOT-Bold;
                margin: 6px 0 0;

                &.fare-lock {
                    font-family: "Lato", sans-serif;
                    font-weight: 400;

                    span {
                        font-family: "Lato", sans-serif;
                        font-weight: 900;
                    }
                }
            }
        }
    }
}

.passed-content {
    .booking-wrapper {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(#fff, 0.75);
            z-index: 198;
            border-radius: $border-radius-lg;
        }
    }
}

.inner-box {
    min-height: 130px;
    margin-bottom: 30px;
    padding: 17px;
    border: 1px $brand-secondary solid;
    border-radius: $border-radius-lg;

    a {
        color: $brand-secondary;

        &:hover {
            color: $brand-primary;
        }
    }

    p {
        font-size: $font-size-small;

        &:first-child {
            margin-bottom: 0.25rem;
        }
    }

    &.prohibited {
        .form-error-message {
            margin-bottom: 0;
        }
    }

    &.fare-lock {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: $border-radius-lg;
            z-index: 1;
            background: rgba($gray, 0.2);
        }
    }
}

.inner-deep-box {
    min-height: 90px;
    padding: 0 15px 0 15px;
    border-radius: $border-radius-lg;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: $gray-lightest;

    &.padded {
        padding: 20px;
    }

    &.rounded {
        border-radius: $border-radius-lg;
        border-bottom-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
    }

    &.upper-half {
        min-height: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &.bottom-half {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

/* MEDIA QUERIES */

@media #{$large-down} {
    .inner-deep-box {
        &.bottom-half {
            border-top-left-radius: $border-radius-lg;
            border-top-right-radius: $border-radius-lg;
        }
    }
}

@media #{$small-down} {
    .booking-wrapper {
        header {
            &.transactions-header {
                margin-top: 20px;

                .close-transaction {
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                }
            }

            .title {
                &.full-width-title {
                    width: calc(100% - 100px);

                    .main-title {
                        font-size: $font-size-xlarge;
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .booking-wrapper {
        max-width: 95%;
        margin: 25px auto;
    }
}
