.transfer-information-container {
    @apply flex bg-cf-light-blue rounded leading-none text-be-cyan-2;
    font-size: 12px;
    padding: 10px;
    margin-bottom: 8px;

    i {
        margin-right: 10px;
    }

    &.narrow {
        @apply w-full;
        max-width: max-content;
        margin: 20px auto;
    }
}

@media #{$xsmall} {
    .transfer-information-container-wrapper {
        margin: 0 15px;
    }
}
