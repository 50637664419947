@import "./flight-variables";

.change-search {
    width: $flight-widget-width;
    max-width: 95%;
    border: 1px solid $brand-secondary;
    background: #fff;
    margin: 20px auto 40px;
    border-radius: $border-radius-lg;
    padding: 20px;

    header {
        text-transform: uppercase;
        color: $brand-secondary;
        font-weight: 600;
        margin-bottom: 0;
    }

    h2 {
        color: $brand-secondary;
        font-size: 24px;
        margin-top: 23px;
    }

    .cb-title {
        line-height: 1.4;

        a {
            color: $membership-link-color;

            &:hover {
                color: $brand-secondary;
            }
        }
    }

    .change-search-label {
        font-size: $font-size-small;
        font-weight: 600;
        color: $brand-secondary;
        display: block;
        cursor: default;
    }

    .jetsmart-number-selector {
        margin-top: 5px;
    }

    .form-group {
        margin-bottom: 20px;

        input[type="text"],
        select {
            padding: 1rem;
            font-size: 1rem;
        }
    }
}

.change-search,
.change-currency {
    .mdl-textfield {
        padding: 20px 0 0;

        &:last-child {
            padding: 20px 0;
        }
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 39px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.form-group {
    &.padded {
        margin-bottom: 20px;
    }
}

.tab-content,
.tab-content2 {
    .change-search {
        margin: 0;
        width: unset;
        max-width: unset;
        position: relative;
        min-height: 200px;
    }
}

.flight-change-search-button-container {
    @apply mt-12 flex justify-end;
}

/* MEDIA QUERIES */
@media #{$small-down} {
    .flight-change-search-button-container {
        @apply mt-8;
    }
}

@media #{$xsmall} {
    .change-search {
        .change-search-label {
            margin-top: 20px;

            &.condensed {
                margin-top: 0;
            }
        }
    }
}