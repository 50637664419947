.cug2b-register-content {
    @apply w-full;
    background: #f7f6f6 url("/Images/Cug22/register-bg.png") no-repeat top center;
    background-size: cover;
    padding: 330px 0 220px;
}

.cug2b-register-main {
    @apply bg-white shadow-medium mx-auto;
    width: 1600px;
    max-width: 95%;
    border-radius: 10px;
}

.cug2b-register-bar {
    @apply w-full bg-cug-gray-5 text-cug-light-gray font-emphasis flex items-center relative z-0 overflow-hidden;
    height: 90px;
    font-size: 21px;
    line-height: 27px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cug2b-register-bar-number {
    @apply rounded-full bg-cug-light-gray text-white flex items-center justify-center flex-shrink-0;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    font-size: 26px;
    padding-top: 6px;
}

.cug2b-register-bar-section {
    @apply h-full flex items-center justify-center rounded-r-full relative w-1/3;
    padding: 0 80px 0 45px;
    text-align: center;

    &:first-child {
        border-top-left-radius: 10px;
    }

    &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 0;
        width: calc(33.333333% + 1px);
    }

    &.past {
        @apply bg-cug-dark-gray text-white;

        .cug2b-register-bar-number {
            @apply bg-white text-be-red;
        }

        .aux-bg {
            @apply absolute inset-0 bg-cug-gray-7;
            z-index: -1;
            border-top-left-radius: 10px;

            &.darker {
                @apply bg-cug-dark-gray;
            }
        }
    }

    &.current {
        @apply bg-cug-gray-7 text-white z-0;

        .cug2b-register-bar-number {
            @apply bg-white text-cug-gray-7;
        }
    }
}

.cug2b-register-tick {
    @apply absolute border-solid border-white;
    width: 12px;
    height: 20px;
    right: 30px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    border-bottom-width: 5px;
    border-right-width: 5px;
    border-left: none;
    border-top: none;
}

.cug2b-register-header {
    @apply flex items-center text-be-blue-2;
    margin: 35px 65px;

    i {
        margin-right: 15px;
        font-size: 50px;
    }

    h1 {
        @apply font-emphasis leading-none;
        font-size: 22px;
    }

    h2 {
        @apply font-body leading-none;
        font-size: 20px;
    }
}

.drag-file {
    @apply w-full h-48 flex flex-col items-center justify-center border border-dashed border-be-blue rounded-lg cursor-pointer overflow-hidden relative;
    background: url("/Images/Cug22/file-upload.png") no-repeat center center;

    input {
        @apply opacity-0 cursor-pointer absolute top-0;
        left: -31000px;
    }

    &.highlighted {
        @apply bg-cug-lightest-gray text-white;

        .drag-file-label {
            @apply text-white;
        }
    }

    &.has-file {
        @apply bg-be-gray-10;
        background-image: none;

        &.highlighted {
            @apply bg-cug-lightest-gray text-white;
        }
    }
}

.drag-file-label {
    @apply text-be-blue absolute top-0 left-0 mt-4 ml-4 font-black;
    font-size: 14px;
}

.ac-tooltip-balloon {
    &.rnt-tooltip {
        max-width: 352px;

        img {
            width: 320px;
        }
    }
}

.cug2b-register-form {
    @apply block;
    margin: 0 65px;

    .birthdate-container,
    .expiration-date-container {
        padding-top: 26px;

        .input-inner-title {
            top: 5px;

            span:not(.ac-tooltip) {
                @apply mr-2 text-be-blue font-bold;
                font-size: 14px;
            }
        }
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;
            -webkit-appearance: none;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }

    .mdl-textfield {
        padding: 5px 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 9px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }
    }

    dc-checkbox {
        @apply flex items-center;
        height: 30px;

        input {
            &.dc-checkbox {
                &[type="checkbox"] {
                    &:not(:disabled) {
                        + label {
                            &[for] {
                                @apply text-be-blue font-body font-bold flex items-center;
                                font-size: 20px;
                                height: 30px;
                                padding-left: 50px;

                                &:before {
                                    @apply bg-white;
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                        &[checked] {
                            + label {
                                &[for] {
                                    &:after {
                                        @apply border-be-blue;
                                        width: 10px;
                                        height: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cug2b-register-btn-container {
    @apply flex items-center justify-between mt-16;
    padding-bottom: 35px;

    &.right {
        @apply justify-end;
    }

    &.center {
        @apply justify-center;
    }

    &.pull-up {
        @apply mt-0;
    }
}

.cug2b-register-back-btn {
    @apply font-body font-bold flex items-center cursor-pointer;
    font-size: 20px;
    transition: all 0.2s ease-in-out;

    i {
        @apply inline-block transform rotate-180;
        font-size: 38px;
        margin-right: 25px;
    }

    &:hover {
        @apply text-be-blue;

        i {
            @apply text-be-blue;
        }
    }
}

.cug2b-register-checkbox-title {
    @apply font-body font-bold text-be-blue;
    font-size: 20px;
}

.cug2b-register-summary {
    @apply flex items-stretch justify-between;
    margin: 0 65px 25px;
}

.cug2b-invoice-warning {
    @apply border border-solid border-cug-gray-4 w-full relative bg-be-cyan text-white;
    padding: 25px 65px 25px 110px;
    margin-bottom: 25px;
    border-radius: 8px;
    font-size: 16px;

    i {
        @apply absolute vertical-center;
        left: 65px;
        font-size: 30px;
    }
}

.cug2b-register-summary-section {
    @apply border border-solid border-cug-gray-4 w-1/2;
    padding: 25px 65px;
    border-radius: 8px;

    &:first-child {
        @apply mr-8;
    }

    li {
        @apply flex items-center;
        line-height: 24px;

        span {
            @apply w-1/2;

            &:first-child {
                @apply font-bold text-brand-secondary;
                font-size: 16px;
            }

            &:last-child {
                @apply italic text-cug-light-gray;
                font-size: 20px;
            }
        }
    }
}

.cug2b-register-summary-section-title {
    @apply font-emphasis text-be-blue flex items-center;
    font-size: 22px;
    margin-bottom: 36px;
    line-height: 26px;

    i {
        @apply text-be-blue;
        font-size: 35px;
        margin-right: 20px;
    }
}

.cug2b-register-terms-container {
    @apply w-full flex items-center whitespace-nowrap justify-end;

    dc-checkbox {
        @apply w-auto;
    }

    a {
        @apply font-semibold underline text-be-cyan-2;
        font-size: 15px;
    }
}

.cug2b-register-success-container {
    @apply flex items-stretch overflow-hidden;
    border-radius: 10px;
}

.cug2b-register-success-banner {
    @apply w-1/2;
}

.cug2b-register-success-main {
    @apply flex flex-col items-center w-1/2;
    padding: 0 55px;

    i {
        @apply text-be-blue;
        font-size: 155px;
        margin: 45px 0 25px;
    }

    h1,
    h2 {
        @apply text-be-blue font-heavy;
        text-align: center;
        font-size: 41px;
        line-height: 51px;
    }

    p {
        @apply text-be-blue;
        text-align: center;
        font-size: 19px;
        line-height: 23px;
    }
}

.cug2b-register-success-footnote {
    @apply text-be-blue;
    font-size: 20px;
    line-height: 23px;
    margin: 75px 0 15px;
}

/* MEDIA QUERIES */

@media #{$small} {
    .cug2b-register-content {
        padding: 185px 0 150px;
    }

    .cug2b-register-main {
    }

    .cug2b-register-bar {
        height: 56px;
        font-size: 14px;
        line-height: 17px;
    }

    .cug2b-register-bar-number {
        width: 24px;
        height: 24px;
        margin-right: 7px;
        font-size: 16px;
        padding-top: 3px;
    }

    .cug2b-register-bar-section {
        padding: 0 45px 0 15px;
    }

    .cug2b-register-tick {
        width: 6px;
        height: 14px;
        right: 20px;
        border-bottom-width: 3px;
        border-right-width: 3px;
    }

    .cug2b-register-btn-container {
        @apply mt-4;
    }

    .cug2b-register-header {
        margin: 20px 25px;

        i {
            margin-right: 8px;
            font-size: 31px;
        }

        h1 {
            font-size: 15px;
        }

        h2 {
            font-size: 14px;
        }
    }

    .cug2b-register-back-btn {
        font-size: 14px;

        i {
            font-size: 23px;
            margin-right: 15px;
        }
    }

    .cug2b-register-checkbox-title {
        font-size: 14px;
    }

    .cug2b-register-form {
        margin: 0 25px;

        .birthdate-container,
        .expriation-date-container {
            padding-top: 18px;

            .input-inner-title {
                top: -2px;
            }

            .dob-selector-container {
                &:after {
                    top: 5px;
                }

                select {
                    height: 24px;
                }
            }
        }

        .mdl-textfield {
            margin-top: 5px;
        }

        .mdl-textfield__label {
            top: 0;
            font-size: 12px;
        }

        .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
        .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
            top: -2px;
            font-size: 10px;
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            top: 5px;
            padding-left: 6px;
            line-height: 32px;
        }

        .js-input {
            @apply leading-none font-body;
            font-size: 12px;
            height: 32px;
            padding-top: 14px;
            padding-left: 5px;

            &.js-select {
                @apply font-body;
                padding-top: 14px;
                padding-left: 5px;
                font-size: 12px;
                appearance: none;
            }
        }

        .js-select-arrow {
            &:after {
                top: 15px;
                right: 7px;
                font-size: 10px;
            }
        }

        dc-checkbox {
            height: 20px;

            input {
                &.dc-checkbox {
                    &[type="checkbox"] {
                        &:not(:disabled) {
                            + label {
                                &[for] {
                                    font-size: 14px;
                                    height: 20px;
                                    padding-left: 30px;

                                    &:before {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                            }
                            &[checked] {
                                + label {
                                    &[for] {
                                        &:after {
                                            width: 8px;
                                            height: 17px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cug-info-icon {
            &.cug-register {
                right: 7px;
                top: 13px;
            }
        }
    }

    .cug2b-register-summary {
        margin: 0 25px 20px;
    }

    .cug2b-invoice-warning {
        padding: 15px 25px 15px 58px;
        margin-bottom: 25px;
        border-radius: 5px;
        font-size: 12px;

        i {
            left: 25px;
            font-size: 18px;
        }
    }

    .cug2b-register-summary-section {
        padding: 15px 20px;
        border-radius: 5px;

        &:first-child {
            @apply mr-4;
        }

        li {
            line-height: 17px;

            span {
                &:first-child {
                    font-size: 12px;
                }

                &:last-child {
                    @apply whitespace-normal;
                    word-break: break-all;
                    font-size: 14px;
                }
            }
        }
    }

    .cug2b-register-summary-section-title {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 25px;

        i {
            font-size: 21px;
            margin-right: 10px;
        }
    }

    .cug2b-register-terms-container {
        @apply justify-start mt-4;
    }

    .cug2b-register-success-container {
    }

    .cug2b-register-success-banner {
    }

    .cug2b-register-success-main {
        padding: 0 28px;

        i {
            font-size: 95px;
            margin: 28px 0 14px;
        }

        h1 {
            font-size: 34px;
            line-height: 40px;
        }

        h2 {
            font-size: 25px;
            line-height: 32px;
        }

        p {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .cug2b-register-success-footnote {
        font-size: 13px;
        line-height: 16px;
        margin: 75px 0 10px;
    }
}

@media #{$xsmall} {
    .cug2b-register-content {
        @apply bg-bg-gray-4;
        background-image: none;
        padding: 80px 0 40px;
    }

    .cug2b-register-main {
    }

    .cug2b-register-bar {
        height: 45px;
    }

    .cug2b-register-bar-number {
        width: 24px;
        height: 24px;
        margin-right: 0;
        font-size: 16px;
        padding-top: 3px;
    }

    .cug2b-register-bar-section {
        @apply p-0;
    }

    .cug2b-register-tick {
        width: 6px;
        height: 14px;
        right: 20px;
        border-bottom-width: 3px;
        border-right-width: 3px;
    }

    .cug2b-breadcrumb-title {
        @apply font-emphasis text-cug-lightest-gray;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        margin: 20px 0 30px;
    }

    .cug2b-register-header {
        margin: 0 15px 15px;

        i {
            font-size: 31px;
            margin-right: 10px;
        }

        h1 {
            font-size: 15px;
        }

        h2 {
            font-size: 11px;
        }
    }

    .cug2b-register-back-btn {
        font-size: 14px;

        i {
            font-size: 23px;
            margin-right: 15px;
        }
    }

    .cug2b-register-checkbox-title {
        font-size: 14px;
    }

    .cug2b-register-form {
        margin: 0 15px;

        .mdl-textfield {
            margin-top: 5px;
        }

        .mdl-textfield__label {
            top: 0;
            font-size: 12px;
        }

        .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
        .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
        .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
            top: -2px;
            font-size: 10px;
        }

        .mdl-textfield--floating-label .mdl-textfield__label {
            top: 5px;
            padding-left: 6px;
            line-height: 32px;
        }

        .js-input {
            @apply leading-none font-body;
            font-size: 12px;
            height: 32px;
            padding-top: 14px;
            padding-left: 5px;

            &.js-select {
                @apply font-body;
                padding-top: 14px;
                padding-left: 5px;
                font-size: 12px;
                appearance: none;
            }
        }

        .js-select-arrow {
            &:after {
                top: 15px;
                right: 7px;
                font-size: 10px;
            }
        }

        dc-checkbox {
            height: 20px;

            input {
                &.dc-checkbox {
                    &[type="checkbox"] {
                        &:not(:disabled) {
                            + label {
                                &[for] {
                                    font-size: 14px;
                                    height: 20px;
                                    padding-left: 30px;

                                    &:before {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                            }
                            &[checked] {
                                + label {
                                    &[for] {
                                        &:after {
                                            width: 8px;
                                            height: 17px;
                                            top: 1px;
                                            left: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cug-info-icon {
            &.cug-register {
                right: 7px;
                top: 13px;
            }
        }
    }

    .cug2b-register-btn-container {
        @apply mt-4;
    }

    .cug2b-register-summary {
        @apply flex-col;
        margin: 0 15px 20px;
    }

    .cug2b-invoice-warning {
        padding: 15px 15px 15px 48px;
        margin-bottom: 15px;
        border-radius: 5px;
        font-size: 12px;

        i {
            left: 15px;
            font-size: 18px;
        }
    }

    .cug2b-register-summary-section {
        @apply w-full;
        padding: 15px 15px;
        border-radius: 5px;

        &:first-child {
            @apply mr-0 mb-4;
        }

        li {
            @apply block mt-2;
            line-height: 15px;

            span {
                @apply block w-full;

                &:first-child {
                    font-size: 12px;
                }

                &:last-child {
                    @apply not-italic text-be-blue;
                    font-size: 12px;
                }
            }
        }
    }

    .cug2b-register-summary-section-title {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 20px;

        i {
            font-size: 28px;
            margin-right: 10px;
        }
    }

    .cug2b-register-terms-container {
        @apply justify-start mt-4 whitespace-normal;
    }

    .cug2b-register-success-container {
        @apply block;
    }

    .cug2b-register-success-banner {
        @apply w-full;
        height: 155px;
    }

    .cug2b-register-success-main {
        @apply w-full;
        padding: 0 20px;

        i {
            font-size: 84px;
            margin: 25px 0 15px;
        }

        h1 {
            font-size: 30px;
            line-height: 36px;
        }

        h2 {
            font-size: 23px;
            line-height: 28px;
        }

        p {
            font-size: 19px;
            line-height: 23px;
        }
    }

    .cug2b-register-success-footnote {
        font-size: 10px;
        line-height: 12px;
        margin: 25px 0 10px;
    }
}
