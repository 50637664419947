.sa-dc-register-page {
    .mdl-textfield {
        padding: 20px 0 0;
    }

    .js-input {
        &.js-select {
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: #163a70;
            -webkit-appearance: none;
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 39px;
            right: 15px;
            font-size: 12px;
            color: #ccc;
        }
    }

    img {
        display: block;
        max-height: 67px;
        margin: 0 auto;
    }

    .dc-standalone-register-info {
        margin-top: 10px;
        text-align: center;
        font-family: "Lato", sans-serif;
        color: $brand-secondary;
        font-size: 14px;
    }

    .sa-dc-register-button-container {
        @apply flex justify-end;
        margin-bottom: 80px;

        .rounded-secondary-btn {
            @apply mr-2;
        }
    }
}

.dc-standalone-sidebar-info {
    width: 100%;
    height: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #00aac3;
    color: #fff;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: 15px;

    &.english {
        font-size: 13px;
    }

    &.portuguese {
        font-size: 14px;
    }

    span {
        display: block;
        text-transform: uppercase;
        color: #fff;
        font-family: "Lato", sans-serif;
        font-weight: 900;
        font-size: 24px;
        margin-top: 5px;
    }
}

.standalone-dc-itinerary-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 130px;
    margin: 25px 0;
    padding: 25px 25px 25px 375px;
    border-radius: $border-radius-lg;
    color: #fff;
    background: $brand-secondary;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.15);

    img {
        position: absolute;
        left: 35px;
        top: 50%;
        transform: translateY(-50%);
    }

    .standalone-dc-itinerary-banner {
        position: relative;
        background: #ffa400;
        color: #fff;
        border-radius: 5px;
        padding: 10px 25px 10px 55px;
        min-height: 50px;
        display: inline-flex;
        align-items: center;

        .standalone-dc-itinerary-banner-text {
            font-family: "Lato", sans-serif;
            font-style: italic;
            font-weight: 600;
            font-size: 23px;
            text-transform: uppercase;
            line-height: 1.1;

            span {
                font-weight: 900;
            }
        }

        i {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 37px;
        }
    }

    .standalone-dc-itinerary-list {
        padding-left: 56px;
        margin-top: 10px;

        li {
            position: relative;
            font-family: "Lato", sans-serif;
            font-size: 18px;
            line-height: 29px;
            color: #fff;

            &:after {
                content: "\2714";
                color: #83b245;
                font-size: 15px;
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.standalone-dc-itinerary-btn-container {
    @apply flex justify-end;
    margin: 30px 0 128px;
}

/* MEDIA QUIERIES */

@media #{$small} {
    .sa-dc-register-page {
        .booking-wrapper {
            width: auto;
            margin: 25px 50px;
        }

        .sa-dc-register-button-container {
            margin: 0 50px 50px;
        }

        .birthdate-container {
            .input-inner-title {
                top: 20px;
            }
        }
    }

    .standalone-dc-itinerary-wrapper {
        padding-left: 207px;

        img {
            height: 43px;
        }

        .standalone-dc-itinerary-banner {
            min-height: 27px;

            i {
                font-size: 20px;
            }

            .standalone-dc-itinerary-banner-text {
                font-size: 12px;
            }
        }

        .standalone-dc-itinerary-list {
            padding-left: 36px;

            li {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
}

@media #{$xsmall} {
    .sa-dc-register-page {
        .booking-wrapper {
            width: auto;
            margin: 25px;
        }

        .birthdate-container {
            .input-inner-title {
                top: 20px;
            }
        }

        .sa-dc-register-button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;

            .rounded-secondary-btn {
                @apply mr-0 mb-2;
            }
        }
    }

    .standalone-dc-itinerary-wrapper {
        margin: 0 15px;
        width: auto;
        padding: 15px;
        display: block;

        img {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            height: 50px;
            margin: 0 auto 25px;
            display: inherit;
        }

        .standalone-dc-itinerary-banner {
            min-height: 27px;

            i {
                font-size: 36px;
            }

            .standalone-dc-itinerary-banner-text {
                font-size: 15px;
            }
        }

        .standalone-dc-itinerary-list {
            padding-left: 30px;

            li {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    .booking-wrapper {
        &.transaction-wrapper {
            &.standalone-dc {
                margin: 15px;
                width: auto;
            }
        }
    }

    .standalone-dc-itinerary-btn-container {
        margin: 30px 15px;
    }
}
