.i2-details-accordion-header {
    @apply flex cursor-pointer items-center justify-between bg-white text-be-blue;
    height: 60px;
    padding: 0 30px 0 40px;
    margin: 0 -40px 0;

    i {
        @apply font-semibold text-be-blue;
        font-size: 33px;
        transform: translateY(-50%) rotate(90deg);
        transition: all 0.25s ease-in-out;
    }

    &.open {
        @apply cursor-default bg-be-light-gray;

        i {
            transform: translateY(-50%) rotate(-90deg);
        }

        + .i2-details-accordion-content {
            max-height: 580px;
            padding: 15px 30px 15px 40px;

            &.i2-redemption-details {
                padding-top: 0;
            }
        }
    }

    &.cursordefault {
        @apply cursor-default;
    }
}

.i2-details-accordion-content {
    @apply relative overflow-hidden border-b border-solid border-be-gray-11 bg-white;
    max-height: 0;
    transition: all 0.25s ease-in-out;
    margin: 0 -40px;
    padding: 0 30px 0 40px;

    &.last {
        @apply border-b-0;
    }
}

.i2-details-pax-header-number {
    @apply font-body font-semibold text-be-blue;
    font-size: 19px;
    margin-right: 25px;
}

.i2-details-pax-header-name {
    @apply relative font-emphasis text-be-blue;
    top: 2px;
    font-size: 19px;
}

.i2-details-pax-header-total {
    @apply relative flex items-center;
    font-size: 26px;
    width: 300px;

    span {
        @apply font-body font-semibold uppercase text-be-blue;

        &:first-child {
            margin-right: 20px;
        }
    }

    i {
        @apply absolute text-be-blue;
        top: 50%;
        right: 1rem;
        font-size: 33px;
    }
}

.i2-details-left-pane,
.i2-details-right-pane {
    &:not(.wide) {
        @apply w-1/2;
    }
}

.i2-details-right-pane {
    padding-left: 30px;
}

.dotted-right {
    @apply relative;

    &:after {
        @apply absolute right-0 top-0 h-full bg-left bg-repeat-y;
        content: "";
        width: 2px;
        background-image: linear-gradient(to bottom, #1c355e 10%, rgba(255, 255, 255, 0) 0%);
        background-size: 2px 8px;
    }
}

.i2-details-pane-title {
    @apply uppercase italic text-be-blue;
    font-family: "ClanOT-Black", sans-serif;
    font-size: 20px;
    margin-bottom: 20px;

    &.redemption-details-title {
        margin-bottom: 14px;
    }
}

.i2-optional-group {
    @apply font-body font-semibold text-be-blue;
    font-size: 19px;
    line-height: 28px;
}

.i2-fare-group {
    @apply flex items-start justify-between;
    padding-right: 80px;
    margin-top: 5px;

    &.miles-fare-group {
        padding-right: 0;
        margin-top: 20px;
    }

    > span {
        line-height: 28px;

        &:first-child {
            @apply font-body font-semibold text-be-blue;
            font-size: 21px;
        }

        &:last-child {
            @apply whitespace-nowrap font-body text-be-blue;
            font-size: 21px;
        }
    }
}

.i2-fare-item {
    @apply flex items-start justify-between;
    padding-right: 80px;

    &.miles-fare-item {
        padding-right: 0;
    }

    > span {
        @apply font-body text-be-blue;
        font-size: 20px;
        line-height: 28px;

        &:last-child {
            @apply whitespace-nowrap;
        }
    }
}

.i2-ssr-item {
    @apply flex items-start justify-between;
    column-gap: 1.75rem;

    &.redemption-details-ssr-item {
        padding-right: 0;
    }

    > span {
        @apply font-body text-be-blue;
        font-size: 20px;
        line-height: 28px;

        &:last-child {
            @apply whitespace-nowrap;
        }
    }

    &.wrap {
        > span {
            &:last-child {
                @apply whitespace-normal;
            }
        }
    }
}

.i2-fare-total {
    @apply flex items-start justify-between;
    padding-right: 80px;
    margin-top: 20px;

    &.miles-fare-total {
        padding-right: 0;
    }

    > span {
        @apply whitespace-nowrap font-body text-be-blue;
        font-size: 20px;
        line-height: 28px;
    }
}

.i2-ssr-total {
    @apply flex w-full items-start justify-between;
    margin-top: 20px;

    &.redemption-details-ssr-total {
        padding-right: 0;
    }

    > span {
        @apply whitespace-nowrap font-body text-be-blue;
        font-size: 20px;
        line-height: 28px;
    }
}

.i2-fare-journey {
    @apply flex-shrink-0 font-body font-black uppercase text-be-blue;
    font-size: 19px;
    width: 120px;
    line-height: 28px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-details-pane-title {
        font-size: 18px;
        margin-bottom: 18px;
    }

    .i2-fare-group {
        padding-right: 80px;
        margin-top: 5px;

        > span {
            line-height: 24px;

            &:first-child {
                font-size: 18px;
            }

            &:last-child {
                font-size: 18px;
            }
        }
    }

    .i2-optional-group {
        font-size: 18px;
        line-height: 24px;
    }

    .i2-fare-item {
        padding-right: 80px;

        > span {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .i2-ssr-item {
        > span {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .i2-fare-total {
        padding-right: 80px;
        margin-top: 16px;

        > span {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .i2-ssr-total {
        margin-top: 16px;

        > span {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .i2-fare-journey {
        font-size: 18px;
        width: 120px;
        line-height: 24px;
    }
}

@media #{$medium} {
    .i2-details-accordion-header {
        height: 45px;
        padding: 0 15px 0 20px;
        margin: 0 -40px 0;

        i {
            font-size: 26px;
        }

        &.open {
            + .i2-details-accordion-content {
                max-height: 600px;
                padding: 15px 15px 15px 20px;
            }
        }
    }

    .i2-details-accordion-content {
        margin: 0 -40px;
        padding: 0 15px 0 20px;
    }

    .i2-details-pax-header-number {
        font-size: 15px;
        margin-right: 18px;
    }

    .i2-details-pax-header-name {
        top: 2px;
        font-size: 15px;
    }

    .i2-details-pax-header-total {
        font-size: 21px;
        width: 240px;

        span {
            &:first-child {
                margin-right: 10px;
            }
        }

        i {
            font-size: 26px;
            margin-left: 16px;
        }
    }

    .i2-details-right-pane {
        padding-left: 20px;
    }

    .i2-details-pane-title {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .i2-fare-group {
        padding-right: 60px;
        margin-top: 5px;

        > span {
            line-height: 24px;

            &:first-child {
                font-size: 17px;
            }

            &:last-child {
                font-size: 17px;
            }
        }
    }

    .i2-optional-group {
        font-size: 17px;
        line-height: 24px;
    }

    .i2-fare-item {
        padding-right: 60px;

        > span {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .i2-ssr-item {
        > span {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .i2-fare-total {
        padding-right: 60px;
        margin-top: 16px;

        > span {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .i2-ssr-total {
        margin-top: 16px;

        > span {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .i2-fare-journey {
        font-size: 14px;
        width: 90px;
        line-height: 24px;
    }
}

@media #{$small} {
    .i2-details-accordion-header {
        height: 36px;
        padding: 0 20px 0 25px;
        margin: 0 -40px 0;

        i {
            font-size: 20px;
        }

        &.open {
            + .i2-details-accordion-content {
                max-height: 600px;
                padding: 15px 20px 15px 25px;
            }
        }
    }

    .i2-details-accordion-content {
        margin: 0 -40px;
        padding: 0 20px 0 25px;
    }

    .i2-details-pax-header-number {
        font-size: 11px;
        margin-right: 12px;
    }

    .i2-details-pax-header-name {
        top: 1px;
        font-size: 11px;
    }

    .i2-details-pax-header-total {
        font-size: 16px;
        width: 200px;

        span {
            &:first-child {
                margin-right: 10px;
            }
        }

        i {
            font-size: 20px;
            margin-left: 16px;
        }
    }

    .i2-details-right-pane {
        padding-left: 25px;
    }

    .i2-details-pane-title {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .i2-fare-group {
        @apply mt-0;
        padding-right: 40px;

        &.miles-fare-group {
            margin-top: 0px;
        }

        > span {
            line-height: 25px;

            &:first-child {
                font-size: 13px;
            }

            &:last-child {
                font-size: 12px;
            }
        }
    }

    .i2-optional-group {
        font-size: 13px;
        line-height: 14px;
    }

    .i2-fare-item {
        padding-right: 40px;

        > span {
            font-size: 12px;
            line-height: 14px;
        }
    }

    .i2-ssr-item {
        > span {
            font-size: 12px;
            line-height: 14px;
        }
    }

    .i2-fare-total {
        padding-right: 40px;
        margin-top: 8px;

        > span {
            font-size: 12px;
            line-height: 17px;
        }
    }

    .i2-ssr-total {
        margin-top: 8px;

        > span {
            font-size: 12px;
            line-height: 17px;
        }
    }

    .i2-fare-journey {
        font-size: 10px;
        width: 60px;
        line-height: 15px;
    }
}

@media #{$xsmall} {
    .i2-details-accordion-header {
        @apply m-0;
        height: unset;
        padding: 0 10px 0 15px;

        i {
            font-size: 25px;
        }

        &.open {
            + .i2-details-accordion-content {
                padding: 0 10px 0 15px;
                max-height: 480px;

                &.i2-redemption-details {
                    max-height: 800px;
                }
            }
        }
    }

    .i2-details-accordion-content {
        margin: 0 0;
        padding: 0 10px 0 15px;
    }

    .i2-details-pax-header-number {
        font-size: 11px;
        margin-right: 0;
    }

    .i2-details-pax-header-name {
        top: 0;
        font-size: 11px;
    }

    .i2-details-pax-header-total {
        @apply flex items-center;
        width: 120px;
        min-width: 90px;

        span {
            &:first-child {
                font-size: 11px;
            }

            &:last-child {
                font-size: 16px;
            }
        }

        i {
            font-size: 25px;
            right: 0;
        }
    }

    .i2-details-left-pane,
    .i2-details-right-pane {
        @apply w-full;
        padding: 15px 10px;

        &:not(.wide) {
            @apply w-full;
        }
    }

    .dotted-right {
        &:after {
            display: none;
        }
    }

    .i2-details-pane-title {
        @apply flex items-center justify-center;
        width: calc(50% + 20px);
        height: 30px;
        font-size: 11px;
        margin-bottom: 12px;
        color: rgba(#9d9d9c, 0.73);
        background: rgba(#9d9d9c, 0.4);

        &.active {
            @apply bg-be-blue text-white;
        }

        &:first-child {
            margin-left: -20px;
        }

        &:last-child {
            margin-right: -20px;
        }

        &.redemption-details-title {
            @apply justify-start text-brand-secondary mt-2.5 mb-[5px] text-[11px];

            width: unset;
            height: unset;
            background: unset;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .i2-fare-group {
        padding-right: 20px;
        margin-top: 0;

        &.miles-fare-group {
            margin-top: 5px;
        }

        > span {
            line-height: 17px;

            &:first-child {
                font-size: 13px;
            }

            &:last-child {
                font-size: 12px;
            }
        }
    }

    .i2-optional-group {
        font-size: 13px;
        line-height: 17px;
    }

    .i2-fare-item {
        padding-right: 20px;

        > span {
            font-size: 12px;
            line-height: 17px;
        }
    }

    .i2-ssr-item {
        padding-right: 20px;

        > span {
            font-size: 12px;
            line-height: 17px;
        }
    }

    .i2-fare-total {
        padding-right: 20px;
        margin-top: 15px;

        &.miles-fare-total {
            margin-top: 5px;
        }

        > span {
            font-size: 12px;
            line-height: 17px;
        }
    }

    .i2-ssr-total {
        padding-right: 20px;
        margin-top: 15px;

        &.redemption-details-ssr-total {
            margin-top: 5px;
            margin-bottom: 10px;
        }

        > span {
            font-size: 12px;
            line-height: 17px;
        }
    }

    .i2-fare-journey {
        font-size: 13px;
        width: 80px;
        line-height: 17px;
    }
}
