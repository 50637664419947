/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "jetsmart-new";
    src: url("../fonts/jetsmart-new.eot?oqzlj8");
    src:
        url("../fonts/jetsmart-new.eot?oqzlj8#iefix") format("embedded-opentype"),
        url("../fonts/jetsmart-new.ttf?oqzlj8") format("truetype"),
        url("../fonts/jetsmart-new.woff?oqzlj8") format("woff"),
        url("../fonts/jetsmart-new.svg?oqzlj8#jetsmart-new") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.js-new {
    font-family: "jetsmart-new" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-n-email-with-tick:before {
    content: "\e923";
}
.js-n-money-in-hand:before {
    content: "\e922";
}
.js-n-no-balance:before {
    content: "\e920";
}
.js-n-broken-circle-plane:before {
    content: "\e921";
}
.js-n-broken-circle-writing:before {
    content: "\e91f";
}
.js-n-pen-edit:before {
    content: "\e91e";
}
.js-n-triangle-exclamation-thick:before {
    content: "\e91d";
}
.js-n-step-tick-empty:before {
    content: "\e91c";
}
.js-n-step-seat:before {
    content: "\e90f";
}
.js-n-step-seat-filled:before {
    content: "\e910";
}
.js-n-step-star:before {
    content: "\e911";
}
.js-n-step-star-filled:before {
    content: "\e912";
}
.js-n-step-tick:before {
    content: "\e913";
}
.js-n-step-user:before {
    content: "\e914";
}
.js-n-step-user-filled:before {
    content: "\e915";
}
.js-n-step-baggage:before {
    content: "\e916";
}
.js-n-step-baggage-filled:before {
    content: "\e917";
}
.js-n-step-money:before {
    content: "\e918";
}
.js-n-step-money-filled:before {
    content: "\e919";
}
.js-n-step-plane:before {
    content: "\e91a";
}
.js-n-step-plane-filled:before {
    content: "\e91b";
}
.js-n-american-airlines:before {
    content: "\e90e";
}
.js-n-triangle-exclamation-thin:before {
    content: "\e90d";
}
.js-n-pictogram-compressed-gas:before {
    content: "\e903";
}
.js-n-pictogram-corrosive:before {
    content: "\e904";
}
.js-n-pictogram-danger:before {
    content: "\e905";
}
.js-n-pictogram-environmental-hazard:before {
    content: "\e906";
}
.js-n-pictogram-explosive:before {
    content: "\e907";
}
.js-n-pictogram-flamable:before {
    content: "\e908";
}
.js-n-pictogram-health-hazard:before {
    content: "\e909";
}
.js-n-pictogram-oxidising:before {
    content: "\e90a";
}
.js-n-pictogram-outline:before {
    content: "\e90b";
    color: #f00;
}
.js-n-pictogram-toxic:before {
    content: "\e90c";
}
.js-n-dc-standard:before {
    content: "\e901";
}
.js-n-dc-group:before {
    content: "\e902";
}
.js-n-sitting-pax:before {
    content: "\e900";
}
