.js-footer {
    @apply relative w-full text-left;

    background: $footer-bg;
    margin-top: $common-gap;
    font-size: 1rem;
    // z-index: -1; // FIX ME have to check very well!!!

    .footer-container {
        @apply relative;

        margin: 20px 45px 20px 345px;
        max-width: 1155px;
    }

    .footer-end {
        @apply relative w-full;

        padding: 20px 45px 20px 345px;
        border-top: 8px solid $footer-main-color;

        .footer-end-content {
            max-width: 1155px;
        }
    }

    &.full-width {
        .footer-container {
            margin: 20px auto;
        }

        .footer-end {
            padding: 20px 0;

            .footer-end-content {
                margin: 0 auto;
                max-width: 1155px;
            }
        }
    }
}

.payment-images {
    @apply flex flex-wrap items-center justify-start;

    .footer-payment-icons {
        margin: 10px;
        max-height: 40px;
        max-width: 100%;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.footer-logo {
    @apply text-right;

    img {
        max-height: 2.125rem;
        max-width: 10.875rem;
    }
}

.footer-copyright {
    @apply flex items-center;

    height: 2.125rem;
    color: $footer-secondary-color;
}

.footer-title {
    color: $footer-secondary-color;
    font-size: 18px;
    margin: 45px 0 10px;
    display: block;

    &.bold {
        font-weight: 900;
    }

    &.footer-section-opener {
        position: relative;
        user-select: none;
        line-height: 1.2;
        height: unset;
        font-size: 18px;
        padding-left: 0;
        display: block;
        cursor: default;

        &:before {
            display: none;
        }

        .opened {
            display: none;
        }

        .closed {
            display: none;
        }
    }
}

label {
    &.footer-title {
        color: #1d4071;
        font-size: 18px;
        margin: 45px 0 10px;
        display: block;

        &.bold {
            font-weight: 900;
        }

        &.footer-section-opener {
            position: relative;
            user-select: none;
            line-height: 1.2;
            height: unset;
            font-size: 18px;
            padding-left: 0;
            display: block;
            cursor: default;

            &:before {
                display: none;
            }

            .opened {
                display: none;
            }

            .closed {
                display: none;
            }
        }
    }
}

.footer-links {
    overflow: hidden;
    transition: max-height $transition-medium-up ease-in-out;

    a {
        display: inline;
        color: $footer-link-color;

        span {
            display: inline-block;

            &:after {
                display: block;
                content: "";
                border-bottom: solid 1px $footer-link-border-color;
                transform: scaleX(0);
                transition: transform $transition-medium-up ease-in-out;
                transform-origin: 0% 50%;
                margin-top: -2px;
            }
        }

        &:hover {
            color: $footer-link-color;

            span {
                &:after {
                    transform: scaleX(1);
                }
            }
        }
    }
}

.social-icons {
    @apply flex items-center justify-between;
    margin: 5px 0 0 0;

    a {
        @apply inline-block cursor-pointer;
        color: $footer-secondary-color;
        font-size: 40px;

        &:hover {
            color: $footer-main-color;
        }

        i {
            &:hover {
                color: $footer-main-color;
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .js-footer {
        .footer-container {
            margin: 45px 25px 45px 345px;
        }
    }
}

@media #{$medium} {
    .js-footer {
        .footer-container {
            margin: 45px 25px 45px 345px;
        }

        &.full-width {
            .footer-container {
                margin: 20px 25px;
            }

            .footer-end {
                .footer-end-content {
                    margin: 0 25px;
                }
            }
        }
    }
}

@media #{$small-down} {
    .js-footer {
        .footer-container {
            margin: 45px 25px 45px 25px;
        }

        .footer-end {
            margin-bottom: 55px;
        }

        &.full-width {
            .footer-container {
                margin: 20px 25px;
            }

            .footer-end {
                .footer-end-content {
                    margin: 0 25px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .js-footer {
        .footer-container {
            display: none;
        }

        .footer-title {
            text-align: center;
        }

        label {
            &.footer-title {
                margin: 3px 0;

                &.footer-section-opener {
                    text-align: left;
                    cursor: pointer;

                    i {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                    }
                }
            }
        }

        .section-opener[type="checkbox"]:checked {
            ~ label {
                .opened {
                    display: block;
                }

                .closed {
                    display: none;
                }
            }

            ~ .footer-links {
                max-height: 150px;
            }
        }

        .section-opener[type="checkbox"]:not(:checked) {
            ~ label {
                .opened {
                    display: none;
                }

                .closed {
                    display: block;
                }
            }

            ~ .footer-links {
                max-height: 0;
            }
        }

        .social-icons {
            margin-bottom: 20px;
        }

        .footer-end {
            padding: 20px;

            .footer-copyright {
                justify-content: center;
                margin-bottom: 20px;
            }

            .footer-logo {
                text-align: center;
            }
        }
    }
}
