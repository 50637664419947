.cug2b-summary-warning {
    @apply absolute w-full left-0;
    bottom: 0.5rem;
    font-size: 14px;
    text-align: center;
}

.cug-widget-emphasised-text {
    @apply font-black;

    font-size: 32px;
}

.cug2b-summary-data-bar {
    @apply w-full flex items-center justify-between;
    margin-bottom: 20px;
}

.cug2b-summary-data {
    @apply bg-cug-dark-gray text-white relative cursor-pointer;
    border-radius: 6px;
    height: 175px;
    width: calc(33.33% - 20px);

    &.peru-compra {
        width: calc(50% - 10px);
    }

    h2 {
        @apply absolute horizontal-center font-emphasis;
        top: 20px;
        font-size: 19px;
        line-height: 22px;
        text-align: center;
        max-width: 150px;
    }

    .ac-tooltip {
        @apply absolute bg-white text-cug-dark-gray;
        top: 10px;
        right: 10px;
    }
}

.cug2b-summary-data-number {
    @apply absolute horizontal-center font-emphasis leading-none;
    bottom: 20px;
    font-size: 61px;
}

.cug2b-summary-company-info-bar {
    @apply border border-solid border-cug-dark-gray bg-cug-gray-5 flex items-center;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 154px;
}

.cug2b-summary-greeting-container {
    @apply relative h-full flex items-center;
    width: 60%;
    padding-left: 28px;

    i {
        font-size: 92px;
        margin-right: 30px;
    }
}

.cug2b-summary-greeting {
    padding-right: 10px;

    h1 {
        @apply font-emphasis text-cug-dark-gray leading-none;
        font-size: 36px;
        margin-bottom: 10px;
    }

    h2 {
        @apply font-body font-bold text-cug-dark-gray leading-none;
        font-size: 23px;
        margin-bottom: 10px;
    }

    h3 {
        @apply font-body font-semibold text-cug-red leading-none;
        font-size: 19px;
    }
}

.cug2b-summary-balance-container {
    @apply relative bg-cug-dark-gray text-white h-full flex flex-col items-center justify-center;
    width: 20%;
}

.cug2b-summary-balance {
    @apply flex flex-col items-center;

    button {
        margin-top: 20px;

        &.initial-amount-ribbon {
            margin-top: 5px;
            margin-bottom: 7px;
        }
    }
}

.cug2b-summary-balance-label {
    @apply font-body;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
}

.cug2b-summary-balance-amount {
    @apply font-emphasis;
    font-size: 20px;
    line-height: 24px;
}

.cug2b-summary-edit {
    @apply relative h-full;
    width: 20%;

    > span {
        @apply cursor-pointer absolute font-body font-bold flex items-center;
        font-size: 13px;
        top: 20px;
        right: 25px;

        &:hover {
            @apply text-cug-red;

            i {
                @apply text-cug-red;
            }
        }
    }

    i {
        @apply text-cug-dark-gray mr-2;
        font-size: 24px;
    }
}

.cug2b-topup-modal {
    width: 495px;
    height: 360px;
}

.cug2b-topup-form {
    padding: 30px 44px;
}

.cug2b-topup-balance {
    @apply text-be-blue;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 15px;

    span {
        @apply text-be-blue font-black;
    }
}

.cug2b-initial-amount-ribbon {
    @apply flex w-full justify-center items-center bg-new-ui-turqoise text-white;
    font-size: 14px;
    line-height: 16px;
    font-family: Lato;
    font-weight: 800;
    padding: 4px 6px;
    gap: 3px;
    top: 86px;
}

.cug2b-topup-input {
    @apply relative;

    label {
        @apply absolute font-body font-bold pointer-events-none;
        top: 0px;
        left: 17px;
        font-size: 14px;
    }

    input {
        @apply font-body w-full;
        border-radius: 5px;
        font-size: 20px;
        padding-top: 20px;

        &::placeholder {
            @apply font-body italic;
            font-size: 20px;
        }
    }
}

.cug2b-redirection-warning {
    @apply font-black text-be-blue;
    font-size: 18px;
    margin-top: 30px;
    text-align: center;
}

.cug2b-edit-travel-partner {
    width: 1000px;
    height: 430px;
    max-height: 90%;
}

.cug2b-edit-user {
    width: 1000px;
    height: 480px;
    max-height: 90%;
}

.peru-compra-edit-user-modal {
    width: 1000px;
    height: 410px;
    max-height: 90%;
}

// MEDIA QUERIES

@media #{$large} {
    .cug2b-summary-edit {
        width: 15%;
    }

    .cug2b-summary-balance-container {
        width: 25%;
    }
}

@media #{$medium} {
    .cug2b-summary-greeting {
        padding-right: 10px;
    }

    .cug2b-summary-edit {
        width: 15%;
    }

    .cug2b-summary-balance-container {
        width: 25%;
    }
}

@media #{$small} {
    .cug2b-summary-warning {
        @apply bottom-0;
        font-size: 12px;
    }

    .cug-widget-emphasised-text {
        font-size: 22px;
    }

    .cug2b-summary-data-bar {
        margin-bottom: 15px;
    }

    .cug2b-summary-data {
        border-radius: 4px;
        height: 117px;
        width: calc(33.33% - 10px);

        h2 {
            top: 20px;
            font-size: 14px;
            line-height: 17px;
            max-width: 150px;
        }

        .material-icons {
            top: 10px;
            right: 10px;
        }
    }

    .cug2b-summary-data-number {
        bottom: 10px;
        font-size: 39px;
    }

    .cug2b-summary-company-info-bar {
        border-radius: 6px;
        height: 103px;
        margin-bottom: 10px;
    }

    .cug2b-summary-greeting-container {
        width: 60%;
        padding-left: 20px;

        i {
            font-size: 56px;
            margin-right: 20px;
        }
    }

    .cug2b-summary-greeting {
        h1 {
            font-size: 22px;
            margin-bottom: 4px;
        }

        h2 {
            font-size: 15px;
            margin-bottom: 4px;
        }

        h3 {
            font-size: 12px;
        }
    }

    .cug2b-summary-balance-container {
        width: 25%;
    }

    .cug2b-summary-balance {
        button {
            margin-top: 10px;
        }
    }

    .cug2b-summary-balance-label {
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 5px;
    }

    .cug2b-summary-balance-amount {
        font-size: 15px;
        line-height: 18px;
    }

    .cug2b-summary-edit {
        width: 15%;

        > span {
            font-size: 10px;
            top: 10px;
            right: 8px;
        }

        i {
            font-size: 15px;
        }
    }

    .cug2b-edit-travel-partner {
        height: 400px;
    }

    .cug2b-header-edit-modal {
        font-size: 20px;
    }
}

@media #{$xsmall} {
    .cug2b-summary-warning {
        @apply text-white inline-block leading-none w-auto bottom-auto relative;
        font-size: 10px;
        padding: 0 10px;
        max-width: 33%;
    }

    .cug-widget-emphasised-text {
        font-size: 22px;
    }

    .cug2b-summary-data-bar {
        @apply flex-col;

        margin-bottom: 15px;
    }

    .cug2b-summary-data {
        @apply w-full flex items-center;
        border-radius: 6px;
        height: 37px;
        margin-top: 15px;
        padding-left: 15px;

        &.peru-compra {
            min-width: 290px;
        }

        h2 {
            @apply relative top-0 left-0 transform-none leading-none whitespace-nowrap;
            font-size: 12px;
            max-width: unset;
        }

        .material-icons {
            @apply relative top-0 right-0 transform-none leading-none ml-2;
            font-size: 11px;
        }
    }

    .cug2b-summary-data-number {
        @apply absolute vertical-center leading-none;
        margin-top: 2px;
        left: auto;
        bottom: auto;
        right: 35px;
        font-size: 30px;
    }

    .cug2b-summary-company-info-bar {
        @apply bg-white m-0 flex-col;
        border-radius: 10px;
        height: 120px;
    }

    .cug2b-summary-greeting-container {
        @apply w-full;
        padding-left: 15px;

        i {
            font-size: 32px;
            margin-right: 10px;
        }
    }

    .cug2b-summary-greeting {
        @apply relative w-full;

        h2 {
            font-size: 16px;
            margin-bottom: 4px;
        }

        h3 {
            font-size: 13px;
        }

        > span {
            @apply absolute top-0 flex items-center;
            font-size: 10px;
            right: 5px;
        }

        i {
            font-size: 14px;
        }
    }

    .cug2b-summary-balance-container {
        @apply w-full justify-start flex-row;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &.initial-amount-ribbon {
            @apply items-start flex-col;
        }
    }

    .cug2b-initial-amount-ribbon {
        border-radius: 0 0 10px 10px;
    }

    .cug2b-summary-balance {
        @apply items-start;
        padding-left: 13px;

        button {
            @apply m-0 absolute vertical-center;
            right: 15px;

            &.initial-amount-ribbon {
                margin-top: -12px;
            }
        }
    }

    .cug2b-summary-balance-label {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 6px;
    }

    .cug2b-summary-balance-amount {
        font-size: 14px;
        line-height: 17px;
    }

    .cug2b-topup-modal {
        width: 90%;
        height: 270px;
    }

    .cug2b-topup-form {
        padding: 15px;
    }

    .cug2b-topup-balance {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 10px;
    }

    .cug2b-topup-input {
        label {
            top: 4px;
            left: 17px;
            font-size: 10px;
        }

        input {
            border-radius: 5px;
            font-size: 14px;
            padding-top: 18px;

            &::placeholder {
                font-size: 14px;
            }
        }
    }

    .cug2b-redirection-warning {
        font-size: 13px;
        margin-top: 20px;
    }

    .cug2b-edit-travel-partner {
        min-height: 65%;
    }

    .cug2b-edit-user {
        min-height: 75%;
    }

    .peru-compra-edit-user-modal {
        height: 580px;
    }

    .cug2b-header-edit-user-modal {
        font-size: 18px;
    }
    .cug2b-header-edit-travel-partner-modal {
        font-size: 16px;
    }
}
