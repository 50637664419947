.print-only {
    display: none !important;
}

.transaction-container {
    margin: 0 60px 0;

    &:first-child {
        margin-top: 20px;
    }
}

.printing-warning {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    color: #333;
    font-size: $font-size-medium;
    font-weight: 600;
    padding: 20px;
    border-radius: $border-radius-lg;
    z-index: 100000;
}

.itinerary-pnr {
    margin: 20px 0;
    color: $brand-secondary;
    font-weight: 600;
    font-size: 18px;

    span {
        font-weight: 600;
        font-size: $font-size-large;
        color: $brand-primary;
    }
}

.all-flights-past {
    padding: $common-gap;
    background: $cvv-bg;
    border-radius: $border-radius-sm;

    a {
        color: $brand-secondary;
    }

    &.error {
        @apply mb-3;
        background: $brand-primary;
        color: $gray-lightest;
    }
}

.hold-booking-warning {
    padding: $common-gap;
    background: $brand-primary;
    color: $gray-lightest;
    border-radius: $border-radius-sm;
    margin-bottom: 40px;
    line-height: 1.45;
}

.successful-change-route-message {
    display: block;
    margin: 0 0 20px 0;
    font-size: 24px;
    color: $brand-primary;
    font-weight: 600;
    text-align: center;
    position: relative;
    top: -20px;
}

.car-widget {
    width: 100%;
    height: 450px;
    margin: 0;
    padding: 0;
}

#mainContentItinerary {
    .page-heading {
        .main-title {
            font-size: 32px;
            font-weight: 600;
            color: $brand-secondary;
            text-align: center;
        }

        .subtitle {
            margin-bottom: 50px;
            font-size: $font-size-xsmall;
            font-weight: 400;
            color: $brand-secondary;
            line-height: 1;
            text-align: center;
        }
    }

    hr {
        margin: 15px 0;
        border-bottom: 1px solid $gray-light;
    }

    .payment-content {
        display: flex;
        align-items: center;
        color: $brand-secondary;
        font-weight: 600;

        a {
            color: $brand-tertiary;

            &:hover {
                color: $brand-primary;
            }
        }
    }

    .payment-btn-container {
        margin: 10px $common-gap;
    }

    .tabs {
        margin-top: 25px;
        user-select: none;

        nav {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;

                    label {
                        display: inline-block;
                        position: relative;
                        width: 250px;
                        height: 80px;
                        line-height: 80px;
                        text-align: center;
                        margin: 0 5px 0 0;
                        background: $gray-lightest;
                        border-top: 1px solid transparent;
                        border-left: 1px solid transparent;
                        border-right: 1px solid transparent;
                        border-top-left-radius: $border-radius-lg;
                        border-top-right-radius: $border-radius-lg;
                        z-index: 1;

                        &:hover {
                            color: $gray-lightest;
                            background: $secondary-btn-color;
                        }

                        span {
                            color: $brand-secondary;
                            font-weight: 600;
                            font-size: 18px;
                        }
                    }
                }
            }

            > input {
                display: none;
            }
        }

        .tab-content {
            background: #fff;
            padding: 25px;
            border: 1px solid $gray-light;
            border-bottom-left-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
        }

        #tab1:checked ~ nav .tab1 label,
        #tab2:checked ~ nav .tab2 label {
            height: 81px;
            bottom: -1px;
            background: #fff;
            border-top: 1px solid $gray-light;
            border-left: 1px solid $gray-light;
            border-right: 1px solid $gray-light;
            color: $brand-secondary;
            cursor: default;
        }
    }

    [type="radio"] {
        + label:after,
        + label:before {
            margin: 0;
            padding: 0;
            display: none;
        }
    }
}

.disabled-button-container {
    @apply relative mx-auto block max-w-full select-none;
    width: 160px;
    padding-right: 25px;
}

.departured-nopax-button-container {
    @apply relative mx-auto block max-w-full select-none;
    width: 226px;
    padding-right: 25px;
}

.flight-container {
    &.padded {
        margin-top: 20px;
    }
}

.flight-container-title {
    margin: 0 0 $common-gap;
    color: $brand-secondary;
    font-weight: 800;
    font-size: 18px;
}

.flight-chart {
    display: table;
    width: 100%;
    border-radius: $border-radius-lg;
    color: $brand-secondary;

    .flight-chart-head {
        display: table-row;
        width: 100%;

        .flight-chart-header {
            display: table-cell;
            padding: 8px 20px;
            border: 1px solid $brand-secondary;
            border-bottom: none;
            border-right: none;
            background: $gray-lighter;
            text-align: center;
            font-weight: 600;
            font-size: 18px;
            width: 20%;

            &:first-child {
                border-top-left-radius: $border-radius-lg;
            }

            &:last-child {
                border-right: 1px solid $brand-secondary;
                border-top-right-radius: $border-radius-lg;
            }
        }
    }

    .flight-chart-body {
        display: table-row;
        width: 100%;

        &:last-child {
            .flight-chart-cell {
                &:first-child {
                    border-bottom-left-radius: $border-radius-lg;
                }

                &:last-child {
                    border-bottom-right-radius: $border-radius-lg;
                }
            }
        }

        .flight-chart-cell {
            display: table-cell;
            padding: 10px;
            border: 1px solid $brand-secondary;
            border-top: none;
            border-right: none;
            background: #fff;
            text-align: center;

            &:last-child {
                border-right: 1px solid $brand-secondary;
            }
        }
    }
}

.flight-mobile-chart {
    display: table;
    width: 100%;
    border: 1px solid $gray-light;
    border-radius: $border-radius-lg;
    color: $brand-secondary;

    .flight-chart-row {
        display: table-row;
        width: 100%;

        &:first-child {
            .flight-chart-cell {
                &:first-child {
                    border-top-left-radius: $border-radius-lg;
                }

                &:last-child {
                    border-top-right-radius: $border-radius-lg;
                }
            }
        }

        &:last-child {
            .flight-chart-cell {
                &:first-child {
                    border-bottom-left-radius: $border-radius-lg;
                }

                &:last-child {
                    border-bottom-right-radius: $border-radius-lg;
                }
            }
        }

        &:nth-child(odd) {
            .flight-chart-cell {
                background: $gray-lighter;
            }
        }

        .flight-chart-cell {
            display: table-cell;
            width: 50%;
            padding: 10px 20px;
            text-align: center;

            &:first-child {
                font-weight: 900;
            }
        }
    }
}

.itinerary-passenger-container {
    width: 100%;
    border-radius: $border-radius-lg;
    color: $brand-secondary;
    margin-bottom: 20px;

    .passenger-header {
        padding: 8px 20px;
        background: $gray-lighter;
        border: 1px solid $brand-secondary;
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
        border-bottom: none;
        font-size: 18px;
        font-weight: 900;
        text-align: center;
    }

    .passenger-body {
        padding: 10px;
        background: #fff;
        border: 1px solid $brand-secondary;
        border-bottom-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        border-top: none;
        text-align: center;
        // span {
        //     display: block;
        //     line-height: 22px;
        //     &.first-name {
        //         font-size: 18px;
        //     }
        // }
    }
}

.itinerary-navigation {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.itinerary-extras-passenger-label {
    margin: $common-gap 0;
    padding: $common-gap;
    background: $gray-lightest;
    color: $brand-secondary;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}

.passenger-circle {
    margin: $common-gap 0;
    padding: $common-gap;
}

.payment-pending-loader {
    position: relative;
    height: 90px;

    &.transaction {
        margin: 100px 0;
    }
}

.rejected-link {
    cursor: pointer;

    &:hover {
        color: $brand-secondary;
    }
}

.itinerary-extras-slogan {
    margin: 20px 0;
    font-size: 20px;
    font-weight: 900;
    color: $brand-primary;
    text-align: center;
}

.insurance-table {
    td {
        font-weight: 400;
        font-size: $font-size-medium;
        color: $brand-secondary;
        line-height: 1.5;
    }
}

.checkin-modal-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.modal {
    &.primary-modal {
        .modal-content {
            &.booking-modal-content {
                .modal-body {
                    padding: 30px;

                    .description-container {
                        .modal-description-title {
                            @apply m-auto mb-5 leading-7;
                            text-align: center;
                            font-size: 22px;

                            span {
                                @apply font-semibold;
                            }
                        }
                    }

                    .primary-modal-button-container {
                        @apply mb-1 mt-6 flex justify-center;
                    }
                }
            }
        }
    }

    &.itinerary-transaction-details-modal {
        .itinerary-transaction-details-modal-header {
            @apply flex items-center justify-center gap-2 text-2xl font-bold text-white;
            background-color: #00abc8;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 10px;

            .title {
                .main-title {
                    @apply px-5 text-center font-bold leading-none;
                }
            }

            i {
                @apply mr-2 text-white;
                font-size: 38px;
            }
        }

        .transaction-details-closing {
            top: 22px;
        }

        .modal-content {
            @apply p-0;

            font-size: 20px;

            .ts-download-title {
                @apply font-bold text-brand-secondary;
            }

            &.booking-modal-content {
                width: 650px;
            }

            .modal-body {
                .title-icon {
                    @apply mr-2;
                    font-size: 38px;
                }
            }

            .transaction-button-container {
                @apply p-0;
            }
        }
    }
    ac-transaction-logger {
        .transaction-container {
            @apply mx-0;
        }
    }
}

.adck-warning {
    line-height: 1.6;
    font-weight: 600;
    color: $brand-secondary;
}

.itinerary-extras-scroller {
    position: absolute;
    top: 90px;
    bottom: 25px;
    left: 25px;
    right: 25px;
    overflow: hidden;
}

.itinerary-extras-tabs {
    position: relative;

    &.pax-based-height-1,
    &.pax-based-height-2,
    &.pax-based-height-3,
    &.pax-based-height-4,
    &.pax-based-height-5,
    &.pax-based-height-6,
    &.pax-based-height-7,
    &.pax-based-height-8 {
        .itinerary-extras-scroller {
            position: relative;
            top: 0;
            left: 0;
            bottom: auto;
            right: auto;
        }
    }
}

.itinerary-payment-image {
    width: 48px;
    height: 30px;
    border-radius: 3px;
    background: #dedede;
}

/* MEDIA QUERIES */
@media #{$large} {
    .departured-nopax-button-container {
        width: 186px;

        .i2-btn {
            &.i2-checkin-btn {
                font-size: 15px;
            }
        }
    }
}

@media #{$medium} {
    .disabled-button-container {
        width: 125px;
        padding-right: 20px;
    }

    .departured-nopax-button-container {
        @apply ml-1;
        width: 140px;
        padding-top: 20px;

        .i2-btn {
            &.i2-checkin-btn {
                font-size: 13px;
            }
        }
    }

    #mainContentItinerary {
        .tabs {
            nav {
                ul {
                    li {
                        label {
                            width: 250px;
                        }
                    }
                }
            }
        }
    }
}

@media #{$small} {
    .disabled-button-container {
        width: 100px;
        padding-right: 15px;
    }

    .departured-nopax-button-container {
        width: 140px;
        padding-right: 15px;
    }

    #mainContentItinerary {
        .tabs {
            nav {
                ul {
                    li {
                        label {
                            width: 250px;
                        }
                    }
                }
            }
        }
    }

    .modal {
        &.itinerary-transaction-details-modal {
            .transaction-button-container {
                @apply flex flex-col gap-2;
            }

            .modal-content {
                &.booking-modal-content {
                    width: 90%;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .itinerary-passenger-container {
        margin-bottom: $common-gap;
    }

    .itinerary-navigation {
        display: block;
    }

    .disabled-button-container {
        @apply m-auto;
        width: 125px;
        padding-right: 20px;
    }

    .departured-nopax-button-container {
        @apply m-auto;
        width: 180px;
        padding-right: 20px;
    }

    #mainContentItinerary {
        .tabs {
            nav {
                ul {
                    li {
                        label {
                            width: 190px;
                        }
                    }
                }
            }
        }

        .payment-content {
            margin: 7px 0;
        }
    }

    .modal {
        .modal-content {
            &.booking-modal-content {
                .modal-header {
                    &.checkin {
                        @apply leading-7;
                        padding: 12px 40px 12px 12px;

                        i {
                            margin-right: 10px;
                        }
                    }

                    &.resend-itinerary-confirmation-modal {
                        @apply bg-white;
                    }
                }
            }
        }

        &.itinerary-transaction-details-modal {
            .transaction-button-container {
                @apply flex flex-col gap-2;
            }

            .modal-content {
                &.booking-modal-content {
                    width: 90%;
                    max-height: 75%;
                }
            }
        }
    }
}

@media print {
    .main-header,
    .js-footer,
    .no-print {
        display: none !important;
    }

    .print-only {
        display: block !important;

        &.table-cell {
            display: table-cell !important;
        }
    }

    * {
        box-shadow: none !important;

        &[class^="i2-"] {
            border: none !important;
        }
    }

    .modal {
        @apply transform-none;

        &.itinerary-transaction-details-modal {
            @apply transform-none;

            .modal-content {
                @apply fixed left-0 top-0 transform-none horizontal-center;

                .scrollable-area {
                    @apply overflow-y-hidden;
                }
            }
        }
    }
}
