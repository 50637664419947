.seatmap-button-container {
    @apply flex flex-col;
}

.cancel-return-seats {
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background: #000;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 20px;
}

.seatmap-second-leg-modal {
    .checkin-modal-buttons {
        a {
            &:not(.rounded-primary-btn) {
                @apply cursor-pointer;
                margin-top: 10px;
                color: $gray-light;

                &:hover {
                    color: $brand-secondary;
                }
            }
        }
    }
}

.return-flight-btn {
    width: 250px;
    border-color: $brand-secondary;
    color: $brand-secondary;
}

.mobile-seat-btn {
    width: 100%;
    border-radius: $border-radius-sm;
    padding: 5px;
    color: #fff;
    z-index: 1000;
}

@media #{$large} {
    .seatmap-button-container {
        .secondary-btn {
            padding: 12px 15px;
        }
    }
}

@media #{$medium} {
    .cf-plane-container {
        .secondary-btn:not(.disabled):hover {
            border: 2px solid $brand-primary;
            background: #fff;
            color: $brand-primary;
        }
    }
}

@media #{$small} {
    .cf-plane-container {
        .secondary-btn:not(.disabled):hover {
            border: 2px solid $brand-primary;
            background: #fff;
            color: $brand-primary;
        }
    }
}

@media #{$xsmall} {
    .cf-plane-container {
        .secondary-btn:not(.disabled):hover {
            border: 2px solid $brand-primary;
            background: #fff;
            color: $brand-primary;
        }
    }
}
