@mixin baggageGrid() {
    grid-template-columns: 4fr 4fr 4fr 2fr;
    grid-template-areas: "pax checked oversized button";

    @media #{$medium} {
        grid-template-columns: 4fr 4fr 4fr 3fr;
    }

    @media #{$xsmall} {
        grid-template-columns: 1fr;
        grid-template-areas:
            "pax"
            "checked"
            "oversized"
            "button";
    }
}

.insurance-content-container {
    padding: 26px;

    &.tighter {
        @apply pt-0;
    }

    &.baggage-insurance-container,
    &.passenger-data-container {
        @apply border-t border-solid border-be-cyan-2;
    }
}

.insurance-title {
    @apply flex items-start;
}

.insurance-description {
    @apply flex justify-start;

    i {
        @apply ml-10 mr-4 text-be-blue;
        font-size: 100px;
    }
}

.insurance-description-list {
    @apply flex list-none flex-col justify-center pl-6 text-bg-gray-2;
    font-size: 15px;
    line-height: 23px;
    padding-right: 30px;

    li {
        @apply relative mb-1;

        &:before {
            @apply absolute border-b-4 border-l-0 border-r-4 border-t-0 border-solid border-green-400 font-normal vertical-center;
            content: "";
            font-family: "jetsmart-v2" !important;
            transform: translateY(-50%) rotate(45deg);
            width: 7px;
            height: 12px;
            left: -18px;
            font-size: 25px;
        }
    }
}

.insurance-data-opener {
    @apply ml-10 mt-6 flex justify-between rounded-lg border border-solid border-be-gray-10 px-6 pb-4 pt-5 text-be-blue;

    &.open {
        @apply rounded-b-none rounded-t-lg;
        border-bottom-color: #163a70;
    }

    .data-opener-title {
        @apply w-3/4;
        font-size: 22px;
        line-height: 26px;
    }
}

.data-opener-button-container {
    @apply flex align-middle;

    button {
        @apply m-auto;
    }
}

.insurance-opened-container {
    @apply ml-10 overflow-hidden rounded-b-lg bg-be-light-gray common-transition;
    max-height: 0;
    transition: all 0.2s ease-in-out;

    &.open {
        @apply border border-solid border-be-light-gray p-6;
        max-height: 2000px;

        &.lost-bags {
            @apply px-0 py-6;
        }
    }
}

.insurance-passenger-list {
    @apply ml-10 overflow-hidden rounded-lg border border-solid border-be-light-gray bg-be-light-gray p-6;
    transition: all 0.2s ease-in-out;
}

.passenger-data-description {
    @apply mb-4 text-be-blue;
    font-size: 14px;
}

.extras-page {
    .insurance-terms-container {
        .insurance-accept-checkbox-wrapper {
            @apply text-be-gray;
            font-size: 12px;
        }
    }
}

.insurance-passenger-title {
    @apply font-semibold text-be-blue;
    font-size: 16px;
}

.insurance-passenger-row {
    @apply mb-5 grid grid-cols-12 gap-3;
}

.insurance-pasenger-name {
    @apply col-span-4;
}

.insurance-pasenger-doctype {
    @apply col-span-3;
}

.insurance-pasenger-docnumber {
    @apply col-span-4;
}

.insurance-passenger-tick {
    @apply col-span-1 flex items-center justify-center align-middle;

    i {
        @apply m-auto text-be-blue;
        font-size: 30px;
    }
}

.baggage-insurance-data-description {
    @apply mb-4 pl-6 text-be-blue;
    font-size: 14px;
}

.insurance-baggage-insurance-title {
    @apply pl-6 pt-5 font-semibold text-be-blue;
    font-size: 16px;
}

.insurance-baggage-insurance-row {
    @apply grid gap-2 border-b border-solid border-be-gray-10 px-6 pb-5 pt-2 text-be-blue;
    font-size: 14px;

    @include baggageGrid();

    &.inactive {
        @apply pointer-events-none select-none text-be-gray-3 opacity-75;
    }
}

.baggage-insurance-amount-row {
    @apply grid gap-2 overflow-hidden bg-be-gray-10 px-6 common-transition;
    max-height: 0;

    @include baggageGrid();

    &.open {
        max-height: 200px;
    }
}

.insurance-baggage-insurance-name {
    grid-area: pax;
}

.insurance-baggage-insurance-checked-bag {
    @apply flex justify-start;
    grid-area: checked;

    .js-bag-zipper-baggage {
        @apply mr-2 text-brand-primary;
        font-size: 50px;
    }

    &.gray-bag-cell {
        @apply text-be-gray-9;

        .js-bag-zipper-baggage {
            @apply text-be-gray-9;
        }
    }
}

.insurance-baggage-insurance-oversized-bag {
    @apply flex justify-start;
    grid-area: oversized;

    .js-bag-guitar-surf-golf {
        @apply mr-2 text-brand-primary;
        font-size: 55px;
    }

    &.gray-bag-cell {
        @apply text-be-gray-9;

        .js-bag-guitar-surf-golf {
            @apply text-be-gray-9;
        }
    }
}

.insurance-baggage-insurance-price {
    @apply flex items-center justify-center align-middle;
    grid-area: button;

    .js-flight-tick {
        @apply ml-2;
        font-size: 25px;
    }

    > button {
        @apply w-11/12 min-w-0 common-transition;
        font-size: 15px;
        padding: 7px 30px 7px 5px;
    }
}

.baggage-insurance-main-text {
    @apply font-semibold;
    font-size: 16px;
}

.insurance-baggage-insurance-total-price {
    @apply mt-8 text-be-blue;
    font-family: "ClanOT-Bold", sans-serif;
    text-align: right;
    width: 91%;
    font-size: 18px;
}

.insurance-bag-cell-price {
    font-size: 14px;
}

.extras-insurance-header {
    @apply flex w-full justify-start bg-cover bg-right bg-no-repeat pl-12 pt-12 text-be-blue;
    height: 230px;
    background-image: url("https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/01737902-4c15-4e38-822f-56c29baccde7/Desktop-insurance-modal%20%281%29.png");
}

.insurance-title-icon {
    @apply mr-4 mt-2;
    font-size: 100px;
}

.extras-insurance-title-box {
    @apply mt-2;
    font-size: 37px;
    line-height: 45px;

    .insurance-title {
        @apply mb-6 italic;
        max-width: 65%;
        font-family: "ClanOT-Black", sans-serif;
    }

    .insurance-subtitle {
        @apply flex items-center justify-start align-middle font-semibold;
        font-size: 14px;
        line-height: 22px;
    }

    .subtitle-chubb-logo {
        @apply ml-4 h-auto;
        width: 185px;
    }
}

.baggage-insurance-amount-description {
    @apply py-3 text-be-blue;
    grid-area: pax;
    font-size: 15px;
    line-height: 18px;
}

.baggage-insurance-amount-checked-bag {
    @apply py-3 text-be-blue;
    grid-area: checked;
    font-size: 16px;
    text-align: center;
}

.baggage-insurance-amount-oversized-bag {
    @apply py-3 text-be-blue;
    grid-area: oversized;
    text-align: center;
    font-size: 16px;
}

.baggage-insurance-amount-price {
    @apply relative;
    grid-area: button;

    &:after {
        @apply absolute bg-be-gray-10;
        content: "";
        width: 20px;
        height: 20px;
        top: -11px;
        right: 50%;
        transform: translateX(50%) rotate(45deg);
    }
}

.baggage-insurance-amount-title {
    font-size: 14px;
}

.baggage-insurance-amount-button-container {
    @apply m-auto mt-2 flex items-center justify-center align-middle;

    .disabled {
        @apply pointer-events-none text-be-gray-3;

        .baggage-insurance-amount-button {
            @apply pointer-events-none border-be-gray-3 text-be-gray-3;
        }
    }

    .baggage-insurance-amount-button {
        @apply mx-3 my-0 flex items-center justify-center rounded-full border-2 border-solid border-brand-primary bg-transparent p-0 font-body font-black leading-none text-brand-primary;
        width: 25px;
        height: 25px;
        letter-spacing: normal;
        font-size: 18px;

        &:focus,
        &:active {
            @apply border-brand-primary text-brand-primary;
        }

        &:disabled {
            @apply pointer-events-none border-be-gray-3 text-be-gray-3;
        }

        &:not(:disabled):hover {
            @apply border-be-blue text-be-blue;
        }
    }
}

.extras-insurance-modal {
    .modal-content {
        @apply p-0;
        width: 600px;

        .modal-header {
            img {
                max-width: 600px;
            }

            .modal-header-closing {
                @apply absolute right-0 top-0 cursor-pointer rounded-full border-0 bg-be-blue p-0 font-normal leading-none text-white;
                transform: translate(50%, -50%);
                font-size: 36px;
                width: 40px;
                height: 40px;
                z-index: 1;
                letter-spacing: unset;

                &:hover {
                    @apply border-0 bg-black no-underline;
                }
            }
        }

        .insurance-title-box {
            @apply my-10 ml-6 italic text-brand-secondary;
            line-height: 26px;
            font-size: 22px;
            font-family: "ClanOT-Black", sans-serif;
            max-width: 50%;
            text-align: center;

            .insurance-chubb-logo {
                @apply mb-1 h-auto;
                width: 200px;
            }

            .insurance-subtitle {
                font-size: 17px;
                line-height: 21px;
            }
        }

        .eic-ribbon {
            @apply mb-4 flex w-full items-center justify-center bg-be-blue py-2 font-emphasis text-white;
            font-size: 24px;
        }

        .ribbon-icon {
            @apply m-0 mr-2 text-white;
            font-size: 45px;
        }

        .eic-ribbon-text {
            font-family: "Lato", sans-serif;
            font-size: 16px;
        }

        .eic-ribbon-title {
            @apply font-bold;
            font-size: 16px;
            line-height: 19px;
            font-family: "ClanOT-Bold", sans-serif;
        }

        i {
            @apply text-be-cyan-2;
            font-size: 184px;
            margin: 0 18px 0 30px;
        }

        .insurance-modal-list-continer {
            @apply pl-24 pr-6 italic text-be-blue;
            font-family: "ClanOT-Black", sans-serif;
            font-size: 12px;
            line-height: 14px;
        }

        ul {
            @apply mb-2 list-none pl-6 not-italic text-be-gray-4;
            font-family: "Lato", sans-serif;

            li {
                @apply relative;
                line-height: 18px;

                &:before {
                    @apply absolute border-b-4 border-l-0 border-r-4 border-t-0 border-solid border-green-400 font-normal vertical-center;
                    content: "";
                    font-family: "jetsmart-v2" !important;
                    transform: translateY(-50%) rotate(45deg);
                    width: 7px;
                    height: 12px;
                    left: -18px;
                    font-size: 25px;
                }
            }
        }

        .rounded-primary-btn {
            @apply flex items-center justify-center font-bold;
            font-size: 18px;
            margin: 18px auto;
        }
    }
}

.extras-insurance-covid-modal {
    .modal-content {
        @apply p-0;
        width: 770px;

        .eic-label {
            @apply flex items-center justify-center bg-be-cyan-2 font-emphasis text-white;
            font-size: 29px;
            width: 350px;
            height: 62px;
            margin: 0 auto 25px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        > button {
            @apply absolute right-0 top-0 flex cursor-pointer items-center justify-center rounded-full border-0 bg-black p-0 font-normal leading-none text-white;
            transform: translate(50%, -50%);
            font-size: 36px;
            width: 40px;
            height: 40px;
            z-index: 1;
            letter-spacing: unset;

            &:hover {
                @apply border-0 bg-brand-secondary no-underline;
            }
        }

        .eic-info {
            @apply font-body text-be-blue;
            margin: 0 auto 20px;
            font-size: 24px;
            max-width: 635px;
            line-height: 29px;
        }

        .eic-ribbon {
            @apply flex w-full items-center bg-be-blue font-emphasis text-white;
            font-size: 24px;
            height: 54px;
            padding-left: 20px;
            margin-bottom: 20px;
        }

        i {
            @apply text-be-cyan-2;
            font-size: 184px;
            margin: 0 18px 0 30px;
        }

        ul {
            @apply font-body text-be-blue;
            font-size: 24px;
            line-height: 29px;
            padding-right: 30px;

            li {
                @apply relative;

                &:before {
                    @apply absolute rounded-full bg-be-blue;
                    top: 14px;
                    left: -11px;
                    content: "";
                    width: 3px;
                    height: 3px;
                }
            }
        }

        .rounded-primary-btn {
            @apply flex items-center justify-center;
            width: 300px;
            height: 58px;
            font-size: 24px;
            margin: 28px auto;
        }
    }
}

@media #{$large} {
    .insurance-baggage-insurance-price {
        .rounded-primary-btn {
            @apply w-full;
        }
    }
}

@media #{$medium} {
    .baggage-insurance-main-text {
        font-size: 13px;
    }

    .insurance-baggage-insurance-row {
        font-size: 12px;
    }

    .extras-insurance-title-box {
        .insurance-title {
            @apply max-w-none;
        }
    }

    .baggage-insurance-amount-description {
        font-size: 12px;
        line-height: 15px;
    }

    .baggage-insurance-amount-title {
        font-size: 13px;
    }
}

@media #{$small} {
    .extras-insurance-modal {
        .modal-header {
            img {
                max-width: 475px;
            }
        }

        .modal-content {
            width: 475px;

            .eic-label {
                font-size: 18px;
                width: 216px;
                height: 48px;
                margin: 0 auto 10px;
            }

            > button {
                font-size: 22px;
                width: 32px;
                height: 32px;
            }

            .eic-ribbon {
                @apply py-3;
                font-size: 20px;
                margin-bottom: 15px;
            }

            .ribbon-icon {
                @apply m-0 mr-3;
                font-size: 60px;
            }

            .eic-ribbon-text {
                font-size: 16px;
            }

            .eic-ribbon-title {
                font-size: 20px;
            }

            i {
                font-size: 114px;
                margin: 0 15px 0 30px;
            }

            ul {
                @apply pl-10;
                font-size: 15px;
                line-height: 18px;
                padding-right: 20px;

                li {
                    @apply relative;

                    &:before {
                        left: -16px;
                    }
                }
            }

            .rounded-primary-btn {
                font-size: 15px;
                margin: 15px auto;
            }
        }
    }

    .extras-insurance-covid-modal {
        .modal-content {
            width: 475px;

            .eic-label {
                font-size: 18px;
                width: 216px;
                height: 48px;
                margin: 0 auto 10px;
            }

            > button {
                font-size: 22px;
                width: 32px;
                height: 32px;
            }

            .eic-info {
                margin: 0 auto 25px;
                font-size: 16px;
                max-width: 433px;
                line-height: 19px;
            }

            .eic-ribbon {
                font-size: 15px;
                height: 37px;
                padding-left: 30px;
                margin-bottom: 15px;
            }

            i {
                font-size: 114px;
                margin: 0 15px 0 30px;
            }

            ul {
                font-size: 15px;
                line-height: 18px;
                padding-right: 30px;

                li {
                    &:before {
                        top: 9px;
                        left: -9px;
                    }
                }
            }

            .rounded-primary-btn {
                width: 137px;
                height: 36px;
                font-size: 15px;
                margin: 15px auto;
            }
        }
    }

    .insurance-description {
        i {
            @apply ml-5 mr-3;
            font-size: 80px;
        }
    }

    .insurance-description-list {
        font-size: 13px;
        line-height: 20px;
    }

    .insurance-data-opener {
        @apply ml-5 mt-4;

        .data-opener-title {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .insurance-opened-container {
        @apply ml-5;

        &.open {
            @apply p-4;
        }
    }

    .insurance-passenger-list {
        @apply ml-5 p-4;
    }

    .insurance-opened-baggage-insurance-container {
        @apply ml-5 p-4;
    }

    .insurance-passenger-row {
        @apply gap-1;
    }

    .insurance-pasenger-name {
        @apply col-span-4;
    }

    .insurance-pasenger-docnumber {
        @apply col-span-4;
    }

    .insurance-baggage-insurance-title {
        @apply pl-4 pt-3;
        font-size: 14px;
    }

    .insurance-baggage-insurance-row {
        @apply gap-1 px-4 pb-4;
        font-size: 12px;
    }

    .insurance-baggage-insurance-price {
        .js-flight-tick {
            @apply ml-1;
            font-size: 25px;
        }

        .rounded-primary-btn {
            @apply w-full;
            font-size: 12px;
            padding: 7px 30px 7px 6px;

            &:after {
                right: 2px;
            }
        }
    }

    .baggage-insurance-main-text {
        @apply font-semibold;
        font-size: 13px;
    }

    .insurance-baggage-insurance-total-price {
        font-size: 17px;
    }

    .insurance-bag-cell-price {
        font-size: 13px;
    }

    .extras-insurance-header {
        @apply rounded-t-lg pl-6 pt-6;
        height: 140px;
    }

    .insurance-title-icon {
        @apply mr-3 mt-1;
        font-size: 55px;
    }

    .extras-insurance-title-box {
        @apply mt-1;
        font-size: 24px;
        line-height: 27px;

        .insurance-title {
            @apply mb-3;
        }

        .insurance-subtitle {
            font-size: 11px;
            line-height: 13px;
        }

        .subtitle-chubb-logo {
            @apply ml-2;
            width: 90px;
        }
    }

    .extras-insurance-modal {
        .modal-content {
            .modal-header {
                img {
                    max-width: 475px;
                }
            }

            .insurance-title-box {
                @apply mb-10 mt-3;
                font-size: 18px;

                .insurance-subtitle {
                    font-size: 16px;
                }
            }

            .rounded-primary-btn {
                margin: 30px auto;
            }
        }
    }

    .baggage-insurance-amount-row {
        @apply px-4;
    }

    .baggage-insurance-amount-description {
        font-size: 12px;
        line-height: 15px;
    }

    .baggage-insurance-amount-title {
        font-size: 12px;
    }

    .baggage-insurance-amount-checked-bag {
        font-size: 14px;
    }

    .baggage-insurance-amount-oversized-bag {
        font-size: 14px;
    }

    .baggage-insurance-amount-button-container {
        .baggage-insurance-amount-button {
            &:not(:disabled):hover {
                @apply border-brand-primary text-brand-primary;
            }
        }
    }
}

@media #{$xsmall} {
    .extras-insurance-modal {
        .modal-content {
            width: 285px;

            .modal-header {
                @apply bg-cover bg-right;
                border-radius: 10px 10px 0 0;
                background-image: url("https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/a52f7160-f3f2-4626-99cf-8631f0751c48/Mobile-insurance-modal (1).png");

                img {
                    max-width: 285px;
                }
            }

            .insurance-title-box {
                @apply my-0 ml-4 flex flex-col items-center justify-center;
                line-height: 15px;
                font-size: 12px;
                max-width: 75%;

                .insurance-chubb-logo {
                    @apply mb-2;
                    width: 130px;
                }

                .insurance-subtitle {
                    font-size: 10px;
                    line-height: 12px;
                }
            }

            .eic-label {
                font-size: 15px;
                width: 144px;
                height: 32px;
                margin: 0 auto 12px;
            }

            > button {
                font-size: 30px;
                width: 40px;
                height: 40px;
            }

            .eic-ribbon {
                @apply py-3;
                font-size: 16px;
                margin-bottom: 13px;
            }

            .ribbon-icon {
                @apply mb-3 ml-1;
                font-size: 40px;
            }

            .eic-ribbon-text {
                font-size: 16px;
            }

            .eic-ribbon-title {
                @apply mb-1;
            }

            i {
                font-size: 74px;
                margin: 0 10px 0 15px;
            }

            .insurance-modal-list-continer {
                @apply pl-4 pr-6 italic text-be-blue;
                font-family: "ClanOT-Black", sans-serif;
                font-size: 12px;
                line-height: 14px;
            }

            ul {
                @apply pl-4;
                font-size: 13px;
                line-height: 18px;
                padding-right: 10px;

                li {
                    @apply relative;

                    &:before {
                        left: -16px;
                    }
                }
            }

            .rounded-primary-btn {
                font-size: 14px;
                margin: 15px auto;
            }
        }
    }

    .extras-insurance-covid-modal {
        .modal-content {
            width: 285px;

            .eic-label {
                font-size: 15px;
                width: 144px;
                height: 32px;
                margin: 0 auto 12px;
            }

            > button {
                font-size: 15px;
                width: 22px;
                height: 22px;
            }

            .eic-info {
                margin: 0 auto 18px;
                font-size: 12px;
                max-width: 90%;
                line-height: 14px;
            }

            .eic-ribbon {
                font-size: 13px;
                height: 30px;
                padding-left: 15px;
                margin-bottom: 18px;
            }

            i {
                font-size: 74px;
                margin: 0 10px 0 15px;
            }

            ul {
                font-size: 10px;
                line-height: 12px;
                padding-right: 15px;

                li {
                    &:before {
                        top: 6px;
                        left: -7px;
                    }
                }
            }

            .rounded-primary-btn {
                width: 90%;
                height: 37px;
                font-size: 14px;
                margin: 40px auto 10px;
            }
        }
    }

    .insurance-description {
        @apply mt-4;

        i {
            @apply ml-0 mr-2 mt-6;
            font-size: 45px;
        }
    }

    .insurance-description-list {
        font-size: 13px;
        line-height: 20px;

        li {
            @apply mb-0;
        }
    }

    .insurance-data-opener {
        @apply ml-0 mt-6 block px-4 py-3;

        .data-opener-title {
            @apply mb-2 w-full;
            font-size: 15px;
            line-height: 20px;
        }
    }

    .insurance-opened-container {
        @apply ml-0 p-4;

        &.open {
            max-height: 4700px;

            &.lost-bags {
                @apply py-0;
            }
        }

        &.lost-bags {
            @apply border-0 py-0;
        }
    }

    .insurance-passenger-list {
        @apply ml-0 p-4;
    }

    .insurance-opened-baggage-insurance-container {
        @apply ml-0 p-4;
    }

    .passenger-data-description {
        display: none;
    }

    .baggage-insurance-data-description {
        display: none;
    }

    .insurance-passenger-row {
        @apply relative gap-1;
    }

    .insurance-pasenger-name {
        @apply col-span-12;
    }

    .insurance-pasenger-doctype {
        @apply col-span-12;
    }

    .insurance-pasenger-docnumber {
        @apply col-span-12;
    }

    .mobile-flight-tick-icon {
        &:after {
            @apply absolute m-auto block text-be-blue;
            font-size: 28px;
            right: 8px;
            bottom: 22px;
            font-family: "jetsmart-v2" !important;
            content: "\e92e"; // DEVNOTE Might change if icons change
        }
    }

    .insurance-passenger-tick {
        display: none;
    }

    .insurance-baggage-insurance-title {
        @apply pl-4 pt-3;
        font-size: 14px;
    }

    .insurance-baggage-insurance-row {
        @apply block px-0 pb-4;
        font-size: 12px;
    }

    .insurance-baggage-insurance-name {
        @apply pl-4;
    }

    .insurance-baggage-insurance-checked-bag {
        @apply p-4;

        .js-icon-bag {
            &.js-bag-zipper-baggage {
                @apply w-1/4;
                text-align: center;
                font-size: 55px;
            }
        }
    }

    .insurance-baggage-insurance-oversized-bag {
        @apply p-4;

        .js-bag-guitar-surf-golf {
            @apply w-1/4;
            text-align: center;
        }
    }

    .insurance-baggage-insurance-price {
        @apply border-0 p-4;

        .js-flight-tick {
            @apply ml-1;
            font-size: 25px;
        }

        > button {
            width: 140px;
            padding: 8px 30px 8px 5px;
        }
    }

    .baggage-insurance-main-text {
        @apply font-semibold;
        font-size: 13px;
    }

    .insurance-baggage-insurance-total-price {
        @apply m-0 w-full bg-white py-4 pr-0;
        font-size: 17px;
    }

    .insurance-bag-cell-price {
        font-size: 13px;
    }

    .extras-insurance-header {
        @apply rounded-t-xl bg-right-top pl-2 pt-0;
        background-image: url("https://assets-us-01.kc-usercontent.com/b2956330-c34f-0064-2c6f-27bd5c0147fc/a52f7160-f3f2-4626-99cf-8631f0751c48/Mobile-insurance-modal (1).png");
        height: 155px;
    }

    .insurance-title-icon {
        @apply mr-1 mt-0 flex items-center;
        font-size: 65px;
    }

    .extras-insurance-title-box {
        @apply mt-0 flex flex-col justify-center;
        font-size: 18px;
        line-height: 23px;

        .insurance-title {
            @apply mb-0;
            max-width: 60%;
        }

        .insurance-subtitle {
            @apply block;
            font-size: 11px;
            max-width: 160px;
            line-height: 13px;
        }

        .subtitle-chubb-logo {
            @apply ml-0 mt-2 block;
            width: 100px;
        }
    }

    .insurance-content-container {
        padding: 19px;
    }

    .baggage-insurance-amount-row {
        @apply relative px-4;

        &:after {
            @apply absolute bg-be-gray-10;
            content: "";
            width: 20px;
            height: 20px;
            top: -11px;
            right: 50%;
            transform: translateX(50%) rotate(45deg);
        }
    }

    .mobile-baggage-insurance-amount-container {
        @apply flex items-center justify-between;
    }

    .baggage-insurance-amount-description {
        @apply w-5/12;
        font-size: 14px;
        line-height: 17px;
    }

    .baggage-insurance-amount-checked-bag {
        @apply w-5/12;
    }

    .baggage-insurance-amount-oversized-bag {
        @apply w-5/12;
    }

    .baggage-insurance-amount-title {
        font-size: 13px;
    }

    .baggage-insurance-amount-button-container {
        .baggage-insurance-amount-button {
            &:not(:disabled):hover {
                @apply border-brand-primary text-brand-primary;
            }
        }
    }
}
