.i2-itinerary-section {
    &.i2-dc-banner {
        @apply bg-be-cyan-2 text-white;
        border-radius: 20px;
        padding: 35px 0;
        grid-area: dc;
    }
}

.i2-dc-banner-title {
    font-family: "ClanOT-Black", sans-serif;
    font-size: 43px;
    line-height: 51px;
}

.i2-dc-banner-subtitle {
    font-family: "ClanOT-Medium", sans-serif;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
}

.i2-dc-banner-text {
    @apply font-body;
    font-size: 27px;
    line-height: 32px;
    max-width: 480px;
    text-align: left;
}

.i2db-left {
    @apply border-r-2 border-solid border-white;
    text-align: center;
    padding: 12px 70px 12px 0;
    margin-right: 70px;

    img {
        height: 105px;
    }
}

.i2db-right {
    text-align: center;

    img {
        height: 72px;
        margin-bottom: 32px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-dc-banner {
        border-radius: 17px;
        padding: 35px 0;
        margin-top: 30px;
    }

    .i2-dc-banner-title {
        font-size: 40px;
        line-height: 48px;
    }

    .i2-dc-banner-subtitle {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 16px;
    }

    .i2-dc-banner-text {
        font-size: 24px;
        line-height: 28px;
        max-width: 350px;
    }

    .i2db-left {
        padding: 12px 50px 12px 0;
        margin-right: 50px;

        img {
            height: 90px;
        }
    }

    .i2db-right {
        img {
            height: 60px;
            margin-bottom: 24px;
        }
    }
}

@media #{$medium} {
    .i2-dc-banner {
        border-radius: 14px;
        padding: 28px 0;
        margin-top: 30px;
    }

    .i2-dc-banner-title {
        font-size: 35px;
        line-height: 40px;
    }

    .i2-dc-banner-subtitle {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
    }

    .i2-dc-banner-text {
        font-size: 18px;
        line-height: 21px;
        max-width: 250px;
    }

    .i2db-left {
        padding: 12px 45px 12px 0;
        margin-right: 45px;

        img {
            height: 80px;
        }
    }

    .i2db-right {
        img {
            height: 54px;
            margin-bottom: 24px;
        }
    }
}

@media #{$small} {
    .i2-dc-banner {
        border-radius: 12px;
        padding: 22px 0;
        margin-top: 30px;
    }

    .i2-dc-banner-title {
        font-size: 29px;
        line-height: 34px;
    }

    .i2-dc-banner-subtitle {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 8px;
    }

    .i2-dc-banner-text {
        font-size: 18px;
        line-height: 22px;
        max-width: 320px;
    }

    .i2db-left {
        padding: 7px 40px 7px 0;
        margin-right: 40px;

        img {
            height: 70px;
        }
    }

    .i2db-right {
        img {
            height: 47px;
            margin-bottom: 24px;
        }
    }
}

@media #{$xsmall} {
    .i2-dc-banner {
        border-radius: 12px;
        padding: 28px;
        margin: 25px auto 0;
        width: 90%;
    }

    .i2-dc-banner-title {
        font-size: 24px;
        line-height: 29px;
    }

    .i2-dc-banner-subtitle {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 5px;
    }

    .i2-dc-banner-text {
        font-size: 13px;
        line-height: 16px;
        max-width: unset;
    }

    .i2db-left {
        @apply border-0;
        padding: 0;
        margin-right: 0;

        img {
            height: 66px;
            margin-bottom: 15px;
        }
    }

    .i2db-right {
        img {
            height: 34px;
            margin-bottom: 12px;
        }
    }
}
