.btn-boarding {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 62px;
    padding: 5px;
    border: 2px solid transparent;
    border-radius: $border-radius-lg;
    background: $boarding-btn-bg;
    color: $gray-lightest;
    text-align: center;
    font-weight: $semibold-font-weight;
    font-size: $font-size-small;
    user-select: none;
    cursor: pointer;

    &:not(.disabled):hover {
        border-color: $brand-secondary;
        background: $brand-secondary;
        color: $gray-lightest;
    }

    &.selected {
        border-color: $brand-secondary;
        background: $gray-lightest;
        color: rgba(0, 0, 0, .87);
    }
}

@media #{$xsmall} {
    .btn-boarding {
        &:not(.disabled):hover {
            border-color: transparent;
            background: $boarding-btn-bg;
            color: $gray-lightest;

            &.selected {
                border-color: $brand-secondary;
                background: $gray-lightest;
                color: rgba(0, 0, 0, .87);
            }
        }
    }
}
