/* ------------------------------------------------------------- */

@font-face {
    font-family: "jetsmart-cug2b-notification";
    src: url("../fonts/jetsmart-cug2b-notification.eot?8lmy5b");
    src: url("../fonts/jetsmart-cug2b-notification.eot?8lmy5b#iefix") format("embedded-opentype"),
        url("../fonts/jetsmart-cug2b-notification.ttf?8lmy5b") format("truetype"),
        url("../fonts/jetsmart-cug2b-notification.woff?8lmy5b") format("woff"),
        url("../fonts/jetsmart-cug2b-notification.svg?8lmy5b#jetsmart-cug2b-notification") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-cug-notification {
    font-family: "jetsmart-cug2b-notification" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-cn-calendar:before {
    content: "\e903";
}
.js-cn-circle-bell:before {
    content: "\e900";
}
.js-cn-circle-clock:before {
    content: "\e901";
}
.js-cn-circle-calendar:before {
    content: "\e902";
}
