.be-footer-title {
    margin: 45px 0 10px;

    span {
        &:nth-child(1),
        &:nth-child(2) {
            font-family: "Lato", sans-serif;
            font-weight: 600;
            font-size: 21px;
            color: $brand-secondary;
            font-style: italic;
        }

        &:nth-child(2) {
            color: $banco-estado-color;
        }

        &:nth-child(3) {
            color: $banco-estado-color;
            font-size: 21px;
            font-family: "Gotham-Bold", sans-serif;
        }
    }
}

.footer-title {
    &.follow-banco-estado {
        font-family: "Lato", sans-serif;
        font-weight: 600;
        font-size: 22px;
        color: $brand-secondary;
    }
}

.be-header-username {
    font-family: "Gotham-Bold", sans-serif;
    color: $banco-estado-color;
    white-space: nowrap;
}

.be-footer-subtitle {
    margin-top: 25px;

    span {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            font-family: "Lato", sans-serif;
            font-size: 20px;
            font-style: italic;
            color: $brand-secondary;
        }

        &:nth-child(2) {
            color: $banco-estado-color;
        }

        &:nth-child(4) {
            color: $brand-secondary;
            font-size: 20px;
            font-family: "Gotham-Bold", sans-serif;
        }
    }
}

.be-footer-sub-subtitle-1 {
    margin-top: 5px;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-style: italic;
    color: $brand-secondary;
}

.be-footer-sub-subtitle-2 {
    margin-top: 8px;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-style: italic;
    color: $footer-link-color;
    line-height: 1.3;

    a {
        color: $brand-secondary;
        font-weight: 700;

        &:hover {
            color: $brand-primary;
        }
    }
}

.be-footer-images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &.text-center {
        justify-content: center;
    }
}

.be-footer-img {
    margin: 10px 20px 0 0;
    height: 40px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .be-footer-title {
        span {
            &:nth-child(1),
            &:nth-child(2) {
                font-size: 16px;
            }

            &:nth-child(3) {
                font-size: 16px;
            }
        }
    }

    .footer-title {
        &.follow-banco-estado {
            font-size: 16px;
        }
    }

    .be-footer-subtitle {
        span {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                font-size: 16px;
            }

            &:nth-child(4) {
                font-size: 16px;
            }
        }
    }

    .be-footer-img {
        height: 40px;
    }
}

@media #{$medium} {
    .be-footer-title {
        span {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                font-size: 16px;
            }
        }
    }

    .footer-title {
        &.follow-banco-estado {
            font-size: 16px;
        }
    }

    .be-footer-img {
        margin-right: 0;
        height: 35px;

        &:nth-child(1) {
            margin-right: 20px;
        }
    }

    .be-footer-subtitle {
        margin-top: 20px;

        span {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                font-size: 16px;
            }
        }
    }

    .be-footer-sub-subtitle-2 {
        font-size: 16px;
    }

    .social-icons {
        a {
            font-size: 32px;
        }
    }
}

@media #{$small-down} {
    .be-footer-title {
        span {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                font-size: 18px;
            }
        }
    }

    .footer-title {
        &.follow-banco-estado {
            font-size: 18px;
        }
    }

    .be-footer-img {
        height: 50px;
        margin-right: 0;

        &:nth-child(1) {
            margin-right: 20px;
        }
    }

    .be-footer-subtitle {
        margin-top: 20px;

        span {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                font-size: 16px;
                line-height: 1.4;
            }
        }
    }

    .be-footer-title {
        text-align: center;
    }

    .be-footer-subtitle {
        text-align: center;
        margin-top: 20px;
    }

    .be-footer-sub-subtitle-1 {
        text-align: center;
    }

    .be-footer-sub-subtitle-2 {
        font-size: 16px;
        text-align: center;
    }

    .be-footer-images {
        display: block;

        .be-footer-img {
            display: block;
            margin: 10px auto;
        }
    }
}

@media #{$xsmall} {
    .be-footer-title {
        line-height: 1.4;
    }
}
