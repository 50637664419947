@media #{$xsmall} {
    .b2-baggage-page {
        padding: 25px;

        label,
        .b2m-label {
            @apply h-full w-full font-body font-black text-be-blue;

            &.checked {
                @apply pointer-events-none;
            }

            &.disabled {
                @apply pointer-events-none text-bg-gray-2 opacity-100;
            }
        }

        [type="radio"] {
            &:not(:checked),
            &:checked {
                + label {
                    @apply inline-block h-auto;
                    font-size: 12px;
                    line-height: 13px;
                    padding: 0 10px 0 0;

                    &:before {
                        top: 40%;
                        left: -118%;
                        transform: translateY(-50%);
                        width: 15px;
                        height: 15px;
                    }

                    &:after {
                        top: 40%;
                        left: -118%;
                        transform: translateY(-50%) scale(0.5);
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            &:not(:disabled),
            &:disabled {
                + label {
                    @apply inline-block h-auto;
                    font-size: 12px;
                    line-height: 13px;
                }
            }

            &:not(:checked) {
                + label {
                    &:before {
                        @apply border-be-blue;
                    }
                }
            }

            &:checked {
                @apply pointer-events-none;

                + label {
                    @apply pointer-events-none h-auto;
                    font-size: 12px;

                    &:before {
                        @apply border-be-blue;
                    }

                    &:after {
                        @apply border-be-blue bg-be-blue;
                    }
                }
            }

            &:disabled {
                &:checked {
                    + label {
                        @apply text-be-blue opacity-100;
                        font-size: 12px;

                        &:before {
                            @apply border-be-blue;
                        }

                        &:after {
                            @apply bg-be-blue;
                        }
                    }
                }

                &:not(:checked) {
                    + label {
                        @apply text-bg-gray-2;
                        font-size: 12px;

                        &:before {
                            @apply border-bg-gray-2 opacity-75;
                        }

                        &:after {
                            @apply opacity-0;
                        }
                    }
                }
            }
        }
    }

    .b2-tooltip {
        padding: 30px 50px 25px 30px;

        span {
            &:nth-child(2) {
                bottom: 14px;
                left: 24px;
            }

            &:nth-child(3) {
                top: 10px;
                left: 65px;
            }

            &:nth-child(4) {
                top: 65px;
                right: 6px;
            }
        }
    }

    .b2-per-booking-quantity {
        font-size: 17px;
        margin: 5px 10px 0;
    }

    .b2m-cabin-bag-container {
        @apply relative w-full;
    }

    .b2m-per-booking-section {
        @apply relative flex items-center border border-b-0 border-solid border-be-gray-10;
        padding: 15px 15px 15px 15%;

        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &.padded {
            padding-top: 25px;

            &.selected {
                padding-bottom: 60px;
            }
        }

        .js-icon-bag {
            @apply text-be-blue;

            &.js-bag-backpack {
                font-size: 30px;
                margin-bottom: 4px;

                &.solo {
                    font-size: 40px;
                    margin-bottom: 8px;
                }
            }

            &.js-bag-regular-baggage {
                font-size: 44px;
                margin-bottom: 4px;
            }

            &.js-bag-crossed-out-baggage {
                font-size: 50px;
                margin-bottom: 8px;
            }

            &.js-bag-zipper-baggage {
                font-size: 50px;
                margin-bottom: 8px;
            }
        }

        .b2m-dimensions {
            @apply flex items-center font-news leading-none text-bg-gray-3;
            height: 20px;
            margin-bottom: 8px;
            font-size: 10px;

            &.smaller {
                font-size: 8px;
            }
        }
    }

    .b2m-per-booking-selector {
        @apply w-1/2;

        > span {
            @apply mb-2 block font-semibold text-be-blue;
            font-size: 11px;
            line-height: 10px;

            &.reduced {
                @apply text-be-cyan-2;
            }

            span {
                @apply font-heavy;
                font-size: 11px;
            }
        }

        &.padded {
            padding-bottom: 25px;
        }

        .b2-per-booking-quantity {
            font-size: 17px;
            margin: 5px 10px 0;
        }

        .b2m-crossed-out-price {
            @apply mb-1;
            font-size: 9px;
            padding-left: 32px;

            span {
                @apply font-emphasis;
                font-size: 9px;
            }
        }
    }

    .b2m-view {
        @apply flex items-center justify-between bg-be-light-gray;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 15px 12px 12px;

        .b2-secondary-button {
            @apply whitespace-normal;
            max-width: 150px;
        }

        &.per-pax {
            @apply rounded-none;
            padding-bottom: 15px;
        }
    }

    .b2m-info-amount {
        max-width: 50%;
    }

    .b2m-view-info {
        @apply font-emphasis text-be-blue;
        font-size: 10px;
        line-height: 12px;
    }

    .b2m-pax-amount {
        @apply font-body text-be-blue;
        font-size: 10px;
        line-height: 12px;
    }

    .b2-secondary-button {
        margin-left: 20px;
    }

    .js-icon-bag {
        @apply text-be-blue;

        &.js-bag-backpack {
            font-size: 30px;
            margin-bottom: 4px;

            &.solo {
                font-size: 40px;
                margin-bottom: 8px;
            }
        }

        &.js-bag-regular-baggage {
            font-size: 44px;
            margin-bottom: 4px;
        }

        &.js-bag-crossed-out-baggage {
            font-size: 68px;
            margin-bottom: 8px;
        }

        &.js-bag-zipper-baggage {
            font-size: 68px;
            margin-bottom: 8px;
        }
    }

    .b2m-ribbon {
        @apply absolute font-emphasis text-be-cyan-2;
        font-size: 11px;
        top: 5px;
        left: 15px;

        span {
            @apply relative;
            font-size: 15px;
            top: 1px;
        }
    }

    .b2m-per-pax-container {
        @apply flex items-center border border-solid border-be-gray-10;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 201px;
    }

    .b2m-per-pax-section {
        @apply relative flex h-full w-1/2 flex-col items-center justify-end;
        padding-top: 110px;

        &.padded {
            padding-bottom: 12px;

            i {
                margin-bottom: 20px;
            }
        }

        img {
            @apply absolute horizontal-center;
            top: 30px;
        }

        &:first-child {
            @apply border-r border-solid border-be-gray-10;
        }

        .b2m-ribbon {
            @apply whitespace-nowrap;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
        }

        .b2-per-booking-next-price {
            @apply flex w-full items-end justify-center font-body font-semibold text-be-blue;
            font-size: 12px;
            height: 15px;

            span {
                @apply relative;
                font-size: 11px;
                top: 1px;
                left: 3px;
            }

            &.original {
                display: none;
            }

            &.reduced {
                @apply m-1 h-auto font-normal leading-none;
                font-size: 9px;

                span {
                    font-size: 9px;
                }
            }

            &.dc {
                @apply text-be-cyan-2;
            }

            .b2-amount {
                font-size: 13px;
            }
        }
    }

    .b2m-tooltips.backpack-and-bag-tooltips {
        .backpack-tooltip-opener {
            top: 100px;
            left: 50%;
        }

        .regular-bag-tooltip-opener {
            top: 89px;
            left: 56%;
        }
    }

    .b2m-tooltip {
        .b2-tooltip-opener {
            @apply absolute m-0;
            top: 50%;
            left: 50%;
            transform: translate(15px, -50px);
            width: 15px;
            height: 15px;

            &:hover {
                z-index: 1000;
            }

            &.backpack-tooltip-opener {
                top: 80px;
                left: 55%;
            }

            &.regular-bag-tooltip-opener {
                top: 105px;
                left: 54%;
            }

            &.zipped-bag-tooltip-opener {
                top: 82px;
                left: 67%;
            }
        }

        &.checked {
            .b2-tooltip-opener {
                transform: translate(20px, -55px);

                .b2-tooltip {
                    bottom: 50%;
                    transform: translateY(50%);
                    left: unset;
                    right: 20px;

                    &:after {
                        top: 50%;
                        left: 100%;
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
            }
        }
    }

    .b2m-tooltips {
        .b2-tooltip-opener {
            @apply absolute m-0;
            top: 50%;
            left: 50%;
            width: 15px;
            height: 15px;

            &:hover {
                z-index: 1000;
            }

            &:first-child {
                transform: translate(-25px, -50px);
            }

            &:last-child {
                transform: translate(45px, -57px);

                .b2-tooltip {
                    bottom: 50%;
                    transform: translateY(50%);
                    left: unset;
                    right: 20px;

                    &:after {
                        top: 50%;
                        left: 100%;
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                }
            }
        }
    }

    .b2m-journeys-container {
        @apply relative rounded-b-lg bg-bg-gray-4;
        padding: 0 15px 15px;
    }

    .b2m-journeys {
        @apply overflow-hidden bg-white;
        border-radius: 9px;
    }

    .b2m-journey-details {
        @apply overflow-hidden common-transition;
        max-height: 0;
    }

    .b2m-journey-heading {
        @apply relative flex items-center bg-be-cyan-2 text-white;
        padding: 0 35px 0 15px;
        height: 30px;

        i {
            @apply text-white common-transition;
        }

        .js-bag-plane-take-off {
            font-size: 18px;
            margin-right: 10px;
        }

        .js-circle-chevron-right {
            @apply absolute;
            top: 50%;
            transform: translateY(-50%) rotate(0);
            right: 10px;
            font-size: 18px;
        }

        span {
            @apply font-emphasis;
            font-size: 10px;
        }

        &.open {
            .js-circle-chevron-right {
                transform: translateY(-50%) rotate(90deg);
            }

            + .b2m-journey-details {
                max-height: 2000px;
            }
        }
    }

    .b2m-pax-content {
        @apply flex items-stretch overflow-hidden common-transition;
        max-height: 0;

        .b2-pax-option-info {
            font-size: 10px;

            span {
                font-size: 10px;
            }
        }

        [type="radio"] {
            &:disabled,
            &:not(:disabled) {
                + label {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }

        .b2-pax-option {
            min-height: 30px;
            max-height: 50px;
        }
    }

    .b2m-pax-opener {
        @apply relative flex items-stretch border-b border-solid border-bg-gray-1;

        &:last-child {
            @apply border-b-0;
        }

        &.open {
            + .b2m-pax-content {
                max-height: 200px;
            }

            .b2m-pax-type {
                @apply border-white bg-bg-cyan;
            }

            .b2m-pax-name {
                @apply bg-bg-cyan;

                i {
                    transform: translateY(-50%) rotate(90deg);
                    font-size: 16px;
                }
            }

            .b2m-pax-selected-bags {
                .js-circle-chevron-right {
                    transform: translateY(-50%) rotate(90deg);
                    font-size: 16px;
                }
            }
        }

        &.selected {
            @apply bg-be-blue text-white;
        }
    }

    .b2m-pax-selected-name {
        @apply relative flex w-1/2 items-center font-body font-black;
        font-size: 11px;
        line-height: 13px;
        padding: 12px 12px 12px 30px;

        &:after {
            @apply absolute border-b-2 border-l-0 border-r-2 border-t-0 border-solid border-white;
            content: "";
            left: 12px;
            top: 50%;
            width: 6px;
            height: 12px;
            transform: translateY(-60%) rotate(45deg);
        }
    }

    .b2m-pax-selected-bags {
        @apply relative flex w-1/2 items-end justify-center;
        padding: 8px 30px 8px 12px;

        .js-bag-guitar-surf-golf {
            @apply text-white;
            font-size: 22px;
            transform: scale(1.5);
        }

        .js-bag-backpack {
            @apply text-white;
            font-size: 15px;

            &.solo {
                @apply mb-0;
                font-size: 20px;
            }
        }

        .js-bag-regular-baggage {
            @apply text-white;
            font-size: 22px;
        }

        .js-bag-crossed-out-baggage {
            @apply mb-0 text-white;
            font-size: 22px;
        }

        .js-bag-zipper-baggage {
            @apply mb-0 text-white;
            font-size: 22px;
        }

        .b2m-plus {
            @apply font-body font-semibold text-white;
            top: -5px;
            margin-left: 3px;
            font-size: 14px;
        }

        .js-circle-chevron-right {
            @apply absolute text-white common-transition;
            font-size: 14px;
            top: 50%;
            right: 10px;
            transform: translateY(-50%) rotate(0);
        }
    }

    .b2m-pax-type {
        @apply flex w-1/2 items-center border-r border-solid border-bg-gray-1 font-body text-be-blue;
        font-size: 11px;
        line-height: 12px;
        padding: 12px;
    }

    .b2m-pax-name {
        @apply relative flex w-1/2 items-center justify-between font-body font-black text-be-blue;
        font-size: 11px;
        line-height: 12px;
        padding: 12px 30px 12px 12px;

        i {
            @apply absolute text-be-blue common-transition;
            font-size: 16px;
            top: 50%;
            right: 10px;
            transform: translateY(-50%) rotate(0);
        }
    }

    .b2m-free-cell {
        @apply relative flex w-1/2 flex-col justify-center border-r border-solid border-bg-gray-1;
        padding: 12px;
        background: rgba(#cee6ed, 0.3);
    }

    .b2m-paid-cell {
        @apply relative flex w-1/2 flex-col justify-center;
        padding: 12px;
        background: rgba(#cee6ed, 0.3);

        .b2-per-booking-quantity-container {
            @apply relative bottom-0 right-0 justify-center;
        }

        .b2-per-booking-quantity {
            font-size: 17px;
            margin: 5px 10px 0;
        }
    }

    .b2m-checked-bag-container {
        @apply relative;

        .b2m-per-booking-section {
            &.selected {
                @apply border-b border-be-red;
            }

            &.free {
                &.selected {
                    @apply border-b border-be-blue;
                }
            }
        }
    }

    .b2-per-booking-quantity-container {
        @apply absolute right-0;
        bottom: -25px;
    }

    .b2m-oversized-bag-container {
        padding-bottom: 15px;

        .b2-per-pax-quantity {
            margin-top: 7px;
        }
    }

    .b2m-add-oversized {
        @apply font-body font-semibold text-be-blue;
        font-size: 12px;

        span {
            @apply relative font-heavy text-be-blue;
            font-size: 12px;
            top: -1px;
        }
    }
}
