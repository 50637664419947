/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "js-bundle-icons";
    src: url("../fonts/js-bundle-icons.eot?8x5c5j");
    src: url("../fonts/js-bundle-icons.eot?8x5c5j#iefix") format("embedded-opentype"),
        url("../fonts/js-bundle-icons.ttf?8x5c5j") format("truetype"),
        url("../fonts/js-bundle-icons.woff?8x5c5j") format("woff"),
        url("../fonts/js-bundle-icons.svg?8x5c5j#js-bundle-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-bundle {
    font-family: "js-bundle-icons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-bundle-circle-full:before {
    content: "\e905";
}
.js-bundle-circle-empty:before {
    content: "\e906";
}
.js-bundle-circle-x-full:before {
    content: "\e901";
}
.js-bundle-cabin-and-checked-bags:before {
    content: "\e902";
}
.js-bundle-circle-plus:before {
    content: "\e903";
}
.js-bundle-circle-x:before {
    content: "\e904";
}
.js-bundle-flexi-smart-no-border:before {
    content: "\e900";
}
