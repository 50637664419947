.profile-giftcard-widget {
    @apply relative;
    grid-area: giftcard;
    height: 264px;
}

.giftcard-widget-container {
    @apply absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 264px;
}

.giftcard-widget-content {
    @apply relative bg-white;
    height: 264px;
    border-radius: 12px;
}

.gcwc-header {
    @apply bg-be-red flex items-center justify-between font-emphasis text-white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-size: 20px;
    height: 42px;
    padding: 0 30px;

    span {
        @apply font-body;
        font-size: 12px;
        margin-right: 10px;
    }
}

.gcwc-logo {
    @apply font-emphasis flex items-center uppercase;
    height: 42px;
    font-size: 28px;
    padding-top: 5px;

    i {
        @apply relative;
        top: -4px;
        margin-right: 12px;
        font-size: 34px;
        font-weight: 500;
    }

    span {
        font-family: "ClanOT-Book", sans-serif;
        font-size: 26px;
        margin-right: 0;
    }
}

.gcwc-number {
    padding-top: 3px;
    @apply flex items-center;

    span {
        top: -2px;
        @apply relative;
    }
}

.gcwc-body {
    height: calc(100% - 42px);
    @apply w-full relative;

    a,
    a:focus,
    a:active {
        @apply relative flex items-center justify-center font-black font-body bg-be-blue text-white normal-case border border-be-blue border-solid;
        width: 160px;
        height: 30px;
        padding: 0 28px 0 10px;
        border-radius: 15px;
        letter-spacing: normal;
        margin: 0 auto;
        font-size: 14px;
        transition: all 0.25s ease-in-out;

        i {
            @apply absolute text-white;
            font-size: 20px;
            top: 4px;
            right: 3px;
            transition: all 0.25s ease-in-out;
        }

        &:not(.disabled):hover {
            @apply border border-be-blue border-solid bg-white text-be-blue;
            padding: 0 28px 0 10px;

            i {
                @apply text-be-blue;
            }
        }
    }
}

.gcwc-title {
    margin: 25px auto 20px;
    max-width: 386px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    @apply font-body font-semibold text-be-blue;
}

.gcwc-item {
    font-size: 12px;
    line-height: 14px;
    max-width: 455px;
    margin: 0 auto 15px;
    @apply text-be-blue font-body;
}

.gcwc-tabs {
    height: 57px;
    @apply flex items-end;

    span {
        width: calc(33.33% - 10px);
        margin-right: 10px;
        height: 38px;
        background: #e6e6e6;
        font-size: 16px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        @apply flex items-center justify-center font-body font-semibold text-be-gray-4 cursor-pointer;

        &.selected {
            @apply text-white bg-be-cyan-2 cursor-default;
        }

        &:hover {
            @apply text-white bg-be-cyan-2;
        }
    }
}

.gcwc-table-header {
    @apply text-be-blue flex w-full;
    background: #a7dce7;
    height: 30px;

    span {
        @apply font-body font-semibold flex items-center justify-center;
        font-size: 13px;
        width: 33%;
    }
}

.gcwc-scroller {
    @apply absolute;
    top: 86px;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    li {
        @apply bg-be-light-gray flex items-center w-full;
        height: 36px;

        &:nth-child(even) {
            background: #ddd;
        }

        i {
            @apply text-be-blue relative cursor-pointer;
            font-size: 16px;
            margin-left: 10px;
        }

        > span {
            @apply flex items-center font-body text-be-blue justify-center;
            width: 33%;
            font-size: 16px;
            border-right: 1px solid #ccc;

            &:last-child {
                @apply border-r-0;
            }
        }
    }

    button {
        @apply relative flex items-center justify-center font-body bg-be-blue text-white normal-case border border-be-blue border-solid;
        width: 114px;
        height: 23px;
        padding: 0 28px 0 10px;
        border-radius: 12px;
        letter-spacing: normal;
        font-size: 11px;

        i {
            @apply absolute text-white;
            font-size: 18px;
            top: 1px;
            right: 2px;
            margin: 0;
        }

        &:not(.disabled):hover {
            @apply border border-be-blue bg-white text-be-blue border-solid;
            padding: 0 28px 0 10px;

            i {
                @apply text-be-blue;
            }
        }
    }
}

.gcwc-tooltip {
    font-size: 12px;
    padding: 10px 20px;
    border-radius: 6px;
    transform: translate(30px, 135px) translateY(-50%);
    z-index: 10000000000;
    line-height: 1.5;
    @apply block absolute bg-be-blue text-white font-light font-body italic;

    &:after {
        @apply bg-be-blue absolute;
        content: "";
        width: 15px;
        height: 15px;
        left: -7px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
    }

    ul {
        padding: 0 0 0 32px;

        li {
            @apply relative;
            line-height: 1.5;

            &:after {
                @apply absolute;
                content: "\2714\fe0e";
                color: #49d682;
                font-size: 12px;
                top: 50%;
                transform: translateY(-50%);
                left: -15px;
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .gcwc-header {
        padding: 0 15px;
    }
}

@media #{$small} {
    .profile-giftcard-widget {
        height: 185px;
    }

    .giftcard-widget-container {
        height: 185px;
    }

    .giftcard-widget-content {
        height: 185px;
    }

    .gcwc-header {
        font-size: 13px;
        height: 26px;
        padding: 0 20px;

        span {
            font-size: 11px;
            margin-right: 5px;
        }
    }

    .gcwc-logo {
        height: 26px;
        font-size: 17px;
        padding-top: 3px;

        span {
            font-size: 16px;
            margin-right: 0;
        }
    }

    .gcwc-body {
        height: calc(100% - 26px);

        a {
            width: 130px;
            height: 22px;
            padding: 0 28px 0 10px;
            font-size: 10px;
            margin-top: 5px;

            i {
                font-size: 15px;
                top: 3px;
                right: 3px;
            }

            &:not(.disabled):hover {
                padding: 0 28px 0 10px;
            }
        }

        &.portuguese {
            button {
                margin-top: 0;
            }

            .gcwc-title {
                margin: 5px auto;
            }

            .gcwc-item {
                margin: 0 auto 5px;
            }
        }
    }

    .gcwc-title {
        margin: 10px auto 10px;
        max-width: 286px;
        font-size: 14px;
        line-height: 16px;
    }

    .gcwc-item {
        font-size: 11px;
        line-height: 13px;
        max-width: 325px;
        margin: 0 auto 7px;
    }

    .gcwc-tabs {
        height: 35px;

        span {
            width: calc(33.33% - 5px);
            margin-right: 0;
            height: 23px;
            font-size: 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            @apply border-t border-r border-l border-solid border-be-gray-6;

            &.selected {
                @apply border-t border-r border-l border-solid border-be-cyan-2;
            }
        }
    }

    .gcwc-table-header {
        height: 20px;

        span {
            font-size: 10px;
        }
    }

    .gcwc-scroller {
        top: 55px;

        li {
            height: 22px;

            i {
                font-size: 10px;
                margin-left: 7px;
            }

            > span {
                font-size: 10px;
            }
        }

        button {
            width: 70px;
            height: 14px;
            padding: 0 18px 0 10px;
            font-size: 10px;
            margin: 0;

            i {
                font-size: 10px;
                top: 1px;
                right: 2px;
            }

            &:not(.disabled):hover {
                padding: 0 18px 0 10px;
            }
        }
    }

    .gcwc-tooltip {
        transform: translate(23px, 87px) translateY(-50%);
        font-size: 10px;
        padding: 7px 15px;

        ul {
            padding: 0 0 0 20px;

            li {
                &:after {
                    font-size: 10px;
                    left: -15px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .profile-giftcard-widget {
        height: 153px;

        &.portuguese {
            height: 180px;

            .giftcard-widget-container {
                height: 180px;
            }

            .giftcard-widget-content {
                height: 180px;
            }
        }
    }

    .giftcard-widget-container {
        height: 153px;
    }

    .giftcard-widget-content {
        height: 153px;
    }

    .gcwc-header {
        font-size: 12px;
        height: 22px;
        padding: 0 20px;

        span {
            font-size: 11px;
            margin-right: 5px;
        }
    }

    .gcwc-logo {
        height: 22px;
        font-size: 14px;
        padding-top: 3px;

        span {
            font-size: 13px;
            margin-right: 0;
        }
    }

    .gcwc-body {
        height: calc(100% - 22px);

        a {
            width: 130px;
            height: 22px;
            padding: 0 28px 0 10px;
            font-size: 10px;
            margin-top: 5px;

            i {
                font-size: 15px;
                top: 3px;
                right: 3px;
            }

            &:not(.disabled):hover {
                padding: 0 28px 0 10px;
            }
        }
    }

    .gcwc-title {
        margin: 5px auto;
        max-width: 250px;
        font-size: 12px;
        line-height: 13px;
    }

    .gcwc-item {
        font-size: 10px;
        line-height: 12px;
        max-width: 297px;
        margin: 0 auto 7px;
    }

    .gcwc-tabs {
        height: 40px;

        span {
            width: calc(33.33% - 5px);
            margin-right: 0;
            height: 29px;
            font-size: 10px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            @apply border-t border-r border-l border-solid border-be-gray-6;

            &.selected {
                @apply border-t border-r border-l border-solid border-be-cyan-2;
            }
        }
    }

    .gcwc-table-header {
        height: 17px;

        span {
            font-size: 10px;
        }
    }

    .gcwc-scroller {
        top: 57px;

        li {
            height: 18px;

            i {
                font-size: 8px;
                margin-left: 5px;
            }

            > span {
                font-size: 10px;
            }
        }

        button {
            width: 63px;
            height: 14px;
            padding: 0 15px 0 10px;
            font-size: 10px;
            margin: 0;

            i {
                font-size: 10px;
                top: 1px;
                right: 2px;
            }

            &:not(.disabled):hover {
                padding: 0 15px 0 10px;
            }
        }
    }

    .gcwc-tooltip {
        transform: translate(23px, 83px) translateY(-50%);
        font-size: 10px;
        padding: 5px 15px;

        ul {
            padding: 0 0 0 20px;

            li {
                line-height: 1.4;

                &:after {
                    font-size: 10px;
                    left: -15px;
                }
            }
        }
    }
}
