.result-wait-wrapper {
    margin: 0 auto 0;
    padding-top: 50px;
    width: 50%;
    min-width: 400px;
    max-width: 800px;

    .title-icon {
        font-size: 35px;
    }

    .inner-deep-box {
        padding: 15px;
    }

    .inner-box {
        margin-bottom: 0;
        background: #fff;

        header {
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $gray-light;
        }
    }
}

.condensed {
    .result-wait-wrapper {
        margin-top: 0;
    }
}

.transaction-table-container {
    display: flex;
    align-items: flex-start;
    justify-content: stretch;

    > div {
        width: 100%;
    }

    &.download {
        display: block;

        > div {
            width: 600px;
            max-width: 100%;

            .payment-confirm-table {
                td {
                    width: 300px;
                }
            }
        }
    }
}

.payment-confirm-table {
    td {
        padding: 5px 10px;
        color: $brand-secondary;
        font-weight: 300;
        font-size: 18px;
        vertical-align: top;

        span {
            color: $brand-secondary;
            font-weight: 300;
            font-size: 18px;
        }

        &:first-child {
            font-weight: 600;
            font-size: $font-size-medium;
        }
    }
}

.transaction-button-container {
    @apply flex justify-end;
    padding: 20px;

    .rounded-primary-btn {
        margin-left: $common-gap;
    }

    .rounded-transaction-btn {
        &:first-child {
            margin-right: $common-gap;

            &.deposit {
                @apply mr-0;
            }
        }
    }

    .rounded-secondary-btn {
        &.deposit {
            @apply mr-0;
        }
    }
}

.main-title {
    &.fare-lock {
        &.download {
            max-width: 580px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    .result-wait-wrapper {
        margin: $js-header-height auto 0;
        padding-top: 50px;
        width: 95%;
    }

    .transaction-button-container {
        margin: 0 auto;
        width: 95%;
    }
}

@media #{$xsmall} {
    .result-wait-wrapper {
        margin: $js-header-height auto 0;
        padding-top: 50px;
        width: 95%;
    }
}

@media #{$small-down} {
    .printed-area {
        width: 100%;

        &.download {
            width: 200%;
            max-width: 1000px;
        }
    }
    .transaction-container {
        margin: 20px 25px;
        width: calc(100% - 50px);
    }

    .transaction-table-container {
        display: block;

        &.download {
            width: unset;
            max-width: unset;

            > div {
                width: 100%;
                max-width: unset;

                .payment-confirm-table {
                    width: 100%;
                    max-width: unset;

                    td {
                        width: 50%;
                        max-width: unset;
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    .transaction-button-container {
        margin: 0 auto;
        width: 95%;
        display: block;

        a {
            width: 100%;
            display: flex;
            margin-bottom: $common-gap;

            &.rounded-primary-btn {
                margin-left: 0;
                justify-content: center;
            }
        }
    }

    .payment-confirm-table {
        width: 100%;

        td {
            padding: 5px 0;
            width: 40%;

            &:first-child {
                width: 60%;
            }
        }
    }
}

@media #{$xsmall} {
    .printed-area {
        width: 100%;

        &.download {
            width: 200%;
            max-width: 1000px;
        }
    }
    .transaction-container {
        margin: 20px 25px;
        width: calc(100% - 50px);
    }

    .transaction-table-container {
        display: block;

        &.download {
            width: unset;
            max-width: unset;

            > div {
                width: 100%;
                max-width: unset;

                .payment-confirm-table {
                    width: 100%;
                    max-width: unset;

                    td {
                        width: 50%;
                        max-width: unset;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}

@media print {
    .main-header,
    .transaction-button-container {
        display: none;
    }
}
