.i2-itinerary-section {
    &.i2-farelock-section {
        @apply font-body text-be-blue;
        padding: 25px 40px 25px 20px;
        font-size: 24px;
        line-height: 1.4;

        span {
            @apply font-body font-black text-be-blue;
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .i2-itinerary-section {
        &.i2-farelock-section {
            padding: 20px 32px 20px 15px;
            font-size: 18px;
        }
    }
}

@media #{$small} {
    .i2-itinerary-section {
        &.i2-farelock-section {
            padding: 10px 15px;
            font-size: 12px;
        }
    }
}

@media #{$xsmall} {
    .i2-itinerary-section {
        &.i2-farelock-section {
            padding: 10px 15px;
            font-size: 12px;
        }
    }
}
