#mainContentPayment {
    .giftcard-button-container {
        @apply flex items-center justify-center m-auto h-full;
        padding-top: 19px;

        .rounded-primary-btn {
            @apply w-full;
        }
    }
}
