@import "../../jetsmart-theme/variables";
/* changed */
$rp-grid-heading-background-color: #6d829a;
$rp-grid-color: #6d829a;
$rp-grid-border-color: #6d829a;
$rp-grid-col-padding-h: 5px;
$rp-grid-col-padding-v: 15px;
$rp-grid-font-size: 14px;
$rp-grid-header-height: 55px;
$rp-grid-accent-bg: $brand-secondary;
$rp-grid-accent-border-color: $brand-secondary;
$rp-grid-filter-icon-width: 0;

$rp-grid-margin: 0;
$rp-grid-input-width: 250px;
$rp-grid-heading-font-weight: bold;
$rp-grid-filter-editor-width: 160px;

$rp-grid-row-bg: #fff;
$rp-grid-alternate-row-bg: #f5f5f5;
$rp-grid-accent-color: #fff;
$rp-grid-link-color: $brand-primary;
$rp-grid-modal-border-color: #333;
$rp-grid-modal-shadow: none;

$rp-grid-filter-top: 35px;
$rp-grid-filter-input-padding: 10px 15px;
$rp-grid-filter-left: -1px;
$rp-grid-filter-border-color: #333;
$rp-grid-filter-input-font-size: $rp-grid-font-size;

$rp-erase-field-padding: 5px;
$rp-erase-field-margin: 10px 0 0 10px;
$rp-erase-field-border-radius: 2px;
$rp-erase-field-color: #fff;
$rp-erase-field-bg-color: #333;

$rp-submit-field-padding: 5px;
$rp-submit-field-margin: 10px 10px 0 0;
$rp-submit-field-border-radius: 2px;
$rp-submit-field-color: #333;
$rp-submit-field-bg-color: $brand-primary;

$rp-grid-link-color: $brand-primary;
$rp-grid-hover-link-color: lighten($brand-primary, 10%);

$rp-local-menu-border-radius: 3px;

$datepicker-top: 21px;

// @import "../../stylesheets/_mixins.scss";
// @import "../../stylesheets/_variables.scss";

// @import "local-menu.tag.scss";

my-trips-grid-container {
    table {
        td,
        th {
            &:nth-child(1),
            &:nth-child(2) {
                width: 120px;
                font-weight: 600;
                padding-right: 0;
            }

            &:nth-child(4),
            &:nth-child(5) {
                width: 110px;
            }
        }
    }
}

users-grid-container,
pending-reservations-grid-container,
confirmed-reservations-grid-container,
my-trips-grid-container {
    display: block;
    position: relative;
    margin: $rp-grid-margin;
    width: 100%;
    height: 100%;
    color: $rp-grid-color;
    font-size: $rp-grid-font-size;

    .status {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 5px;
        color: $brand-secondary;

        .tooltip-container {
            position: absolute;
            top: 5px;
            left: 5px;

            &:hover {
                .tooltip {
                    display: block;
                }
            }
        }

        .tooltip {
            display: none;
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 10px;
            background: pink;
        }
    }

    .js-select-arrow {
        &:after {
            content: "\25BC";
            display: block;
            position: absolute;
            top: 50%;
            right: 10px;
            font-size: 12px;
            transform: translateY(-50%);
            color: $gray-light;
        }
    }

    row-button,
    nav {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .fa {
            font-weight: 400;
        }

        .btn,
        .btn-primary {
            color: $brand-secondary;
            background: transparent;
            -webkit-appearance: none;
            border: none;
            font-size: 20px;
            margin: 0;
            padding: 0;
        }

        button,
        .btn {
            &:not(:disabled) {
                &:hover,
                &:focus {
                    border: none;
                    color: $brand-primary;
                    background: transparent;
                }
            }
        }
    }

    .hidden {
        display: none;
    }

    .grid-controls-top {
        height: $rp-grid-header-height;
        display: flex;
        align-items: center;
        justify-content: space-between;

        select {
            padding: 5px;
            border-radius: $border-radius-sm;
        }

        div {
            &:nth-child(3) {
                display: none;
            }
        }
    }

    input[name="freeTextFilter"] {
        display: inline-block;
        width: $rp-grid-input-width;
        padding: $rp-grid-filter-input-padding;
        border: 1px solid $rp-grid-filter-border-color;
        line-height: normal;
        font-size: $rp-grid-font-size;
    }

    select {
        display: inline-block;
        width: auto;
    }

    table {
        width: 100%;
        table-layout: fixed;

        thead {
            background: $rp-grid-heading-background-color;
            color: #fff;
        }

        tbody {
            tr {
                background: $rp-grid-row-bg;

                &:nth-child(even) {
                    background-color: $rp-grid-alternate-row-bg;
                }
            }
        }

        th,
        td {
            position: relative;
            border: 1px solid $rp-grid-border-color;
            height: unset;

            &.selection-column {
                width: 25px;
                padding: $rp-grid-col-padding-v $rp-grid-col-padding-h;

                [type="checkbox"]:not(:checked),
                [type="checkbox"]:checked {
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                    appearance: checkbox;
                    -webkit-appearance: checkbox;
                }
            }

            &.secondary {
                width: 100px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                width: 120px;
                font-weight: 600;
                padding-right: 0;
            }

            &:nth-child(5),
            &:nth-child(6) {
                width: 110px;
            }

            &:nth-child(7) {
                width: 40px;
                border-right: none;
                padding-right: 10px;

                nav {
                    justify-content: flex-end;
                }
            }

            &:nth-child(9) {
                width: 40px;
                border-left: none;
            }
        }

        th {
            height: $rp-grid-header-height;
            padding-right: $rp-grid-filter-icon-width;
            text-align: center;
            font-weight: $rp-grid-heading-font-weight;
            user-select: none;
            vertical-align: middle;

            &:last-child {
                border-right: none;
            }

            &.no-order {
                cursor: default;
            }

            &.local-menu-column {
                width: 40px;
                padding: $rp-grid-col-padding-v $rp-grid-col-padding-h;
            }

            &.active {
                background: $rp-grid-accent-bg;
                color: $rp-grid-accent-color;

                .header-filter,
                .header-orderer {
                    background: $rp-grid-accent-bg;
                    color: $rp-grid-accent-color;
                }

                .header-orderer {
                    &.inactive {
                        cursor: default;

                        &:hover {
                            background-color: $rp-grid-accent-bg;
                            color: #fff;
                        }
                    }
                }
            }
        }

        td {
            padding: $rp-grid-col-padding-v $rp-grid-col-padding-h;
            border: 1px solid $rp-grid-border-color;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            a {
                color: $rp-grid-color;
            }

            &.local-menu-trigger {
                position: relative;
                overflow: visible;
                cursor: pointer;
                text-align: center;

                &:hover {
                    background-color: $rp-grid-accent-bg;
                    color: $rp-grid-accent-color;
                }
            }
        }

        &.isHoverable {
            tbody {
                tr {
                    &:hover {
                        background-color: $rp-grid-accent-bg;
                        color: $rp-grid-accent-color;

                        td {
                            a,
                            rp-raw,
                            .status,
                            i {
                                color: $rp-grid-accent-color;
                            }
                        }
                    }
                }
            }
        }
    }

    .header-orderer {
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // line-height: $rp-grid-header-height;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // overflow: hidden;
        color: #fff;
        background: $rp-grid-heading-background-color;
        cursor: pointer;

        &:hover {
            color: #fff;
            background: darken($rp-grid-heading-background-color, 5%);
        }

        &.inactive {
            cursor: default;

            &:hover {
                color: #fff;
                background: $rp-grid-heading-background-color;
            }
        }
    }

    .header-filter {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: $rp-grid-filter-icon-width;
        right: 0;
        top: 0;
        bottom: 0;
        color: $rp-grid-color;
        background: $rp-grid-heading-background-color;
        cursor: pointer;

        &:hover {
            color: $rp-grid-color;
            background: darken($rp-grid-heading-background-color, 5%);
        }

        &.open {
            background: $rp-grid-accent-bg;
            color: $rp-grid-accent-color;
        }

        .filter-editor {
            display: block;
            position: absolute;
            top: 32px;
            left: 0;
            width: $rp-grid-filter-editor-width;
            transform: translateX(-50%) translateX($rp-grid-filter-icon-width);
            padding: $rp-grid-col-padding-v $rp-grid-col-padding-h;
            border: 1px solid $rp-grid-modal-border-color;
            border-radius: $rp-local-menu-border-radius;
            color: $rp-grid-color;
            background: $rp-grid-row-bg;
            z-index: 1;
            box-shadow: $rp-grid-modal-shadow;

            &:after,
            &:before {
                position: absolute;
                right: 50%;
                bottom: 100%;
                width: 0;
                height: 0;
                content: " ";
                border: solid transparent;
                pointer-events: none;
            }

            &:after {
                margin-left: -10px;
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: #ffffff;
                border-width: 10px;
            }

            &:before {
                margin-left: -11px;
                border-color: rgba(194, 225, 245, 0);
                border-bottom-color: $rp-grid-modal-border-color;
                border-width: 11px;
            }

            rp-input,
            rp-datepicker,
            rp-select {
                display: inline-block;

                input {
                    padding: 5px 10px;
                    width: 100%;
                    line-height: normal;
                    font-size: $rp-grid-filter-input-font-size;
                }
            }

            rp-input[name="minval"],
            rp-input[name="maxval"] {
                width: 100%;

                input {
                    width: 100%;
                }
            }

            rp-datepicker[name="mindate"],
            rp-datepicker[name="maxdate"] {
                width: 100%;

                input {
                    width: 100%;
                }
            }

            rp-input[name="maxval"],
            rp-datepicker[name="maxdate"] {
                margin-top: 10px;
            }

            input::-ms-clear {
                display: none;
            }

            .datepicker-container {
                top: $datepicker-top;
            }

            .filter-buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;

                > span {
                    flex: 1;
                }
            }

            .erase-field {
                display: inline-block;
                margin: $rp-erase-field-margin;
                padding: $rp-erase-field-padding;
                border-radius: $rp-erase-field-border-radius;
                border: 1px solid $rp-grid-filter-border-color;
                color: $rp-erase-field-color;
                background-color: $rp-erase-field-bg-color;
                text-align: center;
                cursor: pointer;

                &:hover {
                    background-color: $rp-grid-accent-bg;
                    color: $rp-grid-accent-color;
                }
            }

            .submit-field {
                display: inline-block;
                margin: $rp-submit-field-margin;
                padding: $rp-submit-field-padding;
                border-radius: $rp-submit-field-border-radius;
                border: 1px solid $rp-grid-filter-border-color;
                color: $rp-submit-field-color;
                background-color: $rp-submit-field-bg-color;
                text-align: center;
                cursor: pointer;

                &:hover {
                    background-color: $rp-grid-accent-bg;
                    color: $rp-grid-accent-color;
                }
            }
        }
    }

    .grid-controls-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .result-number {
            white-space: nowrap;
        }
    }

    paginator {
        display: block;
        user-select: none;
        cursor: default;

        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .page-navigator {
            display: block;

            li {
                display: inline-block;
                padding: 10px;
                border: 1px solid transparent;
                cursor: pointer;

                &:hover {
                    background-color: $rp-grid-accent-bg;
                    color: $rp-grid-accent-color;
                }

                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    opacity: 0.33;
                }

                &.current-page {
                    border-color: $rp-grid-accent-border-color;
                    border-radius: $border-radius-sm;
                }
            }
        }
    }

    .view-toggler {
        display: none;
        padding: 0.25em 0;
        color: $rp-grid-color;
        font-weight: bold;

        span {
            display: inline-block;
            padding: 0.5em 0;
            font-size: 0.85em;
            text-transform: uppercase;
            white-space: nowrap;
        }

        &.clickable {
            span {
                color: $rp-grid-link-color;
                cursor: pointer;

                &:hover {
                    color: darken($rp-grid-link-color, 10%);
                }
            }
        }
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }
}

users-grid-container {
    table {
        td,
        th {
            &:nth-child(1),
            &:nth-child(2) {
                width: 100px;
                font-weight: 600;
                padding-right: 0;
            }

            &:nth-child(4) {
                width: 110px;
            }

            &:nth-child(5) {
                width: 60px;
                font-weight: 400;
            }

            &:nth-child(6) {
                width: 20px;
                border-right: none;
                padding-right: 10px;

                nav {
                    justify-content: flex-end;
                }
            }

            &:nth-child(7) {
                border-left: none;
                width: 20px;
                border-right: 1px solid #6d829a;

                nav {
                    justify-content: center;
                }
            }
        }
    }
}
.ac-tooltip-balloon {
    &.tooltip-danger {
        background: $brand-primary;
        color: #fff;

        &:before {
            border: 10px solid $brand-primary;
            border-color: $brand-primary transparent transparent;
        }

        &.down {
            &:before {
                border-color: transparent transparent $brand-primary;
            }
        }

        &.left {
            &:before {
                border-color: transparent transparent transparent $brand-primary;
            }
        }
    }
}

pending-reservations-grid-container {
    .danger {
        i {
            color: $brand-primary;
            font-size: 17px;
            position: relative;
            top: 2px;
            margin-left: 2px;
        }
    }

    table {
        th {
            text-align: center;
        }

        td,
        th {
            width: unset;

            &:nth-child(6),
            &:nth-child(7) {
                width: unset;
                font-weight: 400;
            }

            &:nth-child(2) {
                width: 120px;
                font-weight: 400;
            }

            &:nth-child(3) {
                width: 130px;
                font-weight: 400;
            }

            &:nth-child(4) {
                width: 80px;
                font-weight: 400;
            }

            &:nth-child(5) {
                width: 130px;
                font-weight: 400;
            }

            &:nth-child(8) {
                padding: 0;
                width: 66px;
                text-align: center;
                font-weight: 400;

                .secondary-btn {
                    margin: 0;
                    padding: 0 10px;
                    font-weight: 400;
                }
            }

            &:nth-child(9) {
                padding: 5px;
                width: 40px;
                font-weight: 400;
            }
        }
    }

    .no-pay {
        table {
            td,
            th {
                &:nth-child(7) {
                    display: none;
                }
            }
        }
    }
}

confirmed-reservations-grid-container {
    table {
        th {
            text-align: center;
        }

        td,
        th {
            width: unset;

            &:nth-child(6),
            &:nth-child(7) {
                width: unset;
                font-weight: 400;
            }

            &:nth-child(2) {
                width: 120px;
                font-weight: 400;
            }

            &:nth-child(3) {
                width: 130px;
                font-weight: 400;
            }

            &:nth-child(4) {
                width: 80px;
                font-weight: 400;
            }

            &:nth-child(5) {
                width: 133px;
                font-weight: 400;
            }

            &:nth-child(8) {
                border-right: 1px solid $rp-grid-border-color;
                width: 140px;

                a {
                    color: $brand-secondary;
                    cursor: default;

                    i {
                        cursor: pointer;

                        &:hover {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }
}

my-trips-grid-container {
    height: unset;

    .grid-controls-top,
    .grid-controls-bottom {
        display: none;
    }

    table {
        td,
        th {
            padding: 10px;
            font-weight: 300 !important;

            &:not(:first-child) {
                border-left: none;
            }

            &:not(:last-child) {
                border-right: none;
            }

            &:nth-child(5) {
                width: 50px;
            }
        }
    }
}

.expandable-column {
    cursor: default;
    user-select: none;
    font-weight: 600;

    .first-name,
    .full-name {
        font-weight: 600;
    }

    .js-add {
        color: $brand-tertiary;
    }

    .js-close {
        color: $membership-accent-color;
    }

    .js-icon {
        position: relative;
        left: -5px;
    }

    .email,
    .role {
        margin: 10px 0 10px 19px;
    }

    .role {
        margin-bottom: 0;
    }

    input:checked {
        ~ .full-name {
            .js-add {
                display: none;
            }
        }
    }

    input:not(:checked) {
        ~ .full-name {
            .js-close {
                display: none;
            }
        }

        ~ .email,
        ~ .role {
            display: none;
        }
    }
}

.grid-controls-bottom {
    margin-top: 10px;
    margin-bottom: 20px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .cug {
        users-grid-container {
            table {
                td,
                th {
                    &:nth-child(4) {
                        width: 160px;
                    }

                    &:nth-child(5) {
                        width: 80px;
                    }
                }
            }
        }
    }
}

@media #{$medium} {
    .cug {
        users-grid-container {
            table {
                td,
                th {
                    &:nth-child(1) {
                        display: none;
                    }
                }
            }
        }

        pending-reservations-grid-container {
            table {
                td,
                th {
                    padding: 10px 2px;

                    &:nth-child(3),
                    &:nth-child(5),
                    &:nth-child(7) {
                        display: none;
                    }

                    &:nth-child(8) {
                        width: 70px;
                    }

                    &:nth-child(9) {
                        width: 30px;
                    }
                }
            }
        }

        confirmed-reservations-grid-container {
            table {
                td,
                th {
                    padding: 10px 2px;

                    &:nth-child(3),
                    &:nth-child(5),
                    &:nth-child(6) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media #{$small} {
    .cug {
        users-grid-container {
            table {
                td,
                th {
                    &:nth-child(1),
                    &:nth-child(3),
                    &:nth-child(4) {
                        display: none;
                    }

                    &:nth-child(5) {
                        width: 60px;
                    }

                    &:nth-child(6),
                    &:nth-child(7) {
                        width: 20px;
                    }
                }
            }
        }

        pending-reservations-grid-container {
            table {
                td,
                th {
                    padding: 10px 2px;

                    &:nth-child(3),
                    &:nth-child(5),
                    &:nth-child(7) {
                        display: none;
                    }

                    &:nth-child(8) {
                        width: 70px;
                    }

                    &:nth-child(9) {
                        width: 30px;
                    }
                }
            }

            .grid-controls-bottom {
                display: block;
            }

            .result-number {
                margin: 20px;
            }
        }

        confirmed-reservations-grid-container {
            table {
                td,
                th {
                    padding: 10px 2px;

                    &:nth-child(3),
                    &:nth-child(5),
                    &:nth-child(6) {
                        display: none;
                    }
                }
            }

            .grid-controls-bottom {
                display: block;
            }

            .result-number {
                margin: 20px;
            }
        }
    }

    my-trips-grid-container {
        table {
            td,
            th {
                font-size: 11px;
                padding: 6px 2px;

                &:nth-child(1) {
                    width: 60px;
                }

                &:nth-child(2) {
                    width: 70px;
                }

                &:nth-child(3) {
                    width: 100px;
                }

                &:nth-child(4) {
                    width: 60px;
                }

                &:nth-child(5) {
                    width: 30px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .reservation-hidden-content {
        display: none;
    }

    label {
        h3 {
            &.opener {
                margin: 0;
                padding: 10px 0;
                font-size: 20px;

                span {
                    font-size: 12px;
                    font-weight: 600;
                    color: $brand-primary;
                    white-space: nowrap;
                }

                &.push-down {
                    margin-top: 50px;
                }
            }
        }

        h4 {
            &.opener {
                margin: 0;
                padding: 10px 0;
                font-size: 16px;
                color: $brand-secondary;
                font-weight: 600;

                span {
                    font-size: 12px;
                    font-weight: 600;
                    color: $brand-primary;
                }
            }
        }
    }

    .cug-border-box {
        .closer {
            margin: 0 50px 20px 0;
            height: 1px;
            border-bottom: 1px solid $brand-secondary;
            position: relative;
            cursor: pointer;

            i {
                position: absolute;
                top: -9px;
                right: -30px;
                border-radius: 50%;
                border: 1px solid $brand-secondary;
                color: $brand-secondary;
                padding: 4px;
                font-size: 8px;
                transform: rotate(90deg);

                &.mirrored {
                    transform: rotate(270deg);
                }
            }
        }
    }

    cug-reservations {
        .cug-border-box {
            border: none;

            &.padded {
                padding: 20px 10px 0;
            }
        }

        .cug-box {
            h1 {
                margin-left: 10px;
                margin-top: 10px;
            }
        }
    }

    .cug {
        users-grid-container {
            table {
                td,
                th {
                    padding: 10px 5px;

                    &:nth-child(1),
                    &:nth-child(3),
                    &:nth-child(4) {
                        display: none;
                    }

                    &:nth-child(5) {
                        width: 60px;
                    }

                    &:nth-child(6),
                    &:nth-child(7) {
                        width: 20px;
                    }
                }
            }

            .expandable-column {
                .js-icon {
                    left: 0;
                }
            }

            .grid-controls-bottom {
                display: block;
            }

            .result-number {
                margin: 40px;
            }
        }

        pending-reservations-grid-container {
            table {
                td,
                th {
                    padding: 10px 2px;

                    &:nth-child(2) {
                        width: 80px;
                    }

                    &:nth-child(3),
                    &:nth-child(5),
                    &:nth-child(7) {
                        display: none;
                    }

                    &:nth-child(8) {
                        width: 70px;
                    }

                    &:nth-child(9) {
                        width: 30px;
                    }
                }
            }

            .grid-controls-bottom {
                display: block;
            }

            .result-number {
                margin: 40px;
            }
        }

        confirmed-reservations-grid-container {
            table {
                td,
                th {
                    padding: 10px 2px;

                    &:nth-child(2) {
                        width: 80px;
                    }

                    &:nth-child(3),
                    &:nth-child(5),
                    &:nth-child(6) {
                        display: none;
                    }
                }
            }

            .grid-controls-bottom {
                display: block;
            }

            .result-number {
                margin: 40px;
            }
        }
    }

    my-trips-grid-container {
        table {
            td,
            th {
                font-size: 11px;
                padding: 6px 2px;

                &:nth-child(1) {
                    width: 60px;
                }

                &:nth-child(2) {
                    width: 70px;
                }

                &:nth-child(3) {
                    width: 100px;
                }

                &:nth-child(4) {
                    width: 60px;
                }

                &:nth-child(5) {
                    width: 30px;
                }
            }
        }
    }
}
