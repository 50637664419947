.family-member-error-modal {
    @apply relative;

    &.modal-content {
        @apply p-0 leading-normal;
        width: 700px;
        max-width: 95%;
        height: 400px;
        max-height: 95%;
    }

    .modal-header {
        @apply bg-be-blue text-white relative font-body font-black flex items-center justify-center;
        height: 136px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-size: 40px;

        &:after {
            @apply absolute bottom-0 left-0 right-0 bg-be-red;
            content: "";
            height: 5px;
        }

        .modal-header-closing {
            @apply absolute flex items-center justify-center rounded-full border-solid border-white text-white p-0 m-0 z-10 bg-transparent;
            width: 40px;
            height: 40px;
            border-width: 3px;
            font-size: 30px;
            top: 20px;
            right: 20px;

            &:hover {
                @apply bg-white text-be-blue;
            }
        }

        i {
            @apply text-be-cyan-2 font-normal absolute vertical-center;
            left: 2.5rem;
            font-size: 80px;
        }
    }

    .modal-body {
        padding: 35px 70px 0;
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .family-member-error-modal {
        .modal-header {
            height: 136px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 40px;

            &:after {
                height: 5px;
            }

            .modal-header-closing {
                width: 40px;
                height: 40px;
                border-width: 3px;
                font-size: 30px;
                top: 20px;
                right: 20px;
            }

            i {
                font-size: 80px;
            }
        }

        .modal-body {
            padding: 35px 70px 0;
        }
    }
}

@media #{$small} {
    .family-member-error-modal {
        &.modal-content {
            height: 355px;
        }

        .modal-header {
            height: 122px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 30px;

            &:after {
                height: 5px;
            }

            .modal-header-closing {
                width: 40px;
                height: 40px;
                border-width: 3px;
                font-size: 30px;
                top: 20px;
                right: 20px;
            }

            i {
                font-size: 60px;
            }
        }

        .modal-body {
            padding: 35px 25px 0;
        }
    }
}

@media #{$xsmall} {
    .family-member-error-modal {
        &.modal-content {
            height: 430px;
        }

        .modal-header {
            @apply leading-none;
            height: 122px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 24px;
            padding: 0 60px 0 120px;

            &:after {
                height: 5px;
            }

            .modal-header-closing {
                width: 35px;
                height: 35px;
                border-width: 2px;
                font-size: 26px;
                top: 10px;
                right: 10px;
            }
            i {
                font-size: 60px;
            }
        }

        .modal-body {
            padding: 15px 15px 0;
        }
    }
}
