.profile-banco-estado-ad {
    @apply bg-white text-be-blue flex items-center justify-around;
    grid-area: advert;
    height: 162px;
    border-radius: 12px;

    img {
        width: 80px;
    }

    a,
    a:focus,
    a:active {
        @apply relative flex items-center justify-center font-body bg-be-blue text-white normal-case border border-be-blue border-solid;
        width: 146px;
        height: 30px;
        padding: 0 28px 0 10px;
        border-radius: 15px;
        letter-spacing: normal;
        font-size: 14px;
        transition: all 0.25s ease-in-out;

        i {
            @apply absolute text-white;
            font-size: 20px;
            top: 4px;
            right: 5px;
            margin: 0;
            transition: all 0.25s ease-in-out;
        }

        &:not(.disabled):hover {
            @apply border border-be-blue border-solid bg-white text-be-blue;
            padding: 0 28px 0 10px;

            i {
                @apply text-be-blue;
            }
        }
    }
}

.bead-info {
    @apply text-be-blue;
    max-width: 610px;
    text-align: center;

    span {
        @apply font-body;
        font-size: 27px;
        line-height: 33px;

        &:first-child {
            font-size: 24px;
            line-height: 29px;
        }

        &:last-child {
            span {
                @apply font-emphasis;
            }
        }
    }
}

.profile-destinations-ad {
    @apply bg-white text-be-blue flex items-center justify-around relative;
    grid-area: advert;
    height: 162px;
    border-radius: 12px;

    img {
        width: 99px;
    }

    button {
        @apply relative flex items-center justify-center font-body font-black bg-be-blue text-white normal-case border border-be-blue border-solid;
        width: 170px;
        height: 30px;
        padding: 0 28px 0 10px;
        border-radius: 15px;
        letter-spacing: normal;
        font-size: 14px;
        transition: all 0.25s ease-in-out;

        i {
            @apply absolute text-white;
            font-size: 20px;
            top: 4px;
            right: 5px;
            margin: 0;
            transition: all 0.25s ease-in-out;
        }

        &:not(.disabled):hover {
            @apply border border-be-blue border-solid bg-white text-be-blue;
            padding: 0 28px 0 10px;

            i {
                @apply text-be-blue;
            }
        }
    }
}

.pdad-info {
    @apply text-be-blue font-body;
    max-width: 420px;
    text-align: center;
    font-size: 24px;
    line-height: 29px;

    span {
        font-family: "ClanOT-Black", sans-serif;
        font-size: 27px;
        line-height: 33px;
    }

    &.portuguese {
        max-width: 460px;
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    .profile-banco-estado-ad {
        height: 100px;

        img {
            width: 54px;
        }

        a {
            height: 22px;
            width: 135px;
            font-size: 12px;

            i {
                font-size: 15px;
                top: 3px;
                right: 3px;
            }
        }
    }

    .bead-info {
        max-width: 400px;

        span {
            font-size: 13px;
            line-height: 18px;

            &:first-child {
                font-size: 14px;
                line-height: 20px;
            }

            &:last-child {
                span {
                    font-size: 14px;
                }
            }
        }
    }

    .profile-destinations-ad {
        height: 99px;

        img {
            width: 56px;
        }

        button {
            width: 152px;
            height: 30px;
            padding: 0 28px 0 10px;
            font-size: 14px;

            i {
                font-size: 20px;
                top: 4px;
                right: 5px;
            }

            &:not(.disabled):hover {
                padding: 0 28px 0 10px;
            }
        }
    }

    .pdad-info {
        max-width: 350px;
        font-size: 20px;
        line-height: 24px;

        span {
            font-size: 22px;
            line-height: 26px;
        }
    }
}

@media #{$xsmall} {
    .profile-banco-estado-ad {
        @apply flex-col bg-be-orange justify-center;
        height: 148px;

        a {
            @apply text-be-orange bg-white border-white;
            margin-top: 20px;
            height: 22px;
            width: 130px;
            font-size: 12px;

            i {
                @apply text-be-orange;
                font-size: 15px;
                top: 3px;
                right: 3px;
            }

            &:hover {
                @apply text-white bg-be-orange border-white;

                i {
                    @apply text-white;
                }
            }
        }
    }

    .bead-info {
        max-width: 260px;
        text-align: left;

        span {
            @apply block text-white;
            font-size: 14px;
            line-height: 17px;

            &:first-child {
                font-size: 14px;
                line-height: 17px;
            }

            span {
                @apply inline;
            }
        }
    }

    .profile-destinations-ad {
        @apply flex-col justify-center;
        height: 148px;

        img {
            @apply absolute;
            width: 38px;
            right: 22px;
            bottom: 26px;
        }

        button {
            width: 125px;
            height: 22px;
            padding: 0 28px 0 10px;
            font-size: 12px;

            i {
                font-size: 14px;
                top: 4px;
                right: 5px;
            }

            &:not(.disabled):hover {
                padding: 0 28px 0 10px;
            }
        }
    }

    .pdad-info {
        max-width: 230px;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 25px;

        span {
            font-size: 17px;
            line-height: 20px;
        }
    }
}
