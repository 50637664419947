.flight-move-modal-content {
    .modal-header {
        @apply relative flex justify-between rounded-t-lg bg-brand-secondary;
        padding: 25px 70px 25px 45px;

        .flight-move-modal-title {
            @apply font-semibold leading-8 text-white;
            max-width: 50%;
            font-size: 28px;
        }

        .flight-move-modal-plane {
            @apply flex items-center justify-center;
            max-width: 50%;

            img {
                @apply block h-auto w-full;
            }
        }

        .flight-move-modal-close {
            @apply absolute flex cursor-pointer items-center justify-center rounded-full border-2 border-solid border-white bg-transparent p-0 text-white;
            right: 20px;
            top: 20px;
            font-size: 32px;
            width: 35px;
            height: 35px;

            &:hover {
                @apply border-gray-400 text-gray-400;
            }
        }
    }

    .modal-body {
        padding: 30px;

        .description-container {
            @apply m-auto mb-6 px-4 leading-6 text-brand-secondary;
            text-align: center;
            font-size: 22px;
        }

        .primary-modal-button-container {
            @apply mb-1 mt-6 flex justify-center;

            .rounded-primary-btn {
                @apply w-64;
            }
        }

        .move-modal-dont-want {
            @apply mt-6 block cursor-pointer text-gray-500 underline;
            font-size: 15px;
            text-align: center;

            &:hover {
                @apply text-be-red;
            }
        }
    }
}

@media #{$small} {
    .flight-move-modal-content {
        .modal-header {
            .flight-move-modal-title {
                font-size: 24px;
            }
        }

        .modal-body {
            padding: 30px 0;

            .description-container {
                padding: 0 30px;
            }

            .primary-modal-button-container {
                .rounded-primary-btn {
                    @apply w-full rounded-none py-4;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .flight-move-modal-content {
        .modal-header {
            padding: 25px 55px 25px 25px;

            .flight-move-modal-title {
                @apply leading-7;
                font-size: 20px;
            }
        }

        .modal-body {
            padding: 15px 0 20px;

            .description-container {
                @apply mb-3 leading-5;
                padding: 0 25px;
                font-size: 18px;
            }

            .primary-modal-button-container {
                @apply mt-0;

                .rounded-primary-btn {
                    @apply mt-0 w-full rounded-none py-4;
                    font-size: 20px;
                }
            }
        }
    }
}
