@mixin arrow($color) {
    @apply absolute w-0 h-0 pointer-events-none;
    content: "";
    top: 50%;
    transform: translateY(-50%) translateY(3px);
    transform-origin: top center;
    transition: all 0.2s ease-in-out;
    right: 10px;
    border: 6px solid transparent;
    border-color: $color transparent transparent transparent;
}

dc-select {
    .dc-select {
        @apply relative select-none block w-full;

        .select-input {
            @apply bg-white text-gray-800 py-2 px-3 border border-solid border-gray-600 cursor-pointer w-full;

            &.readonly {
                @apply bg-gray-200;
            }

            &.invalid {
                @apply bg-red-200;
            }
        }

        .select-button {
            @apply bg-brand-primary text-white relative py-3 pl-4 pr-8 leading-none rounded;

            &:after {
                @include arrow(#ffffff);
            }
        }

        .select-items {
            @apply absolute bg-white left-0 overflow-auto;
            min-width: 100%;
            max-height: 400px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
            top: 100%;
            z-index: 99;

            .item {
                @apply bg-white text-gray-800 py-2 px-3 border border-transparent cursor-pointer select-none whitespace-nowrap;

                &:hover,
                &.keyboard-selected {
                    @apply bg-brand-primary text-white;
                }

                &.selected {
                    @apply bg-brand-primary text-white;
                }
            }
        }

        &.button-select {
            @apply inline-block w-auto;

            &.opened {
                button {
                    &:after {
                        transform: translateY(-50%) translateY(9px) rotate(-180deg);
                    }
                }
            }

            .select-items {
                @apply right-auto rounded min-w-full;
                transform: translateY(3px);
            }
        }

        &.dropdown-select {
            &:after {
                @include arrow(#666666);
            }
        }
    }

    &.sm {
        @apply w-12;

        .dc-select {
            .select-input {
                @apply text-sm py-1 px-2;
            }

            &:after {
                right: 3px;
            }
        }
    }

    .validation-result {
        @apply text-red-500 text-xs font-bold absolute w-full left-0 bg-white;
        top: 100%;
        line-height: 1.2;
    }
}
