.i2-itinerary-section {
    &.i2-header {
        @apply flex items-center justify-between;
        grid-area: header;
        padding: 25px 40px 25px 20px;

        i {
            @apply text-be-cyan-2;
            font-size: 60px;
            margin-right: 15px;
        }
    }
}

.i2-header-title {
    @apply font-emphasis italic text-be-blue;
    font-size: 33px;
    line-height: 40px;
}

.i2-header-subtitle {
    @apply font-body text-be-blue;
    font-size: 15px;
}

.i2-header-pax-nr {
    @apply font-emphasis text-be-blue leading-none relative;
    font-size: 73px;
    margin-right: 10px;
    top: 7px;
}

.i2-header-pax {
    @apply font-body text-be-blue;
    font-size: 32px;
    margin-top: 11px;
}

.i2-header-pnr-title {
    font-family: "ClanOT-News", sans-serif;
    font-size: 17px;
    color: #999999;
    text-wrap: none;
}

.i2-header-pnr {
    @apply text-be-blue font-emphasis;
    font-size: 27px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-itinerary-section {
        &.i2-header {
            i {
                font-size: 55px;
                margin-right: 12px;
            }
        }
    }

    .i2-header-title {
        font-size: 30px;
        line-height: 36px;
    }

    .i2-header-subtitle {
        font-size: 14px;
    }

    .i2-header-pax-nr {
        font-size: 66px;
        margin-right: 8px;
        top: 8px;
    }

    .i2-header-pax {
        font-size: 28px;
        margin-top: 10px;
    }

    .i2-header-pnr-title {
        @apply whitespace-nowrap;
        font-size: 13px;
        text-align: center;
    }

    .i2-header-pnr {
        font-size: 25px;
    }
}

@media #{$medium} {
    .i2-itinerary-section {
        &.i2-header {
            padding: 20px 32px 20px 15px;

            i {
                font-size: 48px;
                margin-right: 8px;
            }
        }
    }

    .i2-header-title {
        font-size: 24px;
        line-height: 26px;
    }

    .i2-header-subtitle {
        font-size: 11px;
        line-height: 1.2;
    }

    .i2-header-pax-nr {
        font-size: 58px;
        margin-right: 2px;
        top: 8px;
    }

    .i2-header-pax {
        font-size: 24px;
        margin-top: 10px;
    }

    .i2-header-pnr-title {
        @apply whitespace-nowrap;
        font-size: 12px;
        text-align: center;
    }

    .i2-header-pnr {
        font-size: 22px;
    }
}

@media #{$small} {
    .i2-itinerary-section {
        &.i2-header {
            padding: 10px 15px;

            i {
                font-size: 35px;
                margin-right: 8px;
            }
        }
    }

    .i2-header-title {
        font-size: 20px;
        line-height: 23px;
    }

    .i2-header-subtitle {
        font-size: 10px;
        line-height: 12px;
        max-width: 300px;
    }

    .i2-header-pax-nr {
        font-size: 43px;
        margin-right: 2px;
        top: 5px;
    }

    .i2-header-pax {
        font-size: 19px;
        margin-top: 4px;
    }

    .i2-header-pnr-title {
        @apply whitespace-nowrap;
        font-size: 11px;
        text-align: center;
    }

    .i2-header-pnr {
        font-size: 16px;
    }
}

@media #{$xsmall} {
    .i2-itinerary-section {
        &.i2-header {
            @apply flex-col;
            padding: 10px 15px;

            i {
                font-size: 35px;
                margin-right: 8px;
            }
        }
    }

    .i2-header-title {
        font-size: 15px;
        line-height: 18px;
    }

    .i2-header-subtitle {
        font-size: 9px;
        line-height: 11px;
    }

    .i2-header-pax-nr {
        font-size: 39px;
        margin-right: 2px;
        top: 5px;
    }

    .i2-header-pax {
        font-size: 17px;
        margin-top: 4px;
    }

    .i2-header-pnr-title {
        @apply whitespace-nowrap;
        font-size: 10px;
        text-align: center;
    }

    .i2-header-pnr {
        font-size: 14px;
    }
}
