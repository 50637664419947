.be-credit-shell {
    @apply border border-be-blue border-solid bg-white;
    border-radius: 10px;
    transition: all 0.25s ease-in-out;

    .bcs-header {
        @apply flex items-center;
        padding: 20px;
    }

    .bcs-logo-caption {
        @apply font-emphasis text-be-gray-4 flex items-center;
        font-size: 22px;
        transition: all 0.25s ease-in-out;
        width: 50%;
    }

    img {
        width: 50px;
        margin-right: 20px;
    }

    .bcs-total {
        @apply font-body text-be-blue block leading-none;
        font-size: 16px;
        margin-bottom: 5px;
        transition: all 0.25s ease-in-out;
    }

    .bcs-total-container {
        @apply flex flex-col items-center justify-center;
        width: 25%;
    }

    .bcs-total-amount {
        @apply font-emphasis text-be-blue block leading-none;
        font-size: 38px;
        transition: all 0.25s ease-in-out;
    }

    .bcs-button-container {
        @apply flex flex-col items-center justify-center;
        width: 25%;
    }

    .bcs-use {
        @apply relative border-2 border-be-blue border-solid bg-be-blue text-white flex items-center justify-center font-body font-black normal-case;
        width: 170px;
        height: 38px;
        border-radius: 19px;
        font-size: 18px;
        transition: all 0.25s ease-in-out;
        padding: 0 16px 0 0;
        margin-bottom: 5px;
        letter-spacing: normal;

        &:after {
            @apply absolute leading-none font-normal text-white;
            font-family: "jetsmart-v2" !important;
            top: 5px;
            right: 15px;
            content: "\e9ba"; // DEVNOTE Might change if icons change
            font-size: 23px;
            transition: all 0.25s ease-in-out;
        }

        &:hover {
            @apply text-be-blue bg-white border-2 border-be-blue border-solid;
            letter-spacing: normal;

            &:after {
                @apply text-be-blue;
            }
        }
    }

    .bcs-used {
        @apply relative border-2 border-be-blue border-solid bg-be-blue text-white flex items-center justify-center font-body font-black normal-case cursor-default;
        width: 170px;
        height: 38px;
        border-radius: 19px;
        font-size: 18px;
        transition: all 0.25s ease-in-out;
        padding: 0 16px 0 0;
        margin-bottom: 5px;
        letter-spacing: normal;

        &:after {
            @apply absolute leading-none font-normal text-white;
            font-family: "jetsmart-v2" !important;
            top: 5px;
            right: 15px;
            content: "\e93a"; // DEVNOTE Might change if icons change
            font-size: 23px;
            transition: all 0.25s ease-in-out;
        }

        &:hover,
        &:active,
        &:focus {
            @apply relative border-2 border-be-blue border-solid bg-be-blue text-white flex items-center justify-center font-body font-black normal-case cursor-default;
        }
    }

    .bcs-details {
        @apply font-body font-semibold text-be-gray-4 underline cursor-pointer block leading-none;
        font-size: 14px;
        transition: all 0.25s ease-in-out;

        &:hover {
            @apply no-underline text-be-blue;
        }
    }

    .bcs-detail-list {
        @apply overflow-hidden bg-white;
        max-height: 0;
        transition: all 0.25s ease-in-out;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        i {
            @apply text-be-cyan-2;
            font-size: 42px;
            margin-right: 20px;
        }

        li {
            &:not(:last-child) {
                border-bottom: 1px solid #cccccc;
            }
        }
    }

    .bcsdl-title {
        @apply text-be-blue;
        font-family: "ClanOT-Medium", sans-serif;
        font-size: 21px;
    }

    .bcsdl-body {
        @apply font-body text-be-blue font-normal leading-none;
        font-size: 16px;
    }

    .bcsdl-amount {
        @apply font-emphasis text-be-blue;
        font-size: 30px;
    }

    &.open {
        @apply bg-be-gray-3 text-white border-be-gray-4;

        .bcs-details,
        .bcs-total,
        .bcs-total-amount,
        .bcs-logo-caption {
            @apply text-white;
        }

        .bcs-detail-list {
            max-height: 1000px;
            padding: 5px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$medium} {
    .be-credit-shell {
        img {
            width: 40px;
            margin-right: 10px;
        }

        .bcs-logo-caption {
            @apply leading-none;
            font-size: 20px;
        }

        .bcs-total-amount {
            font-size: 20px;
        }

        .bcs-use {
            width: 130px;

            &:after {
                right: 8px;
            }
        }
    }
}

@media #{$small} {
    .be-credit-shell {
        img {
            width: 30px;
            margin-right: 15px;
        }

        .bcs-header {
            padding: 10px;
        }

        .bcs-logo-caption {
            font-size: 14px;
            width: 40%;
        }

        .bcs-total-container {
            width: 30%;
        }

        .bcs-total {
            font-size: 10px;
        }

        .bcs-total-amount {
            font-size: 24px;
        }

        .bcs-button-container {
            width: 30%;
        }

        .bcs-use {
            width: 105px;
            height: 22px;
            font-size: 12px;

            &:after {
                font-size: 17px;
                top: 1px;
                right: 5px;
            }
        }

        .bcs-used {
            width: 133px;
            height: 31px;
            font-size: 15px;

            &:after {
                font-size: 19px;
                top: 3px;
                right: 5px;
            }
        }

        .bcs-details {
            font-size: 10px;
        }

        .be-credit-shell {
            .bcs-detail-list {
                i {
                    font-size: 28px;
                    margin-right: 7px;
                }
            }
        }

        .bcsdl-title {
            font-size: 13px;
            line-height: 1;
        }

        .bcsdl-body {
            font-size: 10px;
        }

        .bcsdl-amount {
            font-size: 19px;
        }
    }
}

@media #{$xsmall} {
    .be-credit-shell {
        @apply relative bg-be-gray-7;

        &.open {
            .bcs-header {
                height: 116px;
            }

            .bcs-total-container {
                top: 40px;
                left: 10px;
                transform: translateX(0);
            }

            .bcs-total {
                font-size: 14px;
                margin-right: 10px;
                margin-bottom: 3px;
            }

            .bcs-total-amount {
                font-size: 18px;
            }

            .bcs-button-container {
                top: 72px;
            }
        }

        img {
            display: none;
        }

        .bcs-header {
            height: 145px;
            padding: 10px;
            transition: all 0.15s ease-in-out;
        }

        .bcs-logo-caption {
            @apply absolute text-white whitespace-nowrap;
            top: 14px;
            left: 10px;
            font-size: 14px;
            width: unset;
        }

        .bcs-details {
            @apply text-white no-underline absolute;
            top: 10px;
            right: 10px;
            font-size: 20px;

            &:focus,
            &:hover {
                @apply text-white;
            }

            &.open {
                transform: rotate(-90deg);
            }
        }

        .bcs-button-container {
            @apply absolute;
            transform: translateX(-50%);
            top: 103px;
            left: 50%;
            transition: all 0.15s ease-in-out;
        }

        .bcs-use,
        .bcs-used {
            @apply p-0;
            width: 170px;
            height: 33px;
            font-size: 17px;
            transition: all 0.25s ease-in-out;

            &:after {
                font-size: 25px;
                top: 3px;
                right: 3px;
                transition: all 0.25s ease-in-out;
            }
        }

        .bcs-total-container {
            @apply absolute flex-row justify-start whitespace-nowrap;
            top: 55px;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.25s ease-in-out;
            width: unset;
        }

        .bcs-total {
            @apply text-white;
            font-size: 18px;
            margin-right: 15px;
            transition: all 0.25s ease-in-out;
        }
        .bcs-total-amount {
            @apply text-white;
            font-size: 32px;
            transition: all 0.25s ease-in-out;
        }

        .bcs-detail-list {
            i {
                font-size: 26px;
                margin-right: 7px;
            }
        }

        .bcsdl-title {
            @apply leading-none;
            font-size: 13px;
        }

        .bcsdl-body {
            font-size: 10px;
        }

        .bcsdl-amount {
            font-size: 18px;
            margin-top: 10px;
        }
    }
}
