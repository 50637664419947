@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.cb-title {
    position: relative;
    text-align: left;
    font-size: $font-size-small;

    &.cb-amount {
        text-align: right;
        margin-right: $common-gap;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a,
    a:visited,
    a:active {
        color: $brand-tertiary;
        font-size: $font-size-small;
        text-decoration: underline;
    }

    a:hover {
        color: $brand-secondary;
        text-decoration: none;
    }
}

.mdl-checkbox-wrapper {
    min-height: 40px;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: $border-radius-lg;
    user-select: none;

    .mdl-checkbox,
    .mdl-checkbox.is-upgraded,
    .mdl-checkbox.is-checked {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 44px;
        width: 100%;
        margin: 17px 0 0;
        padding: 0 0 0 30px;
        background: white;
        vertical-align: middle;
        font-weight: bold;
        z-index: 0;
        cursor: pointer;

        .mdl-checkbox__label {
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;
            width: 100%;
            margin: 0;
            padding-left: 0;
            font-size: $font-size-medium;
            user-select: none;
            cursor: pointer;
        }

        .mdl-checkbox__focus-helper,
        .mdl-checkbox__box-outline,
        .mdl-checkbox__ripple-container {
            box-sizing: border-box;
            display: inline-block;
            position: absolute;
            top: 12px;
            height: 20px;
            width: 20px;
            padding: 10px;
            float: none;
            font-weight: bold;
            font-size: 15px;
            border-radius: $border-radius-sm;
            background-color: transparent;
            cursor: pointer;
        }

        .mdl-checkbox__focus-helper {
            left: 0;
            border-color: $brand-secondary;
        }

        .mdl-checkbox__box-outline {
            left: 0;
            margin: 0;
            border: 1px solid rgba(0, 0, 0, 0.54);
            overflow: hidden;
            z-index: 2;
            cursor: pointer;
        }

        .mdl-checkbox__ripple-container {
            position: relative;
            left: -10px;
            border-color: $brand-secondary;
            overflow: hidden;
            -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
            mask-image: -webkit-radial-gradient(circle, #fff, #000);
            z-index: 2;
            cursor: pointer;
        }

        .mdl-checkbox__tick-outline {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            float: none;
            padding: 10px;
            background: transparent;
            font-weight: bold;
            font-size: 15px;
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            border-radius: $border-radius-sm;
            border-color: $brand-secondary;
        }

        &.checkbox-loader {
            .mdl-checkbox__tick-outline {
                display: none;
            }

            .mdl-checkbox__box-outline {
                &:after {
                    content: "";
                    box-sizing: border-box;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -5px;
                    margin-left: -5px;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    border-top: 2px solid $checkbox-after-border-color;
                    border-right: 2px solid transparent;
                    animation: spinner 0.6s linear infinite;
                }
            }
        }
    }

    .mdl-checkbox {
        &.is-checked {
            .mdl-checkbox__tick-outline {
                background: $brand-tertiary
                    url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCjwvc3R5bGU+DQo8cG9seWxpbmUgY2xhc3M9InN0MCIgcG9pbnRzPSIzMS42LDE1LjYgMTcuNywyOS41IDExLDIyLjggIi8+DQo8L3N2Zz4NCg==");
            }
        }
    }

    &.seatmap {
        padding: 0 8px;

        .mdl-checkbox {
            height: 50px;
            width: auto;
            margin: 0;
            padding: 0 0 0 24px;

            .mdl-checkbox__label {
                justify-content: space-between;
                padding-left: 25px;
                line-height: 1.2;
            }

            .mdl-checkbox__focus-helper,
            .mdl-checkbox__box-outline,
            .mdl-checkbox__ripple-container,
            .mdl-checkbox__tick-outline {
                top: 5px;
                width: 40px;
                height: 40px;
                padding: 15px;
                border-radius: $border-radius-lg;
                border-color: $brand-tertiary;
            }

            .mdl-checkbox__box-outline {
                border-style: solid;
                border-width: 2px;
            }

            .mdl-checkbox__ripple-container {
                top: -6px;
            }

            .mdl-checkbox__tick-outline {
                top: -3px;
                left: -2px;
            }
        }
    }

    &.checkin-type {
        min-height: 75px;
        margin: 12px 15px 0;
        padding-left: 25px;
        border: 1px solid $brand-secondary;
        background: #fff;

        .mdl-checkbox {
            display: inline-block;
            padding: 0 0 0 24px;

            .mdl-checkbox__label {
                justify-content: space-between;
                padding-left: 25px;
                line-height: 1.2;
                width: unset;
            }

            .mdl-checkbox__focus-helper,
            .mdl-checkbox__box-outline,
            .mdl-checkbox__ripple-container,
            .mdl-checkbox__tick-outline {
                width: 34px;
                height: 34px;
                padding: 15px;
                border-radius: $border-radius-lg;
                border-color: $brand-tertiary;
            }

            .mdl-checkbox__focus-helper {
                top: 3px;
            }

            .mdl-checkbox__box-outline {
                top: 3px;
                border-style: solid;
                border-width: 2px;
            }

            .mdl-checkbox__ripple-container {
                top: -6px;
            }

            .mdl-checkbox__tick-outline {
                top: -3px;
                left: -2px;
            }
        }
    }

    &.payment-contact {
        @apply inline-block;

        .mdl-checkbox {
            margin-top: 0;
            background: transparent;
        }
    }

    &.cug {
        .mdl-checkbox {
            margin-top: 0;
        }

        .cb-title {
            font-weight: 600;
            color: $brand-secondary;
            font-size: $font-size-medium;
        }
    }

    &.commission {
        margin-bottom: 20px;
        padding-left: 10px;
        border: 1px solid $brand-secondary;
        border-radius: $border-radius-sm;
        background: #fff;

        .mdl-checkbox {
            height: 44px;
            margin-top: 0;
            border-radius: $border-radius-sm;
        }
    }

    &.security {
        margin-bottom: 20px;
        padding-left: 10px;
        border: 1px solid $brand-secondary;
        border-radius: $border-radius-sm;
        background: #fff;

        .mdl-checkbox {
            height: 44px;
            margin-top: 0;
            border-radius: $border-radius-sm;
        }

        .mdl-checkbox__label {
            line-height: 1.4;
        }

        .cb-title {
            font-weight: 600;
            color: $brand-secondary;
        }
    }

    a {
        color: $brand-tertiary;

        &:hover {
            color: $brand-primary;
        }
    }

    &.change {
        margin-top: 25px;
    }
}

@media #{$small} {
    .mdl-checkbox-wrapper {
        &.checkin-type {
            min-height: unset;
            padding-left: 10px;

            .mdl-checkbox {
                &.mdl-js-checkbox {
                    &.is-upgraded {
                        margin-top: 4px;
                        margin-bottom: 2px;
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .terms-container {
        &.desktop-container {
            display: block;
            width: 100%;
        }
    }

    .chkbox-btn-wrapper {
        &.payment {
            display: block;
            width: 90%;
            margin: 10px auto;

            &.terms {
                display: block;
                width: 90%;
                margin: 20px auto;
            }
        }
    }
}
