.modal {
    &.primary-modal {
        .modal-content {
            &.booking-modal-content {
                &.resend-itinerary-report-modal-content {
                    .modal-header {
                        @apply flex items-center justify-center bg-transparent pb-0;

                        i {
                            @apply m-0 text-brand-tertiary;
                            font-size: 86px;
                        }

                        .modal-header-closing {
                            @apply absolute flex items-center justify-center rounded-full border-none font-body font-black text-white;
                            right: 15px;
                            top: 30px;
                            width: 30px;
                            height: 30px;
                            padding: 0 0 1px 1px;
                            font-size: 24px;
                            background: #828282;
                            letter-spacing: normal;

                            &:hover {
                                background: #484848;
                            }
                        }
                    }

                    .modal-body {
                        @apply pt-0;
                    }
                }
            }
        }
    }
}
