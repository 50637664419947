$loader-color: $brand-primary;

#mainContentWrapper {
    position: relative;

    &.push-down {
        margin-top: 120px;
    }

    &.expanded {
        min-height: 75vh;
    }

    &.content-push-down {
        margin-top: 80px;
    }
}

.post-commit-header {
    color: $brand-secondary;
    font-weight: 900;
    font-size: 20px;
    margin: 20px 0;
}

.sidebar-total-loader {
    position: relative;
    display: block;
    width: 15px;
    height: 15px;
    float: right;
    top: 7px;
    left: -25px;

    &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
        margin-left: 0;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border-bottom: 2px solid #fff;
        border-left: 2px solid transparent;
        animation: spinner 1s linear infinite;
    }

    &:after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -5px;
        margin-left: -5px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border-top: 2px solid #fff;
        border-right: 2px solid transparent;
        animation: spinner 1s linear infinite;
    }
}

.loader-overlay,
.loader-overlay-color {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    border-radius: $border-radius-lg;

    &.no-round {
        border-radius: 0;
    }

    &.inline {
        position: relative;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        display: block;
        width: 150px;
        height: 150px;
        margin: 20px auto 0;
    }
}

.loader-overlay-color {
    background-color: rgba(#fff, 0.75);
}

.loader-container {
    position: absolute;
    width: 150px;
    bottom: 0;
    height: 150px;
    padding: 30px;
    text-align: center;
    left: 0;
    margin: auto;
    right: 0;
    top: 0;
}

.loader-line {
    box-sizing: border-box;
    height: 49px;
    left: 25px;
    top: 25px;
    overflow: hidden;
    position: absolute;
    @include animation(cssload-spin 2300ms cubic-bezier(.175, .885, .32, 1.275) infinite);
    @include transform-origin(50% 100%);
    width: 97px;
}

.loader-line-wrap {
    border: 4px solid transparent;
    border-radius: 100%;
    box-sizing: border-box;
    height: 97px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 97px;
}

.loader-line:nth-child(1) {
    @include animation-delay(-57.5ms);
}

.loader-line:nth-child(2) {
    @include animation-delay(-115ms);
}

.loader-line:nth-child(3) {
    @include animation-delay(-172.5ms);
}

.loader-line:nth-child(4) {
    @include animation-delay(-230ms);
}

.loader-line:nth-child(5) {
    @include animation-delay(-287.5ms);
}

.loader-line:nth-child(1) .loader-line-wrap {
    border-color: $loader-color;
    height: 88px;
    width: 88px;
    top: 7px;
}

.loader-line:nth-child(2) .loader-line-wrap {
    border-color: $loader-color;
    height: 74px;
    width: 74px;
    top: 14px;
}

.loader-line:nth-child(3) .loader-line-wrap {
    border-color: $loader-color;
    height: 60px;
    width: 60px;
    top: 20px;
}

.loader-line:nth-child(4) .loader-line-wrap {
    border-color: $loader-color;
    height: 47px;
    width: 47px;
    top: 27px;
}

.loader-line:nth-child(5) .loader-line-wrap {
    border-color: $loader-color;
    height: 33px;
    width: 33px;
    top: 34px;
}

@-webkit-keyframes cssload-spin {

    0%,
    15% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes cssload-spin {

    0%,
    15% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.content-intro {
    width: 100%;
    padding-top: 100%;
}

.spa-content {
    position: relative;
    margin-top: 25px;
}

[ref="passengers"] {
    .spa-content {
        margin-top: 0;
    }
}

.faded {
    opacity: 0.25;
    pointer-events: none;
}

/* MEDIA QUERIES */

@media #{$small-down} {

    #mainContentWrapper {
        &.push-down {
            margin-top: 30px;
        }
    }

    .loader-overlay,
    .loader-overlay-color {
        &:not(.keep-mobile) {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &.inline {
            position: relative;
            top: unset;
            bottom: unset;
            left: unset;
            right: unset;
            display: block;
            width: 150px;
            height: 150px;
            margin: 20px auto 0;
        }
    }
}