.payment-confirmation-modal-terms-wrapper {
    @apply mx-auto border border-solid border-brand-secondary rounded-lg p-2;
    min-height: 64px;
    max-width: 460px;
    margin-top: 20px;

    .cb-title {
        @apply leading-4;

        a {
            @apply text-be-cyan-2;
        }
    }
}

@media #{$xsmall} {
    .payment-confirmation-modal-terms-wrapper {
        height: 110px;
    }
}
