.spa-modal {
    .modal-content {
        width: fit-content;
        max-width: 95%;
        padding: 0;

        .modal-header {
            img {
                @apply rounded-t-lg;
                max-width: 800px;
                max-height: 60vh;
            }

            .modal-header-closing {
                @apply absolute right-0 top-0 cursor-pointer rounded-full border-0 bg-be-blue p-0 font-normal leading-none text-white;
                transform: translate(50%, -50%);
                font-size: 36px;
                width: 40px;
                height: 40px;
                z-index: 1;
                letter-spacing: unset;

                &:hover {
                    @apply border-0 bg-black no-underline;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .spa-modal {
        .modal-content {
            .modal-header {
                img {
                    max-width: 300px;
                }

                .modal-header-closing {
                    @apply right-[20px] top-[20px];

                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}
