// This was provided by JS, we only renamed the classes, removed ids and turned to tailwind what was possible
.flight-option-order-container {
    @apply flex items-center justify-end;
    width: $fee-selector-width;
    margin: 10px auto;
    max-width: 95%;
}

@media #{$xsmall} {
    .flight-option-order-container {
        max-width: 90%;
    }
}

.flight-option-order-opener {
    @apply font-bold cursor-pointer relative whitespace-nowrap;
    color: #55595c;
    text-align: center;
}

.flight-option-order-selected-text {
    @apply text-brand-secondary font-bold;
}

.flight-option-order-arrow {
    @apply inline-block;
    transition: transform 0.3s ease;
    color: #b92234;

    &.flight-option-order-arrow-up {
        @apply transform rotate-180;
    }
}

.flight-option-order-dropdown {
    @apply absolute;
    z-index: 1;
    display: none;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    border-radius: 5px;
    left: 10%;

    &.show {
        @apply block;
    }
}

.flight-option-order-option {
    @apply whitespace-nowrap text-brand-secondary block cursor-pointer;
    padding: 12px 16px;

    &:hover {
        @apply bg-be-light-gray;
    }
}
