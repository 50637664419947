@import "./flight-variables";

.line-through {
    text-decoration: line-through;
}

.week-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s ease-in-out;

    &.open {
        max-height: 75px;
        margin: 40px 0 10px 0;
    }

    .scroll-week {
        @apply select-none;
        min-width: 35px;
        font-size: 35px;
        color: $brand-secondary;

        &:hover {
            color: $brand-tertiary;
        }
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 10px;
        width: $flight-widget-width;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0 40px;
            height: $flight-widget-height;
            border: 1px solid $gray-light;
            background: #fff;
            text-transform: capitalize;
            cursor: pointer;

            &:hover,
            &.selected {
                background: $brand-secondary;

                span {
                    color: #fff;
                }
            }

            &.disabled,
            &.selected {
                pointer-events: none;
                cursor: default;

                &:hover {
                    background: unset;
                    color: unset;
                }
            }

            span {
                display: block;
                color: $brand-secondary;
                font-size: $font-size-small;
            }
        }
    }
}

.scroll-left-icon {
    display: inline-block;
    transform: rotate(-180deg);
}

.fee-selector {
    .disabled {
        opacity: 1;
        pointer-events: none;
        color: $gray-light !important;
        border-color: $gray-light !important;

        span,
        .station {
            color: $gray-light !important;
            border-color: $gray-light !important;
        }
    }
}

.fee-selector,
.selected-flight {
    header {
        display: block;
        border: 1px solid $brand-secondary;
        height: $fee-selector-header-height;
        width: $fee-selector-width;
        margin: 0 auto 20px;
        background: #fff;

        label {
            height: $fee-selector-header-height;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;

            span {
                display: block;
                margin-top: 10px;
                font-size: 18px;
                color: $brand-secondary;
                font-weight: 600;
                text-transform: capitalize;
            }
        }
    }

    ul {
        margin-bottom: 40px;

        li {
            width: $fee-selector-width;
            max-width: 95%;
            margin: 0 auto;
            transition: all 0.5s;

            &.wide {
                width: $flight-widget-width;
                height: 150px;

                .fee-selector {
                    .itinerary {
                        height: 80px;
                    }
                }

                &.tall {
                    height: unset;
                }
            }
        }
    }

    .itinerary {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: $itinerary-height;

        .divider {
            border-bottom: 1px solid $gray-light;
            margin: 0 5px;
        }

        .plane-icon {
            font-size: 35px;
            color: $brand-secondary;
        }

        .help-icon {
            color: $brand-tertiary;
            cursor: pointer;

            &.darker {
                color: #00abc8;
            }
        }
    }

    .flight-tooltip {
        text-align: left;
        font-size: $font-size-medium;

        span {
            margin-top: 3px;
            font-size: $font-size-xsmall;
            display: block;
        }
    }
}

.direct-flight-info-container {
    @apply relative mx-2 flex justify-center;
    width: 20px;
    height: 20px;

    .ac-tooltip {
        width: 15px;
        height: 15px;
        background-color: #00abc8;
    }
}

.direct-flight-tooltip {
    @apply font-bold;
    text-align: left;
    font-size: $font-size-medium;

    span {
        @apply block;
        margin-top: 3px;
        font-size: $font-size-xsmall;
    }
}

.direct-flight-caption {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-style: italic;
    font-family: "Lato", sans-serif;
    color: #00abc8;
    font-weight: 600;
    white-space: nowrap;
}

.selected-flight {
    width: 100%;
    border: 1px solid $brand-secondary;
    border-radius: $border-radius-lg;
    background: #fff;
    position: relative;
    height: 100%;

    &.dc {
        @apply border-[#ffa400];
    }

    .direction {
        text-align: center;
        color: $brand-secondary;
        font-weight: 600;
        margin-top: 20px;
        font-size: 18px;
        line-height: 1;
        text-transform: capitalize;

        span {
            font-size: 18px;
            color: $brand-secondary;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    &.discount-club {
        border-color: $membership-accent-color;
    }
}

/* MEDIA QUERIES */
@media #{$large} {
    .week-selector {
        ul {
            li {
                padding: 0 25px;
            }
        }
    }
}

@media #{$medium} {
    .week-selector {
        ul {
            @apply flex;

            li {
                @apply mr-1 flex w-1/6 flex-col items-center justify-center whitespace-nowrap;
                padding: 0;
                font-size: 14px;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }

    .fee-selector {
        header {
            width: 95%;
        }

        ul {
            li {
                width: $fee-selector-medium-width;
            }
        }
    }

    .selected-flight {
        width: 100%;

        .itinerary {
            .divider {
                width: 35px;
            }
        }
    }
}

@media #{$small} {
    .week-selector {
        ul {
            @apply flex;

            li {
                @apply mr-1 flex w-1/6 flex-col items-center justify-center whitespace-nowrap;
                padding: 0;
                font-size: 14px;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .week-selector {
        ul {
            li {
                padding: 0 10px;

                &:hover {
                    @apply bg-white;
                    color: $brand-secondary;

                    span {
                        color: $brand-secondary;
                    }
                }

                span {
                    font-size: $font-size-xsmall;
                }
            }
        }
    }

    .fee-selector {
        z-index: 0;

        header {
            display: none;
        }

        .itinerary {
            height: 80px;
            position: unset;
            margin-top: -17px;
        }

        ul {
            li {
                height: unset;
                margin: 0 auto 20px;
                width: 90%;

                &.wide {
                    width: 90%;
                    height: 230px;

                    .selected-flight {
                        &.tall {
                            height: unset;
                        }
                    }
                }
            }

            li,
            li.wide {
                position: relative;
            }
        }
    }

    .mobile-direction {
        text-align: center;
        color: $brand-secondary;
        font-size: $font-size-medium;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 5px;
    }

    .selected-flight {
        .direction {
            margin-top: 10px;
        }
    }
}
