ac-cug-notification {
    @apply font-semibold font-body text-be-blue h-full flex items-center;
    font-size: 16px;
    transition: all 0.25s ease-in-out;

    i {
        font-size: 36px;
    }
}

.notification-container {
    @apply font-semibold font-body text-white h-full flex items-center relative cursor-pointer;
    width: 70px;
    font-size: 16px;

    p {
        margin-bottom: 0.5rem;
        text-align: justify;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.bell-container {
    @apply h-full w-full flex items-center justify-center;

    &:hover {
        @apply text-white;
        background: #204071;

        i {
            @apply text-white;
            font-size: 36px;
        }
    }
}

.js-cn-circle-bell {
    @apply relative;
}

.new-notification-sign {
    @apply flex items-center justify-center bg-cug-red absolute rounded-full;
    height: 17px;
    width: 16px;
    top: -0.3rem;
    right: -0.2rem;
}

.new-notification-count {
    @apply flex items-center justify-center font-semibold font-body text-white;
    font-size: 0.6rem;
    height: 14px;
    width: 12px;
}

@keyframes delay-overflow {
    from {
        @apply overflow-hidden;
    }
    to {
        overflow: unset;
    }
}

.notification-dropdown-container {
    @apply absolute bg-white overflow-hidden common-transition;
    top: 63px;
    right: 4px;
    width: 388px;
    height: 296px;
    max-height: 0px;
    z-index: 100000;

    &.open {
        max-height: 296px;
        animation: 0.5s delay-overflow forwards;
        border-radius: 10px;
        box-shadow: 0 0 0 1px #2c3438;
    }

    &::after {
        @apply absolute bg-white;
        content: "";
        top: -8px;
        right: 24px;
        z-index: 100001;
        width: 14px;
        height: 14px;
        border-top: 1px solid #2c3438;
        border-right: 1px solid #2c3438;
        transform: rotate(-45deg);
    }
}

.close-dropdown {
    display: none;
}

.notifications-box-label {
    @apply font-semibold font-body text-be-blue border-b-2 border-gray-300 border-solid w-full cursor-default;
    padding: 0.7rem 1rem 0.7rem 1rem;
}

.notifications-box-scroller {
    @apply absolute inset-0 bg-white;
    top: 40px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.notifications-box-container {
    @apply flex flex-col font-body cursor-default;
}

.notification-element-container {
    @apply w-full border-t-2 border-gray-300 border-solid flex flex-row;
    padding: 0.7rem 1rem 0.7rem 1rem;

    &:first-child {
        @apply border-none;
    }
}

.js-cn-circle-calendar,
.js-cn-circle-clock {
    font-size: 40px;
    color: #00b0ca !important;
}

.cug-flight-number {
    @apply font-bold;
    color: #00b0ca;
}

.notification-info-container {
    @apply text-cug-dark-gray;
    padding: 0 0.7rem 0 0.7rem;
}

.illustration-container {
    @apply flex flex-col w-full text-xs;
    color: #00b0ca;
    height: 64px;
}

.notifications-message-flight-cancelled {
    @apply font-bold;
    color: #aa272f !important;
}

.illustration-grid {
    @apply grid w-full items-end;

    grid-template-columns: 5fr 1fr 5fr;
    grid-template-rows: auto;

    span {
        &:nth-child(1) {
            justify-self: start;
        }
        &:nth-child(2) {
            justify-self: center;
        }
        &:nth-child(3) {
            justify-self: end;
        }
    }

    //IF FLIGHT CANCELLED
    &.notifications-flight-cancelled {
        .notification-dot {
            @apply bg-black;
        }
        .notification-line {
            @apply border-black;
        }
        span,
        .js-bag-plane-take-off {
            @apply text-black;
        }
    }
}

.illustration-row {
    @apply flex flex-row w-full;

    .js-bag-plane-take-off {
        padding-top: 3px;
        color: #00b0ca;
    }

    //IF FLIGHT CANCELLED
    &.notifications-flight-cancelled {
        .notification-dot {
            @apply bg-black;
        }
        .notification-line {
            @apply border-black;
        }
        span,
        .js-bag-plane-take-off {
            @apply text-black;
        }
    }
}

.notification-dot {
    @apply inline-block rounded-full;
    height: 10px;
    width: 10px;
    background-color: #00b0ca;
}

.notification-line {
    @apply w-full;
    height: 1px;
    border-bottom: 2px solid #00b0ca;
}

// MEDIA QUERIES

@media #{$small-down} {
    .notification-container {
        @apply text-be-blue;
        height: 60px;
    }
}

@media #{$xsmall} {
    .notification-dropdown-container {
        @apply overflow-hidden mx-auto fixed;
        width: 90%;
        max-width: 355px;
        right: 18px;

        &::after {
            right: 120px;
        }
    }

    .close-dropdown {
        @apply absolute flex items-center justify-center text-xl font-semibold font-body text-white rounded-full;
        top: 4px;
        right: 4px;
        height: 30px;
        width: 30px;
        background-color: #484848;
        opacity: 0.4;
        z-index: 100000;
    }

    .cug-layover {
        @apply inset-0 w-full h-full bg-black fixed common-transition;
        content: "";
        opacity: 0.3;
        z-index: 99999;
    }
}
