.pcra-login-info-modal {
    @apply p-0 leading-normal;
    width: 413px;
    height: 480px;
    max-width: 95%;
    max-height: 95%;
}

.pcra-login-info-content{
    @apply flex flex-col justify-between items-center text-be-blue font-medium;

    text-align: justify;
    padding: 20px 20px 4px;
    height: 336px;
}

//MEDIA QUERIES
@media #{$small} {
    .pcra-login-info-modal {
        height: 460px;
    }
}

@media #{$xsmall} {
    .pcra-login-info-modal {
        width: 335px;
        height: 480px;
    }

    .pcra-login-info-content{
        padding: 15px 15px 4px;
        height: 356px;
    }
}
