@import "./flight-variables";

.flight-calendar {
    width: $flight-widget-width;
    max-width: 95%;
    margin: 40px auto;
    position: relative;

    header {
        text-transform: uppercase;
        color: $brand-secondary;
        font-size: $font-size-medium;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .scroll-month {
        font-size: 35px;
        color: $brand-secondary;
        cursor: pointer;
        position: absolute;
        top: 40px;

        &:first-child {
            left: -50px;
        }

        &:last-child {
            right: -50px;
        }

        &:hover {
            color: $brand-tertiary;
        }
    }

    .month-tab {
        background: $flight-calendar-month-color;
        margin: 0 5px 5px 0;
        text-align: center;
        padding: 10px;
        text-transform: uppercase;
        font-size: $font-size-small;
        font-weight: 600;
        color: #fff;
        padding: 25px 0 10px;
        cursor: pointer;

        &:hover {
            background: $brand-secondary;
            color: #fff;
        }

        span {
            display: block;
            font-size: $font-size-xsmall;
        }

        &.selected {
            background: $brand-secondary;
            margin-bottom: 0;
            border-bottom: 5px solid $brand-secondary;

            &:hover {
                cursor: default;
            }
        }
    }

    .col-xs-1-7:last-child {
        .month-tab {
            margin-right: 0;
        }
    }

    .weekdays {
        background: $brand-secondary;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-xsmall;
        padding: 5px 0;
    }

    .week {
        .day {
            position: relative;
            display: flex;
            padding: 30px 0 25px;
            align-items: center;
            justify-content: center;
            border-left: 5px solid $gray-lightest;
            color: $flight-calendar-day-color;

            &.disabled {
                opacity: 1;
                cursor: default;
            }

            // &.selected {
            //     background: $brand-secondary;
            //     color: #fff;
            //     label {
            //         background: $brand-secondary;
            //     }
            //     .date {
            //         background: $membership-accent-color;
            //     }
            // }
            &.with-label {
                color: $brand-tertiary;
            }

            &:not(.unavailable) {
                border-bottom: 1px solid $flight-calendar-month-color;
            }

            &:not(.unavailable):not(.disabled) {
                cursor: pointer;

                &:hover {
                    background: $brand-secondary;
                    color: #fff;
                }
            }

            label {
                position: absolute;
                top: 2px;
                left: 17px;
                right: 2px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $brand-tertiary;
                font-style: italic;
                font-size: $font-size-xsmall;
                color: #fff;
                padding-right: 16px;
                white-space: nowrap;
            }

            .date {
                position: absolute;
                top: 2px;
                left: -5px;
                display: flex;
                width: 20px;
                height: 20px;
                align-items: center;
                justify-content: center;
                background: $flight-calendar-month-color;
                font-size: $font-size-xsmall;
                color: #fff;
            }
        }

        .calendar-selector {
            display: none;

            &:checked {
                +label {
                    .day {
                        background: $brand-secondary;
                        color: #fff;

                        label {
                            background: $brand-secondary;
                        }

                        .date {
                            background: $membership-accent-color;
                        }
                    }
                }
            }
        }
    }
}

label,
[type="radio"]:not(:checked)+label,
[type="radio"]:checked+label {
    &.non-pico {
        padding-left: 0;
        height: auto;
        line-height: unset;
        font-size: unset;
        display: block;

        &:after {
            display: none;
        }

        &:before {
            display: none;
        }
        &.disabled {
            cursor: default;
        }
    }
}

.calendar-button-container {
    @apply flex justify-end;
    width: $flight-widget-width;
    max-width: 95%;
    margin: 40px auto;
}

.calendar-legend {
    span {
        display: inline-block;
        border-left: 20px solid $brand-secondary;
        padding-left: 10px;
        font-style: italic;
        margin: 0 20px 0 10px;
        color: $brand-secondary;
        &:first-child {
            border-color: $brand-tertiary;
        }
    }
}

/* MEDIA QUERIES */
@media #{$large} {
    .flight-calendar {
        .scroll-month {
            &:first-child {
                left: -40px;
            }

            &:last-child {
                right: -40px;
            }
        }
    }
}

@media #{$medium} {
    .flight-calendar {
        .scroll-month {
            &:first-child {
                left: -40px;
            }

            &:last-child {
                right: -40px;
            }
        }

        .week {
            .day {
                label {
                    font-size: 11px;
                    white-space: nowrap;
                }
            }
        }
    }
}

@media #{$small} {
    .flight-calendar {
        max-width: 90%;

        .scroll-month {
            &:first-child {
                left: -35px;
            }

            &:last-child {
                right: -35px;
            }
        }

        .week {
            .day {
                label {
                    font-size: 10px;
                    white-space: nowrap;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .flight-calendar {
        max-width: 95%;

        .scroll-month {
            font-size: 25px;

            &:first-child {
                left: -25px;
            }

            &:last-child {
                right: -25px;
            }
        }

        .month-tab {
            font-size: $font-size-xsmall;
            padding: 5px 0;

            span {
                font-size: 10px;
            }
        }

        .week {
            .day {
                font-size: 8px;
                padding: 25px 0 7px;

                &.with-label {
                    .date {
                        background: $brand-tertiary;
                    }
                }

                .date {
                    font-size: 11px;
                }

                label {
                    display: none;
                }
            }
        }
    }
}