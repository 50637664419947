/* ------------------------------------------------------------- */

/* External webfonts-------------------------------------------- */

/* ------------------------------------------------------------- */

@font-face {
    font-family: "js-cug-icons";
    src: url("../fonts/js-cug-icons.eot?x0ah10"); //todo
    src: url("../fonts/js-cug-icons.eot?x0ah10#iefix") format("embedded-opentype"),
        url("../fonts/js-cug-icons.ttf?x0ah10") format("truetype"),
        url("../fonts/js-cug-icons.woff?x0ah10") format("woff"),
        url("../fonts/js-cug-icons.svg?x0ah10#js-cug-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.js-icon-cug {
    font-family: "js-cug-icons" !important;
    font-size: 32px;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.js-cug-big-group:before {
    content: "\e914";
}
.js-cug-edit:before {
    content: "\e900";
}
.js-cug-exclamation:before {
    content: "\e901";
}
.js-cug-eye:before {
    content: "\e902";
}
.js-cug-finger:before {
    content: "\e903";
}
.js-cug-globe:before {
    content: "\e904";
}
.js-cug-group:before {
    content: "\e905";
}
.js-cug-grouping:before {
    content: "\e906";
}
.js-cug-letter:before {
    content: "\e907";
}
.js-cug-lock:before {
    content: "\e908";
}
.js-cug-man-and-plane:before {
    content: "\e909";
}
.js-cug-man-and-plus:before {
    content: "\e90a";
}
.js-cug-man-and-star:before {
    content: "\e90b";
}
.js-cug-phone:before {
    content: "\e90c";
}
.js-cug-search:before {
    content: "\e90d";
}
.js-cug-tick:before {
    content: "\e90e";
}
.js-cug-user:before {
    content: "\e90f";
}
.js-cug-bin:before {
    content: "\e910";
}
.js-cug-chart:before {
    content: "\e911";
}
.js-cug-company:before {
    content: "\e912";
}
.js-cug-devolution:before {
    content: "\e913";
}
