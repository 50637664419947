.flight-move-result-banner {
    @apply bg-be-cyan-2 text-white rounded-xl flex justify-start leading-7 italic;
    padding: 20px 105px 20px 25px;
    font-family: "ClanOT-Medium", sans-serif;
    font-size: 24px;
    margin-bottom: 30px;

    .js-tick-broken-circle {
        @apply block mr-5;
        font-size: 60px;
    }
}

@media #{$small} {
    .flight-move-result-banner {
        @apply leading-6;
        padding: 20px 25px;
        font-size: 20px;

        .js-tick-broken-circle {
            @apply mr-4 font-semibold;
            font-size: 45px;
        }
    }
}

@media #{$xsmall} {
    .flight-move-result-banner {
        @apply leading-6 mx-auto;
        padding: 15px 20px;
        font-size: 18px;
        width: 90%;

        .js-tick-broken-circle {
            @apply mr-3 font-semibold;
            font-size: 40px;
        }
    }
}
