.i2-itinerary-section {
    &.i2-bancoestado {
        @apply border-0;
        border-radius: 22px;

        img {
            @apply block;
        }
    }
}

.i2-be-banner-title {
    @apply text-white uppercase;
    font-family: "ClanOT-Black", sans-serif;
    font-size: 19px;
    line-height: 23px;
    text-align: center;
    margin: 0 auto;
    max-width: 256px;
}

.i2-be-banner-text {
    @apply font-body;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-bottom: 10px;
}

.i2-be-banner-button {
    @apply bg-white flex items-center justify-center relative font-body font-black my-0 mx-auto;
    width: 120px;
    height: 30px;
    color: #ff8300;
    border-radius: 15px;
    padding-right: 20px;
    font-size: 15px;

    i {
        @apply absolute font-normal;
        font-size: 24px;
        top: 3px;
        right: 3px;
        color: #ff8300;
    }
}

/* MEDIA QUERIES */

@media #{$xsmall} {
    .i2-itinerary-section {
        &.i2-bancoestado {
            background: #ff8300;
            padding: 15px;
            border-radius: 12px;
        }
    }
}
