.i2-itinerary-section {
    &.i2-optionals {
        @apply grid p-0 bg-be-gray-10 overflow-hidden border border-solid border-be-gray-10;
        row-gap: 2px;
        column-gap: 2px;
        grid-template-columns: 1fr 1fr;
        border-radius: 12px;
        grid-area: optionals;
    }
}

.i2-itinerary-optional {
    @apply bg-white relative flex;
    padding: 30px 30px 105px;

    i {
        @apply text-be-blue;
        margin-right: 15px;
        font-size: 108px;
    }

    header {
        @apply italic text-be-blue;
        font-family: "ClanOT-Black", sans-serif;
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 25px;
    }

    article {
        @apply text-be-blue;
        font-family: "ClanOT-Medium", sans-serif;
        font-size: 15px;
        line-height: 18px;

        .main-emphasis {
            @apply italic text-be-cyan-5;
            font-family: "ClanOT-Black", sans-serif;
            font-size: 17px;
            line-height: 20px;
        }

        .emphasis {
            @apply text-be-blue;
            font-family: "ClanOT-Black", sans-serif;
        }
    }

    .i2-benefit {
        @apply text-white absolute flex items-center justify-center font-body font-semibold not-italic right-0;
        font-size: 19px;
        line-height: 16px;
        width: 234px;
        height: 60px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        top: 20px;

        > img {
            margin-right: 15px;
        }

        .smart-logo {
            height: 36px;
        }

        span {
            > img {
                height: 15px;
            }
        }
    }

    a.i2-btn {
        @apply absolute;
        bottom: 35px;
        right: 35px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-itinerary-optional {
        padding: 24px 24px 90px;

        i {
            margin-right: 12px;
            font-size: 96px;
        }

        header {
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 20px;

            &.push-down {
                margin-top: 38px;
            }
        }

        article {
            font-size: 15px;
            line-height: 18px;

            .main-emphasis {
                font-size: 17px;
                line-height: 20px;
            }
        }

        .i2-benefit {
            @apply top-0 right-0;
            font-size: 16px;
            line-height: 14px;
            width: 202px;
            height: 54px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            > img {
                margin-right: 10px;
            }

            span {
                > img {
                    height: 12px;
                }
            }
        }
    }
}

@media #{$medium} {
    .i2-itinerary-optional {
        padding: 15px 15px 65px;

        i {
            margin-right: 8px;
            font-size: 73px;
        }

        header {
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 10px;

            &.push-down {
                margin-top: 18px;
            }
        }

        article {
            font-size: 10px;
            line-height: 12px;

            .main-emphasis {
                font-size: 12px;
                line-height: 14px;
            }
        }

        .i2-benefit {
            @apply right-0;
            font-size: 13px;
            line-height: 11px;
            width: 155px;
            height: 42px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            top: 10px;

            > img {
                margin-right: 10px;
            }

            .smart-logo {
                height: 25px;
            }

            span {
                > img {
                    height: 10px;
                }
            }
        }

        a.i2-btn {
            bottom: 15px;
            right: 15px;
        }
    }
}

@media #{$small} {
    .i2-itinerary-optional {
        padding: 15px 15px 50px;

        i {
            margin-right: 8px;
            font-size: 73px;
        }

        header {
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 10px;

            &.push-down {
                margin-top: 18px;
            }
        }

        article {
            font-size: 10px;
            line-height: 12px;

            .main-emphasis {
                font-size: 12px;
                line-height: 14px;
            }
        }

        .i2-benefit {
            @apply right-0;
            font-size: 13px;
            line-height: 11px;
            width: 163px;
            height: 42px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            top: 10px;

            > img {
                margin-right: 10px;
            }

            .smart-logo {
                height: 25px;
            }

            span {
                > img {
                    height: 10px;
                }
            }
        }

        a.i2-btn {
            bottom: 15px;
            right: 15px;
        }
    }
}

@media #{$xsmall} {
    .i2-itinerary-section {
        &.i2-optionals {
            @apply gap-0 border-0;
            grid-template-columns: 1fr;
            border-radius: 12px;


            .i2-itinerary-optional {
                @apply block p-0;

                &.hidden-sm-up {
                    &:first-of-type {
                        .mobile-checkbox {
                            &:checked {
                                ~ .i2-optional-mobile-header {
                                    border-top-left-radius: 12px;
                                    border-top-right-radius: 12px;
                                }
                            }
                        }
                    }
                }
            }

            .i2-optional-mobile-header {
                @apply w-full relative border-b border-solid border-be-gray-10;

                header {
                    @apply flex w-full items-center p-4 m-0;
                    font-size: 13px;
                    line-height: 16px;
                    min-height: 50px;
                    max-width: 260px;
                    transition: all 0.25s ease-in-out;

                    .opener {
                        @apply absolute vertical-center rounded-full font-body font-semibold border border-solid border-be-red text-be-red not-italic flex items-center justify-center;
                        transition: all 0.25s ease-in-out;
                        right: 15px;
                        width: 25px;
                        height: 25px;
                        font-size: 22px;
                    }

                    .closer {
                        @apply absolute opacity-0 pointer-events-none text-be-cyan-2 not-italic font-body font-semibold;
                        transition: all 0.25s ease-in-out;
                        right: 10px;
                        top: 10px;
                        font-size: 32px;
                    }

                    .i2-btn {
                        @apply opacity-0 pointer-events-none absolute vertical-center;
                        right: 40px;
                        bottom: unset;
                    }
                }
            }

            .i2-optional-mobile-content {
                @apply overflow-hidden bg-be-cyan-2 text-white;
                max-height: 0;
                transition: all 0.25s ease-in-out;
                border-bottom-left-radius: 13px;
                border-bottom-right-radius: 13px;

                article {
                    @apply text-white;
                    font-size: 11px;
                    line-height: 13px;
                    margin: 20px;

                    .emphasis {
                        @apply text-white;
                    }

                    .main-emphasis {
                        @apply text-white;
                        font-size: 11px;
                        line-height: 13px;
                    }
                }
            }

            .mobile-checkbox {
                &:checked {
                    ~ .i2-optional-mobile-header {
                        @apply border border-solid border-be-cyan-2;

                        header {
                            @apply w-1/2;

                            .opener {
                                @apply opacity-0 pointer-events-none;
                            }

                            .closer {
                                @apply opacity-100 pointer-events-auto;
                            }

                            .i2-btn {
                                @apply opacity-100 pointer-events-auto;
                            }
                        }
                    }

                    ~ .i2-optional-mobile-content {
                        max-height: 200px;
                    }
                }
            }

            .i2-itinerary-optional {
                .i2-benefit {
                    @apply relative rounded-none w-full justify-start;
                    height: 32px;
                    padding-left: 15px;
                    font-size: 10px;
                    top: unset;
                    right: unset;

                    > img {
                        height: 22px;
                    }

                    .smart-logo {
                        height: 19px;
                    }

                    span {
                        img {
                            @apply relative;
                            height: 10px;
                            top: 1px;
                        }
                    }

                    br {
                        display: none;
                    }
                }

                i {
                    @apply text-be-blue;
                    font-size: 31px;
                    margin-right: 10px;
                }
            }
        }
    }
}
