$square-height: 33px;

.dg-dp-months-container {
    @apply flex w-full h-full relative;
}

.dg-dp-month {
    @apply relative h-full w-1/2;
    border-right: 1px solid #e0e2e5;
    padding: 12px 25px;

    &:last-child {
        @apply border-none;
    }
}

.dg-dp-disabled {
    @apply pointer-events-none opacity-25;
}

.dg-dp-square {
    @apply inline-flex relative items-center justify-center w-full;
    font-size: 13px;
    height: $square-height;
    margin: 0;

    &.dg-dp-navigation {
        @apply cursor-pointer text-2xl text-dp-blue;

        &:not(.dg-dp-disabled):hover {
            @apply bg-dp-blue text-white;
        }
    }

    &.dg-dp-month-back {
        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }

    &.dg-dp-month-forward {
        &:after {
            transform: translate(-50%, -50%) rotate(225deg);
        }
    }

    &.dg-dp-month-back,
    &.dg-dp-month-forward {
        @apply inline-block relative my-0;

        &:after {
            @apply absolute border-b border-l border-solid border-dp-red border-r-0 border-t-0;
            content: "";
            width: 10px;
            height: 10px;
            top: 50%;
            left: 50%;
        }

        &.dg-dp-disabled,
        &:not(.dg-dp-disabled) {
            &:hover {
                @apply bg-transparent;

                &:after {
                    @apply border-dp-blue;
                }
            }
        }
    }

    &.dg-dp-date {
        @apply cursor-pointer text-dp-blue text-dp-blue flex-col items-center justify-center;
        border-radius: 5px;

        &.color-0 {
            color: #3dcbe2;
        }
        &.color-1 {
            color: #14abe0;
        }
        &.color-2 {
            color: #1477af;
        }
        &.color-3 {
            color: #1b365d;
        }

        span {
            @apply mt-2 font-normal;
            font-size: 11px;
        }

        &.dg-dp-today {
            @apply text-dp-red border-dp-red font-bold;
        }

        &.dg-dp-selected {
            @apply bg-dp-red text-white font-bold overflow-hidden relative;

            &.dg-dp-range-start {
                @apply rounded-r-none;

                &:before {
                    @apply absolute inset-y-0 left-0 pointer-events-none;
                    content: "";
                    width: 0;
                    border-top: #{$square-height * 0.5} solid transparent;
                    border-bottom: #{$square-height * 0.5} solid transparent;
                    border-left: 10px solid #dd7f8c;
                }
            }

            &.dg-dp-range-end {
                @apply rounded-l-none;

                &:after {
                    @apply absolute inset-y-0 right-0 pointer-events-none;
                    content: "";
                    left: unset;
                    width: 0;
                    border-top: #{$square-height * 0.5} solid transparent;
                    border-bottom: #{$square-height * 0.5} solid transparent;
                    border-right: 10px solid #dd7f8c;
                    border-left: none;
                }
            }
        }

        &.dg-dp-in-range {
            @apply text-white bg-dp-pink rounded-none opacity-100;

            span {
                @apply opacity-0;
            }
        }

        &:nth-child(6),
        &:nth-child(7) {
            @apply font-bold;
        }

        &:not(.dg-dp-disabled):hover {
            @apply bg-dp-red text-white;

            &.dg-dp-hover-dg-dp-range-end {
                @apply rounded-l-none;

                &:after {
                    @apply absolute inset-y-0 right-0 pointer-events-none;
                    content: "";
                    left: unset;
                    width: 0;
                    border-top: #{$square-height * 0.5} solid transparent;
                    border-bottom: #{$square-height * 0.5} solid transparent;
                    border-right: 10px solid #dd7f8c;
                    border-left: none;
                }
            }
        }
    }

    &.dg-dp-col-header {
        @apply font-bold uppercase my-2 text-dp-blue flex items-center justify-center;
        height: 21px;
        font-size: 12px;
    }
}

.dg-dp-unit-nav {
    @apply px-2 inline-flex items-center capitalize text-dp-red font-body;
    font-size: 16px;
    height: 30px;
}

/* MEDIA QUERIES */

@media #{$large} {
    .dg-dp-month {
        padding: 12px;
    }

    .dg-dp-square {
        font-size: 12px;

        &.dg-dp-date {
            span {
                font-size: 10px;
            }
        }
    }
}

@media #{$medium} {
    .dg-dp-month {
        padding: 10px;
    }

    .dg-dp-square {
        font-size: 11px;

        &.dg-dp-date {
            span {
                font-size: 9px;
            }
        }
    }
}

@media #{$small} {
    .dg-dp-month {
        @apply w-full border-none;
        padding: 10px;
    }

    .dg-dp-square {
        font-size: 11px;

        &.dg-dp-date {
            span {
                font-size: 9px;
            }
        }
    }

    .dc-datepicker {
        .dg-dp-square {
            &.dg-dp-navigation {
                &:not(.dg-dp-disabled):hover {
                    @apply bg-transparent text-dp-blue;
                }
            }

            &.dg-dp-date {
                &:not(.dg-dp-disabled):hover {
                    @apply bg-transparent text-dp-blue;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .dg-dp-months-container {
        @apply overflow-x-auto w-full block;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .dg-dp-month {
        @apply border-none mx-auto;
        max-width: 100%;
        width: 415px;
    }

    .dg-dp-closer {
        @apply absolute flex items-center justify-center rounded-full text-brand-secondary bg-white font-semibold z-50;
        font-size: 30px;
        top: 0;
        right: 0.25rem;
        width: 4rem;
        height: 4rem;
    }

    .dc-datepicker {
        .dg-dp-unit-nav {
            @apply whitespace-normal w-full flex-wrap justify-center;
            height: 45px;
        }

        .dg-dp-square {
            &.dg-dp-month-back,
            &.dg-dp-month-forward {
                width: 3rem;

                &:not(.dg-dp-disabled) {
                    &:hover {
                        &:after {
                            @apply text-dp-red;
                        }
                    }
                }
            }

            &.dg-dp-month-back {
                @apply ml-auto;
            }

            &.dg-dp-navigation {
                &:not(.dg-dp-disabled):hover {
                    @apply bg-transparent text-dp-blue;
                }
            }

            &.dg-dp-date {
                &:not(.dg-dp-disabled):hover {
                    @apply bg-transparent text-dp-blue;
                }
            }
        }
    }
}
