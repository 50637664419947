.booking-wrapper {
    &.staff-benefit-container {
        @apply p-0 rounded-xl;

        [type="checkbox"] + label[for] {
            @apply h-auto leading-none;
        }

        [type="checkbox"] + label:before {
            display: none;
        }

        [type="checkbox"]:not(:checked) {
            + .sb-list-elem {
                .opened {
                    display: none;
                }

                .closed {
                    display: inline;
                }
            }
        }

        [type="checkbox"]:checked {
            + .sb-list-elem {
                background-color: #f2f2f2;

                .opened {
                    display: inline;
                }

                .closed {
                    display: none;
                }

                .sb-list-opener {
                    @apply transform rotate-90;
                }

                .sb-list-extra-description {
                    max-height: 540px;
                }

                .sb-list-extra-image {
                    max-height: 540px;
                }
            }
        }

        label {
            @apply cursor-pointer common-transition flex items-center justify-center;
            grid-area: opener;
        }

        dc-table {
            .dc-table {
                tr {
                    &:last-child td:not([class^="p-"]):not([class*=" p-"]) {
                        @apply px-8;

                        > div {
                            @apply p-0;
                        }
                    }
                }

                th {
                    @apply bg-be-blue font-body font-bold;
                    font-size: 16px;
                }

                td {
                    @apply pointer-events-none;

                    &:not([class^="p-"]):not([class*=" p-"]) {
                        @apply px-8;

                        > div {
                            @apply p-0;
                        }
                    }
                }
            }
        }
    }
}

.sb-header {
    @apply w-full bg-cover bg-left bg-no-repeat px-32 py-12 flex justify-end rounded-t-xl;
    background-image: url("/Images/EmployeeDiscount/ed-banner-image.png");
    height: 200px;
}

.sb-header-text {
    @apply text-white font-body;
    font-size: 42px;
    line-height: 45px;
    width: 240px;
    text-shadow: 2px 2px 10px #333;
}

.sb-header-smart {
    @apply mt-2 font-emphasis;
    text-align: right;
    font-size: 40px;
}

.sb-content {
    @apply py-10;
}

.sb-title-container {
    @apply flex justify-center uppercase px-16;
    text-align: center;
    margin-bottom: 30px;
}

.sb-title {
    @apply relative text-be-blue font-body font-black italic;
    max-width: 525px;
    font-size: 28px;
    line-height: 33px;

    &:after,
    &:before {
        @apply absolute bg-be-blue vertical-center;
        content: "";
        width: 70px;
        height: 1px;
    }

    &:before {
        left: -100px;
    }

    &:after {
        right: -100px;
    }
}

.sb-subtitle {
    @apply text-be-cyan-2 font-body font-black italic;
}

.sb-banner {
    @apply text-brand-secondary grid grid-cols-12 mb-4 rounded-lg border border-solid border-gray-400 py-8 mx-auto;
    width: 856px;
    max-width: 100%;
}

.sb-banner-icon {
    @apply col-span-2 flex justify-center align-middle items-center;

    .js-circle-user {
        @apply text-be-cyan-2;
        font-size: 65px;
    }
}

.sb-banner-info {
    @apply col-span-7;
}

.sb-banner-info-title {
    @apply mb-2 font-heavy;
    font-size: 24px;
}

.sb-banner-info-description-bottom {
    font-size: 16px;
}

.sb-banner-button-container {
    @apply col-span-3 flex justify-center align-middle items-center;
}

.sb-list-elem {
    @apply px-16 text-brand-secondary mb-8 pt-6 pb-2 overflow-hidden common-transition relative grid w-full;
    grid-template-columns: 85px auto 315px 80px;
    grid-template-areas:
        "icon title benefit opener"
        "icon description benefit opener"
        "icon collapsed-description collapsed-image dummy";
}

.sb-list-icon {
    grid-area: icon;
    @apply text-be-cyan-2;
    font-size: 60px;
}

.sb-list-info-title {
    @apply font-heavy;
    grid-area: title;
    font-size: 21px;
}

.sb-list-info-description {
    @apply mt-3;
    grid-area: description;
    margin-right: 42px;

    p {
        font-size: 16px;
        line-height: 19px;
    }
}

.sb-list-benefit-container {
    @apply rounded-md border border-solid border-gray-400 py-2 px-8 bg-white;
    grid-area: benefit;
    height: 100px;
}

.sb-list-discount-family-img {
    @apply my-8 rounded-xl w-full;
}

.sb-list-opener {
    @apply common-transition;
    font-size: 35px;
}

.sb-list-extra-description {
    @apply overflow-hidden common-transition;
    grid-area: collapsed-description;
    max-height: 0;
}

.sb-list-extra-image {
    @apply overflow-hidden common-transition;
    grid-area: collapsed-image;
    max-height: 0;
}

.sb-back-button-container {
    @apply flex justify-center px-16 mt-4;
}

.sb-fifty-percent-container {
    @apply flex justify-between items-center h-full;
}

.sb-fifty-percent-left {
    @apply w-full;
    text-align: center;
}

.sb-fifty-percent-img {
    @apply mr-2;
    width: 109px;
}

.sb-fifty-percent-right {
    @apply flex m-auto items-center align-middle h-full;
}

.sb-pet-container {
    @apply flex items-center h-full;
}

.sb-pet-left {
    margin: 0 20px;
    text-align: center;

    i {
        font-size: 65px;
    }
}

.sb-pet-right {
    @apply flex m-auto items-center align-middle h-full font-bold font-body;
    font-size: 16px;
    line-height: 19px;

    span {
        @apply font-black;
    }
}

.sb-bag-container {
    @apply flex items-center justify-center h-full;
}

.sb-bag-left {
    margin: 0 20px;
    text-align: center;

    i {
        font-size: 75px;
    }
}

.sb-bag-right {
    @apply h-full flex flex-col justify-center;
}

.sb-bag-right-top {
    @apply font-body font-bold;
    font-size: 14px;
    line-height: 15px;
}

.sb-bag-right-bottom {
    @apply flex items-center;
    margin-top: 10px;

    > span {
        &:first-child {
            @apply flex items-center;
        }

        &:last-child {
            @apply flex flex-col items-center justify-center;

            > span {
                &:first-child {
                    @apply font-bold font-bold whitespace-nowrap block;
                    font-size: 11px;
                    line-height: 11px;
                }

                &:last-child {
                    @apply font-bold font-bold block;
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }
    }
}

.sb-discounted-trips-container {
    @apply h-full inline-flex flex-col justify-center;

    div {
        &:nth-child(1) {
            @apply italic mb-1;
            font-size: 14px;
        }
        &:nth-child(2) {
            @apply bg-be-cyan-2 inline-block mb-1 rounded-md text-white py-1 px-2;
        }
        &:nth-child(3) {
            @apply text-gray-500;
            font-size: 11px;
        }
    }
}

.sb-gift-card-container {
    @apply pt-3 m-auto h-full;
    text-align: center;

    div {
        &:first-child {
            @apply font-bold mb-4 relative;
            top: -9px;
            font-size: 20px;
        }

        &:last-child {
            font-size: 16px;
        }
    }
}

.sb-gift-card-img {
    @apply inline relative ml-1;
    top: 8px;
    height: 30px;
}

.sb-gift-card-pill {
    @apply bg-be-cyan-2 mr-2 rounded-md text-white py-1 px-2;
}

.sb-bag-text-image {
    @apply inline;
    margin: 0 8px;
    height: 20px;
}

.sb-family-container {
    @apply border border-solid border-gray-400;
    margin: 40px 85px;
    padding: 40px 85px;
    border-radius: 15px;
}

.sb-family-back-button-container {
    margin: 0 85px;
}

.sb-breadcrumbs {
    margin-bottom: 55px;

    span {
        @apply font-extra italic text-be-blue;
        font-size: 20px;

        &:last-child {
            @apply underline font-emphasis italic;
        }
    }
}

.sb-family-title {
    @apply flex font-body text-be-blue;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 40px;

    i {
        @apply text-be-cyan-2;
        font-size: 42px;
        margin-right: 15px;
    }

    h1 {
        @apply font-heavy text-be-blue;
        font-size: 27px;
        line-height: 32px;
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    .booking-wrapper {
        &.staff-benefit-container {
            margin: 40px 35px;
            width: calc(100% - 70px);

            dc-table {
                .dc-table {
                    th {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .sb-header {
        height: 147px;
        padding-top: 35px;
        padding-right: 60px;
        background-position: center;
    }

    .sb-header-text {
        font-size: 30px;
        line-height: 36px;
        width: 170px;
    }

    .sb-header-smart {
        margin-top: 8px;
        font-size: 30px;
    }

    .sb-title-container {
        margin-bottom: 24px;
    }

    .sb-banner {
        @apply flex items-center;
        width: 635px;
        margin: 0 auto 35px;
        padding: 15px 30px;
    }

    .sb-banner-icon {
        .js-circle-user {
            font-size: 44px;
        }
    }

    .sb-banner-info {
        margin: 0 25px;
    }

    .sb-banner-info-title {
        font-size: 20px;
    }

    .sb-banner-info-description-bottom {
        font-size: 15px;
    }

    .sb-list-elem {
        grid-template-columns: 55px auto 215px 55px;
        max-height: unset;
        padding: 15px 30px;
        margin-bottom: 0;
    }

    .sb-list-icon {
        font-size: 40px;
    }

    .sb-list-info-title {
        @apply leading-none;
        font-size: 14px;
    }

    .sb-list-info-description {
        margin-top: 5px;
        p {
            font-size: 12px;
            line-height: 14px;
        }
    }

    .sb-list-benefit-container {
        height: 70px;
        padding: 0 15px;
    }

    .sb-list-discount-family-img {
        margin-top: 15px;
        margin-bottom: 35px;
    }

    .sb-list-opener {
        font-size: 27px;
    }

    .sb-fifty-percent-img {
        width: 65px;
    }

    .sb-fifty-percent-right {
        font-size: 12px;
        line-height: 14px;
    }

    .sb-pet-left {
        @apply m-0;

        i {
            font-size: 43px;
            margin-right: 10px;
        }
    }

    .sb-pet-right {
        font-size: 11px;
        line-height: 13px;
    }

    .sb-bag-left {
        @apply m-0;

        i {
            font-size: 52px;
        }
    }

    .sb-bag-right-top {
        font-size: 11px;
        line-height: 11px;
    }

    .sb-bag-right-bottom {
        margin-top: 5px;

        > span {
            &:first-child {
                font-size: 11px;
                line-height: 11px;
            }

            &:last-child {
                font-size: 11px;
                line-height: 11px;
            }
        }
    }

    .sb-discounted-trips-container {
        div {
            &:nth-child(1) {
                font-size: 12px;
                margin-bottom: 4px;
            }
            &:nth-child(2) {
                font-size: 13px;
                margin-bottom: 4px;
            }
            &:nth-child(3) {
                font-size: 10px;
            }
        }
    }

    .sb-gift-card-container {
        padding-top: 15px;

        div {
            &:first-child {
                @apply mb-0;
                font-size: 13px;
            }

            &:last-child {
                font-size: 13px;
            }
        }
    }

    .sb-gift-card-img {
        height: 25px;
    }

    .sb-bag-text-image {
        margin: 0 4px;
    }

    .sb-family-container {
        margin: 0 30px;
        padding: 30px 55px 60px;
    }

    .sb-family-back-button-container {
        margin: 45px 30px 0;
    }

    .sb-breadcrumbs {
        margin-bottom: 25px;

        span {
            font-size: 13px;
        }
    }

    .sb-family-title {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 22px;

        i {
            font-size: 27px;
            margin-right: 10px;
        }

        h1 {
            font-size: 18px;
            line-height: 21px;
        }
    }
}

@media #{$xsmall} {
    .booking-wrapper {
        &.staff-benefit-container {
            margin: 40px 20px;
            width: calc(100% - 40px);

            [type="checkbox"]:not(:checked) {
                + .sb-list-elem {
                    .opened {
                        display: none;
                    }

                    .closed {
                        display: inline;
                    }
                }
            }

            [type="checkbox"]:checked {
                + .sb-list-elem {
                    .sb-list-info-description {
                        max-height: 500px;
                    }
                }
            }

            label {
                @apply w-full justify-end;
                padding: 10px;
            }

            dc-table {
                @apply block w-full;

                .dc-table {
                    &:not(.pinned-cells) {
                        @apply w-full;
                        min-width: unset;
                    }

                    tr {
                        &:last-child td:not([class^="p-"]):not([class*=" p-"]) {
                            @apply px-4 py-2;
                        }
                    }

                    th {
                        font-size: 11px;
                    }

                    td {
                        @apply h-auto whitespace-normal;

                        &:not([class^="p-"]):not([class*=" p-"]) {
                            @apply px-4 py-2;
                        }
                    }
                }
            }
        }
    }

    .sb-header {
        height: 81px;
        padding-top: 25px;
        padding-right: 20px;
        background-position: center;
    }

    .sb-header-text {
        font-size: 14px;
        line-height: 17px;
        width: 170px;
        text-shadow: 2px 2px 10px #333;
        text-align: right;
    }

    .sb-header-smart {
        @apply mt-0;
        text-align: right;
        font-size: 13px;
    }

    .sb-content {
        padding: 20px;
    }

    .sb-title-container {
        padding: 0 5px;
        margin-bottom: 24px;
    }

    .sb-title {
        max-width: unset;
        font-size: 14px;
        line-height: 17px;

        &:after,
        &:before {
            @apply transform-none;
            content: "";
            width: 25px;
            height: 1px;
            top: 12px;
        }

        &:before {
            left: -40px;
        }

        &:after {
            right: -40px;
        }
    }

    .sb-banner {
        @apply flex flex-col w-full;
        margin: 0 auto 25px;
        padding: 15px 12px;
    }

    .sb-banner-icon {
        .js-circle-user {
            @apply mr-2;
            font-size: 32px;
        }
    }

    .sb-banner-info {
        @apply m-0;
    }

    .sb-banner-info-title {
        @apply mb-0;
        font-size: 12px;
    }

    .sb-banner-info-description-bottom {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 20px;
    }

    .sb-banner-title {
        @apply flex items-center justify-center;
        margin-bottom: 7px;
    }

    .sb-list-elem {
        @apply p-0 grid gap-0 w-full rounded-md border border-solid border-gray-400;
        grid-template-columns: 42px auto;
        grid-template-rows: auto;
        grid-template-areas:
            "icon title"
            "benefit benefit"
            "opener opener"
            "description description"
            "collapsed-description collapsed-description"
            "collapsed-image collapsed-image";
    }

    .sb-list-icon {
        @apply w-full border-b border-solid border-gray-400;
        padding: 6px;
        font-size: 30px;
    }

    .sb-list-info-title {
        @apply leading-none w-full border-b border-solid border-gray-400 h-full flex items-center m-0;
        padding: 6px 20px 6px 0;
        font-size: 12px;
    }

    .sb-list-info-description {
        @apply overflow-hidden common-transition;
        max-height: 0;
        margin: 0 20px;

        p {
            font-size: 11px;
            line-height: 13px;
        }
    }

    .sb-list-benefit-container {
        @apply border-none mx-auto bg-transparent pb-0;
        height: unset;
        padding: 15px;

        &.xs-left {
            @apply mx-0;
        }
    }

    .sb-list-discount-family-img {
        margin: 15px 20px;
        width: calc(100% - 40px);
    }

    .sb-list-opener {
        @apply relative top-auto right-auto;
        font-size: 20px;
        margin-left: 10px;
    }

    .opened,
    .closed {
        @apply font-body font-black;
        font-size: 14px;
    }

    .opened {
        @apply text-be-red;
    }

    .closed {
        @apply text-be-blue;
    }

    .sb-fifty-percent-img {
        width: 65px;
    }

    .sb-fifty-percent-right {
        font-size: 12px;
        line-height: 14px;
    }

    .sb-pet-left {
        @apply m-0;

        i {
            font-size: 43px;
            margin-right: 10px;
        }
    }

    .sb-pet-right {
        max-width: 90px;
        font-size: 11px;
        line-height: 13px;
    }

    .sb-bag-left {
        @apply m-0;

        i {
            font-size: 45px;
        }
    }

    .sb-bag-right-top {
        font-size: 11px;
        line-height: 13px;
    }

    .sb-bag-right-bottom {
        margin-top: 5px;

        > span {
            &:first-child {
                font-size: 11px;
                line-height: 11px;
            }

            &:last-child {
                @apply block;
                font-size: 11px;
                line-height: 11px;

                > span {
                    &:first-child {
                        @apply inline mr-1;
                        font-size: 12px;
                        line-height: 14px;
                    }

                    &:last-child {
                        @apply inline;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }
        }
    }

    .sb-discounted-trips-container {
        div {
            &:nth-child(1) {
                font-size: 12px;
                margin-bottom: 2px;
                text-align: center;
            }
            &:nth-child(2) {
                font-size: 12px;
                margin-bottom: 2px;
            }
            &:nth-child(3) {
                font-size: 8px;
            }
        }
    }

    .sb-gift-card-container {
        padding-top: 10px;

        div {
            &:first-child {
                top: -9px;
                font-size: 15px;
            }

            &:last-child {
                font-size: 14px;
            }
        }
    }

    .sb-gift-card-img {
        height: 25px;
    }

    .sb-bag-text-image {
        height: 15px;
    }

    .sb-family-container {
        margin: 20px 10px;
        padding: 10px 10px 30px;
    }

    .sb-family-back-button-container {
        margin: 0 10px;
    }

    .sb-breadcrumbs {
        margin-bottom: 25px;

        span {
            font-size: 11px;
        }
    }

    .sb-family-title {
        @apply items-center justify-center;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 15px;

        i {
            font-size: 33px;
            margin-right: 15px;
        }

        h1 {
            font-size: 12px;
            line-height: 14px;
        }
    }

    .sb-family-title-description {
        @apply font-body;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 15px;
    }
}
