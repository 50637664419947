/* MEDIA QUERIES */

@media #{$medium-down} {
    .mobile-review-journey-selector {
        margin: 0 20px;
    }

    .mobile-review-seatmap {
        @apply fixed inset-x-0 bottom-0 bg-white overflow-scroll;
        top: 70px;
        z-index: 1000;

        header {
            @apply flex items-center justify-around border-b border-solid border-new-ui-blue;
            padding: 20px 0;
            text-align: center;

            span {
                @apply font-black text-new-ui-blue;
                font-size: 22px;
            }
        }

        a {
            @apply inline underline text-new-ui-red;
            margin: 20px auto 0;
            font-size: 14px;
        }

        .cb-title {
            a {
                @apply text-be-cyan;
            }
        }
    }

    .mobile-review-table {
        @apply border border-solid border-new-ui-blue;
        margin: 17px;
        border-radius: 10px;
        background: #e2e2e2;
    }

    .mobile-review-row {
        @apply flex items-center text-new-ui-blue;

        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            .mobile-review-col {
                @apply font-bold;
                font-size: 16px;

                &:first-child {
                    border-top-left-radius: 10px;
                }

                &:last-child {
                    border-top-right-radius: 10px;
                }
            }
        }

        &:nth-child(even) {
            background: $gray-lightest;
        }

        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .mobile-review-col {
                &:first-child {
                    border-bottom-left-radius: 10px;
                }

                &:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    .mobile-review-col {
        @apply flex items-center justify-center text-new-ui-blue font-normal;
        flex: 1;
        padding: 14px;
        text-align: center;
        font-size: 14px;

        &:first-child {
            @apply justify-start;
            text-align: left;
        }
    }

    .mobile-review-flight-number {
        @apply text-new-ui-blue font-semibold leading-none;
        font-size: 12px;
    }

    .mobile-review-segment-endpoints {
        @apply text-new-ui-blue font-bold leading-none;
        font-size: 16px;
    }

    .mobile-review-mobile-buttons {
        display: none;
    }

    .seat-total {
        @apply inline-block text-new-ui-blue font-black whitespace-nowrap;
        margin: 0 17px;
        font-size: 16px;
    }

    .mobile-review-tablet-buttons {
        @apply flex;

        a {
            @apply inline-block my-0 mx-auto;
            line-height: 50px;
        }
    }
}

@media #{$medium} {
    .mobile-review-seatmap {
        left: 320px;
    }
}

@media #{$small-down} {
    .mobile-review-seatmap {
        &.pushdown {
            top: 150px;

            &.banco-estado-seat-review {
                top: 100px;
            }
        }
    }
}

@media #{$xsmall} {
    .mobile-review-tablet-buttons {
        display: none;
    }

    .mobile-review-mobile-buttons {
        @apply block;

        a {
            @apply inline-block my-0 mx-auto;
            line-height: 50px;
        }
    }

    .seat-total {
        @apply block leading-none;
        margin: 0 auto 20px;
    }
}
