.seatmap-main-container {
    @apply flex flex-row-reverse items-stretch;
}

.cf-plane-container {
    @apply z-0 w-full bg-transparent;
    margin-left: -318px;
}

.cf-plane-body {
    @apply ml-auto flex origin-center justify-end;
    width: 855px;
    transition: all 0.5s;
    margin-right: -15px;

    &.faded {
        @apply opacity-0;
        transform: scale(1.2, 1.2);
    }
}

.cf-plane-inside {
    width: 260px;
    margin-right: 140px;
}

.cf-seatmap-row {
    @apply relative flex w-full items-center justify-center;

    &.cf-gap-before {
        padding-top: 12px;
    }

    &.extra-room {
        padding-top: 12px;
    }
}

.cf-plane-body-image {
    @apply absolute bottom-0 top-0 h-full;
    right: -140px;
    width: 855px;
    z-index: -2;

    &.keep-ratio {
        bottom: unset;
        height: 1000%;
    }
}

.cf-seatmap-seat-container {
    @apply relative flex items-end justify-center;
    height: 30px;
    width: 14.2857%;

    &.cf-row-number {
        @apply items-center font-semibold text-brand-secondary;
        font-size: 14px;
    }

    &.cf-offset-forward {
        top: -7px;
    }

    &.cf-offset-backward {
        top: 7px;
    }
}

.cf-exits {
    @apply relative flex items-center justify-between;
    height: 30px;

    &:after {
        @apply absolute inset-x-0 z-10 bg-be-gray;
        content: "";
        top: 14px;
        height: 1px;
    }

    .cf-exit-icon {
        &:nth-child(4) {
            margin-left: -13px;
        }

        &:nth-child(5) {
            margin-right: -13px;
        }
    }
}

.cf-toilette-row {
    @apply relative flex items-center justify-between;
    height: 30px;

    .flight-number {
        @apply absolute font-body font-black text-new-ui-blue all-center;
        font-size: 16px;
    }
}

.cf-exit-row {
    @apply pointer-events-none absolute inset-y-0 flex items-center justify-between;
    left: -13px;
    right: -13px;
}

.cf-exit-icon {
    height: 20px;
}

.cf-toilette-icon {
    height: 20px;
}

.cf-banco-estado-zone {
    &:before {
        @apply pointer-events-none absolute top-0;
        content: "";
        border-radius: 5px;
        left: -10px;
        right: -140px;
        bottom: -62px;
        background: rgba(238, 128, 29, 0.3);
        border: 1px solid rgba(63, 60, 57, 0.3);
        z-index: 1;
    }

    &.unavailable {
        &:before {
            background: rgba(135, 135, 135, 0.3);
        }
    }

    &.is-banco-estado-category-56 {
        &.unavailable {
            &:before {
                background: rgba(234, 128, 26, 0.3);
            }
        }
    }
}

.cf-banco-estado-zone-logo {
    @apply absolute z-10 flex flex-col items-center justify-center vertical-center;
    right: -120px;

    > span {
        @apply font-body italic;
        font-size: 12px;
        color: #42403f;
    }

    img {
        height: 33px;
        margin-top: 5px;
    }
}

.cf-banco-estado-tooltip-opener {
    @apply absolute right-0 top-0 cursor-pointer font-body italic;
    font-size: 12px;
    color: #42403f;
    width: 14px;
    height: 14px;

    &:after {
        @apply absolute left-0 top-0 flex items-center justify-center rounded-full font-body font-black not-italic text-white;
        content: "?";
        width: 14px;
        height: 14px;
        background: gray;
        font-size: 11px;
    }

    &:hover {
        .cf-banco-estado-tooltip {
            @apply flex;
        }
    }
}

.cf-banco-estado-tooltip {
    @apply absolute items-center justify-center bg-be-light-gray font-body italic shadow-medium;
    font-size: 12px;
    color: #42403f;
    top: -80px;
    right: -20px;
    display: none;
    border-radius: 8px;
    width: 223px;
    height: 62px;
    border: 1px solid gray;

    &:after {
        @apply absolute right-0 bg-be-light-gray;
        content: "";
        top: 100%;
        transform: translate(-15px, -9px) rotate(45deg);
        width: 20px;
        height: 20px;
        border-bottom: 1px solid gray;
        border-right: 1px solid gray;
    }

    i {
        font-size: 38px;
        color: gray;
        margin-right: 10px;
    }
}

.cf-banco-estado-emphasis {
    @apply block font-body font-black not-italic;
    font-size: 14px;
    color: gray;
}

.cf-banco-estado-tooltip-info {
    @apply block whitespace-normal font-body not-italic leading-none;
    max-width: 162px;
    font-size: 10px;
    font-weight: 400;
    color: gray;
}

.cf-banco-estado-mobile-logo {
    display: none;
}

.seatmap-row-price-category-label {
    &.category-1 {
        background-color: #263f6a;
    }
    &.category-2 {
        background-color: #00abc8;
    }
    &.category-3 {
        background-color: #2b7ea3;
    }
    &.category-4 {
        background-color: #263f6a;
    }
    &.category-5 {
        background-color: #61d6e5;
    }
}

// https://github.com/karlhorky/gray/blob/gh-pages/css/gray.css
.grayscale {
    /* Firefox 10-34 */
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");

    /*
      Chrome 19+,
      Safari 6+,
      Safari 6+ iOS,
      Opera 15+
    */
    -webkit-filter: grayscale(1);

    /* Firefox 35+ */
    filter: grayscale(1);

    /* IE 6-9 */
    filter: gray;

    &.grayscale-faded {
        opacity: 0.45;
    }

    &.disabled {
        @apply pointer-events-none;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .cf-plane-container {
        margin-left: -380px;
    }

    .cf-plane-body {
        width: 745px;
        margin-right: -9px;
    }

    .cf-plane-inside {
        width: 240px;
        margin-right: 60px;
    }

    .cf-plane-body-image {
        width: 828px;
        clip-path: polygon(0 0, 0 100%, 91% 100%, 91% 0);

        &.keep-ratio {
            height: 880%;
        }
    }

    .cf-seatmap-seat-container {
        height: 28px;
    }

    .cf-banco-estado-zone {
        &:before {
            right: -76px;
        }
    }

    .cf-banco-estado-zone-logo {
        right: -65px;

        > span {
            font-size: 11px;
        }
    }
}

@media #{$medium-down} {
    .seatmap-main-container {
        &.outer-package-box {
            @apply border-none;
        }
    }

    .cf-plane-container {
        @apply ml-0 border-none p-0;
        z-index: unset;
    }

    .cf-plane-body {
        @apply mr-auto w-full border border-solid border-be-blue;
        margin-top: 15px;
        border-radius: 5px;
    }

    .cf-plane-inside {
        @apply mx-auto;
        width: 48%;
    }

    .cf-plane-body-image {
        @apply z-0;
        left: -57%;
        right: -57%;
        width: 214%;

        &.keep-ratio {
            height: 770%;
        }
    }

    .banco-estado-56 {
        .cf-plane-body-image {
            @apply z-0;
            left: -57%;
            right: -57%;
            width: 214%;

            &.keep-ratio {
                height: 770%;
            }
        }
    }

    .cf-seatmap-row {
        &.cf-gap-before {
            padding-top: 12px;
        }
    }

    .cf-seatmap-seat-container {
        @apply block;
        height: 10%;
        padding-top: 2.2%;

        &.cf-mobile-vertical-offset {
            @apply pt-0;
        }

        &.cf-row-number {
            @apply flex items-center;
            font-size: 14px;
        }

        &.cf-offset-forward {
            top: -0.5vw;
        }

        &.cf-offset-backward {
            top: 0.5vw;
        }
    }

    .cf-banco-estado-zone {
        &:before {
            left: -2.5%;
            right: -55%;
            bottom: -210%;
        }
    }

    .cf-banco-estado-zone-logo {
        right: -41%;
    }

    .cf-exit-icon {
        @apply z-10;
    }

    .cf-toilette-icon {
        @apply relative z-10;
    }
}

@media #{$small} {
    .cf-plane-container {
        @apply w-full px-0;
    }

    .cf-plane-inside {
        width: 46%;
        padding-bottom: 15px;
    }

    .cf-seatmap-row {
        &.cf-gap-before {
            padding-top: 12px;
        }
    }

    .cf-banco-estado-zone {
        &:before {
            left: -4%;
            right: -50%;
        }
    }
}

@media #{$xsmall} {
    .cf-plane-container {
        @apply w-full px-0;
        z-index: 1;
    }

    .cf-plane-inside {
        width: 77%;
        padding-bottom: 1%;
    }

    .cf-plane-body-image {
        left: -15%;
        right: -15%;
        width: 130%;
    }

    .banco-estado-56 {
        .cf-plane-body-image {
            left: -15%;
            right: -15%;
            width: 130%;
        }
    }

    .cf-seatmap-row {
        &.cf-gap-before {
            padding-top: 12px;
        }
    }

    .cf-seatmap-seat-container {
        height: 8.5vw;

        &.cf-offset-forward {
            top: -2vw;
        }

        &.cf-offset-backward {
            top: 2vw;
        }
    }

    .cf-exit-row {
        left: -20px;
        right: -20px;
    }

    .cf-banco-estado-zone {
        &:before {
            top: 10%;
            left: -4%;
            right: -14%;
            bottom: -212%;
        }
    }

    .cf-banco-estado-zone-logo {
        display: none;
    }

    .cf-banco-estado-mobile-logo {
        @apply absolute z-10 block transform-none bg-be-orange;
        width: 32px;
        top: 9%;
        right: -14%;
        bottom: -210%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        &:after {
            @apply absolute origin-center whitespace-normal font-black text-white;
            content: "Zona BancoEstado";
            line-height: 1.25;
            top: 50%;
            left: 50%;
            font-size: 10px;
            text-align: center;
            transform: translate(-50%, -50%) rotate(-90deg);
        }
    }

    .mobile-seatmap-button-container {
        @apply fixed inset-x-0 bottom-0;
        z-index: 1000;
    }
}
