.breadcrumbs-bg {
    position: relative;
    margin-top: 20px;
}

.breadcrumbs {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 40px;
    transform: translateY(-1px);
    border-bottom: 2px $brand-secondary dotted;
}

.booking-step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $common-gap;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $breadcrumb-booking-step-bg;
    color: #fff;
    z-index: 99;

    &.active {
        background: $brand-secondary;
    }

    &.past {
        cursor: pointer;

        &:hover {
            background: $brand-secondary;
        }
    }
}

.breadcrumb-section {
    position: relative;

    span {
        position: absolute;
        top: 0;
        left: 50px;
        color: $brand-secondary;
        font-family: "Lato", sans-serif;
        font-size: 15px;
        font-weight: $bold-font-weight;
    }
}

/* MEDIA QUERIES */

@media #{$small-down} {
    .breadcrumbs-bg {
        margin-top: 20px;
    }

    .breadcrumbs {
        top: 33px;
    }

    .booking-step {
        width: 25px;
        height: 25px;
        display: inline-flex;
    }
}

@media #{$xsmall} {
    .breadcrumbs-bg {
        z-index: 0;
    }
}
