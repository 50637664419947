.nbs-name {
    @apply font-body text-new-ui-blue;
    padding: 0 0 10px 10px;
    font-size: 14px;
}

.nbs-info {
    @apply flex items-center h-full;
}

.nbs-number {
    @apply flex items-center border-be-gray-3 border border-solid text-white justify-center;
    border-radius: 5px;
    margin: 0 0 0 10px;
    width: 52px;
    height: 34px;

    &.selected {
        @apply bg-be-gray-3;
    }
}

.category-5 {
    .nbs-number {
        @apply border-be-orange;

        &.selected {
            @apply bg-be-orange;
        }
    }
}

.nbs-seat-number {
    @apply font-body font-bold p-0;
    font-size: 14px;
    margin-right: 2px;
}

.nbs-delete-seat {
    @apply flex items-center justify-center border border-solid border-white rounded-full leading-none cursor-pointer;
    font-size: 13px;
    width: 16px;
    height: 16px;
}

.be-2 {
    .seatmap-journey-price {
        @apply text-be-blue font-body p-0 leading-none flex items-center justify-center font-semibold border-be-blue;
        width: 355px;
        height: 37px;
        margin-top: 18px;
        font-size: 21px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .nbs-name {
        font-size: 14px;
        padding: 0 0 5px 5px;
    }

    .nbs-number {
        width: 50px;
        height: 30px;
        margin-left: 5px;
    }

    .nbs-seat-number {
        font-size: 14px;
        margin-right: 4px;
    }

    .nbs-delete-seat {
        font-size: 13px;
        width: 14px;
        height: 14px;
    }

    .be-2 {
        .seatmap-journey-price {
            font-size: 15px;
        }
    }
}
