.i2-itinerary-section {
    &.i2-seats {
        @apply relative;
        grid-area: seats;
        border-radius: 22px;
        height: 253px;
        padding: 46px 30px 0 387px;

        &.banco-estado {
            padding-top: 20px;

            header {
                padding-right: 276px;
                margin-bottom: 10px;
            }

            article {
                margin-bottom: 10px;
            }
        }

        > img {
            @apply block absolute top-0 left-0 bottom-0;
            max-height: 100%;
        }

        header {
            @apply italic text-be-cyan-5;
            font-family: "ClanOT-Black", sans-serif;
            font-size: 28px;
            line-height: 34px;
        }

        article {
            @apply italic text-be-blue;
            font-family: "ClanOT-Black", sans-serif;
            font-size: 19px;
            line-height: 28px;
            margin-bottom: 25px;

            span {
                font-family: "ClanOT-Medium", sans-serif;
            }
        }

        .i2-benefit {
            @apply text-white absolute flex items-center justify-center font-body font-semibold not-italic right-0;
            font-size: 19px;
            line-height: 16px;
            width: 234px;
            height: 60px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            top: 20px;

            > img {
                margin-right: 15px;
            }

            .smart-logo {
                height: 36px;
            }

            span {
                > img {
                    height: 15px;
                }
            }
        }
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .i2-itinerary-section {
        &.i2-seats {
            height: 200px;
            padding: 20px 20px 0 290px;

            header {
                font-size: 26px;
            }

            article {
                margin-bottom: 8px;
            }

            &.banco-estado {
                header {
                    font-size: 24px;
                    line-height: 30px;
                    padding-right: 250px;
                }

                article {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }
    }
}

@media #{$medium} {
    .i2-itinerary-section {
        &.i2-seats {
            height: 150px;
            padding: 30px 0 0 230px;

            &.banco-estado {
                header {
                    padding-right: 187px;
                    max-width: 458px;
                }
            }

            header {
                font-size: 17px;
                line-height: 20px;
            }

            article {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 10px;
            }

            .i2-benefit {
                @apply right-0;
                font-size: 13px;
                line-height: 11px;
                width: 164px;
                height: 42px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                top: 15px;

                > img {
                    margin-right: 10px;
                }

                .smart-logo {
                    height: 25px;
                }

                span {
                    > img {
                        height: 10px;
                    }
                }
            }
        }
    }
}

@media #{$small} {
    .i2-itinerary-section {
        &.i2-seats {
            height: 150px;
            padding: 30px 30px 0 230px;

            &.banco-estado {
                header {
                    padding-right: 187px;
                    max-width: 458px;
                }
            }

            header {
                font-size: 17px;
                line-height: 20px;
            }

            article {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 10px;
            }

            .i2-benefit {
                @apply right-0;
                font-size: 13px;
                line-height: 11px;
                width: 164px;
                height: 42px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                top: 15px;

                > img {
                    margin-right: 10px;
                }

                .smart-logo {
                    height: 25px;
                }

                span {
                    > img {
                        height: 10px;
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .i2-itinerary-section {
        &.i2-seats {
            height: 127px;
            padding: 10px 20px 0 140px;
            border-radius: 5px;

            &.banco-estado {
                height: 172px;
                padding-top: 45px;

                header {
                    padding-right: 0;
                    margin-bottom: 5px;
                }

                article {
                    font-size: 9px;
                    line-height: 11px;
                }
            }

            header {
                @apply mb-0;
                font-size: 11px;
                line-height: 13px;
            }

            article {
                font-size: 10px;
                line-height: 12px;
                margin-bottom: 10px;
            }

            .i2-benefit {
                @apply leading-none w-auto top-0 right-0;
                font-size: 9px;
                height: 28px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                left: 121px;

                br {
                    display: none;
                }

                > img {
                    margin-right: 5px;
                }

                .smart-logo {
                    height: 19px;
                }

                span {
                    > img {
                        height: 8px;
                    }
                }
            }
        }
    }
}
