.bancoe-included-optional {
    @apply flex items-center text-white font-body font-semibold whitespace-nowrap;
    padding: 8px 15px;
    border-radius: 10px;
    margin-bottom: 20px;

    img {
        &:first-child {
            height: 28px;
            margin-right: 15px;
        }

        &:last-child {
            height: 15px;
            margin-left: 7px;
        }
    }
}

.extra-in-bundle-info {
    @apply font-body font-semibold text-white inline-block;
    margin-bottom: 20px;
    font-size: 19px;
    padding: 7px 20px;
    border-radius: 11px;
    line-height: 1.1;

    img {
        @apply relative;
        top: 2px;
        height: 18px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .bancoe-included-optional {
        font-size: 13px;

        img {
            &:first-child {
                height: 28px;
                margin-right: 7px;
            }

            &:last-child {
                height: 15px;
                margin-left: 4px;
                position: relative;
            }
        }
    }
}

@media #{$medium} {
    .bancoe-included-optional {
        font-size: 10px;
        padding: 8px;

        img {
            &:first-child {
                height: 18px;
                margin-right: 4px;
            }

            &:last-child {
                @apply relative;
                height: 12px;
                margin-left: 4px;
            }
        }
    }
}

@media #{$small} {
    .bancoe-included-optional {
        @apply inline-flex;
        padding: 5px 13px;
        font-size: 14px;

        img {
            &:first-child {
                margin-right: 8px;
                height: 20px;
            }

            &:last-child {
                @apply relative;
                margin-left: 5px;
                height: 15px;
            }
        }
    }

    .extra-in-bundle-info {
        font-size: 14px;
        padding: 5px 13px;

        img {
            height: 13px;
        }
    }
}

@media #{$xsmall} {
    .extra-in-bundle-info {
        margin: 10px 0;
        font-size: 11px;
        padding: 7px 15px;
        border-radius: 5px;

        img {
            top: 0;
            height: 11px;
        }
    }

    .bancoe-included-optional {
        position: relative;
        font-size: 10px;
        padding-left: 60px;
        margin: 10px 0;

        img {
            &:first-child {
                height: 22px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
            }

            &:last-child {
                height: 12px;
                position: relative;
                margin-left: 4px;
            }
        }
    }
}
