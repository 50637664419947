.seatmap-placeholder {
    min-height: 100vh;
}

ac-seatmap {
    user-select: none;
}

.cfs-subtitle {
    @apply font-body text-new-ui-blue;
    font-size: 14px;
    margin: 10px 0 20px;
}

/* MEDIA QUERIES */

.cfs-subtitle {
    margin-bottom: 16px;
}
