.flight-move-page {
    &.booking-wrapper {
        min-height: unset;

        .i2-tab-title {
            @apply mb-0;
        }

        &.inactive {
            opacity: 0.33;
        }
    }

    .flight-move-breadcrumbs {
        @apply flex items-start justify-center;

        li {
            @apply relative flex flex-col items-center justify-start font-body;
            text-align: center;
            width: 160px;
            font-size: 20px;
            line-height: 22px;
            z-index: 2;

            span {
                @apply flex items-center justify-center rounded-full bg-flight-move-gray font-body font-black text-white;
                font-size: 28px;
                width: 75px;
                height: 75px;
                border: 15px solid #fff;
            }

            &.active {
                @apply text-be-blue;

                span {
                    @apply bg-be-cyan;
                }
            }

            &:after {
                @apply absolute left-0 right-0 bg-flight-move-gray;
                content: "";
                top: 37px;
                height: 1px;
                z-index: -1;
            }

            &:nth-child(1) {
                &:after {
                    left: 50%;
                }
            }

            &:nth-child(4) {
                &:after {
                    right: 50%;
                }
            }
        }
    }

    .flight-move-journey {
        @apply flex w-full items-center;
        margin-bottom: 25px;

        &:last-child {
            @apply mb-0;
        }

        ac-flight-journey-summary {
            .multisegment {
                @apply flex flex-col items-start;
                width: unset;
            }

            .multisegment,
            .one-segment {
                .basic-checkbox-wrapper {
                    &.flight-journey-summary-checkbox-wrapper {
                        margin-bottom: 24px;

                        label {
                            &:before {
                                border: 1px solid $sidebar-small-down-bg;
                            }
                        }
                        .basic-checkbox-input {
                            &:checked {
                                ~ label {
                                    &:before {
                                        background-color: #f0ffff;
                                    }
                                    &:after {
                                        z-index: 2;
                                        background-color: $sidebar-small-down-bg;
                                        clip-path: polygon(39% 75%, 86% 28%, 91% 32%, 39% 83%, 15% 65%, 19% 60%);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .flight-journey-summary-checkbox-label {
                font-size: 16px;
            }
        }

        .flight-journey-summary {
            flex-shrink: unset;
            width: unset;
            padding-right: 30px;

            &.one-segment {
                @apply w-full;

                .flight-journey-overview-info {
                    margin: 0 0 15px 0;
                }
            }
        }
    }

    .flight-move-journey-container {
        @apply relative mx-auto inline-flex w-full cursor-pointer flex-nowrap items-center border border-solid border-flight-move-gray-2 common-transition;
        padding: 24px;
        border-radius: 6px;

        &.non-hoverable {
            transform: none;
            transition: none;
        }

        &:not(.non-hoverable) {
            &:hover {
                @apply shadow-medium;
                transform: scale(1.025);
            }
        }

        &.one-segment {
            ac-flight-journey-summary {
                @apply w-full;
            }

            .flight-journey-flight-plan {
                @apply whitespace-normal;

                @apply m-0 p-0;

                .flight-journey-stops-tooltip-opener {
                    &:before {
                        left: -141px;
                        width: 111px;
                    }

                    &:after {
                        right: -141px;
                        width: 111px;
                    }
                }

                .flight-journey-flight-left {
                    margin-right: 105px;
                }
                .flight-journey-flight-right {
                    margin-left: 105px;
                }
            }
        }
    }

    .flight-move-route-date-selector {
        @apply mx-auto;
        width: 1005px;
        max-width: 95%;

        h1 {
            @apply mb-8 text-be-blue;
            font-size: 20px;
            line-height: 22px;
        }

        .form-group {
            @apply mb-4;
        }
    }

    .flight-move-flight-selector {
        min-height: 50vh;

        .basic-checkbox-wrapper {
            label {
                @apply font-medium;
            }
        }
    }

    .flight-move-terms-container {
        @apply mx-auto my-8;
        width: 1005px;
        max-width: 95%;

        .cb-title {
            @apply text-be-blue;
            font-size: 20px;
            line-height: 22px;
        }
    }

    ac-flight-journey-summary {
        @apply w-full;
    }
}

.flight-move-flight-selector-buttons {
    @apply my-8 flex w-full justify-end common-transition;
    margin-left: auto;
    margin-right: auto;
}

/* MEDIA QUERIES */

@media #{$medium} {
    .flight-move-page {
        &.booking-wrapper {
            @apply mx-auto;
            max-width: 95%;

            .week-selector {
                ul {
                    li {
                        padding: 0 30px;
                    }
                }
            }
        }

        .cf-multisegment-journey-stops {
            @apply flex flex-col;
        }

        .cf-multisegment-journey-transfer {
            max-width: 100px;
        }

        .flight-move-journey-container {
            &.one-segment {
                @apply w-full;

                .flight-journey-flight-plan {
                    .flight-journey-stops-tooltip-opener {
                        &:before {
                            left: -80px;
                            width: 60px;
                        }

                        &:after {
                            right: -80px;
                            width: 60px;
                        }
                    }

                    .flight-journey-flight-left {
                        margin-right: 20px;
                    }
                    .flight-journey-flight-right {
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    .flight-move-flight-selector-buttons {
        @apply mx-auto;
        max-width: 95%;
    }
}

@media #{$small} {
    .flight-move-page {
        &.booking-wrapper {
            @apply mx-auto;
            max-width: 95%;
            padding: 25px;

            .week-selector {
                ul {
                    li {
                        padding: 0 10px;
                    }
                }
            }
        }

        .flight-move-breadcrumbs {
            margin-bottom: 35px;

            li {
                width: 140px;
                font-size: 19px;
                line-height: 22px;

                span {
                    font-size: 28px;
                    width: 75px;
                    height: 75px;
                    border: 15px solid #fff;
                }

                &:after {
                    top: 37px;
                }
            }
        }

        .flight-move-journey {
            margin-bottom: 25px;

            .flight-journey-summary {
                padding-right: 30px;
            }
        }

        .flight-move-journey-container {
            @apply w-full justify-center;
            padding: 15px 25px 45px 15px;
            border-radius: 6px;

            &:hover {
                @apply transform-none shadow-none;
            }

            &.one-segment {
                @apply w-full;

                .flight-journey-flight-plan {
                    .flight-journey-stops-tooltip-opener {
                        &:before {
                            left: -110px;
                            width: 80px;
                        }

                        &:after {
                            right: -110px;
                            width: 80px;
                        }
                    }

                    .flight-journey-flight-left {
                        margin-right: 105px;
                    }
                    .flight-journey-flight-right {
                        margin-left: 105px;
                    }
                }
            }
        }

        .flight-move-route-date-selector {
            width: 1005px;
            max-width: 95%;

            h1 {
                @apply mb-6;
                font-size: 20px;
                line-height: 22px;
            }

            .form-group {
                @apply mb-4;
            }
        }

        .flight-move-terms-container {
            @apply my-6;
            width: 1005px;
            max-width: 95%;

            .cb-title {
                font-size: 19px;
                line-height: 16px;
            }
        }
    }

    .flight-move-flight-selector-buttons {
        @apply mx-auto my-4;
        max-width: 95%;
    }
}

@media #{$xsmall} {
    .flight-move-page {
        &.booking-wrapper {
            @apply mx-auto w-full px-2 py-2;
            max-width: 95%;
        }

        .flight-move-breadcrumbs {
            margin-bottom: 15px;

            li {
                width: 140px;
                font-size: 14px;
                line-height: 16px;

                span {
                    font-size: 14px;
                    width: 45px;
                    height: 45px;
                    border: 10px solid #fff;
                }

                &:after {
                    top: 23px;
                }
            }
        }

        .flight-move-journey {
            @apply mb-0;

            .flight-journey-summary {
                padding-right: unset;
            }
        }

        .flight-move-journey-container {
            @apply justify-center;
            margin: 10px auto;
            border-radius: 6px;
            width: fit-content;
            width: -moz-fit-content;
            max-width: 95%;

            &:hover {
                @apply transform-none shadow-none;
            }

            .cf-multisegment-journey-grid {
                @apply m-0 w-full;
            }

            &.one-segment {
                @apply w-full;

                .flight-journey-flight-plan {
                    .flight-journey-stops-tooltip-opener {
                        &:before {
                            left: -30px;
                            width: 22px;
                        }

                        &:after {
                            right: -30px;
                            width: 22px;
                        }
                    }

                    .flight-journey-flight-left {
                        margin-right: 0;
                    }
                    .flight-journey-flight-right {
                        margin-left: 0;
                    }
                }
            }
        }

        .flight-move-route-date-selector {
            width: 1005px;
            max-width: 95%;

            h1 {
                @apply mb-6;
                font-size: 14px;
                line-height: 18px;
            }

            .form-group {
                @apply mb-4;
            }
        }

        .flight-move-terms-container {
            @apply my-6;
            width: 1005px;
            max-width: 95%;

            .cb-title {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .flight-move-flight-selector-buttons {
        @apply mx-auto my-4;
        max-width: 95%;
    }

    .flight-move-page {
        .fjs-endpoint-station-container {
            @apply flex-col;
        }
    }
}
