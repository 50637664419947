.profile-rockstar-ad {
    grid-area: advert;
    height: 162px;
    border-radius: 12px;
    @apply bg-white text-be-blue flex items-center justify-around;

    img {
        width: 223px;
    }

    button {
        @apply relative flex items-center font-body font-black justify-center font-body bg-be-red text-white normal-case border border-be-red border-solid;
        width: 170px;
        height: 30px;
        padding: 0 28px 0 10px;
        border-radius: 15px;
        letter-spacing: normal;
        font-size: 14px;
        transition: all 0.25s ease-in-out;

        i {
            @apply absolute text-white;
            font-size: 20px;
            top: 4px;
            right: 5px;
            margin: 0;
            transition: all 0.25s ease-in-out;
        }

        &:not(.disabled):hover {
            @apply border border-be-red border-solid bg-white text-be-red;
            padding: 0 28px 0 10px;

            i {
                @apply text-be-red;
            }
        }
    }
}

.rock-info {
    max-width: 610px;
    text-align: center;
    @apply text-be-blue;

    span {
        @apply block font-body;

        &:first-child {
            font-family: "ClanOT-Black", sans-serif;
            font-size: 27px;
            line-height: 33px;
        }

        &:last-child {
            font-size: 24px;
            line-height: 29px;
            max-width: 520px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$small} {
    .profile-rockstar-ad {
        height: 100px;

        img {
            width: 126px;
        }

        button {
            width: 125px;
            height: 22px;
            padding: 0 20px 0 5px;
            font-size: 12px;

            i {
                font-size: 14px;
                top: 3px;
                right: 5px;
            }

            &:not(.disabled):hover {
                padding: 0 20px 0 5px;
            }
        }
    }

    .rock-info {
        max-width: 360px;

        span {
            &:first-child {
                font-size: 18px;
                line-height: 22px;
            }

            &:last-child {
                font-size: 12px;
                line-height: 14px;
                max-width: 260px;
            }
        }
    }
}

@media #{$xsmall} {
    .profile-rockstar-ad {
        height: 148px;
        @apply bg-be-blue flex-col items-center justify-center;

        button {
            width: 147px;
            height: 30px;
            padding: 0 28px 0 10px;
            font-size: 12px;
            margin-top: 20px;

            i {
                font-size: 20px;
                top: 5px;
                right: 5px;
            }

            &:not(.disabled):hover {
                padding: 0 28px 0 10px;
            }
        }
    }

    .rock-info {
        max-width: 265px;

        span {
            @apply text-white;

            &:first-child {
                font-size: 13px;
                line-height: 15px;
            }

            &:last-child {
                font-size: 11px;
                line-height: 13px;
                max-width: 245px;
            }
        }
    }
}
