.passenger-page-accordion-header {
    @apply relative flex border border-solid border-brand-secondary;
    padding: 16px;
    border-radius: 5px;

    .user-icon {
        margin-right: 16px;
    }

    .accordion-arrow {
        @apply absolute border border-b-0 border-r-0 border-solid border-brand-secondary common-transition;
        width: 16px;
        height: 16px;
        right: 26px;
        transform: translateY(-4px) rotate(225deg);
    }

    &.open {
        .accordion-arrow {
            transform: translateY(4px) rotate(45deg);
        }
    }
}

.passenger-title {
    @apply font-semibold text-brand-secondary;
    margin-bottom: $small-gap;
    font-size: $font-size-small;
}

.passenger-subtitle {
    @apply text-brand-secondary;
    margin-bottom: $small-gap;
    font-size: $font-size-small;
}

.passengers-page-office-phone {
    @apply grid w-full gap-2;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: auto;
}

.passengers-fare-lock-pax-info {
    @apply flex items-center font-body text-brand-secondary;
    margin-bottom: 30px;
    font-size: 16px;

    span {
        @apply flex flex-shrink-0 items-center justify-center rounded-full border border-solid border-brand-secondary font-body;
        font-size: 28px;
        width: 36px;
        height: 36px;
        margin-right: 10px;
    }
}

.passengers-button-container {
    @apply flex justify-end;
}

.employee-discount-selector {
    .inner-deep-box {
        @apply min-h-0;
        padding: 15px;
    }

    .mdl-textfield {
        padding: 5px 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 9px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -3px;
            }
        }
    }
    .js-input {
        &.js-select {
            @apply appearance-none;
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;
            color: $brand-secondary;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            @apply absolute block;
            content: "\25BC";
            top: 25px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.passengers-grid {
    @apply grid w-full gap-y-0;
    padding: 20px 0;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "firstName lastName";

    &.chile-compra {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas:
            "firstName firstName firstName lastName lastName lastName"
            "docNumber docNumber email email officePhone officePhone"
            "paxType paxType paxType reason reason reason";

        &.for-infant {
            grid-template-areas: "firstName lastName";
        }

    }

    &.peru-compra {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "firstName lastName"
            "country email"
            "officePhone mobilePhone"
            "docType docNumber"
            "contactInfo contactInfo"
            "contactFirstName contactLastName"
            "contactOfficePhone contactMobilePhone"
            "contactEmail none3";

        &.for-infant {
            grid-template-areas: "firstName lastName";
        }
    }

    &.with-birth-data {
        grid-template-areas: "firstName lastName dateOfBirth";

        &.peru-compra {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas:
                "firstName firstName lastName lastName"
                "country country email email"
                "officePhone officePhone mobilePhone mobilePhone"
                "docType docNumber dateOfBirth dateOfBirth"
                "contactInfo contactInfo contactInfo contactInfo"
                "contactFirstName contactFirstName contactLastName contactLastName"
                "contactOfficePhone contactOfficePhone contactMobilePhone contactMobilePhone"
                "contactEmail contactEmail none3 none3";

            &.for-infant {
                grid-template-areas:
                    "firstName firstName lastName lastName"
                    "dateOfBirth dateOfBirth non1 non1";
            }
        }
    }

    &.with-doc {
        grid-template-areas:
            "firstName lastName"
            "docType docNumber";

        &.peru-compra {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "firstName lastName"
                "country email"
                "officePhone mobilePhone"
                "docType docNumber"
                "contactInfo contactInfo"
                "contactFirstName contactLastName"
                "contactOfficePhone contactMobilePhone"
                "contactEmail none3";

            &.for-infant {
                grid-template-areas: "firstName lastName";
            }
        }

        &.with-birth-data {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                "firstName lastName dateOfBirth"
                "docType docNumber none";

            &.peru-compra {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-areas:
                    "firstName firstName lastName lastName"
                    "country country email email"
                    "officePhone officePhone mobilePhone mobilePhone"
                    "docType docNumber dateOfBirth dateOfBirth"
                    "contactInfo contactInfo contactInfo contactInfo"
                    "contactFirstName contactFirstName contactLastName contactLastName"
                    "contactOfficePhone contactOfficePhone contactMobilePhone contactMobilePhone"
                    "contactEmail contactEmail none3 none3";

                &.for-infant {
                    grid-template-areas:
                        "firstName firstName lastName lastName"
                        "dateOfBirth dateOfBirth non4 non4";
                }
            }
        }
    }

    .mdl-textfield {
        @apply py-0;
    }

    .js-input {
        font-size: 20px;
        padding-top: 17px;

        &.js-select {
            padding-top: 10px;
        }
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        font-size: 14px;
        top: 4px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                font-size: 14px;
                top: -10px;
            }
        }
    }

    .birthdate-container {
        @apply mb-0;
        padding-top: 21px;

        .input-inner-title {
            @apply top-0;
        }

        .dob-selector-container {
            margin-right: 10px;

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .input-inner-title {
        top: 20px;
    }
}

.passengers-grid-first-name {
    grid-area: firstName;
}
.passengers-grid-last-name {
    grid-area: lastName;
}
.passengers-grid-contact-first-name {
    grid-area: contactFirstName;
}
.passengers-grid-contact-last-name {
    grid-area: contactLastName;
}
.passengers-grid-date-of-birth {
    grid-area: dateOfBirth;
}
.passengers-grid-doc-type {
    grid-area: docType;
}
.passengers-grid-doc-number {
    grid-area: docNumber;
}
.passengers-grid-office-phone {
    grid-area: officePhone;
}
.passengers-grid-contact-office-phone {
    grid-area: contactOfficePhone;
}
.passengers-grid-mobile-phone {
    grid-area: mobilePhone;
}
.passengers-grid-contact-mobile-phone {
    grid-area: contactMobilePhone;
}
.passengers-grid-dni {
    grid-area: dni;
}
.passengers-grid-country {
    grid-area: country;
}
.passengers-grid-email {
    grid-area: email;
}
.passengers-grid-contact-email {
    grid-area: contactEmail;
}
.passengers-grid-pax-type {
    grid-area: paxType;
}
.passengers-grid-reason {
    grid-area: reason;
}
.passengers-grid-contact-info {
    @apply col-span-2 font-body font-bold text-new-ui-blue;
    font-size: 16px;
}

.compact {
    .js-input {
        padding-top: 25px;
    }

    .mdl-textfield {
        padding: 0;
    }

    .mdl-textfield--floating-label .mdl-textfield__label {
        top: 3px;
    }

    .mdl-textfield--floating-label {
        &.is-focused,
        &.is-dirty,
        &.has-placeholder {
            .mdl-textfield__label {
                top: -7px;
            }
        }
    }
}

.passengers-grid {
    .js-input {
        &.js-select {
            @apply cursor-pointer appearance-none text-brand-secondary;
            font-size: 20px;
            padding-left: 15px;
            padding-top: 8px;

            option {
                font-size: $font-size-medium;
            }
        }
    }

    .js-select-arrow {
        &:after {
            @apply pointer-events-none absolute block;
            content: "\25BC";
            top: 20px;
            right: 15px;
            font-size: $font-size-xsmall;
            color: $gray-light;
        }
    }
}

.staff-family-button-container {
    @apply flex items-center;
    margin-bottom: 15px;

    .title-icon {
        @apply absolute font-semibold vertical-center;
        left: 5px;
        font-size: 25px;
    }

    .rounded-primary-btn {
        @apply whitespace-nowrap;
        padding-left: 35px;
    }
}

.staff-family-passenger {
    .passenger-title {
        font-size: 20px;
    }
}

.staff-passenger-header {
    @apply bg-white;

    &.inner-box {
        @apply mb-0;
        min-height: unset;
    }

    .passenger-title {
        @apply mb-0;
        font-size: 20px;
    }
}

.travelling-person-checkbox-wrapper {
    @apply mt-6;

    margin-bottom: 7px;
}

/* MEDIA QUERIES */

@media #{$small} {
    .passengers-grid {
        column-gap: 12px;

        &.with-doc {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                "firstName lastName"
                "docType docNumber";

            &.peru-compra {
                grid-template-columns: 1fr 1fr;
                grid-template-areas:
                    "firstName lastName"
                    "country email"
                    "officePhone mobilePhone"
                    "docType docNumber"
                    "contactInfo contactInfo"
                    "contactFirstName contactLastName"
                    "contactOfficePhone contactMobilePhone"
                    "contactEmail none3";

                &.for-infant {
                    grid-template-areas: "firstName lastName";
                }
            }

            &.with-birth-data {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-areas:
                    "firstName firstName lastName lastName dateOfBirth dateOfBirth"
                    "docType docType docType docNumber docNumber docNumber";

                &.peru-compra {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-template-areas:
                        "firstName firstName lastName lastName"
                        "country country email email"
                        "officePhone officePhone mobilePhone mobilePhone"
                        "docType docNumber dateOfBirth dateOfBirth"
                        "contactInfo contactInfo contactInfo contactInfo"
                        "contactFirstName contactFirstName contactLastName contactLastName"
                        "contactOfficePhone contactOfficePhone contactMobilePhone contactMobilePhone"
                        "contactEmail contactEmail none3 none3";

                    &.for-infant {
                        grid-template-areas:
                            "firstName firstName lastName lastName"
                            "dateOfBirth dateOfBirth non4 non4";
                    }
                }
            }
        }
    }
}

@media #{$xsmall} {
    .passengers-page-office-phone {
        @apply grid w-full gap-2;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .passengers-grid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "firstName"
            "lastName";

        &.chile-compra {
            grid-template-columns: 1fr;
            grid-template-areas:
                "firstName"
                "lastName"
                "docNumber"
                "email"
                "officePhone"
                "paxType"
                "reason";

            &.for-infant {
                grid-template-areas:
                    "firstName"
                    "lastName";
            }
        }

        &.peru-compra {
            grid-template-columns: 1fr;
            grid-template-areas:
                "firstName"
                "lastName"
                "country"
                "email"
                "officePhone"
                "mobilePhone"
                "docType"
                "docNumber"
                "contactInfo"
                "contactFirstName"
                "contactLastName"
                "contactEmail"
                "contactOfficePhone"
                "contactMobilePhone";

            &.for-infant {
                grid-template-areas:
                    "firstName"
                    "lastName";
            }
        }

        &.with-birth-data {
            grid-template-columns: 1fr;
            grid-template-areas:
                "firstName"
                "lastName"
                "dateOfBirth";

            &.peru-compra {
                grid-template-columns: 1fr;
                grid-template-areas:
                    "firstName"
                    "lastName"
                    "country"
                    "email"
                    "officePhone"
                    "mobilePhone"
                    "docType"
                    "docNumber"
                    "dateOfBirth"
                    "contactInfo"
                    "contactFirstName"
                    "contactLastName"
                    "contactEmail"
                    "contactOfficePhone"
                    "contactMobilePhone";

                &.for-infant {
                    grid-template-areas:
                        "firstName"
                        "lastName"
                        "dateOfBirth";
                }
            }
        }

        &.with-doc {
            grid-template-columns: 1fr;
            grid-template-areas:
                "firstName"
                "lastName"
                "docType"
                "docNumber";

            &.peru-compra {
                grid-template-columns: 1fr;
                grid-template-areas:
                    "firstName"
                    "lastName"
                    "country"
                    "email"
                    "officePhone"
                    "mobilePhone"
                    "docType"
                    "docNumber"
                    "contactInfo"
                    "contactFirstName"
                    "contactLastName"
                    "contactEmail"
                    "contactOfficePhone"
                    "contactMobilePhone";

                &.for-infant {
                    grid-template-areas:
                        "firstName"
                        "lastName";
                }
            }

            &.with-birth-data {
                grid-template-columns: 1fr;
                grid-template-areas:
                    "firstName"
                    "lastName"
                    "dateOfBirth"
                    "docType"
                    "docNumber";

                &.peru-compra {
                    grid-template-columns: 1fr;
                    grid-template-areas:
                        "firstName"
                        "lastName"
                        "country"
                        "email"
                        "officePhone"
                        "mobilePhone"
                        "docType"
                        "docNumber"
                        "dateOfBirth"
                        "contactInfo"
                        "contactFirstName"
                        "contactLastName"
                        "contactEmail"
                        "contactOfficePhone"
                        "contactMobilePhone";

                    &.for-infant {
                        grid-template-areas:
                            "firstName"
                            "lastName"
                            "dateOfBirth";
                    }
                }
            }
        }
    }

    .staff-family-passenger {
        .passenger-title {
            font-size: 16px;
        }
    }

    .staff-family-button-container {
        @apply flex items-center;
    }
}
