.modal-content {
    &.smart-plus-seating-modal-content {
        @apply p-0;
        width: 690px;

        .modal-header {
            @apply relative flex items-center justify-center bg-be-gray-3 font-emphasis text-white;
            height: 76px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            font-size: 23px;

            .modal-header-closing {
                @apply absolute right-0 top-0 cursor-pointer rounded-full border-0 bg-be-blue p-0 font-normal leading-none text-white;
                font-family: "Lato", sans-serif;
                transform: translate(50%, -50%);
                font-size: 36px;
                width: 40px;
                height: 40px;
                z-index: 1;
                letter-spacing: unset;

                &:hover {
                    @apply border-0 bg-black no-underline;
                }
            }
        }

        .smart-plus-seating-logo {
            @apply absolute font-body font-semibold italic text-white;
            text-align: center;
            top: 22px;
            left: 10px;
            transform: rotate(-30deg);
            font-size: 17px;

            span {
                @apply block font-emphasis not-italic text-white;
                margin-top: 5px;
                padding-left: 10px;
                font-size: 14px;
            }
        }

        .primary-btn,
        .secondary-btn {
            @apply inline-block;
        }
    }
}

.spsm-stats {
    @apply flex items-center justify-center;
    margin-top: 23px;

    i {
        @apply text-be-orange;
        font-size: 56px;
        margin-right: 10px;
    }
}

.spsm-stats-item {
    @apply font-body text-be-blue;
    font-size: 16px;
    line-height: 23px;

    span {
        @apply font-body font-semibold text-be-blue;
        font-size: 19px;
    }
}

.spsm-reminder {
    @apply font-body font-semibold text-be-gray-4;
    font-size: 17px;
    margin-top: 15px;
    text-align: center;

    span {
        @apply font-body font-normal italic text-be-gray-4;
        font-size: 17px;
    }
}

.spsm-button-container {
    @apply flex items-center justify-center;
    margin-top: 40px;
    margin-bottom: 15px;

    .secondary-link {
        @apply cursor-pointer font-body font-semibold text-be-blue underline;
        font-size: 15px;
        margin-right: 35px;

        &:hover {
            @apply text-be-gray-4 no-underline;
        }
    }

    .cta {
        @apply relative flex cursor-pointer items-center justify-center rounded-full border border-solid border-be-red bg-be-red font-body font-black leading-none text-white;
        width: 190px;
        height: 35px;
        padding-right: 30px;
        font-size: 15px;
        transition: all 0.25s ease-in-out;

        i {
            @apply absolute vertical-center;
            right: 5px;
            font-size: 25px;
        }

        &:hover {
            @apply bg-white text-be-red;
        }
    }
}

.spsm-seats-container {
    @apply mx-auto my-0;
    width: -moz-fit-content;
    width: fit-content;
}

.spsm-seats {
    @apply flex items-center justify-center;
    margin-top: 25px;

    &.too-many {
        @apply justify-start;
    }

    .spsm-seat {
        @apply relative;
        margin-right: 12px;

        &:last-child {
            @apply m-0;
        }

        i {
            @apply relative text-be-gray-3;
            font-size: 64px;
            left: -2px;
        }

        .spsm-seat-number {
            @apply absolute font-body font-semibold text-white all-center;
            font-size: 19px;
        }
    }
}

/* MEDIA QUERIES */

@media #{$xsmall} {
    .modal-content {
        &.smart-plus-seating-modal-content {
            width: 90%;

            .smart-plus-seating-logo {
                display: none;
            }

            .modal-header {
                @apply leading-none;
                text-align: center;
                font-size: 14px;
                height: 53px;
            }
        }
    }

    .spsm-stats {
        i {
            display: none;
        }
    }

    .spsm-stats-item {
        @apply flex items-center justify-between;
        font-size: 12px;
        padding: 0 10px;
        max-width: 80%;
        line-height: 1.2;
        margin: 7px 0;

        span {
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .spsm-reminder {
        font-size: 12px;
        max-width: 80%;
        margin: 15px auto 0;

        span {
            font-size: 12px;
        }
    }

    .spsm-seats {
        .spsm-seat {
            margin-right: 4px;

            i {
                font-size: 35px;
            }

            .spsm-seat-number {
                font-size: 11px;
            }
        }
    }

    .spsm-button-container {
        @apply flex-col-reverse;

        .cta {
            width: 154px;
            height: 28px;
            font-size: 12px;
        }

        .secondary-link {
            margin: 10px 0 0 0;
            font-size: 12px;
        }
    }
}
