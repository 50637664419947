.seatmap-second-leg-modal {
    .booking-modal-content {
        .modal-header {
            line-height: 1.45;
        }
    }
}

.return-seats-balloon {
    @apply absolute shadow-medium;
    top: 10%;
    right: -80px;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    background: #def3f7;
    text-align: center;
    z-index: 100;
    max-width: 347px;

    span {
        @apply block text-brand-secondary cursor-pointer;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .return-seats-balloon {
        right: -172px;
    }
}

@media #{$medium} {
    .return-seats-balloon {
        @apply right-auto;
        top: 210px;
        left: 50%;
    }
}

@media #{$small} {
    .return-seats-balloon {
        @apply right-auto;
        top: 220px;
        left: 50%;
        z-index: 1000;
    }
}

@media #{$xsmall} {
    .return-seats-balloon {
        @apply right-auto;
        top: 220px;
        left: 50%;
    }
}
